import {
  exists,
  ResizeHandler,
  getWindowHeight
} from './dom-utils.js';
import {lazyLoadImage} from './lazy-load.js';
import { _isNotNull, CookieHandler, EventHandler } from './utils.js';
import { $_GENERIC_LIGHTBOX_, brand_id, transitionEvents } from './vars.js';

$_GENERIC_LIGHTBOX_.on('show.bs.modal', (event) => {
  let button = $(event.relatedTarget);
  let pathToLightbox = button.data('lightbox-path');
  resetLightboxmodal();
  openLightbox(pathToLightbox, (e) => {
    let $imgs = $_GENERIC_LIGHTBOX_.find('img:not(.pll-image-full-size), .has-bg-img:not(img)'),
      count = $imgs.length;

    if (count > 0) {
      $imgs.each((i, el) => {
        lazyLoadImage($(el));

        EventHandler.one('img.load bgImg.load', (e) => {
          if (--count === 0) {
            getModalSize(e);
          }
        }, $(el));
      });

    } else {
      EventHandler.send(EventHandler.lazyLoad.rebind);
      getModalSize(e);
    }
  });
});

function openLightbox(pathToLightbox, callback) {
  if (pathToLightbox) {
    $.ajax({
      url: pathToLightbox,
      type: 'GET',
      success: (results) => {
      // Sanitize the HTML content using DOMPurify
        var sanitizedHTML = DOMPurify.sanitize(results, {ADD_TAGS: ["iframe"]});
        $_GENERIC_LIGHTBOX_.find('.modal-content').html(sanitizedHTML);

        // Fire modal open event for WR authenticated users to trigger data binding.
        if ((brand_id.toLowerCase() === 'wr') && CookieHandler.cidAuthenticated() && window.User) {
          EventHandler.send('wr.modal.open', $(document));
        }

        if (typeof callback === 'function') {
          callback($_GENERIC_LIGHTBOX_);
        }
      },
      error: (response) => {
        EventHandler.triggerEvent('brands-error', response);
        console.log(response);
      }
    });
  }
}

function getModalSize(e) {
  if (typeof e === 'object' && 'target' in e && _isNotNull(e.target)) {
    e = $(e.target);
  } else if (typeof e === 'string') {
    e = $(e);
  }

  let $size = e.find('.modal-content .modal-size');

  if (exists($size)) {
    e.find('> div').removeClass('modal-small modal-medium modal-large');

    if ($size.is('.modal-large')) {
      e.find('> div').addClass('modal-large');
    } else if ($size.is('.modal-medium')) {
      e.find('> div').addClass('modal-medium');
    } else {
      e.find('> div').addClass('modal-small');
    }
  } else {
    e.find('> div').addClass('modal-small');
  }
}

function checkModalHeightAgainstWindow(e) {
  let $modal = $(e.target);

  function addOverflowClass() {
    $modal.off(transitionEvents).removeClass('modal-overflow');

    let $dialog = $modal.find('.modal-dialog');

    if ($dialog.outerHeight() > (getWindowHeight() - 60)) {
      $modal.addClass('modal-overflow');
    }
  }

  if (!($modal.is('modal-overflow'))) {
    $modal.one(transitionEvents, addOverflowClass);
    ResizeHandler.addResizeEndFn(addOverflowClass, 'height');
  }
}

function resetLightboxmodal() {
 $_GENERIC_LIGHTBOX_.find('.modal-content').empty();
}

export {
  openLightbox,
  checkModalHeightAgainstWindow,
  getModalSize,
  resetLightboxmodal
};
