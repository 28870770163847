import {
  getBrand,
  getBrandName,
  ReservationHandler
} from '../base/session-handler.js';
import CancelReservation from '../components/cancel-reservation.js';
import {
  exists,
  isMobileWidth
} from '../base/dom-utils.js';
import RateDetails from '../components/rate-details.js';
import {
  _isNotNull,
  getDateFromDateString,
  getLocaleUrlToken,
  isSMORate,
  CookieHandler,
  isGoFreeRate,
  checkSessionForDualBrandImage

} from '../base/utils.js';
import { isWRCCHolder } from '../base/session-handler.js';
import {
  $_PAGE_,
  overview_propertyId,
  overview_propertyName,
  property_address,
  property_city_name,
  property_country_code,
  property_postal_code,
  property_state_code,
  roomrate_redirect_url,
  additionalCorpCodes
} from '../base/vars.js';
import Analytics from '../base/wyn-analytics-module.js';
import BnplAnalytics from '../base/bnpl-uplift-analytics.js';
class ConfirmationPage {
  constructor() {
    if ($_PAGE_.hasClass('confirmation-page')) {
      checkSessionForDualBrandImage();
    }
    if ($_PAGE_.is('.confirmation-page') && !($_PAGE_.is('.authoring'))) {
      this.reservation = ReservationHandler.getReviewReservation();
      if (additionalCorpCodes && this.reservation.corporateCode && (additionalCorpCodes.indexOf(this.reservation.corporateCode) > -1)) {
        $('.confirmation-component').addClass('hold-rate');
        $('.confirmation-title').find('.headline-e:not(.title-hold-rate)').hide();
        $('.res-sum-modify-cancel .modify-button').hide();
      }

      if (($_PAGE_.hasClass('confirmation-page') && ($('.page-content').hasClass('reservation-found') || $('.page-content').hasClass('reservation-canceled'))) && (this.reservation.rateCode && isSMORate(this.reservation.rateCode))) {
        $('.confirmation-component').addClass('hold-rate');
        $('.res-sum-modify-cancel .modify-button').hide();
      }
      this.populateRateInformation();

      CancelReservation.enableCancel();

      this.handleContentModule();
      this.bindLinks();
      this.handleAnalytics();
      this.catchBackButtonClicks();
      this.handleAffinionBanner();
      this.handleCaesarsRewards();

      //WRCCBarclay changes
      if(CookieHandler.cidAuthenticated() && isWRCCHolder() && isGoFreeRate(this.reservation.rateCode)){
          this.$0 = $('.page-content,footer');
          this.$ratePlanRibbon = this.$0.find('.discount-ribbon');
          this.$totalpoints = this.$0.find('.points-total .val');
			if (this.$ratePlanRibbon.length) {
			this.$ratePlanRibbon.removeClass('hidden');
			}
			if (this.$totalpoints.length && this.reservation.totalPoints) {
				this.$totalpoints.html('<strike>' + this.reservation.totalPoints + '</strike>');
			}
      }
    }
}

  populateRateInformation() {
    $('.title-reserved span,.title-cancelled span').addClass('aem-token customer-first-name');

    if (this.reservation.taxList.match(/[\[{].*[}\]]/)) {
      RateDetails.fillTaxes(JSON.parse(this.reservation.taxList), this.reservation.currencyCode, '', getBrand(true),this.reservation,'summary');
    } else {
      RateDetails.fillTaxes('', this.reservation.currencyCode, this.reservation.taxList, getBrand(true),this.reservation,'summary');
    }

    if ((this.reservation.dateRateMap.toString().length > 0) && _isNotNull(this.reservation.dateRateMap)) {
      let dateRateMap = JSON.parse(this.reservation.dateRateMap);
      if (typeof dateRateMap === 'object' && !($.isEmptyObject(dateRateMap))) {
        RateDetails.updateRateBreakdown(dateRateMap, this.reservation.currencyCode);
      } else {
        $('.rate-breakdown').hide();
      }
    } else {
      $('.rate-breakdown').hide();
    }
  }

  handleContentModule() {
    if (exists($('.content-module-component'))) {
      let aemRefBrand = this.reservation.referringBrand,
        contentModule = $('.content-module-component');
      $(contentModule).each((i, el) => {
        let contentModuleBrand = $(el).data('brand-codes');
        if (_isNotNull(contentModuleBrand)) {
          (contentModuleBrand.match(aemRefBrand)) ? $(el).show() : $(el).hide();
        } else {
          $(el).show();
        }
      });
    }
  }

  bindLinks() {
    $('.search-new-hotel').click(() => {
      window.location.replace(window.location.pathname.replace(/\/reservation.*/, ''));
    });

    if (roomrate_redirect_url && overview_propertyId) {
      $('.book-another-link').click(() => {
        location.href = roomrate_redirect_url + '?hotel_id=' + overview_propertyId;
      });
    } else {
      $('.book-another-link').remove();
    }

    $('.modify-button a').click((e) => {
      let data = { ...this.reservation , packages: JSON.stringify(this.reservation.packages || {}), customerPhoneNum: this.reservation.customerPhone};
      delete data['customerPhone'];
      e.preventDefault();
      let localeFixed = getLocaleUrlToken(); //DAI-462 : EN-CA Validations
      $('#modifyFormForAEMPost').attr('action', localeFixed + '/' + getBrandName() + '/reservation/modify');
      ReservationHandler.postReservationDataToAEM(data, $('#modifyFormForAEMPost'), 'confirmation');
    });

    $('.export-to-cal').click((e) => {
      e.preventDefault();
      this.createCalendarEvent();
    });

    $('.print-reservation').click((e) => {
      e.preventDefault();
      window.print();
    });

    // load uplift disclaimer phone number click event
    BnplAnalytics.trackUpliftDisclaimerPhoneClick();

  }

  createCalendarEvent() {
    function _ensureTwo(value) {
      return (value < 10 ? '0' : '') + value;
    }

    function formatcalDateTime(dateTime, local) {
      return (!dateTime ? '' : (local ?
        '' + dateTime.getFullYear() + _ensureTwo(dateTime.getMonth() + 1) +
        _ensureTwo(dateTime.getDate()) + 'T' + _ensureTwo(dateTime.getHours()) +
        _ensureTwo(dateTime.getMinutes()) + _ensureTwo(dateTime.getSeconds()) :
        '' + dateTime.getUTCFullYear() + _ensureTwo(dateTime.getUTCMonth() + 1) +
        _ensureTwo(dateTime.getUTCDate()) + 'T' + _ensureTwo(dateTime.getUTCHours()) +
        _ensureTwo(dateTime.getUTCMinutes()) + _ensureTwo(dateTime.getUTCSeconds()) + 'Z'));
    }

    function getCalString(event) {
      var limit75 = function(text) {
        var out = '';
        while (text.length > 75) {
          out += text.substr(0, 75) + '\n';
          text = ' ' + text.substr(75);
        }
        out += text;
        return out;
      };
      return 'BEGIN:VCALENDAR\n' +
        'VERSION:2.0\n' +
        'PRODID:jquery.icalendar\n' +
        'METHOD:PUBLISH\n' +
        'BEGIN:VEVENT\n' +
        'UID:' + new Date().getTime() + '@' +
        (window.location.href.replace(/^[^\/]*\/\/([^\/]*)\/.*$/, '$1') || 'localhost') + '\n' +
        'DTSTAMP:' + formatcalDateTime(new Date()) + '\n' +
        (event.url ? limit75('URL:' + event.url) + '\n' : '') +
        (event.contact ? limit75('MAILTO:' + event.contact) + '\n' : '') +
        limit75('TITLE:' + event.title) + '\n' +
        'DTSTART:' + formatcalDateTime(event.start) + '\n' +
        'DTEND:' + formatcalDateTime(event.end) + '\n' +
        (event.summary ? limit75('SUMMARY:' + event.summary) + '\n' : '') +
        (event.description ? limit75('DESCRIPTION:' + event.description) + '\n' : '') +
        (event.location ? limit75('LOCATION:' + event.location) + '\n' : '') +
        (event.recurrence ? event.recurrence + '\n' : '') +
        'END:VEVENT\n' +
        'END:VCALENDAR';
    }

    let calevent = {},
      fdate = getDateFromDateString(this.reservation.checkInDate),
      tdate = getDateFromDateString(this.reservation.checkOutDate);

    calevent.title = 'Reservation';

    calevent.location = property_address + ', ' + property_city_name + ', ' + property_state_code + ', ' + property_country_code + ', ' + property_postal_code;
    let overview_propertyNameForCalendar = overview_propertyName.replace(/&amp;+/gi,'and').replace(/&#39;+|&#34;+/gi,'');
    calevent.description = 'Your stay at ' + overview_propertyNameForCalendar;

    calevent.start = new Date(fdate.getFullYear(), fdate.getMonth(), fdate.getDate(), 0, 0, 0);
    calevent.end = new Date(tdate.getFullYear(), tdate.getMonth(), tdate.getDate(), 0, 0, 0);

    calevent.summary = 'Hotel Reservation @ ' + overview_propertyNameForCalendar;

    let loc = '/BWSServices/services/booking/calendar?content=' + encodeURIComponent(getCalString(calevent));
    window.location.href = loc;

    $('.export-to-cal').attr('disabled','disabled');
  }

  handleAnalytics() {
    Analytics.setConfirmationAnalyticsData(this.reservation);
    Analytics.fireAnalyticsPageEvent();
  }

  catchBackButtonClicks() {
    // Back button code for confirmation page
    if (!(document.referrer.match(/wyndham\-rewards/))) {
      window.onload = () => {
        // Push 2 states to stack. One for current page, one for if user clicks back button
        history.pushState('catch-back', null, null);
        history.pushState('currentstate', null, null);
        window.addEventListener('popstate', (e) => {
          // If user goes back, state will become 'catch-back'.
          // If user refreshes or logs out, state will be null.
          if (e.state === 'catch-back') {
            // Currentstate has been removed from history by back click. Push replacement to stack and alert user of error.
            history.pushState('newcurrentstate', null, null);
            alert($('.confirmation-component').attr('data-back-button-prevention-text'));
          }
        });
      };
    }
  }
  handleAffinionBanner() {
    if (exists($('.affinion-banner')) && !isMobileWidth()) {
      $('#affinionBanner').modal({
        show: true,
        remote: false
      });
    }
  }

  handleCaesarsRewards() {
    const notification = $('#caesars-notify');
    const caesarsEnrollFlag = sessionStorage.getItem('caesarsEnrollFlag') || false;
    if(caesarsEnrollFlag) {
      console.info('Showing Caesars Message');
      notification.css('display','block');
    }
    // Clear the flag in case we book again
    sessionStorage.removeItem('caesarsEnrollFlag');
  }

}

export default new ConfirmationPage();
