import { $_PAGE_ } from '../vars.js';

const timeOutDelay = 2000;

// analytics script

function init() {
  try {
    setTimeout(() => {
      const isBookingPage = $_PAGE_.hasClass('booking-page');
      if (isBookingPage) {
        attachEventHandlerToLearnMoreClick();
      }
    }, parseInt(timeOutDelay));
  } catch (error) {
    console.error('Error on barclays-analytics.js: ', error);
  }
}

function attachEventHandlerToLearnMoreClick() {
  const bookingCreditCardPromoLearnMore = document.querySelector('a.analytics-learn-more');
  if (bookingCreditCardPromoLearnMore) {
    bookingCreditCardPromoLearnMore.removeEventListener('click', firePromoLearnMoreClickEvent);
    bookingCreditCardPromoLearnMore.addEventListener('click', firePromoLearnMoreClickEvent);
  }
}

function firePromoLearnMoreClickEvent() {
  satelliteTracker('creditCardLearnMoreClicks');
}

/**
 * Sends the analytics data using satellite tracker.
 *
 * This method sends the tracked data to the analytics service, which processes the data and provides insights based on it.
 *
 * @param {string} directCall - The name of the direct call.
 */
function satelliteTracker(directCall) {
  if (window._satellite && directCall) {
    window._satellite.track(directCall);
  }
}

// initialize
init();
