class ModifiedPromoCarousel {
  constructor() {
    this.instances = $('.modified-promo-carousel');
    if (this.instances.length > 0) {
      this.initCarousel();
      window.at.wait(this.instances, (root) => {
        this.initCarousel(root);
      });
    }
  }
  initCarousel(root) {
    $.each($('.mpc-slick-carousel', root), (i, el) => {
      let componentContainer = $(el).parent(),
        arrowContainer = componentContainer.find('.mpc-arrow-controls');

      $(el).on('init', (event, slick) => {
        this.showHideDots(slick);
        this.switchCarouselBGClasses(event.currentTarget, slick.$slides[slick.currentSlide].classList);
      });
      $(el).slick({
        dots: true,
        adaptiveHeight: true,
        appendArrows: $(arrowContainer),
        rows: 0,
        responsive: [{
          breakpoint: 1199,
          settings: {
            arrows: false
          }
        }]
      });
      $(el).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        this.switchCarouselBGClasses(event.currentTarget, slick.$slides[nextSlide].classList);
      });
      $(el).on('breakpoint', (event, slick, breakpoint) => {
        this.showHideDots(slick);
      });
    });
  }
  showHideDots(slick) {
    let breakpoint = slick.activeBreakpoint || Math.max.apply(null, slick.breakpoints);
    if (slick.$dots && breakpoint) {
      let slidesToShow = slick.breakpointSettings[breakpoint].slidesToShow || 1;
      if (slick.$slides.length > slidesToShow) {
        slick.$dots.show();
      } else {
        slick.$dots.hide();
      }
    }
  }
  switchCarouselBGClasses(carousel, classList) {
    var classes = classList;

    $(carousel).parent()
      .removeClass((i, css) => {
        return (css.match(/\bbg-\S+/g) || []).join(' ');
      });

    $.each(classes, (i, e) => {
      if (e.indexOf('bg-') == 0) {
        $(carousel).parent()
          .addClass(e);
      }
    });
  }
}

export default new ModifiedPromoCarousel();
