import { $_PAGE_ } from '../vars.js';
/**
 * Class representing TPD BWS Analytics.
 *
 * This class is responsible for tracking user interactions related to the TPD BWS,
 * and it sends the tracked data to an analytics service.
 */
class TPD_BWSAnalytics {
  /**
   * Create an instance of TPD_BWSAnalytics.
   */
  constructor() {
    this.isSearchResultsPage = $_PAGE_.hasClass('search-results-page');
    this.isRoomsRatesPage = $_PAGE_.hasClass('rooms-rates-page');
    this.isPropertyDetailsPage = $_PAGE_.hasClass('property-page');
    this.isBookingPage = $_PAGE_.hasClass('booking-page');
    this.isConfirmationPage = $_PAGE_.hasClass('confirmation-page');
    this.isPackagesPage = $_PAGE_.hasClass('packages-page');
    this.timeOutDelay = 3000;

    this.srpRunOnce = true;

    this.CONST = {
      searchResultsPage: 'search results page',
      propertyDetailsPage: 'property details page',
    };

    // window.digitalData.totalPriceDisplay = window.digitalData.totalPriceDisplay || {};
  }

  /**
   * Sets up a mutation observer to detect changes in the DOM.
   *
   * This method allows the object to react to changes in the page's structure,
   * including the addition of elements that need to be tracked.
   */
  setupMutationObserver(selector, mutationCaller, childList = true, subtree = false) {
    try {
      const observer = new MutationObserver((mutations) => {
        if (mutations.length > 0) {
          if (mutationCaller === this.CONST.propertyDetailsPage) {
            this.attachPropertyDetailsClickEvent();
          }
        }
      });

      const targetNode = document.querySelector(selector);
      const config = { childList, subtree };
      observer.observe(targetNode, config);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Sends the analytics data using satellite tracker.
   *
   * This method sends the tracked data to the analytics service, which processes the data and provides insights based on it.
   *
   * @param {string} directCall - The name of the direct call.
   */
  satelliteTracker(directCall) {
    if (window._satellite && directCall) {
      window._satellite.track(directCall);
    }
  }

  /**
   * Attach click on "Total for Stay" on property details page (Featured rooms)
   */
  attachPropertyDetailsClickEvent(calledFrm) {
    try {
      const stayTotalEl = document.querySelectorAll('a.stay-total');
      if (stayTotalEl.length) {
        stayTotalEl.forEach((el, i) => {
          el.removeEventListener('click', this.firePropertyDetailsClickEvent.bind(this));
          el.addEventListener('click', this.firePropertyDetailsClickEvent.bind(this));
        });
      }
    } catch (error) {
      error.errorMessage = 'Could not assign click event to "Total for Stay"';
      throw error;
    }
  }

  firePropertyDetailsClickEvent() {
    this.satelliteTracker('tpdRateDetailsClick');
  }

  /**
   * Attach click on "Total for Stay" on rooms-rates page and booking page
   */
  attachRoomsRatesClickEvent() {
    try {
      setTimeout(() => {
        const stayTotalEl = document.querySelectorAll('a.stay-total');
        if (stayTotalEl.length) {
          stayTotalEl.forEach((el, i) => {
            el.removeEventListener('click', this.fireRoomsRateClickEvent.bind(this));
            el.addEventListener('click', this.fireRoomsRateClickEvent.bind(this));
          });
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      error.errorMessage = 'Could not assign click event to "Total for Stay"';
      throw error;
    }
  }

  fireRoomsRateClickEvent() {
    this.satelliteTracker('tpdRateDetailsClick');
  }

  /**
   * Attach click on "Cancellation & Rate Details" on booking page, package page and confirm page
   */
  attachCancellationAndRateDetailsClickEvent() {
    try {
      setTimeout(() => {
        const rateDetailsEl = document.querySelector('a.rate-details-link');
        const rateDetailsAuthoredEl = document.querySelector('a.rate-details-link-authored');
        const totalForStayEL = document.querySelector('#bb-total-for-stay.rate-details-link'); // for total for stay click in booking page

        if (rateDetailsEl) {
          rateDetailsEl.addEventListener('click', () => {
            this.satelliteTracker('tpdCancellationRoomRateDetailsClick');
          });
        }

        if (rateDetailsAuthoredEl) {
          rateDetailsAuthoredEl.addEventListener('click', () => {
            this.satelliteTracker('tpdCancellationRoomRateDetailsClick');
          });
        }

        if (totalForStayEL) {
          totalForStayEL.addEventListener('click', () => {
            this.satelliteTracker('tpdRateDetailsClick');
          });
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      error.errorMessage = 'Could not assign click event to "Cancellation & Rate Details"';
      throw error;
    }
  }

  /**
   * Initializes the class instance by setting up required events and configurations.
   *
   * This method checks if the current page is one of the TPD pages.
   * If so, it attaches click events to relevant elements and updates digitalData properties
   */
  init() {
    try {
      if(this.isRoomsRatesPage) {
        this.attachRoomsRatesClickEvent();
      }
      if (this.isPropertyDetailsPage) {
        this.setupMutationObserver('.rooms-slide.by-room', this.CONST.propertyDetailsPage);
      }
      if (this.isBookingPage || this.isConfirmationPage) {
        this.attachCancellationAndRateDetailsClickEvent();
      }
      if(this.isSearchResultsPage) {
        if(sessionStorage.getItem('tpdExperience')) {
          // remove this session var to avoid inconsistent ever36 firing
          sessionStorage.removeItem('tpdExperience');
        }
      }
    } catch (error) {
      console.error('Error on tpd-bws-analytics.js', error);
    }
  }
}

// Instantiate the TPD_BWSAnalytics class and set up event listeners
let tpdBwsAnalytics = new TPD_BWSAnalytics();
tpdBwsAnalytics.init();
