import { exists, isMobileWidth } from '../../base/dom-utils.js';
import GoogleAPI from '../../base/google-api-handler.js';
import { _isNumber, _isWeakTrue, CookieHandler } from '../../base/utils.js';
import { overview_lat, overview_long, overview_brandTier, overview_brandId, brand_id, brandMapColors, hashId } from '../../base/vars.js';
import { lazyLoadImageInViewport, lazyLoadMapInViewport } from '../../base/lazy-load.js';

class UUMap {
  constructor(className = '.uu-wide-map') {
    if (exists(className)) {
      let elements = [].slice.call(document.querySelectorAll(className));
      this.dim = document.getElementById('dimensions');
      this.mapValues = {};
      this.mapUrl = $('.directions-btn a').attr('href') || 'https://www.google.com/maps';
      this.staticMap = _isWeakTrue(window.enableDynamicMaps) || false;
      this.callbackMaps = this.callbackMaps.bind(this);
      let isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
      if(!isProxy) {
        if (this.staticMap) {
          elements.forEach((element, observer) => {
            lazyLoadMapInViewport(element, this.callbackMaps);
          });
        } else {
          elements.forEach(element => {
            this.getPropertyLatLong();
            this.renderMap(element);
          });
        }
      } else {
        $('#mapDiv').removeClass('loading');
      }
    }
  }

  callbackMaps(element, observer) {
	this.getPropertyLatLong();
    this.renderMap(element);
    observer.unobserve(element);
  }

  getPropertyLatLong() {
    if (this.dim) {
      this.mapValues = {
        latitude: this.dim.dataset.overrideLatitude,
        longitude: this.dim.dataset.overrideLongitude
      };
    } else {
      if (typeof overview_lat !== 'undefined' &&
        typeof overview_long !== 'undefined' && overview_lat && overview_long) {
        this.mapValues = {
          latitude: overview_lat,
          longitude: overview_long
        };
      }
    }
  }

  renderMap(element) {
    if (typeof this.mapValues.latitude !== 'undefined' &&
      typeof this.mapValues.longitude !== 'undefined' && this.mapValues.latitude && this.mapValues.longitude) {
      let zoom = parseInt($('.uu-wide-map').data('zoom')),
        googleMapsUrl;
      if (!_isNumber(zoom) || zoom <= 0) {
        if ($('.uu-wide-map-component .col-md-7').length > 0) {
          zoom = 12;
        } else {
          zoom = 15;
        }
      }
        
      if (this.staticMap) {
        if ($('.uu-wide-map').hasClass('loading')) {
          this.loadDynamicMap(zoom, element);
        }
      } else {
        this.insertStaticMap(zoom);
        element.classList.remove('loading');
      }
      googleMapsUrl = this.mapUrl;
      googleMapsUrl += '/dir//' + encodeURIComponent($($('.uu-map-address')[0]).text()
        .trim()) + '/';
      googleMapsUrl += '@' + this.mapValues.latitude + ',' + this.mapValues.longitude + ',' + zoom + 'z';
      $('.directions-btn a').attr('href', googleMapsUrl);
    }
  }

  getMapPinPath(brandId) {
    let mapPinPath = `/etc/designs/wyndham-tablet/images/map-pins/google/map_pin_${brandId.toLowerCase()}.png`;

    if (hashId && hashId.length > 0) {
      mapPinPath = `${mapPinPath}?${hashId}`;
    }

    return mapPinPath;
  }

  getStaticMapImg(brandId, latitude, longitude, zoom, size, className = '') {
    const url = 'https://maps.googleapis.com/maps/api/staticmap';

    const markers = [
      `color:${brandMapColors[brandId.toLowerCase()]}`,
      `label:${brandId.substring(0, 1).toUpperCase()}`,
      `icon:${window.domainUrl}${this.getMapPinPath(brandId)}`,
      `${latitude},${longitude}`
    ].join('|');

    const params = [
      `key=${window.gKey}`,
      `markers=${markers}`,
      `zoom=${zoom}`,
      `size=${size}`
    ].join('&');

    let staticMapImg = document.createElement('img');

    staticMapImg.className = `static-map lazy-load lazy-load-viewport ${className}`;
    staticMapImg.setAttribute('id', 'static-map');
    staticMapImg.setAttribute('src', `${url}?${params}`);

    return staticMapImg;
  }

  insertStaticMap(zoom) {
	let src = '', brandId;
	let locationOrig = window.domainUrl;
	brandId = (brand_id) ? brand_id : (overview_brandTier) ? overview_brandTier : overview_brandId;
	let mapPinPath = '/etc/designs/wyndham-tablet/images/map-pins/google/map_pin_' + brandId + '.png';

	let staticMapImg = document.createElement('img');
	staticMapImg.className = 'static-map lazy-load lazy-load-viewport';
	staticMapImg.setAttribute('id', 'static-map');
	src = 'https://maps.googleapis.com/maps/api/staticmap?';
	src = src + 'key=' + window.gKey;
	//start marker
	src = src + '&markers=';
	src = src + 'color:' + brandMapColors[brandId.toLowerCase()];
	src = src + '|label:' + brandId.substring(0,1).toUpperCase();
	src = src + '|icon:' + locationOrig;
	mapPinPath = (hashId && hashId.length > 0) ? mapPinPath + '?' + hashId : mapPinPath;
	src = src + mapPinPath;
	src = src + '|' + this.mapValues.latitude + ',' + this.mapValues.longitude;
	src = src + '&zoom=' + zoom;
	src = src + '&size=' + this.calcMapWidth();

	staticMapImg.setAttribute('data-src', src);
	document.getElementById('mapDiv').appendChild(staticMapImg);
	lazyLoadImageInViewport('img.static-map.lazy-load');

	window.addEventListener("orientationchange", () => {
      setTimeout(() => {
		let args = src.split("&");
		args[args.length - 1] = 'size=' + this.calcMapWidth();
		src = "";
		for(let i in args) {
          src = src + args[i] + "&";
		}
		src = src.slice(0,-1);
		staticMapImg.setAttribute('data-src', src);
		document.getElementById('mapDiv').appendChild(staticMapImg);
		lazyLoadImageInViewport('img.static-map.lazy-load');
      });
	});
  }

  calcMapWidth() {
	let uumapaddress,
        finalwidth;
	if($('.uu-map-address').length) {
      uumapaddress = Math.round($('.uu-map-address').closest('.main-content-width').width());
	} else {
      uumapaddress = Math.round($('.uu-wide-map-component').closest('.container-fluid').width() - 200);
	}
	if(isMobileWidth()) {
      return uumapaddress + 'x230';
	} else {
      finalwidth = Math.round(uumapaddress - (uumapaddress / 10));
      return finalwidth + 'x600';
	}
  }

  loadDynamicMap(zoom, element) {
    GoogleAPI.loadedPromise.then(() => {
      let latlng = new window.google.maps.LatLng(this.mapValues.latitude, this.mapValues.longitude);

      let map = new window.google.maps.Map(document.getElementById('mapDiv'), {
        center: latlng,
        zoom: zoom,
        styles: GoogleAPI.mapStyle,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        clickableIcons: false
      });
      GoogleAPI.buildNearMeControl(map, true);
      let marker = new UUMapMarker({
        latlng: latlng
      });
      marker.setMap(map);
      element.classList.remove('loading');
    });
  }
}

class UUMapMarker {
  constructor(data) {
    this.latlng = data.latlng;
    this.overlayView = new window.google.maps.OverlayView();
    this.overlayView.draw = this.draw.bind(this);
    this.overlayView.remove = this.remove.bind(this);
  }

  draw() {
    let div = this.div,
      point,
      panes,
      span;

    if (!div) {
      div = this.div = document.createElement('div');
      div.className = 'map-base-pin map-icon-pin highlight';
      span = document.createElement('span');
      span.className = 'map-icon';
      div.appendChild(span);
      panes = this.overlayView.getPanes();
      panes.overlayImage.appendChild(div);
    }
    point = this.overlayView.getProjection().fromLatLngToDivPixel(this.latlng);

    if (point) {
      let $div = $(div);
      div.style.left = (point.x - ($div.outerWidth() / 2)) + 'px';
      div.style.top = (point.y - ($div.outerHeight() + 6)) + 'px';
    }
  }
  remove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  setMap(map) {
    this.overlayView.setMap(map);
  }
}

export default new UUMap();
