import { exists, ResizeHandler } from '../base/dom-utils.js';

class SpecialPromo {
  constructor() {
    this.initCarousel();
    ResizeHandler.addResizeEndFn(() => this.repositionIndicators());
    this.repositionIndicators();
    this.bindCollapsibleContainerClick();
  }
  bindCollapsibleContainerClick() {
    // needed for collapsible container to recalculate height
    $('.cc-show').click(() => {
      this.repositionIndicators();
    });
  }
  initCarousel() {
    if (exists($('.promo-carousel .carousel'))) {
      $('.promo-carousel .carousel').each((i, el) => {
        // Disable touchevents for carousels with 1 element
        let $carousel = $(el),
          swipe = ($carousel.find('.item').length > 1) ? 50 : false;

        $carousel.carousel({
          interval: 0,
          swipe: swipe
        });

        this.onSlide($carousel);
        this.onControlClick($carousel);
      });
    }
  }
  onSlide($carousel) {
    $carousel.on('slide.bs.carousel', (e) => {
      let activeSlide = $(e.target).find('.carousel-inner > .item.active'),
        activeSlideId = activeSlide.attr('id'),
        nextSlide = $(e.relatedTarget),
        nextSlideId = nextSlide.attr('id');

      $carousel.find('.carousel-indicators').find('[aria-controls=' + nextSlideId + ']')
        .attr({
          'aria-selected': 'true',
          'tabindex': '0'
        });

      $carousel.find('.carousel-indicators').find('[aria-controls=' + activeSlideId + ']')
        .attr({
          'aria-selected': 'false',
          'tabindex': '-1'
        });
    });
  }
  onControlClick($carousel) {
    // Focus to corresponding arrow in next slide
    $carousel.find('.carousel-control').on('click', (e) => {
      let arrowClicked = $(e.currentTarget);

      $carousel.one('slid.bs.carousel', () => {
        let activeSlide = $(e.relatedTarget);

        if (arrowClicked.hasClass('left')) {
          activeSlide.find('a.carousel-control.left').focus();
        } else if (arrowClicked.hasClass('right')) {
          activeSlide.find('a.carousel-control.right').focus();
        }
      });
    });
  }
  repositionIndicators() {
    $('.promo-carousel').each((i, el) => {
      $(el).find('.item')
        .css({
          'paddingTop': $(el).find('.section-title-component')
            .outerHeight(),
          'paddingBottom': $(el).find('.carousel-indicators')
            .outerHeight()
        });
    });
  }
}

export default new SpecialPromo();
