import {
  exists,
  getWindowHeight,
  isDesktopWidth,
  isMobileWidth
} from '../base/dom-utils.js';
import {lazyLoadImage} from '../base/lazy-load.js';
import { checkModalHeightAgainstWindow } from '../base/lightbox.js';
import { _isWeakTrue, getName, EventHandler } from '../base/utils.js';
import { $_BOOKING_BAR_MINI_FORM } from '../base/vars.js';

class PhotoGallery {
  constructor(galleryId) {
    this.galleryId = galleryId;
    this.$propLightBox = $(`#${this.galleryId}`);
    this.carouselId = this.$propLightBox.find('.carousel').attr('id');
    this.$carousel = $(`#${this.carouselId}`);
    this.$carouselItems = this.$carousel.find('.item');
    this.numItems = this.$carouselItems.length;
    this.$bookNowButton = this.$propLightBox.find('button.book-now-btn');
    this.currentItem = 0;

    this.bindUNAP();
    this.bindBookNowButton();
    this.initCarousel();
    this.bindCategoryLinks();
    this.bindGalleryShow();
    this.bindCarouselSlideEvents();
    this.onUpdateMobileDropdown();
    this.onMobileImageClick();
  }

  bindUNAP() {
    EventHandler.one(EventHandler.UNAP.updated, () => {
      $('.photo-gallery-lightbox .main-header').text(getName());
    });
  }

  bindBookNowButton() {
    if (exists(this.$bookNowButton) && exists($_BOOKING_BAR_MINI_FORM)) {
      this.$bookNowButton.click((e) => {
        e.preventDefault();

        $_BOOKING_BAR_MINI_FORM.submit();
      });
    } else if (exists(this.$bookNowButton)) {
      this.$bookNowButton.remove();
    }

    let roomsAndRatesUrl = window.location.href.replace('overview', 'rooms-rates');
    this.$propLightBox.find('.book-now-btn').attr('href', roomsAndRatesUrl);
  }

  initCarousel() {
    this.$carousel.carousel({
      pause: true,
      interval: false
    });
  }

  bindCategoryLinks() {
    $('.control-panel__list-section li > a').click((e) => {
      this.currentItem = $(e.currentTarget).data('slide-to');
      this.$carousel.trigger('slide.bs.carousel');
    });
  }

  bindGalleryShow() {
    this.$propLightBox.on('show.bs.modal', () => {
      this.currentItem = this.$carousel.data('start-pos') || 0;
      this.$carousel.trigger('slide.bs.carousel');
      this.changeCaption();
      this.changeCategory();
      if (isDesktopWidth()) {
        if (getWindowHeight() > 680) {
          $('.modal-dialog.photo-gallery-lightbox .modal-content').css({
            height: 720 + 'px'
          });
        } else {
          $('.modal-dialog.photo-gallery-lightbox .modal-content').css({
            height: 620 + 'px'
          });
        }
      }
    });

    //DIG-1572 Adding on first load function
    this.$propLightBox.on('shown.bs.modal', () => {
      if (isDesktopWidth()) {
        this.adjustHeightAfterLoad();
      }
    });
  }

  bindCarouselSlideEvents() {
    this.$carousel.on('slide.bs.carousel', (event) => {
      let newActiveSlide = this.$carouselItems.index($(event.relatedTarget));
      this.currentItem = (newActiveSlide >= 0) ? newActiveSlide : this.currentItem;

      this.handleSectionHighlight();
      this.handleSectionDropdown();
      this.loadImages();
    });


    this.$carousel.on('slid.bs.carousel', () => {
      this.changeCaption();
      this.changeCategory();
      //DIG-1572 adjust height after loading image
      if (isDesktopWidth()) {
        this.adjustHeightAfterLoad();
      }
    });
  }

  adjustHeightAfterLoad() {
    let $dialog = this.$propLightBox.find('.modal-dialog');

    //DIG-1572 condition that executes when the image size is small and also adjusts the modal in the center of the screen
    $dialog.addClass('modal-underflow');
    let dialogHeight = $dialog.outerHeight(),
      mar_adj = (getWindowHeight() - dialogHeight);

    //Adjust the height of the modal
    let ctr_height = this.$propLightBox.outerHeight(),
      mar_adj_ctr = dialogHeight - ctr_height;
    this.$propLightBox.css('margin-top', '0px'); // reset margin -top to override negative margins
    // if the image does not load then calculate after 800ms
    if (ctr_height < 100) {
      setTimeout(() => {
        let $dialog = $('.uu-new-photo-gallery .photo-gallery-lightbox.modal-dialog'),
          $car_ctr = $dialog.find('.carousel-container'),
          car_ctr_height = $car_ctr.outerHeight(),
          mar_adj_ctr = $dialog.outerHeight() - car_ctr_height;

        if ((mar_adj_ctr > 70) && (car_ctr_height > 100)) {
          $car_ctr.css('margin-top', (mar_adj_ctr - 70) / 2 + 'px');
        } else {
          $car_ctr.css('margin-top', '0px');
        }
      }, 800);
    }
    //70px would be the padding top and bottom 35px each
    if ((mar_adj_ctr > 70) && (ctr_height > 100)) {
      this.$propLightBox.css('margin-top', (mar_adj_ctr - 70) / 2 + 'px');
    } else {
      this.$propLightBox.css('margin-top', '0px');
    }
    //Center the modal
    if (mar_adj > 0) {
      $dialog.css('margin-top', mar_adj / 2 + 'px');
    }
  }

  changeCaption() {
    if ($('#' + this.carouselId + ' .item').hasClass('active') && _isWeakTrue($('#' + this.carouselId + ' .item.active').data('display-caption'))) {
      let caption = $('#' + this.carouselId + ' .carousel-inner .item.active').attr('data-image-caption');
      $('.photo-gallery-modal .carousel-caption').html(caption);
    } else {
      $('.photo-gallery-modal .carousel-caption').html('');
    }
  }

  changeCategory() {
    let selText = $('.mobile-control-panel__list-scetion .current-section').text();
    $('.current-section').closest('div')
      .find('button[data-toggle="dropdown"]')
      .html(selText + ' <span class="caret"></span>');
  }

  handleSectionHighlight() {
    let listItems = this.$propLightBox.find(' .control-panel__list-section li > a');
    // Clear the current section.
    listItems.removeClass('current-section');
    // Flag the current section. Index should be between scroll-to values.
    // If we've reached the last element (nextIndex undefined) the image must be
    // in that section.
    for (let i = 0; i < listItems.length; i++) {
      let currentIndex = $(listItems[i]).data('slide-to');
      let nextIndex = $(listItems[i + 1]).data('slide-to');
      if (!nextIndex || (this.currentItem >= currentIndex && this.currentItem < nextIndex)) {
        $(listItems[i]).addClass('current-section');
        break;
      }
    }
  }

  handleSectionDropdown() {
    let listItems = this.$propLightBox.find(' .mobile-control-panel__list-scetion li > a');
    listItems.removeClass('current-section');
    for (let i = 0; i < listItems.length; i++) {
      let currentIndex = $(listItems[i]).data('slide-to');
      let nextIndex = $(listItems[i + 1]).data('slide-to');
      if (!nextIndex || (this.currentItem >= currentIndex && this.currentItem < nextIndex)) {
        $(listItems[i]).addClass('current-section');
        break;
      }
    }
  }

  loadImages() {
    EventHandler.on('img.load', checkModalHeightAgainstWindow, $(this.$carouselItems.find('img').get(this.currentItem)));

    lazyLoadImage($(this.$carouselItems.find('img').get(this.currentItem)));
    this.loadPreviousImage();
    this.loadNextImage();
  }

  loadPreviousImage() {
    let loadSlide = (this.currentItem - 1 < 0) ? this.numItems - 1 : this.currentItem - 1;
    lazyLoadImage($(this.$carouselItems.find('img').get(loadSlide)));
  }

  loadNextImage() {
    let loadSlide = (this.currentItem + 1 >= this.numItems) ? 0 : this.currentItem + 1;
    lazyLoadImage($(this.$carouselItems.find('img').get(loadSlide)));
  }

  onUpdateMobileDropdown() {
    /*wyn-3969*/
    $('.mobile-control-panel__list-scetion > li').click((e) => {
      let selText = $(e.currentTarget).text();
      $(e.currentTarget).closest('div')
        .find('button[data-toggle="dropdown"]')
        .html(selText + ' <span class="caret"></span>');
    });
  }

  onMobileImageClick() {
    if (isMobileWidth()) {
      $('.modal-dialog.photo-gallery-lightbox .item img').click(() => {
        $('.control-panel, .carousel-caption, .control-panel__header-section .main-header, .modal-header').toggle();
        let aVisible = $('.modal-header').css('display');
        if (aVisible == 'none') {
          $('.modal-dialog.photo-gallery-lightbox .item').addClass('no-bg');
        } else {
          $('.modal-dialog.photo-gallery-lightbox .item').removeClass('no-bg');
        }
      });
    }

  }
}

let galleryList = {};
$('.uu-new-photo-gallery').each((i, el) => {
  galleryList[el.id] = new PhotoGallery(el.id);
});

export const photoGalleries = galleryList;

export default PhotoGallery;
