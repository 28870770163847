import { exists } from '../base/dom-utils.js';
import { UserHandler } from '../base/session-handler.js';
import { EventHandler } from '../base/utils.js';
import WrNonParticipatingBrandConfig from '../base/aem-configs/wr-non-participating-brands-config.js';

class WyndhamRewardsPromoBanner {
  constructor() {
    if (exists('.rewards-promo-container')) {
      this.rewardsPromoElement = $('.rewards-promo');
      EventHandler.one(EventHandler.profile.fetched, () => {
        this.hideIfAuthenticated();
        this.hideIfNotParticipatingToWyndhamRewards();
        // this.bindSignInLink();
      });
    }
  }

  hideIfNotParticipatingToWyndhamRewards() {
    if (!WrNonParticipatingBrandConfig.isCurrentBrandParticipatingToWR()) {
      this.rewardsPromoElement.remove();
    }
  }

  hideIfAuthenticated() {
    if (UserHandler.isWRLoggedIn()) {
      this.rewardsPromoElement.remove();
    }
  }

  // bindSignInLink() {
  //   $('.rewards-promo .login-link').click(() => {
  //     $('#wr-login').modal('show');
  //     return false;
  //   });
  // }

}
export default new WyndhamRewardsPromoBanner();
