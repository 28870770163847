import { $_PAGE_ } from '../base/vars';

const timeOutDelay = 2000;

function init() {
  try {
    setTimeout(() => {
      const isBookingPage = $_PAGE_.hasClass('booking-page');
      if (isBookingPage) {
        attachEventHandlerToLearnMoreClick();
      }
    }, parseInt(timeOutDelay));
  } catch (error) {
    console.error('Error on booking-page.js: ', error);
  }
}

function attachEventHandlerToLearnMoreClick() {
  const learnMoreElem = document.querySelector('a.learn-more');
  const iElem = document.querySelector('.open-modal-links a i');
  if (learnMoreElem) {
    iElem.style.display = 'inline-block';
    learnMoreElem.addEventListener('click', () => {
      const ariaExpanded = learnMoreElem.getAttribute('aria-expanded');

      // flip chevron and reset aria-expanded value for a tag
      if (ariaExpanded === 'true') {
        learnMoreElem.setAttribute('aria-expanded', 'false');
        iElem.style.transform = 'rotate(0deg)';
      }
      if (ariaExpanded === 'false') {
        learnMoreElem.setAttribute('aria-expanded', 'true');
        iElem.style.transform = 'rotate(180deg)';
      }
    });
  }
}


// initialize
init();
