import { exists, ResizeHandler, isMobileWidth } from '../base/dom-utils.js';

class PhotoCollage {
  constructor() {
    if (exists('.photo-collage-container')) {
      this.collageImages = $('.photo-collage-container img');
      this.setNumImages();
      this.loadImages();
      this.bindImageClick();
      this.bindViewAllClick();
    }
  }

  setNumImages() {
    let galId = $('.photo-collage-container').data('gal-id'); //'#propLightBox'
    this.carouselId = $(`${galId}`).find('.carousel')
      .attr('id');
    // Set number of images on both mobile and desktop "view gallery" buttons
    this.galleryImages = $(`#${this.carouselId} .item`);
    $('.view-photo-gallery span, .view-photo-gallery-mobile span').html(this.galleryImages.length);
  }

  loadImages() {
    // Set up lazy loading (responsive)
    this.reloadImages();
    ResizeHandler.addResizeEndFn(() => this.reloadImages());
    window.addEventListener("orientationchange", () => this.reloadImages());
  }

  reloadImages() {
    $('.photo-collage__left-section, .first-image, .second-image').each((i, el) => {
      $(el).height($(el).width() / 3 * 2);
    });


    if (!isMobileWidth()) {
      let leftHeight = $('.photo-collage__left-section')[0].getBoundingClientRect().height;
      let rightHeight = $('.photo-collage__right-section')[0].getBoundingClientRect().height;
      $('.first-image').css('margin-bottom', leftHeight - rightHeight);
    }

    $('.view-photo-gallery').css('top', '51%');
  }

  bindImageClick() {
    // Scroll carousel to user-selected image using IDs.
    this.collageImages.click((e) => {
      // Get target image ID
      let imageId = $(e.currentTarget).data('gallery-id');
      let imageIndex = 0;
      // Iterate carousel to check for target image.
      this.galleryImages.each((index, el) => {
        // If present, rotate carousel to target image. If not, open at default location
        if ($(el).attr('id') === imageId) {
          imageIndex = index;
        }
      });
      $(`#${this.carouselId}`).carousel(imageIndex);
      $(`#${this.carouselId}`).data('start-pos', imageIndex);
    });
  }

  bindViewAllClick() {
    $('.view-photo-gallery').click(() => {
      $(`#${this.carouselId}`).carousel(0);
      $(`#${this.carouselId}`).data('start-pos', 0);
    });
  }
}

export default new PhotoCollage();
