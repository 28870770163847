import { EventHandler } from '../base/utils.js';
import { $_PAGE_ } from '../base/vars.js';

if($_PAGE_.is('.modify-page')) {
  EventHandler.one('modify-view-changed-to-select', () => {
    $('.no-rates-available-header-container a').addClass('modify-flow-back-button');

    $('.no-rates-available-header-container a.modify-flow-back-button').on('click', (event) => {
      event.preventDefault();
    });
  });
}
