import { exists } from '../base/dom-utils.js';

class FeaturedServiceDetails {
  constructor() {
    if (exists('.fsd-component')) {
      this.bindFSDs();
    }
  }

  bindFSDs() {
    $('.fsd-component').each((i, el) => {
      if ($(el).css('background-image') == 'none') {
        $(el).addClass('no-authored-image');
      }
    });
  }
}

export default new FeaturedServiceDetails();
