import {
  exists
} from '../base/dom-utils.js';
import Dropdown from './dropdown.js';

class CorporateContact {
  constructor() {
    this.$component = $('.corporate-category-form-component'),
      this.formStatus = 'pristine',
      this.state = [],
      this.tempState = [];

    if (exists('.contact-form')) {
      // Form Validation Events
      $('.contact-form').parsley()
        .on('form:validated', () => {
          this.formStatus = 'validated';
        });
    }

    if (exists(this.$component)) {
      this.bindComponent();
    }
  }

  bindComponent() {
    let $checkboxes = this.$component.find('input:checkbox'),
      $btns = this.$component.find('.cancel, .save');

    this.$categoryFormFieldText = this.$component.find('.category-dropdown-button .category-dropdown-label'),
    this.categoryFormFieldTextPlaceholder = this.$categoryFormFieldText.text(),
    this.$categoryFormFieldCounter = this.$component.find('.category-dropdown-button .category-dropdown-counter'),
    this.$categoryFormVal = this.$component.find('#corpContactCategory'),


    $btns.click(() => {
      Dropdown.publicCloseDropdown();
    });

    $checkboxes.click((e) => {
      let $elm = $(e.currentTarget);

      if ($elm.is('#all-categories')) {
        $checkboxes.not($elm).prop('checked', $elm.prop('checked'));
      } else {
        if (!$elm.prop('checked')) {
          $checkboxes.filter('#all-categories').prop('checked', false);
        }
      }
      this.setInputValue();
    });
  }

  setInputValue() {
    let text = '',
      textArray = [],
      allCheckboxes = $('.corporate-category-form-component input:checkbox'),
      allCategoriesCheckbox = allCheckboxes.filter('#all-categories'),
      categoriesCheckboxes = allCheckboxes.filter(':not(#all-categories)'),
      checkedCategories = categoriesCheckboxes.filter(':checked');

    if (checkedCategories.length === categoriesCheckboxes.length) {
      text = allCategoriesCheckbox.attr('name');
      this.$categoryFormFieldCounter.text(categoriesCheckboxes.length);
      this.$categoryFormVal.val(text);
      this.$categoryFormFieldCounter.removeClass('hidden');
    } else if (!checkedCategories.length) {
      text = this.categoryFormFieldTextPlaceholder;
      this.$categoryFormFieldCounter.text('');
      this.$categoryFormVal.val('');
      this.$categoryFormFieldCounter.addClass('hidden');
    } else {
      textArray = $.map(checkedCategories, (elm) => {
        return $(elm).attr('name');
      });
      text = textArray.join(', ');
      this.$categoryFormFieldCounter.text(checkedCategories.length);
      this.$categoryFormVal.val(text);
      this.$categoryFormFieldCounter.removeClass('hidden');
    }
    this.$categoryFormFieldText.text(text);
    if (this.formStatus !== 'pristine') {
      this.$categoryFormVal.parsley().validate();
    }
  }
}

export default new CorporateContact();
