import FeaturedAmenityIcons from "./featured-amenity-icons.js";

class PropertyAmenities {
    constructor(propertyOverview, amenitiesIconMap) {
        let row = { columns: [] };
        this.rows = [];
        let details = propertyOverview.amenitiesDetails;
        for(let i = 0; i < details.length && i < 6; i++) {
            if((i % 2) == 0) {
                row = { columns: [] }; this.rows.push(row);
            }
            const detail = details[i].groupDetails[0];
            const key = FeaturedAmenityIcons.getIconKeyFromName(detail.amenityNameEN);
            row.columns.push({
              text: detail.amenityName,
              icon: (amenitiesIconMap && amenitiesIconMap.hasOwnProperty(key)) ? amenitiesIconMap[key] : ''
            });
        }
    }
}

export default PropertyAmenities;
