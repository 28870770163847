import {
    getCriteria
} from '../../base/session-handler.js';
import {
    getParameterByName
} from '../../base/utils.js';
import {
    _defaultDateFormat
} from '../../base/vars.js';


export default class SearchUtils {
    constructor() {
        this._trypRedirectURL = 'http://www.tryphotels.com/en/hotel-availability.html';
        this._phRedirectURL = 'https://www.totalrewards.com/hotel-reservations/main/';
        this._trypRedirection = false;
        this._phRedirection = true;
        this._lqRedirection = false;
    }
    isRedirectRequiredForTryp() {
        return this._trypRedirection;
    }
    isRedirectRequiredForPH() {
        return this._phRedirection;
    }
    isRedirectRequiredForLQ() {
        return this._lqRedirection;
    }
    getPHURIString(page) {
        let queryParam;

        if (page == 'O') {
            queryParam = 'https://www.caesars.com/planet-hollywood';
        } else {
            let d1, d2,
                searchCriteria = getCriteria();
            queryParam = this._phRedirectURL + '?';
            if (searchCriteria) {
                d1 = $.datepicker.parseDate(_defaultDateFormat, searchCriteria.checkInDate);
                d2 = $.datepicker.parseDate(_defaultDateFormat, searchCriteria.checkOutDate);

                queryParam += 'propcode=PHV&tierCode=&neatSelectorFlg=' + '&action=FindRooms&casinoSelected=&primaryTRNumber=' + '&arrivalDay=' + ('0' + d1.getDate()).slice(-2) + '&arrivalMonth=' + ('0' + (d1.getMonth() + 1)).slice(-2) + '&arrivalYear=' + d1.getFullYear() + '&departureDay=' + ('0' + d2.getDate()).slice(-2) + '&departureMonth=' + ('0' + (d2.getMonth() + 1)).slice(-2) + '&departureYear=' + d2.getFullYear() + '&numAdults=' + searchCriteria.adults + '&firstName=&numChildren=' + searchCriteria.children + '&loggedIn=N' // + this.isWRLoggedIn()?"Y":"N"
                    +
                    '&memberJspPath=&allowChild=' + '&childText=&originalpage=/MakeReservation.do&rd=WYN';
            }
        }
        return queryParam;
    }
    getTrypURIString(propId, globalPropMap) {
        let queryParam = this._trypRedirectURL + '?',
            d1, d2, index, count, ageParam,
            searchCriteria = getCriteria(),
            altPropId = propId,
            prop;

        if (searchCriteria) {
            d1 = $.datepicker.parseDate(_defaultDateFormat, searchCriteria.checkInDate);
            d2 = $.datepicker.parseDate(_defaultDateFormat, searchCriteria.checkOutDate);
            prop = this.getPropertyFromGlobalMap(globalPropMap, propId);
            if (prop) {
                altPropId = prop.hotelCode;
            }
            queryParam += 'codigoHotel=' + altPropId + '&zona=&lang=en&idPartner=TRYP' + '&idPrm=MBTRYP&idONg=R47&idNom=&irListaHoteles=Y' + '&dia=' + ('0' + d1.getDate()).slice(-2) + '&mes=' + ('0' + (d1.getMonth() + 1)).slice(-2) + '&anio=' + d1.getFullYear() + '&diaHasta=' + ('0' + d2.getDate()).slice(-2) + '&mesHasta=' + ('0' + (d2.getMonth() + 1)).slice(-2) + '&anioHasta=' + d2.getFullYear() + '&habitaciones=' + searchCriteria.rooms;

            for (index = 0; index < searchCriteria.rooms; index++) {
                queryParam += '&adultsRoom' + (index + 1) + '=' + searchCriteria.adults + '&childrenRoom1=' + searchCriteria.children;

                if (searchCriteria.children > 0) {
                    ageParam = searchCriteria.childAge.split('-');
                    for (count = 0; count < searchCriteria.children; count++) {
                        queryParam += '&child' + (count + 1) + 'Room' + (index + 1) + '=' + ageParam[count];
                    }
                }
            }
        }

        return queryParam;
    }
    getLQURIString(href) {
      const sanitizedHref = DOMPurify.sanitize(href);
        let queryParam = '',
            searchCriteria = getCriteria(),
          stopMobi = getParameterByName('stop_mobi', sanitizedHref);

        if (searchCriteria) {
            let queryStringObj = {
              searchCity: DOMPurify.sanitize(searchCriteria.location || searchCriteria.destination),
              indate: DOMPurify.sanitize(searchCriteria.checkInDate),
              outdate: DOMPurify.sanitize(searchCriteria.checkOutDate),
              adults: DOMPurify.sanitize(searchCriteria.adults),
              numChildren: DOMPurify.sanitize(searchCriteria.children),
              rooms: DOMPurify.sanitize(searchCriteria.rooms)
            };

            if (stopMobi) {
              queryStringObj['stop_mobi'] = DOMPurify.sanitize(stopMobi);
            }

            queryParam = '?' + Object.keys(queryStringObj).map((key) => {
              const sanitizedValue = DOMPurify.sanitize(queryStringObj[key]);
              return `${key}=${encodeURIComponent(sanitizedValue)}`;
            })
                .join('&');
        }

      return sanitizedHref.split('?')[0] + queryParam;
    }

    getPropertyFromGlobalMap(map, propId) {
        let prop;
        if (propId && map) {
            prop = map[propId];
        }
        return prop;
    }
}
