import {
  exists,
  scrollToAnimated,
  handleFnOnLoad,
  ResizeHandler
} from '../base/dom-utils.js';
import {
  initCriteria
} from '../base/session-handler.js';
import {
  $_BOOKING_BAR_MAIN_,
  unloadEvent
} from '../base/vars.js';
import LazyLoad, { handleAssetLoaded, handleAssetClicked, getNewIntersectionObserver, lazyLoadImage } from '../base/lazy-load.js';
import {
  getHeaderHeight,
  EventHandler
} from '../base/utils.js';
import Analytics from '../base/wyn-analytics-module.js';
import '../base/thinking.js';

$(() => {
  if (exists($_BOOKING_BAR_MAIN_) || exists($('.search-results-page, .search-results-page-v2'))) {
    initCriteria();
  }
  if (window.location.hash) {
    let id = window.location.hash,
      $target = (id && exists($(id))) ? $(id) : null;

    if ($target !== null) {
      $target.attr('id', 'TempHashID');

      $(() => {
        $target.attr('id', id.replace('#', ''));
      });

      EventHandler.one(EventHandler.serviceCall.end, () => {
        scrollToAnimated($target.offset().top - getHeaderHeight(), 1000, null, () => {
          let callback = $target.data('loadScrollCallback');

          if (typeof (window[callback]) === 'function') {
            window[callback].call($target);
          }
        });
      });
    }
  }

  measurePerformanceMarks();

});

$(window).one('load', () => {
  $('img').each((i, el) => {
    if (!($(el).hasClass('lazy-load-pageload')) && $(el).parents('.photo-gallery-modal').length === 0 && !($(el).is('.lazy-load-viewport'))) {
      /** page-load.js **/
      lazyLoadImage($(el));
      $(el).addClass('lazy-load-pageload');
    } else {
      $(el).on('load', () => {
        handleAssetLoaded($(el));
      });
    }
    handleAssetClicked($(el));
  });

  LazyLoad.init();

  ResizeHandler.addResizeEndFn(() => {
    LazyLoad.handleResize();
  });

  handleFnOnLoad();
  loadViewPortImages();
});

window.addEventListener(unloadEvent, () => {
  Analytics.analyticsBeforeUnload();
});

function measurePerformanceMarks() {
  let marks = window.wynPerf.getEntries().filter((mark) => mark.name.startsWith('wyn')),
    perfEntries = [];

  marks.forEach((mark) => {
    if(mark.name.endsWith('start')) {
      let measurementName = mark.name.replace('-start', '');
      window.wynPerf.measure(measurementName, mark.name, mark.name.replace('-start', '-end'));

      perfEntries.push(window.wynPerf.getEntriesByName(measurementName)[0]);
    }
  });

  Analytics.handlePerfTimings(perfEntries);
}
function loadViewPortImages() {
  let observer = getNewIntersectionObserver(callbackLoadViewPortImgHandleIntersect);
  $('.below-the-fold-components .lazy-load-viewport').each((index, element) => {
    observer.observe(element);
  });
}
function callbackLoadViewPortImgHandleIntersect(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
        let target = entry.target;
        let $element = $(target);
        lazyLoadImage($element);
        observer.unobserve(target);
    }
  });
}

