import ConfigsUtils from './config-utils';

const KEY = 'show-banner';

/**
 * Returns a list of brands where a banner must be shown instead of properties 
 * depending on the Show Banner AEM Configs.
 * If no brands are found, an empty list is returned.
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @returns {string}
 */
const getBrands = (channels, locales) => {
    const configs = ConfigsUtils.getConfigs(KEY, channels, locales, ConfigsUtils.Any);
    const brands = configs.map(config => config.brands).flat();
    return removeDuplicates(brands);
};

function removeDuplicates(brands) {
    const brandsWithoutDuplicates = new Set(brands);
    return Array.from(brandsWithoutDuplicates);
}

export default {
    getBrands,
};
