import { scrollToElement } from '../base/animate.js';
import { EventHandler, _isNotNull } from '../../base/utils.js';

class ExpandableItem {
  constructor() {
    this.selector = '.expandable-item';
    this.element = $(this.selector);
    this.dropdownToggle = this.element.find('.dropdown-toggle');
    this.isNavSectionLink = $('.section-links-menu');

    if (this.element.length > 0) {
      this.init();
      this.bindEvents();
    }
  }

  init(id, scrollToEl) {
    let expandable = null;

    if (_isNotNull(id)) {
      this.element.removeClass('expanded');
      this.element.find('.dropdown').hide();
      this.element.find('.dropdown-icon').removeClass('dropdown-icon-change');

      this.element.each((i, el) => {
        let elm = $(el),
          toggleSign = elm.find('.dropdown-icon'),
          expandableContent = elm.closest(this.selector).find('.dropdown');

        if ((id && elm.data('id') === id) || elm.is('.expanded')) {
          expandable = elm;
          expandableContent.show();
          expandable.addClass('expanded');
          toggleSign.addClass('dropdown-icon-change');
        }
      });
    } else {
      expandable = this.element.filter('.expanded');
      expandable.find('.dropdown').show();
      expandable.find('.dropdown-icon').addClass('dropdown-icon-change');
    }

    if (scrollToEl && expandable) {
      scrollToElement(expandable);
    }
  }

  bindEvents() {
    let dropdownToggleSelector = `${this.selector} .dropdown-toggle`;

    $(document).on('click', dropdownToggleSelector, (e) => {
      e.preventDefault();
      let toggle = $(e.currentTarget),
        toggleSign = toggle.find('.dropdown-icon'),
        expandableContent = toggle.closest(this.selector).find('.dropdown');
      expandableContent.slideToggle(400, () => {
        toggleSign.toggleClass('dropdown-icon-change');
        // WAI-ARIA state
        if (expandableContent[0].hasAttribute('aria-expanded')) {
          expandableContent.attr('aria-expanded', (i, attr) => {
              return attr == 'true' ? 'false' : 'true';
          });
        }
        if (toggleSign.hasClass('dropdown-icon-change') && this.isNavSectionLink.length) {
          // WR Analytics
          EventHandler.triggerEvent('section-link-item', {
            sectionName: toggle.closest('[data-section]').data('section'),
            sectionItemDetail: $.trim(toggle.text().toLowerCase())
          });
        }
      });
    });
  }
}

export default new ExpandableItem();
