import {
  isPublish
} from './vars.js';
import { getLogLocale } from './utils.js';

class Logging {
  constructor() {
    this.loglocale = getLogLocale();
    if (isPublish) {
      if (!this.loglocale.seed) {
        // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        this.loglocale.seed = this.guid();
      }
      this.setLogCookie();
    }
  }
  guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  setLogCookie() {
    document.cookie = `loglocale=${JSON.stringify(this.loglocale)};path=/;secure`;
  }
}

export default new Logging();
