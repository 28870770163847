import { exists } from '../base/dom-utils.js';
import { EventHandler } from '../base/utils.js';
import {lazyLoadImage} from '../base/lazy-load.js';

class PhotoTileGrid {
  constructor() {
    if (exists('.photo-tile-grid-component')) {
      this.bindLazyLoadHandlers();
    }
  }

  bindLazyLoadHandlers() {
    $('.lazyimg').each((i, el) => {
      EventHandler.one('img.load', (e) => {
        $(e.currentTarget).removeClass('lazy');
      }, $(el));

      setTimeout(() => {
        lazyLoadImage($(el));
      }, i * 500);
    });
  }
}

export default new PhotoTileGrid();
