import {
  _isNotNull,
  _isNull,
  replaceToken,
  getCity,
  getName,
  getUNAP,
  EventHandler
} from '../base/utils.js';

class Title {
  constructor() {
    this.$_ = $('title');
    EventHandler.one(EventHandler.UNAP.updated, () => {
      this.populateTitle();
    });
  }
  populateTitle() {
    for (let i = 0; i < this.$_.length; i++) {
      let title = '';
      if (_isNotNull(this.$_[i].getAttribute('data-override'))) {
        title = this.$_[i].getAttribute('data-override');
      }
      if (_isNull(title)) {
        title = this.$_[i].textContent;
      }
      let stateAbr = getUNAP('stateCode') ? getUNAP('stateCode') : getUNAP('country');
      title = replaceToken(title, '${hotelName}', getName()) || title;
      title = replaceToken(title, '${city}', getCity()) || title;
      title = replaceToken(title, '${zipcode}', getUNAP('postalCode')) || title;
      title = replaceToken(title, '${stateAbr}', stateAbr) || title;
      this.$_[i].text = title;
    }
  }
}

export default new Title();
