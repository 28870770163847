import {
  exists
} from '../base/dom-utils.js';
import {
  getUrlPathFromLocationDetails,
  getLocationDetails,
  getCriteria
} from '../base/session-handler.js';
import {
  _isNotNull,
  replaceToken,
  getQueryString,
  getName,
  getUNAP,
  EventHandler,
  CookieHandler
} from '../base/utils.js';
import {
  $_PAGE_,
  property_city_name,
  property_state_name,
  property_country_code,
  search_redirect_url
} from '../base/vars.js';

class Breadcrumb {
  constructor() {
    this.$_ = $('ul.breadcrumb');
    this._count = this.$_.find('li:last-child meta[itemprop=position]').attr('content') || 0;
    this.$home = this.$_.find('meta[itemprop=position][content=1]').prev() || null;
    this.$city = this.$_.find('.brCityName') || null;
    this.$hotel = this.$_.find('.brHotelName') || null;
    this.$rrhotel = this.$_.find('.rrHotelName') || null;
    this.init();
  }
  populateCity(city, ld) {
    if (typeof ld === 'object' && _isNotNull(ld.entity) && (ld.entity in ld || ld.entity.toLowerCase() in ld || ld.entity.toUpperCase() in ld)) {
      ld = ld.entity;
    } else if (typeof ld === 'object') {
      ld = 'city';
    }

    let str = replaceToken(this.$city[0].innerText, '${' + ld + '}', city) || this.$city[0].innerText;
      str = replaceToken(str, '${location}', getCriteria().searched) || str;

    if (str !== false) {
      this.$city.find('h2,span').text(str);
      return true;
    }

    return false;
  }
  populateHotel() {
    let url = window.location.pathname.replace('rooms-rates', 'overview') + window.location.search;
    if(url.includes('rooms-rates') || url.includes('overview')){
      localStorage.setItem('rooms-rates-page-path',url);
    }
    this.$hotel.find('h2,span').text(getName());
    this.$hotel.find('a').attr('href', url);
    if($_PAGE_.is('.packages-page') && url.indexOf('reservation/packages')){
      url = localStorage.getItem('rooms-rates-page-path') || CookieHandler.readCookie('__attentive_ss_referrer') || (localStorage.getItem('QM') && JSON.parse(localStorage.getItem('QM')).lastUrl && JSON.parse(localStorage.getItem('QM')).lastUrl.href) || url;
      this.$rrhotel.find('a').attr('href', url.replace('overview','rooms-rates'));
      this.$hotel.find('a').attr('href', url.replace('rooms-rates', 'overview'));
    }
  }

  populateBreadcrumb() {
    if (exists(this.$city)) {
      let _text = false,
        _url = false;

      if (_isNotNull(property_city_name)) {
        _text = this.populateCity(getUNAP('city') || getLocationDetails('city') || property_city_name, 'city');
      } else if (_isNotNull(property_state_name)) {
        _text = this.populateCity(getUNAP('state') || getLocationDetails('state') || property_state_name, 'city');
      } else if (_isNotNull(property_country_code)) {
        _text = this.populateCity(getUNAP('country') || getLocationDetails('country') || property_country_code, 'city');
      }

      if (exists(this.$city.find('span[itemprop=name]'))) {
        let city_token = this.$city.find('span[itemprop=name]').data('override');
        if (city_token && _isNotNull(city_token) && ((city_token.indexOf('${City}') > -1) || (city_token.indexOf('${city}') > -1))) {
          let city_token_ele = this.$city.find('span[itemprop=name]'),
            city_token_value = '';

          if (_isNotNull(this.$city.text())) {
            if ((city_token_ele.text().indexOf('${City}') > -1)) {
              city_token_value = city_token.replace('${City}', this.$city.text().trim());
            } else if ((city_token_ele.text().indexOf('${city}') > -1)) {
              city_token_value = city_token.replace('${city}', this.$city.text().trim());
            }
          }

          if (_isNotNull(city_token_value)) {
            city_token_ele.attr('data-override', city_token_value);
            if ((city_token_ele.text().indexOf('${City}') > -1) || (city_token_ele.text().indexOf('${city}') > -1)) {
              city_token_ele.text(city_token_value);
            }
          }
        }
      }
      if (exists(this.$hotel.find('span[itemprop=name]'))) {
        let hotelName_token = this.$hotel.find('span[itemprop=name]').data('override');
        if (hotelName_token && _isNotNull(hotelName_token) && ((hotelName_token.indexOf('${hotelName}') > -1))) {
          let hotelName_token_ele = this.$hotel.find('span[itemprop=name]'),
            hotelName_token_value = '';

          if (_isNotNull(this.$hotel.text())) {
            if ((hotelName_token_ele.text().indexOf('${HotelName}') > -1)) {
              hotelName_token_value = hotelName_token.replace('${hotelName}', this.$hotel.text().trim());
            } else if ((hotelName_token_ele.text().indexOf('${hotelName}') > -1)) {
              hotelName_token_value = hotelName_token.replace('${hotelName}', this.$hotel.text().trim());
            }
          }
          if (_isNotNull(hotelName_token_value)) {
            hotelName_token_ele.attr('data-override', hotelName_token_value);
            if ((hotelName_token_ele.text().indexOf('${hotelName}') > -1)) {
              hotelName_token_ele.text(hotelName_token_value);
            }
          }
        }
      }

      if (_isNotNull(search_redirect_url)) {
        let cityRedirectLink = `${search_redirect_url}?${getQueryString()}`;
        this.$city.find('a').attr('href', cityRedirectLink);
        _url = true;
      }

      if (_text === false || _url === false) {
        let ld = getLocationDetails(null, true);

        if (_text === false) {
          if (_isNotNull(ld.city) && ld.city !== '') {
            this.populateCity(getUNAP('city') || ld.city, ld);
          } else if (_isNotNull(ld.state) && ld.state !== '') {
            this.populateCity(ld.city, ld);
          } else if (_isNotNull(ld.country) && ld.country !== '') {
            this.populateCity(ld.country, ld);
          }
        }

        if (_url === false) {
          let url = getUrlPathFromLocationDetails();
          url += '?' + getQueryString();

          this.$city.find('a').attr('href', url);
        }
      }
    }
    this.populateHotel();
    this.$_.show();
  }
  init() {
    let dataFetchEvent = EventHandler.UNAP.updated;

    if ($_PAGE_.is('.search-results-page')) {
      dataFetchEvent += ' ' + EventHandler.getLocation.success;
    }

    if ($_PAGE_.is('.search-results-page, .property-page, .rooms-rates-page, .packages-page')) {
      EventHandler.one(dataFetchEvent, () => {
        this.populateBreadcrumb();
      });
    }
  }
}

export default new Breadcrumb();
