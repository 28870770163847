import {
  exists
} from '../base/dom-utils.js';
import {
  _isNotNull
} from '../base/utils.js';
import {
  _LANGUAGE_,
  _REGION_,
  speedRFPId,
  speedRFPOptionsKey,
  Srfp
} from '../base/vars.js';

class SpeedRFP {
  constructor() {
    if (exists('.link-srfp') && _isNotNull(Srfp)) {
      // this.iterateSrfpLinks();
      console.log('disble C-event');
    } else {
      console.log('disble C-event');
      // setTimeout(() => this.iterateSrfpLinks(), 100);
    }
  }

  iterateSrfpLinks() {
    $('.link-srfp').each((i, el) => {
      //this.initSrfp(el);
      console.log('disble C-event');
    });
  }

  initSrfp(e) {
    let SpeedRFP = new Srfp.widget({
      clear_previous: true,
      display: 'overlay',
      key: speedRFPOptionsKey,
      uid: $(e).attr('data-uid') || speedRFPId,
      locale: `${_LANGUAGE_}-${_REGION_.toUpperCase()}`
    });
    $(e).data('speedrfp', SpeedRFP);

    $(e).on('click', (event) => {
      event.preventDefault();
      let widget = $(event.currentTarget).data('speedrfp');
      widget.show();
    });
  }
}

export default new SpeedRFP();
