import ConfigsUtils from './config-utils';
const KEY = 'banner-visibility';

/**
 * Returns the first Banner Visibility AEM Config for the specified brand that satisfies the provided
 * channels and locales.
 * If no Banner Visibility AEM Config that satisfies the provided parameters is found, undefined is returned. 
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 * @returns {Object|undefined}
 */
const findBannerVisibilityConfig = (channels, locales, bannerBrand, brands) => {
    const key = `${bannerBrand.toLowerCase()}-${KEY}`;
    return ConfigsUtils.findConfig(key, channels, locales, brands);
};

export default {
    findBannerVisibilityConfig,
};
