import BannerVisibilityConfig from "../../base/aem-configs/brand-visibility-config";
import ShowBannerConfig from "../../base/aem-configs/show-banner-config";
import { getNumDays, getQueryString } from "../../base/utils";
import { getCriteria } from "../../base/session-handler";
import { _LOCALE_ } from "../../base/vars";

const POSITION = 'position';
const MAX_LOS = 'max-los';
const MIN_LOS = 'min-los';
const VIEW_RESULTS_BY = 'view-results-by';
const DIRECT_BILL = 'Direct Bill';

export default class SearchBrandBanner {
    constructor() {
        this.showBannerBrands = [];
        this.showBannerProperties = [];
        this.filteredShowBannerProperties = [];
        this.showBannerFilteredBrands = [];
        this.searchCriteria = getCriteria();
        this.currentSearchFilter = null;
        this.sortedProperties = {};
    }

    isWyndhamDirectValidForBrand(brandId) {
        const brandProperties = this.sortedProperties[brandId];
        return !this.isWyndhamDirectFilterSelected() || brandProperties.some(property => {
            return property.propertyAttributes && property.propertyAttributes.includes(DIRECT_BILL);
        });
    }

    isShowOnlyAmenityValidForBrand(brandId) {
        const brandProperties = this.sortedProperties[brandId];    
        return (
            !this.currentSearchFilter ||
            !this.currentSearchFilter.showOnlyAmenities || 
            this.currentSearchFilter.showOnlyAmenities.every(showOnlyAmenity => {
                return brandProperties.some(property => {
                    return property.filterAmenities && property.filterAmenities.includes(showOnlyAmenity);
                });
            })
        );
    }

    isPositionValidForBrand(config, currentPosition, numOfHotels) {
        const positionToShowBanner = config.attributes[POSITION];
        const isLastPosition = currentPosition === numOfHotels;
        return currentPosition == positionToShowBanner || isLastPosition;
    }

    isBrandPropertiesInSearchRadius(brandId) {
        return this.filteredShowBannerProperties.some(properties => {
            return properties.brand.toLowerCase() === brandId.toLowerCase();
        });
    }

    isSearchCriteriaValidForBrand(config, brandId) {
        return (
            !this.isBrandFilterSelected() &&
            this.isBrandPropertiesInSearchRadius(brandId) &&
            this.isLengthOfStayValidForBrand(config) &&
            this.isViewResultsValidForBrand(config) &&
            this.isWyndhamDirectValidForBrand(brandId) &&
            this.isBrandWithSelectedAmenities(brandId) &&
            this.isShowOnlyAmenityValidForBrand(brandId)
        );
    }

    isLengthOfStayValidForBrand(config) {
        const maxLengthOfStay = config.attributes[MAX_LOS];
        const minLengthOfStay = config.attributes[MIN_LOS];
        const searchCriteria = getCriteria();
        const checkInDate = searchCriteria.checkInDate;
        const checkOutDate = searchCriteria.checkOutDate;
        const lengthOfStay = getNumDays(checkInDate, checkOutDate);
        return lengthOfStay >= minLengthOfStay && lengthOfStay <= maxLengthOfStay;
    }

    isViewResultsValidForBrand(config) {
        const viewResultsBy = config.attributes[VIEW_RESULTS_BY];
        const currentViewResultsBy = getCriteria().rateTypeFilter;
        
        return viewResultsBy === currentViewResultsBy;
    }

    isBrandFilterSelected() {
        return this.currentSearchFilter && this.currentSearchFilter.brands.length !== 0;
    }

    isWyndhamDirectFilterSelected() {
        return this.currentSearchFilter && this.currentSearchFilter.directbillflag;
    }

    isBrandWithSelectedAmenities(brandId) {
        if (this.currentSearchFilter && this.currentSearchFilter.amenities.length > 0) {
            return this.sortedProperties[brandId].some(property => {
                const selectedAmenities = this.currentSearchFilter.amenities;
                return selectedAmenities.every(selectedAmenity => property.amenities.includes(selectedAmenity));
            });
        }
        return true;
    }

    renderBannerIfNeeded(currentPosition, numOfHotels) {
        let template = null;

        this.showBannerFilteredBrands.forEach((brandId, index) => {
            const config = BannerVisibilityConfig.findBannerVisibilityConfig(null, null, brandId);
            if (this.isPositionValidForBrand(config, currentPosition + 1, numOfHotels)) {
                const url = this.getBannerURLForBrand(brandId);
                template = _.template($(`#banner-${brandId}`).html())({
                    'url': url
                });
                this.showBannerFilteredBrands.splice(index, 1);
            }
        });

        return template;
    }

    getBannerURLForBrand(brandId) {
        if (this.sortedProperties[brandId].length === 1) {
            return this.getPropertyOverviewURL(brandId);
        } else {
            return this.getSearchResultURLForBrand(brandId);
        }
    }

    getPropertyOverviewURL(brandId) {
        const propertyId = this.sortedProperties[brandId][0].hotelId;
        return `${window.location.origin}/${_LOCALE_}/hotels/${propertyId}?${getQueryString()}`;
    }

    getSearchResultURLForBrand(brandId) {
        const regex = /brand_id=[A-Z]{2,3}/;
        brandId = brandId.toUpperCase();
        const url = window.location.href.replace(regex, `brand_id=${brandId}`);
        return url;
    }

    filterShowBannerBrands(currentSearchFilter, searchFilterObject) {
        this.currentSearchFilter = currentSearchFilter;
        if (searchFilterObject) {
            this.filteredShowBannerProperties = searchFilterObject.refinePropsOnDistance(
                searchFilterObject._currentFilter.distanceMin,
                this.showBannerProperties
            );
        }
        this.showBannerFilteredBrands = this.showBannerBrands.filter(brand => {
            const config = BannerVisibilityConfig.findBannerVisibilityConfig(null, null, brand);
            if (config) {
                return this.isSearchCriteriaValidForBrand(config, brand);
            }
        });
    }

    sortPropertiesByBrand() {
        this.showBannerBrands.forEach(brandId => { 
            this.sortedProperties[brandId] = this.filteredShowBannerProperties.filter(property => {
                const propertyBrand = property.brand.toLowerCase();
                return propertyBrand === brandId;
            });
        });
    }

    removeShowBannerBrandsFromSearchResults(searchResults, currentSearchFilter) {
        this.showBannerBrands = ShowBannerConfig.getBrands();
        const altSell = searchResults.availability.altSell;
        const hostBrand = searchResults.availability.hostBrand;
        this.showBannerProperties = [];
        altSell.availability = altSell.availability.filter(property => {
            const isBrandInShowBanner = this.showBannerBrands.includes(property.brand.toLowerCase());
            if (isBrandInShowBanner) {
                altSell.availabilityCount--;
                hostBrand.totalPropertyCount--;
                altSell.totalPropertyCount--;
                this.showBannerProperties.push(property);
            }
            return !isBrandInShowBanner;
        });
        this.filteredShowBannerProperties = this.showBannerProperties;
        searchResults.availability.altSell = altSell;
        searchResults.availability.hostBrand = hostBrand;

        this.filterShowBannerBrands(currentSearchFilter);
        this.sortPropertiesByBrand();

        return searchResults;
    }
}

