import {
  exists,
  getElHeightFromChildren,
  scrollToAnimated,
  isMobileWidth
} from '../base/dom-utils.js';
import GoogleAPI from '../base/google-api-handler.js';
import {
  navigateToSearchResults,
  setLocationDetails,
  getBrand
} from '../base/session-handler.js';
import {
  _isNotNull,
  getHeaderHeight,
  callService
} from '../base/utils.js';
import {
  CITY_SEARCH_PATH,
  _LOCALE_,
  transitionEvents
} from '../base/vars.js';

class Locations {
  constructor() {
    if (exists('.locations-component')) {
      this.params = {
        brand: getBrand(true),
        isCountry: 'Y',
        language: _LOCALE_
      };
      this.getCountriesOnPage();
    }
  }

  getCountriesOnPage() {
    // gets countries on page
    $('.location-section__country-name').each((i, el) => {
      this.params.country = $(el).text()
        .trim();
      if (this.params.state) {
        delete this.params.state;
      }
      callService('regionSearch', this.params).then((r) => {
        this.buildCountryEl(r, i, $(el).parent()
          .find('.location-section__state-name'));
        if (r.status && r.status === 'OK') {
          this.handleMoreLess(el, r);
        }
      });
    });
  }

  handleMoreLess(el, r) {
    let $moreLessContainer = $(el).parents('.locations-container.location-section')
      .find('.location-section__see-more-less-section');
    if (r.results.destinationList.length < 10) { // Remove more/less if fewer than 10 items for given country
      $moreLessContainer.remove();
    } else {
      $moreLessContainer.find('a').click((e) => {
        e.preventDefault();

        let $moreLessLink = $(e.currentTarget),
          $expand = $moreLessLink.parents('.locations-container.location-section').find('.locations-section__mobile-default-collapsed');
        if ($moreLessLink.is('[aria-expanded=false]')) {
          $expand.height(getElHeightFromChildren($expand)).addClass('open')
            .attr('aria-expanded', true);

          $moreLessLink.attr('aria-expanded', true);
        } else {
          $expand.height(0).removeClass('open')
            .attr('aria-expanded', false)
            .one(transitionEvents, () => {
              scrollToAnimated($(el).parents('.locations-container.location-section')
                .offset().top - getHeaderHeight());
            });

          $moreLessLink.attr('aria-expanded', false);
        }
      });
    }
  }

  buildCountryEl(res, i, $el) {
    if (res.status && res.status === 'OK' &&
      res.results.searchType === 'country' &&
      _isNotNull(res.results.locationName) &&
      (res.results.destinationList.length > 0)) {
      let allDest = res.results.destinationList,
        searchLocationName = res.results.locationName,
        countriesWithStates = new RegExp('(united states|canada)', 'i'),
        $firstTen = $el.find('.locations-section__mobile-default-expanded'),
        $otherItems = $el.find('.locations-section__mobile-default-collapsed'),
        $stateTemplate = $el.find('.states-section__state:first-child'),
        $accordionTemplate = $el.find('.state-section-accordion'),
        mobileLimit = 10;

      allDest.forEach((destination, index) => {
        let id = this.buildAccordionId(destination.destinationName, destination.destinationStateCountry),
          $dest = $stateTemplate.clone(),
          $acc = $accordionTemplate.clone().attr('id', id);

        $dest.attr({
          'data-index': index + 1,
          'data-state': destination.destinationName,
          'data-country': destination.destinationStateCountry
        }).find('.locations-section__state-name__span')
          .text(destination.destinationName);

        if (searchLocationName.match(countriesWithStates)) {
          $dest.find('.states-section__state-anchor').attr('href', `#${id}`)
            .click((e) => this.handleCityAccordion(e))
            .click((e) => this.handleOpenAccordions(e));

          $dest.find('.service-trigger').one('click', (e) => this.getCities(e));
        } else {
          $dest.find('.states-section__state-anchor').attr({
            href: this.generateHREF(destination.destinationName, destination.destinationStateCountry),
            'data-city': destination.destinationName,
            'data-state': destination.destinationStateCountry
          })
            .click((e) => this.getUrlForLocation(e));

          $acc = undefined;
        }

        if (index < mobileLimit) {
          $firstTen.append($dest);
        } else {
          $otherItems.append($dest);
        }

        if (_isNotNull($acc)) {
          $acc.insertAfter($dest);
        }
      });
    } else {
      $el.parents('.locations-container.location-section').remove();
    }
  }

  handleCityAccordion(e) {
    let $parent = $(e.currentTarget).parents('.states-section__state'),
      index = $parent.data('index'),
      id = this.buildAccordionId($parent.data('state'), $parent.data('country'));

    if (!isMobileWidth()) {
      if (index % 3) {
        let countToEnd = index + (3 - (index % 3));

        $(`#${id}`).insertAfter($(e.currentTarget).parents('.location-section__country')
          .find('[data-index=' + countToEnd + ']'));
      }
    } else {
      $(`#${id}`).insertAfter($(e.currentTarget));
    }
  }

  handleOpenAccordions(e) {
    if ($(e.currentTarget).is('[aria-expanded=false]')) {
      $('.state-section-accordion[aria-expanded=true]').each((i, el) => {
        $(el).collapse('hide');
      });
    }
  }

  getCities(e) {
    let $el = $(e.currentTarget);
    this.params.country = $el.parents('.location-section__country').find('.location-section__country-name')
      .text()
      .trim();
    this.params.state = $el.text().trim();

    callService('regionSearch', this.params).then((r) => {
      this.buildCitiesList(r, $el);
    });
  }

  buildCitiesList(res, $el) {
    if (res.status && res.status === 'OK' && res.results.destinationList.length > 0) {
      let id = this.buildAccordionId($el.parent().data('state'), $el.parent().data('country')),
        $stateSectionAccordion = $(`#${id}`),
        $cityTemplate = $stateSectionAccordion.find('> .state-city-linkout');

      for (let i = 0; i < res.results.destinationList.length; i++) {
        let destination = res.results.destinationList[i],
          $temp = $cityTemplate.clone();

        $temp.find('a.city-link')
          .attr({
            href: this.generateHREF(destination.destinationName, destination.destinationStateCountry),
            'data-state': destination.destinationStateCountry,
            'data-city': destination.destinationName
          })
          .text(destination.destinationName + ' (' + destination.destinationCount + ')')
          .click((e) => this.getUrlForLocation(e));

        $stateSectionAccordion.find('.panel-body').append($temp);
      }

      $el.attr('aria-hidden', true)
        .parent()
        .find('.accordion-trigger')
        .attr('aria-hidden', false)
        .click();
    } else {
      $el.parents('.states-section__state').remove();
    }
  }

  getUrlForLocation(e) {
    e.preventDefault();

    let $el = $(e.currentTarget),
      destination = `${$el.data('city')}, ${$el.data('state')}`;
    GoogleAPI.getLocationDetailsFromGeoCoding(destination).then((l) => {
      setLocationDetails(l);
      navigateToSearchResults();
    });
  }

  buildAccordionId(city, state) {
    return city.replace(/\W/g, '') + state.replace(/\W/g, '');
  }

  translateToUrlString(name) {
    return (_isNotNull(name)) ? name.replace(/[\s.,\/#!$%\^&\*;:{}=\_`~()]/g, '-').replace(/[-]+/g, '-')
      .toLowerCase() : undefined;
  }

  generateHREF(locationName, state1) {
    let fullHREF = (('/' + this.params.language + CITY_SEARCH_PATH) || '') + '/';
    let locName = locationName.replace(/[\s.,\/#!$%\^&\*;:{}=\_`~()]/g, '-').replace(/[-]+/g, '-')
      .toLowerCase();
    let stateName = state1.replace(/[\s.,\/#!$%\^&\*;:{}=\_`~()]/g, '-').replace(/[-]+/g, '-')
      .toLowerCase();

    fullHREF += locName + '-' + stateName;
    return fullHREF;
  }
}

export default new Locations();
