import { exists } from '../base/dom-utils.js';
import Analytics from '../base/wyn-analytics-module.js';

class PromoDeals {
  constructor() {
    if (exists('.promodeals-component')) {
      this.bindEvents();
    }
  }

  bindEvents() {
    $('.promo-info-container a').click(() => {
      Analytics.getPromoDealsInfo(this);
    });
  }
}

export default new PromoDeals();
