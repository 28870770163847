import {
  getBrand
} from '../base/session-handler.js';
import {
  exists,
  getCSSPropertyAsInt,
  getElTrueHeight,
  getElTrueWidth,
  scrollToAnimated,
  addMobileOverflowFix,
  removeMobileOverflowFix,
  getWindowHeight,
  getWindowWidth,
  isCompactView,
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth,
  WatchHandler,
  ResizeHandler
} from '../base/dom-utils.js';
import {
  _isNull,
  getHeaderHeight,
  EventHandler
} from '../base/utils.js';
import {
  $_PAGE_,
  $_BOOKING_BAR_MAIN_,
  brand_id
} from '../base/vars.js';

class Dropdown {
  constructor() {
    this.initialized = false;
    this.buttonDataSelector = 'dropdown';
    this.buttonSelector = 'button[data-dropdown*=dropdown]';
    this.focusedSelector = 'button:focus[data-dropdown*=dropdown]';
    this.containerSelector = '.dropdown-container';
    this.buttonClass = 'on';
    this.containerClass = 'open';
    this.$parent = null;
    this.$button = null;
    this.$container = null;
    this.$arrow = null;
    this.$saveCancelBar = null;
    this.watch = new WatchHandler();
    this._watching = false;
    this.events = {
      opening: 'dropdown:opening', // Dropdown trigger instantiated
      open: 'dropdown:open', // About to open dropdown
      opened: 'dropdown:opened', // Dropdown positioned correctly and is now open
      closing: 'dropdown:closing', // Dropdown closeDropdown triggered
      closeDropdown: 'dropdown:closeDropdown', // About to closeDropdown dropdown
      closedDropdown: 'dropdown:closed', // Dropdown closeDropdown finished
      finished: '_dropdown:finished',
      height: '_dropdown:heightChange'
    };
    this.options = {
      Desktop: {
        show: {
          css: {
            opacity: 1
          },
          opts: {}
        },
        hide: {
          css: {
            opacity: 0,
            display: 'none'
          },
          opts: {}
        }
      },
      Tablet: {
        show: {
          css: {
            opacity: 1,
            display: 'block'
          },
          opts: {}
        },
        hide: {
          css: {
            opacity: 0,
            display: 'none'
          },
          opts: {}
        }
      },
      Mobile: {
        show: {
          css: {
            opacity: 1
          },
          opts: {}
        },
        hide: {
          css: {
            opacity: 0
          },
          opts: {}
        }
      },
      easing: 'linear',
      timing: 333,
      openComplete: () => {
        if(this.$container){
          this.finishOpen();
        }
      },
      closeDropdownComplete: () => {
        if(this.$container){
          this.finishcloseDropdown();
        }
      }
    };

    this.resizeEnd = () => {
      if(!$('body').is('.iphone') || (this.$container && this.$container.parents('.modal-content').length === 0)) {
        this.closeDropdown();
        this.reset();
        this.setAnimationProperties();
      }
    };
    this.props = {};
    this._doingSomething = false;
    if (!($_PAGE_.is('.modify-page'))) {
      $(() => {
        this.init();
      });
    }
  }
  disableNativeBehavior(e) {
    e.preventDefault();
  }
  finishOpen() {
    if(this.$container){
      this.$container.find('.dropdown-overflow').scrollTop(0);
      this.$container.addClass(this.containerClass).trigger(this.events.opened);
      this.$container.attr('tabindex', 0).focus();
    }
    this.props.show.opts.duration = this.options.timing;

    this._doingSomething = false;

    $('body').trigger(this.events.finished);
    if (isMobileWidth()) {
      addMobileOverflowFix();
    }
    if ((exists('#smartbanner')) && isCompactView()) {
      let sab = $('#smartbanner');
      sab.is(':visible') ? sab.css({
        'z-index': '0'
      }) : '';
    }
    if (isMobileWidth()) {
      $('.outer-wrapper.page').css('overflow', 'visible');
    }
  }
  finishcloseDropdown() {
    $(this.$container).trigger(this.events.closedDropdown)
      .removeClass(this.containerClass)
      .removeClass('scroll')
      .removeAttr('tabindex')
      .hide();
    this.unset();

    this.props.hide.opts.duration = this.options.timing;

    this._doingSomething = false;

    $('body').trigger(this.events.finished);
    removeMobileOverflowFix();
    if ((exists('#smartbanner')) && isCompactView()) {
      let sab = $('#smartbanner');
      try {
        (parseInt(sab.css('z-index')) === 0) ? sab.css({
          'z-index': '99'
        }) : '';
      } catch (e) {
        console.log('Try catch failed');
      }
    }
    if (isMobileWidth()) {
      $('.outer-wrapper.page').css('overflow', 'hidden');
    }
  }
  targetIsInButtonGroup($btn) {
    return (
      this.$button.data('group') // The button is part of a button group
      &&
      $btn.data('group') // The target is part of a button group
      &&
      !(this.$button.is($btn)) // The new target is not the current button
      &&
      this.$button.parents('.btn-group').is($btn.parents('.btn-group')) // Current button and new target share button group
    );
  }
  setOptions(opts) {
    $.extend(true, this.props, opts);
  }
  checkHeightAgainstWindow() {
    if(this.$container){
      let dropdownHeight = this.$container.outerHeight() + (this.$container.offset().top - window.pageYOffset);

      let alertHeight = 0;
      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height() + 30;
      }
      if (dropdownHeight > getWindowHeight() && !this.$container.is('.av-calendar-special-rates-dropdown')) {
        let modelAlertHeight = getWindowHeight() - (this.$container.offset().top - window.pageYOffset);
        let space = getWindowHeight() - (dropdownHeight - this.$container.outerHeight()) - this.$container.position().top,
          opts = {
            maxHeight: this.$container.parents('.modal').length ? modelAlertHeight - 10 : space - alertHeight,
            overflowY: 'auto'
          };

        if (!isMobileWidth() && $('body').is('.win') && !this.$container.is('.win-open')) {
          this.$container.width(this.$container.outerWidth() + 20).addClass('win-open');
        }

        this.$container.find('.dropdown-overflow').css(opts);
      } else {
        $('.booking-bar .destination-dropdown').find('.dropdown-overflow').css({
          'overflow-y': 'auto'
        });
      }
    }

    if (!(this._watching)) {
      this.watchHeight(() => {
        this.checkDropdownBottomOffScreen();
      });
    }
  }
  checkDropdownBottomOffScreen() {
    if (!(isMobileWidth())) {
      let dropdownBottom = (this.$container.offset().top + this.$container.outerHeight() + 20) - window.pageYOffset;
      let alertHeight = 0;

      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height();
      }
      // Don't scroll page for dropdowns in Rewards header mega-menu and Mobile menu
      if (dropdownBottom > (getWindowHeight()) && (window.pageYOffset + getHeaderHeight()) + alertHeight < this.$parent.offset().top && !exists(this.$container.parents('.header-mm-dropdown')) && !exists(this.$container.parents('.mobile-nav'))) {
        let top = dropdownBottom + window.pageYOffset - getWindowHeight();
        if (top > this.$parent.offset().top) {
          top = this.$parent.offset().top - getHeaderHeight();

          if (exists(this.$parent.closest('.booking-bar'))) {
            top = this.$parent.offset().top;
          }
        }

        scrollToAnimated(top, null, null, () => this.checkHeightAgainstWindow());
      } else {
        this.checkHeightAgainstWindow();
      }
    } else {
      if (_isNull(this.watch)) {
        this.watch = new WatchHandler();
      }

      if (!(this._watching)) {
        this.watchHeight(() => {
          if (this.watch.getCurrent() > (getWindowHeight() - this.$arrow.outerHeight())) {
            this.watch.getElement().parent()
              .addClass('scroll');
          } else {
            this.watch.getElement().parent()
              .removeClass('scroll');
          }
        });
      }
    }
  }
  watchHeight(callback) {
    let $el = this.$container;
    if (isMobileWidth()) {
      $el = this.$container.find('.dropdown-overflow');
    }

    if($el){
      this._watching = true;

      this.watch.init($el, this.events.height, {
        fn: 'outerHeight',
        params: null
      });

      EventHandler.on(this.events.height, callback);
    }
  }
  endWatchHeight() {
    this.watch.stop();

    EventHandler.off(this.events.height, this.checkDropdownBottomOffScreen);

    this._watching = false;
  }
  stopProp(e) {
    e.stopPropagation();
  }
  publicCloseDropdown(callback) {
    let closeTarget = $(isMobileWidth() ? '.mobile-booking-dropdown' : '.mm-booking-form-container');
    if (!exists(closeTarget)) {
      closeTarget = $('body');
    }
    closeTarget.one(this.events.finished, function() {
      if (typeof (callback) == 'function') {
        callback();
      }
    }).click();
  }
  turnButtonOff() {
    this.$button.off('blur', this.handleButtonBlur).removeClass(this.buttonClass);
  }
  closeDropdown() {
    if (this.$container !== null) {
      this.$container.trigger(this.events.closing);
      // Remove the top value on close
      this.$container[0].style.top = '';
      this.$container[0].style.zIndex = '';

      if (!this.$parent.is('.modal') && !this.$button.data('booking-dates-dropdown') && isMobileWidth()) {
        this.$parent.attr('data-z-index', '').css({
          zIndex: ''
        });
      }

      this.turnButtonOff();
      this.hideContainer();
      $('body').off('keyup');
    }
  }
  setAnimationProperties() {
    this.props = {};

    if (isDesktopWidth()) {
      this.props = this.options.Desktop;
    } else if (isTabletWidth()) {
      this.props = this.options.Tablet;
    } else {
      this.props = this.options.Mobile;
    }

    if (!(this.props.show.opts.duration)) {
      this.props.show.opts.duration = this.options.timing;
    }
    if (!(this.props.show.opts.easing)) {
      this.props.show.opts.easing = this.options.easing;
    }
    if (!(this.props.show.opts.complete)) {
      this.props.show.opts.complete = this.options.openComplete;
    }

    if (!(this.props.hide.opts.duration)) {
      this.props.hide.opts.duration = this.options.timing;
    }
    if (!(this.props.hide.opts.easing)) {
      this.props.hide.opts.easing = this.options.easing;
    }
    if (!(this.props.hide.opts.complete)) {
      this.props.hide.opts.complete = this.options.closeDropdownComplete;
    }
  }
  unset() {
    this.$parent = null;
    this.$button = null;
    this.$container = null;
    this.$arrow = null;
    this.$saveCancelBar = null;

    if (this.watch !== null) {
      this.watch.stop();
    }
    this.watch = null;
  }
  reset() {
    this.unset();

    this._doingSomething = false;
    this._watching = false;

    $(this.containerSelector).removeClass(this.containerClass)
      .removeClass('scroll');
    $(this.buttonSelector).removeClass(this.buttonClass);
  }
  getContainerFromButton() {
    if (exists(this.$button)) {
      // REVIEW in the parent (first container or container fluid), find the data-dropdown associated with button
      this.$container = this.$parent.find(this.$button.data(this.buttonDataSelector));
      this.$arrow = this.$container.find('.arrow-up');
      this.$saveCancelBar = this.$container.find('.save-cancel-container');
    }
  }
  getButtonParent($el) {
    let $trueParent = null;

    if (!(exists($el)) && exists(this.$button)) {
      $el = this.$button;
    }

    if (exists($el)) {

      if (exists($el.parents('.av-calendar-options'))) {
        this.$parent = $el.parents('.av-calendar-options');
        this.$trueParent = this.$parent; // force true parent for Availability Calendar
      } else {
        // REVIEW Should be checking at page level, not the container or container fluid level
        this.$parent = exists($el.parents('.container')) ? $el.parents('.container') : $el.parents('.container-fluid');
      }

      if (!$trueParent) {
        this.$parent.parents().each((i, el) => {
          if ($trueParent !== null || $(el).is('.page,body,html')) {
            return;
          }

          if ($(el).css('position') === 'fixed' || $(el).css('position') === 'absolute') {
            $trueParent = $(el);
            return;
          }
        });
      }

      this.$parent = ($trueParent) ? $trueParent : this.$parent;

      return this.$parent;
    }
  }
  setButton($btn) {
    this.$button = $btn;

    this.getButtonParent();
    this.getContainerFromButton();
  }
  hideContainer() {
    this.$container.off('click', this.stopProp).stop()
      .trigger(this.events.closeDropdown)
      .removeClass(this.containerClass)
      .animate(this.props.hide.css, this.props.hide.opts);
    this.$container.find('.dropdown-overflow').removeAttr('style');

    if (!this.$parent.is('.modal')) {
      this.$parent.css({
        zIndex: this.$parent.data('z-index'),
      });
    }
    this.endWatchHeight();

    // Remove styles for Rewards Header BB Calendar reposition after close animation
    if (!isMobileWidth() && this.$container.is('.booking-dates-dropdown') && this.$container.parents('.header-mm-dropdown').length > 0) {
      let $closingContainer = this.$container;
      setTimeout(() => {
        $closingContainer.removeClass('calendar-compact').css('top', '')
          .find('.arrow-up')
          .removeClass('arrow-right');
      }, this.props.hide.opts.duration);
    }

  }
  handleCloseDropdownEvents() {
    $('body').one('click focus', (e) => {
      this.closeDropdown();
    });
    $('body').on('keyup', (e) => {
      if (e.keyCode === $.ui.keyCode.ESCAPE) {
        this.closeDropdown();
      }
    });
  }
  showContainerMobile(top, event) {
    if (this.$container.is('.booking-dates-dropdown')) {
      this.props.hide.css.top = '-100%';
    } else {
      this.props.hide.css.top = this.$container.position().top;
    }
    this.props.show.css.top = top;

    if (exists(this.$saveCancelBar) && exists(this.$arrow)) {
      if(exists(this.$container.closest('.generic-modal')) && !isMobileWidth()) {
        this.$arrow.height('auto');
        this.$arrow.siblings('.dropdown-overflow').find('.wyndham-rewards-checkbox')
          .css('margin-top', this.$saveCancelBar.outerHeight());
      } else {
        this.$arrow.siblings('.dropdown-overflow').find('.wyndham-rewards-checkbox')
          .css('margin-top', 0);
      }

      this.$parent.css('overflow', 'visible');
    }

    this.showContainer();
  }
  showContainer() {
    this.$container.click(this.stopProp).stop()
      .trigger(this.events.open)
      .animate(this.props.show.css, this.props.show.opts);

  }
  positionContainer(event) {
    this.$container.show();

    if (!isMobileWidth() && this.$container.is('.booking-dates-dropdown')) {
      this.$container.width(getElTrueWidth($('.ui-datepicker-group-first')) + getElTrueWidth($('.ui-datepicker-group-last')) + ($('body').is('.win') ? 17 : 2));
    }

    let buttonWidth = this.$button.width(),
      buttonLeft = this.$button.parent().offset().left,
      dropdownWidth = getElTrueWidth(this.$container);

    if (this.$button.data('group')) {
      buttonWidth = this.$button.parent().width();
      buttonLeft = this.$button.parent().parent()
        .offset().left;
    }

    if (isMobileWidth()) {
      let top = (this.props.show.css.top !== null && this.props.show.css.top !== undefined) ? this.props.show.css.top : 0;

      this.$container[0].style.left = '';
      if(this.props.show.css.left){
        delete this.props.show.css.left;
      }

      this.$container[0].style.width = '';
      if(this.props.show.css.width){
        delete this.props.show.css.width;
      }

      this.showContainerMobile(top, event);
    } else {
      this.$container.detach().insertAfter(this.$button);
      if (dropdownWidth > getElTrueWidth(this.$container) && !this.$container.is('.rooms-and-guests-container,.more-options-container')) {
        dropdownWidth = getElTrueWidth(this.$container);
      }

      let em = Math.min(getCSSPropertyAsInt(this.$button.parents('.container'), 'padding-left'), 10), // getCSSPropertyAsInt( $container, 'font-size' ),
        left = buttonLeft + buttonWidth / 2 - dropdownWidth / 2,
        containerOpts = {
          left: this.$container.is('.intl-dropdown') ? getCSSPropertyAsInt(this.$button.parent(), 'padding-left') : left - buttonLeft,
          width: dropdownWidth
        };

      // Reposition for Rewards Header BB Calendar
      if (this.$container.is('.booking-dates-dropdown') && this.$container.parents('.header-mm-dropdown').length > 0) {
        this.$container.addClass('calendar-compact');

        let calendarHeight = getElTrueHeight(this.$container),
          buttonHeight = this.$button.parent().height();

        dropdownWidth = getElTrueWidth($('.ui-datepicker-group-first')) + getElTrueWidth($('.ui-datepicker-group-last')) + ($('body').is('.win') ? 17 : 2);

        containerOpts = {
          left: -dropdownWidth - 12,
          top: buttonHeight / 2 - calendarHeight / 2,
          width: dropdownWidth
        };

        this.$container.find('.arrow-up').addClass('arrow-right');
      } else {
        if (left + dropdownWidth > (getWindowWidth() - em)) {
          containerOpts = {
            left: 'auto',
            right: em,
            width: dropdownWidth
          };

          this.$arrow.css({
            left: dropdownWidth - (buttonWidth / 2) + containerOpts.right
          });

        } else if (left < em) {
          containerOpts.left = em;

          this.$arrow.css({
            left: buttonWidth / 2 - containerOpts.left
          });
        }

        this.$container.css({
          top: ''
        });
      }

      this.$container.css(containerOpts);

      this.showContainer();
    }

    if (!this.$parent.is('.modal') && !this.$button.data('dropdownStaticContainer') && isMobileWidth()) {
      let setZindex = this.$container.css('zIndex');
      if(brand_id === 'wr') {
        setZindex = 999;
      }
      this.$parent.attr('data-z-index', this.$parent.css('zIndex')).css({
        zIndex: setZindex
      });
    }

    this.checkDropdownBottomOffScreen();
  }
  handleButtonBlur(e) {
    let $focus = $(e.relatedTarget);

    if (!($focus.length) || $focus.is('[data-dropdown]') || $focus.is('[data-button]') || $focus.parents('[data-button]').length > 0) {
      return;
    }

    this.closeDropdown();
  }
  turnButtonOn() {
    this.$button.blur((e) => this.handleButtonBlur(e)).trigger(this.events.opening)
      .addClass(this.buttonClass);
    this.$container.find('input, button').last()
      .blur((e) => this.handleButtonBlur(e));
  }
  dropdownInputFocus($el) {
    $('.more-options-container .code-container input', $el).click((e) => {
      let ele = e.currentTarget;
      $(window).resize(ele, () => {
        this.adderScrollOption($(ele).offset().top);
      });
      if (isTabletWidth()) {
        // Booking Bar positioned with different container on Rewards
        if (getBrand() === 'WR') {
          scrollToAnimated($('form.booking-bar-main').css('top'), $('document'));
        } else {
          scrollToAnimated($_BOOKING_BAR_MAIN_.css('top'), $('document'));
        }

      }
    });
  }
  adderScrollOption(pushVal) {
    if (isMobileWidth()) {
      $('.more-options-container .dropdown-overflow').css({
        'overflow-y': 'scroll !important'
      });
      $('.more-options-container .dropdown-overflow').height($(window).height() - $('.more-options-container .dropdown-overflow .save-cancel-container').outerHeight());
      if (pushVal > $('.more-options-container .dropdown-overflow').height()) {
        scrollToAnimated($('.more-options-container .dropdown-overflow')[0].scrollHeight - $('.more-options-container .dropdown-overflow').height(), .25, $('.more-options-container .dropdown-overflow'));
      } else {
        scrollToAnimated(pushVal, .25, $('.more-options-container .dropdown-overflow'));
      }
    }
  }
  open($btn, event) {
    if (this._doingSomething) {
      $('body').one(this.events.finished, () => {
        if($btn){
          this.open($btn);
        }
      });
      return;
    }

    this._doingSomething = true;

    if (this.watch === null) {
      this.watch = new WatchHandler();
    } else {
      this.watch.stop();
    }

    if(exists(this.$button) && exists($btn)) {
      if (this.targetIsInButtonGroup($btn)) {
        this.turnButtonOff();
        this.setButton($btn);
        this.turnButtonOn();
        this.positionContainer();

        return;
      } else if ($btn.is(this.$button)) {
        this.closeDropdown();

        return;
      } else {
        this.props.show.opts.duration = (this.options.timing / 2);
        this.props.hide.opts.duration = (this.options.timing / 2);

        this.$container.one(this.events.closedDropdown, () => {
          if($btn){
            this.open($btn);
            this.props.show.opts.duration = this.options.timing;
            this.props.hide.opts.duration = this.options.timing;
          }
        });

        return this.closeDropdown();
      }
    }

    this.setButton($btn);
    this.handleCloseDropdownEvents();
    this.turnButtonOn();
    this.positionContainer(event);

    ResizeHandler.addResizeStartFn(this.resizeEnd, 'width');
  }

  init($el) {
    if (_isNull($el)) {
      $el = $('body');
    }

    let arrow = $(document.createElement('span')).addClass('arrow-up');
    $el.find(this.containerSelector).prepend(arrow);

    this.$button = $(this.focusedSelector, $el);
    if (exists(this.$button)) {
      this.getContainerFromButton();
    }

    let collectEvents = (() => {
      let events = {},
        timeout = null,

        clearTO = () => {
          window.clearTimeout(timeout);
          timeout = null;
        },

        fn = (e) => {
          if (e.type == 'focus' && $el.find(e.currentTarget).is('.on')) {
            return;
          }

          events[e.type] = e;

          clearTO();
          timeout = window.setTimeout(() => {
            if (('click' in events) && $el.find(events.click.currentTarget)) {
              this.open($el.find(events.click.currentTarget), events.click);
            } else if ('focus' in events && $el.find(events.focus.currentTarget)) {
              this.open($el.find(events.focus.currentTarget), events.focus);
            }

            events = {};
            timeout = null;
          }, 222);
        };

      return {
        fn: fn,
        clear: clearTO
      };
    })();
    collectEvents.clear();

    $el.find('[data-dropdown^="."]').on('click', (e) => {
      e.stopPropagation();

      _.debounce(collectEvents.fn(e), 300);
    });

    if (!this.initialized) {
      this.setAnimationProperties();
    }
    this.dropdownInputFocus($el);
    this.initialized = true;
  }
}

export default new Dropdown();
