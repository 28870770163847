export default class MapMarker {
    constructor(data, searchMap) {
        this.SearchMap = searchMap;
        this.overlayview = new window.google.maps.OverlayView();
        this.overlayview.draw = this.draw.bind(this);
        this.overlayview.remove = this.remove.bind(this);
        this.latlng = new window.google.maps.LatLng(data.lat, data.lng);
        this.lat = data.lat;
        this.lng = data.lng;
        this.textPin = data.textPin;
        this.decimals = data.decimals;
        this.useIcon = data.useIcon;
        this.data = data;
        this.div = null;
    }
    draw() {
        let point, panes, sup, span;

        if (!this.div) {
            this.div = document.createElement('div');
            this.div.className = 'map-base-pin clickable map-price-pin' + (this.data.notAvailable ? ' not-available' : '') + (this.data.highlight ? ' highlight' : '');

            if (this.data.hidden) {
                this.div.style.display = 'none';
            }

            if (this.useIcon) {
                this.div.className += ' map-icon-pin';
                span = document.createElement('span');
                span.className = 'map-icon';
                this.div.appendChild(span);
            } else {
                this.div.className += ' map-price-pin' + (this.data.notAvailable ? ' not-available' : '');

                if (this.data.notAvailable) {
                    let index = this.textPin.lastIndexOf(' ');

                    if (index > 0) {
                        this.textPin = this.textPin.substring(0, index) + '<br/>' + this.textPin.substring(index + 1);
                    }
                }

                this.div.innerHTML = this.textPin;

                if (this.decimals !== '') {
                    sup = document.createElement('sup');
                    sup.innerHTML = this.decimals;
                    this.div.appendChild(sup);
                }
            }

            window.google.maps.event.addDomListener(this.div, 'click', (event) => {
                // remove previously selected pin
                $('.map-price-pin.selected').removeClass('selected');
                // add the selected class
                this.div.className += ' selected';
                // show the hotel details
                this.SearchMap.showHotelDetails(this.data, this.latlng);
                window.google.maps.event.trigger(this, 'click');
            });
            panes = this.overlayview.getPanes();
            panes.overlayImage.appendChild(this.div);
        }
        point = this.overlayview.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            this.div.style.left = (point.x - ($(this.div).outerWidth() / 2)) + 'px';
            this.div.style.top = (point.y - ($(this.div).outerHeight() + 6)) + 'px';
        }
    }
    remove() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    }
    setMap(map) {
        this.overlayview.setMap(map);
    }
}
