
import Channels from "./channels";
import {_defaultChannelId, _LOCALE_, brand_id} from "../vars";
import {_isValidBrand} from "../utils";
import { getChanel } from "../dom-utils";

const ALL = 'all';
const ANY = [];
const BRAND_TIER_SEPARATOR = ':';

/**
 * Returns the first AEM Config that satisfies the provided parameters.
 * If no AEM Configs satisfy the provided parameters, undefined is returned.
 * @param {string} key - The key for which the AEM Config exists for
 * @param {string[]} [channels=Web] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=Current locale] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=Current brand] - The brands for which the AEM Config exists for
 */
const findConfig = (key, channels, locales, brands) => {
    channels = channels || Channels.Web;
    locales = locales || [getCurrentLocale()];
    brands = brands || [getCurrentBrand()];
    const configs = getConfigsFromKey(key);

    return configs.find(config => {
        return (
            isConfigForChannels(config, channels) &&
            isConfigForLocales(config, locales) &&
            isConfigForBrands(config, brands)
        );
    });
};

/**
 * Returns a list of all AEM Configs that satisfy the provided parameters.
 * If no AEM Configs satisfy the provided parameters, an empty list is returned.
 * @param {string} key - The key for which the AEM Config exists for
 * @param {string[]} [channels=Web] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=Current locale] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=Current brand] - The brands for which the AEM Config exists for
 * @returns {Object[]}
 */
const getConfigs = (key, channels, locales, brands) => {
    channels = channels || Channels.Web;
    locales = locales || [getCurrentLocale()];
    brands = brands || [getCurrentBrand()];
    const configs = getConfigsFromKey(key);

    return configs.filter(config => {
        return (
            isConfigForChannels(config, channels) &&
            isConfigForLocales(config, locales) &&
            isConfigForBrands(config, brands)
        );
    });
};

/**
 * Returns brand and tier codes from the provided config.
 * If the 'tiersAttributeName' param is not provided, calculate the tiers from the value of the 'brandsAttributeName' property of the 'config'.
 *
 * Note - The 'brands' property in the output contains the AEM brand code i.e lq for LaQuinta, gn for Wyndham Garden, dz for Dazzler etc.
 * and the 'bwsBrands' proeprty contains the BWS brand code i.e. lq for LaQuinta, wy for Wyndham Grand, wy for Wyndham Garden etc.
 * While the 'tiers' property contains the brand tier used for the brand at the BWS Services side. Eg. gn for Wyndham Garden, dz for Dazzler, es for Esplendor.
 * The 'brandsWithTiers' property contains the config value as it is i.e. lq for LaQuinta, gn:wy for Wyndham Garden, dz:fe for Dazzler.
 *
 *
 * @param {object} config - The config from which the data needs to be fetched
 * @param {string} brandsAttributeName - property in the config that contains the brands
 * @param {string} tiersAttributeName - property in the config that contains the tiers
 * @returns {object} - JSON with brands, tiers, and brandsWithTiers property.
 */
const getBrandsAndTiersFromConfig = (config, brandsAttributeName, tiersAttributeName) => {
    let configuredBrands = (config ? config.attributes[brandsAttributeName] : []);

    // clone of configured brands to return as it is so that the brands and tiers mapping can be used by the caller.
    let brandsWithTiers = [].concat(configuredBrands);

    // clone of configured brands to return list of brands for BWS Services.
    let bwsBrands = [].concat(configuredBrands);

    let brandTiers = [];

    if(tiersAttributeName) {
        brandTiers = (config ? config.attributes[tiersAttributeName] : []);
        configuredBrands.forEach((brand, index) => {
            const isTierBrand = brand.indexOf(BRAND_TIER_SEPARATOR) !== -1;
            if (isTierBrand) {
                let brandAndTier = brand.split(BRAND_TIER_SEPARATOR);
                configuredBrands[index] = brandAndTier[0];
                bwsBrands[index] = brandAndTier[1];
            }
        });
    } else {
        configuredBrands.forEach((brand, index) => {
            const isTierBrand = brand.indexOf(BRAND_TIER_SEPARATOR) !== -1;
            if (isTierBrand) {
                let brandAndTier = brand.split(BRAND_TIER_SEPARATOR);
                configuredBrands[index] = brandAndTier[0];
                brandTiers.push(brandAndTier[0]);
                bwsBrands[index] = brandAndTier[1];
            }
        });
    }

    return {
        brands: configuredBrands,
        tiers: removeDuplicates(brandTiers),
        bwsBrands: removeDuplicates(bwsBrands),
        brandsWithTiers: brandsWithTiers,
    };
};

function isConfigForChannels(config, channels) {
    return !config.channels || includes(config.channels, channels);
}

function isConfigForLocales(config, locales) {
    return !config.locales || includes(config.locales, locales);
}

function isConfigForBrands(config, brands) {
    brands.forEach((brand, index) => {
        const tier = window.brandTierMap[brand];
        if (tier && !brand.includes(BRAND_TIER_SEPARATOR)) {
            brands[index] = brand + BRAND_TIER_SEPARATOR + tier;
        }
    });
    return !config.brands || includes(config.brands, brands);
}

function includes(array, values) {
    return (
        values === ANY ||
        array.includes(ALL) ||
        values.every(currentValue => array.includes(normalizeString(currentValue)))
    );
}

function normalizeString(string) {
    return string.toLowerCase();
}

function getConfigsFromKey(key) {
    return (window.wyndham && window.wyndham.configs && window.wyndham.configs[key]) ? window.wyndham.configs[key].configs : [];
}

function getAPIFromKey(key) {
  return (window.wyndham && window.wyndham.configs && window.wyndham.configs[key]) ? window.wyndham.configs[key] : [];
}

function removeDuplicates(array) {
    return Array.from(new Set(array));
}

function getCurrentLocale() {
    return window._SEO_LOCALE_ || 'en-us';
}

function getCurrentBrand() {
    return window.brand_id;
}

function includesCurrentBrand(brands) {
    if (!brands) return false;
    return brands.includes(getCurrentBrand()) || brands.includes(ALL);
}

function includesLocale(locales) {
    if (!locales) return false;
    return locales.includes(window._LOCALE_) || locales.includes(ALL);
}

function getSMSPersonalizationDisabled() {
    return this.getConfigs('disable-sms-personalization',_defaultChannelId,[_LOCALE_],this.Any).reduce((result , item) => {
      return (_isValidBrand(window.brand_id) && (includesCurrentBrand(item.brands) || includesLocale(item.locales) || item.attributes)) ? item : result;
    }, null);
  }

function getUpliftBrandDisabled() {
  return this.getConfigs('disable-bnpl',_defaultChannelId,[_LOCALE_],this.Any).reduce((result , item) => {
    return (_isValidBrand(window.brand_id) && item.brands.includes(window.brand_id) && item.locales.includes(window._LOCALE_)) ? item : result;
  },null);
}

function getMychecktV3Enabled() {
  // shiji-mycheck-settings
  return (this.getConfigs('enable-mycheck-version-6',_defaultChannelId,[_LOCALE_],this.Any).reduce((result , item) => {
    return (_isValidBrand(window.brand_id) && item.brands && item.locales && (item.brands.includes(window.brand_id) || (item.brands.includes('all'))) && (item.locales.includes(window._LOCALE_) || item.locales.includes('all')) ) ? item : result;
  },null) === null);
}


function getMycheckConfiguration() {
    return  (this.getConfigs('mycheck-configuration',_defaultChannelId,[_LOCALE_],this.Any).length > 0) ? this.getConfigs('mycheck-configuration',_defaultChannelId,[_LOCALE_],this.Any)[0] : {} ;
  }

function getCardValue(ccid) {
  let mycheckCards = (this.getConfigs('mycheck-credit-cards-mapping',_defaultChannelId,[_LOCALE_],this.Any).length > 0) ? this.getConfigs('mycheck-credit-cards-mapping',_defaultChannelId,[_LOCALE_],this.Any)[0] : {} ;
  return mycheckCards[ccid.toLowerCase()] || '';
}

function getJoinNowTimeout(){
  // console.log('getJoinNowTimeout called ');
  return this.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], this.Any).reduce(
    (result, item) => {
      // console.log({result, item});
      let joinNowTimeoutValue = item.name === 'join_now_timeout' && item.value ? item.value : result;
      // console.log('joinNowTimeoutValue: ', joinNowTimeoutValue);
      return joinNowTimeoutValue;
    },
    null
  );
}

function getViewCashOrPointsConfig(){
    return  (this.getConfigs('view-cash-or-points-config',getChanel(),[_LOCALE_],[brand_id]).length > 0) ? this.getConfigs('view-cash-or-points-config',getChanel(),[_LOCALE_],[brand_id])[0] : {} ;
}

function getAirlineMilesPartners() {
  return getAPIFromKey('airlineMilesPartners');
}
function getMycheckV3LanguageValue(locale) {
    let mycheckLocale = (this.getConfigs('mycheck-locale-mapping',_defaultChannelId,[_LOCALE_],this.Any).length > 0) ? this.getConfigs('mycheck-locale-mapping',_defaultChannelId,[_LOCALE_],this.Any)[0] : {} ;
    return mycheckLocale[locale] || '';
  }

export default {
    All: ALL,
    Any: ANY,
    BrandTierSeparator: BRAND_TIER_SEPARATOR,
    findConfig,
    getConfigs,
    getViewCashOrPointsConfig,
    getBrandsAndTiersFromConfig,
    getUpliftBrandDisabled,
    getMychecktV3Enabled,
    getCardValue,
    getSMSPersonalizationDisabled,
    getJoinNowTimeout,
    getAirlineMilesPartners,
    getMycheckConfiguration,
    getMycheckV3LanguageValue
};
