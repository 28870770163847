import {
  exists,
  getElTrueHeight,
  ResizeHandler,
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth
} from '../../base/dom-utils.js';
import { $_BOOKING_BAR_MINI_NAV, $_HEADER_, $_PAGE_, $_BOOKING_BAR_MAIN_ } from '../../base/vars.js';

class UUBookingBar {
  constructor() {
    if ($_PAGE_.is('.uu-property')) {
      if (isMobileWidth()) {
        if ($('.uuproperty-notification-banner') && $('.booking-bar-form.mini-booking').parent() && ($('.uuproperty-notification-banner').length > 0 && $('.booking-bar-form.mini-booking').parent().length > 0)) {
          let elenb = $('.uuproperty-notification-banner').detach();
          $('.booking-bar-form.mini-booking').parent().find('.uu-subnav-bg').before(elenb);
        }
        this.uuBookingPositioner();
      }
      this.setMiniBookingZIndex();
      this.handlePageResize();
    }
  }

  setMiniBookingZIndex() {
    if (exists($_BOOKING_BAR_MINI_NAV)) {
      let zIndex = $_HEADER_.css('z-index');

      $('#headerLinkContainer').on('show.bs.collapse', () => {
        $_HEADER_.css({
          zIndex: 101
        });
      })
        .on('hidden.bs.collapse', () => {
          $_HEADER_.css({
            zIndex: zIndex
          });
        });
    }
  }

  handlePageResize() {
    //UU Page Resize
    ResizeHandler.addResizeEndFn(() => {
      this.uuBookingBarStick();
      this.uuBookingPositioner();
      this.uuBookingTabletDesktop(false);
      $('#bookingBar__mini').css({
        'display': 'block'
      });
      $('.booking-bar.open').removeClass('open');
      $('#magnifyingGlassButton').attr('aria-expanded', false);
      if ($_PAGE_.is('.uu-overview') && isMobileWidth()) {
        $('.booking-bar-form.mini-booking').parent()
          .css({
            'display': 'block'
          });
      }
    });
  }

  uuBookingBarStick() {
    if (isMobileWidth() && exists('#uuCarousel')) {
      $('.uu-hero-carousel .property-info .mobile-buttons a').css('height', $(
        '.uu-hero-carousel .property-info .mobile-buttons a').width() + 4);

      let alertHeight = 0;

      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height();
      }

      if ($(window).scrollTop() >= $('#uuCarousel').outerHeight() + $('.uu-umbrella-promo-banner')
        .outerHeight() - 20) {
          $('.property-info.info-mobile').addClass('fix-property-info');
        $('#bookingBar__mini').addClass('stick');
        
        $('.uu-subnav-bg').css({
          'position': 'fixed',
          'top': $('.uu-property #bookingBar__mini').outerHeight() + $(
            '.info-mobile.fix-property-info').outerHeight() + alertHeight
        });
      } else {
        $('.property-info.info-mobile').removeClass('fix-property-info');
        $('#bookingBar__mini').removeClass('stick');
        $('.uu-subnav-bg').css({
          'position': 'relative',
          'top': alertHeight
        });
        this.uuBookingTabletDesktop(false);
      }
    } else {
      if ($(window).scrollTop() >= $('.navbar-header').outerHeight()) {
        $('#bookingBar__mini').addClass('stick');
        this.bookingRetainRelativity(true);
        this.uuBookingTabletDesktop(true);
        $_BOOKING_BAR_MAIN_.css({
          'position': 'fixed'
        });
      } else {
        $('#bookingBar__mini').removeClass('stick');
        this.bookingRetainRelativity(false);
        this.uuBookingTabletDesktop(false);
      }
    }
  }

  uuBookingPositioner() {
    $('.property-page-hero-carousel-component').css({
      'marginTop': '0'
    });
    //More specific changes
    if (isMobileWidth()) {
      $('#pageHeader').css({
        'position': 'relative',
        'top': 0
      });
      $('#pageHeadernew').css({
        'position': 'relative',
        'top': 0
      });
      let heroHeight = $('#uuCarousel').height();
      let headerHeight = $('#pageHeader').is(':visible') ? $('#pageHeader').height() : $('#pageHeadernew').height();
      let alertHeight = 0;

      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height();
      }
      if($('.component.property-page-hero-carousel-component').next().length > 0) {
        $('.booking-bar-form.mini-booking').parent()
          .css({
            'position': 'absolute',
            'top': heroHeight + headerHeight + alertHeight + $('.uu-umbrella-promo-banner').outerHeight(),
            'width': '100%'
        });
      }
      //Wait till components are loaded before calculing the height
      $(() => {
        $('.component.property-page-hero-carousel-component').next().css('marginTop', $('.booking-bar-form.mini-booking').parent().height());
      });

    } else {
      if (isTabletWidth()) {
        $('.property-page-hero-carousel-component').css({
          'position': 'relative'
        });
      }
      $('.booking-bar-form.mini-booking').parent()
        .css({
          'position': 'relative',
          'top': 'auto',
          'width': '100%'
        });
      $('.component.property-page-hero-carousel-component').next()
        .css(
          'marginTop', '0'
        );
    }
  }

  uuBookingTabletDesktop(flagger) {
    if (isDesktopWidth()) {
      $('#headerLinkContainer')[0].style.top = '';
      $('#headerLinkContainer')[0].style.position = '';
      if (flagger) {
        $('.booking-bar-form.mini-booking').parent()
          .css({
            'position': 'fixed',
            'top': '0',
            'z-index': '100'
          });
      } else {
        $('#pageHeader').css({
          'position': 'absolute',
          'top': $('.uu-umbrella-promo-banner').outerHeight()
        });
        $('.booking-bar-form.mini-booking').parent()
          .css({
            'position': 'absolute',
            'top': $('.uu-umbrella-promo-banner').outerHeight() + $('#pageHeader').outerHeight()
          });
        if (!exists($('.property-page-hero-carousel-component'))) {
          $('#pageHeader').css({
            'position': 'relative',
            'top': '0'
          });
          $('.booking-bar-form.mini-booking').parent()
            .css({
              'position': 'relative',
              'top': '0'
            });
        }
      }
    } else if (isTabletWidth()) {
      $('.property-page-hero-carousel-component').css({
        'position': 'relative'
      });
      if (!flagger) {
        $('#pageHeader').css({
          'position': 'relative',
          'top': '0'
        });
        $('#pageHeadernew').css({
          'position': 'relative',
          'top': '0'
        });
        $('#headerLinkContainer').css({
          position: 'absolute',
          top: getElTrueHeight($('#pageHeader'))
        });
        $('.booking-bar-form.mini-booking').parent()
          .css({
            'position': 'relative',
            'top': '0'
          });
      }
    } else if (isMobileWidth()) {
      let headerPosition = exists('.uu-umbrella-promo-banner') ? $('.uu-umbrella-promo-banner').outerHeight() :
        0;
      $('#pageHeader').css({
        'position': 'absolute',
        'top': headerPosition
      });
      if (!exists($('.property-page-hero-carousel-component'))) {
        $('#pageHeader').css({
          'position': 'relative',
          'top': '0'
        });
        $('#pageHeadernew').css({
          'position': 'relative',
          'top': '0'
        });
        $('.booking-bar-form.mini-booking').parent()
          .css({
            'position': 'relative',
            'top': '0'
          });
      }
    }
  }

  bookingRetainRelativity(flagger) {
    let alertHeight = 0;

    if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
      alertHeight = $('.alert-component').height();
    }
    if (flagger) {
      $('.uu-subnav-bg').css({
        'position': 'fixed',
        'top': $('.uu-property #bookingBar__mini').outerHeight() + alertHeight
      });
    } else {
      $('.uu-subnav-bg').css({
        'position': 'relative',
        'top': alertHeight
      });
    }
  }

}

export default new UUBookingBar();
