class WyndhamBusinessPage {
    constructor() {
        let content = document.getElementById('drift-api');
        if (content) {
            $('#drift-api').on('click', function() {
              if (window.drift) {
                window.drift.api.startInteraction({ interactionId: 290421 });
              }
              return;
            });
        }
    }
}

export default new WyndhamBusinessPage();
