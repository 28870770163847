import {
  BBForm,
  BBMoreOptionsClass
} from './booking-bar.js';
import Dropdown from './dropdown.js';
import {
  getCriteria,
  normalizeSearchCriteria,
  setCriteria,
  getBrand,
  buildSearchQuery
} from '../base/session-handler.js';
import {
  _isDateType,
  _isNotNull,
  formatDateForBWSAvailability,
  formatDateForPrinting,
  formatDateForBWS,
  getDateFromDateString,
  getNextDay,
  getToday,
  isPriorDate,
  getNumDays,
  getXDays,
  getParameterByName,
  getQueryParameter,
  removeEndingAmp,
  callService,
  EventHandler,
  isAllInPriceRegion,
  allyPrevNextDatePicker,
  convertToCurrency,
  getSelectedCurencyCode,
  getCurrencyMapping,
  isFeesInclude
} from '../base/utils.js';
import {
  isCompactView,
  isMobileWidth
} from '../base/dom-utils.js';
import {
  availabilityCalendarConfig,
  additionalCorpCodes,
  countryCodeList
} from '../base/vars.js';
import BookingConfig from '../base/aem-configs/booking-config.js';

/** START: Mock Services Code **/

function generateMockParseDate(value) {
  if (_isDateType(value)) {
    return new Date(value);
  } else {
    return getDateFromDateString(value);
  }
}

function generateMockCurrencyCode() {
  var locale = window._LOCALE_ || window.geoLocation || 'en-us',
    currencyCode = 'USD';

  if (locale === 'tr-tr' || locale === 'de-de') {
    currencyCode = 'EUR';
  } else if (locale === 'ko-kr') {
    currencyCode = 'KRW';
  }

  return currencyCode;
}

function generateAvailableStatus(params) {
  if (params.promotionCode == '1000011084') {
    return 'MinStay';
  } else if (params.promotionCode == '1000022313') {
    return 'MaxStay';
  }
  return 'Open';
}

function generateClosedStatus(params) {
  if (params.promotionCode == '1000008323' && !location.search.match(/mockAvailabilityCalendarForceClosed=true/)) {
    return 'ClosedOnArrival';
  }
  return 'Close';
}

function generateMockResponseNewStay(date, available, price, params) {
  return {
    startDate: formatDateForBWSAvailability(date),
    endDate: formatDateForBWSAvailability(date),
    amountBeforeTax: price.toFixed(2),
    amountAfterTax: (price * 1.1).toFixed(2),
    status: available ? generateAvailableStatus(params) : generateClosedStatus(params),
    quantity: 1 + Math.ceil(Math.random() * 3)
  };
}

function generateMockResponse(params) {
  var start = generateMockParseDate(params.stayDateRangeStart),
    end = generateMockParseDate(params.stayDateRangeEnd),
    curr = start,
    hotelStays = [],
    response = $.extend({
      currencyCode: generateMockCurrencyCode(),
      hotelStayAvailability: hotelStays
    }, params),
    stay = null,
    lastAvailable = true,
    lastPrice = 100,
    forceClosed = location.search.match(/mockAvailabilityCalendarForceClosed=true/);


  while (curr <= end) {
    var available = forceClosed ? false : Math.round(Math.random() * 100) <= (lastAvailable ? 90 : 50),
      priceRandom = Math.round(Math.random() * 100),
      price;

    if (curr.getDate() <= 1) {
      price = 100;
    } else if (priceRandom <= 75) {
      price = lastPrice;
    } else if (priceRandom <= 85) {
      price = lastPrice - Math.round(Math.random() * 100 * lastPrice * 0.15) / 100;
    } else {
      price = lastPrice + Math.round(Math.random() * 100 * lastPrice * 0.2) / 100;
    }

    if (!stay || (available && stay.status === 'Close') || (!available && stay.status === 'Open') || (stay.amountBeforeTax != price.toFixed(2))) {
      stay = generateMockResponseNewStay(curr, available, price, params);
      hotelStays.push(stay);
    } else {
      stay.endDate = formatDateForBWSAvailability(curr);
    }

    lastAvailable = available;
    lastPrice = price;
    curr.setDate(curr.getDate() + 1);
  }

  if (location.search.match(/mockAvailabilityCalendarForceUnavailable=true/)) {
    delete response.hotelStayAvailability;
  }

  if (location.search.match(/mockAvailabilityCalendarForceError=true/)) {
    response.ErrorCode = 11;
  }

  return response;
}

/** END: Mock Services Code **/

class AvailabilityCalendar {
  constructor() {
    this.availabilityHandler = null;
    this.datePickerHandler = null;
    this.ratesDataHandler = null;
    this.moreOptionsHandler = null;
    this.$el = null;
    this.options = null;
    this.data = null;
  }
  init(el, options) {
    this.$el = el;
    this.options = options;
    this.data = this.getSearchData(options);

    this.availabilityHandler = new AvailabilityHandler(this.$el, this.data);
    this.datePickerHandler = new DatePickerHandler(this.options, this.data, this.availabilityHandler);
    this.ratesDataHandler = new RatesDataHandler(this.$el, this.data);

    this.moreOptionsHandler = new BBMoreOptionsClass(this.ratesDataHandler, null, this.$el, {
      updateLabelOnlyOnSave: true,
      quickFireSave: true,
      displayRateCodeValue: true
    });

    if (this.options.isSearchResults) {
      this.handleApplyToAll();
    }

    this.setMinStay();
    this.handleBookNow();
    this.handleReset();
    Dropdown.init(el);
    // this.moreOptionsHandler.init(); Probably no longer needed - merged with constructor
    this.$el.removeClass('hidden');
    this.datePickerHandler.init(el);
    this.availabilityHandler.onDataLoaded(() => {
      this.datePickerHandler.refresh();
    });
    this.datePickerHandler.onDatesSelected(() => {
      let rate = this.availabilityHandler.calculateRate(this.data.checkInDate, this.data.checkOutDate);
      this.setAverageDailyRate(rate.numberOfNights, rate.averageDailyRate, rate.currencySymbol, rate.currencyCode,rate.priceDisplayType,rate.tpdCmaOverride);
    });
    this.ratesDataHandler.onChange(() => this.selectionReset());
    this.ratesDataHandler.init();
    this.availabilityHandler.reset();
    this.data.initialLoadStarted = true;
  }
  getSearchData(options) {
    let criteria = getCriteria(),
      rate = BBForm.getSpecialRate(),
      checkInDate = getDateFromDateString(criteria.checkInDate),
      checkOutDate = getDateFromDateString(criteria.checkOutDate);

    if (rate && (rate.type === 'promotional' || rate.type === 'group')) {
      rate = null;
    }

    if (!(_isDateType(checkInDate)) || isPriorDate(checkInDate)) {
      checkInDate = getToday();
    }

    if (!(_isDateType(checkOutDate)) || isPriorDate(checkOutDate, checkInDate)) {
      checkOutDate = getNextDay(checkInDate);
    } else if (isPriorDate(checkOutDate)) {
      checkInDate = getToday();
      checkOutDate = getNextDay();
    }

    checkInDate.setHours(0, 0, 0, 0);
    checkOutDate.setHours(0, 0, 0, 0);

    return {
      brand: getBrand(),
      rate: rate,
      checkInDate: checkInDate,
      originalCheckInDate: checkInDate,
      checkOutDate: checkOutDate,
      originalCheckOutDate: checkOutDate,
      initialDates: true,
      minStay: options.minStay || $('.booking-dates-container').data('min-los') || 1,
      adults: BBForm.getAdults(),
      children: BBForm.getChildren(),
      childAges: BBForm.getChildAges(),
      propertyId: options.propertyId,
      roomTypeNumberOfUnits: BBForm.getRooms()
    };
  }
  resetAllCriteria() {
    let ac = getCriteria(),
      fields = ['corpCode', 'groupCode', 'rateCode', 'ratePlan', 'useWRPoints'];

    $.each(fields, (index, field) => {
      if (ac[field] !== undefined) {
        ac[field] = undefined;
      }
    });

    setCriteria(ac, true);
  }
  setDatesCriteria(data, sc) {
    sc.checkInDate = data.checkInDate;
    sc.checkOutDate = data.checkOutDate;
    return sc;
  }
  setSpecialRateCriteria(code, type, sc) {
    sc.ratePlan = null;
    sc.corpCode = null;
    sc.groupCode = null;
    sc.rateCode = null;
    sc.useWRPoints = false;

    if (type == 'corpCode') {
      sc.corpCode = code;
    } else if (type == 'groupCode') {
      sc.groupCode = code;
    } else if (type == 'rateCode' || type == 'promotional') {
      sc.rateCode = code;
    } else if (code) {
      sc.corpCode = code;
    }
  }
  setSearchData(data) {
    let sc = getCriteria();
    this.resetAllCriteria();

    BBForm.setFrom(data.checkInDate);
    BBForm.setTo(data.checkOutDate);
    sc = this.setDatesCriteria(data, sc);

    if (data.rate) {
      BBForm.setSpecialRate(data.rate.code, data.rate.type);
      this.setSpecialRateCriteria(data.rate.code, data.rate.type, sc);
    } else {
      BBForm.setSpecialRate(null, null);
      this.setSpecialRateCriteria(null, null, sc);
    }

    setCriteria(sc);
  }
  getOriginalCheckInOutDates() {
    let availCalUsed = getParameterByName('availCalUsed'),
      originalCheckInOut = 'availCalUsed=';

    if (availCalUsed != null) {
      originalCheckInOut += availCalUsed;
    } else {
      originalCheckInOut += formatDateForBWSAvailability(getQueryParameter('checkInDate')) +
        '|' + formatDateForBWSAvailability(getQueryParameter('checkOutDate'));
    }

    return originalCheckInOut;
  }
  buildRoomsAndRatesUrl(data) {
    let locale = window._LOCALE_ || window.geoLocation || '';
    return (locale == 'en-us' ? '' : '/' + locale) + '/hotels/rooms-rates' + removeEndingAmp(buildSearchQuery()
      .replace('?', '?hotel_id=' + data.propertyId + '&')) + '&' + this.getOriginalCheckInOutDates();
  }
  getOptions(opts, isMobile) {
    opts = opts || {};

    if (isMobile) {
      opts.mobile = true;
    }

    return opts;
  }
  cloneCalendar() {
    let el = $('#availability-calendar-base').clone();
    el.removeAttr('id');
    el.find('.av-calendar-special-rates-dropdown').addClass('dropdown-container');
    return el;
  }
  buildModal(contentElement, mobile) {
    let modal = $('#availability-calendar-modal').clone(),
      content = modal.find('.modal-content'),
      propertyTitle = $('h1.property-name, h1.mini-booking_property-title-text'),
      title;

    if (mobile) {
      propertyTitle.remove();
      modal.removeClass('fade').addClass('modal-mobile')
        .addClass('modal-overflow')
        .data({backdrop: 'static', refresh: true});
    } else if (propertyTitle.length) {
      let strTitle = '';
      if (propertyTitle.length > 1) {
        strTitle = propertyTitle[0].innerText;
      } else {
        strTitle = propertyTitle.text();
      }
      title = $('<h2 class="headline-c"/>');
      title.text(strTitle);
      content.prepend(title);
    }

    content.append(contentElement);
    $('body').append(modal);

    modal.on('hidden.bs.modal', () => {
      modal.remove();
    });

    modal.on('dropdown:opening', () => {
      modal.addClass('modal-dropdown-open');
    });

    modal.on('dropdown:closing', () => {
      setTimeout(() => {
        modal.removeClass('modal-dropdown-open');
      }, 300);
    });

    return modal;
  }
  isEnabled() {
    return availabilityCalendarConfig && availabilityCalendarConfig.enabled === 'true'; // Comes from AEM
  }
  openModal(opts) {
    let el = this.cloneCalendar(),
      modal = this.buildModal(el);
    this.init(el, this.getOptions(opts));
    modal.modal('show');
  }
  openInline(target, opts) {
    let el = this.cloneCalendar();
    target.append(el);
    this.init(el, this.getOptions(opts));
  }
  openMobile(opts) {
    let el = this.cloneCalendar(),
      modal = this.buildModal(el, true);
    this.init(el, this.getOptions(opts, true));
    modal.modal('show');
    this.onOrientationChange(modal);
    this.handleMobileBodyScroll(modal);
  }
  selectionReset() {
    this.setError();
    this.$el.find('.avg-daily-rate, .avg-rate-btn').addClass('hidden');
    this.data.initialLoadStarted = false; // prevent duplicated requests
    this.datePickerHandler.reset();
    this.availabilityHandler.reset();
    this.data.initialLoadStarted = true;
    this.$el.find('.av-cal-apply-to-all').addClass('disabled');
    this.data.hideRate = false;
  }
  fullReset() {
    this.ratesDataHandler.reset();
    this.moreOptionsHandler.reset();
    this.selectionReset();
  }
  handleReset() {
    this.$el.find('.av-cal-reset a').on('click', (e) => {
      e.preventDefault();
      this.fullReset();
    });
  }
  buildUrlForAnalytics() {
    let url = buildSearchQuery();

    _.contains(url, '?') ? url += '&' : url += '?';
    url += this.getOriginalCheckInOutDates();

    return url;
  }
  handleApplyToAll() {
    let applyToAll = this.$el.find('.av-cal-apply-to-all');
    this.$el.find('.av-cal-reset').removeClass('col-xs-24')
      .addClass('col-xs-12');
    applyToAll.removeClass('hidden').find('a')
      .on('click', (e) => {
        e.preventDefault();

        if (!applyToAll.hasClass('disabled')) {
          this.setSearchData(this.data);
          normalizeSearchCriteria();
          window.location = this.buildUrlForAnalytics();
        }
      });
  }
  handleBookNow() {
    this.$el.find('.avg-rate-btn .book-now').on('click', (e) => {
      e.preventDefault();
      this.setSearchData(this.data);
      window.location = this.buildRoomsAndRatesUrl(this.data);
    });
  }
  setError(message) {
    let $error = this.$el.find('.av-calendar-error');

    if (message && message.length > 0) {
      $error.text(message).parent()
        .removeClass('hidden');
    } else {
      $error.text('').parent()
        .addClass('hidden');
    }
  }
  setMinStay() {
    let text = (typeof this.options.minStayMessage == 'string' && this.options.minStayMessage !== '') ? this.options.minStayMessage : $.trim($('.min-los-message').first()
      .text());
    if (text && text.length > 0) {
      let $message = this.$el.find('.av-calendar-min-stay');
      text = text.replace(/\$\{NUM\}/g, this.options.minStay ? this.options.minStay : 1).replace(/\${[a-zA-Z0-9_\-]*}/g, ' ');
      $message.text(text).removeClass('hidden')
        .parent()
        .removeClass('hidden');
    }
  }
  setAverageDailyRate(nights, averageDailyRate, currencySymbol, currencyCode,priceDisplayType,tpdCmaOverride) {
    let label = availabilityCalendarConfig.labels.nightAverageDialyRate, // Comes from AEM
      labelField = this.$el.find('.avg-daily-rate'),
      currencyField = this.$el.find('.price .symbol'),
      currencyCodeField = this.$el.find('.unit-per-night .unit-per'),
      priceField = this.$el.find('.nightly-price-integer'),
      decimalField = this.$el.find('.nightly-price-decimals'),
      rate = averageDailyRate.toFixed(2),
      index;

    label = label.replace('$(NUM)', nights);
    index = label.indexOf('(');

    if (index > 0) {
      label = label.substring(0, index) + '<span class="nights">' + label.substring(index) + '</span>';
    }

    labelField.html(label);
    currencyField.html(currencySymbol || '&nbsp;');
    currencyCodeField.html(currencyCode || '&nbsp;');

    index = rate.indexOf('.');
    priceField.text(rate.substring(0, index));
    decimalField.text(rate.substring(index + 1));
    if (countryCodeList && (this.$el.find('.avg-rate-btn .taxes-fees').text().trim().length > 0) && isAllInPriceRegion()) {
      this.$el.find('.avg-rate-btn .taxes-fees').removeClass('hidden');
    }
    if(tpdCmaOverride){
      this.$el.find('.av-calendar-fees-msg').removeClass('hidden');
    }else{
      this.$el.find('.av-calendar-fees-msg').addClass('hidden');
    }
    if(isFeesInclude(priceDisplayType)){
      (priceDisplayType == 'CMA') ? this.$el.find('.avg-rate-btn .taxes-fees').removeClass('hidden') : this.$el.find('.avg-rate-btn .fees').removeClass('hidden');
    }else{
      this.$el.find('.avg-rate-btn .fees').addClass('hidden');
    }
    this.$el.find('.avg-daily-rate, .avg-rate-btn').removeClass('hidden');
    this.$el.find('.av-cal-apply-to-all').removeClass('disabled');
  }
  onOrientationChange(modal) {
    window.addEventListener('orientationchange', () => {
      if (isMobileWidth() !== true) {
        modal.removeClass('modal-mobile');
        Dropdown.closeDropdown();
      } else if (screen.orientation.type === 'portrait-secondary' || screen.orientation.type === 'portrait-primary') {
        modal.addClass('modal-mobile');
      }
    });
  }
  handleMobileBodyScroll(modal) {
    if (isMobileWidth() && $('body').hasClass('iphone')) {
      let modalContent = modal.find('.av-calendar-modal-content')[0];

      if (modalContent) {
        let clientY = null;

        modalContent.addEventListener('touchstart', function(e) {
          if (e.targetTouches.length === 1) {
            clientY = e.targetTouches[0].clientY;
          }
        }, false);

        modalContent.addEventListener('touchmove', function(e) {
          if (e.targetTouches.length === 1) {
            let newClientY = e.targetTouches[0].clientY - clientY;

            if (modalContent.scrollTop === 0 && newClientY > 0) {
              e.preventDefault();
            }

            if ((modalContent.scrollHeight - modalContent.scrollTop <= modalContent.clientHeight) && newClientY < 0) {
              e.preventDefault();
            }
          }
        }, false);
      }
    }
  }
}

class AvailabilityHandler {
  constructor(el, data) {
    this.$el = el;
    this.data = data;
    this.calendarData = {};
    this.today = null;
    this.loader = this.$el.find('.loading-wrapper .loadingBar');
    this.dataLoadedCallback = null;
  }
  onDataLoaded(callback) {
    this.dataLoadedCallback = callback;
  }
  parseDate(value) {
    if (_isDateType(value)) {
      return new Date(value);
    } else {
      return getDateFromDateString(value);
    }
  }
  getFirstDateOfMonth(date) {
    date.setDate(1);
    return date;
  }
  goToLastDateOfMonth(date, months) {
    let newDate = new Date(date.getFullYear(), date.getMonth() + 1 + months, 1); // start one month ahead
    newDate.setDate(0);
    return newDate;
  }
  getMonthKey(date) {
    return date.getFullYear() + '-' + date.getMonth();
  }
  getFirstMonthPending(date, increment) {
    while (this.calendarData[this.getMonthKey(date)]) {
      if (increment < 0) {
        date = this.goToLastDateOfMonth(date, increment);
      } else {
        date.setMonth(date.getMonth() + increment);
      }
    }
    return date;
  }
  reset() {
    this.calendarData = {};
    this.today = new Date();
    this.today.setMilliseconds(0);
    this.today.setSeconds(0);
    this.today.setMinutes(0);
    this.today.setHours(0);
    this.load(this.data.checkInDate, 2);
  }
  checkInMinStayHelper(date, minStay) {
    let available = true,
      nextDate,
      key,
      entry;

    for (let i = 1; i < minStay; i++) {
      nextDate = this.parseDate(date);
      nextDate.setDate(nextDate.getDate() + i);
      key = this.getMonthKey(nextDate);
      entry = this.calendarData[key] ? this.calendarData[key][nextDate.getDate() - 1] : null;

      if (entry && !entry.available) {
        available = false;
        break;
      }
    }

    return available;
  }
  processCheckIn(start, end, minStay) {
    let curr = start;

    while (curr <= end) {
      let key = this.getMonthKey(curr),
        entry = this.calendarData[key] ? this.calendarData[key][curr.getDate() - 1] : null,
        currMinStay = minStay;

      if (entry) {
        // override minStay when the date requires a specific minimum length stay
        if (entry.minStay >= 1) {
          currMinStay = entry.minStay;
        }

        if (currMinStay <= 1) {
          entry.isCheckInAllowed = !entry.closedOnArrival && entry.available;
        } else {
          entry.isCheckInAllowed = !entry.closedOnArrival && entry.available && this.checkInMinStayHelper(this.parseDate(curr), currMinStay);
        }
      }

      curr.setDate(curr.getDate() + 1);
    }
  }
  isAllowedToCheckOut(date, checkInDate, minStay) {
    let allowed = true,
      prevDate = this.parseDate(date),
      checkInKey = this.getMonthKey(checkInDate),
      checkInMonth = this.calendarData[checkInKey],
      checkInEntry = checkInMonth ? checkInMonth[checkInDate.getDate() - 1] : null,
      stayLength,
      key,
      entry,
      totalDays = 1,
      maxMinStay = minStay;
    prevDate.setDate(prevDate.getDate() - 1);

    // if a maximum stay has been established for this date,
    // limit the allowed check out based on the number of dates
    // short circuit for performance
    if (checkInEntry && checkInEntry.maxStay >= 1) {
      stayLength = getNumDays(checkInDate, date);

      if (stayLength > checkInEntry.maxStay) {
        return false;
      }
    }

    while (prevDate > checkInDate) {
      key = this.getMonthKey(prevDate);
      entry = this.calendarData[key] ? this.calendarData[key][prevDate.getDate() - 1] : null;

      if (!entry || !entry.available) {
        allowed = false;
        break;
      }

      if (entry.minStay && entry.minStay > 0) {
        maxMinStay = Math.max(maxMinStay, entry.minStay);
      }

      totalDays++;
      prevDate.setDate(prevDate.getDate() - 1);
    }

    return allowed && totalDays >= maxMinStay;
  }
  processData(startDate, endDate, response) {
    if (!response || response.ErrorCode || !response.hotelStayAvailability) {
      this.handleServiceError(response);
      return false; //If we get here this means that there is no data to process so we cancel the execution.
    } else {
      this.handleErrorFutureMessage(response.hotelStayAvailability);
    }
    let start = this.parseDate(startDate),
      end = this.parseDate(endDate),
      curr = this.parseDate(start),
      isUSD = response.currencyCode && response.currencyCode.toUpperCase() === 'USD',
      currencyCode = response.currencyCode || '',
      currencySymbol = isUSD ? '$' : '',
      hotelStays = response.hotelStayAvailability || [],
      lastStayIndex = 0;

    if (!$.isArray(hotelStays)) {
      hotelStays = [hotelStays];
    }

    // prepare the data
    $.each(hotelStays, (index, stay) => {
      if (stay) {
        stay.closedOnArrival = stay.status === 'ClosedOnArrival';
        stay.available = stay.status === 'Open' || stay.status === 'MinStay' || stay.status === 'MaxStay' || stay.closedOnArrival;
        stay.start = this.parseDate(stay.startDate);
        stay.end = this.parseDate(stay.endDate);
        if (countryCodeList && (this.$el.find('.avg-rate-btn .taxes-fees').text().trim().length > 0) && isAllInPriceRegion() && (stay.amountAfterTax > 0)) {
         
          stay.rate = parseFloat(stay.amountToDisplay);
        } else {
          
          stay.rate = parseFloat(stay.amountToDisplay);
        }

        if ((isUSD && stay.rate > 9999) || stay.rate > 99999) {
          this.data.hideRate = true;
        }
      }
    });

    while (curr <= end) {
      let key = this.getMonthKey(curr),
        stay = null,
        entry = {};

      if (!this.calendarData[key]) {
        this.calendarData[key] = [];
      }

      for (let i = lastStayIndex; i < hotelStays.length; i++) {
        if (curr >= hotelStays[i].start && curr <= hotelStays[i].end) {
          stay = hotelStays[i];
          lastStayIndex = i;
          break;
        }
      }

      entry.closedOnArrival = stay ? stay.closedOnArrival : false;
      entry.available = stay ? stay.available : false;
      entry.rate = (stay && stay.available) ? stay.rate : 0;
      entry.minStay = (stay && stay.status === 'MinStay') ? parseInt(stay.quantity) : 0;
      entry.maxStay = (stay && stay.status === 'MaxStay') ? parseInt(stay.quantity) : 0;
      entry.currencyCode = currencyCode;
      entry.currencySymbol = currencySymbol;
      entry.priceDisplayType = stay.priceDisplayType;
      entry.tpdCmaOverride = stay.tpdCmaOverride;
      this.calendarData[key][curr.getDate() - 1] = entry;

      curr.setDate(curr.getDate() + 1);
    }

    this.processCheckIn(start, end, this.data.minStay);
  }
  getServiceParameters(start, end) {
    let params = {
      stayDateRangeStart: formatDateForBWSAvailability(start),
      stayDateRangeEnd: formatDateForBWSAvailability(end),
      hotelCode: this.data.propertyId,
      adultCount: this.data.adults, // there is a minimum of 1 adult.
      roomTypeNumberOfUnits: this.data.roomTypeNumberOfUnits // there is a minimum of 1 room.
    };

    // Add children to the parameters object.
    if (this.data.children > 0) {
      _.extend(params, {
        childCount: this.data.children,
        childAges: this.data.childAges
      });
    }

    // Add promotional codes.
    if (this.data.rate && this.data.rate.type && this.data.rate.code) {
      if (this.data.rate.type === 'groupCode') {
        _.extend(params, {
          ratePlanCode: this.data.rate.code
        });
      } else {
        _.extend(params, {
          // ratePlanCode: this.data.rate.type,
          promotionCode: this.data.rate.code
        });
      }
    }
    //Add additionalCorpCodes param for mobile view only
    if (isMobileWidth() && _isNotNull(additionalCorpCodes)) {
      _.extend(params, {
        additionalCorpCodes: additionalCorpCodes
      });
    }
    return params;
  }
  setMessageError(message) {
    let $error = this.$el.find('.av-calendar-error');
    $error.text(message)
      .parent()
      .removeClass('hidden');
  }
  handleErrorFutureMessage(res) {
    let hotelStays = res || [],
      hasAvailability = false,
      errorMessage;

    if (!$.isArray(hotelStays)) {
      hotelStays = [hotelStays];
    }

    if (hotelStays.length > 0) {
      $.each(hotelStays, (index, field) => {
        if (field && field.status && field.endDate) {
          let endDate = this.parseDate(field.endDate);
          if (field.status !== 'Close' && endDate > this.today) {
            hasAvailability = true;
          }
        }
      });
    }
    if (!hasAvailability) {
      errorMessage = availabilityCalendarConfig.labels.errorRateFuture;

      if (this.data.rateCodeText && availabilityCalendarConfig.labels.errorRateFutureToken) {
        errorMessage = availabilityCalendarConfig.labels.errorRateFutureToken.replace('$(RATE)', this.data.rateCodeText);
      }

      this.setMessageError(errorMessage);
    }
  }
  handleServiceError(res) {
    let $elementsToHide = this.$el.find('.av-calendar-options, .action-btns, .datepicker-container, .av-calendar-min-stay'),
      errorMessage = '';

    if (!res || res.ErrorCode == '11' || res.ErrorCode == '321' || res.ErrorCode == '448' || res.ErrorCode == '450' || res.ErrorCode == '784') {
      errorMessage = availabilityCalendarConfig.labels.error;
      $elementsToHide.hide();
    } else {
      errorMessage = availabilityCalendarConfig.labels.errorRate;

      if (this.data.rateCodeText && availabilityCalendarConfig.labels.errorRateToken) {
        errorMessage = availabilityCalendarConfig.labels.errorRateToken.replace('$(RATE)', this.data.rateCodeText);
      }
    }

    this.setMessageError(errorMessage);
    this.loader.hide();
    allyPrevNextDatePicker();

    //Analytics implementation for service call errors.
    window.digitalData.error.errorInfo.errorMessage = errorMessage + ': ';
    if (res && res.responseJSON && res.responseJSON.ErrorMessage) {
      window.digitalData.error.errorInfo.errorMessage += res.responseJSON.ErrorMessage;
    }
    EventHandler.triggerEvent('search-results_availability-calendar-error');
  }
  requestData(start, end, callback) {
    let params = this.getServiceParameters(start, end);
    // temporal mock code
    if (location.search.match(/mockAvailabilityCalendar=true/)) {
      var me = this;
      setTimeout(function() {
        var res = generateMockResponse(params);
        me.processData(start, end, res);
        callback();
      }, 400);
    } else {
      callService('avCalendar', params).then((res) => {
        this.processData(start, end, res);
        callback();
      });
    }
  }
  load(startDate, months, callback) {
    let start = this.getFirstDateOfMonth(this.parseDate(startDate)),
      end = this.parseDate(start);

    end.setMonth(end.getMonth() + months);
    end.setDate(0); // last date of previous month

    // discard dates already loaded
    start = this.getFirstMonthPending(start, 1);
    end = this.getFirstMonthPending(end, -1);

    if (start < end) {
      this.loader.show();

      this.requestData(start, end, () => {
        if (this.dataLoadedCallback) {
          this.dataLoadedCallback();
        }
        this.loader.hide();
        allyPrevNextDatePicker();
        if (typeof callback === 'function') {
          callback();
        }
      });
    }
  }
  // this method is no longer used because the price is hidden if there are more than 5 characters
  // it was left in case there is a future use case where it is required
  limitRate(rate, currencyCode, currencySymbol) {
    let value = rate + '';

    if (rate <= 0) {
      return '&nbsp;';
    } else if (value.length > 5) {
      value = value.substring(0, 3) + '...';
    }

    return currencySymbol + value + ' ' + currencyCode;
  }
  getDateStatusAndPrice(date, isCheckOut) {
    let selectedDate = this.parseDate(date),
      checkInDate = this.parseDate(this.data.checkInDate),
      checkOutDate = this.parseDate(this.data.checkOutDate),
      monthKey = this.getMonthKey(selectedDate),
      entry = this.calendarData[monthKey] ? this.calendarData[monthKey][selectedDate.getDate() - 1] : null,
      checkInMonthKey = this.getMonthKey(checkInDate),
      checkInEntry = this.calendarData[checkInMonthKey] ? this.calendarData[checkInMonthKey][checkInDate.getDate() - 1] : null,
      checkInMinStay = (checkInEntry && checkInEntry.minStay > 0) ? checkInEntry.minStay : this.data.minStay,
      minStay = (entry && entry.minStay && entry.minStay > 0) ? entry.minStay : this.data.minStay,
      values = {
        isActive: true,
        class: '',
        price: (entry && !this.data.hideRate) ? this.limitRate(entry ? Math.round(convertToCurrency(entry.rate,entry.currencyCode)) : 0, getSelectedCurencyCode(entry.currencyCode), getCurrencyMapping(entry.currencyCode)) : '&nbsp;'
      };

    if (!isCheckOut && (!entry || !entry.isCheckInAllowed)) {
      values.isActive = false;
    }

    if (checkInDate && checkOutDate) {
      if (!this.calendarData[monthKey]) {
        values.isActive = false;
      } else if (selectedDate.getTime() < checkInDate.getTime() && isCheckOut) {
        values.isActive = false;

        if (selectedDate.getTime() < this.today.getTime()) {
          values.price = '&nbsp';
        }
      } else if (selectedDate.getTime() == checkInDate.getTime() && !this.data.initialDates) {
        if (isCheckOut) {
          values.isActive = false;
        }
        values.class = 'active-date active-date-check-in';
      } else if (selectedDate.getTime() == checkOutDate.getTime() && !isCheckOut && !this.data.initialDates) {
        values.class = 'active-date active-date-check-out';

        if (!entry || !entry.isCheckInAllowed) {
          values.class += ' ui-datepicker-unselectable';
        }
      } else if ((selectedDate.getTime() > checkInDate.getTime()) && (selectedDate.getTime() < checkOutDate.getTime()) && !isCheckOut && !this.data.initialDates) {
        values.class = 'between-date';
      } else if (selectedDate.getTime() < this.today.getTime()) {
        values.isActive = false;
        values.price = '&nbsp';
      } else if (!isCheckOut && (!entry || !entry.isCheckInAllowed)) {
        values.isActive = false;
        if (entry && entry.available) {
          values.class = 'av-display-price';
        }
      } else if (isCheckOut && !this.isAllowedToCheckOut(selectedDate, checkInDate, checkInMinStay)) {
        values.isActive = false;
        if (entry && entry.available) {
          values.class = 'av-display-price';
        }
      } else if (minStay && minStay > 1) {
        values.class = 'av-display-price av-min-los';
      }
    }
    return $.map(values, (value, index) => {
      return [value];
    });
  }
  calculateRate(start, end) {
    let curr = this.parseDate(start),
      endDate = this.parseDate(end),
      rate = {
        numberOfNights: getNumDays(start, end),
        totalRate: 0,
        averageDailyRate: 0,
        currencySymbol: '',
        currencyCode: '',
        priceDisplayType: null,
        tpdCmaOverride: false
      };

    while (curr < endDate) {
      let key = this.getMonthKey(curr),
        entry = this.calendarData[key] ? this.calendarData[key][curr.getDate() - 1] : null;

      if (entry) {
        rate.totalRate += parseFloat(convertToCurrency(entry.rate,entry.currencyCode));
        rate.currencySymbol = getCurrencyMapping(entry.currencyCode);
        rate.currencyCode = getSelectedCurencyCode(entry.currencyCode);
        rate.priceDisplayType = entry.priceDisplayType;
        rate.tpdCmaOverride = entry.tpdCmaOverride;

      }

      curr.setDate(curr.getDate() + 1);
    }

    if (rate.numberOfNights > 0) {
      rate.averageDailyRate = rate.totalRate / rate.numberOfNights;
    }

    return rate;
  }
}

class DatePickerHandler {
  constructor(options, data, availabilityHandler) {
    this.options = options;
    this.data = data;
    this.availabilityHandler = availabilityHandler;
    this.$datePicker = null;
    this.$checkIn = null;
    this.$checkOut = null;
    this.$priceBtn = null;
    this.$applyToAll = null;
    this.checkInDate = null;
    this.checkOutDate = null;
    this.isCheckOut = false;
    this.datesSelectedCallback = null;
  }
  goToDate(date) {
    let inst = this.$datePicker.data('datepicker');
    inst.drawMonth = inst.selectedMonth = date.getMonth();
    inst.drawYear = inst.selectedYear = date.getFullYear();
    $.datepicker._notifyChange(inst);
    this.$datePicker.datepicker('refresh');
  }
  setDate(dateText) {
    let selectedDate = getDateFromDateString(dateText),
      triggerCallback = false;

    this.data.initialDates = false;

    if (this.isCheckOut) {
      this.checkOutDate = selectedDate;
      this.$checkOut.html(formatDateForPrinting(this.checkOutDate, 'textMonth')).addClass('text-uppercase');
      triggerCallback = true;
    } else {
      this.checkInDate = selectedDate;
      this.checkOutDate = getXDays(this.data.minStay, selectedDate);
      this.$checkIn.html(formatDateForPrinting(this.checkInDate, 'textMonth')).addClass('text-uppercase');
      this.$checkOut.html(availabilityCalendarConfig.labels.dates.selectDate).removeClass('text-uppercase');
      this.$priceBtn.addClass('hidden');
      this.$applyToAll.addClass('disabled');
    }

    this.isCheckOut = !this.isCheckOut;
    this.data.checkInDate = formatDateForBWS(this.checkInDate);
    this.data.checkOutDate = formatDateForBWS(this.checkOutDate);

    if (triggerCallback && this.datesSelectedCallback) {
      this.datesSelectedCallback();
    }
  }
  deleteEmptyWeeks() {
    this.$datePicker.find('tbody tr:last-child').each((index, tr) => {
      let $tr = $(tr);
      if ($tr.text().trim().length < 1) {
        $tr.html('');
      }
    });
  }
  initDatePicker() {
    let monthsToShow = isCompactView() ? 1 : 2;
    const today = new Date();

    this.$datePicker.datepicker({
      numberOfMonths: monthsToShow,
      minDate: 0,
      maxDate: this.isCheckOut ? BookingConfig.getMaximumCheckOutDate(this.checkInDate || today ) : BookingConfig.getMaximumCheckInDate(),
      nextText: 'Next',
      prevText: 'Previous',
      onSelect: (dateText, picker) => {
        this.setDate($(picker.input).datepicker('getDate'));
        this.reinitialize();
      },
      beforeShowDay: (date) => {
        allyPrevNextDatePicker();
        return this.availabilityHandler.getDateStatusAndPrice(date, this.isCheckOut);
      },
      onChangeMonthYear: (year, month) => {
        this.deleteEmptyWeeks();
        setTimeout(() => this.deleteEmptyWeeks(), 10);

        if (this.data.initialLoadStarted) { // only request data after initial load
          this.availabilityHandler.load(new Date(year, month - 1, 1), 2, this.deleteEmptyWeeks.bind(this));
        }
      }
    });
    this.deleteEmptyWeeks();
    this.goToDate(getDateFromDateString(this.data.checkInDate));
  }
  reinitialize() {
    this.$datePicker.datepicker('destroy');
    this.initDatePicker();
  }
  reset() {
    let label = availabilityCalendarConfig.labels.dates.selectDate;
    this.data.initialDates = true;
    this.data.checkInDate = this.data.originalCheckInDate;
    this.data.checkOutDate = this.data.originalCheckOutDate;
    this.checkInDate = getDateFromDateString(this.data.originalCheckInDate);
    this.checkOutDate = getDateFromDateString(this.data.originalCheckOutDate);
    this.isCheckOut = false;
    this.$checkIn.html(label).removeClass('text-uppercase');
    this.$checkOut.html(label).removeClass('text-uppercase');
    this.goToDate(this.checkInDate);
    this.refresh();
    this.reinitialize();
  }
  refresh() {
    this.$datePicker.datepicker('refresh');
    this.deleteEmptyWeeks();
  }
  onDatesSelected(callback) {
    this.datesSelectedCallback = callback;
  }
  init(el) {
    this.$datePicker = el.find('.datepicker-container');
    this.$checkIn = el.find('.av-cal-dates .checkIn .date');
    this.$checkOut = el.find('.av-cal-dates .checkOut .date');
    this.$priceBtn = el.find('.avg-daily-rate, .avg-rate-btn');
    this.$applyToAll = el.find('.av-cal-apply-to-all');
    this.initDatePicker();
  }
}

class RatesDataHandler {
  constructor(el, data) {
    this.$el = el;
    this.data = data;
    this.def = this.data.rate;
    this.onChangeCallback = null;
  }
  setRate() {
    let newRate = this.data.selectedRate;

    if (!newRate && this.data.rate != null) {
      this.data.rate = null;

      if (this.onChangeCallback) {
        this.onChangeCallback(null);
      }

    } else if (!this.data.rate || this.data.rate.code != newRate.code || this.data.rate.type != newRate.type) {
      this.data.rate = newRate;

      if (this.onChangeCallback) {
        this.onChangeCallback(newRate);
      }
    }
  }
  setRateCodeText(rateCodeText) {
    this.data.rateCodeText = rateCodeText;
  }
  onChange(callback) {
    this.onChangeCallback = callback;
  }
  reset() {
    this.data.rate = this.def;
  }
  // Mimic BookingBarForm's functions for use in BBMoreOptions.js
  getRedeemWRPoint() {
    return false;
  }
  setRedeemWRPoint() {
    // Do nothing
  }
  getSpecialRate() {
    return this.data.rate;
  }
  setSpecialRate(code, type) {
    let newRate = null;

    if (code || type) {
      if (type && typeof type === 'number') { // fix a bug with the dropdown selection
        let temp = code;
        code = type;
        type = temp;
      }

      newRate = {};

      if (code) {
        newRate.code = String(code);
      }
      if (type) {
        newRate.type = String(type);
      }

      this.data.selectedRate = newRate;
    } else {
      if (this.data.rate != null) {
        this.data.selectedRate = null;
      }
    }
  }
  init() {
    this.$el.on('click', '.av-calendar-special-rates-dropdown .btn-secondary.save', () => {
      setTimeout(() => this.setRate(), 25);
    });
  }
}

EventHandler.on('ac-open-modal', (e, opts) => {
  let availabilityCalendar = new AvailabilityCalendar();
  availabilityCalendar.openModal(opts);
});

EventHandler.on('ac-open-mobile', (e, opts) => {
  let availabilityCalendar = new AvailabilityCalendar();
  availabilityCalendar.openMobile(opts);
});

export default AvailabilityCalendar;
