import {once, CookieHandler, EventHandler, hasRetailBanner} from './utils.js';
import { brand_id, $_MASK_, _MOBILE_MAX_WIDTH, _TABLET_MAX_WIDTH, _DESKTOP_MAX_WIDTH, $_PAGE_ } from './vars.js';
import Channels from "./aem-configs/channels";


let prevDistanceScrolled = $(window).scrollTop();

class WatchHandler {
  constructor() {
    this.$_ = null;
    this.event = undefined;
    this.fn = null;
    this.val = null;
    this.original = null;
    this.int = null;
    this.timing = 111;
  }
  watch() {
    if (this.val !== this.fn()) {
      this.val = this.fn();

      EventHandler.send(this.event);
    }
  }
  stop() {
    clearInterval(this.int);
    this.int = null;
  }
  init($el, ev, opts) {
    if (!(exists($el)) || // Element required
      this.event === null || this.event === 'undefined' || // Event required
      typeof (opts) !== 'object' || // Opts must be object
      (!(opts.prop) && !(opts.fn) // Opts must have either 'prop' or 'fn' set
      )
    ) {
      return;
    }

    this.$_ = $el;
    this.event = ev;
    this.fn = (opts.prop) ? () => {
      return this.$_.css(opts.prop);
    } : () => {
      return (!opts.params) ? this.$_[opts.fn]() : this.$_[opts.fn](opts.params);
    };

    // Optional
    this.timing = opts.timing || this.timing;

    this.val = this.original = this.fn();

    this.int = setInterval(() => {
      this.watch();
    }, this.timing);
  }
  getCurrent() {
    return this.val;
  }
  getElement() {
    return this.$_;
  }
  getOriginal() {
    return this.original;
  }
  setOriginal() {
    this.original = this.val;
  }
}

function exists($el) {
  if (typeof ($el) === 'string') {
    const sanitizedSelector = DOMPurify.sanitize($el);
    $el = $(sanitizedSelector);
  }

  return ($el && $el.length > 0);
}

function inViewportHeight($el) {
  var elH = $el.outerHeight(),
    H = $(window).height(),
    r = $el[0].getBoundingClientRect(),
    t = r.top,
    b = r.bottom;
  return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
}

function isElementInViewport(el) {
  if (typeof jQuery === 'function' && el instanceof jQuery) {
    el = el[0];
  }

  let rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
  );
}

function getCSSPropertyAsInt(el, property) {
  let num = (el.css(property).replace(/px/, ''));
  num = (num.replace(/%/, ''));
  num *= 1;

  return (!(isNaN(num))) ? num : 0;
}

function getElTrueHeight(el) {
  if (el.length > 0) {
    return Math.ceil(
      Math.ceil(el.height() * 1) + Math.ceil(getCSSPropertyAsInt(el, 'padding-top') * 1) + Math.ceil(getCSSPropertyAsInt(
        el, 'padding-bottom') * 1) + Math.ceil(getCSSPropertyAsInt(el, 'margin-top') * 1) + Math.ceil(getCSSPropertyAsInt(
        el, 'margin-bottom') * 1) + Math.ceil(getCSSPropertyAsInt(el, 'border-top-width') * 1) + Math.ceil(
        getCSSPropertyAsInt(el, 'border-bottom-width') * 1)
    );
  }

  return 0;
}

function getElTrueWidth(el) {
  if (el.length > 0) {
    return (
      el.width() + getCSSPropertyAsInt(el, 'padding-left') + getCSSPropertyAsInt(el, 'padding-right') +
      getCSSPropertyAsInt(el, 'margin-left') + getCSSPropertyAsInt(el, 'margin-right') +
      getCSSPropertyAsInt(el, 'border-left-width') + getCSSPropertyAsInt(el, 'border-right-width')
    );
  }

  return 0;
}

function swapHeaderImages($logoImg, $rewardsLogoImg, which) {
  $logoImg.attr('src', $logoImg.data(which));

  if (isDesktopWidth() || !$rewardsLogoImg.attr('src')) {
    $rewardsLogoImg.attr('src', $rewardsLogoImg.data(which));
  }
}

function simpleLightbox(msg, opts) {
  if (!(opts)) {
    opts = {};
  } else if (typeof (opts) == 'function') {
    let temp = opts;
    opts = {
      closed: temp
    };
  }

  let content = $(document.createElement('div')).addClass('lightbox-content')
    .html(msg);

  $_MASK_.append(content).addClass('lightbox takeover open');
  if (!(opts.disableClose)) {
    $_MASK_.click((e) => {
      $(e.currentTarget).off('click')
        .removeClass('lightbox takeover open')
        .find('.lightbox-content')
        .remove();

      if (typeof (opts.closed) == 'function') {
        opts.closed();
      }
    });
  }

  if (typeof (opts.opened) == 'function') {
    opts.opened();
  }
}

function scrollToAnimated(position, duration, el, callback) {
  if (!(duration)) {
    duration = 333;
  }
  if (!(el)) {
    el = $('html,body');
  }

  let complete = function() {};
  if (typeof (callback) == 'function') {
    complete = once(callback);
  }

  el.stop().animate({
    scrollTop: position
  }, {
    duration: duration,
    complete: complete
  });
}

function stickify($el, opts) {
  requestAnimationFrame(function() {
    if (!(opts.stick)) {
      opts.stick = {};
    }
    if (!(opts.stick.css)) {
      opts.stick.css = {};
    }
    if (!(opts.unstick)) {
      opts.unstick = {};
    }
    if (!(opts.unstick.css)) {
      opts.unstick.css = {};
    }

    if (exists(opts.endOnEl) && $el.is('.stick')) {
      var $tmp = $(opts.endOnEl);

      opts.end = $tmp.position().top - $el.position().top - $el.outerHeight();
    }

    // The distance the page has scrolled
    var distanceScrolled = $(window).scrollTop();

    function isInsideStickyArea() {
      return (
        (distanceScrolled > opts.start) &&
        (
          (opts.end && distanceScrolled < opts.end) ||
          (!(opts.end))
        )
      );
    }

    if ($el.length) {
      // if el is inside the sticky area
      if (isInsideStickyArea()) {
        if (!($el.is('.stick'))) {
          $el.addClass('stick');

          if (!($.isEmptyObject(opts.stick.css))) {
            $el.removeAttr('style').css(opts.stick.css);
          }

          if (typeof (opts.onStick) == 'function') {
            opts.onStick();
          }
        } else {
          if (typeof (opts.onStuck) == 'function') {
            opts.onStuck();
          }
        }
      } else { // Outside of the sticky area
        if ($el.is('.stick')) {
          if (opts.end && distanceScrolled < opts.end && typeof (opts.atTop) == 'function') {
            opts.atTop();
          }
          if (opts.end && distanceScrolled >= opts.end && typeof (opts.atBottom) == 'function') {
            opts.atBottom();
          }

          $el.removeClass('stick');

          if (typeof (opts.onUnstick) == 'function') {
            opts.onUnstick();
          }
        } else {
          if (opts.end && distanceScrolled >= opts.end && typeof (opts.atBottom) == 'function') {
            opts.atBottom();
          }
          if (typeof (opts.onUnstuck) == 'function') {
            opts.onUnstuck();
          }
        }
      }
    }

    // Reset previousDistance to update whether the page has scrolled up or down
    prevDistanceScrolled = distanceScrolled;
  });
}

function getDistanceScrolled() {
  return prevDistanceScrolled;
}

function isVisible($el) {
  return (
    exists($el) &&
    $el.is(':visible') &&
    $el.position().top + $el.outerHeight() > 0 && // Bottom of element is off screen top
    $el.position().top < getWindowHeight() && // Top of element is off screen bottom
    $el.position().left + $el.outerWidth() > 0 &&
    $el.position().left < getWindowWidth()
  );
}

function getElHeightFromChildren(el) {
  if (exists(el)) {
    let $el = $(el),
      height = 0,
      items = $el.children();

    for (let i = 0; i < items.length; i++) {
      if ($(items[i]).is(':visible')) {
        height += getElTrueHeight($(items[i]));
      }
    }

    return height;
  }
}

function matchElementsHeight($el) {
  if (typeof $el === 'string') {
    $el = $($el);
  }

  let heights = $el.map((i, el) => {
      return parseInt($(el).height());
    }).get(),
    max = Math.max.apply(null, heights);

  $el.height(max);
}

function objectFitImage($image, $container) {
  // Check if image will fill container with 100% width
  let resizeRatio = $container.width() / $image.width() * 1.0;
  if ($image.height() * resizeRatio >= $container.height()) {
    $image.width('100%');
  } else {
    $image.height('100%');
  }
}

function addMobileOverflowFix() {
  if (!isDesktopWidth()) {
    $('body').attr('scroll-to', window.scrollY);
    $('html, body').addClass('mobile-overflow-hidden');
  }
}

function removeMobileOverflowFix() {
  let scrollTo = $('body').attr('scroll-to');
  $('html, body').removeClass('mobile-overflow-hidden');
  if (scrollTo) {
    $('body').removeAttr('scroll-to');
    window.scrollTo(0, scrollTo);
  }
}

function handleFnOnLoad() {
  let pairs = DOMPurify.sanitize(location.search.substr(1)).split('&');

  for (let i = 0; i < pairs.length; i++) {
    let search = pairs[i].split('='),
      key = DOMPurify.sanitize(search[0]),
      val = DOMPurify.sanitize(search[1]); // Sanitize the value extracted from URL

    if (key === 'runfn' && typeof (window[val]) === 'function') {
      window[val]();
    } else if (key === 'lightbox' && exists($('[data-lightbox-path="' + DOMPurify.sanitize(val) + '"]'))) {
      $('[data-lightbox-path="' + DOMPurify.sanitize(val) + '"]').click();
    }
  }
}

function suppressNoSrcImages(img) {
  if (!img.hasAttribute('scr') && !img.hasAttribute('data-info') && !img.classList.contains('pll-image-full-size')) {
    img.style.height = 0;
  }
}

// WINDOW RESIZE HANDLER
class Resize {
  constructor() {
    this.resizeStartFns = [];
    this.resizingFns = [];
    this.resizeWidthEndFns = [];
    this.resizeHeightEndFns = [];
    this.resizeEndFns = [];
    this._initialWidth = getWindowWidth();
    this._initialHeight = getWindowHeight();
    this._resizeTimeout = null;
    this._ignore = false;
    this.init();
  }
  handleResizing() {
    let ww = getWindowWidth(),
        wh = getWindowHeight();
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = setTimeout(() => {
      if (($.inArray(brand_id, ['DX', 'DZ', 'ES', 'WT']) >= 0) && $('body').is('.ios,.iphone,.ipad,.ipod,.android') && !($_PAGE_.is('.booking-page,.confirmation-page')) && Math.abs(this._initialWidth - ww) > 100) {
        $_PAGE_.addClass('loading');
        if (CookieHandler.readCookie('dolceOrientationChange') && CookieHandler.readCookie('dolceOrientationChange').toString() ===
            'dolce-Orientation-Change') {
          CookieHandler.eraseCookie('dolceOrientationChange');
        }
        CookieHandler.createCookie('dolceOrientationChange', 'dolce-Orientation-Change');

        window.location.replace(window.location.href);
      } else {
        if (ww !== this._initialWidth) {
          this.resizeWidthEnd();
        }

        if (wh !== this._initialHeight) {
          this.resizeHeightEnd();
        }

        if (ww !== this._initialWidth || wh !== this._initialHeight) {
          this.resizeEnd();
        } else {
          this.reset();
        }
      }
      if (hasRetailBanner()) {
        $('.retail-banner-mt').css('marginTop', inViewportHeight($('.retail-banner-component')));
      }
    }, 333);

    if (!(this._ignore) && (ww !== this._initialWidth || wh !== this._initialHeight)) {
      this.resizing();
    }
  }
  start() {
    this._ignore = $(document.activeElement).is('[type=text]');

    this.resizeStart();

    $(window).off('resize', this.startWrapper);
  }
  reset() {
    $(window)
      .off('resize', this.startWrapper)
      .off('resize', this.handleResizingWrapper)
      .on('resize', this.startWrapper)
      .on('resize', this.handleResizingWrapper);
  }
  resizeStart() {
    if (!(this._ignore)) {
      for (let i = 0; i < this.resizeStartFns.length; i++) {
        this.resizeStartFns[i]();
      }
    }
  }
  removeResizeStartFn(fn) {
    let index = $.inArray(fn, this.resizeStartFns);

    if (index > -1) {
      this.resizeStartFns.splice(index, 1);
    }
  }
  addResizeStartFn(fn) {
    this.resizeStartFns.push(fn);
  }
  resizing() {
    for (let i = 0; i < this.resizingFns.length; i++) {
      this.resizingFns[i]();
    }
  }
  addResizeFn(fn) {
    this.resizingFns.push(fn);
  }
  removeResizeFn(fn) {
    let index = $.inArray(fn, this.resizingFns);

    if (index > -1) {
      this.resizingFns.splice(index, 1);
    }
  }
  resizeEnd() {
    if (!(this._ignore)) {
      for (let i = 0; i < this.resizeEndFns.length; i++) {
        this.resizeEndFns[i]();
      }
    }
  }
  resizeWidthEnd() {
    if (!(this._ignore)) {
      for (let i = 0; i < this.resizeWidthEndFns.length; i++) {
        this.resizeWidthEndFns[i]();
      }
    }
  }
  resizeHeightEnd() {
    if (!(this._ignore)) {
      for (let i = 0; i < this.resizeHeightEndFns.length; i++) {
        this.resizeHeightEndFns[i]();
      }
    }
  }
  addResizeEndFn(fn, when) {
    if (typeof (when) === 'undefined') {
      when = 'width';
    }
    if (fn) {
      if (when == 'width') {
        this.resizeWidthEndFns.push(fn);
      } else if (when == 'height') {
        this.resizeHeightEndFns.push(fn);
      } else {
        this.resizeEndFns.push(fn);
      }
    }
  }
  removeResizeEndFn(fn) {
    let index = $.inArray(fn, this.resizeEndFns);

    if (index > -1) {
      this.resizeEndFns.splice(index, 1);
    }

    index = $.inArray(fn, this.resizeWidthEndFns);

    if (index > -1) {
      this.resizeWidthEndFns.splice(index, 1);
    }

    index = $.inArray(fn, this.resizeHeightEndFns);

    if (index > -1) {
      this.resizeHeightEndFns.splice(index, 1);
    }
  }
  init() {
    this.addResizeStartFn(() => {
      this._initialWidth = getWindowWidth();
      this._initialHeight = getWindowHeight();
    });

    this.addResizeEndFn(() => {
      this.reset();
    }, 'both');

    this.startWrapper = () => {
      this.start();
    };

    this.handleResizingWrapper = () => {
      this.handleResizing();
    };

    $(window).on('resize', this.startWrapper);
    $(window).on('resize', this.handleResizingWrapper);
  }
}

function getWindowWidth() {
  return $(window).width();
}

function getWindowHeight() {
  return $(window).height();
}

function isMobileWidth() {
  return getWindowWidth() <= _MOBILE_MAX_WIDTH;
}

function isTabletWidth() {
  return getWindowWidth() <= _TABLET_MAX_WIDTH && !(isMobileWidth());
}

function isDesktopWidth() {
  return getWindowWidth() <= _DESKTOP_MAX_WIDTH && !(isTabletWidth()) && !(isMobileWidth());
}

function isCompactView() {
  return isMobileWidth() || isTabletWidth();
}

function isPortrait() {
  return (getWindowHeight() > getWindowWidth()) ? true : false;
}

function isHomepageExtendedHeroException() {
  return $_PAGE_.is('.homepage.extended-hero') && (isDesktopWidth() || isTabletWidth());
}

function getChanel() {
  if (isMobileWidth()) {
      return [ Channels.MobileWeb ];
  } else if (isTabletWidth()) {
      return [ Channels.Tablet ];
  } else if (isDesktopWidth()) {
      return [ Channels.Desktop ];
  }
}

let ResizeHandler = new Resize();

export {
  exists,
  isElementInViewport,
  inViewportHeight,
  getCSSPropertyAsInt,
  getElTrueHeight,
  getElTrueWidth,
  swapHeaderImages,
  simpleLightbox,
  scrollToAnimated,
  stickify,
  getDistanceScrolled,
  isVisible,
  getElHeightFromChildren,
  matchElementsHeight,
  objectFitImage,
  addMobileOverflowFix,
  removeMobileOverflowFix,
  handleFnOnLoad,
  suppressNoSrcImages,
  getWindowWidth,
  getWindowHeight,
  isMobileWidth,
  isTabletWidth,
  isDesktopWidth,
  isCompactView,
  isPortrait,
  WatchHandler,
  ResizeHandler,
  getChanel,
  isHomepageExtendedHeroException
};

