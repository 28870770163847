import { exists, ResizeHandler, isMobileWidth } from '../base/dom-utils.js';
import {CookieHandler} from '../base/utils.js';
import { lazyLoadImageInViewport } from '../base/lazy-load.js';
import {deletePropAvailSessionData,deleteSearchOverviewSessionData,deleteRoomsRatesSessionData} from '../base/session-handler.js';

class Footer {
  constructor() {
    if(exists('#pageFooter')) {
      this.footerToggleCb = (e) => {
        this.toggleFooterMenu(e.currentTarget);
      };

      this.lazyLoadAppBadges();

      this.resetMenu();
      ResizeHandler.addResizeEndFn(() => this.resetMenu());
      this.decorateSeoLinks();
      this.initiateFeedbackLink();
    }
	if(exists('form.contact-form')) {
    deletePropAvailSessionData();
    deleteSearchOverviewSessionData();
    deleteRoomsRatesSessionData();
  }
 }

  resetMenu() {
    $('.set-title').off('click', null, this.footerToggleCb);
    if (!isMobileWidth()) {
      $('.collapsing-set').show();
    } else {
      $('.collapsing-set').hide();
      $('.set-title i').removeClass('angle-rotate');
      $('.set-title').click(this.footerToggleCb);
    }
  }

  toggleFooterMenu(el) {
    let setTitle = $(el),
      setDropdown = setTitle.next(),
      arrow = setTitle.children('i');

    setDropdown.slideToggle(500, () => {
      arrow.toggleClass('angle-rotate');
    });
  }

  decorateSeoLinks() {
    $('#seoLinksCollapse a').each((index,el) => {
      let topDestText = $(el).text()
          .split(' '),
        topDestStr = '';
      for(let i = 0; i < topDestText.length; i++) {
        if(i == 2) {
          topDestStr += '<strong>';
        }
        topDestStr += topDestText[i] + ' ';
        if(i == topDestText.length - 1) {
          topDestStr += '</strong>';
        }
      }
      $(el).html(topDestStr);
    });
  }

  lazyLoadAppBadges() {
    if(CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() != 'CN') {
      $('#android-app-download img').attr('data-src','https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png').removeAttr('style');
      lazyLoadImageInViewport('.app-download-container .lazy-load-footer-app-badge');
    } else {
      $('#android-app-download img').css('display','none');
      lazyLoadImageInViewport('.app-download-container #apple-app-download');
    }
  }

  initiateFeedbackLink() {
    let kampyleId = 24378;
    if(exists('.website-feedback')) {
      $('.website-feedback').on('click', (e) => {
        if(window.KAMPYLE_ONSITE_SDK) {
          if (window.KAMPYLE_ONSITE_SDK.showForm(kampyleId) === false) {
            console.error(`KAMPYLE_ONSITE_SDK was not able to execute the showForm call using id ${kampyleId}`);
          }
        } else {
          console.error("KAMPYLE_ONSITE_SDK is not available.");
      }
        e.preventDefault();
        e.stopPropagation();
      });
    }
  }

}


export default new Footer();
