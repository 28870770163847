import ConfigsUtils from '../base/aem-configs/config-utils.js';
import { ACCESS_TOKEN, REFRESH_TOKEN, _LOCALE_, _defaultChannelId} from '../base/vars.js';
import { CookieHandler, EventHandler } from '../base/utils.js';
import { createAuth0Client } from '@auth0/auth0-spa-js';

let oktaSigninClient;
let isTokenRetrievalInProgress = false;
let isClientInitializationInProgress = false;
let hasCheckLocalStorage = false;
let tokenRetrievalPromise = null;
let clientInitializationPromise = null;

class OktaClient {
    constructor() {
        this.getOktaClient();
    }

    async getOktaClient() {
        this.removeBackupAuth0LocalStorage();
        if (oktaSigninClient) {
            return oktaSigninClient;
        }
        if (isClientInitializationInProgress) {
            await clientInitializationPromise;
            return oktaSigninClient;
        }
        const classContext = this;
        isClientInitializationInProgress = true;
        clientInitializationPromise = new Promise(async(resolve, reject) => {
            try {
                let oktaClientParameters = {
                    domain: classContext.getOktaUrl(),
                    clientId: classContext.getOktaClientId(),
                    useRefreshTokens: true,
                    cacheLocation: 'localstorage',
                    audience: classContext.getOktaAudience(),
                    scope: classContext.getOktaScope(),
                };
                oktaSigninClient = await createAuth0Client(oktaClientParameters);
                resolve();
            } catch (err) {
                reject(err);
            } finally {
                isClientInitializationInProgress = false;
            }
        });
        await clientInitializationPromise;
        return oktaSigninClient;
    }

    async getTokenFromOktaClient() {
        if (isTokenRetrievalInProgress) {
            return await tokenRetrievalPromise;
        }
        const classContext = this;
        isTokenRetrievalInProgress = true;
        tokenRetrievalPromise = new Promise(async(resolve, reject) => {
            try {
                const token = await oktaSigninClient.getTokenSilently({
                    authorizationParams: {
                        audience: classContext.getOktaAudience(),
                        scope: classContext.getOktaScope(),
                    }
                });
                resolve(token);
            } catch(err) {
                reject(err);
            } finally {
                isTokenRetrievalInProgress = false;
            }
        });
        return await tokenRetrievalPromise;
    }

    async validateOktaToken() {
        // get refresh token from cookie
        const refresh_token = CookieHandler.readCookie(REFRESH_TOKEN);
        // if stored token is not undefined or null, check with okta client.
        let storedToken = this.fetchRefreshTokenFromLocalStorage();
        // check if Auth0 token exist for refreshing
        if (storedToken) {
            // create refresh token if doesn't exist in cookie
            if (!refresh_token) {
                CookieHandler.createCookie(REFRESH_TOKEN, storedToken, 1);
            }
            // remove id_token from storage as there is one available in auth0 local storage
            // this was created during join/ftsi flow
            localStorage.removeItem('id_token');
            try {
                const accessToken = await this.getTokenFromOktaClient();
                CookieHandler.createCookie(ACCESS_TOKEN, accessToken, 1);
            } catch (err) {
                EventHandler.send(EventHandler.profile.oktaSignOut);
                return false;
            }
        // if store token doesn't exist and refresh token doesn't exist in cookie, return false
        } else if (!refresh_token) {
            return false;
        }
        return true;
    }

    resetOktaclient() {
        if (oktaSigninClient) {
            oktaSigninClient = null;
        }
    }

    getOktaUrl() {
        return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
            (result, item) => {
            return item.name === 'okta_domain_url' && item.value ? item.value : result;
            },
            null
        );
    }
    
    getOktaClientId() {
        return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
            (result, item) => {
            return item.name === 'okta_clientId' && item.value ? item.value : result;
            },
            null
        );
    }

    getOktaAudience() {
        return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
            (result, item) => {
            return item.name === 'okta_audience' && item.value ? item.value : result;
            },
            null
        );
    }

    getOktaScope() {
        return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
            (result, item) => {
            return item.name === 'okta_scope' && item.value ? item.value : result;
            },
            null
        );
    }

    fetchRefreshTokenFromLocalStorage(){
        let key, refreshToken;
    
        for (let n = 0, len = localStorage.length; n < len; ++n) {
            const thisKey = localStorage.key(n);
            if (thisKey && thisKey.startsWith('@@auth0spajs@@') && thisKey.includes(this.getOktaAudience()) && thisKey.includes(this.getOktaScope())) {
                key = thisKey;
                break;
            }
        }
        if (key) {
            const value = JSON.parse(localStorage.getItem(key));
            console.log("fetchRefreshTokenFromLocalStorage::key", key);
			console.log("fetchRefreshTokenFromLocalStorage::value", value);
            refreshToken = value.body.refresh_token;
        }
        return refreshToken;
    }

    createBackupAuth0LocalStorage() {
        for (let n = 0, len = localStorage.length; n < len; ++n) {
            const thisKey = localStorage.key(n);
            if (thisKey && thisKey.startsWith('@@auth0spajs@@')) {
                const auth0Value = localStorage.getItem(thisKey);
                localStorage.setItem("backup-" + thisKey, auth0Value);
            }
        }
    }    
    
    removeBackupAuth0LocalStorage() {
        if (!hasCheckLocalStorage) {
            hasCheckLocalStorage = true;
            for (let n = 0, len = localStorage.length; n < len; ++n) {
                const thisKey = localStorage.key(n);
                if (thisKey && thisKey.startsWith('backup-@@auth0spajs@@')) {
                    const key = thisKey.replace('backup-','');
                    const auth0Value = localStorage.getItem(key);
                    if (!auth0Value) {
                        const backupAuth0value = localStorage.getItem(thisKey);
                        localStorage.setItem(key, backupAuth0value);
                    }
                    localStorage.removeItem(thisKey);
                }
            }
        }
    }

    createAuth0LocalStorageObject() {
        if (this.isPhApp()) {
            return;
        }
        const storedToken = this.fetchRefreshTokenFromLocalStorage();
        if (storedToken) {
            return;
        }
        const access_token = CookieHandler.readCookie('access_token');
        const refresh_token = CookieHandler.readCookie('refresh_token');
        if (!access_token || !refresh_token) {
            return;
        }
        const body = {
            access_token: access_token,
            audient: this.getOktaAudience(),
            client_id: this.getOktaClientId(),
            expires_in: 3600,
            oauthTokenScope: this.getOktaScope(),
            refresh_token: refresh_token,
            scope: this.getOktaScope(),
            token_type: 'Bearer'
        };
        const auth0Value = {
            body: body,
            expiresAt: ''
        };
        const auth0Key = '@@auth0spajs@@' + '::' + this.getOktaClientId() + '::' + this.getOktaAudience() + '::' + this.getOktaScope();
        localStorage.setItem(auth0Key, JSON.stringify(auth0Value));
    }

    isPhApp() {
        const urlParams = new URLSearchParams(location.search),
            hasPhAppParams = urlParams.has('platform') && urlParams.has('adobeID');
        if (hasPhAppParams) {
            return true;
        }
        const ftsiObject = localStorage.getItem('ftsi'),
        ftsiInfo = ftsiObject ? JSON.parse(ftsiObject) : undefined,
        enrollmentObject = localStorage.getItem('enrollmentInfo'),
        enrollmentInfo = enrollmentObject ? JSON.parse(enrollmentObject) : undefined;
        let hasPhAppFlag = false;

        if (ftsiInfo && ftsiInfo.isPhApp) {
            hasPhAppFlag = true;
        }
        if (enrollmentInfo && enrollmentInfo.isPhApp) {
            hasPhAppFlag = true;
        }
        return hasPhAppFlag;
    }
}

export default new OktaClient();
