class BadgeExternalized {
  constructor() {
    //
  }

  getAmenitiesRanking(shortCodes, lang) {
    const jsonData = JSON.stringify({"amenities": shortCodes, "language": lang});
    const url = '/bin/getBadges';
    let df = $.Deferred();
    let imgData;
      $.ajax({
        url: url,
        type: 'POST',
        data: jsonData,
        contentType: "application/json",
        success: function(data) {
            imgData = df.resolve(data);
            return imgData;
        },
        error: function(res) {
            df.reject(res);
        }
      });

      return df.promise();
  }

  badgeExternalizedClick() {
    $('.badge-externalize-modal').on('click', (e) => {
      let badgeMdl = $('#badge-modal'),
        modalDtls = $(e.currentTarget).next('.badge-externalized-description')
          .html(),
        badgeMdlSec = badgeMdl.find('.modal-section');
      badgeMdlSec.html(modalDtls);
    });
  }
}

export default new BadgeExternalized();
