import {
  exists,
  matchElementsHeight,
  ResizeHandler,
  isMobileWidth
} from '../base/dom-utils.js';

class TabbedFeaturedServiceDetails {
  constructor() {
    if (exists('.tabbed-fsd-component .carousel')) {
      this.initCarousel();
      this.handleSlideHeights();
    }
  }

  initCarousel() {
    $('.tabbed-fsd-component .carousel').carousel({
      pause: true,
      interval: 0
    });
  }

  handleSlideHeights() {
    // Normalize slide heights once images load
    $(() => {
      ResizeHandler.addResizeEndFn(() => this.tabbedFSDFixedHeight());
      if ($('body').is('.ie')) {
        $('.tabbed-fsd-component').each((i,el) => {
          $(el).find('img')
            .on('img.load', () => this.tabbedFSDFixedHeight());
        });
      }
      this.tabbedFSDFixedHeight();
    });
  }

  tabbedFSDFixedHeight() {
    $('.tabbed-fsd-component .tab-pane, .tabbed-fsd-component .item').css('height', 'auto');
    if (isMobileWidth()) {
      $('.tabbed-fsd-component .carousel').each((i,el) => {
        matchElementsHeight($(el).find('.item'));
      });
    } else {
      $('.tabbed-fsd-component .tab-content').each((i,el) => {
        let tabPanes = $(el).find('.tab-pane');
        // IE miscalculates height of hidden tab elements. Show them all before calculating height,
        // then hide them afterward.
        if ($('body').is('.ie')) {
          tabPanes.show();
        }
        matchElementsHeight(tabPanes);
        if ($('body').is('.ie')) {
          let tmpHeight = tabPanes.height();
          tabPanes.removeAttr('style').height(tmpHeight);
        }
      });
    }
  }
}

export default new TabbedFeaturedServiceDetails();
