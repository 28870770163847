
class Modal {
  constructor() {
    this.onModalClose();
  }
  show(options, modal) {
    let el = this.getModalElement(modal);
    $(el).modal(options);
    if (options && options.backdrop === 'static') {
      $(el).addClass('modal-locked');
    }
    $(document).trigger('forceCloseDropdowns');
  }
  close(modal) {
    let el = this.getModalElement(modal);
    $(el).modal('hide');
    $(el).removeClass('modal-locked');
    if (window.brand_id.toLowerCase() === 'wr') {
      this.emptyModalContent(modal);
    }
  }
  showModalSpinner(modal) {
    let el = this.getModalElement(modal);
    $(el).find('.modal-content')
      .html('<div class="spinner"></div>');
  }
  hideModalSpinner(modal) {
    let el = this.getModalElement(modal);
    $(el).find('.modal-content .spinner')
      .remove();
  }
  emptyModalContent(modal) {
    let el = this.getModalElement(modal);
    $(el).find('.modal-content')
      .empty();
  }
  populateModalContent(content, modal) {
    let el = this.getModalElement(modal);
    $(el).find('.modal-content')
      .html(content);
    $(document).trigger('checkEmptyInput');
  }
  getModalElement(modal) {
    let el = modal;
    if (!modal) {
      el = $('#genericLightbox');
    }
    return el;
  }
  onModalClose() {
    $('.modal:not(.booknow_widget):not(.locations-filter-modal):not(.mycheck-payment-details)').on('hidden.bs.modal', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.close($(e.currentTarget));
    });
  }
}

export default new Modal();
