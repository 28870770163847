import {
  BBForm
} from '../components/booking-bar.js';
import {
  getBrand,
  getBrandName,
  getBrandTier,
  getCriteria,
  setCriteria,
  getLocalStorage
} from './session-handler.js';
import {
  _isWeakTrue,
  _isValidBrandTier,
  formatDateForBWSAvailability,
  getISOFormatDate,
  isGoFastRate,
  isLoyaltyRP,
  hyphenToCamel,
  removeQuery,
  CookieHandler,
  EventHandler,
  _isNotNull,
  getDateFromDateString,
  getNumDays
} from './utils.js';
import {
  $_PAGE_,
  brandMap,
  brand_id,
  isPublish,
  property_city_name,
  property_country_code,
  property_country_name,
  property_postal_code,
  property_state_code,
  property_state_name,
  SESSION_PROFILE
} from './vars.js';
import { exists } from './dom-utils.js';
class AboutMyStayAnalytics {
  constructor(analytics) {
    this.analytics = analytics;
  }
  trackLookUp() {
    this.analytics.satelliteTracker('abtMyStayLookUpReservation');
  }
  trackIDontKnowMyConf() {
    this.analytics.satelliteTracker('abtMyStayIDKMCN');
  }
  trackAboutMyStaySubmit() {
    this.analytics.satelliteTracker('abtMyStaySubmit');
  }
  trackAboutMyStaySubmitSuccessfull() {
    this.analytics.satelliteTracker('abtMyStaySuccessSubmit');
  }
  saveCategoryAndSubcategory(category, subcategory) {
    if (window.digitalData.page.pageInfo) {
      window.digitalData.page.pageInfo.aboutMyStayCategory = [category, subcategory].join(' | ');
    }
  }
  removeCategoryAndSubcategory() {
    delete window.digitalData.page.pageInfo.aboutMyStayCategory;
  }
  saveConfirmationNumber(confirmationNumber) {
    if (!window.digitalData.booking.hasOwnProperty('confirmation')) {
      window.digitalData.booking.confirmation = {
        confirmInfo: {
          confirmationNumber: confirmationNumber
        }
      };
    } else {
      if (!window.digitalData.booking.confirmation.hasOwnProperty('confirmInfo')) {
        window.digitalData.booking.confirmation.confirmInfo = {
          confirmationNumber: confirmationNumber
        };
      } else {
        window.digitalData.booking.confirmation.confirmInfo.confirmationNumber = confirmationNumber;
      }
    }
  }
}

class Analytics {
  constructor() {
    this.unloadEvent = 'pagehide';
    this.referrerCookieName = 'firstReferringBrand';
    this.formErrors = [];
    this.pageUrl = decodeURIComponent(window.location.href);

    if (isPublish) {
      this.onSignInSuccess();
      this.handleAnalyticsUnload();
      this.addReferrerEvent();
      this.onRoomsRatesServiceEnd();
      this.onUpdateSearchBrandValue();
      this.addErrorEventListeners();
    }
    this.aboutMyStayAnalytics = new AboutMyStayAnalytics(this);
  }
  getAboutMyStayAnalytics() {
    return this.aboutMyStayAnalytics;
  }
  onSignInSuccess(){
    EventHandler.on('sign-in-success', () => this.getUserInfo());
    console.log("sign-in-success");
  }
  getBrand() {
    let brandName = '';
    // Different ways to get the brand name
    if (window.pageProperties && window.pageProperties.brand) {
      brandName = window.pageProperties.brand;
    } else if (typeof getBrandName !== 'undefined') {
      brandName = getBrandName();
    } else if (brandMap && brand_id && brandMap.hasOwnProperty(brand_id)) {
      brandName = brandMap[brand_id];
    } else {
      console.log('Unable to get brand name.');
    }
    // Odd exception due to mismatching brand mapping
    if (brandName === 'umbrella') {
      brandName = 'wyndham-hotel-group';
    }
    return brandName || '';
  }
  // Get Authentication data from Session Cookie
  getSessionData(session) {
    let nextLevel = session.level ? this.getNextLevel(session.level) : '',
      nightsLeft = session.level === 'Diamond' || session.level === 'Titanium' ? 0 : session.nightsNeeded || '',
      progression = nextLevel !== '' && nightsLeft !== '' ? nightsLeft + ' nights|' + nextLevel : '';
    return {
      authenticated: session.state && session.state === 'AUTHENTICATED' ? 'yes' : 'no',
      wyndhamRewardsID: session.membershipId ? session.membershipId : '',
      pointsExpiration: session.expiration ? session.expiration : '',
      pointsBalance: session.points ? session.points : '',
      memberLevel: session.level ? session.level : '',
      memberProgression: progression ? progression : ''
    };
  }
  // Get Authentication data from User Object
  getUserData(user) {
    if (user && user.currencies) {
      let currLevel = user.currentTier.description,
        nextLevel = user.projectTied.description,
        nightsLeft = currLevel === 'DIAMOND' || currLevel === 'TITANIUM' ? 0 : user.aspirationTiers[0].requiredAmount || '',
        progression = nextLevel !== '' && nightsLeft !== '' ? nightsLeft + ' nights|' + nextLevel.toLowerCase() : '';

      return {
        authenticated: 'yes',
        wyndhamRewardsID: user.UniqueID.Id,
        pointsExpiration: user.CustLoyalty[0].ExpireDate,
        pointsBalance: user.currencies.available,
        memberLevel: user.CustLoyalty[0].LoyaltyLevel.toLowerCase(),
        memberProgression: progression
      };
    }
    return {
      authenticated: 'no',
      wyndhamRewardsID: '',
      pointsExpiration: '',
      pointsBalance: '',
      memberLevel: '',
      memberProgression: ''
    };
  }
  onRoomsRatesServiceEnd() {
    if ($_PAGE_.is('.rooms-rates-page')) {
      EventHandler.one(EventHandler.serviceCall.end, () => {
        if (!window.digitalData.search.searchInfo.adults || window.digitalData.search.updateSearchData) {
          window.digitalData.search.updateSearchData = false;
          this.handleSearchAnalytics();
        }
        EventHandler.triggerEvent('roomsRatesView');
      });
    }
  }
  onUpdateSearchBrandValue() {
    EventHandler.on('updateSearchBrandValue', () => {
      this.updateSearchBrandValue();
    });
  }
  updatePropTierNum(tierValue){
	window.digitalData.property.propertyInfo.tierNum = tierValue;
  }
  getHotelDealsCarouselInfo(component) {
    window.digitalData.event.pageComponents.componentType = $(component)
      .text()
      .replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    window.digitalData.event.pageComponents.componentLocation = 'Hotel Deals and Discounts';
    window.digitalData.event.pageComponents.componentName = $(component)
      .prev()
      .prev()
      .text();
    window.digitalData.event.eventInfo.eventName = 'Component Click';
  }
  getPromoDealsInfo(component) {
    window.digitalData.event.pageComponents.componentType = $(component)
      .text()
      .replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    window.digitalData.event.pageComponents.componentLocation = 'Promo Deals';
    window.digitalData.event.pageComponents.componentName = $(component)
      .prev()
      .prev()
      .text();
    window.digitalData.event.eventInfo.eventName = 'Component Click';
  }
  getContentModuleInfo(component) {
    window.digitalData.event.pageComponents.componentType = $(component)
      .find('div')
      .text()
      .replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    window.digitalData.event.pageComponents.componentLocation = 'Content Module';
    window.digitalData.event.pageComponents.componentName = $(component)
      .parent()
      .parent()
      .find('.main-header')
      .text();
    window.digitalData.event.eventInfo.eventName = 'Component Click';
  }
  /*
   * Extracted from _scripts/media-queries.js, please keep updated accordingly.
   */
  getSystemEnv() {
    let windowWidth = window.innerWidth;

    if (windowWidth < 720) {
      return 'mobile';
    } else if (windowWidth > 719 && windowWidth < 1200) {
      return 'tablet';
    } else if (windowWidth > 1199) {
      return 'desktop';
    }
    return '';
  }
  getNumberOfDays(checkInDate) {
    let oneDay = 24 * 60 * 60 * 1000,
      firstDate = new Date(),
      secondDate,
      diffDays,
      formattedDate,
      month,
      day,
      year,
      dateString;
    formattedDate = checkInDate.split('-');
    month = firstDate.getMonth() * 1 + 1;
    day = (firstDate.getDate() < 10) ? '0' + firstDate.getDate() : firstDate.getDate();
    year = firstDate.getFullYear();
    dateString = month + '-' + day + '-' + year;

    if(checkInDate === dateString) {
      firstDate.setHours(0, 0, 0, 0);
    }

    //Month needs to be minus one, months start at 0 in JS
    formattedDate[0] = parseFloat(formattedDate[0]) - 1;
    //year - month - day
    secondDate = new Date(parseFloat(formattedDate[2]), parseFloat(formattedDate[0]), parseFloat(formattedDate[1]));
    diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
    return diffDays;
  }
  getSelectNewRoomRate() {
    if ($_PAGE_.hasClass('modify-page') && $_PAGE_.hasClass('select')) {
      window.digitalData.page.pageInfo.pageName = 'select-a-new-room';
      window.digitalData.page.category.primaryCategory = 'modify';
      this.fireAnalyticsPageEvent();
    }
  }
  handleError(res) {
    EventHandler.triggerEvent('brands-error', res);
  }
  handleFormFieldError(error) {
    $.each(error._ui.$errorsWrapper.children('li'), (i, e) => {
      if (!_.contains(this.formErrors, e.innerText)) {
        this.formErrors.push(e.innerText);
      }
    });
  }
  handleSearchAnalytics() {
    let searchCriteria = getCriteria(),
      tempCheckIn = formatDateForBWSAvailability(searchCriteria.checkInDate),
      tempCheckOut = formatDateForBWSAvailability(searchCriteria.checkOutDate),
      checkInDate = this.parseDate(tempCheckIn),
      checkOutDate = this.parseDate(tempCheckOut),
      brandTier = getBrandTier(),
      roomNights = 0,
      formattedCheckOutDate,
      formattedCheckInDate,
      rateData,
      specialRateDrop,
      specialRateData,
      currentBrandCode = getBrand(true).toLowerCase();

    if (brandTier) {
      currentBrandCode = brandTier;
    }

    rateData = BBForm.getRateCodeData();
    specialRateData = BBForm.getSpecialRate();
    specialRateDrop = searchCriteria.specialRateDrop;

    // Calculate number of nights
    roomNights = this.daydiff(checkInDate, checkOutDate) * searchCriteria.rooms;

    //format date for analytics
    formattedCheckInDate = tempCheckIn.split('-');
    formattedCheckOutDate = tempCheckOut.split('-');

    //make sure month/day are two digits
    if (parseFloat(formattedCheckInDate[0]) < 10 && formattedCheckInDate[0].length < 2) {
      formattedCheckInDate[0] = '0' + formattedCheckInDate[0];
    }
    if (parseFloat(formattedCheckInDate[1]) < 10 && formattedCheckInDate[1].length < 2) {
      formattedCheckInDate[1] = '0' + formattedCheckInDate[1];
    }
    if (parseFloat(formattedCheckOutDate[0]) < 10 && formattedCheckOutDate[0].length < 2) {
      formattedCheckOutDate[0] = '0' + formattedCheckOutDate[0];
    }
    if (parseFloat(formattedCheckOutDate[1]) < 10 && formattedCheckOutDate[1].length < 2) {
      formattedCheckOutDate[1] = '0' + formattedCheckOutDate[1];
    }
    formattedCheckInDate = formattedCheckInDate[2] + '-' + formattedCheckInDate[0] + '-' + formattedCheckInDate[1];
    formattedCheckOutDate = formattedCheckOutDate[2] + '-' + formattedCheckOutDate[0] + '-' + formattedCheckOutDate[1];

    if (!searchCriteria.children) {
      searchCriteria.children = 0;
    }

    //populate analytics with search data
    if (_isNotNull(searchCriteria.searchInfo_destination) &&
        ($_PAGE_.is('.property-page') || $_PAGE_.is('.rooms-rates-page') || $_PAGE_.is('.booking-page'))) {
      window.digitalData.search.searchInfo.destination = searchCriteria.searchInfo_destination;
    } else {
      window.digitalData.search.searchInfo.destination = `${property_city_name}${(property_city_name !== '' && property_state_name !== '') ? ', ' : ''}`;
      window.digitalData.search.searchInfo.destination += `${property_state_name}${(property_state_name !== '' && property_country_code !== '') ? ', ' : ''}`;
      window.digitalData.search.searchInfo.destination += `${(property_city_name !== '' && property_city_name !== '' && property_state_name === '') ? ', ' : ''}${property_country_code}`;
      setCriteria({
        searchInfo_destination: window.digitalData.search.searchInfo.destination
      });
    }

    window.digitalData.search.searchInfo.checkIn = formattedCheckInDate;
    window.digitalData.search.searchInfo.checkOut = formattedCheckOutDate;
    window.digitalData.search.searchInfo.roomNights = parseFloat(roomNights);
    window.digitalData.search.searchInfo.rooms = parseFloat(searchCriteria.rooms);
    window.digitalData.search.searchInfo.adults = parseFloat(searchCriteria.adults);
    window.digitalData.search.searchInfo.children = parseFloat(searchCriteria.children).toString();
    window.digitalData.search.searchInfo.totalGuests = parseFloat(searchCriteria.children) + parseFloat(searchCriteria.adults);

    if (!window.digitalData.search.searchInfo.searchResultsCount) {
      window.digitalData.search.searchInfo.searchResultsCount = '';
    }

    if (CookieHandler.readCookie('UserGeoData')) {
      window.digitalData.search.searchInfo.currentLocation = 'Yes';
    } else {
      window.digitalData.search.searchInfo.currentLocation = 'No';
    }

    if (_isWeakTrue(searchCriteria.useWRPoints)) {
      window.digitalData.search.searchInfo.rewardsPoints = 'Yes';
    } else {
      window.digitalData.search.searchInfo.rewardsPoints = 'No';
    }

    //get Rate Type data
    if (rateData && rateData.type) {
      window.digitalData.search.searchInfo.specialRateType = rateData.type.toUpperCase();
      window.digitalData.search.searchInfo.specialRate = 'Yes';
      window.digitalData.search.searchInfo.specialRateCode = rateData.code;
      if ($('input[value="' + rateData.type + '"]')) {
        let rateInput = $('.booking-bar input[value="' + rateData.type + '"]').next()[0];
        window.digitalData.search.searchInfo.specialRateType = $(rateInput).text();
        if (!window.digitalData.search.searchInfo.initialRateCode) {
          window.digitalData.search.searchInfo.initialRateCode = $(rateInput).text();
        }
      } else if (!window.digitalData.search.searchInfo.initialRateCode) {
        window.digitalData.search.searchInfo.initialRateCode = rateData.type;
      }
    } else if (specialRateDrop){
      window.digitalData.search.searchInfo.initialRateCode = specialRateDrop;
      window.digitalData.search.searchInfo.specialRate = 'Yes';
      window.digitalData.search.searchInfo.specialRateType = specialRateDrop;
      if (specialRateData) {
        window.digitalData.search.searchInfo.specialRateCode = specialRateData.code;
      }
    } else if (!window.digitalData.search.searchInfo.initialRateCode) {
      window.digitalData.search.searchInfo.initialRateCode = 'BAR';
      window.digitalData.search.searchInfo.specialRate = 'No';
      window.digitalData.search.searchInfo.specialRateCode = '';
    } else {
      window.digitalData.search.searchInfo.specialRate = 'No';
      window.digitalData.search.searchInfo.specialRateCode = '';
      window.digitalData.search.searchInfo.specialRateType = '';
    }

    //get search channel info
   /* if (window.digitalData.pagePath) {
      let searchChannel = window.digitalData.pagePath;
      if (searchChannel.indexOf('directory/university') > -1) {
        window.digitalData.search.searchInfo.searchChannel = 'University';
      } else if (searchChannel.indexOf('directory/airports') > -1) {
        window.digitalData.search.searchInfo.searchChannel = 'Airports';
      } else if (searchChannel.indexOf('directory/amenities') > -1) {
        window.digitalData.search.searchInfo.searchChannel = 'Amenities';
      } else if (searchChannel.indexOf('directory/landmarks') > -1) {
        window.digitalData.search.searchInfo.searchChannel = 'Landmarks';
      } else if (searchChannel.indexOf('directory/stadiums') > -1) {
        window.digitalData.search.searchInfo.searchChannel = 'Stadiums';
      } else {
        window.digitalData.search.searchInfo.searchChannel = 'City';
      }
    } else {
      window.digitalData.search.searchInfo.searchChannel = 'City';
    }*/

    if (brandMap[currentBrandCode] && !window.digitalData.search.searchInfo.searchBrand) {
      window.digitalData.search.searchInfo.searchBrand = brandMap[currentBrandCode];
    }
  }
  updateSearchBrandValue() {
    let currentBrand = window.pageProperties.brand;

    if (currentBrand && currentBrand === 'umbrella') {
      currentBrand = 'wyndham-hotel-group';
    }
    if (
      !CookieHandler.readCookie('firstSearchBrand') &&
      currentBrand !== '' &&
      (!window.digitalData.search.searchInfo.searchBrand || window.digitalData.search.searchInfo.searchBrand === '' || window.digitalData.search.searchInfo.searchBrand.trim().length < 1)
    ) {
      CookieHandler.createCookie('firstSearchBrand', currentBrand);
    }
    if (!window.digitalData.search.searchInfo.searchBrand || window.digitalData.search.searchInfo.searchBrand === '' || window.digitalData.search.searchInfo.searchBrand.trim().length < 1) {
      window.digitalData.search.searchInfo.searchBrand = CookieHandler.readCookie('firstSearchBrand');
    }
    $(window).unload(() => {
      CookieHandler.eraseCookie('firstSearchBrand');
    });
  }
  updateFormName() {
    let formName;
    if(exists($('.do-not-sell-info'))) {
      formName = "DONOTSELL";
    } else {
      formName = $('.contact-form .category select').val();
    }
    window.digitalData.event.contactUs.formName = formName;
  }
  updateCancellationData(res) {
    let propName = $('.res-summary-container .reservation-information .property-name').text(),
      brandTier = getBrandTier();

    window.digitalData.booking.cancelBookingInfo.siteID = this.pad(res.propertyId, 5);
    window.digitalData.booking.cancelBookingInfo.brandID = res.brandId;
    window.digitalData.booking.cancelBookingInfo.propertyName = propName;
    if (_isValidBrandTier(brand_id) && brandTier !== '') {
      window.digitalData.booking.cancelBookingInfo.tierBrandID = brandTier.toUpperCase();
    } else {
      window.digitalData.booking.cancelBookingInfo.tierBrandID = "";
    }

    window.digitalData.booking.cancelBookingInfo.propertyCity = window.propertyInfo && window.propertyInfo.propertyCity ? window.propertyInfo.propertyCity : property_city_name;
    window.digitalData.booking.cancelBookingInfo.propertyState = window.propertyInfo && window.propertyInfo.propertyState ? window.propertyInfo.propertyState : property_state_code;
    window.digitalData.booking.cancelBookingInfo.propertyPostal = window.propertyInfo && window.propertyInfo.propertyPostal ? window.propertyInfo.propertyPostal : this.pad(property_postal_code, 5);
    window.digitalData.booking.cancelBookingInfo.propertyCountry = window.propertyInfo && window.propertyInfo.propertyCountry ? window.propertyInfo.propertyCountry : property_country_name;
    window.digitalData.page.pageInfo.pageName = 'cancel-booking';
    window.digitalData.page.category.primaryCategory = 'find-reservation';
  }
  /** @func
   * @name updateMultiRoomBookingAnalytics
   * @desc Updates the value in Data Layer Element -> window.digitalData.booking.bookingInfo.multiRoom.
   * @param {integer} rooms Number of rooms.
   * @param {string} code Room code
   * @param {string} name Room type short description.
   * @param {integer} nights Number of nights (overall).
   * @param {integer} taxes Taxes Fees (overall).
   * @param {integer} inclusive Total Inclusive (overall).
   * @param {integer} exclusive Total Exclusive (overall).
   * @param {integer} confirmationNumbers Confirmation number(comma separated if multiple).
   */
  updateMultiRoomBookingAnalytics(rooms, code, name, nights, taxes, inclusive, exclusive, confirmationNumbers) {
    let confirmationNumbersArray, roomObj;

    if (rooms && rooms > 0 && confirmationNumbers && confirmationNumbers !== '') {
      confirmationNumbersArray = confirmationNumbers.split(',');
      window.digitalData.booking.bookingInfo.multiRoom = [];
      roomObj = {};

      for (let i = 0; i <= rooms - 1; i++) {
        roomObj = {
          roomName: name,
          roomType: code,
          roomNights: nights,
          taxesFees: parseFloat(((taxes * nights) / rooms).toFixed(2)),
          totalInclusive: parseFloat((inclusive / rooms).toFixed(2)),
          totalExclusive: parseFloat((exclusive / rooms).toFixed(2)),
          confNumber: confirmationNumbersArray.length > 1 ? confirmationNumbersArray[i] : confirmationNumbersArray[0]
        };
        window.digitalData.booking.bookingInfo.multiRoom.push(roomObj);
      }
    }
  }
  updateRoomsRates(button) {
    if (!$_PAGE_.is('.modify-page')) {
      if ($('.sort-bar .tab.tab-1').hasClass('active')) {
        // console.log("toggle by room", window.digitalData.roomsRates);
        window.digitalData.roomsRates.display.selectionToggleView = 'By Room';
      } else {
        // console.log("toggle by rate", window.digitalData.roomsRates);
        window.digitalData.roomsRates.display.selectionToggleView = 'By Rates';
      }
      if (
        $(button)
          .parents('.room')
          .find('.warn')
          .text().length
      ) {
        window.digitalData.roomsRates.alert.alertMessage = 'yes';
        window.digitalData.roomsRates.alert.alertType = 'Urgency Message';
        window.digitalData.roomsRates.alert.alertValue = $(button)
          .parents('.room')
          .find('span.warn')
          .text();
      } else {
        window.digitalData.roomsRates.alert.alertMessage = 'no';
        window.digitalData.roomsRates.alert.alertType = '';
        window.digitalData.roomsRates.alert.alertValue = '';
      }
      //this might be a duplicate call and need to be adjusted for booking.
      this.fireAnalyticsPageEvent();
    }
  }
  updateSearchAnalyticsValues(locationDetails) {
    if (locationDetails) {
      if (locationDetails.city) {
        locationDetails.city = locationDetails.city.charAt(0).toUpperCase() + locationDetails.city.slice(1);
      } else {
        locationDetails.city = "";
      }
      window.digitalData.search.searchInfo.destination = `${locationDetails.city}${(locationDetails.city !== '' && locationDetails.state !== '') ? ', ' : ''}`;
      window.digitalData.search.searchInfo.destination += `${locationDetails.state}${(locationDetails.state !== '' && locationDetails.countryCode !== '') ? ', ' : ''}`;
      window.digitalData.search.searchInfo.destination += `${(locationDetails.countryCode !== '' && locationDetails.city !== '' && locationDetails.state === '') ? ', ' : ''}${locationDetails.countryCode}`;
      setCriteria({
        searchInfo_destination: window.digitalData.search.searchInfo.destination
      });
    }

  }
  updateSearchRefinementAnalytics(refinementType, refinementSelection) {
    window.digitalData.search.filter.refinementType = refinementType || '';
    window.digitalData.search.filter.refinementSelection = refinementSelection || '';
  }
  updateAnalyticsSearchCount(searchCount) {
    window.digitalData.search.searchInfo.searchResultsCount = searchCount;
  }
  updateAnalyticsSearchToggle(toggleType) {
    window.digitalData.search.display.selectionToggle = toggleType;
  }
  updateSearchSortAnalytics(sortType) {
    window.digitalData.search.display.displaySelection = sortType;
  }
  updateSearchAlertAnalytics(alert, alertType, alertValue) {
    window.digitalData.search.alert.alertMessage = alert;
    window.digitalData.search.alert.alertType = alertType;
    window.digitalData.search.alert.alertValue = alertValue;
  }
  updateRoomsData() {
    let rooms = $('.room:not(.clone):visible');
    if (rooms) {
      window.digitalData.roomsRates.display.rooms = [];
      $.each(rooms, (i, el) => {
        let $room = $(el),
          room = {
            roomName: $room.find('.room-title span').text(),
            roomCode: $room.attr('room'),
            alertValue: $room
              .find('span.warn')
              .eq(0)
              .text() || 'no alert message'
          };

        window.digitalData.roomsRates.display.rooms.push(room);
      });
    }
  }
  updateRatesData() {
    let rates = $('li.rate:not(.clone)');
    if (rates) {
      window.digitalData.roomsRates.display.visibleRates = [];
      window.digitalData.roomsRates.display.hiddenRates = [];
      $.each(rates, (i, el) => {
        let $rate = $(el),
          rateName = $rate.find('.rate-title').text(),
          ratePlan = $rate.attr('rate'),
          rate = {
            rateName: rateName,
            ratePlan: ratePlan
          };

        if ($rate.is(':visible') && !_.any(window.digitalData.roomsRates.display.visibleRates, rate)) {
          window.digitalData.roomsRates.display.visibleRates.push(rate);
        } else if (!isLoyaltyRP(ratePlan) && !_.any(window.digitalData.roomsRates.display.hiddenRates, rate)) {
          window.digitalData.roomsRates.display.hiddenRates.push(rate);
        }
      });
    }
  }
  updateCancellationConfirmationNumber(cancellationReservationNumber) {
    window.digitalData.confirmation.cancelConfirmInfo.cancelledConfirmationNumber = cancellationReservationNumber;
  }
  updateCurrencyCode(code) {
    window.digitalData.booking.bookingInfo.currencyCode = code;
  }
  beforeFormValidate() {
    this.formErrors = [];
  }
  afterFormValidate(form) {
    if (!form.$element.parsley().isValid()) {
      EventHandler.triggerEvent('brands-form-error');
    }
  }
  addReferrerEvent() {
    window.addEventListener(this.unloadEvent, e => {
      let referrerCookie = CookieHandler.readCookie(this.referrerCookieName),
        currBrand = this.getBrand(),
        newSession = false;

      if (window.previousReferrerCookieOnLoad) {
        // Current page was loaded whitin an existent session
        if (referrerCookie) {
          // Session still alive, its expiration time needs to be updated.
          CookieHandler.eraseCookie(this.referrerCookieName);
          this.createReferrerCookie(window.digitalData.page.pageInfo.referringBrand);
        } else {
          // Session got expired due to inactivity or deleted after cookies cleanup.
          newSession = true;
        }
      } else {
        // No previous session was active
        newSession = true;
      }

      if (newSession) {
        // A new session needs to be created from the current page brand.
        if (currBrand !== '') {
          CookieHandler.eraseCookie(this.referrerCookieName);
          this.createReferrerCookie(currBrand);
        } else {
          // Brand name might not be available, in that case a fallback or an error need to be fired.
          console.log('Referring brand is not available. Not able to create a new session.');
        }
      }
    });
  }
  addErrorEventListeners() {
    if (document) {
      document.addEventListener(
        'brands-error',
        evt => {
          let error = null;
          if (evt) {
            if (evt.detail && evt.detail.responseJSON && evt.detail.responseJSON.ErrorMessage) {
              error = evt.detail.responseJSON.ErrorMessage;
            } else if (evt.detail && evt.detail.responseJSON && evt.detail.responseJSON.errmsg) {
              error = evt.detail.responseJSON.errmsg;
            } else if (evt.detail && evt.detail.errorMessage) {
              error = evt.detail.errorMessage;
            } else if (evt.detail && evt.detail.errmsg) {
              error = evt.detail.errmsg;
            } else {
              error = 'An error occurred, but no error message is available in the response';
            }
          } else {
            error = 'An error occurred, but no error message is available in the response';
          }
          this.resetErrorMessages();
          window.digitalData.error.errorInfo.errorMessage = error;
        },
        false
      );

      // Global Form error
      document.addEventListener(
        'brands-form-error',
        evt => {
          this.resetErrorMessages();
          window.digitalData.error.errorInfo.errorFormMessage = this.formErrors;
        },
        false
      );
    }
  }
  getSessionObj() {
    let sessionName = SESSION_PROFILE,
      session = getLocalStorage(sessionName) || '';

    if (session && typeof session === 'string') {
      try {
        session = JSON.parse(session);
      } catch (e) {
        return '';
      }
    }
    return session;
  }
  getUserInfo() {
    console.log("getUserInfo");
    let session = this.getSessionObj();
    if (session && session.state === 'AUTHENTICATED') {
      window.digitalData.user.userInfo.authenticated = 'Yes';
    } else {
      window.digitalData.user.userInfo.authenticated = 'No';
    }

    if (session && session.state.match(/(AUTHENTICATED)/)) {
      window.digitalData.user.userInfo.wyndhamRewardsID = session.membershipId;
      window.digitalData.user.userInfo.pointsExpiration = session.pointsExpiration;
      window.digitalData.user.userInfo.pointsBalance = session.points;
      window.digitalData.user.userInfo.memberLevel = session.level;
      window.digitalData.user.userInfo.memberProgression = this.setMemberProgression();
    } else {
      window.digitalData.user.userInfo.wyndhamRewardsID = '';
      window.digitalData.user.userInfo.pointsExpiration = '';
      window.digitalData.user.userInfo.pointsBalance = '';
      window.digitalData.user.userInfo.memberLevel = '';
      window.digitalData.user.userInfo.memberProgression = '';
    }
  }
  setMemberProgression() {
    let session = this.getSessionObj(),
      nextLevel = this.getNextLevel(),
      nights = session && (session.level === 'Diamond' || session.level === 'Titanium') ? 0 : session.nightsNeeded || null;

    if (nextLevel && nights !== null) {
      return nights + ' nights|' + nextLevel;
    } else {
      return '';
    }
  }
  getNextLevel() {
    let session = this.getSessionObj();
    switch (session && session.level.toLowerCase()) {
    case 'blue':
      return 'gold';
    case 'gold':
      return 'platinum';
    case 'platinum':
      return 'diamond';
    case 'diamond':
      return 'diamond';
    case 'titanium':
      return 'titanium';
    default:
      return '';
    }
  }
  pad(int, max) {
    return int.toString().length < max ? this.pad('0' + int, max) : int;
  }
  cookiesEnabled() {
    let cookieEnabled = navigator.cookieEnabled;

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }
    return cookieEnabled;
  }
  createReferrerCookie(value) {
    // Referrer cookie should last after 30min of inactivity.
    CookieHandler.createCookie(this.referrerCookieName, value, this.fromMinutesToDays(30));
  }
  fromMinutesToDays(minutes) {
    return minutes / (24 * 60);
  }
  processAddressType(addressType) {
    return addressType === 1 ? 'Home' : 'Business';
  }
  processSubscribeEStatements(subscribeEStatements) {
    return subscribeEStatements === 'Preferred' ? 'yes' : 'no';
  }
  _isSearchResultsPage() {
    if ($_PAGE_ && $_PAGE_.length > 0 && $_PAGE_.hasClass('search-results-page')) {
      return true;
    }
    return false;
  }
  satelliteTracker(directCall) {
    if (typeof window._satellite !== 'undefined' && typeof directCall !== 'undefined') {
      window._satellite.track(directCall);
    }
  }
  parseDate(str) {
    let mdy = str.split('-');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  daydiff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  setEnteredRewardsID(rewardsID) {
    window.digitalData.user.userInfo.enteredRewardsID = rewardsID;
  }
  setConfirmationAnalyticsData(res) {
    let formattedCity = res.customerCity,
      tempCheckIn = formatDateForBWSAvailability(res.checkInDate),
      bookingWindow = this.getNumberOfDays(tempCheckIn);

    formattedCity = formattedCity.toLowerCase();
    formattedCity = formattedCity.charAt(0).toUpperCase() + formattedCity.slice(1);

    if ($('.page-content').hasClass('reservation-modified')) {
      window.digitalData.modify.confirmation.confirmationInfo.confirmationNumber = res.confirmationNumber;
      window.digitalData.modify.confirmation.confirmationInfo.customerCity = formattedCity;
      window.digitalData.modify.confirmation.confirmationInfo.customerState = res.customerState;
      window.digitalData.modify.confirmation.confirmationInfo.customerPostal = this.pad(res.customerZip, 5);
      window.digitalData.modify.confirmation.confirmationInfo.customerCountry = res.customerCountry;
      window.digitalData.modify.confirmation.confirmationInfo.bookingWindow = bookingWindow + ' days';
      window.digitalData.page.pageInfo.pageName = 'modify-confirmation';
      window.digitalData.page.category.primaryCategory = 'modify';
    } else {
      window.digitalData.confirmation.confirmInfo.confirmationNumber = res.confirmationNumber;
      window.digitalData.confirmation.confirmInfo.itineraryNumber = res.itineraryNumber;
      window.digitalData.confirmation.confirmInfo.confirmationNumbers = res.confirmationNumbers;
      window.digitalData.confirmation.confirmInfo.customerCity = formattedCity;
      window.digitalData.confirmation.confirmInfo.customerState = res.customerState;
      window.digitalData.confirmation.confirmInfo.customerPostal = this.pad(res.customerZip, 5);
      window.digitalData.confirmation.confirmInfo.customerCountry = res.customerCountry;
      window.digitalData.confirmation.confirmInfo.bookingWindow = bookingWindow + ' days';
    }

    // Multi Room Analytics
    if (
      window.digitalData.confirmation.confirmInfo.confirmationNumbers &&
      window.digitalData.confirmation.confirmInfo.confirmationNumbers !== '' &&
      res.rooms &&
      res.roomCode &&
      res.rooms > 0 &&
      res.nights &&
      res.nights > 0
    ) {
      this.updateMultiRoomBookingAnalytics(
        res.rooms,
        res.roomCode,
        res.roomDescriptionShort,
        res.nights,
        window.digitalData.booking.bookingInfo.taxesFees,
        window.digitalData.booking.bookingInfo.totalInclusive,
        window.digitalData.booking.bookingInfo.totalExclusive,
        window.digitalData.confirmation.confirmInfo.confirmationNumbers
      );
    }
  }
  setModifiedReservationOriginalData(originalData) {
    let formattedTaxes,
      specialRateUsed,
      propName = $('.current-rate-summary h5.property-name').text(),
      rateData = getCriteria().ratePlan;

    if (!originalData.children) {
      originalData.children = 0;
    }
    formattedTaxes = parseFloat(originalData.averageAfterTax) - parseFloat(originalData.averageBeforeTax);
    if (rateData) {
      specialRateUsed = 'Yes';
    } else {
      specialRateUsed = 'No';
    }

    window.digitalData.modify.booking.bookingInfo.originalCheckIn = originalData.checkInDate;
    window.digitalData.modify.booking.bookingInfo.originalCheckOut = originalData.checkOutDate;
    window.digitalData.modify.booking.bookingInfo.originalRoomNights = originalData.nights;
    window.digitalData.modify.booking.bookingInfo.originalRooms = originalData.rooms;
    window.digitalData.modify.booking.bookingInfo.originalAdults = parseFloat(originalData.adults);
    window.digitalData.modify.booking.bookingInfo.originalChildren = parseFloat(originalData.children);
    window.digitalData.modify.booking.bookingInfo.originalTotalGuests = parseFloat(originalData.children) + parseFloat(originalData.adults);
    window.digitalData.modify.booking.bookingInfo.originalSpecialRate = specialRateUsed;
    window.digitalData.modify.booking.bookingInfo.originalSpecialRateType = '';
    window.digitalData.modify.booking.bookingInfo.originalSpecialRateCode = '';
    window.digitalData.modify.booking.bookingInfo.originalRoomName = originalData.roomDescriptionShort;
    window.digitalData.modify.booking.bookingInfo.originalBedtype = originalData.roomCode;
    window.digitalData.modify.booking.bookingInfo.originalRateName = originalData.ratePlanName;
    window.digitalData.modify.booking.bookingInfo.originalRatePlan = originalData.rateCode;
    window.digitalData.modify.booking.bookingInfo.originalTaxesFees = parseFloat(formattedTaxes.toFixed(2));
    window.digitalData.modify.booking.bookingInfo.originalTotalExclusive = originalData.averageBeforeTax;
    window.digitalData.modify.booking.bookingInfo.originalTotalInclusive = originalData.averageAfterTax;
    window.digitalData.modify.roomsRates.propertyInfo.siteID = originalData.propertyId;
    window.digitalData.modify.roomsRates.propertyInfo.brandID = originalData.brandId;
    window.digitalData.modify.roomsRates.propertyInfo.propertyName = window.propertyInfo && window.propertyInfo.propertyName ? window.propertyInfo.propertyName : propName;
    window.digitalData.modify.roomsRates.propertyInfo.propertyCity = window.propertyInfo && window.propertyInfo.propertyCity ? window.propertyInfo.propertyCity : property_city_name;
    window.digitalData.modify.roomsRates.propertyInfo.propertyState = window.propertyInfo && window.propertyInfo.propertyState ? window.propertyInfo.propertyState : property_state_code;
    window.digitalData.modify.roomsRates.propertyInfo.propertyPostal = window.propertyInfo && window.propertyInfo.propertyPostal ? window.propertyInfo.propertyPostal : property_postal_code;
    window.digitalData.modify.roomsRates.propertyInfo.propertyCountry = window.propertyInfo && window.propertyInfo.propertyCountry ? window.propertyInfo.propertyCountry : property_country_name;

    window.digitalData.page.pageInfo.pageName = 'modify-reservation';
    window.digitalData.page.category.primaryCategory = 'modify';

    this.fireAnalyticsPageEvent();
  }
  reviewModifyRoomsRates(oldRes, newRes) {
    if ($_PAGE_.hasClass('modify-page') && $_PAGE_.hasClass('confirm')) {
      let formattedTaxes = parseFloat(newRes.totalAfterTax) - parseFloat(newRes.totalBeforeTax),
        rateData = BBForm.getRateCodeData();

      //get Rate Type data
      if (rateData && rateData.type) {
        window.digitalData.modify.review.reviewInfo.newSpecialRateType = rateData.type.toUpperCase();
        window.digitalData.modify.review.reviewInfo.newSpecialRate = 'Yes';
        window.digitalData.modify.review.reviewInfo.newSpecialRateCode = rateData.code;
        if ($('input[value="' + rateData.type + '"]')) {
          let rateInput = $('.booking-bar input[value="' + rateData.type + '"]').next()[0];
          window.digitalData.modify.review.reviewInfo.newSpecialRateType = $(rateInput).text();
          if (!window.digitalData.modify.review.reviewInfo.newSpecialRateCode) {
            window.digitalData.modify.review.reviewInfo.newSpecialRateCode = $(rateInput).text();
          }
        } else if (!window.digitalData.modify.review.reviewInfo.newSpecialRateCode) {
          window.digitalData.modify.review.reviewInfo.newSpecialRateCode = rateData.type;
        }
      } else if (!window.digitalData.modify.review.reviewInfo.newSpecialRateCode) {
        window.digitalData.modify.review.reviewInfo.newSpecialRateCode = 'BAR';
        window.digitalData.modify.review.reviewInfo.newSpecialRate = 'No';
        window.digitalData.modify.review.reviewInfo.newSpecialRateCode = '';
      } else {
        window.digitalData.modify.review.reviewInfo.newSpecialRate = 'No';
        window.digitalData.modify.review.reviewInfo.newSpecialRateCode = '';
        window.digitalData.modify.review.reviewInfo.newSpecialRateType = '';
      }

      window.digitalData.modify.review.reviewInfo.newCheckIn = getISOFormatDate(newRes.checkInDate);
      window.digitalData.modify.review.reviewInfo.newCheckOut = getISOFormatDate(newRes.checkOutDate);
      window.digitalData.modify.review.reviewInfo.newRoomNights = newRes.nights;
      window.digitalData.modify.review.reviewInfo.newRooms = newRes.rooms;
      window.digitalData.modify.review.reviewInfo.newAdults = newRes.adults;
      window.digitalData.modify.review.reviewInfo.newChildren = newRes.children;
      window.digitalData.modify.review.reviewInfo.newTotalGuests = parseFloat(newRes.children) + parseFloat(newRes.adults);
      window.digitalData.modify.review.reviewInfo.netChangeExclusive = oldRes.averageBeforeTax - newRes.totalBeforeTax;
      window.digitalData.modify.review.reviewInfo.netChangeInclusive = oldRes.averageAfterTax - newRes.totalAfterTax;
      window.digitalData.modify.review.bookingInfo.newRoomName = newRes.shortName;
      window.digitalData.modify.review.bookingInfo.newRoomType = newRes.roomTypeCode;
      window.digitalData.modify.review.bookingInfo.newRateName = newRes.ratePlanName;
      window.digitalData.modify.review.bookingInfo.newRatePlan = newRes.ratePlanId;
      window.digitalData.modify.review.bookingInfo.newAverageDailyRate = parseFloat(newRes.averageBeforeTax.toFixed(2));
      window.digitalData.modify.review.bookingInfo.newTaxesFees = parseFloat(formattedTaxes.toFixed(2));
      window.digitalData.modify.review.bookingInfo.newExclusivePrice = parseFloat(newRes.totalBeforeTax.toFixed(2));
      window.digitalData.modify.review.bookingInfo.newInclusivePrice = parseFloat(newRes.totalAfterTax.toFixed(2));
      window.digitalData.modify.review.bookingInfo.currencyCode = newRes.currencyCode;
      window.digitalData.page.pageInfo.pageName = 'review-rooms-rates';
      window.digitalData.page.category.primaryCategory = 'modify';
      this.fireAnalyticsPageEvent();
    }
  }
  confirmationSetReward(optInValue) {
    if (optInValue) {
      window.digitalData.confirmation.confirmInfo.wyndhamRewardsOptIn = 'Yes';
    } else {
      window.digitalData.confirmation.confirmInfo.wyndhamRewardsOptIn = 'No';
    }
  }
  confirmationSetDirectBillNumber(billNo) {
      window.digitalData.confirmation.confirmInfo.directBillNumber = billNo;
  }
  confirmationSetWynOptIn(optInValue) {
    if (optInValue) {
      window.digitalData.confirmation.confirmInfo.wyndhamOffersOptIn = 'Yes';
    } else {
      // Value should be blank until opt-in is deployed. After deployment, uncomment "No" line.
      window.digitalData.confirmation.confirmInfo.wyndhamOffersOptIn = '';
    }
  }
  confirmationSetBrandOptIn(optInValue) {
    if (optInValue) {
      window.digitalData.confirmation.confirmInfo.brandOffersOptIn = 'Yes';
    } else {
      window.digitalData.confirmation.confirmInfo.brandOffersOptIn = 'No';
    }
  }
  confirmationSetPaymentType(paymentType, rateCode) {
    if (rateCode && isGoFastRate(rateCode)) {
      paymentType += ' - Go Fast';
    }
    window.digitalData.confirmation.confirmInfo.paymentType = paymentType;
  }
  analyticsConfirmSubscribeEstatements(optInValue) {
    if (optInValue) {
      window.digitalData.user.preferences.subscribeEstatements = 'Yes';
    } else {
      window.digitalData.user.preferences.subscribeEstatements = 'No';
    }
  }
  analyticsConfirmWyndhamRewardsPartnerOptIn(optInValue) {
    if (optInValue) {
      window.digitalData.confirmation.confirmInfo.wyndhamRewardsPartnerOptIn = 'Yes';
    } else {
      window.digitalData.confirmation.confirmInfo.wyndhamRewardsPartnerOptIn = 'No';
    }
  }
  analyticsConfirmBrandPartnerOptIn(optInValue) {
    if (optInValue) {
      window.digitalData.confirmation.confirmInfo.brandPartnerOptIn = 'Yes';
    } else {
      window.digitalData.confirmation.confirmInfo.brandPartnerOptIn = 'No';
    }
  }
  handleAnalyticsUnload() {
    EventHandler.on('analytics:unload', () => {
      this.analyticsBeforeUnload();
    });
  }
  analyticsBeforeUnload(e) {
    window.digitalData.page.pageInfo.referringURL = removeQuery(decodeURIComponent(this.pageUrl));
    window.digitalData.booking.cancelBookingInfo.tierBrandID = '';

    if (typeof Storage !== 'undefined' && this.sessionStorageAvailable()) {
      sessionStorage.digitalData = JSON.stringify(window.digitalData);
    }
  }
  sessionStorageAvailable() {
    try {
      sessionStorage.testStorage = 1;
      return sessionStorage.testStorage != undefined;
    } catch (error) {
      return false;
    }
  }
  populateBookingRoomInfoAnalytics(room, rate, ratePlan) {
    //let formattedTaxes = parseFloat(rate.averageAfterTax) - parseFloat(rate.averageBeforeTax),
    let checkInDate = getDateFromDateString(getCriteria().checkInDate),
      checkOutDate = getDateFromDateString(getCriteria().checkOutDate),
      noOfNights = getNumDays(checkInDate, checkOutDate),
      noOfRooms = getCriteria().rooms || $('#numOfRooms').val(),
      multiRoomObj = {};

    //UU has this available, so if it's there just use that instead.
    /*if (rate.totalTax && rate.totalTax > 0) {
      formattedTaxes = rate.totalTax;
    }*/

    // format the number
    // formattedTaxes = Math.ceil(formattedTaxes * 100) / 100;
    if (room.shortName != null && room.shortName != undefined) {
      window.digitalData.booking.bookingInfo.roomName = room.shortName.replace(/^,|,$|,+/g, '').trim();
    } else {
      window.digitalData.booking.bookingInfo.roomName = room.shortName;
    }

    if ($_PAGE_.hasClass('booking-page')) {
      //this.updateSearchAnalyticsValues(getLocationDetails());
      let searchCriteria = getCriteria();
      if (_isNotNull(searchCriteria.searchInfo_destination)) {
        window.digitalData.search.searchInfo.destination = searchCriteria.searchInfo_destination;
      } else {
        window.digitalData.search.searchInfo.destination = `${property_city_name}${(property_city_name !== '' && property_state_name !== '') ? ', ' : ''}`;
        window.digitalData.search.searchInfo.destination += `${property_state_name}${(property_state_name !== '' && property_country_code !== '') ? ', ' : ''}`;
        window.digitalData.search.searchInfo.destination += `${(property_city_name !== '' && property_city_name !== '' && property_state_name === '') ? ', ' : ''}${property_country_code}`;
        setCriteria({
          searchInfo_destination: window.digitalData.search.searchInfo.destination
        });
      }
    }

    window.digitalData.booking.bookingInfo.roomType = room.roomTypeCode;
    window.digitalData.booking.bookingInfo.rateName = ratePlan.RatePlanName;
    window.digitalData.booking.bookingInfo.ratePlan = rate.ratePlanId;
    window.digitalData.booking.bookingInfo.averageDailyRate = parseFloat(rate.averageBeforeTax.toFixed(2));
    window.digitalData.booking.bookingInfo.taxesFees = parseFloat((rate.totalAfterTax - rate.totalBeforeTax).toFixed(2));
    if (window.digitalData.packages && window.digitalData.packages.packageCartVal) {
      window.digitalData.booking.bookingInfo.totalInclusive = parseFloat(rate.totalAfterTax.toFixed(2)) + parseFloat(window.digitalData.packages.packageCartVal);
    } else {
      window.digitalData.booking.bookingInfo.totalInclusive = parseFloat(rate.totalAfterTax.toFixed(2));
    }
    window.digitalData.booking.bookingInfo.totalExclusive = parseFloat(rate.totalBeforeTax.toFixed(2));
    window.digitalData.booking.bookingInfo.currencyCode = rate.currencyCode;

    if(noOfRooms && noOfRooms > 0) {
      for(let i = 0; i < noOfRooms; i++) {
        multiRoomObj = {
          roomType: room.roomTypeCode,
          roomName: window.digitalData.booking.bookingInfo.roomName,
          roomNights: noOfNights,
          taxesFees: parseFloat(((rate.totalAfterTax / noOfRooms) - (rate.totalBeforeTax / noOfRooms)).toFixed(2)),
          totalInclusive: parseFloat((rate.totalAfterTax / noOfRooms).toFixed(2)),
          totalExclusive: parseFloat((rate.totalBeforeTax / noOfRooms).toFixed(2))
        };
        if (window.digitalData.packages && window.digitalData.packages.packageCartVal) {
          multiRoomObj.totalInclusive = parseFloat(Number(multiRoomObj.totalInclusive + (window.digitalData.packages.packageCartVal / noOfRooms)).toFixed(2));
        }
        window.digitalData.booking.bookingInfo.multiRoom.push(multiRoomObj);
      }
    }

    this.fireAnalyticsPageEvent();
  }
  removeQuery(a_name) {
  if ((a_name != '') && (a_name != null) && (a_name != undefined)) {
    a_name = a_name.split('?')[0];
    a_name = a_name.split('#')[0];
    a_name = a_name.split('&')[0];
  }
  return a_name;
  }
  logEmptyValues() {
    let pageInfo = window.digitalData.page.pageInfo;
    let categoryInfo = window.digitalData.page.category;
    if(!(pageInfo.pageName && pageInfo.pageName.length > 0)) {
      console.error("Analytic Error-'pageName' Before value: " + pageInfo.pageName + " - After value: " + window.pageProperties.pagePath.replace('/jcr:content', '').split('/')[window.pageProperties.pagePath.replace('/jcr:content', '').split('/').length - 1]);
      window.digitalData.page.pageInfo.pageName = window.pageProperties.pagePath.replace('/jcr:content', '').split('/')[window.pageProperties.pagePath.replace('/jcr:content', '').split('/').length - 1];
    }
    if(!(pageInfo.pagePath && pageInfo.pagePath.length > 0)) {
      console.error("Analytic Error-'pagePath' Before value: " + pageInfo.pagePath + " - After value: " + window.pageProperties.pagePath);
      window.digitalData.page.pageInfo.pagePath = window.pageProperties.pagePath;
    }
    if(!(pageInfo.language && pageInfo.language.length > 0)) {
      console.error("Analytic Error-'language' Before value: " + pageInfo.language + " - After value: " + ((window.pageProperties && window.pageProperties.language) ? window.pageProperties.language : (pageInfo.geoRegion ? pageInfo.geoRegion.split('-')[0] : null)));
      window.digitalData.page.pageInfo.language = window.pageProperties && window.pageProperties.language ? window.pageProperties.language : (pageInfo.geoRegion ? pageInfo.geoRegion.split('-')[0] : null);
    }
    if(!(pageInfo.geoRegion && pageInfo.geoRegion.length > 0)) {
      console.error("Analytic Error-'geoRegion' Before value: " + pageInfo.geoRegion + " - After value: " + ((window.pageProperties && window.pageProperties.geoRegion) ? window.pageProperties.geoRegion : (pageInfo.geoRegion ? pageInfo.geoRegion.split('-')[1] : null)));
      window.digitalData.page.pageInfo.geoRegion = window.pageProperties && window.pageProperties.geoRegion ? window.pageProperties.geoRegion : (pageInfo.geoRegion ? pageInfo.geoRegion.split('-')[1] : null);
    }
    if(!(pageInfo.systemEnv && pageInfo.systemEnv.length > 0)) {
      console.error("Analytic Error-'systemEnv' Before value: " + pageInfo.systemEnv + " - After value: " + window.systemEnv);
      window.digitalData.page.pageInfo.systemEnv = window.systemEnv;
    }
    if(!(pageInfo.effectiveDate && pageInfo.effectiveDate.length > 0)) {
      console.error("Analytic Error-'effectiveDate' Before value: " + pageInfo.effectiveDate + " - After value: " + ((window.pageProperties && window.pageProperties.lastModified) ? window.pageProperties.lastModified : (pageInfo.lastModified ? pageInfo.lastModified : null)));
      window.digitalData.page.pageInfo.effectiveDate = window.pageProperties && window.pageProperties.lastModified ? window.pageProperties.lastModified : (pageInfo.lastModified ? pageInfo.lastModified : null);
    }
    if(!(categoryInfo.brand && categoryInfo.brand.length > 0)) {
      console.error("Analytic Error-'brand' Before value: " + categoryInfo.brand + " - After value: " + ((window.pageProperties && window.pageProperties.brand) ? window.pageProperties.brand : (pageInfo.brand ? pageInfo.brand : null)));
      window.digitalData.page.category.brand = window.pageProperties && window.pageProperties.brand ? window.pageProperties.brand : (pageInfo.brand ? pageInfo.brand : null);
    }
    if(!(categoryInfo.breadCrumbs && categoryInfo.breadCrumbs.length > 0)) {
      console.error("Analytic Error-'breadCrumbs' Before value: " + categoryInfo.breadCrumbs + " - After value: " + ((window.pageProperties && window.pageProperties.breadCrumbs) ? window.pageProperties.breadCrumbs : (pageInfo.breadCrumbs ? pageInfo.breadCrumbs : null)));
      window.digitalData.page.category.breadCrumbs = window.pageProperties && window.pageProperties.breadCrumbs ? window.pageProperties.breadCrumbs : (pageInfo.breadCrumbs ? pageInfo.breadCrumbs : null);
    }
    if(!(categoryInfo.primaryCategory && categoryInfo.primaryCategory.length > 0)) {
      console.error("Analytic Error-'primaryCategory' Before value: " + categoryInfo.primaryCategory + " - After value: " + ((window.pageProperties && window.pageProperties.channel) ? window.pageProperties.channel : (pageInfo.primaryCategory ? pageInfo.primaryCategory : null)));
      window.digitalData.page.category.breadCrumbs = window.pageProperties && window.pageProperties.channel ? window.pageProperties.channel : (pageInfo.primaryCategory ? pageInfo.primaryCategory : null);
    }
    if(!(pageInfo.destinationURL && pageInfo.destinationURL.length > 0)) {
      console.error("Analytic Error-'destinationURL' Before value: " + pageInfo.destinationURL + " - After value: " + this.removeQuery(window.pageUrl));
      window.digitalData.page.pageInfo.destinationURL = removeQuery(window.pageUrl);
    }
    if(!(pageInfo.referringBrand && pageInfo.referringBrand.length > 0)) {
      console.error("Analytic Error-'referringBrand' Before value: " + pageInfo.referringBrand + " - After value: " + (window.pageProperties && window.pageProperties.referringBrand) ? window.pageProperties.referringBrand : null);
      window.digitalData.page.pageInfo.referringBrand = (window.pageProperties && window.pageProperties.referringBrand) ? window.pageProperties.referringBrand : null;
    }
  }
  fireAnalyticsPageEvent() {

    if ($_PAGE_.hasClass('search-results-page')) {
      // Search Results
      this.logEmptyValues();
      this.satelliteTracker('searchView');
    } else if ($_PAGE_.hasClass('rooms-rates-page')) {
      // Rooms and Rates
      this.logEmptyValues();
      this.satelliteTracker('roomsRatesView');
    } else if ($_PAGE_.hasClass('booking-page') && !$_PAGE_.hasClass('packages-page')) {
      // Booking
      this.logEmptyValues();
      this.satelliteTracker('bookingView');
    } else if ($_PAGE_.hasClass('packages-page')) {
      // Packages
      this.logEmptyValues();
      this.satelliteTracker('packagesView');
    } else if ($_PAGE_.hasClass('confirmation-page') && window.location.href.indexOf('reservation/confirm') > -1) {
      // Confirmation
      this.logEmptyValues();
      this.satelliteTracker('confirmationView');
    } else if ($_PAGE_.hasClass('confirmation-page') && $('.page-content').hasClass('reservation-modified')) {
      // Confirmed Reservation Modification
      this.logEmptyValues();
      this.satelliteTracker('modificationConfirmation');
    } else if ($_PAGE_.hasClass('modify-page')) {
      // Modify Edit Dates
      if ($_PAGE_.hasClass('select')) {
        // Modify- Select A new Room
        this.logEmptyValues();
        this.satelliteTracker('selectNewRoomRate');
      } else if ($_PAGE_.hasClass('confirm')) {
        // Modify- Review Changes
        this.logEmptyValues();
        this.satelliteTracker('reviewRoomRate');
      }
    }
  }
  resetErrorMessages() {
    if(window.digitalData){
      window.digitalData.error.errorInfo.errorMessage = '';
      window.digitalData.error.errorInfo.errorFormMessage = [];
    }
  }

  handlePerfTimings(timings) {
    timings.forEach((entry) => {
      let camelName = hyphenToCamel(entry.name);
      if(window.digitalData.perfMetrics[camelName]) {
        window.digitalData.perfMetrics[camelName].startTime = entry.startTime;
        window.digitalData.perfMetrics[camelName].duration = entry.duration;
      }
    });

    this.satelliteTracker('wynPerfMetrics');
  }

  updateRewardLockStatus(status){
    window.digitalData.user.userInfo.rewardLockStatus = status;
  }

  updatePreSavedRefinementAnalytics(preSavedRefinementType, preSavedRefinementSelection, trackerEvent = 'preSelectedFiltersLoaded') {
    window.digitalData.search.filter.preSavedRefinementType = preSavedRefinementType || '';
    window.digitalData.search.filter.preSavedRefinementSelection = preSavedRefinementSelection || '';
    this.satelliteTracker(trackerEvent);
  }

  updateBookingPreferenceAnalytics(bookingPreferenceType, bookingPreferenceValue, trackerEvent = 'accountPreferenceSaved') {
    console.log('About to call updateBookingPreferenceAnalytics :: ', {bookingPreferenceType, bookingPreferenceValue, trackerEvent});
    window.digitalData.user.preferences.bookingPreferenceType = bookingPreferenceType || '';
    window.digitalData.user.preferences.bookingPreferenceSelection = bookingPreferenceValue || '';
    this.satelliteTracker(trackerEvent);
  }



}

let analyticsInst = new Analytics();

window.Wyndham = window.Wyndham || {};
window.Wyndham.Analytics = analyticsInst;

window.Parsley.on('form:validate', () => {
  analyticsInst.beforeFormValidate();
});

window.Parsley.on('field:error', (e) => {
  analyticsInst.handleFormFieldError(e);
});

window.Parsley.on('form:validated', (e) => {
  analyticsInst.afterFormValidate(e);
});

export default analyticsInst;
