import { BBExpandCollapse } from '../components/booking-bar.js';
import {
  exists,
  stickify,
  ResizeHandler,
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth,
  swapHeaderImages,
  isHomepageExtendedHeroException
} from '../base/dom-utils.js';
import Dropdown from '../components/dropdown.js';
// import LazyLoad from '../base/lazy-load.js';
import { getHeaderHeight, EventHandler, getPromoHeight, getBookingBarHeight, RetailBannerUtils } from '../base/utils.js';
import {
  $_BOOKING_BAR_MAIN_,
  $_PAGE_,
  $_PAGE_HERO_,
  $_PAGE_TOP_
} from '../base/vars.js';
import Header from '../components/header.js';

class Home {
  constructor() {
    this.$logoImg = $('.navbar__logo-container img');
    this.$rewardsLogoImg = $('img.wyndham-rewards-logo');
    this.$heroBackImg = $_PAGE_HERO_.find('.has-bg-img');
    this.$mag = $('.magnifying-glass-container');
    this.desktopScrollDistance = 10;
    this.scrollOpts = {};    
    this.loadPromotionFlag();
    if (isHomepageExtendedHeroException()) {
      if ($('.extended-hero-container').length) {
        this.$extendedHero = $('.extended-hero-container');
      } else {
        this.$extendedHero = $('.extended-hero-component');
      }
      this.putBookingBarIntoExtendedHero();
    }

    $_PAGE_TOP_.hide();
    $('#bookingBarCollapsed').remove();
    this.onPageScroll();
    this.onResizeEnd();
    this.bindEvents();
    this.initCarousel();
    this.bookingBarSetTop(true);
    this.setHeroHeight();
    RetailBannerUtils.slideDownRetailBanner();
  }
  /**
   * Finds the next and prev slide to load.
   **/
  preLoadSlides($element, $homepageHeroCarousel) {
    let $carouselInner = $homepageHeroCarousel.find('.carousel-inner').children(),
      activeIndex = $element.index(),
      nextIndex = activeIndex + 1,
      prevIndex = activeIndex - 1;
    if (nextIndex >= $carouselInner.length) {
      nextIndex = 0;
    }
    if (prevIndex < 0) {
      prevIndex = $carouselInner.length - 1;
    }
  }
  initCarousel() {
    if (exists('#homepageHeroCarousel') && $('#homepageHeroCarousel').find('.item').length > 1) {
      let $homepageHeroCarousel = $('#homepageHeroCarousel'),

        interval = !(isMobileWidth()) ? $homepageHeroCarousel.data('timer') * 1000 : 0;

      $homepageHeroCarousel.carousel({
        interval: interval,
        pause: null
      });
      EventHandler.on(EventHandler.lazyLoad.ready, () => {
        this.preLoadSlides($homepageHeroCarousel.find('.active'), $homepageHeroCarousel);
        $homepageHeroCarousel.on('slid.bs.carousel', () => {
          this.preLoadSlides($homepageHeroCarousel.find('.active'), $homepageHeroCarousel);
        });
      });
    }
    if ($('.booking-bar-form').hasClass('display-none')) {
      $_PAGE_.addClass('no-booking');
      $('#homepageHeroCarousel .hero-text').removeClass('hidden-xs');
      $('#homepageHeroCarousel .caption').removeClass('hidden-xs');
    }
  }
  onResizeEnd() {
    ResizeHandler.addResizeEndFn(() => {
      Header.determinePageScroll();
      Header.positionPromoBanner();
      Header.setMobileBookingBarPosition();
      this.showMagnifyGlass(false);
      this.swapImages();

      BBExpandCollapse.collapse(() => {
        this.disableStick();
        this.bookingBarSetTop();
        this.populateStickyOpts();
        this.enableStick();
      });

      this.setHeroHeight();
    });
  }
  setHeroHeight(){
    let height = 'auto';

    if(isMobileWidth() && !$_PAGE_.hasClass('scrolled')){
      height = getHeaderHeight() + getPromoHeight() + getBookingBarHeight();
    }

    $_PAGE_HERO_.css({
      height: height
    });

    this.$heroBackImg.css({
      height: height
    });
  }
  bindEvents() {
    EventHandler.on('bgImg.load', () => this.bookingBarSetTop(), $_PAGE_HERO_.find('.has-bg-img'));
  }
  onPageScroll() {
    $(window).on('scroll', () => {
      Header.determinePageScroll();
      Header.setMobileBookingBarPosition();
      this.setHeroHeight();
      this.swapImages();
    });
  }
  findBookingSearchChannels(data) {
    for (const item of data) {
      if (item.hasOwnProperty('bookingSearchChannels')) {
        return item.bookingSearchChannels;
      }
    }
    return null;
  }

  loadPromotionFlag() { 
    if (exists($('.homepage')) || exists($('.homepage-template'))){
        $.ajax({
          url: "/bin/promotion-check",
          type: 'POST',
          data: JSON.stringify({currentPagePath:window.currentPagePath}),
          contentType: 'application/json',
          success: (res) => {
            $(".extended-hero-container").attr("data-promotion-toggle",res.promotionFlag);           
          },
          error: (res) => {
            EventHandler.triggerEvent('brands-error', res);
          }
        });
    }   
  }

  putBookingBarIntoExtendedHero() { // DAI-40069
    window.wyndham.configs = window.wyndham.configs || {};
    let hero = this.$extendedHero,
      $bb = $('.booking-bar-form.booking-bar-main');

    if (hero.length && $bb.length) {
      hero = hero.filter('.has-booking-bar');
      hero.append($bb.detach());

      if (!(isMobileWidth())) {
        if(window.wyndham.configs && window.wyndham.configs.hasOwnProperty("booking-search-configuration")){
            const channels = this.findBookingSearchChannels(window.wyndham.configs["booking-search-configuration"].configs);
            if (channels !== null && (channels.includes("desktop") || channels.includes("tablet"))){
              if($(".extended-hero-container.height-tall.has-booking-bar").length > 0){
                $(".extended-hero-container.height-tall.has-booking-bar").addClass("u-pin-to-top");
                $(".homepage.page.extended-hero:not(.scrolled) header").css("border", "0 none");
                $(window).scroll(()=> {
                  if($(window).scrollTop() >= $('.retail-banner-mt').height()) {
                    $(".extended-hero-container.height-tall.has-booking-bar.u-pin-to-top .booking-bar-form").addClass('extended-hero-sticky-bb');
                  } else{
                    $(".extended-hero-container.height-tall.has-booking-bar.u-pin-to-top .booking-bar-form").removeClass('extended-hero-sticky-bb');
                  }
                });
              }
            }
        }
      }
    }
  }
  swapImages(){
    if (!isHomepageExtendedHeroException()) {
      if(!$_PAGE_.hasClass('scrolled') && !$('#pageHeader').is('.old-navigation-hide')){
        swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'white');
      } else {
        swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'color');
      }
    } else {
      swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'color');
    }
  }
  showMagnifyGlass(show){
    if($_PAGE_.hasClass('homepage') && show){
      $('.magnifying-glass-container').css({'display': 'flex'});
    } else {
      $('.magnifying-glass-container')[0].style.display = '';
    }
  }
  applyMobileBookingFocusIn(e) {
    this.handleMobileBookingButtonFocusIn($(e.currentTarget).data('dropdown'));
  }
  handleBookingBarSaveCancelClicked(e) {
    $(e.currentTarget).parents('.save-cancel-container')
      .find('button')
      .off('click', (e) => this.handleBookingBarSaveCancelClicked(e.currentTarget));

    $_BOOKING_BAR_MAIN_.find('button[data-dropdown*=dropdown]').focusin((e) => this.applyMobileBookingFocusIn(e));
  }
  handleMobileBookingButtonFocusIn(el) {
    let $el = $(el);
    if (!($_PAGE_.is('.scrolled'))) {
      $_BOOKING_BAR_MAIN_.find('button[data-dropdown*=dropdown]').off('focusin', (e) => this.applyMobileBookingFocusIn(e));

      $_BOOKING_BAR_MAIN_.css({
        zIndex: 100
      });

      $el.one('dropdown:closed', () => {
        // Inline Z-index reset after setting to 100
        $_BOOKING_BAR_MAIN_.css({
          zIndex: ''
        });
      });
      $el.find('.save-cancel-container button').click((e) => this.handleBookingBarSaveCancelClicked(e));
    }
    Dropdown.setOptions();
  }
  turnHomepageBookingBarButtonsOn() {
    if (isMobileWidth()) {
      $_BOOKING_BAR_MAIN_.find('.dropdown-container').on('dropdown:open', (e) => this.handleMobileBookingButtonFocusIn(e.currentTarget));
    } else {
      $_BOOKING_BAR_MAIN_.find('.dropdown-container').off('dropdown:open', (e) => this.handleMobileBookingButtonFocusIn(e.currentTarget));
    }
  }
  bookingBarSetTop(isPageLoad) {
    if (isPageLoad === null || isPageLoad === undefined) {
      isPageLoad = true;
    }

    if (isDesktopWidth()) {
      if (!$_BOOKING_BAR_MAIN_.is('.expanded')) {
        let bot = ($_PAGE_HERO_.find('.active .caption').length) ? $_PAGE_HERO_.find('.active .caption').position().top : 0;

        $_BOOKING_BAR_MAIN_.css({
          top: bot - (2 * $_BOOKING_BAR_MAIN_.height())
        });

        $('#homepageHeroPush').height($_PAGE_HERO_.height());
      }

    } else if (isTabletWidth() && !isHomepageExtendedHeroException()) {
      let bot = ($_PAGE_HERO_.find('.active .caption').length) ? $_PAGE_HERO_.find('.active .caption').position().top : 0;
      // International booking bar is taller on tablet. Adjust position to compensate.
      let positionMultiplier = $_BOOKING_BAR_MAIN_.is('.intl-search') ? 1.15 : 1.35;
      $_BOOKING_BAR_MAIN_.css({
        top: bot - (positionMultiplier * $_BOOKING_BAR_MAIN_.height())
      });
    }

    if (isPageLoad) {
      this.populateStickyOpts();
      this.enableStick();
      this.turnHomepageBookingBarButtonsOn();

      if (window.pageYOffset > $_BOOKING_BAR_MAIN_.position().top) {
        this.stickifyBookingBarHomepage();
      }

      this.positionHeroText();
    }
  }
  positionHeroText() {
    let heroImageHeight = $_PAGE_HERO_.height(),
      heroTextHeight = $('#homepageHeroCarousel .hero-text').height();

    // Center Hero Text on Mobile
    if ($_PAGE_.hasClass('no-booking') && (isMobileWidth())) {
      let heroTextOffset = (heroImageHeight - heroTextHeight + getHeaderHeight()) / 2;
      $('#homepageHeroCarousel .hero-text').css({
        top: heroTextOffset
      });
    }
  }
  stickifyBookingBarHomepage() {
    stickify($_BOOKING_BAR_MAIN_, this.scrollOpts);
  }
  enableStick() {
    if (!(isMobileWidth())) {
      $(window).scroll(() => this.stickifyBookingBarHomepage());
    } else {
      this.disableStick();
    }
  }
  disableStick() {
    $_BOOKING_BAR_MAIN_.removeClass('stick sticky stuck');
    $(window).off('scroll', () => this.stickifyBookingBarHomepage());
  }
  populateStickyOpts() {
    this.scrollOpts = {};

    if (isDesktopWidth()) {
      let alertHeight = 0;

      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height();
      }
      let top = Math.abs($_BOOKING_BAR_MAIN_.outerHeight() - getHeaderHeight()) / 2;
      this.scrollOpts.start = $_BOOKING_BAR_MAIN_.offset().top + alertHeight;

      this.scrollOpts.onStick = () => {
        top = Math.abs($_BOOKING_BAR_MAIN_.outerHeight() - getHeaderHeight()) / 2;
        $_BOOKING_BAR_MAIN_.removeAttr('style').css({
          top: top
        });
        BBExpandCollapse.collapse();
      };

      this.scrollOpts.onUnstick = () => {
        BBExpandCollapse.collapse(() => {
          this.bookingBarSetTop(false);
        });
      };
    } else if (isTabletWidth()) {
      this.scrollOpts.start = $_BOOKING_BAR_MAIN_.offset().top;

      this.scrollOpts.onStick = () => {
        this.showMagnifyGlass(true);
        $_BOOKING_BAR_MAIN_.removeAttr('style');

        if (this.$mag.is('[aria-expanded=true]')) {
          this.$mag.click();
        }
      };

      this.scrollOpts.onUnstick = () => {
        this.showMagnifyGlass(false);

        if (this.$mag.is('[aria-expanded=true]')) {
          this.$mag.click();
        }

        this.bookingBarSetTop(false);
      };
    }
  }
}

let homeInst = null;

if ($_PAGE_.is('.homepage')) {
  homeInst = new Home();
}

export default homeInst;
