import {
  exists
} from './dom-utils.js';
import {
  getLocationDetails
} from './session-handler.js';
import {
  _isNotNull,
  replaceToken,
  getCity,
  getName,
  getUNAP,
  EventHandler
} from './utils.js';
import {
  _LOCALE_,
  overview_propertyName,
  property_city_name,
  property_country_code,
  property_state_code
} from './vars.js';

function handleTitleTag() {
  if (exists('title[data-override]')) {
    EventHandler.one(EventHandler.getLocation.success, () => {
      $('title[data-override]').each((i, el) => {
        var newTitle = $(el).attr('data-override'),
          locationDetails = getLocationDetails();

        if (_isNotNull(locationDetails.stateCode)) {
          newTitle = newTitle.replace('\$\{stateAbr\}', locationDetails.stateCode);
        } else if (_isNotNull(locationDetails.country)) {
          newTitle = newTitle.replace('\$\{stateAbr\}', locationDetails.country);
        }

        newTitle = newTitle.replace('\$\{city\}', locationDetails.city);

        if (_isNotNull(overview_propertyName)) {
          newTitle = newTitle.replace('\$\{hotelName\}', overview_propertyName);
        }

        $(el).html(newTitle);
      });
    }).one(EventHandler.getLocation.error, () => {
      console.log('Cannot get location for SEO Title Replace');
    });
  }

  EventHandler.one(EventHandler.UNAP.updated, () => {
    for (let i = 0; i < $('title').length; i++) {
      let newTitle = $('title')[i].textContent.replace('\$\{hotelName\}', getName());
      $('title')[i].textContent = newTitle;
    }
  });
}

function handleMetaTag() {
  if (exists('meta[name=description]')) {
    EventHandler.one(EventHandler.getLocation.success, () => {
      $('meta[name=description]').each((i, el) => {
        let newDesc = $(el).attr('data-override'),
          locationDetails = getLocationDetails();
        if (_isNotNull(newDesc) && _isNotNull(locationDetails)) {
          if (_isNotNull(locationDetails.state)) {
            newDesc = newDesc.replace('\$\{state\}', locationDetails.state);
          } else if (_isNotNull(locationDetails.country)) {
            newDesc = newDesc.replace('\$\{state\}', locationDetails.country);
          } else {
            newDesc = newDesc.replace('\$\{state\}', '');
          }
          if (locationDetails.city) {
            newDesc = newDesc.replace('\$\{city\}', locationDetails.city);
          } else {
            newDesc = newDesc.replace('\$\{city\}', '');
          }
          if (_isNotNull(overview_propertyName)) {
            newDesc = newDesc.replace('\$\{hotelName\}', overview_propertyName);
          } else {
            newDesc = newDesc.replace('\$\{hotelName\}', '');
          }
        }
        $(el).attr('content', newDesc);
      });
    }).one(EventHandler.getLocation.error, () => {
      console.log('Cannot get location for SEO Meta Description Replace');
    });
  }
}
//Handles Overview page meta description token replacement - different approach - takes OTA Bean details and UNAP data for other locales
//Assumption there would be only one meta description in overview page
function handleOverviewMetaTag() {
  if (exists('meta[name=description]')) {
    $('meta[name=description]').each((i, el) => {
      let newDesc = $(el).attr('data-override'),
        UNAPDesc = $(el).attr('data-override'),
        locationDetails = getLocationDetails();
      if (_isNotNull(newDesc)) {
        if (_isNotNull(property_state_code)) {
          newDesc = newDesc.replace('\$\{stateAbr\}', property_state_code);
        } else if (_isNotNull(window.propertyInfo.propertyState)) {
          newDesc = newDesc.replace('\$\{stateAbr\}', window.propertyInfo.propertyState);
        } else if (_isNotNull(locationDetails) && (_isNotNull(locationDetails.state))) {
          newDesc = newDesc.replace('\$\{stateAbr\}', locationDetails.state);
        } else if (_isNotNull(locationDetails) && (_isNotNull(locationDetails.country))) {
          newDesc = newDesc.replace('\$\{stateAbr\}', locationDetails.country);
        }

        if (newDesc.indexOf('stateAbr') > 0) {
          if (_isNotNull(property_country_code)) {
            newDesc = newDesc.replace('\$\{stateAbr\}', property_country_code);
          } else if (_isNotNull(window.propertyInfo.propertyCountry)) {
            newDesc = newDesc.replace('\$\{stateAbr\}', window.propertyInfo.propertyCountry);
          } else {
            newDesc = newDesc.replace('\$\{stateAbr\}', '');
          }
        }

        if (_isNotNull(property_city_name)) {
          newDesc = newDesc.replace('\$\{city\}', property_city_name);
        } else if (_isNotNull(window.propertyInfo.propertyCity)) {
          newDesc = newDesc.replace('\$\{city\}', window.propertyInfo.propertyCity);
        } else if (_isNotNull(locationDetails.city)) {
          newDesc = newDesc.replace('\$\{city\}', locationDetails.city);
        } else {
          newDesc = newDesc.replace('\$\{city\}', '');
        }

        if (_isNotNull(overview_propertyName)) {
          newDesc = newDesc.replace('\$\{hotelName\}', overview_propertyName);
        } else if (_isNotNull(window.propertyInfo.propertyName)) {
          newDesc = newDesc.replace('\$\{hotelName\}', window.propertyInfo.propertyName);
        } else {
          newDesc = newDesc.replace('\$\{hotelName\}', '');
        }
      }
      $(el).attr('content', newDesc);

      EventHandler.one(EventHandler.UNAP.updated, () => {
        if ((_LOCALE_ && _LOCALE_ !== 'en-us') && (_isNotNull(UNAPDesc))) {
          let stateAbr = getUNAP('stateAbr') ? getUNAP('stateAbr') : getUNAP('country');
          UNAPDesc = replaceToken(UNAPDesc, '${stateAbr}', stateAbr) || UNAPDesc;
          UNAPDesc = replaceToken(UNAPDesc, '${city}', getCity()) || UNAPDesc;
          UNAPDesc = replaceToken(UNAPDesc, '${hotelName}', getName()) || UNAPDesc;

          $(el).attr('content', UNAPDesc);
        }
      });
    });
  } else {
    console.log('Cannot get location information for overview page SEO Meta Description Replace');
  }
}

export {
  handleTitleTag,
  handleMetaTag,
  handleOverviewMetaTag
};
