import Modal from './modal.js';
import { getParameterByName } from '../../base/utils.js';
import { ResizeHandler, isMobileWidth } from '../../base/dom-utils.js';
import Analytics from '../../base/wyn-analytics-module.js';

class ExtendedHeroVideoSlide {
  constructor() {
    this.jwPlayers = {};
    this.onOpenVideoModal();
  }
  initVideos(videos) {
    if (videos) {
      if (!isMobileWidth()) {
        $.each(videos, (i, el) => {
          this.createVideos(i, el);
        });
        $(videos[0]).closest('.extended-hero-container')
          .addClass('videos-loaded');
      }
      // On Resize- check if videos have been loaded if not mobile
      ResizeHandler.addResizeEndFn(() => {
        if (!isMobileWidth() && !($('.extended-hero-container').hasClass('videos-loaded'))) {
          $.each(videos, (i, el) => {
            this.createVideos(i, el);
          });
        }
      });
    }
  }
  createVideos(i, el) {
    if ($(el).hasClass('slick-cloned')) {
      $(el).find('.background-video')
        .attr('id', 'clone' + i);
    }
    this.setupVideo(i, $(el).find('.background-video'), $(el));
  }
  setupVideo(i, el, container) {
    let id = $(el).attr('id');
    if (id) {
      this.jwPlayers[id] = jwplayer(id).setup({
        file: $(el).data('video-file'),
        autostart: $(el).data('video-autostart') || true,
        controls: $(el).data('video-controls') || false,
        mute: $(el).data('video-mute') || true,
        stretching: $(el).data('video-stretching') || 'fill',
        height: $(el).data('video-height') || '100%',
        width: $(el).data('video-width') || '100%',
        repeat: false
      })
        .on('ready', () => {
        // loop attr causes complete event don't fire
          $(this.jwPlayers[id].getContainer()).find('video')
            .removeAttr('loop');

          if ((jwplayer(id).getConfig().autostart) && ($(container).hasClass('slick-active'))) {
          // Direct call, autoplay isn't detected
            Analytics.satelliteTracker('jwplay');
          }
        })
        .on('complete', () => {
          let loop = $(this.jwPlayers[id].getContainer()).data('video-loop') || true;
          // Analytics flags
          setTimeout(() => {
            $(this.jwPlayers[id].getContainer()).find('video')
              .attr('data-is-complete', true);
          }, 1);

          // Manually loop
          if (typeof loop !== typeof undefined) {
            this.playVideo(id);
          }
        })
        .on('firstFrame', () => {
        // Analytics flag
          $(this.jwPlayers[id].getContainer()).find('video')
            .attr('data-is-started', true);
        })
        .on('play', () => {
          if (id.indexOf('clone') === -1) {
            this.analyticsMetaData(id);
          }
        });
    }

    // If slide isn't active and not videoModal
    if ((id !== 'videoModal') && (!$(container).hasClass('slick-active'))) {
      this.pauseVideo(id);
    }
  }
  playVideo(id) {
    this.analyticsMetaData(id);
    jwplayer(id).play();
  }
  pauseVideo(id) {
    jwplayer(id).pause();
  }
  onSlideChange() {
    let carousels = $('.extended-hero-carousel');
    $.each(carousels, (i, el) => {
      $(el).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        let $currentSlide = $(slick.$slides[currentSlide]),
          $currentVideo = $(slick.$slides[currentSlide]).find('.jwplayer'),
          $nextVideo = $(slick.$slides[nextSlide]).find('.jwplayer'),
          currentVideoId = $currentVideo.attr('id') || null,
          nextVideoId = $nextVideo.attr('id') || null;

        // Pause current video
        if (currentVideoId) {
          this.pauseVideo(currentVideoId);
        }

        // Play Next video
        if (nextVideoId) {
          // If current slide is the last slide
          if ($currentSlide.next().hasClass('slick-cloned')) {
            jwplayer($currentSlide.next().find('.jwplayer')
              .attr('id')).seek(jwplayer(nextVideoId).getPosition());
          }
          // Reverse navigation- If next slide is the last slide
          if ($nextVideo.parents('.hero-slide').next()
            .hasClass('slick-cloned')) {
            jwplayer($nextVideo.parents('.slick-carousel').find('.hero-slide')
              .eq(0)
              .find('.jwplayer')
              .attr('id')).seek(jwplayer(nextVideoId).getPosition());
          }

          this.playVideo(nextVideoId);
        }
      });
    });
  }
  analyticsMetaData(id) {
    const playlistItem = jwplayer(id).getPlaylistItem().sources[0];
    let name = playlistItem.file,
      type = playlistItem.type;
    name = name.split('\\').pop()
      .split('/')
      .pop()
      .split('-')
      .shift();

    // Analytics data
    window.digitalData.rewards = {
      activity: {
        videoName: name,
        videoType: type
      }
    };
  }
  onOpenVideoModal() {
    $(document).on('click', 'a[href*="#openVideoModal"]', (e) => {
      e.preventDefault();
      let url = getParameterByName('url', $(e.currentTarget).attr('href'));
      if (url) {
        let $video = `<div id="videoModal" data-video-file="${url}"></div>`;
        Modal.populateModalContent($video);
        Modal.show();
        this.setupVideo(1, $('#videoModal'));
      }
    });
  }
}

export default new ExtendedHeroVideoSlide();
