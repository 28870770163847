import { exists } from '../base/dom-utils.js';
import { $_PAGE_ } from '../base/vars.js';

class PropertyIntroCopy {
  constructor() {
    if(exists('.property-intro-copy')) {
      this.bindShowHideLinks();

      this.handleUUPropResize();
    }
  }

  bindShowHideLinks() {
    $('.uu-show').click((e) => {
      let $content = $('.show-options').siblings('div.text-content');

      $content.toggleClass('short-text full-text');

      $(e.currentTarget).addClass('hide-link');
      $(e.currentTarget).siblings()
        .removeClass('hide-link');
    });
  }

  handleUUPropResize() {
    if($_PAGE_.is('.uu-property')) {
      $('.uu-property .property-intro-copy').parent()
        .removeClass('col-sm-14')
        .addClass('col-sm-11');
    }
  }
}

export default new PropertyIntroCopy();
