import { _LOCALE_ } from '../base/vars.js';
import { getServiceUrl, getSortTabPayload, getSearchParameters } from '../base/utils.js';

// const host = 'http://localhost:3003';
const serviceUrl = getServiceUrl('mySearchFavourite');
const hotelCollectionAPI = serviceUrl;

const queries = {
  listProperties: `
    query sample($searchParameters: SearchParameters) {
      version
      searchProperties(searchParameters: $searchParameters) {
        totalCount
        properties {
          id
          propertyId
          address {
            address1
            address2
            country
            city
            countryCode
            postalCode
            state
            stateCode
          }
          brand
          brandTier
          sabreId
        }
        totalCount
      }
    }
  `,
  distinctamenitiesandbrands: `
  query sample($searchParameters: SearchParameters) {
    searchProperties(searchParameters: $searchParameters) {
      distinctCollection{
        name
        values
      }
      totalCount
      properties {
       address {
          state
          stateCode
        }
      }
    }
  }`,
  getstates: `
  query sample($searchParameters: SearchParameters) {
    searchProperties(searchParameters: $searchParameters) {
      distinctCollection{
        name
        values
      }
      properties {
        address {
          state
          stateCode
        }
      }
    }
  }
  `,
  fetchallproperties: `
      query sample($searchParameters: SearchParameters) {
        version
        searchProperties(searchParameters: $searchParameters) {
          totalCount
          properties {
            id
            propertyId
            name
            headline
            address {
              address1
              address2
              country
              city
              countryCode
              postalCode
              state
              stateCode
            }
            brand
            brandTier
            sabreId
          }
          totalCount
        }
      }
    `,
};
function getStates(countryCodes, stateCodes) {
  const variables = {
    searchParameters: {
      countryCodes,
      distinctBy: ['hotel_full_view.state_code'],
      offset: 0,
      limit: 1000,
      language: _LOCALE_,
    },
  };
  const query = queries.getstates;
  if (stateCodes && Array.isArray(stateCodes) && stateCodes.length) {
    variables.searchParameters.stateCodes = stateCodes;
  }
  return fetchGraphQLData({ url: hotelCollectionAPI, query, variables });
}
function getCities(countryCodes, stateCodes, cityFilters) {
  const variables = {
    searchParameters: {
      countryCodes,
      stateCodes,
      distinctBy: ['hotel_full_view.city'],
      offset: 0,
      limit: 1,
      language: _LOCALE_,
    },
  };
  const query = queries.distinctamenitiesandbrands;
  if (cityFilters && Array.isArray(cityFilters) && cityFilters.length) {
    variables.searchParameters.filters = cityFilters;
  }
  return fetchGraphQLData({ url: hotelCollectionAPI, query, variables });
}

function getDistinctAmenitiesAndBrands(baselineParams, limit = 1) {
  const variables = {
    searchParameters: {
      limit,
      offset: 0,
      language: _LOCALE_,
      distinctBy: [
        'hotel.brand_id',
        'hotel.country_code',
        'hotel_full_view.city',
        'hotel_full_view.state_code',
        'hotel.searchable_amenities',
        'hotel_full_view.brand_tier_code',
      ],
    },
  };
  const query = queries.distinctamenitiesandbrands;
  if (baselineParams) {
    variables.searchParameters = { ...variables.searchParameters, ...baselineParams };
  } else {
    variables.searchParameters = getSearchParameters(variables.searchParameters);
  }
  return fetchGraphQLData({ url: hotelCollectionAPI, query, variables });
}

function getFilteredProperties(selectedFilters) {
  const variables = {
    searchParameters: {
      language: _LOCALE_,
      offset: 0,
      limit: 1000,
      ...selectedFilters,
      sortBy: getSortTabPayload(),
    },
  };
  const query = queries.fetchallproperties;
  return fetchGraphQLData({ url: hotelCollectionAPI, query, variables });
}

function fetchGraphQLData({ url, query, variables }) {
  return fetchWrapper({ url, query, variables });
}
async function fetchWrapper({ url, query, variables }) {
  try {
    const payload = {};
    if (query) {
      payload.query = query;
    }
    if (variables) {
      payload.variables = variables;
    }
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    return {
      error: error.message || 'API Failed',
    };
  }
}
function getDistinctCollectionsObject(response) {
  let result = null;
  if (
    response &&
    response.data &&
    response.data.searchProperties &&
    response.data.searchProperties.distinctCollection
  ) {
    result = response.data.searchProperties.distinctCollection;
  }
  return result;
}
function getItemFromDistinctCollections(distinctCollection, itemKey) {
  const result = distinctCollection.find((dc) => dc.name === itemKey);
  if (result) {
    return result.values;
  }
  return [];
}
function getStatesMapping(response) {
  if (response && response.data && response.data.searchProperties && response.data.searchProperties.properties) {
    const mappedStates = [];
    const matchedStates = [];
    const properties = response.data.searchProperties.properties;
    const states = getItemFromDistinctCollections(getDistinctCollectionsObject(response), 'hotel_full_view.state_code');
    for (let property of properties) {
      if (states.length === matchedStates.length) {
        break;
      }
      const {
        address: { stateCode, state },
      } = property;
      if (states.includes(stateCode) && !matchedStates.includes(stateCode)) {
        matchedStates.push(stateCode);
        mappedStates.push({ key: stateCode, value: state });
      }
    }
    return mappedStates;
  }
  return [];
}

export {
  getDistinctAmenitiesAndBrands,
  getStates,
  getDistinctCollectionsObject,
  getItemFromDistinctCollections,
  getCities,
  getStatesMapping,
  getFilteredProperties,
};
