import {getBrand, getBrandTier, getCriteria, getLocationDetails} from "../../base/session-handler";
import {
    _isNotNull,
    CookieHandler, EventHandler, formatDateForBWSAvailability, formatNumber, getCurrencyMapping,
    getDefaultSearchRadius, getQueryString, getServiceUrl,
    replaceToken,
    uppercaseFirstLetterEachWord,
    convertToCurrency
} from "../../base/utils";
import GoogleAPIHandler from "../../base/google-api-handler";
import Analytics from "../../base/wyn-analytics-module";
import {_LANGUAGE_, _LOCALE_} from "../../base/vars";
import {isMobileWidth} from "../../base/dom-utils";
import MapMarker from "./MapMarker";
import SearchUtils from "./SearchUtils";


export default class SearchMap {
    constructor(searchResults) {
        this.SearchResults = searchResults;
        this._map = null;
        this._params = {};
        this._markers = [];
        this._showBrandOnly = null;
        this._resetMapZoomCenter = null;
        this.searchUtilsInst = new SearchUtils();
    }
    showHotelDetails(data, latlng) {
        if (this.SearchResults && this.SearchResults._propertyRateMap && this.SearchResults._propertyRateMap[data.hotelId]) {
            //check if hotelId is in rate map
            //hotelId
            let hotelObj = this.SearchResults._propertyRateMap[data.hotelId];
            this.findMapHotelData(data.hotelId + '-map', hotelObj);
        } else {
            //if it's not, go out and fetch the rate
            this.fetchPropAvailability(data.hotelId, data.brand);
        }

        // if mobile, center the map
        if (latlng && this._map && $('#mobileToggleView').is(':visible')) {
            this._resetMapZoomCenter = {
                zoom: this._map.getZoom(),
                center: this._map.getCenter()
            };
            $('body').addClass('takeover');
            this.centerMapToLocation(this._map, latlng, 170, this._map.getZoom());
        }
    }
    loadMapViewComponent(mapEle, propList, showPoints) {
        let locationDetails = getLocationDetails(),
            initialLat = 37.7915162, // fallback values in case it is not possible to retrieve them
            initialLong = -95.8311325,
            initialZoom = 4,
            useIcons = false,
            isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
        if(!isProxy) {
            this.hideRateView();
            if (locationDetails && locationDetails.latitude && locationDetails.longitude) {
                initialLat = parseFloat(locationDetails.latitude);
                initialLong = parseFloat(locationDetails.longitude);
                initialZoom = 10;
            }

            $(mapEle).data('rendered', true);
            $(mapEle).on('keydown', (e) => this.keyHandler(e));

            if (!this._showBrandOnly) {
                this._showBrandOnly = $('#showBrandOnly');
                this._showBrandOnly.on('change', () => this.onShowBrandOnly());
            }

            GoogleAPIHandler.loadedPromise.then(() => {
                let map = new window.google.maps.Map(mapEle, {
                    zoom: initialZoom,
                    center: {
                        lat: initialLat,
                        lng: initialLong
                    },
                    styles: GoogleAPIHandler.mapStyle,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    clickableIcons: false
                });
                GoogleAPIHandler.buildNearMeControl(map, true);
                propList = this.preProcessProperties(propList);
                this.useIcons = showPoints || this.shouldUseIcons(propList);
                this.sortProperties(propList, useIcons);
                let markers = this.buildMapMarkers(propList, this.useIcons);
                $.each(markers, (index, instance) => {
                    instance.marker.setMap(map);
                });
                this._markers = markers;
                this._map = map;
                this.centerMap(map, markers);
                $('#searchNumPropReadOnly').data('total', propList.length);
                this.onShowBrandOnly();
            });
        }
    }
    keyHandler(evt) {
        if (evt.keyCode === $.ui.keyCode.ESCAPE) {
            this.hideRateView();
        }
    }
    getDecimalsPrice(price) {
        let decimals = '00',
            pos = price.indexOf('.');

        if (pos > 0) {
            decimals = price.substring(pos + 1);
        }

        return decimals;
    }
    hideRateView() {
        if (_isNotNull(this._resetMapZoomCenter)) {
            this._map.setZoom(this._resetMapZoomCenter.zoom);
            this._map.setCenter(this._resetMapZoomCenter.center);
            this._resetMapZoomCenter = null;
        }
        $('body').removeClass('takeover');
        $('.map-rate-wrapper').hide();
        $('.map-price-pin.selected').removeClass('selected');
    }
    preProcessProperties(propList) {
        // start by adding a property to keep the original order within categories
        $.each(propList, (index, prop) => {
            prop.searchIndex = index;
            prop.notAvailable = _.contains(window.NOT_AVAILABLE_BRANDS, prop.brand.toLowerCase()) || !prop.rate || (prop.fnsAvailable == false || typeof prop.rate.displayRate != 'number') || (prop.fnsAvailable == false || prop.rate.displayRate <= 0);
        });

        return propList;
    }
    sortProperties(propList, useIcons) {
        if (useIcons) {
            // brand properties must be displayed first
            // then give priority to brands with availability
            propList.sort((a, b) => {
                let deltaA = (!a.notAvailable ? -5000 : 0) +
                        (a.isCurrentBrand ? -10000 : 0),
                    deltaB = (!b.notAvailable ? -5000 : 0) +
                        (b.isCurrentBrand ? -10000 : 0);
                return (a.searchIndex + deltaA) - (b.searchIndex + deltaB);
            });
        } else {
            // properties with a rate must be displayed first
            // properties from the current brand have priority
            propList.sort((a, b) => {
                let deltaA = (!a.notAvailable ? -10000 : 0) +
                        (a.isCurrentBrand ? -5000 : 0),
                    deltaB = (!b.notAvailable ? -10000 : 0) +
                        (b.isCurrentBrand ? -5000 : 0);
                return (a.searchIndex + deltaA) - (b.searchIndex + deltaB);
            });
        }

        return propList.reverse();
    }
    shouldUseIcons(propList) {
        let useIcons = false;
        $.each(propList, (index, prop) => {
            if (!prop.notAvailable) {
                let text = (prop.rate.currencyCode === 'USD' ? '$' : '') + Math.floor(prop.rate.displayRate);
                if (text.length > 5) {
                    useIcons = true;
                }
            }
        });
        return useIcons;
    }
    buildMapMarkers(propList, useIcons) {
        let markers = [],
            notAvailable = $('#map-view').data('not-available');
        let emeaRegion = this.SearchResults._emeaRegion;

        $.each(propList, (index, prop) => {
            let data = {
                    hotelId: prop.hotelId,
                    brand: prop.brand.toUpperCase(),
                    brandTier: prop.brandTier ? prop.brandTier.toUpperCase() : '',
                    lat: parseFloat(prop.latitude),
                    lng: parseFloat(prop.longitude),
                    highlight: !!prop.isCurrentBrand,
                    useIcon: useIcons,
                    notAvailable: prop.notAvailable
                },
                rate;

            if (!prop.notAvailable) {
                if (emeaRegion && ($('#map-view').data('emea') && $('#map-view').data('emea').trim().length > 0) && (prop.rate.totalAfterTax > 0)) {
                    rate = parseFloat(convertToCurrency(prop.rate.totalAfterTax,prop.rate.currencyCode));
                } else {
                    rate = convertToCurrency(prop.rate.displayRate,prop.rate.currencyCode);
                }
                data.textPin = getCurrencyMapping(prop.rate.currencyCode) + Math.floor(rate);
                data.decimals = this.getDecimalsPrice(rate.toFixed(2));
            } else {
                data.textPin = notAvailable;
                data.decimals = '';
            }
            data.marker = new MapMarker(data, this);
            markers.push(data);
        });

        return markers;
    }
    centerMap(map, markers) {
        let maxLat = -90,
            minLat = 90,
            maxLon = -180,
            minLon = 180,
            zoom1 = 0,
            zoom2 = 0;

        if (markers.length === 1) {
            map.setZoom(12);
            map.setCenter({
                lat: markers[0].lat,
                lng: markers[0].lng
            });
        } else if (markers.length > 0) {
            $.each(markers, (index, marker) => {
                maxLat = Math.max(marker.lat, maxLat);
                minLat = Math.min(marker.lat, minLat);
                maxLon = Math.max(marker.lng, maxLon);
                minLon = Math.min(marker.lng, minLon);
            });

            if ((maxLon != minLon) && (maxLat != minLat)) {
                zoom1 = Math.log(Math.abs(360 / 128 * 692 / Math.cos(maxLon - minLon))) / Math.log(2);
                zoom2 = Math.log(Math.abs(180 / 128 * 692 / Math.cos(maxLat - minLat))) / Math.log(2);
            } else {
                zoom1 = 10;
                zoom2 = 10;
            }

            let maxAverageZoom = 10,
                averageZoom = Math.round(zoom1, zoom2),
                newZoom = Math.min(averageZoom, maxAverageZoom),
                maxRadius = getDefaultSearchRadius();

            if($('#distanceMin').length) {
                if(maxRadius === parseInt($('#distanceMin').val())) {
                    newZoom = newZoom - 3;
                }
            } else {
                if(maxRadius === parseInt($('#kmDistanceMin').val())) {
                    newZoom = newZoom - 3;
                }
            }
            map.setZoom(newZoom);
            map.setCenter({
                lat: (maxLat + minLat) / 2,
                lng: (maxLon + minLon) / 2
            });
        }
    }
    centerMapToLocation(map, latlng, offsetY, currentZoom) {
        map.setZoom(currentZoom > 16 ? currentZoom : 16);

        let point1 = map.getProjection().fromLatLngToPoint(latlng),
            point2 = new window.google.maps.Point(0, offsetY / Math.pow(2, map.getZoom()));

        map.setCenter(map.getProjection().fromPointToLatLng(new window.google.maps.Point(
            point1.x - point2.x,
            point1.y + point2.y
        )));
    }
    switchBrandTitle(checked) {
        let text = $('#searchCriteriaSummary h1').data('text'),
            currentBrandCode = (_isNotNull(getBrand(true))) ? getBrand(true).toLowerCase() : null,
            currentBrandName = '',
            brandTier = getBrandTier();

        if (checked && $('#searchCriteriaSummary h1').data('brandtext') && $('#searchCriteriaSummary h1').data('brandtext').length > 0) {
            text = $('#searchCriteriaSummary h1').data('brandtext');
        }

        if (brandTier) {
            currentBrandName = brandTier.toUpperCase();
        }
        if (this.SearchResults._brandNameMapping && this.SearchResults._brandNameMapping[currentBrandCode]) {
            currentBrandName = this.SearchResults._brandNameMapping[currentBrandCode];
        }
        currentBrandName = currentBrandName.replace(/-/g, ' ');
        currentBrandName = uppercaseFirstLetterEachWord(currentBrandName);

        text = replaceToken(text, '${CITY}', getLocationDetails('city')) || text;
        text = replaceToken(text, '${LOCATION}', getCriteria().searched || getLocationDetails('locations') || getLocationDetails('city')) || text;
        text = replaceToken(text, '${BRAND}', currentBrandName) || text;

        $('#searchCriteriaSummary h1').text(text);
    }
    onShowBrandOnly() {
        let brand = null,
            count = 0;

        if (this._showBrandOnly.is(':checked')) {
            brand = this._showBrandOnly.val().toUpperCase();
        }

        $.each(this._markers, (index, marker) => {
            let data = marker.marker.data;
            data.hidden = !(!brand || brand == marker.brand || brand == marker.brandTier || marker.highlight);

            if (!data.hidden) {
                count++;
            }

            if (marker.marker.div) {
                marker.marker.div.style.display = data.hidden ? 'none' : 'block';
            }
        });

        $('#searchNumPropReadOnly').text(count);
        if (this._showBrandOnly.is(':checked')) {
            this.switchBrandTitle(true);
        } else {
            this.switchBrandTitle(false);
        }
    }
    getSearchCriteria(propId) {
        let searchCriteria = getCriteria();

        if (searchCriteria) {
            this._params.brand_id = searchCriteria.brandId;
            this._params.checkin_date = formatDateForBWSAvailability(searchCriteria.checkInDate);
            this._params.checkout_date = formatDateForBWSAvailability(searchCriteria.checkOutDate);
            this._params.useWRPoints = searchCriteria.useWRPoints;
            this._params.rateCode = searchCriteria.rateCode;
            this._params.corporate_id = searchCriteria.corpCode;
            this._params.ratePlan = searchCriteria.ratePlan;
            this._params.group_code = searchCriteria.groupCode;
            this._params.childAge = searchCriteria.childAge;
            this._params.children = searchCriteria.children;
            this._params.adults = searchCriteria.adults;
            this._params.rooms = searchCriteria.rooms;
            this._params.iata = searchCriteria.iata;
            this._params.language = searchCriteria.language;
        }

        if (this._params.rateCode) {
            this._params.rateCode = this._params.rateCode.toUpperCase();
        }
        if (this._params.ratePlan) {
            this._params.ratePlan = this._params.ratePlan.toUpperCase();
        }

        if ($('#redeemWRPoint').is(':checked')) {
            this._params.rateCode = 'SRB';
        }

        this._params.properties = propId;

        return this._params;
    }
    fetchPropAvailability(propId, brandCode) {
        //Call property-availability or rate service passing propIds & other criteria
        let fullPropId = brandCode + propId;

        $.ajax({
            url: getServiceUrl('rates'),
            type: 'GET',
            data: this.getSearchCriteria(fullPropId),
            dataType: 'json',
            success: (result, status, xhr) => {
                //if data is retrieved, update property map and render container
                //update property map
                if (result.availability && result.availability.altSell.availability[0]) {
                    this.SearchResults._propertyRateMap[propId] = result.availability.altSell.availability[0];
                    this.findMapHotelData(propId + '-map', result.availability.altSell.availability[0]);
                }
            },
            error: (result, status, error) => {
                EventHandler.triggerEvent('brands-error', result);
            }
        });
    }
    /**
     * This method is used to enable 'Map View' tab to load the map module.
     */
    enableMapViewList() {
        // Map control
        $('.nav-tabs [aria-controls="map"]').off('click');
        $('.nav-tabs [aria-controls="map"]').click((e) => {
            $('.nav-tabs [aria-controls="list"]').attr('tabindex', '-1')
                .attr('aria-selected', 'false');
            $('.nav-tabs [aria-controls="map"]').attr('tabindex', '0')
                .attr('aria-selected', 'true');
            let mapEle = $('#map-view');
            $('#list-view').hide()
                .removeClass('active');
            $('#map-view').show()
                .addClass('active');
            $('.show-brand-only').show();
            $('#map-view').css('min-height', 500 + 'px');
            if (!$('#map-view').data('rendered')) {
                mapEle.empty();
                this.loadMapViewComponent(mapEle.get(0), this.preparePropListForMap(), $('#rewardspricefilter').length > 0 && $('#wyndham-rewards-filter').is(':checked'));
            } else {
                this.onShowBrandOnly();
            }
            Analytics.updateAnalyticsSearchToggle('Map');
            Analytics.satelliteTracker('search_results_view');
        });
        $('.nav-tabs [aria-controls="list"]').click((e) => {
            $('.nav-tabs [aria-controls="map"]').attr('tabindex', '-1')
                .attr('aria-selected', 'false');
            $('.nav-tabs [aria-controls="list"]').attr('tabindex', '0')
                .attr('aria-selected', 'true');
            $('#map-view').hide()
                .removeClass('active');
            $('.map-rate-wrapper').hide();
            $('.show-brand-only').hide();
            $('#list-view').show()
                .addClass('active');
            $('#map-view').css('min-height', 0 + 'px');
            if ($('#searchNumPropReadOnly').data('total')) {
                $('#searchNumPropReadOnly').text($('#searchNumPropReadOnly').data('total'));
            }
            Analytics.updateAnalyticsSearchToggle('List');
            Analytics.satelliteTracker('search_results_view');
        });

        //Add keyboard function that pressing arrow left, arrow right, arrow up, or arrow down from the tabs toggle the tabs.
        $('.list-map-tab-wrapper .nav-tabs li a[role=\'tab\']').keydown((e) => {
            if ((e.which == 39) || (e.which == 37) || (e.which == 38) || (e.which == 40)) {
                let controller = $(e.currentTarget).attr('aria-controls');
                if (controller == 'list') {
                    // switch to map view
                    $('.nav-tabs [aria-controls="map"]').trigger('click');
                    $('.nav-tabs [aria-controls="map"]').focus();
                } else {
                    // switch to list view
                    $('.nav-tabs [aria-controls="list"]').trigger('click');
                    $('.nav-tabs [aria-controls="list"]').focus();
                }
            }
        });
    }
    /**
     * This method is used to prepare the property list for map display.
     */
    includePriceOnMapList(item, arrayPrice) {
        for (let i = 0; i < arrayPrice.length; i++) {
            if (arrayPrice[i].hotelId === item.hotelId && typeof arrayPrice[i].rate !== 'undefined') {
                if (this.SearchResults.SearchFilter._currentFilter.wrPoints) {
                    item.fnsAvailable = arrayPrice[i].fnsAvailable;
                    item.fnsPoints = arrayPrice[i].fnsPoints;
                    item.totalFnsPoints = arrayPrice[i].totalFnsPoints;
                    if (item.hasOwnProperty('rate')) {
                        item.rate.pacRate = arrayPrice[i].rate.pacRate;
                    } else {
                        item.rate = arrayPrice[i].rate;
                    }
                } else if (this.SearchResults.SearchFilter._currentFilter.price) {
                    if (item.hasOwnProperty('rate')) {
                        item.rate.alternate = arrayPrice[i].rate.alternate;
                        item.rate.cugRate = arrayPrice[i].rate.cugRate;
                        item.rate.currencyCode = arrayPrice[i].rate.currencyCode;
                        item.rate.displayRate = arrayPrice[i].rate.displayRate;
                        item.rate.errorCode = arrayPrice[i].rate.errorCode;
                        item.rate.message = arrayPrice[i].rate.message;
                        item.rate.ratePlanId = arrayPrice[i].rate.ratePlanId;
                        item.rate.strikethroughRate = arrayPrice[i].rate.strikethroughRate;
                        item.rate.strikethroughRatePlanId = arrayPrice[i].rate.strikethroughRatePlanId;
                        item.rate.totalAfterTax = arrayPrice[i].rate.totalAfterTax;
                        if (!(item.rate.hasOwnProperty('pacRate'))) {
                            item.rate.pacRate = arrayPrice[i].rate.pacRate;
                        }
                    } else {
                        item.rate = arrayPrice[i].rate;
                    }
                } else {
                    if (arrayPrice[i].rate.displayRate > 0) {
                        item.rate = arrayPrice[i].rate;
                    }
                }
                break;
            }
        }
    }
    preparePropListForMap() {
        let propertyListForMap = [],
            propJSONResp = this.SearchResults._displaySearchResults,
            brand, index, hotelObj, propActual;

        if (propJSONResp && propJSONResp.status == 'OK') {
            brand = propJSONResp.availability.hostBrand;
            propActual = this.SearchResults._actualSearchResults.availability.hostBrand.availability;
            for (index = 0; index < brand.availabilityCount; index++) {
                hotelObj = brand.availability[index];
                hotelObj.isCurrentBrand = true;
                this.includePriceOnMapList(hotelObj, propActual);
                propertyListForMap.push(hotelObj);

            }
            brand = propJSONResp.availability.altSell;
            propActual = this.SearchResults._actualSearchResults.availability.altSell.availability;
            for (index = 0; index < brand.availabilityCount; index++) {
                hotelObj = brand.availability[index];
                hotelObj.isCurrentBrand = false;
                this.includePriceOnMapList(hotelObj, propActual);
                propertyListForMap.push(hotelObj);
            }
        }
        return propertyListForMap;
    }
    getMapHotelDetails(selectedHotel, propId, tempHotObj) {
        let newRequestURL = window.location.protocol + '//' + window.location.host + '/bin/propertyDataList.json',
            hotelRequestData = {
                locale: _LOCALE_,
                hotels: []
            },
            hotelObject = {
                brandId: selectedHotel[0].brand,
                hotelId: selectedHotel[0].hotelId,
                location: selectedHotel[0].state
            };
        hotelRequestData.hotels.push(hotelObject);
        $.ajax({
            url: newRequestURL,
            type: 'POST',
            data: JSON.stringify(hotelRequestData),
            dataType: 'json',
            contentType: 'application/json',
            success: (result, status, xhr) => {
                if (result && result.error === false) {
                    let items = result.hotels[0],
                        hotelObj = selectedHotel[0],
                        brandCode,
                        propertyId;
                    //have to pull hotel ID from initial request
                    brandCode = hotelObj.brand.toUpperCase();
                    propertyId = hotelObj.brand + hotelObj.hotelId;
                    items.propertyId = propertyId;
                    if (this.SearchResults._propertyRateMap[hotelObj.hotelId] && this.SearchResults._propertyRateMap[hotelObj.hotelId].hotelName) {
                        items.name = this.SearchResults._propertyRateMap[hotelObj.hotelId].hotelName;
                    } else if (this.SearchResults._globalPropertyMap[hotelObj.hotelId] && this.SearchResults._globalPropertyMap[hotelObj.hotelId].hotelName) {
                        items.name = this.SearchResults._globalPropertyMap[hotelObj.hotelId].hotelName;
                    }
                    items.brand = hotelObj.brand;
                    items.logoUrl = '/content/dam/assets/clientlibs/' + this.SearchResults._brandNameMapping[brandCode] + '/graphics/' + brandCode + '_logo_BMP.png';
                    this.SearchResults._renderedProperties[propertyId] = items;
                    this.populateMapRateDivSection(items, propId, tempHotObj);
                    return items;
                }
            },
            error: (result, status, error) => {
                EventHandler.triggerEvent('brands-error', result);
            }
        });
    }
    findMapHotelData(propId, hotObj) {
        let fullMapId = hotObj.brand + hotObj.hotelId;
        if (this.SearchResults._renderedProperties[fullMapId]) {
            let hotelDetails = this.SearchResults._renderedProperties[fullMapId];
            this.populateMapRateDivSection(hotelDetails, propId, hotObj);
        } else {
            //go get the hotel details
            let selectedHotel = _.where(this.SearchResults._propertyListForMap, {
                'hotelId': hotObj.hotelId
            });
            this.getMapHotelDetails(selectedHotel, propId, hotObj);
        }
    }
    populateMapRateDivSection(hotelDetails, propId, hotObj) {
        let template = _.template($('#mapRateTemplate').html()),
            items = {},
            compiledTemplate,
            dollarPercentDataObj = $('#dollar-percent-data'),
            overRideText = dollarPercentDataObj.data('off-authoring'),
            dollarRatePlan = dollarPercentDataObj.data('dollar-rates');
        let displayRate;
        //combining data from rate and hotel responses for template
        items.hotelId = propId;
        items.name = hotelDetails.name;
        items.image = {};
        items.image.mobile = hotelDetails.image.mobile;
        items.image.tablet = hotelDetails.image.tablet;
        items.image.altText = hotelDetails.image.altText;
        items.image.desktop = hotelDetails.image.desktop;
        items.image.mobile = hotelDetails.image.mobile;
        items.overviewPath = hotelDetails.overviewPath;
        items.isCrossSell = hotelDetails.isCrossSell;
        // Translated property names sourced from property-availability. Fall back to getMultiPropertySearch if property
        // name is not translated. Final fallback to AEM data source in case services don't return names.
        if (this.SearchResults._propertyRateMap[hotObj.hotelId] && this.SearchResults._propertyRateMap[hotObj.hotelId].hotelName) {
            items.name = this.SearchResults._propertyRateMap[hotObj.hotelId].hotelName;
        } else if (this.SearchResults._globalPropertyMap[hotObj.hotelId] && this.SearchResults._globalPropertyMap[hotObj.hotelId].hotelName) {
            items.name = this.SearchResults._globalPropertyMap[hotObj.hotelId].hotelName;
        }
        // UNAP - property address and phone should come from services.
        let mpsProp = this.SearchResults._globalPropertyMap[hotObj.hotelId];
        items.address1 = mpsProp.address1;
        items.address2 = mpsProp.address2;
        if(mpsProp.address2) {
            items.fullAddress = mpsProp.address1 + ' ' + mpsProp.address2;
        } else {
            items.fullAddress = mpsProp.address1;
        }
        items.city = mpsProp.city;
        items.state = ((mpsProp.country && mpsProp.country.match(/^(us|ca)$/i)) || (mpsProp.countryCode && mpsProp.countryCode.match(/^(us|ca)$/i))) ? mpsProp.state : mpsProp.country;
        items.country = mpsProp.country;
        items.postalCode = mpsProp.postalCode;
        if (_LANGUAGE_ !== 'en') {
            if(mpsProp.countryCode && (mpsProp.countryCode.match(/^(cn)$/i) || mpsProp.countryCode.match(/^(tw)$/i) || mpsProp.countryCode.match(/^(hk)$/i) || mpsProp.countryCode.match(/^(mo)$/i))) {
                if(mpsProp.phone1.indexOf('+') !== 0) {
                    items.phone = '+' + mpsProp.phone1;
                } else {
                    items.phone = mpsProp.phone1;
                }
            } else {
                items.phone = mpsProp.phone1;
            }
        } else {
            items.phone = hotelDetails.phone;
        }
        // End UNAP

        items.headline = hotelDetails.headline;
        items.brand = hotObj.brand;
        items.unavailableAuthoredText = _.contains(window.NOT_AVAILABLE_BRANDS, items.brand.toLowerCase());
        items.fnsPoints = formatNumber(hotObj.fnsPoints);

        if (items.brand === 'LQ' && this.searchUtilsInst.isRedirectRequiredForLQ()) {
            items.overviewPath = this.searchUtilsInst.getLQURIString(items.overviewPath);
        } else if (!items.overviewPath.match(/\?/)) {
            items.overviewPath = items.overviewPath + '?' + getQueryString();
        }

        if ($('#rewardspricefilter').length > 0 && $('#wyndham-rewards-filter').is(':checked')) {
            items.showRewards = true;
        } else {
            items.showRewards = false;
        }
        compiledTemplate = template({
            items: items
        });
        $('#mapRateView').html(DOMPurify.sanitize(compiledTemplate));
        if (!$('.map-rate-wrapper').is(':visible')) {
            $('.map-rate-wrapper').show(400);
        }
        $('#mapRateView .close').on('click', (e) => {
            e.preventDefault();
            this.hideRateView();
        });
        if ($('#mapRateView').is(':visible')) {
            $('.fitler-button').click(() => {
                //return false;
                $('.refine-result-wrapper.mobile-view-menu-toggle').hide();
            });
        }
        if (propId && $('#' + propId + '-loading').length > 0) {
            if (hotObj) {
                let hotelRateObj = hotObj.rate;
                if (this.SearchResults._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotelRateObj.totalAfterTax > 0)) {
                    displayRate = parseFloat(hotelRateObj.totalAfterTax);
                } else {
                    displayRate = parseFloat(hotelRateObj.displayRate);
                }
                if (hotelRateObj && displayRate >= 0) {
                    let currencySymbol = getCurrencyMapping(hotelRateObj.currencyCode); // === "USD")? "$":"";
                    if (parseFloat(hotelRateObj.strikethroughRate) >= 0 && parseFloat(hotelRateObj.strikethroughRate) > parseFloat(displayRate)) {
                        let unroundedPercent = (1 - (parseFloat(displayRate) / parseFloat(hotelRateObj.strikethroughRate))) * 100,
                            hotelRatePlanId = hotelRateObj.ratePlanId,
                            dollarSign,
                            percentageOff,
                            isDollar = _isNotNull(dollarRatePlan) && hotelRatePlanId.match(new RegExp(dollarRatePlan, 'i'));

                        if (isDollar) {
                            dollarSign = getCurrencyMapping(hotelRateObj.currencyCode);
                            percentageOff = hotelRateObj.strikethroughRate - displayRate;
                            if (overRideText !== undefined && overRideText !== ' ') {
                                percentageOff = dollarSign + percentageOff.toFixed(2) + ' ' + overRideText;
                            } else {
                                percentageOff = dollarSign + percentageOff.toFixed(2);
                            }
                        } else {
                            // Handle for floating point errors; round down in all cases except if the number should be a round integer.
                            percentageOff = (unroundedPercent % 1 > 0.999) ? Math.round(unroundedPercent) : Math.floor(unroundedPercent);
                        }

                        $('.hotel-rate .' + propId + '-percentOff').text(percentageOff);
                        if ($('.' + propId + '-rooms-and-rates-button-link').length > 0) {
                            $('.' + propId + '-rooms-and-rates-button-link').click((e) => {
                              Analytics.updateSearchAlertAnalytics('Yes', 'Price', (isDollar ? percentageOff + ' off' : percentageOff + '% off'));
                              Analytics.satelliteTracker('search_alert_type');
                            });
                        }
                        $('.hotel-rate .' + propId + '-strikedPrice').text(currencySymbol + parseFloat(hotelRateObj.strikethroughRate).toFixed(2));
                    } else {
                        if ($('.' + propId + '-rooms-and-rates-button-link').length > 0) {
                            $('.' + propId + '-rooms-and-rates-button-link').click(() => {
                              Analytics.updateSearchAlertAnalytics('No', '', '');
                              Analytics.satelliteTracker('search_alert_type');
                            });
                        }
                        $('.hotel-rate .' + propId + '-line-through').removeClass('display-inline-block')
                            .hide();
                    }
                    $('.hotel-rate .' + propId + '-priceUnit').text(' ' + (hotelRateObj.currencyCode ? hotelRateObj.currencyCode : ''));
                    let superScriptValue = parseFloat(displayRate).toFixed(2),
                        decimalPosition = superScriptValue.indexOf('.'),
                        superScriptDiv = $('.hotel-rate .' + propId + '-price').find('.' + propId + '-superscript');
                    $('.hotel-rate .' + propId + '-price').html(currencySymbol + Math.floor(displayRate));
                    //if the superscript container gets removed, append it back to the price container
                    if ($('.hotel-rate .' + propId + '-superscript').length === 0) {
                        $('.hotel-rate .' + propId + '-price').append(superScriptDiv[0]);
                    }
                    $('.hotel-rate .' + propId + '-superscript').html(superScriptValue.substring(decimalPosition + 1));
                    $('.hotel-rate #' + propId + '-loading').hide();
                    $('.hotel-rate .' + propId + '-available').show();
                    //Displaying WR points
                    $('p.' + propId + '-wrsection').show();
                    //Check if the rate is Alternate
                    if (hotelRateObj.alternate) {
                        $('.' + propId + '-available div.available-room-check span').text('Alternate Rate');
                    }
                    // $('#'+propId+'-unavailable').hide();
                    $('.hotel-rate .' + propId + '-unavailable').remove();
                    if(!isMobileWidth()) {
                        $('.' + propId + '-unavailable').closest('.propSummary').find('.price-badge').css('cursor','pointer');
                    }
                    this.SearchResults.assignURIInViewRoomsButton(propId, hotObj);
                    if (this.SearchResults._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotelRateObj.totalAfterTax > 0)) {
                        $('.' + propId + '-taxes-fees').removeClass('hidden');
                    }
                } else {
                    //property is not available
                    this.SearchResults.displayUnavailableProp(propId, hotObj.brand);
                }
                if ((hotObj.fnsAvailable && hotelRateObj && (this.SearchResults._ratesRewards || displayRate >= 0)) || _.contains(window.NOT_AVAILABLE_BRANDS, hotObj.brand.toLowerCase())) {
                    let goFastCash;
                    //Property is available
                    let currencySymbol = getCurrencyMapping(hotelRateObj.currencyCode);
                    $('.' + propId + '-go-free').show();
                    $('.hotel-rewards .' + propId + '-unavailable').remove();
                    if(!isMobileWidth()) {
                        $('.' + propId + '-unavailable').closest('.propSummary').find('.price-badge').css('cursor','pointer');
                    }
                    this.SearchResults.assignURIInViewRoomsButton(propId, hotObj);
                    if (hotObj.rate.pacRate && hotObj.rate.pacRate.pacRatePlan) {
                        $('.' + propId + '-go-fast, .' + propId + '-or').show();
                        if (this.SearchResults._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotObj.rate.pacRate.pacRateAfterTax && hotObj.rate.pacRate.pacRateAfterTax > 0)) {
                            goFastCash = parseFloat(hotObj.rate.pacRate.pacRateAfterTax);
                        } else {
                            goFastCash = parseFloat(hotObj.rate.pacRate.pacRate);
                        }
                        let goFastPoints = hotObj.rate.pacRate.pacPoints;
                        $('.hotel-rewards .' + propId + '-priceUnit').text(' ' + (hotelRateObj.currencyCode ? hotelRateObj.currencyCode : ''));
                        let superScriptValue = parseFloat(goFastCash).toFixed(2),
                            decimalPosition = superScriptValue.indexOf('.'),
                            superScriptDiv = $('.hotel-rewards .' + propId + '-price').find('.' + propId + '-superscript');
                        $('.hotel-rewards .' + propId + '-price').html(currencySymbol + Math.floor(goFastCash));
                        //if the superscript container gets removed, append it back to the price container
                        if ($('.hotel-rewards .' + propId + '-superscript').length === 0) {
                            $('.hotel-rewards .' + propId + '-price').append(superScriptDiv[0]);
                        }
                        $('.hotel-rewards .' + propId + '-superscript').html(superScriptValue.substring(decimalPosition + 1));
                        $('.hotel-rewards .' + propId + '-points').html(formatNumber(goFastPoints) + ' ');
                        if (this.SearchResults._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotObj.rate.pacRate.pacRateAfterTax && hotObj.rate.pacRate.pacRateAfterTax > 0)) {
                            $('.' + propId + '-taxes-fees').removeClass('hidden');
                        }
                    }
                    if (this.SearchResults.isRearrangeRequired()) {
                        this.SearchResults.rearrangePropertyOrder(propId, hotObj.brand);
                    }
                }
                //Set flag for rate section is populated.
                $('#summary-' + propId + ' .average-rate').append('<span id="rate-section-updated-' + propId + '" class="display-none">true</span>');
            } else {
                this.SearchResults.displayUnavailableProp(propId, this.searchUtilsInst.getPropertyFromGlobalMap(this.SearchResults._globalPropertyMap, propId).brand);
            }
        } else {
            this.SearchResults._rateUpdateComplete = false;
        }
    }
}
