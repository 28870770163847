import { getCriteria, isWRCCHolder, setCriteria, setPropertyAvailability, UserHandler } from "../../base/session-handler";
import {
    _isNotNull, EventHandler,
    formatDateForBWSAvailability,
    getDateFormatFromISO, getServiceUrl,
    getToday, getTomorrow,
    isPriorDate,
    isToday, updateQueryParameter
} from "../../base/utils";
import { isMobileWidth } from "../../base/dom-utils";
import { additionalCorpCodes, everGreenRateCodes, memberRateCorpCodes, wrccCorpCodes } from "../../base/vars";
import Cookie from "../../wr/base/cookie";
import { BBForm } from "../booking-bar";


export default class RateFetch {
    constructor(search, propertyIds, brand, rateFetchOnly, rateUsageFor) {
        this.Search = search;

        this._propertyIds = propertyIds;
        this._params = {};
        this._params.brandId = brand.toUpperCase();
        this._rateFetchOnly = rateFetchOnly;
        this._rateUsageFor = rateUsageFor;
    }

    getSearchCriteria() {
        let searchCriteria = getCriteria();

        if (searchCriteria) {
            this._params.brand_id = searchCriteria.brandId;
            this._params.checkin_date = formatDateForBWSAvailability(searchCriteria.checkInDate);
            this._params.checkout_date = formatDateForBWSAvailability(searchCriteria.checkOutDate);
            this._params.useWRPoints = searchCriteria.useWRPoints;
            this._params.rateCode = searchCriteria.rateCode;
            this._params.corporate_id = searchCriteria.corpCode;
            this._params.ratePlan = searchCriteria.ratePlan;
            this._params.group_code = searchCriteria.groupCode;
            this._params.childAge = searchCriteria.childAge;
            this._params.children = searchCriteria.children;
            this._params.adults = searchCriteria.adults;
            this._params.rooms = searchCriteria.rooms;
            this._params.iata = searchCriteria.iata;
            this._params.language = searchCriteria.language;
        }

        this._params.properties = this._propertyIds.join();

        return this._params;
    }

    fetchPropAvailability(currPropList) {
        //Call property-availability or rate service passing propIds & other criteria
        let propData = this.getSearchCriteria(),
            searchPropIndex = 0,
            callLevel = 1;
        this.Search._remainingPropsCall = false;

        $(this.Search._displaySearchResults.availability.hostBrand.availability).each((i, val) => {
            if (searchPropIndex > 0) {
                if (val && val.brand && val.brand.toUpperCase() === 'WY') {
                    propData.properties += ',' + val.brand + val.hotelId + '|' + val.brand + val.hotelCode;
                } else {
                    propData.properties += ',' + val.brand + val.hotelId;
                }
            } else {
                if (val && val.brand && val.brand.toUpperCase() === 'WY') {
                    propData.properties = val.brand + val.hotelId + '|' + val.brand + val.hotelCode;
                } else {
                    propData.properties = val.brand + val.hotelId;
                }
            }
            searchPropIndex++;
        });
        $(this.Search._displaySearchResults.availability.altSell.availability).each((i, val) => {
            if (searchPropIndex > 0) {
                if (val && val.brand && val.brand.toUpperCase() === 'WY') {
                    propData.properties += ',' + val.brand + val.hotelId + '|' + val.brand + val.hotelCode;
                } else {
                    propData.properties += ',' + val.brand + val.hotelId;
                }
            } else {
                if (val && val.brand && val.brand.toUpperCase() === 'WY') {
                    propData.properties = val.brand + val.hotelId + '|' + val.brand + val.hotelCode;
                } else {
                    propData.properties = val.brand + val.hotelId;
                }
            }
            searchPropIndex++;
        });
        if (propData.useWRPoints || propData.useWRPoints === 'true') {
            propData.corpCode = propData.groupCode = propData.rateCode = propData.ratePlan = null;
            propData.rateTypeFilter = 'loyalty';
            this.Search._rateTypeFilterLoyalty = true;
        } else {
            //hard code this for Go Fast/Go Free functionality but don't update criteria flag
            if (_isNotNull(propData.corpCode) || _isNotNull(propData.groupCode) || _isNotNull(propData.rateCode)) {
                $('[for=wyndham-rewards-filter], [for=sort-points-filter]').off('click')
                    .addClass('disabled');
                propData.useWRPoints = false;
                propData.rateTypeFilter = 'price';
                this.Search._rateTypeFilterPrice = true;
            } else {
                propData.useWRPoints = false;
                propData.rateTypeFilter = 'price';
                this.Search._rateTypeFilterPrice = true;
            }
        }

        //Add additionalCorpCodes param for mobile view only
        if (isMobileWidth() && _isNotNull(additionalCorpCodes)) {
            propData.additionalCorpCodes = additionalCorpCodes;
            if (_isNotNull(memberRateCorpCodes) && (UserHandler.isWRLoggedIn() || (window.User && Cookie.isAuthenticated()))) {
                propData.additionalCorpCodes = additionalCorpCodes + '|' + memberRateCorpCodes;
            }
        } else if (_isNotNull(memberRateCorpCodes) && (UserHandler.isWRLoggedIn() || (window.User && Cookie.isAuthenticated()))) {
            propData.additionalCorpCodes = memberRateCorpCodes;
        }

        //Add param to include everGreenRate as part of the lowerRateCalculation
        if (isMobileWidth() && _isNotNull(everGreenRateCodes)) {
            propData.ratePlan = (propData.ratePlan) ? propData.ratePlan + ',' + everGreenRateCodes : everGreenRateCodes;
        }

        if (isWRCCHolder() && _isNotNull(wrccCorpCodes)) {
            if (propData.additionalCorpCodes) {
                propData.additionalCorpCodes = propData.additionalCorpCodes + '|' + wrccCorpCodes;
            } else {
                propData.additionalCorpCodes = wrccCorpCodes;
            }
        }

        // Update default dates to be today and tomorrow in case propData dates are in the past
        let checkInDate = getDateFormatFromISO(propData.checkin_date);
        let checkOutDate = getDateFormatFromISO(propData.checkout_date);
        let isCheckInPriorToday = !isToday(checkInDate) && isPriorDate(checkInDate, getToday());
        let isCheckOutPriorToday = !isToday(checkOutDate) && isPriorDate(checkOutDate, getToday());
        if (isCheckInPriorToday || isCheckOutPriorToday) {
            let today = formatDateForBWSAvailability(getToday());
            let tomorrow = formatDateForBWSAvailability(getTomorrow());
            propData.checkin_date = today;
            propData.checkout_date = tomorrow;
            updateQueryParameter("checkInDate", today);
            updateQueryParameter("checkOutDate", tomorrow);
            setCriteria({
                checkInDate: today,
                checkOutDate: tomorrow
            });
        }

        this.triggerPropAvail(propData, callLevel, currPropList);
    }

    triggerPropAvail(propData, callLevel, currPropList) {
        if (propData.properties) {
            $.ajax({
                url: getServiceUrl('rates'),
                type: 'GET',
                data: propData,
                dataType: 'json',
                success: (result, status, xhr) => {
                    setPropertyAvailability(result);
                    if (BBForm.getRedeemWRPoint() || getCriteria().useWRPoints) {
                        $('#wyndham-rewards-filter').attr('checked', true);
                        $('.hotel-rewards').show();
                        $('.hotel-rate').hide();
                        this.Search._ratesRewards = true;
                        this.Search._refineRatesFetched = true;
                        setTimeout(() => {
                            $('.hotel-rate').each((i, el) => {
                                $(el).closest('.hotel-details-wrapper')
                                    .removeClass('view-by-rate');
                            });
                        });
                    } else {
                        $('#price-filter').attr('checked', true);
                        $('.hotel-rewards').hide();
                        $('.hotel-rate').show();
                        this.Search._refineRatesFetched = true;
                        setTimeout(() => {
                            $('.hotel-rate').each((i, el) => {
                                $(el).closest('.hotel-details-wrapper')
                                    .addClass('view-by-rate');
                            });
                        });
                    }
                    this.Search.usePropertyRateForNextAction(result, this._propertyIds, this._rateFetchOnly, this._rateUsageFor, currPropList);
                    $('#list-view').trigger('prop-avail', this.Search._remainingPropsData);
                },
                error: (result, status, error) => {
                    EventHandler.triggerEvent('brands-error', result);
                    console.warn(error);
                    if (callLevel === 1) {
                        this.Search.usePropertyRateForNextAction(result, this._propertyIds, this._rateFetchOnly, this._rateUsageFor, currPropList);
                    }
                }
            });
        } else {
            if (this.Search.SearchFilter._currentFilter.showAvailableOnly) {
                this.Search._remainingPropsCall = true;
            }
        }
    }
}
