import {
  exists,
  ResizeHandler,
  isMobileWidth
} from '../../base/dom-utils.js';
import { observeSrcMutations } from '../../base/utils.js';

class UUBrandCard {
  constructor() {
    if(exists('.uu-hotelcarddeals-component')) {
      this.bindCardHeightFixing();
      this.handleImageResize();
    }
  }

  bindCardHeightFixing() {
    if(!isMobileWidth()) {
      this.handleBrandCardHeights();

      $('.deal-image img').on('img.load', () => {
        this.handleBrandCardHeights();
      });
    }
    ResizeHandler.addResizeEndFn(() => {
      if(!isMobileWidth()) {		// If the window is resized to a non-mobile height, run the function again to handle height changes
        this.handleBrandCardHeights();
      } else {		// If the window is resized to a mobile height, we want to clear out the inline heights we forced
        $('.hotel-deal-slide.uu-hotel-slide .top-region-text').height('auto');
        $('.hotel-deal-slide.uu-hotel-slide').height('auto');
      }
    });
  }

  handleBrandCardHeights() {
    let brandCardImageHeight = 0;
    $('.hotel-deal-slide.uu-hotel-slide .deal-image').each((i,el) => {
      if($(el).height() > brandCardImageHeight) {
        brandCardImageHeight = $(el).height() - 40;
      }
    });

    if(brandCardImageHeight !== 0) {
      $('.hotel-deal-slide.uu-hotel-slide .top-region-text').height(brandCardImageHeight);
    }

    let highestBox = 0,
      $slides = $('.hotel-deal-slide.uu-hotel-slide');
    $slides.height('auto');
    $slides.each((i,el) => {
      if($(el).height() > highestBox) {
        highestBox = $(el).height();
      }
    });
    $('.hotel-deal-slide.uu-hotel-slide').height(highestBox);
  }
  // Handle resize when image changes.
  handleImageResize() {
    observeSrcMutations('.hotel-deal-slide.uu-hotel-slide .pll-image-full-size', () => {
      this.bindCardHeightFixing();
    });
  }
}

export default new UUBrandCard();
