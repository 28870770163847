import { exists, isMobileWidth } from '../base/dom-utils.js';

class Disclaimer {
  constructor() {
    if(exists('.disclaimer-component')) {
      this.switchMobileHeaders();
    }
  }

  switchMobileHeaders() {
    // Disclaimer component headers are h5 for mobile only
    if(isMobileWidth()) {
      $('.disclaimer-component h6').addClass('headline-e');
    }
  }
}

export default new Disclaimer();
