import {
  _isNotNull
} from '../base/utils.js';
import {
  requestUrls
} from '../base/vars.js';

class FeaturedAmenityIcons {

  getAmenityIconsMap() {

    const deferred = $.Deferred();

    if (requestUrls['amenityIconsMap']) {
      const instance = this;
      $.ajax({
        url: requestUrls['amenityIconsMap'],
        success: function(data) {
          if (data.list && data.list.hasOwnProperty('item')) {
            const amenityIconsMap = instance._parseAmenityIconsMapList(data.list);

            if (Object.keys(amenityIconsMap).length) {
              deferred.resolve(amenityIconsMap);
              return;
            }
          }
          deferred.reject();
        },
        error: function() {
          deferred.reject();
        }
      });

    } else {
      deferred.reject();
    }
    return deferred.promise();
  }

  getIconKeyFromName(amenityNameEn) {
    return $.trim(amenityNameEn).toLowerCase().replace(/\s+/g, '_');
  }

  _parseAmenityIconsMapList(amenityIconsMapJcr) {
    let amenityIconsMap = {};

    for (const property in amenityIconsMapJcr) {
      if (property.indexOf('item') >= 0) {
        let amenityIconObj = amenityIconsMapJcr[property];
        if (_isNotNull(amenityIconObj['jcr:title']) &&
          _isNotNull(amenityIconObj['value'])) {
          let amenityNameProcessed = this.getIconKeyFromName(amenityIconObj['jcr:title']);
          amenityIconsMap[amenityNameProcessed] = amenityIconObj['value'];
        }
      }
    }

    return Object.keys(amenityIconsMap).length > 0 ? amenityIconsMap : false;
  }

}

export default new FeaturedAmenityIcons();
