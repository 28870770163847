import { CID_COOKIE, WR_AUTHENTICATED } from '../../base/vars.js';

class Cookie {
  getCookie(id) {
    let value = '; ' + document.cookie,
      parts = value.split('; ' + id + '=');
    if (parts.length >= 2) {
      return parts.pop().split(';')
        .shift();
    } else {
      return false;
    }
  }
  deleteCookie(id) {
    document.cookie = id + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  createCookie(name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    }

    document.cookie = name + '=' + value + expires + '; path=/; secure';
  }
  isCookieActive(cookie) {
    let now = new Date(),
      expiration = this.splitCookie(cookie)[2],
      expired = false;

    if (cookie && this.getCookie(cookie) && expiration) {
      expiration = new Date(expiration);
      expired = expiration.getTime() < now.getTime();
    }

    if (expired) {
      return false;
    } else {
      return true;
    }
  }
  splitCookie(cookie) {
    return this.getCookie(cookie).split('|');
  }
  handleSignedInState() {
    document.getElementsByTagName('html')[0].classList.remove('signed-out');
    document.getElementsByTagName('html')[0].classList.add('signed-in');
  }
  handleSignedOutState() {
    document.getElementsByTagName('html')[0].classList.remove('signed-in');
    document.getElementsByTagName('html')[0].classList.add('signed-out');
  }
  updateState() {
    if (this.isAuthenticated()) {
      this.handleSignedInState();
    } else {
      this.handleSignedOutState();
    }
  }
  isAuthenticated(cookie) {
    cookie = cookie || WR_AUTHENTICATED;
    return this.getCookie(cookie);
  }
  isAnonymous(cookie) {
    cookie = cookie || CID_COOKIE;
    if (this.getCookie(cookie)) {
      return this.splitCookie(cookie)[1] === 'ANONYMOUS';
    } else {
      return false;
    }
  }
  isWRLoggedIn() {
    return this.isAuthenticated();
  }
}

export default new Cookie();
