import Cookie from '../base/cookie.js';
import { getISOFormatDate, getXDays, EventHandler } from '../../base/utils.js';
import { brandMap, requestUrls } from '../../base/vars.js';

class Reservations {
  constructor() {
    this.userResData = {};
    $(document).trigger('getResData:init');
    this.setPageReservationTrigger();
  }
  renderUpcomingReservationData(response) {
    this.userResData = response;
    $(document).trigger('getUpcomingResData:success');
  }
  noReservationData() {
    this.userResData = {};
    EventHandler.triggerEvent('upcoming-reservation', {
      upcomingReservation: 'no'
    });
    $(document).trigger('getResData:none');
  }
  getReservations(pastDate, futureDate) {
    if (requestUrls['getReservations']) {
      return $.ajax({
        url: requestUrls['getReservations'],
        data: {
          dateFrom: pastDate,
          dateTo: futureDate
        },
        contentType: 'application/json'
      });
    }
  }
  getUpcomingReservations() {
    let todayDate = getISOFormatDate(new Date()),
      futureDate = getISOFormatDate(getXDays(366, new Date()));

    this.getReservations(todayDate, futureDate)
      .done((response) => {
        if (response.body) {
          this.renderUpcomingReservationData(response.body);
        } else {
          this.noReservationData(response.body);
        }
      })
      .always(() => {
        $(document).trigger('getResData:always');
      });
  }
  handleFallbackImage($resImg, brandId) {
    let brandImage = '',
      fallbackImage = '/content/dam/assets/global/room1.png';

    if (brandId) {
      brandImage = '/content/dam/assets/clientlibs/' + brandMap[brandId] + '/graphics/' + brandId + '_generic_propertyimg.jpg';
    }

    $resImg.one('error', () => {
      // Replace bad Prop images with Brand image
      $resImg.attr('src', brandImage).one('error', () => {
        // Replace bad BRAND images with generic fallback image
        $resImg.attr('src', fallbackImage);
      });
    });
  }
  setPageReservationTrigger() {
    $(document).on('user:profile:updated', () => {
      if (!($('.full-reservations-component').length > 0)) { // Full Reservations passes authored default params
        if ($('.my-upcoming-reservations').length > 0) {
          // Account Page- get current on init
          this.getUpcomingReservations();
        } else if ($('.upcoming-reservation-preview').length > 2) {
          // Homepage- get current on init IF authentiated
          if (Cookie.isAuthenticated()) {
            this.getUpcomingReservations();
          }
        }
      }
    });

    $('.top-nav-item[data-dropdown="reservations"], .top-nav-item.dropdown-toggle').one('click', () => {
      if (Cookie.isAuthenticated() && !this.userResData) {
        this.getUpcomingReservations();
      }
    });
  }
}

export default new Reservations();
