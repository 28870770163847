import { getBrand } from '../base/session-handler.js';
import { exists } from '../base/dom-utils.js';
import { EventHandler } from '../base/utils.js';

class EmailPreferenceForm {
  constructor() {
    if (exists('.email-preference-container')) {
      this.prefForm = $('#emailPreferenceForm');
      this.bindFormSubmit();
    }
  }

  bindFormSubmit() {
    $('.email-preference-container form').on('submit', (e) => {
      e.preventDefault();
      if (this.prefForm.find('.parsley-error').length > 0) {
        return;
      }

      let emailData = this.collectFormData();
      this.submitForm(emailData);
    });
  }

  collectFormData() {
    // Collect data from dom for form submission
    let firstNameVal = this.prefForm.find('.first-name input').val();
    let lastNameVal = this.prefForm.find('.last-name input').val();
    let streetVal = this.prefForm.find('.street input').val();
    // check if street2 is on the form
    let street2Val = (this.prefForm.find('.street2').length > 0) ? this.prefForm.find('.street2 input').val() : '';
    let cityVal = this.prefForm.find(' .street input').val();
    let stateProvinceVal = this.prefForm.find('.state-province select').val()
      .split('_')[1];
    let countryVal = this.prefForm.find('.country select').val();
    let postcodeVal = this.prefForm.find('.postcode input').val();
    let userEmail = this.prefForm.find('.email input').val();

    // when authoring must use : optIn-optOut-radios
    this.emailOptIn = this.prefForm.find('input[value="opt-1"]').is(':checked');

    // collect the form's value into an object to send to service
    return {
      email_id: userEmail,
      first_name: firstNameVal,
      last_name: lastNameVal,
      address_line1: streetVal,
      address_line2: street2Val,
      city: cityVal,
      state: stateProvinceVal,
      postal: postcodeVal,
      country: countryVal,
      brand: getBrand(),
      optinout: this.emailOptIn
    };
  }

  submitForm(emailData) {
    $.ajax({
      type: 'POST',
      url: '/BWSServices/services/hotels/email-preference-center',
      // The key needs to match your method's input parameter (case-sensitive).
      data: emailData,
      dataType: 'json',
      success: (data) => {
        if (this.emailOptIn && data.statusCode === 200) {
          // Opted in successfully.
          window.location.href = this.prefForm.data('opt-in-url');
        } else if (!this.emailOptIn) {
          if (data.statusCode === 200) {
            // Opt-out successful
            window.location.href = this.prefForm.data('opt-out-url');
          } else if (data.statusCode === 500) {
            // opt-out failed - user is unregistered
            window.location.href = this.prefForm.data('opt-out-failed-url');
          }
        }
      },
      failure: (errMsg) => {
        console.err(errMsg);
        EventHandler.triggerEvent('brands-error', errMsg);
      }
    });
  }
}

export default new EmailPreferenceForm();
