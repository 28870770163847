import intlTelInput from 'intl-tel-input';
import ConfigsUtils from '../../base/aem-configs/config-utils';
import { _LOCALE_, _REGION_, _LANGUAGE_, _defaultChannelId } from '../../base/vars';

class PhoneInput {
    constructor() {
        this.languageMap = JSON.parse(this.getLanguageMap()) || {};
    }

    getLanguageMap() {
        return ConfigsUtils.getConfigs('phoneInputConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
            (result, item) => {
                return item.name === 'phone_number_language' && item.value ? item.value : result;
            },
            null
        );
    }

    initializePhoneInputs() {
        if (document.querySelector(".iti.country-list")) {
            return;
        }
        const phoneInputs = document.querySelectorAll("input.phone-input");
        phoneInputs.forEach((input) => {
            this.setPhoneInput(input);
        });
    }
    
    setPhoneInput(input) {
        let region = _REGION_;
        if (_LANGUAGE_ === 'es') {
            region = _LANGUAGE_;
        }
        intlTelInput(input, {
            separateDialCode: false,
            autoPlaceholder: "off",
            customContainer: "country-list",
            preferredCountries: [region],
            localizedCountries: this.languageMap,
        });
        const labelText = input.getAttribute("data-text");
        if (labelText) {
            const labelFor = input.getAttribute("id");
            const labelElement = document.createElement("label");
            const countryContainer = input.closest(".country-list");
            labelElement.className = "floating-label";
            labelElement.setAttribute("for", labelFor);
            labelElement.textContent = labelText;
            countryContainer.appendChild(labelElement);
        }
        input.addEventListener('countrychange', () => {
            this.triggerInputFieldValidation(input);
        });
        const fieldContainer = input.closest(".form-group");
        const spinner = fieldContainer ? fieldContainer.querySelector(".spinner-container") : null;
        if (spinner) {
            spinner.classList.add('hidden');
        }
    }

    refreshInputField(index) {
        const phoneInputs = document.querySelectorAll("input.phone-input");
        if (phoneInputs && phoneInputs.length > 0 && index >= 0 && index <= phoneInputs.length) {
            const currentInput = phoneInputs[index];
            const iti = window.intlTelInputGlobals.getInstance(currentInput);
            iti.setNumber(currentInput.value);
            this.triggerInputFieldValidation(currentInput);
        }
    }

    triggerInputFieldValidation(input) {
        const inputEvent = new Event('input', {
            bubbles: true,
            cancelable: true
        });
        input.dispatchEvent(inputEvent);
    }

    getPhoneNumberCountryByIndex(index) {
        const iti = this.getItiByIndex(index);
        const countryDialCode = iti && iti.selectedCountryData && iti.selectedCountryData.dialCode ? iti.selectedCountryData.dialCode : null;
        return countryDialCode ? `+${countryDialCode}` : '';
    }

    getPhoneNumberISO2ByIndex(index) {
        const iti = this.getItiByIndex(index);
        const iso2Code = iti && iti.selectedCountryData && iti.selectedCountryData.iso2 ? iti.selectedCountryData.iso2 : null;
        return iso2Code ? iso2Code.toUpperCase() : null;
    }

    getItiByIndex(index) {
        const phoneInputs = document.querySelectorAll("input.phone-input");
        if (index < 0 || index >= phoneInputs.length) {
            return null;
        }
        const currentInput = phoneInputs[index];
        const iti = window.intlTelInputGlobals.getInstance(currentInput);
        return iti || null;
    }

    getPhoneNumberWithCountryCode(phoneNumber, index) {
        if (!phoneNumber) {
            return '';
        }
        const startsWithPlus = phoneNumber.startsWith('+');
        let newPhoneNumber = phoneNumber.replace(/\D+/g, '');
        
        if (startsWithPlus) {
            newPhoneNumber = '+' + newPhoneNumber;
        } else {
            newPhoneNumber = this.getPhoneNumberCountryByIndex(index) + newPhoneNumber;
        }
        return newPhoneNumber;
    }

    setCustomPhoneNumber(phoneNumber, iso2, index) {
        const iti = this.getItiByIndex(index);

        if (iti && phoneNumber) {
            iti.setNumber(phoneNumber);
        }

        if (iti && iso2) {
            iti.setCountry(iso2);
        }
    }
}

export default new PhoneInput();
