class CollapsibleContainer {
  constructor() {
    this.bindCollapsibleContainerClick();
  }
  bindCollapsibleContainerClick() {
    $('.cc-show').click((e) => {
      let $link = $(e.currentTarget),
        $content = $link.closest('.c-collapsible-container'),
        $componentsContainer = $content.find('.collapsible-components-content'),
        $slickCarousel = $content.find('.slick-slider'),
        isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

      $componentsContainer.css('height', $componentsContainer.prop('scrollHeight') + 'px');
      $componentsContainer.toggleClass('in');
      $link.children('span').toggleClass('hide');

      if ($componentsContainer.hasClass('in')) {
        $componentsContainer.css('height', $componentsContainer.prop('scrollHeight') + 'px');

        if (isSmoothScrollSupported) {
          window.scrollTo({
            top: $content.offset().top - 205,
            left: 0,
            behavior: 'smooth'
          });
        } else {
          $('html, body').animate({
            scrollTop: $content.offset().top - 205
          }, 300);
        }

        if ($slickCarousel) {
          // Manually refresh positioning of slick to recalculate height
          $slickCarousel.each((i, el) => {
            $(el).slick('setPosition');
            $(el).find('.slick-list')
              .height($(el).find('.slick-current')
                .height());
          });
        }
      } else {
        $componentsContainer.css('height', 0);
      }
    });
  }
}

export default new CollapsibleContainer();
