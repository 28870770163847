import {
    getCriteria,
    setCriteria,
    UserHandler
} from "../../base/session-handler";
import {
    _isNumber,
    formatDateForBWSAvailability,
    getDefaultSearchRadius,
    getQueryParameter,
    getToday,
    updateQueryParameter,
    updateSearchParameters
} from "../../base/utils";
import {_LOCALE_} from "../../base/vars";
import Analytics from "../../base/wyn-analytics-module";
import {BBForm} from "../booking-bar";
import {simpleLightbox} from "../../base/dom-utils";
import RateFetch from "./RateFetch";

export default class SearchFilter {
    constructor(search, map, searchBrandBannerHandler) {
        this.Search = search;
        this.init();
        this.Map = map;
        this._oldFilter = null;
        this._currentFilter = null;
        this._defaultFilter = this.getFilterValues();
        this._isFilteringDone = false;
        this._searchBrandBannerHandler = searchBrandBannerHandler;

        this._currentSortType = 'REL';
        this._isRearrangeRequired = true;
        this._isSortingDone = false;
        this._busy = false;
    }
    executeIfNotBusy(event, cb) {
        if (!this._busy) {
            this._busy = true;
            cb();
        } else {
            event.preventDefault();
        }
    }
    getDropDownArr(el) {
        let distArray = [];
        el.find('option').each((idx, itm) => {
            distArray.push(itm.value);
        });
        return distArray;
    }
    getClosestValue(arr, rad) {
        if (arr.indexOf(rad.toString()) >= 0) {
            return rad;
        } else {
            let arrLargerNum = arr.filter((arrNum) => arrNum > rad);
            let closestNum = Math.min.apply(null, arrLargerNum);
            return (closestNum != Infinity) ? closestNum : parseInt(arr[arr.length - 1]);
        }
    }
    getFilterValues() {
        let filter = {},
            amenities = [],
            showOnlyAmenities = [],
            brands = [],
            $distance = (this.Search._isMilesLocation) ? $('#distanceMin') : $('#kmDistanceMin'),
            tiers = [];

        let searchCriteria = this._searchCriteria ? this._searchCriteria : getCriteria();
        let radius = searchCriteria.radius || getDefaultSearchRadius();

        filter.redeemMin = $('#redeemMin').val();
        filter.redeemMax = $('#redeemMax').val();
        filter.priceMin = $('#priceMin').val();
        filter.priceMax = $('#priceMax').val();
        filter.tripadvisorMin = $('#tripadvisorMin').val();
        filter.tripadvisorMax = $('#tripadvisorMax').val();
        filter.distanceMin = $distance.val();

        if (!Number.isInteger(parseInt(filter.distanceMin))) {
            if (this._currentFilter && this._currentFilter.distanceMin) {
                $distance.val(this.getClosestValue(this.getDropDownArr($distance), parseInt(this._currentFilter.distanceMin)));
                filter.distanceMin = $distance.val();
            } else {
                $distance.val(this.getClosestValue(this.getDropDownArr($distance), parseInt(radius)));
                filter.distanceMin = $distance.val();
            }
        }

        $('.amenitiesCheckbox input:checked').each((i, el) => {
            amenities.push($(el).val());
        });

        $('.show-only-amenities input:checked').each((i, el) => {
            showOnlyAmenities.push($(el).val());
        });

        filter.amenities = amenities;
        filter.showOnlyAmenities = showOnlyAmenities;
        $('.brands input:checked').each((i, el) => {
            brands.push($(el).val()
                .toUpperCase());
        });
        filter.brands = brands;
        filter.showAvailableOnly = ($('.availb-hotel input:checked').length == 1);

        filter.price = !$('.wyndham-rewards-checkbox').is(':checked');
        filter.wrPoints = $('.wyndham-rewards-checkbox').is(':checked');
        filter.directbillflag = ($('.check-direct-bill input:checked').length == 1);
        if(filter.wrPoints) {
            let tiersSelected = $('.tier-filter-container input:checked');

            tiersSelected.each((i,el) => {
                let cbTiers = $(el).attr('value')
                    .split(',')
                    .map(Function.prototype.call, String.prototype.trim);
                tiers = tiers.concat(cbTiers);
            });

            if(tiersSelected.length !== $('.tier-filter-container input:not(.always-disable)').length) {
                filter.tiers = tiers;
            }
            Analytics.updateSearchSortAnalytics($('#wyndham-rewards-filter + span span').text() + '|' + tiers.toString());
        } else {
            Analytics.updateSearchSortAnalytics($('#price-filter + span span').text());
        }
        return filter;
    }
    setCurrentFilter() {
        this._currentFilter = this.getFilterValues();
    }
    disableFilters() {
        $('.mobile-search-tabs ').attr('disabled', true);
        $('label[for=\'price-filter\']').addClass('disabled');
        $('[for=wyndham-rewards-filter]').addClass('disabled');
        $('.tier-filter-container label').addClass('disabled');
        if ($('#price').data('uiSlider')) {
            $('#price').slider('option', 'disabled', true);
        }
        $('#chk-show-avail').attr('disabled', true);
        $('#chk-show-avail').parent().css('opacity', '0.6');
        $('#chk-direct-bill').prop("disabled", true);
        $('#chk-direct-bill').parent().css('opacity', '0.6');
        $('.refine-result-container select').attr('disabled', true);
        $('.amenitiesCheckbox input:checkbox').parent()
            .css('opacity', '0.6');
        $('.amenitiesCheckbox input:checkbox').attr('disabled', true);

        $('.show-only-amenities input:checkbox').parent()
            .css('opacity', '0.6');
        $('.show-only-amenities input:checkbox').attr('disabled', true);

        $('.brands input:checkbox').parent()
            .css('opacity', '0.6');
        $('.brands input:checkbox').attr('disabled', true);
        $('.sort-by-wrapper select').attr('disabled', true);
    }
    enableFilters() {
        $('.mobile-search-tabs ').removeAttr('disabled');
        $('label[for=\'price-filter\']').removeClass('disabled');
        if($('.tier-filter-container input:checked').length === 1) {
            $('.tier-filter-container input:not(:checked):not(.always-disable)').parents('label').removeClass('disabled');
        } else if($('#wyndham-rewards-filter').is(':checked')) {
            $('.tier-filter-container label:not(.always-disable)').removeClass('disabled');
        }
        if(!getCriteria().rateCode){
            $('[for=wyndham-rewards-filter]').removeClass('disabled');
        }
        this.enableCheckBoxes('.brands input:checkbox');
        this.enableCheckBoxes('.amenitiesCheckbox input:checkbox');
        this.enableCheckBoxes('.show-only-amenities input:checkbox');
        if ($('#price').data('uiSlider')) {
            $('#price').slider('option', 'disabled', false);
            if ($('#wyndham-rewards-filter').is(':checked')) {
                $('#price').slider('option', 'disabled', true);
                $('#pricerangefilter').addClass('wr-styles');
            } else {
                $('#pricerangefilter').removeClass('wr-styles');
            }
        }
        if ($('#chk-show-avail').parent('label').find('span.filterCount').text().trim() !== '(0)') {
            $('#chk-show-avail').removeAttr('disabled');
            $('#chk-show-avail').parent().css('opacity', '1');
        }
        if ($('#chk-direct-bill').parent('label').find('span.filterCount').text().trim() !== '(0)') {
            $('#chk-direct-bill').prop("disabled", false);
            $('#chk-direct-bill').parent().css('opacity', '1');
        }
        if ($('#wyndham-rewards-filter').is(':checked')) {
            $('#chk-direct-bill').prop("disabled", true);
            $('#chk-direct-bill').parent().css('opacity', '0.6');
        }
        $('.refine-result-container select').removeAttr('disabled');
        $('.sort-by-wrapper select').removeAttr('disabled');
    }
    enableCheckBoxes(filterType) {
        let filters = [];
        $(filterType).each((i, el) => {
            filters.push($(el).val());
        });
        for (let index = 0; index < filters.length; index++) {
            let filterElement = $(filterType + '[value="' + filters[index] + '"]');
            if (filterElement.is(':checked')) {
                filterElement.parent().css('opacity', '1.0');
                filterElement.removeAttr('disabled').parent('label')
                    .find('span.filterCount')
                    .text('');
            } else if (filterElement.first().parent('label').find('span.filterCount')
                .text() != '(0)') {
                filterElement.parent().css('opacity', '1.0');
                filterElement.removeAttr('disabled');
            }
        }
    }
    enableFiltersOnInit() {
        $('.refine-result-wrapper, .sort-by-wrapper').removeClass('loading')
            .removeClass('waitingBar')
            .removeClass('sortWaitingBar');
        $('#mobileToggleView .fitler-button, #mobileToggleView .sort-icon, #mobileToggleView select').css('visibility', 'visible');
    }
    /**
     * This method will be called to collect all current filter parameter
     * once "APPLY NOW" button in Refine section is clicked.
     */
    enableFilter() {
        let refinementSelection;

        if ($('#rewardspricefilter').length > 0) {
            $('label[for=\'price-filter\']').click(() => {
                $('.hotel-rewards').hide();
                $('.hotel-rate').show();
                if($('.tier-filter-container').is('.default-expand')) {
                    // Reset checkboxes to checked, boxes should be disabled
                    $('.tier-filter-container input:not(.always-disable)')
                        .prop('checked', true)
                        .attr('disabled', true);
                    $('.tier-filter-container label:not(.always-disable)').addClass('disabled');
                } else {
                    $('.tier-filter-container').hide();
                    // Reset checkboxes to checked, reenable all disabled ones
                    $('.tier-filter-container input:not(.always-disable)')
                        .prop('checked', true)
                        .attr('disabled', false);
                    $('.tier-filter-container label:not(.always-disable)').removeClass('disabled');
                }
                $('.hotel-rate').each((i, el) => {
                    $(el).closest('.hotel-details-wrapper')
                        .addClass('view-by-rate');
                });
                updateQueryParameter('useWRPoints', 'false');
                setCriteria({
                    useWRPoints: false,
                    rateTypeFilter: 'price'
                });

                if ($('#chk-direct-bill').length > 0 && $('#chk-direct-bill').parent().data('price-check')) {
                    $('#chk-direct-bill').prop('checked', true).parent().css('opacity', '1').removeAttr('data-price-check');
                }

                if ($('.wyndham-rewards-checkbox').length > 0 && $('.wyndham-rewards-checkbox').is(':checked')) {
                    $('.wyndham-rewards-checkbox').trigger('click');
                    this.Search._displaySearchResults = $.extend(true, {}, this.Search._actualSearchResults);
                    this.Search._propertyRateMap = {};
                    this.goForRefinement();
                    $('#refine-price-div-section').show();
                    updateSearchParameters('useWRPoints', 'false');
                }
                $('#sort-points-filter').prop('checked', false);
            });
            $('label[for=\'wyndham-rewards-filter\']').click(() => {
                $('.hotel-rewards').show();
                $('.hotel-rate').hide();
                // Show tier filter checkboxes
                $('.tier-filter-container').show();
                $('.tier-filter-container input:not(.always-disable)')
                    .prop('disabled', false)
                    .parents('label')
                    .removeClass('disabled');
                $('.hotel-rate').each((i, el) => {
                    $(el).closest('.hotel-details-wrapper')
                        .removeClass('view-by-rate');
                });
                if ($('#price').data('uiSlider')) {
                    $('#price').slider('option', 'disabled', true);
                }
                if ($('#chk-direct-bill').length > 0 && $('#chk-direct-bill').is(':checked')) {
                    $('#chk-direct-bill').prop('checked', false).parent().attr('data-price-check', true);
                }
                $('#chk-direct-bill').prop("disabled", true);
                $('#chk-direct-bill').parent().css('opacity', '0.6');
                updateQueryParameter('useWRPoints', 'true');
                setCriteria({
                    useWRPoints: true,
                    rateTypeFilter: 'loyalty'
                });
                if ($('.wyndham-rewards-checkbox').length > 0 && !$('.wyndham-rewards-checkbox').is(':checked')) {
                    $('.wyndham-rewards-checkbox').trigger('click');
                    this.Search._displaySearchResults = $.extend(true, {}, this.Search._actualSearchResults);
                    this.Search._propertyRateMap = {};
                    this.goForRefinement();
                    $('#refine-price-div-section').hide();
                    updateSearchParameters('useWRPoints', 'true');
                }
                $('#sort-points-filter').prop('checked', true);
            });
            $('.tier-filter-container input').change((e) => {
                // Disable last filter checkbox to avoid none selected
                if($('.tier-filter-container input:checked').length === 1) {
                    $('.tier-filter-container input:checked').attr('disabled', true);
                    $('.tier-filter-container input:checked').parent('label').addClass('disabled');
                } else {
                    $('.tier-filter-container input:not(.always-disable)').attr('disabled', false);
                    $('.tier-filter-container label:not(.always-disable)').removeClass('disabled');
                }
                this.goForRefinement();
            });
            $('#sort-points-filter').change((e) => {
                if ($(e.currentTarget).is(':checked')) {
                    $('label[for="wyndham-rewards-filter"]').click();
                    $('#wyndham-rewards-filter').prop('checked', true);
                } else {
                    $('label[for="price-filter"]').click();
                    $('#price-filter').prop('checked', true);
                }
            });
        }

        $('.amenitiesCheckbox input:checkbox').click((e) => {
            $('.amenitiesCheckbox input:checked').each((index, el) => {
                //Note: This method looks long but is needed to grab the display value and not the filter number.
                if (index < 1) {
                    refinementSelection = $.trim($(el).next()
                        .children()
                        .clone()
                        .children()
                        .remove()
                        .end()
                        .text());
                } else {
                    refinementSelection += ', ' + $.trim($(el).next()
                        .children()
                        .clone()
                        .children()
                        .remove()
                        .end()
                        .text());
                }
            });

            Analytics.updateSearchRefinementAnalytics('Amenities', refinementSelection);

            Analytics.satelliteTracker('search_filter');
            this.goForRefinement();
        });

        $('.show-only-amenities input:checkbox').click((e) => {
            this.executeIfNotBusy(e, () => {
                $('.show-only-amenities input:checked').each((index, el) => {
                    //Note: This method looks long but is needed to grab the display value and not the filter number.
                    if (index < 1) {
                        refinementSelection = el.name;
                    } else {
                        refinementSelection += ', ' + el.name;
                    }
                });

                this.goForRefinement();
            });
        });

        // Analytics handling for show only filters
        $('#availabilityfilter input:checkbox').click((e) => {
            let refinementSelectionDataLayer;
            $('#availabilityfilter input:checked').each((index, el) => {
                //Note: This method looks long but is needed to grab the display value and not the filter number.
                if (index < 1) {
                    refinementSelectionDataLayer = el.name;
                } else {
                    refinementSelectionDataLayer += ', ' + el.name;
                }
            });

            if (refinementSelectionDataLayer) {
                refinementSelectionDataLayer = refinementSelectionDataLayer.toLowerCase();
            }

            Analytics.updateSearchRefinementAnalytics('show only', refinementSelectionDataLayer);

            Analytics.satelliteTracker('search_filter');
        });

        $('.brands input:checkbox').click(() => {
            $('.brands input:checked').each((index, el) => {
                if (index < 1) {
                    refinementSelection = $.trim($(el).next()
                        .children()
                        .clone()
                        .children()
                        .remove()
                        .end()
                        .text());
                } else {
                    refinementSelection += ', ' + $.trim($(el).next()
                        .children()
                        .clone()
                        .children()
                        .remove()
                        .end()
                        .text());
                }
            });

            Analytics.updateSearchRefinementAnalytics('Brands', refinementSelection);
            Analytics.satelliteTracker('search_filter');
            this.goForRefinement();
        });
        // show available hotels only checkbox
        $('#chk-show-avail').click(event => {
            this.executeIfNotBusy(event, () => this.goForRefinement());
        });
        // show direct bill hotels only checkbox
        $('#chk-direct-bill').click(event => {
            this.executeIfNotBusy(event, () => this.goForRefinement());
        });
        //Have to override default functionality and add custom logic for filter
        $('.refine-result-container select').on('change', (e) => {
            let radius = _isNumber($(e.currentTarget).val() * 1) ? $(e.currentTarget).val() : getDefaultSearchRadius();
            this.Search._stopPriceInit = true;
            Analytics.updateSearchRefinementAnalytics('Distance', radius + (this.Search._isMilesLocation ? ' Miles' : 'Kilometers'));
            Analytics.satelliteTracker('distance_dropdown');
            this.goForRefinement();

            setCriteria({
                radius: radius
            });

            return false;
        });

        // On price slider change
        $('#priceMin, #priceMax').change((e) => {
            let $this = $(e.currentTarget);

            if ((e.currentTarget.id === 'priceMax' && $this.val() != this._currentFilter.priceMax) ||
                (e.currentTarget.id === 'priceMin' && $this.val() != this._currentFilter.priceMin)) {
                this.goForRefinement();
            }
        });

        $('input[value=\'wyndham-rewards-filter\']').click(() => {
            if (BBForm.getRooms() > 1) {
                simpleLightbox($('#numOfRooms').data('rewards-error'), () => {
                    $('#price-filter').click();
                });
                return;
            }
        });
    }
    goForRefinement() {
        this._isFilteringDone = false;
        this.Search._isSortingDone = false;
        //Hide altSell message if displayed already
        $('#no-rooms-message-section').hide();
        $('#no-property-message-section').hide()
            .removeClass('no-room-message-wrapper');
        this.Search._nonHostBrandIndex = 0;
        this.Search._hostBrandIndex = 0;
        this.Search._hostBrandSet = false;
        this.Search._dividerDivRendered = false;
        this.applyFilterForRefinement();
    }
    applyFilterForRefinement() {
        this.disableFilters();
        let oldValue = this.Search._remainingPropsCall;
        this.Search._remainingPropsCall = false;
        this._isSortingDone = false;
        this.setCurrentFilter();
        let $listView = $('#list-view');
        if (this._oldFilter != null) {
            if (JSON.stringify(this._currentFilter) === JSON.stringify(this._oldFilter)) {
                this.enableFilters();
                // No change in the current filter nothing to do
            } else if (JSON.stringify(this._currentFilter) === JSON.stringify(this._defaultFilter)) {
                $listView.empty();
                this.Search._noResultsRendered = false;
                this.doFinalThingBeforeDisplayAfterFilter($.extend(true, {}, this.Search._actualSearchResults));
                let $mapView = $('#map-view');
                if($mapView.is(':visible')) {
                    this.Map.loadMapViewComponent($mapView.get(0), this.Map.preparePropListForMap(), this._currentFilter.wrPoints);
                }
            } else {
                $listView.empty();
                this.Search._noResultsRendered = false;
                this.Search._totalCurrDisplayedItem = 0;
                this.applyCurrentFilter();
            }
        } else {
            $listView.empty();
            this.Search._noResultsRendered = false;
            //Filter not applied before
            this.applyCurrentFilter();
        }

        this.Search._remainingPropsCall = oldValue;
    }
    applyCurrentFilter() {
        //Removing scroll event
        $(window).off('scroll');
        this.Search._scrollEnabled = false;
        this.Search._fetchedPropertyItemCount = 0;
        this.Search._renderedPropertyItemCount = 0;
        if (this._currentFilter.distanceMin == 'Select Option') {
            this._currentFilter.distanceMin = this._defaultFilter.distanceMin;
        }
        if (this._currentFilter.distanceMin && parseInt(this._defaultFilter.distanceMin) != parseInt(this._currentFilter.distanceMin) && parseInt(this._defaultFilter.distanceMin) < parseInt(this._currentFilter.distanceMin)) {
            //Properties are fetch second time so assign this variable to false.
            this.Search._ratesFetched = false;
            this.Search._ratesPrice = false;
            this.Search._ratesRewards = false;
            this.Search._rateTypeFilterPrice = false;
            this.Search._rateTypeFilterLoyalty = false;
            //Call MultiPropSearchByRadius service to fetch new data
            let multiPropSC = this.Search.getMultiPropertySearchCriteria();
            multiPropSC.radius = this._currentFilter.distanceMin;
            this._defaultFilter.distanceMin = this._currentFilter.distanceMin;
            this.Search.getMultiPropertySearchByRadius(multiPropSC, true);
        } else if (parseInt(this._currentFilter.distanceMin) >= parseInt(this._defaultFilter.distanceMin) && this._currentFilter.hasOwnProperty('price') && this._currentFilter.hasOwnProperty('wrPoints') && ((this._defaultFilter.price != this._currentFilter.price) || (this._currentFilter.wrPoints)) && (this.Search._ratesRewards === false)) {
            this.Search._ratesFetched = false;
            this.Search._refineRatesFetched = false;
            this.Search._remainingPropsData = {};
            let searchResultsRate = new RateFetch(this.Search, this.Search._propsForRateFetch.slice(0), this.Search._currentBrand, false, null);
            searchResultsRate.fetchPropAvailability(null);
            this.Search._isDataAvailable = true;
            this._oldFilter = $.extend(true, {}, this._currentFilter);
        } else {
            //Identify all the properties under applied distance
            if (this.Search._actualSearchResults) {
                this.proceedWithRestOfTheFilter(this.applyDistanceFilter(this._currentFilter.distanceMin, $.extend(true, {}, this.Search._actualSearchResults)));
            } else {
                this.enableFilters();
                this.Search._noResultsRendered = false;
                this.Search.noResultsFound(false);
            }
        }

        //if not on mobile and not at top of div, move user back to top of search div
        if ($(window).width() > 719 && ($(window).scrollTop() > $('#list-view').offset().top)) {
            $('html, body').animate({
                scrollTop: $('#list-view').offset().top - 200
            }, 300);
        }
    }
    /**
     * This method is for doing rest of the filter in the propertyList.
     */
    proceedWithRestOfTheFilter(currentPropList) {
        if (this._currentFilter) {
            if ((this._currentFilter.tripadvisorMin && this._defaultFilter.tripadvisorMin != this._currentFilter.tripadvisorMin) || (this._currentFilter.tripadvisorMax && this._defaultFilter.tripadvisorMax != this._currentFilter.tripadvisorMax)) {
                //Apply rating filter
                currentPropList = this.applyAttributeFilter(this._currentFilter.tripadvisorMin, this._currentFilter.tripadvisorMax, 'rating', null, currentPropList);
            }
            if (this._currentFilter.amenities && this._currentFilter.amenities.length > 0) {
                //Apply amenities filter
                currentPropList = this.applyAmenityFilter(this._currentFilter.amenities, currentPropList);
            }
            if (this._currentFilter.showOnlyAmenities && this._currentFilter.showOnlyAmenities.length > 0) {
                currentPropList = this.applyShowOnlyAmenityFilter(this._currentFilter.showOnlyAmenities, currentPropList);
            }
            if (this._currentFilter.tiers && this._currentFilter.tiers.length > 0) {
                currentPropList = this.applyTierFilter(this._currentFilter.tiers, currentPropList);
            }
            if (this._currentFilter.directbillflag && (this._defaultFilter.directbillflag != this._currentFilter.directbillflag)) {
                currentPropList = this.applyDBFilter(currentPropList);
            }
            if (this.Search._searchCriteria && (this.Search._searchCriteria.useWRPoints == true || this.Search._searchCriteria.useWRPoints == 'true')) {
                $('#refine-price-div-section').hide();
                if ((this._defaultFilter.redeemMin != this._currentFilter.redeemMin) || (this._defaultFilter.redeemMax != this._currentFilter.redeemMax)) {
                    currentPropList = this.applyAttributeFilter(parseInt(this._currentFilter.redeemMin), parseInt(this._currentFilter.redeemMax), 'tierPoints', null, currentPropList);
                }
            }
            this._isFilteringDone = true;
            if (this._currentFilter.showAvailableOnly || (this._currentFilter.price && this._currentFilter.priceMin && this._defaultFilter.priceMin != this._currentFilter.priceMin) || (this._currentFilter.price && this._currentFilter.priceMax && this._defaultFilter.priceMax != this._currentFilter.priceMax)) {
                this.showAvailableHotelsOnly(currentPropList);
            } else {
                this.doFinalThingBeforeDisplayAfterFilter(currentPropList);
            }
            let mapEle = $('#map-view');
            if (mapEle.is(':visible')) {
                $('#list-view').hide();
                mapEle.empty();
                this.Map.loadMapViewComponent(mapEle.get(0), this.Map.preparePropListForMap(), this._currentFilter.wrPoints);
            }
        } else {
            this.doFinalThingBeforeDisplayAfterFilter(currentPropList);
        }
    }
    doFinalThingBeforeDisplayAfterFilter(currentPropList) {
        this.setBrandRefineCountsAfterFilter(currentPropList);
        //this.setRefineCountsAfterFilter(currentPropList);
        if (this._currentFilter && this._currentFilter.brands && this._currentFilter.brands.length > 0) {
            //Apply brand filter
            currentPropList = this.applyBrandFilter(this._currentFilter.brands, currentPropList);
        }
        this.setAmenityRefineCountsAfterFilter(currentPropList);
        this.setShowOnlyAmenityRefineCountsAfterFilter(currentPropList);
        this.setAvailabilityRefineCountsAfterFilter(currentPropList);
        this.setDirectBillRefineCountsAfterFilter(currentPropList);
        if (!this._isSortingDone && this._currentSortType != 'REL') {
            this._isFilteringDone = true;
            this._oldFilter = $.extend(true, {}, this._currentFilter);
            if (this._currentSortType == 'PLH' || this._currentSortType == 'PHL') {
                //currentPropList = this.applyPriceSorting(currentPropList);
                this.Search.fetchRatesForRemainingProperty('SORTING', currentPropList);
            } else {
                this.applyCurrentSorting(currentPropList);
            }
            this._searchBrandBannerHandler.filterShowBannerBrands(this._currentFilter, this);
        } else {
            this._isFilteringDone = true;

            let searchCriteria = getCriteria();
            let checkIn = formatDateForBWSAvailability(searchCriteria.checkInDate);
            let today = formatDateForBWSAvailability(getToday());

            let rateRequired = [];
            rateRequired = this.getRemainingPropertyIds(rateRequired, currentPropList.availability.hostBrand.availability);
            rateRequired = this.getRemainingPropertyIds(rateRequired, currentPropList.availability.altSell.availability);
            if (rateRequired.length > 0) {
                this.Search._isFirstListRender = true;
                // Call rate service to fetch the data
                let searchResultsRate = new RateFetch(this.Search, rateRequired, this.Search._currentBrand, true, 'REFINING');
                // Fetching rate for property availability
                searchResultsRate.fetchPropAvailability(currentPropList);
            }

            if(checkIn === today){
                if (this._currentSortType == 'PLH' || this._currentSortType == 'PHL') {
                    currentPropList = this.applyPriceSorting(currentPropList);
                } else {
                    currentPropList.availability.hostBrand.availability = currentPropList.availability.hostBrand.availability.sort(this.distanceTodaySort);
                    currentPropList.availability.altSell.availability = currentPropList.availability.altSell.availability.sort(this.distanceTodaySort);
                }
            }

            this.Search._displaySearchResults = currentPropList;

            if (!$('#map-view').is(':visible')) {
                $('#list-view').show();
            }

            this._oldFilter = $.extend(true, {}, this._currentFilter);
            this.Search.initializeForDisplayProperties();
            this._searchBrandBannerHandler.filterShowBannerBrands(this._currentFilter, this);
        }
    }
    showAvailableHotelsOnlyLinkClick() {
        if (!this._currentFilter) {
            this._currentFilter = {};
        }
        this._currentFilter.showAvailableOnly = true;
        this._isFilteringDone = false;
        this.Search._isSortingDone = false;
        $('#no-property-message-section').hide()
            .removeClass('no-room-message-wrapper');
        // this.displayLoadingBar(true);
        //Removing scroll event
        $(window).off('scroll');
        this.Search._scrollEnabled = false;
        this.Search._fetchedPropertyItemCount = 0;
        this.Search._renderedPropertyItemCount = 0;
        //Making "Show Available Hotels Only" checkbox checked.
        $('.refine-result-container .availb-hotel input:checkbox').trigger('click');
        this.showAvailableHotelsOnly(this.Search._displaySearchResults);
    }
    showAvailableHotelsOnly(currentPropList) {
        let rateRequired = [];
        rateRequired = this.getRemainingPropertyIds(rateRequired, currentPropList.availability.hostBrand.availability);
        rateRequired = this.getRemainingPropertyIds(rateRequired, currentPropList.availability.altSell.availability);
        if (rateRequired.length > 0) {
            // Call rate service to fetch the data
            let searchResultsRate = new RateFetch(this.Search, rateRequired, this.Search._currentBrand, true, 'REFINING');
            // Fetching rate for property availability
            searchResultsRate.fetchPropAvailability(currentPropList);
        } else {
            this.refineAvailableHotelOnly(currentPropList);
        }
    }
    refineAvailableHotelOnly(currentPropList) {
        currentPropList.availability.hostBrand.availability = this.removeUnavailableHotelFromList(currentPropList.availability.hostBrand.availability);
        currentPropList.availability.altSell.availability = this.removeUnavailableHotelFromList(currentPropList.availability.altSell.availability);
        //
        if ((this._currentFilter.priceMin && this._currentFilter.priceMax) && ((this._defaultFilter.priceMin != this._currentFilter.priceMin) || (this._defaultFilter.priceMax != this._currentFilter.priceMax))) {
            //Apply rate filter
            currentPropList = this.applyAttributeFilter(this._currentFilter.priceMin, this._currentFilter.priceMax, 'rate', 'displayRate', currentPropList);
        }
        this.doFinalThingBeforeDisplayAfterFilter(currentPropList);
    }
    removeUnavailableHotelFromList(brandProps) {
        let index, hotelObj, propObj;
        for (index = 0; index < brandProps.length; index++) {
            propObj = brandProps[index];
            hotelObj = this.Search._propertyRateMap[propObj.hotelId];
            if (($('.wyndham-rewards-checkbox').length > 0 && $('.wyndham-rewards-checkbox').is(':checked')) || (this._currentFilter && this._currentFilter.wrPoints)) {
                if ((propObj && propObj.brand.toUpperCase() == 'WY' && ('ALL|WY'.indexOf(this.Search._currentBrand) >= 0 || !this.Search.isWYPropRateFetchingRequired()) && !hotelObj.fnsAvailable) || (propObj && propObj.brand.toUpperCase() != 'WY' && !hotelObj.fnsAvailable)) {
                    //property is unavailable;
                    brandProps.splice(index, 1);
                    index--;
                    hotelObj = null;
                }
            } else if ((propObj && propObj.brand.toUpperCase() == 'WY' && ('ALL|WY'.indexOf(this.Search._currentBrand) >= 0 || !this.Search.isWYPropRateFetchingRequired()) && !(hotelObj && hotelObj.rate && hotelObj.rate.displayRate > 0)) || (propObj && propObj.brand.toUpperCase() != 'WY' && !(hotelObj && hotelObj.rate && hotelObj.rate.displayRate > 0))) {
                brandProps.splice(index, 1);
                index--;
                hotelObj = null;
            } else if (!hotelObj || !hotelObj.rate) {
                brandProps.splice(index, 1);
                index--;
                hotelObj = null;
            }
            if ((this._currentSortType == 'PLH' || this._currentSortType == 'PHL') && hotelObj && hotelObj.rate && index > -1) {
                brandProps[index].rate = hotelObj.rate;
                brandProps[index].fnsAvailable = hotelObj.fnsAvailable;
                brandProps[index].fnsPoints = hotelObj.fnsPoints;
                brandProps[index].totalFnsPoints = hotelObj.totalFnsPoints;
            }
        }
        return brandProps;
    }
    applyDBFilter(allProps) {
        //Resetting hostbrand properties after Direct Bill filter
        allProps.availability.hostBrand.availability = this.refinePropsOnDBAttribute('propertyAttributes', 'Direct Bill', allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Resetting non-hostbrand properties after Direct Bill filter
        allProps.availability.altSell.availability = this.refinePropsOnDBAttribute('propertyAttributes', 'Direct Bill', allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        return allProps;
    }

    refinePropsOnDBAttribute(attribute1Name, attribute2Name, avlblBrand) {
        let filteredProps = [],
            attributeValue = false;
        for (let index = 0; index < avlblBrand.length; index++) {
            if (avlblBrand[index][attribute1Name] && avlblBrand[index][attribute1Name].length > 0) {
                attributeValue = (avlblBrand[index][attribute1Name].includes(attribute2Name));
                if (attributeValue) {
                    filteredProps.push(avlblBrand[index]);
                }
            }
        }
        return filteredProps;
    }
    setDirectBillRefineCountsAfterFilter(allProps) {
        let countAfterDBFilter = {};
        this.countPropsOnDBFilter(countAfterDBFilter, 'propertyAttributes', 'Direct Bill', 'Direct Bill', allProps.availability.hostBrand.availability);
        this.countPropsOnDBFilter(countAfterDBFilter, 'propertyAttributes', 'Direct Bill', 'Direct Bill', allProps.availability.altSell.availability);
        this.populateCountInDBFilterSection(countAfterDBFilter, '.check-direct-bill input:checkbox');
    }
    setAvailabilityRefineCountsAfterFilter(allProps) {
        let countAfterAvailabilityFilter = {};
        this.countPropsOnAvailabilty(countAfterAvailabilityFilter, 'Availability', allProps.availability.hostBrand.availability);
        this.countPropsOnAvailabilty(countAfterAvailabilityFilter, 'Availability', allProps.availability.altSell.availability);
        if (this._currentFilter.price && (this._currentFilter.priceMin && this._currentFilter.priceMax) && ((this._defaultFilter.priceMin != this._currentFilter.priceMin) || (this._defaultFilter.priceMax != this._currentFilter.priceMax))) {
            allProps = this.applyAttributeFilter(this._currentFilter.priceMin, this._currentFilter.priceMax, 'rate', 'displayRate', allProps);
        }
        this.populateCountInAvailabilityFilterSection(countAfterAvailabilityFilter, '.availb-hotel input:checkbox');
    }
    countPropsOnAvailabilty(countAfterAvailabilityFilter, filterName, brandProps) {
        let index, hotelObj, propObj;
        if (filterName.length > 0 && (this.Search._propertyRateMap != null)) {
            for (index = 0; index < brandProps.length; index++) {
                propObj = brandProps[index];
                if (this.Search._propertyRateMap != null && !($.isEmptyObject(this.Search._propertyRateMap))) {
                    hotelObj = this.Search._propertyRateMap[propObj.hotelId];
                    if (($('.wyndham-rewards-checkbox').length > 0 && $('.wyndham-rewards-checkbox').is(':checked')) || (this._currentFilter && this._currentFilter.wrPoints)) {
                        if ((propObj && propObj.brand.toUpperCase() == 'WY' && ('ALL|WY'.indexOf(this.Search._currentBrand) >= 0 || !this.Search.isWYPropRateFetchingRequired()) && hotelObj.fnsAvailable) || (propObj && propObj.brand.toUpperCase() != 'WY' && hotelObj.fnsAvailable)) {
                            //property is unavailable for rewards
                            if (countAfterAvailabilityFilter.hasOwnProperty(filterName)) {
                                countAfterAvailabilityFilter[filterName] = countAfterAvailabilityFilter[filterName] + 1;
                            } else {
                                countAfterAvailabilityFilter[filterName] = 1;
                            }
                        }
                    } else if ((propObj && propObj.brand.toUpperCase() == 'WY' && ('ALL|WY'.indexOf(this.Search._currentBrand) >= 0 || !this.Search.isWYPropRateFetchingRequired()) && (hotelObj && hotelObj.rate && hotelObj.rate.displayRate > 0)) || (propObj && propObj.brand.toUpperCase() != 'WY' && (hotelObj && hotelObj.rate && hotelObj.rate.displayRate > 0))) {
                        //property is unavailable for price
                        if (countAfterAvailabilityFilter.hasOwnProperty(filterName)) {
                            countAfterAvailabilityFilter[filterName] = countAfterAvailabilityFilter[filterName] + 1;
                        } else {
                            countAfterAvailabilityFilter[filterName] = 1;
                        }
                    }
                }
            }
        }
    }
    countPropsOnDBFilter(countAfterDBFilter, filterProperty1Name, filterProperty2Name, filterName, avlblBrand) {
        let counter, filterValue;
        if (filterName.length > 0) {
            for (counter = 0; counter < avlblBrand.length; counter++) {
                if (avlblBrand[counter][filterProperty1Name] && ((avlblBrand[counter][filterProperty1Name].length > 0) || (avlblBrand[counter][filterProperty1Name].toString().length > 0))) {
                    if (filterProperty1Name == 'rate') {
                        filterValue = (avlblBrand[counter][filterProperty1Name][filterProperty2Name] && avlblBrand[counter][filterProperty1Name][filterProperty2Name] > -1);
                    } else {
                        filterValue = avlblBrand[counter][filterProperty1Name].includes(filterProperty2Name);
                    }
                    if (filterValue) {
                        if (countAfterDBFilter.hasOwnProperty(filterName)) {
                            countAfterDBFilter[filterName] = countAfterDBFilter[filterName] + 1;
                        } else {
                            countAfterDBFilter[filterName] = 1;
                        }
                    }
                }
            }
        }
    }
    populateCountInDBFilterSection(countAfterDBFilter, filterElement) {
        let filterEle;
        filterEle = $(filterElement);
        if (countAfterDBFilter.hasOwnProperty('Direct Bill')) {
            if (filterEle.is(':checked')) {
                filterEle.removeAttr('disabled').parent('label')
                    .find('span.filterCount')
                    .text('');
            } else {
                filterEle.parent('label')
                    .find('span.filterCount')
                    .html('&nbsp;(' + countAfterDBFilter['Direct Bill'] + ')');
                if ($('.wyndham-rewards-checkbox').length > 0 && $('.wyndham-rewards-checkbox').is(':checked')) {
                    filterEle.attr('disabled', 'disabled').parent('label')
                        .find('span.filterCount')
                        .text('');
                    filterEle.parent().css('opacity', '0.6');
                } else {
                    filterEle.removeAttr('disabled');
                    filterEle.parent().css('opacity', '1.0');
                }
            }
        } else {
            //Either disable the checkbox or put count 0
            if (filterEle.is(':checked')) {
                filterEle.parent().css('opacity', '1.0');
                filterEle.removeAttr('disabled').parent('label')
                    .find('span.filterCount')
                    .text('');
            } else {
                filterEle.attr('disabled', 'disabled').parent('label')
                    .find('span.filterCount')
                    .html('&nbsp;(0)');
                filterEle.parent().css('opacity', '0.6');
            }
        }
    }
    populateCountInAvailabilityFilterSection(countAfterAvailabilityFilter, filterElement) {
        let filterEle;
        filterEle = $(filterElement);
        if (countAfterAvailabilityFilter.hasOwnProperty('Availability')) {
            if (filterEle.is(':checked')) {
                filterEle.removeAttr('disabled').parent('label')
                    .find('span.filterCount')
                    .text('');
            } else {
                filterEle.removeAttr('disabled').parent('label')
                    .find('span.filterCount')
                    .html('&nbsp;(' + countAfterAvailabilityFilter['Availability'] + ')');
            }
            filterEle.parent().css('opacity', '1.0');
        } else {
            //Either disable the checkbox or put count 0
            if (filterEle.is(':checked')) {
                filterEle.parent().css('opacity', '1.0');
                filterEle.removeAttr('disabled').parent('label')
                    .find('span.filterCount')
                    .text('');
            } else {
                filterEle.attr('disabled', 'disabled').parent('label')
                    .find('span.filterCount')
                    .html('&nbsp;(0)');
                filterEle.parent().css('opacity', '0.6');
            }
        }
    }
    getRemainingPropertyIds(rateRequired, propIdsForRate) {
        let hotelId, propId, brand;
        for (let index = 0; index < propIdsForRate.length; index++) {
            hotelId = propIdsForRate[index].hotelId;
            brand = this.Search._globalPropertyMap[hotelId].brand.toUpperCase();
            if (!this.Search._propertyRateMap || !this.Search._propertyRateMap.hasOwnProperty(hotelId) || !propIdsForRate[index].rate) {
                propId = brand + hotelId;
                if (brand == 'WY') {
                    propId += '|' + brand + this.Search._globalPropertyMap[hotelId].hotelCode;
                }
                rateRequired.push(propId);
            }
        }
        return rateRequired;
    }
    applyAttributeFilter(minValue, maxValue, attribute1Name, attribute2Name, allProps) {
        //Resetting hostbrand properties after Rating filter
        allProps.availability.hostBrand.availability = this.refinePropsOnAttribute(parseFloat(minValue), parseFloat(maxValue), attribute1Name, attribute2Name, allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Resetting non-hostbrand properties after Rating filter
        allProps.availability.altSell.availability = this.refinePropsOnAttribute(parseFloat(minValue), parseFloat(maxValue), attribute1Name, attribute2Name, allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        return allProps;
    }
    refinePropsOnAttribute(minValue, maxValue, attribute1Name, attribute2Name, avlblBrand) {
        let filteredProps = [],
            attributeValue = 0;
        for (let index = 0; index < avlblBrand.length; index++) {
            if (attribute2Name != null) {
                attributeValue = parseFloat(avlblBrand[index][attribute1Name][attribute2Name]);
            } else {
                attributeValue = parseFloat(avlblBrand[index][attribute1Name]);
            }
            if (attributeValue >= minValue && attributeValue <= maxValue) {
                filteredProps.push(avlblBrand[index]);
            }
        }
        return filteredProps;
    }
    applyDistanceFilter(distanceFilter, allProps) {
        let currProps = $.extend(true, {}, allProps);
        //Resetting host-brand properties after distance filter
        allProps.availability.hostBrand.availability = this.refinePropsOnDistance(distanceFilter, allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Resetting altSell properties after distance filter
        allProps.availability.altSell.availability = this.refinePropsOnDistance(distanceFilter, allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        if ((allProps.availability.hostBrand.availabilityCount + allProps.availability.altSell.availabilityCount) == 0) {
            //Need to reset
            $('#refineDistance').text(distanceFilter);
            $('#refineLocation').text($('#searchStateReadOnly').text());
            $('#no-property-message-section').show()
                .addClass('no-room-message-wrapper');
            allProps = currProps;
            $('#distance input').val(this._defaultFilter.distanceMin);
            this._currentFilter.distanceMin = this._defaultFilter.distanceMin;
        }
        return allProps;
    }
    refinePropsOnDistance(distanceFilter, avlblBrand) {
        let filteredProps = [];
        for (let index = 0; index < avlblBrand.length; index++) {
            if (parseFloat(avlblBrand[index].distance) <= parseFloat(distanceFilter)) {
                filteredProps.push(avlblBrand[index]);
            }
        }
        return filteredProps;
    }
    applyBrandFilter(brandsFilter, allProps) {
        //Resetting hostbrand properties after Rating filter
        allProps.availability.hostBrand.availability = this.refinePropsOnBrands(brandsFilter, allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Resetting non-hostbrand properties after Rating filter
        allProps.availability.altSell.availability = this.refinePropsOnBrands(brandsFilter, allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        return allProps;
    }
    refinePropsOnBrands(brandsFilter, avlblBrand) {
        let filteredProps = [];
        for (let index = 0; index < avlblBrand.length; index++) {
            if (brandsFilter.indexOf(avlblBrand[index].brand.toUpperCase()) >= 0 ||
                (avlblBrand[index].brandTier && brandsFilter.indexOf(avlblBrand[index].brandTier.toUpperCase()) >= 0)) {
                filteredProps.push(avlblBrand[index]);
            }
        }
        return filteredProps;
    }
    applyAmenityFilter(amenityFilter, allProps) {
        //Resetting hostbrand properties after Rating filter
        allProps.availability.hostBrand.availability = this.refinePropsOnAmenity(amenityFilter, allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Resetting non-hostbrand properties after Rating filter
        allProps.availability.altSell.availability = this.refinePropsOnAmenity(amenityFilter, allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        return allProps;
    }
    applyShowOnlyAmenityFilter(filterAmenityFilter, allProps) {
        //Filtering hostBand properties for Show Only Amenities selected
        allProps.availability.hostBrand.availability = this.refinePropsOnShowOnlyAmenity(filterAmenityFilter, allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Filtering non-hostBand properties for Show Only Amenities selected
        allProps.availability.altSell.availability = this.refinePropsOnShowOnlyAmenity(filterAmenityFilter, allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        return allProps;
    }
    refinePropsOnAmenity(amenityFilter, avlblBrand) {
        let filteredProps = [];
        for (let index = 0; index < avlblBrand.length; index++) {
            if ($(amenityFilter).filter(avlblBrand[index].amenities).length == amenityFilter.length) {
                filteredProps.push(avlblBrand[index]);
            }
        }
        return filteredProps;
    }
    refinePropsOnShowOnlyAmenity(amenityFilter, avlblBrand) {
        let filteredProps = [];
        for (let index = 0; index < avlblBrand.length; index++) {
            if ($(amenityFilter).filter(avlblBrand[index].filterAmenities).length == amenityFilter.length) {
                filteredProps.push(avlblBrand[index]);
            }
        }
        return filteredProps;
    }
    applyTierFilter(tierFilter, allProps) {
        //Resetting hostbrand properties after Rating filter
        allProps.availability.hostBrand.availability = this.refinePropsOnTier(tierFilter, allProps.availability.hostBrand.availability);
        allProps.availability.hostBrand.availabilityCount = allProps.availability.hostBrand.availability.length;
        //Resetting non-hostbrand properties after Rating filter
        allProps.availability.altSell.availability = this.refinePropsOnTier(tierFilter, allProps.availability.altSell.availability);
        allProps.availability.altSell.availabilityCount = allProps.availability.altSell.availability.length;
        return allProps;
    }
    refinePropsOnTier(tierFilter, avlblBrand) {
        let filteredProps = [];
        for (let index = 0; index < avlblBrand.length; index++) {
            if(tierFilter.indexOf(avlblBrand[index].tierNum) >= 0) {
                filteredProps.push(avlblBrand[index]);
            }
        }
        return filteredProps;
    }
    setBrandRefineCountsAfterFilter(allProps) {
        let brands = [];
        $('.brands input:checkbox').each((i, el) => {
            brands.push($(el).val());
        });
        let countAfterBrandFilter = {};
        this.countPropsOnFilter(countAfterBrandFilter, 'brand', brands, allProps.availability.hostBrand.availability);
        this.countPropsOnFilter(countAfterBrandFilter, 'brand', brands, allProps.availability.altSell.availability);
        this.populateCountInFilterSection(brands, countAfterBrandFilter, '.brands input:checkbox');
    }
    setAmenityRefineCountsAfterFilter(allProps) {
        let amenities = [];
        $('.amenitiesCheckbox input:checkbox').each((i, el) => {
            amenities.push($(el).val());
        });
        let countAfterAmenityFilter = {};
        this.countPropsOnFilter(countAfterAmenityFilter, 'amenities', amenities, allProps.availability.hostBrand.availability);
        this.countPropsOnFilter(countAfterAmenityFilter, 'amenities', amenities, allProps.availability.altSell.availability);
        this.populateCountInFilterSection(amenities, countAfterAmenityFilter, '.amenitiesCheckbox input:checkbox');
        this.disableFilters();
    }

    setShowOnlyAmenityRefineCountsAfterFilter(allProps) {
        let showOnlyAmenities = [];
        $('.show-only-amenities input:checkbox').each((i, el) => {
            showOnlyAmenities.push($(el).val());
        });
        let countAfterAmenityFilter = {};
        this.countPropsOnFilter(countAfterAmenityFilter, 'filterAmenities', showOnlyAmenities, allProps.availability.hostBrand.availability);
        this.countPropsOnFilter(countAfterAmenityFilter, 'filterAmenities', showOnlyAmenities, allProps.availability.altSell.availability);
        this.populateCountInFilterSection(showOnlyAmenities, countAfterAmenityFilter, '.show-only-amenities input:checkbox');
        if (!this.Search._initialLoad && this.Search._iterationCount == 0) {
        this.disableFilters();
    }
    }
    /**
     * This method is used to calculate the property count for each filter.
     *
     * countAfterFilter - objects where filter counts are set
     * filterPropertyName - name of the 'property/attribute' are used for calculating the count
     * filterList - list of refine filters
     * avlblBrand - list of array properties for retrieve al counts
     */
    countPropsOnFilter(countAfterFilter, filterPropertyName, filterList, avlblBrand) {
        let index, counter, filterName, filterValue;
        for (index = 0; index < filterList.length; index++) {
            filterName = filterList[index];
            for (counter = 0; counter < avlblBrand.length; counter++) {
                filterValue = avlblBrand[counter][filterPropertyName];
                if (filterValue && ((filterValue.indexOf(filterName) >= 0) || (filterValue.indexOf(filterName.toUpperCase()) >= 0))) {
                    if (countAfterFilter.hasOwnProperty(filterName)) {
                        countAfterFilter[filterName] = countAfterFilter[filterName] + 1;
                    } else {
                        countAfterFilter[filterName] = 1;
                    }
                }

                if (filterPropertyName === 'brand' && avlblBrand[counter]['brandTier']) {
                    filterValue = avlblBrand[counter]['brandTier'];
                    if (filterValue && ((filterValue.indexOf(filterName) >= 0) || (filterValue.indexOf(filterName.toUpperCase()) >= 0))) {
                        if (countAfterFilter.hasOwnProperty(filterName)) {
                            countAfterFilter[filterName] = countAfterFilter[filterName] + 1;
                        } else {
                            countAfterFilter[filterName] = 1;
                        }
                    }
                }
            }
        }
    }
    /**
     * This method is used to populate property count for each filter.
     *
     * filterList - list of refine filters
     * countAfterFilter - objects where filter counts are set
     * propIdSelector - element selector for DOM
     */
    populateCountInFilterSection(filterList, countAfterFilter, filterElementSelector) {
        let index, filterName, filterElement;
        for (index = 0; index < filterList.length; index++) {
            filterName = filterList[index];
            filterElement = $(filterElementSelector + '[value="' + filterName + '"]');
            if (countAfterFilter.hasOwnProperty(filterName)) {
                if (filterElement.is(':checked')) {
                    filterElement.removeAttr('disabled').parent('label')
                        .find('span.filterCount')
                        .text('');
                } else {
                    filterElement.removeAttr('disabled').parent('label')
                        .find('span.filterCount')
                        .text('(' + countAfterFilter[filterName] + ')');
                }
                filterElement.parent().css('opacity', '1.0');
            } else {
                //Either disable the checkbox or put count 0
                if (filterElement.is(':checked')) {
                    filterElement.parent().css('opacity', '1.0');
                    filterElement.removeAttr('disabled').parent('label')
                        .find('span.filterCount')
                        .text('');
                } else {
                    filterElement.attr('disabled', 'disabled').parent('label')
                        .find('span.filterCount')
                        .text('(0)');
                    filterElement.parent().css('opacity', '0.6');
                }
            }
        }
    }
    applyDefaultFilters(distanceChanged, propJSONResp) {
        // if any default filters are present, apply filters. Else proceed as usual
        if ((this._defaultFilter.redeemMin != '' || this._defaultFilter.redeemMax != '' || this._defaultFilter.priceMin > 0 || this._defaultFilter.priceMax < 500 || this._defaultFilter.amenities.length > 0 || this._defaultFilter.showOnlyAmenities.length > 0 || this._defaultFilter.brands.length > 0 || distanceChanged) && !this._defaultFilterApplied) {
            this._defaultFilterApplied = true;
            this._currentFilter = this._defaultFilter;
            //update rewards filter
            if ( ($('.wyndham-rewards-checkbox').is(':checked')) || (this.Search._searchCriteria && (this.Search._searchCriteria.useWRPoints == true || this.Search._searchCriteria.useWRPoints == 'true')) ) {
                this._currentFilter.wrPoints = true;
                this._currentFilter.price = false;
            }
            this.proceedWithRestOfTheFilter(this.Search._displaySearchResults);
        } else if (!distanceChanged) {
            this.Search._isDataAvailable = true;
            let paramValue = getQueryParameter('rrAlt');
            if (paramValue && (paramValue == true || paramValue == 'true')) {
                // Need to display noRoomsAvailable message.
                $('#no-rooms-message-section').show();
                // Display All available hotels
                this.showAvailableHotelsOnlyLinkClick();
            } else {
                paramValue = getQueryParameter('df');
                if (paramValue == 0 || paramValue == '0') {
                    // Need to display de-flagged property message.
                    $('#deflagged-property-err-msg-sec').show();
                }
                this.Search.updateResultsCountContainer(propJSONResp.availability.hostBrand.totalPropertyCount);
                //this.setRefineCountsAfterFilter(propJSONResp);
                this.setBrandRefineCountsAfterFilter(propJSONResp);
                this.setAmenityRefineCountsAfterFilter(propJSONResp);
                this.setShowOnlyAmenityRefineCountsAfterFilter(propJSONResp);
                this.setAvailabilityRefineCountsAfterFilter(propJSONResp);
                this.setDirectBillRefineCountsAfterFilter(propJSONResp);
                this.Search.displayNextSetOfProperty();
            }
        } else {
            this.proceedWithRestOfTheFilter(this.Search._displaySearchResults);
        }
    }


    // ---------- SEARCH SORTING -------------
    bindSortingField() {
        $('.sort-by-wrapper select, .mobile-toggle-view select').change((e) => {
            //Hide altSell message if displayed already
            $('#no-rooms-message-section').hide();
            $('#no-property-message-section').hide()
                .removeClass('no-room-message-wrapper');

            let sortBy = $(e.currentTarget).val();
            $('.mobile-toggle-view select').prop('selectedIndex', 0);
            Analytics.updateSearchSortAnalytics($('.sort-by-wrapper select option:selected').text());
            Analytics.satelliteTracker('sort_by');

            this._isFilteringDone = false;
            this._isSortingDone = false;
            this._isRearrangeRequired = false;
            this._currentSortType = sortBy;
            this.Search._isLoadingDone = false;
            this.Search._nonHostBrandIndex = 0;
            this.Search._hostBrandIndex = 0;
            this.Search._hostBrandSet = false;
            this.Search._dividerDivRendered = false;
            // Making ListView section is active
            $('.nav-tabs [aria-controls="list"]').trigger('click');
            //Removing scroll event
            $(window).off('scroll');
            this.Search._scrollEnabled = false;
            this._fetchedPropertyItemCount = 0;
            this._renderedPropertyItemCount = 0;
            if (sortBy == 'PLH' || sortBy == 'PHL') {
                this.Search.fetchRatesForRemainingProperty('SORTING', null);
            } else {
                this.applyCurrentSorting(null);
            }
        });
    }

    /**
     * This method is used to modify the 'Sort By' select option based on the "Redeem Wyndham Rewards Points" selection.
     *
     */
    changeSortByOption(searchCriteria) {
        if (searchCriteria && (searchCriteria.useWRPoints == true || searchCriteria.useWRPoints == 'true')) {
            $('.sort-by-wrapper select option[value="PHL"]').text('Points - High to Low');
            $('.sort-by-wrapper select option[value="PHL"]').val('RPHL');
            $('.sort-by-wrapper li[rel="PHL"]').attr('rel', 'RPHL')
                .find('a')
                .html('Points - High to Low');
            $('.sort-by-wrapper select option[value="PLH"]').text('Points - Low to High');
            $('.sort-by-wrapper select option[value="PLH"]').val('RPLH');
            $('.sort-by-wrapper li[rel="PLH"]').attr('rel', 'RPLH')
                .find('a')
                .html('Points - Low to High');
            $('#price').data('disabled', 1);
        } else {
            $('#price').data('disabled', 0);
        }
    }

    aggregateAllRateAndSort(rateUsageFor, currentPropertyList) {
        if (rateUsageFor == 'SORTING') {
            if (this._currentSortType == 'PLH' || this._currentSortType == 'PHL') {
                this.Search._displaySearchResults = this.applyPriceSorting(currentPropertyList);
            }
            this.applyCurrentSorting(null);
        } else if (rateUsageFor == 'REFINING') {
            this.refineAvailableHotelOnly(currentPropertyList);
        }
    }
    refineAllPropertiesOnFilter(rateUsageFor, currentPropertyList) {
        if (rateUsageFor == 'REFINING') {
            this.proceedWithRestOfTheFilter(currentPropertyList);
        }
    }

    applyCurrentSorting(currentPropList) {

        $('#list-view').empty();
        if (this._currentSortType == 'REL') {
            this._isRearrangeRequired = true;
        } else {
            this._isRearrangeRequired = false;
        }
        if (this._currentSortType == 'RAT') {
            this.Search._displaySearchResults = currentPropList ? currentPropList : $.extend(true, {}, this.Search._actualSearchResults);
            this.Search._displaySearchResults.availability.hostBrand.availability.sort(this.ratingSort);
            this.Search._displaySearchResults.availability.altSell.availability.sort(this.ratingSort);
        } else if (this._currentSortType == 'DIS' || this._currentSortType == 'REL') {
            let searchCriteria = getCriteria();
            let checkIn = formatDateForBWSAvailability(searchCriteria.checkInDate);
            let today = formatDateForBWSAvailability(getToday());
            this.Search._displaySearchResults = currentPropList ? currentPropList : $.extend(true, {}, this.Search._actualSearchResults);
            if(checkIn === today){
                this.Search._displaySearchResults.availability.hostBrand.availability.sort(this.distanceTodaySort);
                this.Search._displaySearchResults.availability.altSell.availability.sort(this.distanceTodaySort);
            } else {
                this.Search._displaySearchResults.availability.hostBrand.availability.sort(this.distanceSort);
                this.Search._displaySearchResults.availability.altSell.availability.sort(this.distanceSort);
            }
        } else if ((this.Search._searchCriteria.useWRPoints == true || this.Search._searchCriteria.useWRPoints == 'true') && this._currentSortType == 'RPHL') {
            this.Search._displaySearchResults.availability.hostBrand.availability.sort(this.pointsHighToLow);
            this.Search._displaySearchResults.availability.altSell.availability.sort(this.pointsHighToLow);
        } else if ((this.Search._searchCriteria.useWRPoints == true || this.Search._searchCriteria.useWRPoints == 'true') && this._currentSortType == 'RPLH') {
            this.Search._displaySearchResults.availability.hostBrand.availability.sort(this.pointsLowToHigh);
            this.Search._displaySearchResults.availability.altSell.availability.sort(this.pointsLowToHigh);
        }
        if (!$('#map-view').is(':visible')) {
            $('#list-view').show();
        }

        if (this._isFilteringDone) {
            if (currentPropList) {
                this.Search_displaySearchResults = currentPropList;
            }
            this.Search.initializeForDisplayProperties();
        } else {
            this.proceedWithRestOfTheFilter(this.Search._displaySearchResults);
        }
        this._isSortingDone = true;
    }

    applyPriceSorting(currentPropertyList) {
        if (this._currentSortType == 'PLH') {
            currentPropertyList.availability.hostBrand.availability = currentPropertyList.availability.hostBrand.availability.sort(this.priceSortLTH);
            currentPropertyList.availability.altSell.availability = currentPropertyList.availability.altSell.availability.sort(this.priceSortLTH);
        } else if (this._currentSortType == 'PHL') {
            currentPropertyList.availability.hostBrand.availability.sort(this.priceSortHTL);
            currentPropertyList.availability.altSell.availability.sort(this.priceSortHTL);
        }
        return currentPropertyList;
    }

    /**
     * Rearrange the property before 'Unavailable' property
     */
    rearrangePropertyOrder(propId, brand) {
        let propSummary, currProp, prop = $('#summary-' + propId);
        if (this.Search._currentBrand == brand.toUpperCase()) { // Host brand
            propSummary = $('#emptyDiv .propSummary');
        } else { // Non-host brand & //WyndhamHotelGroup
            propSummary = $('#nonhost-brand .propSummary');
        }
        let endIndex = propSummary.index(prop);
        if (endIndex > 0) {
            for (let index = 0; index < endIndex; index++) {
                currProp = $(propSummary[index]);
                if (currProp.find('.unavailable').length > 0) {
                    prop.remove();
                    prop.find('script').remove();

                    prop.insertBefore(currProp);
                    break;
                }
            }
        }
    }

    distanceSort(a, b) {
        let aDistance = parseFloat(a.distance),
            bDistance = parseFloat(b.distance);
        return (aDistance - bDistance);
    }
    distanceTodaySort(a, b) {
        if (!a || !b) {
            return 0;
        }

        const aDistance = parseFloat(a.distance || 0),
            bDistance = parseFloat(b.distance || 0);

        let aHasAvailability = a.rate && a.rate.strikethroughRate && a.rate.strikethroughRate > 0,
            bHasAvailability = b.rate && b.rate.strikethroughRate && b.rate.strikethroughRate > 0;

        if (!a.rate || !a.rate.cugRate || UserHandler.isWRLoggedIn()) {
            aHasAvailability = a.rate && a.rate.displayRate && a.rate.displayRate > 0;
        }

        if (!b.rate || !b.rate.cugRate || UserHandler.isWRLoggedIn()) {
            bHasAvailability = b.rate && b.rate.displayRate && b.rate.displayRate > 0;
        }

        if (!aHasAvailability && bHasAvailability) {
            return 1;
        }

        if (aHasAvailability && !bHasAvailability) {
            return -1;
        }

        if (aDistance === bDistance) {
            const aHotelName = a.hotelName || '';
            const bHotelName = b.hotelName || '';
            return aHotelName.localeCompare(bHotelName, _LOCALE_, {
                sensitivity: 'base',
                ignorePunctuation: true,
                numeric: true
            });
        }

        return (aDistance - bDistance);
    }

    ratingSort(a, b) {
        let aRating = parseFloat(a.rating),
            bRating = parseFloat(b.rating);
        aRating = isNaN(aRating) ? 5 : aRating;
        bRating = isNaN(bRating) ? 5 : bRating;
        return (bRating - aRating);
    }
    pointsHighToLow(a, b) {
        let aPoints = parseFloat(a.tierPoints),
            bPoints = parseFloat(b.tierPoints);
        return (bPoints - aPoints);
    }
    pointsLowToHigh(a, b) {
        let aPoints = parseFloat(a.tierPoints),
            bPoints = parseFloat(b.tierPoints);
        return (aPoints - bPoints);
    }
    priceSortHTL(a, b) {
        let aRate,
            bRate;
        //use strike through rate for sorting if cugRate is true or user is logged in
        if (!a.rate || !a.rate.cugRate || UserHandler.isWRLoggedIn()) {
            if (a.rate && a.rate.displayRate && a.rate.displayRate > 0) {
                aRate = parseFloat(a.rate.displayRate);
            } else {
                aRate = 0;
            }
        } else {
            if (a.rate && a.rate.strikethroughRate && a.rate.strikethroughRate > 0) {
                aRate = parseFloat(a.rate.strikethroughRate);
            } else {
                aRate = 0;
            }
        }

        if (!b.rate || !b.rate.cugRate || UserHandler.isWRLoggedIn()) {
            if (b.rate && b.rate.displayRate && b.rate.displayRate > 0) {
                bRate = parseFloat(b.rate.displayRate);
            } else {
                bRate = 0;
            }
        } else {
            if (b.rate && b.rate.strikethroughRate && b.rate.strikethroughRate > 0) {
                bRate = parseFloat(b.rate.strikethroughRate);
            } else {
                bRate = 0;
            }
        }
        return (bRate - aRate);
    }
    priceSortLTH(a, b) {
        let aRate,
            bRate;
        //use strike through rate for sorting if cugRate is true or user is logged in
        if (!a.rate || !a.rate.cugRate || UserHandler.isWRLoggedIn()) {
            if (a.rate && a.rate.displayRate && a.rate.displayRate > 0) {
                aRate = parseFloat(a.rate.displayRate);
            } else {
                aRate = 0;
            }
        } else {
            if (a.rate && a.rate.strikethroughRate && a.rate.strikethroughRate > 0) {
                aRate = parseFloat(a.rate.strikethroughRate);
            } else {
                aRate = 0;
            }
        }

        if (!b.rate || !b.rate.cugRate || UserHandler.isWRLoggedIn()) {
            if (b.rate && b.rate.displayRate && b.rate.displayRate > 0) {
                bRate = parseFloat(b.rate.displayRate);
            } else {
                bRate = 0;
            }
        } else {
            if (b.rate && b.rate.strikethroughRate && b.rate.strikethroughRate > 0) {
                bRate = parseFloat(b.rate.strikethroughRate);
            } else {
                bRate = 0;
            }
        }

        if (aRate === 0 && bRate !== 0) {
            return 1;
        }
        if (aRate !== 0 && bRate === 0) {
            return -1;
        }
        return (aRate - bRate);
    }
    distanceFilterCleanup() {
        let milesDistanceFilter = $('#miles-distance'),
            kmDistanceFilter = $('#km-distance');

        if (milesDistanceFilter.length && kmDistanceFilter.length) {
            if (this.Search._isMilesLocation) {
                kmDistanceFilter.remove();
                milesDistanceFilter.removeClass('hidden');
            } else {
                milesDistanceFilter.remove();
                kmDistanceFilter.removeClass('hidden');
            }
        } else {
            if (!milesDistanceFilter.length) {
                console.log('#miles-distance filter is expected to be authored.');
            }
            if (!kmDistanceFilter.length) {
                console.log('#km-distance filter is expected to be authored.');
            }
        }
    }
    init() {
        this.distanceFilterCleanup();
    }
}
