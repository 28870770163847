import {
  getBrand,
  getBrandTier
} from '../base/session-handler.js';
import {
  exists,
  getWindowHeight,
  isMobileWidth
} from '../base/dom-utils.js';
import Dropdown from './dropdown.js';
import {
  _isNotNull,
  _isNull,
  getQueryString,
  updateQueryParameter,
  CookieHandler,
  callService,
  EventHandler
} from '../base/utils.js';
import {
  _LOCALE_
} from '../base/vars.js';

class MobilePropertyList {
  constructor() {
    this.$destinationDropdown = $('.mobile-property-list .destination-dropdown');
    this.$destinationDropdownContent = this.$destinationDropdown.find('.dropdown-content');
    this.$destinationDropdownButton = $('#mobilePropertyTrigger');

    this.destinationDropdown = {};
    this.destinationValue = null;

    this.allowedRegex = /^[a-zA-Z0-9;\s.\'&/\\-]+$/;

    this._isReady = false;

    if (exists($('#mobilePropList')) && exists($('.mobile-property-list-trigger')) && isMobileWidth()) {
      this.init();

      this.bindPropListTriggerClick();
    }
  }

  init() {
    EventHandler
      .one(EventHandler.criteria.init, () => this.buildFromSessionData())
      .on(EventHandler.criteria.updated, () => this.buildFromSessionData());
  }

  buildFromSessionData() {
    if (this._isReady === false) {

      if (exists(this.$destinationDropdownContent)) {
        this.initializeDestinationDropdown();
      }

      if (getBrand() === 'DX' && (CookieHandler.readCookie('dolceOrientationChange') === 'dolce-Orientation-Change') && $('body').is('.ios,.iphone,.ipad,.ipod,.android')) {
        CookieHandler.eraseCookie('dolceOrientationChange');
      }

      this._isReady = true;
    }
  }

  initializeDestinationDropdown() {
    callService('brandPropertyPageListing', {
      locale: _LOCALE_,
      brandId: getBrand()
    }).then(() => this.handleDestinationDropdown());
  }


  handleDestinationDropdown(r) {
    for (let i = 0; i < r.subcategory.length; i++) {
      let country = r.subcategory[i].name,

        properties = r.subcategory[i].properties,
        hasProperties = (_isNotNull(properties) && properties.length),

        states = r.subcategory[i].subcategory,
        hasStates = (_isNotNull(states) && states.length),

        $c = $(document.createElement('div')).addClass('destination-dropdown-country headline-g')
          .text(country);

      this.$destinationDropdownContent.append($c);

      if (hasProperties) {
        this.getProperties(properties);
      } else if (hasStates) {
        for (let j = 0; j < states.length; j++) {
          let state = states[j];

          if (_isNotNull(state.properties) && state.properties.length) {
            let $s = $(document.createElement('div')).addClass('destination-dropdown-state')
              .text(state.name);

            this.$destinationDropdownContent.append($s);

            this.getProperties(state.properties);
          }
        }
      }
    }

    let contentHeight = this.$destinationDropdown.find('.dropdown-overflow').outerHeight(),
      viewportHeight = getWindowHeight() - this.$destinationDropdown.find('.save-cancel-container').outerHeight();

    this.$destinationDropdown.find('.dropdown-overflow').css({
      top: (0 - contentHeight)
    });

    if (contentHeight > viewportHeight) {
      this.$destinationDropdownButton.on('dropdown:opening', () => {
        this.$destinationDropdown.find('.dropdown-overflow').css({
          maxHeight: viewportHeight,
          overflowY: 'scroll'
        });
      });
    }


    this.$destinationDropdown.find('.btn-cancel').click(() => {
      Dropdown.publicCloseDropdown();
      $('#mobilePropList').attr('tabindex', '-1');
      $('#mobilePropList').attr('aria-expanded', 'false');
      $('#mobilePropertyTrigger').attr('aria-expanded', 'false');
      $('.mobile-property-list-trigger').attr('aria-expanded', 'false');
    });

    this.$destinationDropdown.find('.search-all-hotels-link').click((e) => {
      e.preventDefault();

      updateQueryParameter('referringBrand', getBrand(true));
      updateQueryParameter('referringTier', getBrandTier());

      window.location.replace($(e.currentTarget).attr('href') + '?' + getQueryString());
    });
  }

  getProperties(propArray, $el) {
    if (_isNull($el)) {
      $el = this.$destinationDropdownContent;
    }

    for (let j = 0; j < propArray.length; j++) {
      let prop = propArray[j];

      if (_isNotNull(prop.path)) {
        let $a = $(document.createElement('a'))
          .addClass('destination-dropdown-property')
          .text(prop.name)
          .attr('href', prop.path)
          .attr('data-name', prop.name)
          .attr('data-city', prop.city)
          .attr('data-state', prop.state);

        $el.append($a);
      }
    }

  }

  bindPropListTriggerClick() {
    $('.mobile-property-list-trigger').on('click', (e) => {
      if ($(e.currentTarget).attr('data-dropdown') === '#mobilePropList') {
        e.stopPropagation();
        let $mobilePropList = $('#mobilePropList'),
          $mobilePropTrigger = $('#mobilePropertyTrigger');

        $mobilePropTrigger.click();
        $mobilePropList.attr('tabindex', 0).focus();
        $mobilePropList.attr('aria-expanded', 'true');
        $mobilePropTrigger.attr('aria-expanded', 'true');
        $(e.currentTarget).attr('aria-expanded', 'true');
      }
    });
  }
}

export default new MobilePropertyList();
