import 'regenerator-runtime/runtime';
import {
  _isNotNull,
  _isNull,
  extractDataFromObject,
  formatDateForBWS,
  getToday,
  getTomorrow,
  isLoyaltyRP,
  uppercaseFirstLetterEachWord,
  getDefaultCriteria,
  populateObjectFromQueryString,
  updateQueryParameter,
  getQueryParameter,
  _isValidBrand,
  CookieHandler,
  _isWeakFalse,
  _isWeakTrue,
  getSecureHostedDomainUrl,
  getLocaleUrlToken,
  formatDateForPrinting,
  formatNumber,
  formatTimeShow,
  isGoFreeRate,
  getDateFromDateString,
  handlePostSubtotal,
  _isNumber,
  getCurrencyMapping,
  getNumDays,
  removeDiacritics,
  getServiceUrl,
  EventHandler,
  getDefaultSearchRadius,
  mapTierCode,
  isPriorDate,
  getXDays,
  getJsonObject
} from './utils.js';
import {
  $_PAGE_,
  _REGION_,
  _LOCALE_,
  _SEO_LOCALE_,
  authoredPaths,
  BOOKING_ROOMS_AND_GUESTS,
  brandMap,
  overview_brandId,
  overview_brandTier,
  overview_orsId,
  overview_propertyId,
  CRITERIA,
  _AEM_LOCALE_,
  PROPERTY_AVAILABILITY,
  PROPERTY_PHONE_NUMBER,
  SEARCH_OVERVIEW,
  ROOMS_RATES,
  brand_id,
  reservationDataFromAEM,
  queryParams,
  unloadEvent,
  req_rate_plan,
  roomrate_redirect_url,
  paramMap,
  ONEDAY,
  SITE_WIDE_ALERT,
  WR_CC_CODES,
  WR_CC_HOLDER,
  wrccCodes,
  PIPL_CONSENT,
  WR_AUTHENTICATED,
  SESSION_PROFILE,
  CID_COOKIE,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  ENROLLMENT,
  FTSI,
  OKTA_AUD,
  OKTA_ID,
  UPDATE_PROFILE_PHONE,
  $_BOOKING_BAR_MAIN_,
  REDIRECT_URL
} from './vars.js';
import GoogleAPIHandler from './google-api-handler.js';
import { exists, ResizeHandler, getWindowWidth, isDesktopWidth } from './dom-utils.js';
import deepmerge from './deepmerge.js';
import Spinner from '../wr/components/spinner.js';
import OktaClient from '../components/okta-client.js';
import ProfileHandler from '../components/profile-handler.js';
import  HideProvinceCountryConfig from './aem-configs/hide-Province-Country';
let _criteria = {};
let _locationDetails = {};
let intlLocationDetails = {};
let dtmEventFired = false;

class User {
  constructor() {
    this._isLoggedIn = false;
    this._userInfo = {};
    this.checkUserCookie();

    if (getBrand() !== 'WR') {
      this.bindFullProfileCall();
      this.checkUserWRLoginSession();
      EventHandler.one(EventHandler.criteria.init, () => this.initFromCid());
    }else{
      if (CookieHandler.getSession()) {
        this._isLoggedIn = true;
      }
    }
    window.Wyndham = window.Wyndham || {};
    window.Wyndham.UserHandler = this;
  }

  checkUserCookie() {
    // situation where access token doesn't exist but user is authenticated, remove authentication.
    if (!CookieHandler.cidExists() && CookieHandler.cidAuthenticated()) {
      CookieHandler.eraseCookie(WR_AUTHENTICATED);
    }
  }

  initFromCid() {
    if (CookieHandler.cidExists() && !CookieHandler.cidExpired() && CookieHandler.cidAuthenticated() && CookieHandler.sessionExists()) {
      // CID exists, not expired and is authenticated. Pull session data from cookie.
      this.readProfileFromSession();
    } else if (
      (!CookieHandler.cidExists() && CookieHandler.tokenExists()) ||
      (CookieHandler.cidExists() && CookieHandler.cidExpired()) ||
      (CookieHandler.cidExists() && !CookieHandler.cidExpired() && !CookieHandler.sessionExists())
    ) {
      // Some combination of cookies exists to imply we should be logged in. Make a call to
      // getProfile to either re-set the missing cookies or remove any expired ones.
      this.updateSessionCookie();
    } else {
      // User is signed out - send signed out events
      EventHandler.send(EventHandler.profile.signedOut);
      EventHandler.send(EventHandler.profile.fetched);
    }
  }

  updateCurrencyExpiration(response){
    if (response.currencies && response.currencies.length > 0) {
      // sort so that currency POINT will be first
      response.currencies.sort((a, b) => {
        if (a.typeCode === 'POINTS') return -1;
        if (b.typeCode === 'POINTS') return 1;
        return 0;
      });
    }
  }

  updateSessionCookie(cb, fullProfile) {
    console.log("updateSessionCookie, append spinner");
    OktaClient.getOktaClient().then(() => {
      console.log("updateSessionCookie, okta client is valid");
      OktaClient.validateOktaToken().then((isValid) => {
        if (isValid) {
          console.log("Access token is vaid proceed to call getProfile.");
          Spinner.appendTo($('html'), true, true);
          ProfileHandler.getProfile().then((res) => {
            console.log('Success Get Profile');
            console.log(res);
            this.updateCurrencyExpiration(res);
            this.createSessionInformation(res);
            if(fullProfile) {
              console.log("updateSessionCookie() fullProfile: ",fullProfile);
              setWRCCCodes(res);
              this.setUserDataFromProfile(res);
            }
            if (CookieHandler.cidExists() && CookieHandler.cidAuthenticated()) {
              this.readProfileFromSession();
            } else {
              EventHandler.send(EventHandler.profile.signedOut);
              EventHandler.send(EventHandler.profile.fetched);
            }
            if (cb && typeof cb === 'function') {
              cb();
            }
          }).catch((result) => {
            console.log("updateSessionCookie() res error while calling api= ", result);
              // getProfile call should delete cid and WHG_SESSION cookies
              EventHandler.triggerEvent('brands-error', result);
              EventHandler.send(EventHandler.profile.signedOut);
              EventHandler.send(EventHandler.profile.fetched);
              EventHandler.send(EventHandler.profile.oktaSignOut);
          }).finally(() => {
            if (getSessionStorage(CID_COOKIE)) {
              Spinner.remove($('html'));
            }
          });
        } else {
          console.error("Access token is invalid. Skip getProfile call.");
        }
      });
    });
  }

  createSessionInformation(profile) {
    console.log('create OT_SESSION', profile);
    let defaultLocale = 'US';
    const prefLocale = profile.preferences ? profile.preferences.filter(preference => preference === 'pref_lang') : null;
    if (prefLocale) {
      defaultLocale = prefLocale.value;
    }
    const currencyObj = profile && profile.currencies ?
      profile.currencies.find(item => item.typeCode === 'POINTS') : null;
    const currencies = currencyObj && currencyObj.available ? currencyObj.available : 0;
    const sessionInfo = {
      membershipId: profile.accountNumber,
      statusCode: profile.accountStatusCode,
      givenName: profile.firstName,
      username: profile.firstName,
      userLocalePref: defaultLocale,
      points: currencies,
      level: profile.currentTier.description || mapTierCode(profile.currentTier.tierCode),
      expiration: profile.currentTier.endDate,
      pointsExpiration: profile.currencies ? profile.currencies[0].pointForfeitureDate : "",
      aspirationTiers: profile.aspirationTiers,
      earningTier: profile.earningTier,
      projectedTier: profile.projectedTier,
      nightsNeeded: profile.aspirationTiers && profile.aspirationTiers.length > 0 && profile.aspirationTiers[0].requiredAmount ?
        profile.aspirationTiers[0].requiredAmount : 0, // will be decommissioned
      state: 'AUTHENTICATED',
    };
    setLocalStorage(SESSION_PROFILE, sessionInfo);
    window.User = {...window.User, ...sessionInfo};
  }

  readProfileFromSession() {
    let cookieSession = CookieHandler.getSession();
    if (cookieSession && cookieSession.points) {
      if (document.querySelector('.wr-logged-points') && cookieSession.points != Number(document.querySelector('.wr-logged-points').innerText)) {
       // $('.wr-logged-points').html(cookieSession.points);
      } else if (document.querySelector('.member-points span') && cookieSession.points != Number(document.querySelector('.member-points span').innerText)) {
        $('.member-points span').html(DOMPurify.sanitize(formatNumber(cookieSession.points)));
        $('.user-memberpoints').html(DOMPurify.sanitize(formatNumber(cookieSession.points)));
      }
    }
    console.log('readProfileFromSession', cookieSession);
    if (cookieSession) {
      this._isLoggedIn = true;
      this.limitedProfile = {
        wrNo: cookieSession.memberNumber || cookieSession.membershipId,
        fname: decodeURIComponent(cookieSession.firstName || cookieSession.givenName),
        username: cookieSession.username,
        pointBal: cookieSession.points,
        level: cookieSession.currentLevel || cookieSession.level
      };
      if(cookieSession.state === 'AUTHENTICATED') {
        EventHandler.send(EventHandler.profile.signedIn);
      } else {
        this._isLoggedIn = false;
        EventHandler.send(EventHandler.profile.signedOut);
      }

    } else {
      EventHandler.send(EventHandler.profile.signedOut);
    }
    EventHandler.send(EventHandler.profile.fetched);
  }
  setWRUserInfo(obj) {
    if (obj) {
      this._userInfo = obj;
    } else {
      this._userInfo = null;
    }
  }
  setUserDataFromProfile(res) {
    if (!res.addresses) res.addresses = [];
    if (!res.emails) res.emails = [];
    if (!res.phones) res.phones = [];
    if (!res.preferences) res.preferences = [];
    let defCurrenciesPoint = {
      typeCode: 'POINTS',
      available: 0,
      pointForfeitureDate: res.currentTier.endDate
    };
    if (!res.currencies) res.currencies = [defCurrenciesPoint];
    let defaultAddr = res.addresses.find((el) => {
        return el.DefaultInd;
      }) || res.addresses[0],
      defaultEmail = res.emails.find((el) => {
        return el.DefaultInd;
      }) || res.emails[0],
      defaultPhone = res.phones.find((el) => {
        return el.DefaultInd;
      }) || res.phones[0];
    if (!defaultAddr) {
      defaultAddr = {};
    }
    if (!defaultEmail) {
      defaultEmail = {};
    }
    if (!defaultPhone) {
      defaultPhone = {};
    }
    if (!defaultAddr.AddressLines) {
      defaultAddr.AddressLines = [];
    }

    let defAwardsPref = '';
    if (res.aliases) {
      res.aliases.forEach(alias => {
        if (alias.typeCode === 'PartnerPreferred') {
          defAwardsPref = alias.alias;
        }
      });
    }

    defCurrenciesPoint = res.currencies.filter(currency =>  currency.typeCode === 'POINTS');

    const smsPreference = res.preferences.filter(pref => pref.preferenceRuleDetailCode == 'CP_WR_SMS');

    const userInfo =  {
      add1: defaultAddr.address1 || '',
      add2: defaultAddr.address2 || '',
      add3: defaultAddr.address3 || '',
      addressType: defaultAddr.typeCode,
      awardsPref: defAwardsPref, // will be decommissioned
      city: defaultAddr.city,
      country: defaultAddr.countryCode,
      email: defaultEmail.email,
      emailType: defaultEmail.typeCode,
      expireDate: defCurrenciesPoint.pointForfeitureDate,
      fname: res.firstName,
      joinDate: res.enrollmentDateTime,
      lname: res.lastName,
      phone: defaultPhone.phoneNumber,
      pointBal: defCurrenciesPoint.available,
      state: defaultAddr.stateCode,
      level: res.currentTier.description || mapTierCode(res.currentTier.tierCode),
      wrNo: res.accountNumber,
      zip: defaultAddr.zip,
      enrolledSMS: smsPreference && smsPreference[0] && smsPreference[0].value
    };

    // will be decommissioned by Tally
    // if(res.CustLoyalty.length > 1) {
    //   const otherAccounts = res.CustLoyalty.slice(1);
    //   otherAccounts.forEach( (account) => {
    //     // console.info(`Other Account ${account}`);
    //     if(account.ProgramID === 'CET') {
    //       userInfo.caesarsMemberNumber = account.MembershipID;
    //       console.info('Found CET Account');
    //     }
    //   });
    // }
    this.setWRUserInfo(userInfo);
    try {
      EventHandler.send(EventHandler.fullProfile.fetched);
    } catch(error) {
      console.error('fullProfile fetch error:', error);
    }
    window.User = getLocalStorage(SESSION_PROFILE) ? {
        ...window.User,
        ...getLocalStorage(SESSION_PROFILE)
      } : window.User;
  }
  getWRUserInfo() {
    return this._userInfo;
  }
  getLimitedProfile() {
    return this.limitedProfile || null;
  }
  isWRLoggedIn() {
    return this._isLoggedIn;
  }
  getWRObject() {
    if (this.isWRLoggedIn()) {
      return this._userInfo;
    } else {
      return null;
    }
  }
  populateWRLoginSection(userInfo, userName) {
    console.log('populateWRLoginSection');
    // Setting blank value
    $('#wrPassword').val('');
    if (typeof userInfo === 'object') {
      userInfo.userId = userName;
      this._userInfo = userInfo;
      this.populateEliteMemberDetails(userInfo);

      this.refreshAfterLogin();
    }
  }
  /**
   * This method is used to populate the loyalty or Elite member details in the page.
   *
   * userInfo - user object
   */
  populateEliteMemberDetails(userInfo) {
    console.log('populateEliteMemberDetails', userInfo);
    $('#wrUserFirstName').html(DOMPurify.sanitize(userInfo.fname));
    $('.wr-logged-user-name').each(function() {
      $(this).html(($(this).data('welcomeBack') ? $(this).data('welcomeBack') + ', ' : '') + DOMPurify.sanitize(userInfo.fname));
    });
    // $('.wr-logged-points').html(userInfo.pointBal);
    $('#wrUserPoints').text(DOMPurify.sanitize(formatNumber(userInfo.pointBal)));
    // if(typeof userInfo.pointBal !== 'undefined' && userInfo.pointBal !== ''){
    //   $(".user-member-points").each(function() {
    //     $(this).text(DOMPurify.sanitize(formatNumber(userInfo.pointBal)) + ' ' + ($(this).data('pointsDesktopText') || $(this).data('pointsMobileText')));
    //   });
    // }
    $('#member-no').html(DOMPurify.sanitize(userInfo.wrNo));
    $('.user-member-id').html(DOMPurify.sanitize(userInfo.wrNo));
    $('#wrTier').html(DOMPurify.sanitize(userInfo.level));
    //Check maxFreeNights & QNS
    if (userInfo.qns > 20) {
      userInfo.maxFreeNights = userInfo.maxFreeNights == 0 ? parseInt(userInfo.qns / 20) : userInfo.maxFreeNights;
      userInfo.qns = userInfo.qns % 20;
    }
    this.adjustLoginDetail(userInfo);
    ResizeHandler.addResizeEndFn(() => this.adjustLoginDetail(userInfo));

    $('#my-account-section').removeClass('hidden');
    $('.wyndham-rewards-links').hide();
    $('.dropdown-toggle:not(#currencyDropDown,.navbar-nav .dropdown-toggle,.amenities-parent-container .dropdown-toggle)').hide();
    $('#currencyDropDown').show();
    $('#languageDropdown').show();
    $('.wyndham-rewards-user-details').removeClass('hidden');
    $('.login-join-tab').hide();
    $('.not-logged-in').hide();
    $('.logged-in').removeClass('hidden');
    $('#my-account').addClass('my-account-top-nav-after-sign');
    $('#my-account').find('i').addClass('after-signin-caret-down-icon');
    $('#my-account').parent().addClass('my-account-nav-container');
  }
  adjustLoginDetail(userInfo) {
    if (getWindowWidth() > 1200 && userInfo.level.toLowerCase() != 'member') {
      $('#wrTier').html(DOMPurify.sanitize(userInfo.level + ' Member'));
    } else {
      $('#wrTier').html(DOMPurify.sanitize(userInfo.level));
    }
    $('.username-member').html(DOMPurify.sanitize(userInfo.level + ' Member'));
    $('#my-account').parent().addClass(userInfo.level.toLowerCase());

  }

  performWRSignOut() {
    $.ajax({
      url: getServiceUrl('logout'),
      contentType: 'application/json',
      type: 'POST',
      complete: this.refreshAfterLogout,
      error: (res) => {
        EventHandler.triggerEvent('brands-error', res);
      }
    });
  }

  refreshAfterLogin() {
    console.log("refreshAfterLogin");
    if (!CookieHandler.cidAuthenticated()) {
      CookieHandler.createCookie(WR_AUTHENTICATED, 'true', 1);
    }
    this.updateSessionCookie(() => {
      if ($_PAGE_.is('.packages-page')) {
        let bookdata = ReservationHandler.getBookData();
        goToPackages(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.at, '', false, bookdata.directBillSupported);
      } else if ($_PAGE_.is('.booking-page')) {
        let bookdata = ReservationHandler.getBookData();
        goToBook(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.at, '', false, bookdata.directBillSupported);
      } else {
        // refresh when you are in unauthenticated state after Login.
        window.scroll(0, 0);
        location.reload();
      }
    }, true);
  }

  refreshAfterLogout() {
    CookieHandler.eraseCookie(WR_AUTHENTICATED);
    if ($_PAGE_.is('.booking-page')) {
      if (isLoyaltyRP(req_rate_plan)) {
        location.href = roomrate_redirect_url || '/' + getBrandName();
      } else {
        let bookdata = ReservationHandler.getBookData();
        goToBook(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.at, '', false, bookdata.directBillSupported);
      }
    } else {
      location.reload();
    }
  }
  checkUserWRLoginSession() {
    EventHandler.one(EventHandler.profile.signedIn, () => {
      console.log('checkUserWRLoginSession, EventHandler.profile.signedIn');
      console.log(this.getWRUserInfo());
      this._isLoggedIn = true;
      this.populateEliteMemberDetails(this.getLimitedProfile());
      if (!isDesktopWidth() && !$('.wyndham-rewards-logged-in').hasClass('login-show')) {
        $('.wyndham-rewards-logged-in').addClass('login-show');
      }
    });
    EventHandler.one(EventHandler.profile.signedOut, () => {
      if ($('.wyndham-rewards-logged-in').hasClass('login-show')) {
        $('.wyndham-rewards-logged-in').removeClass('login-show');
      }
    });
    EventHandler.on(EventHandler.fullProfile.fetched, () => {
      console.log('checkUserWRLoginSession, EventHandler.fullProfile.fetched');
      this.populateEliteMemberDetails(this.getWRUserInfo());
    });
    // This is to set the remember me
    this.applyingRememberMe();
  }
  applyingRememberMe() {
    let wrUsername = CookieHandler.readCookie('wrUsername');
    if (wrUsername && wrUsername.trim().length > 0) {
      $('#wr-signin input:checkbox').prop('checked', true);
      $('#wrUsername').val(wrUsername);
    }
  }
  setRememberMe(userName) {
    if ($('#wr-signin input:checkbox').is(':checked')) {
      CookieHandler.createCookie('wrUsername', userName, 90);
    } else {
      CookieHandler.eraseCookie('wrUsername');
    }
  }
  // deprecated
  // performWRSignIn() {
  //   let userName = $('#wrUsername').val()
  //     .trim(),
  //     password = $('#wrPassword').val()
  //     .trim();

  //   this.setRememberMe(userName);

  //   $('.login-general-error').hide();
  //   $('.login-invalid-credentials').hide();
  //   $('.login-locked-account').hide();
  //   $('.login-invalid-security-answer').hide();
  //   $('.login-suspended-account').hide();

  //   if ($('#wr-signin .parsley-error').length === 0) {
  //     let loginData = JSON.stringify({
  //       Username: userName,
  //       Password: password,
  //       rememberMe: $('#wr-signin input:checkbox').is(':checked')
  //     });
  //     // authenticate.do
  //     $.ajax({
  //       url: getServiceUrl('authUser'),
  //       contentType: 'application/json',
  //       type: 'POST',
  //       data: loginData,
  //       success: (res) => {
  //         let serviceUrl = null;
  //         switch (res.TwoFormActionCode) {
  //           case 'setup':
  //             serviceUrl = getServiceUrl('getSecurityQuestions');
  //             break;
  //           case 'verify':
  //             serviceUrl = getServiceUrl('getVerifyQuestion');
  //             break;
  //           case 'change':
  //             window.location = getLocaleUrlToken() + '/wyndham-rewards/login';
  //             break;
  //           case '':
  //             this.refreshAfterLogin();
  //             break;
  //           default:
  //             window.location = getLocaleUrlToken() + '/wyndham-rewards/login';
  //             break;
  //         }

  //         this.handleTwoFactorAuth(serviceUrl);
  //       },
  //       error: (res) => {
  //         EventHandler.triggerEvent('brands-error', res);
  //         res = res.responseJSON;
  //         $('#wr-login').modal('show');
  //         if (res.ErrorCode === '1005') {
  //           if (exists('.login-locked-account')) {
  //             $('.login-locked-account').show();
  //           } else {
  //             $('.login-general-error').text(res.ErrorMessage)
  //               .show();
  //           }
  //           $('#wrUsername, #wrPassword').val('');
  //         } else if (res.ErrorCode === '2012' || res.ErrorCode === '1004') {
  //           if (exists('.login-suspended-account')) {
  //             $('.login-suspended-account').show();
  //           } else {
  //             $('.login-general-error').text(res.ErrorMessage)
  //               .show();
  //           }
  //           $('#wrUsername, #wrPassword').val('');
  //         } else if (res.ErrorCode === '1003') {
  //           if (exists('.login-invalid-credentials')) {
  //             $('.login-invalid-credentials').show();
  //           } else {
  //             $('.login-general-error').text(res.ErrorMessage)
  //               .show();
  //           }
  //         } else {
  //           $('.login-general-error').text(res.ErrorMessage)
  //             .show();
  //         }
  //       }
  //     });
  //   }
  // }
  handleTwoFactorAuth(serviceUrl) {
    if (serviceUrl) {
      $.ajax({
        url: serviceUrl,
        contentType: 'application/json',
        type: 'GET',
        complete: (res) => {
          if (serviceUrl.match(/Verification/)) {
            this.handleSecurityAnswer(serviceUrl, res);
          } else if (serviceUrl.match(/securityQuestions/)) {
            this.handleSecuritySetup(serviceUrl, res);
          }
        },
        error: (res) => {
          EventHandler.triggerEvent('brands-error', res);
        }
      });
    }
  }
  handleSecuritySetup(serviceUrl, questions) {
    if (questions.responseJSON && questions.responseJSON.success) {
      questions = questions.responseJSON;
      $('.login-page').hide();
      var options = questions.data.map((qObj) => {
        let optNode = document.createElement('option');
        optNode.value = qObj.id;
        optNode.appendChild(document.createTextNode(qObj.question));
        return optNode;
      });
      options.unshift($('.sec-q-set option')[0]);
      if ($('body').is('.ipad,.iphone,.ipod')) {
        options.push(document.createElement('optgroup')); // Fix for long text cutoff in iOS Safari
      }
      $('.sec-q-set select').html(DOMPurify.sanitize(options));
      $('.sec-q-set').show();
      $('#wr-login').attr('aria-labelledby', $('.sec-q-set').find('.headline-c')
        .attr('id'));
      $('#wr-login .modal-dialog').scrollTop(0);
    } else {
      $('.login-general-error').text(questions.ErrorMessage)
        .show();
    }
  }
  handleSecurityAnswer(serviceUrl, question) {
    if (question.responseJSON && question.responseJSON.success) {
      question = question.responseJSON;
      $('.login-page').hide();
      $('#securityId').val(question.data.id);
      $('#securityQuestion').html(DOMPurify.sanitize(question.data.question)).text();
      $('.sec-q-prompt').show();
      $('#wr-login').attr('aria-labelledby', $('.sec-q-prompt').find('.headline-c')
        .attr('id'));
    } else {
      $('.login-general-error').text(question.ErrorMessage)
        .show();
    }
  }
  bindFullProfileCall() {
    console.log("bindFullProfileCall is called");
    let points = '';
    if (exists('.require-full-profile')) {
      EventHandler.one(EventHandler.profile.signedIn, () => {
        console.log("bindFullProfileCall, signedIn event is called");
        OktaClient.getOktaClient().then(() => {
          console.log("bindFullProfileCall, okta client is valid");
          OktaClient.validateOktaToken().then((isValid) => {
            if (isValid) {
              console.log("Access token is vaid proceed to call getProfile.");
              Spinner.appendTo($('html'), true, true);
              ProfileHandler.getProfile().then((responseData) => {
                const result = getJsonObject(responseData);
                console.log('successful get profile from bindFullProfileCall', result);
                Spinner.remove($('html')); // in-case following calls fail
                setWRCCCodes(result);
                this.updateCurrencyExpiration(result);
                this.setUserDataFromProfile(result);
                setSessionStorage(CID_COOKIE, result);
                const currencyObj = result && result.currencies ?
                  result.currencies.find(item => item.typeCode === 'POINTS') : null;
                points = currencyObj && currencyObj.available ? currencyObj.available : 0;
                $('.wr-logged-points').html(DOMPurify.sanitize(formatNumber(points)));
                $(".user-member-points").each(function() {
                  $(this).text(DOMPurify.sanitize(formatNumber(points)) + ' ' + ($(this).data('pointsDesktopText') || $(this).data('pointsMobileText')));
                });
              }).catch((result, status, error) => {
                console.log('Profile retrieve failed', status, error);
                EventHandler.triggerEvent('brands-error', result);
                EventHandler.send(EventHandler.profile.oktaSignOut);
              }).finally(() => {
                Spinner.remove($('html'));
              });
            } else {
              console.error("Access token is invalid. Skip getProfile call.");
            }
          });
        });
      });
    }
  }

  setOktaUserEmail(email) {
    const aud = getSessionStorage(OKTA_AUD);
    if (!aud) {
      return null;
    }
    let cache = getLocalStorage(`@@auth0spajs@@::${aud.aud}::@@user@@`);
    cache.decodedToken.user.email = email;
    localStorage.setItem(`@@auth0spajs@@::${aud.aud}::@@user@@`, JSON.stringify(cache));
  }

  getOktaUser() {
    const aud = getSessionStorage(OKTA_AUD);
    if (!aud) {
      return null;
    }
    const cache = getLocalStorage(`@@auth0spajs@@::${aud.aud}::@@user@@`);
    return cache.decodedToken.user;
  }

  getIdToken() {
    const aud = getSessionStorage(OKTA_AUD);
    if (!aud) {
      return DOMPurify.sanitize(localStorage.getItem('id_token')) || null;
    }
    const cache = getLocalStorage(`@@auth0spajs@@::${aud.aud}::@@user@@`);
    let token = cache && cache.id_token ? cache.id_token : DOMPurify.sanitize(localStorage.getItem('id_token')) || null;
    return token;
  }

  getOktaAccessToken() {
    const lsOktaKey = Object.keys(window.localStorage).find((key) => key.match(/profile email offline_access/));
    let oktaUserVal = window.localStorage.getItem(lsOktaKey);
    const oktaUserJson = oktaUserVal ? JSON.parse(oktaUserVal) : null;

    if (!oktaUserJson) {
      return oktaUserJson;
    }

    if (!oktaUserJson['body']) {
      return oktaUserJson;
    }

    if (oktaUserJson['body'][ACCESS_TOKEN]) {
      console.log(oktaUserJson['body'][ACCESS_TOKEN]);
      return oktaUserJson.body.access_token;
    } else {
      return oktaUserJson;
    }
  }

  // remove session storage, cookies etc.
  logout() {
    CookieHandler.eraseCookie(ACCESS_TOKEN);
    CookieHandler.eraseCookie(REFRESH_TOKEN);
    CookieHandler.eraseCookie(WR_AUTHENTICATED);
    sessionStorage.removeItem(CID_COOKIE);
    sessionStorage.removeItem(OKTA_AUD);
    sessionStorage.removeItem(OKTA_ID);
    localStorage.removeItem('EMAIL_SENT_NOT_VERIFY');
    localStorage.removeItem('PREF_POINTS_MILES');
    removeCorporateCodeFromCriteria();
    this.resetLocalStorage();
  }

  resetLocalStorage() {
    localStorage.removeItem(UPDATE_PROFILE_PHONE);
    localStorage.removeItem(SESSION_PROFILE);
    localStorage.removeItem(ENROLLMENT);
    localStorage.removeItem(FTSI);
    localStorage.removeItem(REDIRECT_URL);
  }
}

function initCriteria() {
  _criteria = $.extend(true, getDefaultCriteria(), getSessionCriteria());
  setRadius();
  setLocale();
  updateCriteriaFromQueryParams();
  setBrand();
  setBrandName();

  if(!_criteria.brandId) {
    _criteria.brandId = getBrand();
  }

  if ($_PAGE_.is('.search-results-page')) {
    if (_isNull(_criteria.referringBrand)) {
      _criteria.referringBrand = getBrand();
    }

    if (getBrand().match(/^(whg|all)$/i) &&
      !(_criteria.referringBrand.match(/^(dx|vo|ce)$/i))
    ) {
      updateQueryParameter('referringBrand', getBrand(true));
      updateQueryParameter('referringTier', getBrandTier());
    }
  }

  initLocationInfo(_criteria);

  if (!($_PAGE_.is('.modify-page'))) {
    EventHandler.send('modify:reset');
  }
}

function updateCriteriaFromQueryParams() {
  let querySessionId = getQueryParameter('sessionId'),
    criteriaSessionId = _criteria.sessionId ? _criteria.sessionId.toString() : null,
    brandId = getQueryParameter('brand_id'),
    obj = '';

  if (window.location.search) {
    obj = populateObjectFromQueryString(window.location.search, true);
    for(let key in paramMap) {
      if(obj[key]) {
        obj[paramMap[key]] = obj[key];
        delete obj[key];
      }
    }
    let isCheckInPriorToday = isPriorDate(obj[paramMap.checkIn], getToday());
    if (isCheckInPriorToday) {
        obj[paramMap.checkIn] = formatDateForBWS(getToday());
    }
    
    const defaultLos = $_BOOKING_BAR_MAIN_.find('.booking-dates-container').data('default-los');
    let isCheckOutPriorToday = isPriorDate(obj[paramMap.checkOut], obj[paramMap.checkIn]) || isPriorDate(obj[paramMap.checkOut], getToday());
    if (isCheckOutPriorToday) {
        obj[paramMap.checkOut] = formatDateForBWS(getXDays(defaultLos, obj[paramMap.checkIn]));
    }

    if(obj.iata) {
      window.digitalpfpiataList = window.digitalpfpiataList.replace(/ /g, "").split(",");
      CookieHandler.createCookie('iataCookie', obj.iata);

      if (_.contains(window.digitalpfpiataList, obj.iata)) {
        CookieHandler.createCookie('pfpCookie', obj.iata, 14);
      }
    }
    obj.updated = true;
  }


  if (criteriaSessionId !== querySessionId) {
    setCriteria(obj);
  } else if(brandId){
    obj = { brandId: brandId };
    setCriteria(obj);
  }
}

function setToStorage(storage, key, obj, replace) {
  try {
    let session = getFromStorage(storage, key);

    if (session && !replace) {
      obj = deepmerge(session, obj);
    }

    if (typeof obj === 'object') {
      obj = JSON.stringify(obj);
    }

    storage.setItem(key, obj);
  } catch (error) {
    return false;
  }
}

function setSessionStorage(key, obj, replace) {
  return setToStorage(sessionStorage, key, obj, replace);
}

function setLocalStorage(key, obj, replace) {
  return setToStorage(localStorage, key, obj, replace);
}

function getFromStorage(storage, key, val) {
  try {
    let session = JSON.parse(DOMPurify.sanitize(storage.getItem(key)));

    if (session && val) {
      return session[val];
    } else {
      return session;
    }
  } catch (error) {
    return false;
  }
}

function getSessionStorage(key, val) {
  return getFromStorage(sessionStorage, key, val);
}

function getLocalStorage(key, val) {
  return getFromStorage(localStorage, key, val);
}

function getCriteria() {
  if(!dtmEventFired){
    EventHandler.triggerEvent(EventHandler.criteria.success);
  }

  return getMemoryCriteria();
}

function setCriteria(obj, triggerEvent) {
  setSessionCriteria(obj, triggerEvent);
}

function getMemoryCriteria() {
  return _criteria;
}

function setMemoryCriteria(obj) {
  _criteria = $.extend({}, _criteria, obj);
}

function getSessionCriteria() {
  let criteria = getSessionStorage(CRITERIA) || getLocalStorage(CRITERIA);
  if(criteria && criteria.expireDate && Date.now() >= criteria.expireDate) {
    localStorage.removeItem(CRITERIA);
    return false;
  }
  return criteria;
}

function setSessionCriteria(obj, triggerEvent) {
  setMemoryCriteria(obj);
  _criteria.expireDate = undefined; // Don't expire sessionStorage by accident
  setSessionStorage(CRITERIA, _criteria);
  _criteria.expireDate = Date.now() + ONEDAY; // localStorage backup expires in 24h
  setLocalStorage(CRITERIA, _criteria);
  updateUrl(_criteria);
  if (triggerEvent) {
    EventHandler.send(EventHandler.criteria.updated);
  }
}

function removeItemFromSession(item){
  try {
    sessionStorage.removeItem(item);
  } catch (error) {
    return false;
  }
}

function updateUrl(obj) {
  for (let key in obj) {
    updateQueryParameter(key, obj[key]);
  }
}


window.Wyndham = window.Wyndham || {};
window.Wyndham.Criteria = {
  initialized: $.Deferred(),
  getLocationDetails: null
};

function finishCriteriaInit() {
  normalizeSearchCriteria();

  if ($_PAGE_.is('.modify-page')) {
    EventHandler.send('modify:reset');
  }
  window.Wyndham.Criteria.getLocationDetails = getLocationDetails;
  EventHandler.send(EventHandler.criteria.init);
  window.Wyndham.Criteria.initialized.resolve();
}


function initLocationInfo(criteriaObj) {
  let $poi = $('.poiattributes'),
    hotelRE = new RegExp('(' + getBrandName() + ')\/([a-zA-z0-9\-]+)'),
    searchRE = new RegExp('(hotels)\/(.+)'),
    locationPath = decodeURIComponent(window.location.pathname),
    locUrlParam = getQueryParameter('loc'),
    latitude = null,
    longitude = null,
    matchesUrlFallback = locationPath.match(searchRE) || locationPath.match(hotelRE);

  if ($poi.length > 0) {
    latitude = $poi.data('attribute-latitude');
    longitude = $poi.data('attribute-longitude');
  } else {
    latitude = getQueryParameter('latitude');
    longitude = getQueryParameter('longitude');
  }

  if ((_isNotNull(locUrlParam) && ($_PAGE_.is('.search-results-page'))) ||
      (_isNotNull(locUrlParam) && _isNull(criteriaObj.loc)) ||
      (_isNotNull(locUrlParam) && _isNotNull(criteriaObj.loc) && (locUrlParam !== criteriaObj.loc)) ||
      (_isNotNull(locUrlParam) && _isNotNull(criteriaObj.loc) && (locUrlParam === criteriaObj.loc) && _isNull(criteriaObj.location))) {
    GoogleAPIHandler.getLocationFromPlace(locUrlParam).then((l) => {
      criteriaObj.loc = criteriaObj.loc_temp;
      if (locUrlParam !== criteriaObj.loc) {
        l.searched = l.location;
      }
      setLocationDetails(l);
      finishCriteriaInit();
    }, () => {
      finishCriteriaInit();
    });
  } else {
    if (_isNotNull(criteriaObj) &&
         _isNotNull(criteriaObj.loc) &&
         _isNotNull(criteriaObj.location) &&
         _isNotNull(criteriaObj.latitude) &&
         _isNotNull(criteriaObj.longitude) &&
         _isNull(latitude) &&
         _isNull(longitude) &&
         !($_PAGE_.is('.property-page, .rooms-rates-page') && _isNull(locUrlParam))) {
      // Location is present in the response object
      // and lat/long are not passed in url or via poi page properties
      setLocationDetails(criteriaObj);
      finishCriteriaInit();
    } else if ($_PAGE_.is('.search-results-page,.search-results-page-v2,.property-page,.rooms-rates-page')) {
      if (_isNotNull(latitude) && _isNotNull(longitude)) {
        // Lat/long are passed in url or via poi page properties
        GoogleAPIHandler.getLocationDetailsFromReverseGeoCoding(latitude, longitude).then((l) => {
          l.location = `${l.city}${(l.city !== '') ? ', ' : ''}${l.state}${(l.state !== '') ? ', ' : ''}${l.countryCode}`;
          setLocationDetails(l);
          finishCriteriaInit();
        }, () => {
          finishCriteriaInit();
        });
      } else if (matchesUrlFallback) {
        // This section is to fallback in case that someone access the site from old url and we do no have
        // latitude and longitude in the url and is the hotels url so we try to get the place from what is in the url.
        GoogleAPIHandler.getLocationDetailsFromAutocomplete(matchesUrlFallback[2], true).then((l) => {
          l.searched = l.location;
          setLocationDetails(l);
          finishCriteriaInit();
        }, () => {
          finishCriteriaInit();
        });
      }
    } else {
      // We don't have information to get a place id, just finish the call.
      finishCriteriaInit();
    }
  }
}

function setIntlLocationInfo(locationDetails) {
  intlLocationDetails = {
    countries: {},
    states: {},
    cities: {}
  };
  $.each(locationDetails.countries, (i, country) => {
    intlLocationDetails.countries[country.key] = country.countryName;

    $.each(country.states, (j, state) => {
      intlLocationDetails.states[state.key] = state.stateName;

      $.each(state.cities, (k, city) => {
        intlLocationDetails.cities[city.key] = city.cityName;
      });
    });
  });
  EventHandler.send(EventHandler.getLocation.success);
}

function setLocationDetails(l, _suppressEvent) {
  if (l && typeof (l) === 'object' && !($.isEmptyObject(l))) {

    let location = $.extend(false, {}, l);

    location.output = {};
    if (_isNotNull(location)) {
      if (_isNotNull(location.city)) {
        location.city = uppercaseFirstLetterEachWord(location.city);
        if (location.state || location.stateName) {
          location.output.cityState = location.city + ', ' + (location.state || location.stateName);

          if (location.country || location.countryName) {
            location.output.cityStateCountry = location.output.cityState + ', ' + uppercaseFirstLetterEachWord(location.country || location.countryName);
          }
          if (location.countryCode) {
            location.output.cityStateCountryAbbr = location.output.cityState + ', ' + location.countryCode;
          }
        }
        if (location.stateCode) {
          location.output.cityStateAbbr = location.city + ', ' + location.stateCode;

          if (location.country || location.countryName) {
            location.output.cityStateAbbrCountry = location.output.cityStateAbbr + ', ' + uppercaseFirstLetterEachWord(location.country || location.countryName);
          }
          if (location.countryCode) {
            location.output.cityStateAbbrCountryAbbr = location.output.cityStateAbbr + ', ' + location.countryCode;
          }
        }

        if ($.isEmptyObject(location.output)) {
          if (location.country || location.countryName) {
            location.output.cityState = location.city + ', ' + (location.country || location.countryName);
            location.output.cityStateAbbr = location.output.cityState;
            location.output.cityStateCountry = location.output.cityState;
          }
          if (location.countryCode) {
            location.output.cityStateAbbr = location.city + ', ' + location.countryCode;
            location.output.cityStateCountryAbbr = location.output.cityStateAbbr;
            location.output.cityStateAbbrCountryAbbr = location.output.cityStateAbbr;
          }
        }
      }

      let scRadius = getCriteria().radius,
        newRadius = getDefaultSearchRadius();

      if (_isNotNull(location.city) &&
        (_isNull(scRadius) || scRadius === getDefaultSearchRadius())) {

        if (_isNotNull(location.countryCode)) {
          if (location.countryCode !== 'US') {
            newRadius = getDefaultSearchRadius('medium');
          } else {
            newRadius = getDefaultSearchRadius('small');
          }
        }
        location.radius = newRadius;
      } else if (scRadius !== getDefaultSearchRadius()) {
        location.radius = scRadius;
      }

      _locationDetails = location;
      setCriteria(location);

      if (_suppressEvent !== false) {
        EventHandler.send(EventHandler.getLocation.success);
      }
    } else {
      EventHandler.triggerEvent('brands-error');
      EventHandler.send(EventHandler.getLocation.error);
    }
  }
}

function getLocationDetails(arg) {
  if (_isNotNull(arg)) {
    let enName = extractDataFromObject(arg, _locationDetails);

    if (intlLocationDetails && arg.match(/city/ig)) {
      return intlLocationDetails.cities && intlLocationDetails.cities[enName] ? intlLocationDetails.cities[enName] : enName;
    } else if (intlLocationDetails && arg.match(/country/ig)) {
      return intlLocationDetails.countries[enName] || enName;
    } else {
      return enName;
    }
  }

  let retVal = $.extend({}, _locationDetails);

  return retVal;
}
/**
 * This method is used to prepare search criteria object for getSearchUrl service.
 *
 * sc - (object) search criteria
 */
function normalizeSearchCriteria(sc) {
  let location = getLocationDetails();

  if (sc) {
    _criteria.destination = sc.destination || _criteria.destination;
    _criteria.checkInDate = _criteria.checkInDate || getToday();
    _criteria.checkOutDate = _criteria.checkOutDate || getTomorrow();

    if (_criteria.useWRPoints === 'true' || _criteria.useWRPoints === true) {
      if (_criteria.ratePlan) {
        _criteria.ratePlan = '';
      }
    }

    _criteria.hotel_id = sc.hotel_id || sc.orsid || _criteria.hotel_id || _criteria.orsid || overview_propertyId;
    _criteria.iata = sc.iata || _criteria.iata || null;
    _criteria.city = location.city;
    _criteria.state = location.state;
    _criteria.country = location.country;
    _criteria.stateCode = location.stateCode;
    _criteria.countryCode = location.countryCode;
    _criteria.location = location.location;
    _criteria.loc = location.loc;
    _criteria.searched = location.searched;
    setCriteria(_criteria);
  }
}

function getSpecialRate() {
  if ($('#special-rate-drop').val()) {
    if ($('#special-rate-drop').val() != 'promotional') {
      return {
        type: $('#special-rate-drop').val(),
        code: $('#corp-code-data').val()
      };
    } else {
      return {
        type: $('#special-rate-drop').val(),
        code: $('#rate-code-data').val()
      };
    }
  } else if ($('#corp-code-data').val()) {
    return {
      type: 'corpCode',
      code: $('#corp-code-data').val()
    };
  } else if ($('#group-code-data').val()) {
    return {
      type: 'groupCode',
      code: $('#group-code-data').val()
    };
  } else if ($('#rate-code-data').val()) {
    return {
      type: 'rateCode',
      code: $('#rate-code-data').val()
    };
  } else if ($('#promotional-code-data').val()) {
    return {
      type: 'promotional',
      code: $('#promotional-code-data').val()
    };
  }

  return null;
}

function prepareBookingBarValues() {
  let rateCode = $('#rate-code-data').val(),
    rate = getSpecialRate(),
    rateTypes = ['corpCode', 'rateCode', 'groupCode'],
    data = {
      checkInDate: formatDateForBWS($('#from').val()),
      checkOutDate: formatDateForBWS($('#to').val()),
      childAge: $('#childAges').val(),
      children: $('#numOfChildren').val() * 1,
      adults: $('#numOfAdults').val() * 1,
      rooms: $('#numOfRooms').val() * 1,
      ratePlan: null,
      corpCode: null,
      rateCode: null,
      groupCode: null,
      //      radius: _criteria.radius,
      brandCode: _criteria.brandCode,
      hotel_id: _criteria.hotel_id,
      useWRPoints: ($_PAGE_.is('.modify-page')) ? false : $('input[name="wyndham-rewards"]').is(':checked'),
      updated: true
    };

  if (data.children === 0) {
    data.childAge = null;
  }

  if (typeof rate === 'object' && rate !== null) {
    if ($.inArray(rate.type, rateTypes) !== -1) {
      data.ratePlan = null;
      data[rate.type] = rate.code;
    } else if (rate.type === 'promotional') {
      data.ratePlan = rate.code;
    } else {
      if (rate.type && rate.type != '' && rate.code && rate.code != '') {
        data.corpCode = rate.code;
      }
    }
  }

  if (rateCode && isLoyaltyRP(rateCode.toUpperCase())) { // If SRB rate plan is selected, make 'useWRPoints' as true.
    $('#redeemWRPoint').trigger('click');
    data.useWRPoints = true;
  }

  data.region = _REGION_;
  data.brandName = brandMap[getBrand()];

  setCriteria(data);
}

function goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify,dbSupport , bookingURL = authoredPaths.bookingURL, bookdata = {}) {
  let resForm = $('<form/>'),
    inp = $('<input>').attr({
      type: 'hidden'
    });
  resForm.attr('action', bookingURL);
  resForm.attr('method', 'POST');
  try {
    $(resForm).append(inp.clone().attr({
      name: 'ratePlan',
      value: (rp + ((isModify) ? ':modtruepath' : ''))
    }), inp.clone().attr({
      name: 'roomType',
      value: rt
    }), inp.clone().attr({
      name: 'corpCode',
      value: cc
    }), inp.clone().attr({
      name: 'autoEnroll',
      value: ae
    }), inp.clone().attr({
      name: 'qualPointsEarned',
      value: qp
    }), inp.clone().attr({
      name: 'pacRatePlan',
      value: pac
    }), inp.clone().attr({
      name: 'afterTax',
      value: at
    }), inp.clone().attr({
      name: 'propertyId',
      value: overview_propertyId || bookdata.overview_propertyId
    }), inp.clone().attr({
      name: 'orsId',
      value: overview_orsId || bookdata.overview_orsId
    }), inp.clone().attr({
      name: 'brandId',
      value: overview_brandId || bookdata.overview_brandId
    }), inp.clone().attr({
      name: 'brandTier',
      value: overview_brandTier || bookdata.overview_brandTier
    }), inp.clone().attr({
      name: 'roomImg',
      value: rImg
    }), inp.clone().attr({
      name: ':operation',
      value: 'nop'
    }), inp.clone().attr({
      name: 'redirect',
      value: authoredPaths.bookingRedirect
    }), inp.clone().attr({
      name: 'referringBrand',
      value: _criteria.referringBrand
    }), inp.clone().attr({
      name: 'referringTier',
      value: _criteria.referringTier
    }), inp.clone().attr({
      name: 'locale',
      value: _AEM_LOCALE_
    }), inp.clone().attr({
      name: 'directBillSupported',
      value: dbSupport
    }));
  } catch (e) {
    console.log('error setting book data');
  }
  sessionStorage.setItem("lbp", true);//DAI-16776
  $('body').append(resForm);
  $(resForm).submit();
}

function refreshAfterSSOLogin(targetUrl) {
  console.log("single signon refreshAfterLogin");
  let bookdata = getLocalStorage('bookingData');
  console.log(bookdata, targetUrl);
  if (targetUrl.includes('packages')) {
    goToPackages(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.at, '', false, bookdata.directBillSupported,targetUrl, bookdata);
  } else if (targetUrl.includes('book')) {
    const booking_rooms_and_guests = getSessionStorage(BOOKING_ROOMS_AND_GUESTS);
    if (booking_rooms_and_guests) {
      setCriteria({
        adults: booking_rooms_and_guests.adults,
        children: booking_rooms_and_guests.children,
        rooms: booking_rooms_and_guests.rooms
      });
      sessionStorage.removeItem(BOOKING_ROOMS_AND_GUESTS);
    }
    goToBook(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.at, '', false, bookdata.directBillSupported, targetUrl, bookdata);
  } else {
    window.location.href = DOMPurify.sanitize(targetUrl);
  }
}

function refreshAfterSSOLogout(targetUrl){
  const originalUrl = 'originalUrl';

  if (targetUrl.includes('packages')) {
    if (isLoyaltyRP(req_rate_plan)) {
      const logoutSourceURL = roomrate_redirect_url || '/' + getBrandName();
      setLocalStorage('postLogoutRedirect', true);
      setLocalStorage(originalUrl, {logoutSourceURL});
    } else {
      setLocalStorage('postLogoutPackagesRedirect', true);
      setLocalStorage(originalUrl, {logoutSourceURL: targetUrl});
    }
  } else if (targetUrl.includes('book')) {
    if (isLoyaltyRP(req_rate_plan)) {
      const logoutSourceURL = roomrate_redirect_url || '/' + getBrandName();
      setLocalStorage('postLogoutRedirect', true);
      setLocalStorage(originalUrl, {logoutSourceURL});
    } else {
      setLocalStorage('postLogoutPackagesRedirect', true);
      setLocalStorage(originalUrl, {logoutSourceURL: targetUrl});
    }
  } else {
    setLocalStorage('postLogoutRedirect', true);
    setLocalStorage(originalUrl, {logoutSourceURL: targetUrl});
  }
}

function bookDataBeforeSSOLogin(targetUrl) {
  let bookdata = ReservationHandler.getBookData();
  bookdata['overview_propertyId'] = overview_propertyId;
  bookdata['overview_orsId'] = overview_orsId;
  bookdata['overview_brandId'] = overview_brandId;
  bookdata['overview_brandTier'] = overview_brandTier;
  bookdata['nights'] = getCriteria().nights;
  const booking_rooms_and_guests = {
    adults: getCriteria().adults,
    children: getCriteria().children,
    rooms: getCriteria().rooms
  };
  setSessionStorage(BOOKING_ROOMS_AND_GUESTS, booking_rooms_and_guests);
  const propertyPhoneNumber = document.querySelector('.property-number');
  if (propertyPhoneNumber && propertyPhoneNumber.innerText) {
    setSessionStorage(PROPERTY_PHONE_NUMBER, {propertyPhoneNumber: propertyPhoneNumber.innerText});
  }
  setLocalStorage('bookingData',bookdata,true);
}

function goToPackages(rt, rp, cc, ae, qp, pac, at, rImg, isModify, dbSupport , packagesURL = authoredPaths.packagesURL, bookdata = {}) {
  let resForm = $('<form/>'),
    inp = $('<input>').attr({
      type: 'hidden'
    });
  resForm.attr('action', packagesURL);
  resForm.attr('method', 'POST');
  try {
    $(resForm).append(inp.clone().attr({
      name: 'ratePlan',
      value: (rp + ((isModify) ? ':modtruepath' : ''))
    }), inp.clone().attr({
      name: 'roomType',
      value: rt
    }), inp.clone().attr({
      name: 'corpCode',
      value: cc
    }), inp.clone().attr({
      name: 'autoEnroll',
      value: ae
    }), inp.clone().attr({
      name: 'qualPointsEarned',
      value: qp
    }), inp.clone().attr({
      name: 'pacRatePlan',
      value: pac
    }), inp.clone().attr({
      name: 'afterTax',
      value: at
    }), inp.clone().attr({
      name: 'propertyId',
      value: overview_propertyId || bookdata.overview_propertyId
    }), inp.clone().attr({
      name: 'orsId',
      value: overview_orsId || bookdata.overview_orsId
    }), inp.clone().attr({
      name: 'brandId',
      value: overview_brandId || bookdata.overview_orsId
    }), inp.clone().attr({
      name: 'brandTier',
      value: overview_brandTier || bookdata.overview_orsId
    }), inp.clone().attr({
      name: 'roomImg',
      value: rImg
    }), inp.clone().attr({
      name: ':operation',
      value: 'nop'
    }), inp.clone().attr({
      name: 'redirect',
      value: authoredPaths.packagesRedirect
    }), inp.clone().attr({
      name: 'referringBrand',
      value: _criteria.referringBrand
    }), inp.clone().attr({
      name: 'referringTier',
      value: _criteria.referringTier
    }), inp.clone().attr({
      name: 'locale',
      value: _AEM_LOCALE_
    }), inp.clone().attr({
      name: 'directBillSupported',
      value: dbSupport
    }));
  } catch (e) {
    console.log('error setting book data');
  }
  sessionStorage.setItem("lbp", true);//DAI-16776
  $('body').append(resForm);
  $(resForm).submit();
}

function setPropertyAvailability(obj, replace){
  setSessionStorage(PROPERTY_AVAILABILITY, obj, replace);
}

function getPropertyAvailability(){
  return getSessionStorage(PROPERTY_AVAILABILITY);
}

function removePropertyAvailability(){
  removeItemFromSession(PROPERTY_AVAILABILITY);
}

// wyndham-china-pipl-consent
function setPiplConsent(obj, replace){
  setSessionStorage(PIPL_CONSENT, obj, replace);
}

function getPiplConsent(){
  return getSessionStorage(PIPL_CONSENT);
}

function removePiplConsent(){
  removeItemFromSession(PIPL_CONSENT);
}

function setSearchOverview(obj, replace){
  setSessionStorage(SEARCH_OVERVIEW, obj, replace);
}

function getSearchOverview(){
  return getSessionStorage(SEARCH_OVERVIEW);
}

function removeSearchOverview(){
  removeItemFromSession(SEARCH_OVERVIEW);
}

function setRoomsRates(obj, replace){
  setSessionStorage(ROOMS_RATES, obj, replace);
}

function getRoomsRates(){
  return getSessionStorage(ROOMS_RATES);
}

function removeRoomsRates(){
  removeItemFromSession(ROOMS_RATES);
}

function deletePropAvailSessionData(){
  if(!(($_PAGE_.is('.search-results-page')) || ($_PAGE_.is('.property-page')))) {
    removePropertyAvailability();
  }
}

function deleteSearchOverviewSessionData(){
  if(!(($_PAGE_.is('.property-page')) || ($_PAGE_.is('.rooms-rates-page')) || ($_PAGE_.is('.booking-page')) || ($_PAGE_.is('.modify-page')))) {
    removeSearchOverview();
  }
}

function deleteRoomsRatesSessionData(){
  if(!(($_PAGE_.is('.rooms-rates-page')) || ($_PAGE_.is('.booking-page')) || ($_PAGE_.is('.modify-page')))) {
    removeRoomsRates();
  }
}

// BRAND NAME AND ID HANDLING
let _currentBrand = _isValidBrand(brand_id) ? brand_id : 'all',
  _brandTier = undefined;

function getBrand(_andIgnoreTier) {
  if (_andIgnoreTier === true) {
    return _currentBrand.toUpperCase();
  }
  if (_brandTier !== undefined) {
    return _brandTier.toUpperCase();
  }

  return _currentBrand.toUpperCase();
}

function getBrandTier() {
  return (_brandTier !== undefined) ? _brandTier : '';
}

function setBrandFromGlobal() {
  if (_isValidBrand(_currentBrand)) {
    if (_currentBrand in window.brandTierMap) {
      _brandTier = _currentBrand;

      _currentBrand = window.brandTierMap[_currentBrand];
    }

    _currentBrand = _currentBrand.toUpperCase();
  } else {
    _currentBrand = 'ALL';
  }
}

let _brandName =
  (typeof (brandMap) === 'object' && _isNotNull(getBrand())) ? brandMap[getBrand()] : null;

function getBrandName() {
  return _brandName;
}

function setBrandName(val) {
  if (_isNotNull(val)) {
    _brandName = val;
  } else if (typeof (brandMap) === 'object') {
    _brandName = brandMap[getBrand()];
  }
}

function setBrand(brand) {
  if (brand && _isValidBrand(brand)) {
    _currentBrand = brand;
  } else {
    setBrandFromGlobal();
  }

  if (_isNotNull(getBrand())) {
    setBrandName();
  }

  updateQueryParameter('brand_id', getBrand());
  updateQueryParameter('brandTier', getBrandTier());
}

function setRadius() {
  // Radius should persist if place_id remains the same, or update to getDefaultSearchRadius() if is in a new search.
  let hasStoredLoc = _isNotNull(getSessionCriteria()) && _isNotNull(getSessionCriteria().loc),
    storedLoc = hasStoredLoc ? getSessionCriteria().loc : null,
    urlLoc = getQueryParameter('loc'),
    hasStoredLocale = _isNotNull(getSessionCriteria()) && _isNotNull(getSessionCriteria().locale),
    storedLocale = hasStoredLocale ? getSessionCriteria().locale : null,
    urlLocale = _LOCALE_;

  // Compare if different place_id
  if (_isNotNull(storedLoc) &&
      _isNotNull(urlLoc) &&
      storedLoc !== urlLoc) {
    setCriteria({
      radius: getDefaultSearchRadius()
    });
  }

  // Compare if locale has changed
  if (_isNotNull(storedLocale) &&
      _isNotNull(urlLocale) &&
      storedLocale !== urlLocale) {
    setCriteria({
      radius: getDefaultSearchRadius()
    });
  }
}

function setLocale() {
  setCriteria({
    locale: _LOCALE_
  });
}

// RESERVATION HANDLER
class Reservation {
  constructor() {
    this.id = '#revReservForm';
    this._retrieveReservation = {};
    this.bookData = {};
    this.init();
  }
  setReviewReservation(obj) {
    if (obj) {
      this._retrieveReservation = obj;
    }
  }
  getReviewReservation() {
    return this._retrieveReservation;
  }
  retrieveReservation() {
    if (_isNull(this._retrieveReservation.customer)) {
      let fName = $('input[name="first"]').val() || this._retrieveReservation.firstName,
        lName = $('input[name="last"]').val() || this._retrieveReservation.lastName,
        confNum = $('input[name="confirmation"]').val() || this._retrieveReservation.confNum,
        $notFound = $('.reservation-not-found p'),
        params = {
          firstName: fName.trim(),
          lastName: lName.trim(),
          confirmation: confNum.trim(),
          hotelBrand: 'ALL',
          language: _LOCALE_
        };

      $.ajax({
        url: getSecureHostedDomainUrl() + getServiceUrl('retrieveReservation'),
        data: params,
        type: 'POST',
        success: (res) => {
          if (res && res.status === 'OK' && res.retrieve) {
            let localeFixed = getLocaleUrlToken(); // WRM-3894
            if (/wr/i.test(getBrand()) && /en.ap/i.test(localeFixed)) { // WRM-3894
              localeFixed = '/en-uk'; // WRM-3894
            } // WRM-3894
            let brandUrlToken = brandMap[res.retrieve.property.brandTier] || brandMap[res.retrieve.property.brand];
            $(this.id).attr('action', localeFixed + '/' + brandUrlToken + '/reservation/view');

            this.setReviewReservation(res.retrieve);

            if (exists(this.id)) {
              let todayDate = new Date();
              todayDate.setHours(0,0,0,0);
              let data = res.retrieve,

                checkInDate = getDateFromDateString(data.rooms[0].CheckInDate),
                checkOutDate = getDateFromDateString(data.rooms[0].CheckOutDate),
                nights = getNumDays(checkInDate, checkOutDate),
                giftCardPrice = (_isNotNull(data.customer.Comments)) ? parseFloat(data.customer.Comments.split('-')[1]) : '',
                taxTotal = parseFloat(data.rooms[0].TotalAfterTax - data.rooms[0].TotalBeforeTax).toFixed(2),
                currency = data.rooms[0].CurrencyCode,
                taxList = (_isNotNull(data.rooms[0].taxList)) ? JSON.stringify(data.rooms[0].taxList) : (_isNotNull(data.rooms[0].taxInfo) ? data.rooms[0].taxInfo : ''),
                dateRateMap = (_isNotNull(data.rooms[0].dateRateMap)) ? (JSON.stringify(data.rooms[0].dateRateMap)) : '',
                dateTaxAmountMap = (_isNotNull(data.rooms[0].dateTaxAmountMap)) ? (JSON.stringify(data.rooms[0].dateTaxAmountMap)) : '',
                dateRateFeeMap = (_isNotNull(data.rooms[0].dateRateFeeMap)) ? (JSON.stringify(data.rooms[0].dateRateFeeMap)) : '',
                addressString = this.checkShouldHideProvinceCountry(data),
                totalAfterPacakge = parseFloat(data.rooms[0].TotalAfterTax)
                + (data.services ? data.services.map((service)=> service.prices)
                  .flat(1)
                  .map((price)=>price.amountAfterTax && parseFloat(price.amountAfterTax))
                  .reduce((amount,total)=>amount + total,0) : 0),

                obj = {
                  propertyName: data.property.name,
                  propertyAddress: addressString,
                  propertyPhone: data.property.phone1,
                  confirmationNumber: data.confirmation,
                  confirmationNumbers: data.confirmation,
                  firstName: data.customer.FirstName,
                  lastName: data.customer.LastName,
                  roomDescriptionLong: data.rooms[0].roomTypeDescription,
                  rooms: data.rooms[0].Units,
                  nights: nights,
                  adults: data.rooms[0].nAdults,
                  children: data.rooms[0].nChildren,
                  childAge: data.rooms[0].childAge,
                  checkInDateFormatted: formatDateForPrinting(checkInDate, 'weekdayCompact'),
                  checkInDate: data.rooms[0].CheckInDate,
                  checkOutDateFormatted: formatDateForPrinting(checkOutDate, 'weekdayCompact'),
                  checkOutDate: data.rooms[0].CheckOutDate,
                  checkInTime: _isNotNull(data.property.checkInTime) ? formatTimeShow(data.property.checkInTime) : '',
                  checkOutTime: _isNotNull(data.property.checkOutTime) ? formatTimeShow(data.property.checkOutTime) : '',
                  roomDescriptionShort: data.rooms[0].Description,
                  subtotal: handlePostSubtotal(data.rooms[0].TotalBeforeTax, giftCardPrice, currency, data.rooms[0].RatePlanCode),
                  taxTotal: getCurrencyMapping(currency,true) + taxTotal + ' ' + currency,
                  taxList: taxList,
                  totalCash: !(isGoFreeRate(data.rooms[0].RatePlanCode)) ? getCurrencyMapping(currency,true) + parseFloat(totalAfterPacakge).toFixed(2) + ' ' + currency : '',
                  totalPoints: formatNumber(data.rooms[0].points || ''),
                  cancellationPolicy: data.rooms[0].cancelPolicyDesc,
                  depositPolicy: data.rooms[0].depositPolicy,
                  giftCard: _isNumber(giftCardPrice) ? getCurrencyMapping(currency,true) + giftCardPrice.toFixed(2) + ' ' + currency : '',
                  propertyId: data.property.propertyId,
                  roomType: data.rooms[0].roomTypeCode,
                  brandId: data.property.brand,
                  brandTier: data.property.brandTier,
                  currencyCode: currency,
                  rateCode: data.rooms[0].RatePlanCode,
                  ratePlanName: data.rooms[0].ratePlanName ? data.rooms[0].ratePlanName : '',
                  roomCode: data.rooms[0].roomTypeCode,
                  cancellable: data.cancellable,
                  modifiable: (getDateFromDateString(todayDate) <= getDateFromDateString(checkInDate)) ? data.modifiable : 'N',
                  cancelled: data.cancellation,
                  email: data.customer.Email,
                  comments: (data.customer.Comments && data.customer.Comments.includes('BNPL')) ? (data.customer.Comments).split(' ').slice(1).join(' ') : data.customer.Comments,
                  retrieveBnplComments:(data.customer.Comments && data.customer.Comments.includes('BNPL')) ? 'BNPL' : '',
                  customerCity: data.customer.City,
                  customerState: data.customer.State,
                  customerCountry: data.customer.Country,
                  customerZip: data.customer.PostalCode,
                  customerAddress: data.customer.Address,
                  customerPhoneNum: data.customer.PhoneNumber,
                  reservationChannel: data.reservationChannel,
                  directBillNumber: data.directBillNumber,
                  directBillProjectNumber: data.directBillProjectNumber,
                  directBillVCCExpiryDate: data.directBillVCCExpiryDate,
                  dateRateMap: dateRateMap, //daily breakup of rate for more than two days of booking
                  dateTaxAmountMap: dateTaxAmountMap, //daily breakup of rate for more than two days of booking
                  dateRateFeeMap: dateRateFeeMap, //daily breakup of rate for more than two days of booking
                  packages:  this.packagesFilter(data), //service list booked along with booking
                  channel:  data.channelID || data.channelId, //service list booked along with booking
                };

              this.postReservationDataToAEM(obj, $('#revReservForm'), 'find');
            } else {
              EventHandler.send(EventHandler.retrieveReservation.success);
            }
          } else {
            $notFound.show();
          }
        },
        error: function(res) {
          EventHandler.triggerEvent('brands-error', res);
          console.log('Error calling retrieveReservation', res);
          $notFound.show();
        }
      });
    }
  }

  checkShouldHideProvinceCountry(data) {
    let shouldHideProvinceCountry = HideProvinceCountryConfig.getHideProvinceCountryConfig(data.property.propertyId);

    if (shouldHideProvinceCountry) {
      return data.property.address1 + (data.property.address2 ? ' ' + data.property.address2 : '') +
        (data.property.address3 ? ' ' + data.property.address3 : '') + ', ' + data.property.city +
        ' ' + data.property.postalCode;
    }
    return data.property.address1 + (data.property.address2 ? ' ' + data.property.address2 : '') +
      (data.property.address3 ? ' ' + data.property.address3 : '') + ', ' + data.property.city + ', ' +
      (data.property.state ? data.property.state : data.property.country) + ' ' + data.property.postalCode;
  }
  packagesFilter(data){
    return data.services ? JSON.stringify(Object.values(data.services.reduce((servicesA, servicesB) => {
      if (servicesA[servicesB.comments.title] && servicesA[servicesB.comments.title].comments.title === servicesB.comments.title){
        servicesB.prices[0]['effectiveDate'] = servicesB.startTime;
        servicesA[servicesB.comments.title].prices = [ ...servicesA[servicesB.comments.title].prices, ...servicesB.prices];
        // servicesA[servicesB.comments.title].prices[0].amountAfterTax = parseFloat(servicesA[servicesB.comments.title].prices[0].amountAfterTax) + parseFloat(servicesB.prices[0].amountAfterTax);
        // servicesA[servicesB.comments.title].prices[0].amountBeforeTax = parseFloat(servicesA[servicesB.comments.title].prices[0].amountBeforeTax) + parseFloat(servicesB.prices[0].amountBeforeTax);
        servicesA[servicesB.comments.title].quantity = parseFloat(servicesA[servicesB.comments.title].quantity) + parseFloat(servicesB.quantity);
      }else{
        servicesB.prices[0]['effectiveDate'] = servicesB.startTime;
        servicesA[servicesB.comments.title] = servicesB;
      }
      return servicesA;
    }, []))) : null;
  }

  init() {
    if (exists(this.id)) {
      $(() => {
        $(this.id).submit((e) => {
          e.preventDefault();

          this.retrieveReservation();
        });
      });
      EventHandler.send('updateSearchBrandValue');
    } else if ($_PAGE_.is('.confirmation-page')) {
      this.setReviewReservation(reservationDataFromAEM);
    }
  }
  postReservationDataToAEM(dataObj, $form, referrer, opts) {
    if (exists($form) && typeof dataObj === 'object') {
      if (typeof opts !== 'object') {
        opts = {};
      }
      if (!('submit' in opts)) {
        opts.submit = true;
      }

      if (!('cancelled' in dataObj)) {
        dataObj.cancelled = 'N';
      }
      if (!('referrer' in dataObj)) {
        dataObj.referrer = referrer;
      }
      if (!('brandId' in dataObj)) {
        dataObj.brandId = getBrand(true);
      }
      if (!('brandTier' in dataObj)) {
        dataObj.brandTier = getBrandTier();
      }
      if (!('propertyId' in dataObj)) {
        dataObj.propertyId = getCriteria().propertyId;
      }
      if( !( 'locale' in dataObj ) ) {
        dataObj.locale = _AEM_LOCALE_;
      }

      dataObj._charset_ = 'UTF-8';

      for (let key in dataObj) {
        if (_isWeakTrue(dataObj[key]) || dataObj[key] === 'y') {
          dataObj[key] = 'Y';
        } else if (_isWeakFalse(dataObj[key]) || dataObj[key] === 'n') {
          dataObj[key] = 'N';
        }

        let $input = $(document.createElement('input'))
          .attr('type', 'hidden')
          .attr('name', key)
          .val(dataObj[key]);

        $form.append($input);
      }

      if (opts.callback && typeof opts.callback === 'function') {
        opts.callback();
      }
      if (opts.submit === true) {
        $form.off('submit');
        $form[0].submit();
      }
      setCriteria({
        adults: dataObj.adults,
        brandId: dataObj.brandId,
        brandTier: dataObj.brandTier,
        checkInDate: formatDateForBWS(dataObj.checkInDate),
        checkOutDate: formatDateForBWS(dataObj.checkOutDate),
        children: dataObj.children,
        childAge: dataObj.childAge,
        nights: dataObj.nights,
        rooms: dataObj.rooms,
        propertyId: dataObj.propertyId,
        updated: true
      });
    }
  }
  getBookData() {
    return this.bookData || null;
  }
  setBookData(key, val) {
    this.bookData[key] = val;
  }
}

let ReservationHandler = new Reservation();

// HANDLER FUNCTIONS FOR SEARCHES ON MAIN AND MINI BOOKING BARS
/**
 * Designed for Mini Booking Bar
 * Update URL parameters and send to search without calling getSearchUrl
 */
function goToRoomsRates() {
  //encode parameters to protect against malicious code.
  // queryParam = encodeURIComponent( queryParam );
  let propertyPath = window.location.pathname,
    nth = 0,
    queryParam,
    replaceAfter = propertyPath.match('/' + _SEO_LOCALE_ + '/') || propertyPath.match('/en-gb|es-la/') ? 5 : 4;
  if (propertyPath.match('/en-gb|es-la/')) {
    propertyPath = propertyPath.replace(/(\/en-gb\/)|(\/es-la\/)/g, '/' + _SEO_LOCALE_ + '/');
  }
  queryParam = buildSearchQuery(); // build query param while we wait - hopefully this is faster than the service
  propertyPath = propertyPath.replace(/\//g, (match, i, original) => {
    nth++;
    return (nth === replaceAfter) ? '/rooms-rates|' : match;
  });
  let propertyURL = window.location.origin + propertyPath.split('|')[0];
  window.location.assign(propertyURL + queryParam);
}

function handleNonTextSearch(searchPath) {
  let queryParam;
  prepareBookingBarValues();
  queryParam = buildSearchQuery();
  window.location.assign(searchPath + queryParam);
}

function buildSearchQuery() {
  normalizeSearchCriteria();

  let sc = getCriteria(),
    query = '';

  for (let i = 0; i < queryParams.length; i++) {
    let key = queryParams[i],
      value = sc[key];

    if(key === 'brand_id'){
      value = getBrand();
    }

    if (_isNotNull(value)) {
      if (i === 0) {
        query = '?' + key + '=' + value;
      } else {
        query += '&' + key + '=' + value;
      }
    }
  }

  return query;
}

// SEARCH URL STUFF
/**
 * Creates a slug url friendly from a value provided.
 **/
function textToSlug(value) {
  if (value && value.length > 0) {
    value = removeDiacritics(value);
    return value.toString().toLowerCase()
      .replace(/['`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      .replace(/\s+/g, '-')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  //Fallback in case that value is null or length 0.
  return value;
}

function getUrlBrandNamePath() {
  let brandName = getBrandName(),
    excludedBrands = ['wyndham-hotel-group'];
  if (_.contains(excludedBrands, brandName)) {
    return '';
  } else {
    return '/' + brandName;
  }
}
/**
 * This function is used to generate the portion of the url with the name of the city + (state || country).
 **/
function getUrlPathFromLocationDetails() {
  let url = getLocaleUrlToken(),
    locationDetail = getLocationDetails(),
    locationsPagePath = getUrlBrandNamePath() + '/locations',
    searchResultsPagePath = '';

  if (_isNotNull(getCriteria().searched)) {
    searchResultsPagePath += textToSlug(getCriteria().searched);
  } else if (_isNotNull(locationDetail.location)) {
    searchResultsPagePath += textToSlug(locationDetail.location);
  }

  // Hotels urls.
  if (searchResultsPagePath.length > 0) {
    url += '/hotels/' + searchResultsPagePath;
  } else {
    url += locationsPagePath;
  }

  // Return the final url without parameters.
  return url;
}
/**
 * This function replace the getSearchUrl service for a local javascript implementation.
 **/
function navigateToSearchResults() {
  EventHandler.one(EventHandler.criteria.updated, () => {
    let url, params;
    $(window).off(unloadEvent);
    url = getUrlPathFromLocationDetails();
    params = url.match(/\/locations/) ? '?noResultFound=true' : buildSearchQuery();
    // Navigate to location. criteria should be saved from page unload event.
    window.location.assign(url + params);
  });

  prepareBookingBarValues();
  setCriteria({
    brandId: getBrand(),
    radius: getDefaultSearchRadius('')
  }, true);
}

function setSiteWideAlert(key, obj) {
  //setLocalStorage(key, obj);
  setSessionStorage(key, obj);
}

function getSiteWideAlert() {
  //return getLocalStorage(SITE_WIDE_ALERT);
  return getSessionStorage(SITE_WIDE_ALERT);
}

function setWRCCCodes(res) {
  let creditCards = [];
  if (res.paymentCardAccounts) {
    $.each(res.paymentCardAccounts, (i, card) => {
      creditCards.push(card.typeCode);
    });
    setLocalStorage(WR_CC_CODES, creditCards, true);
	isWRCCHolder();
  }
}

function getWRCCCodes() {
  return getLocalStorage(WR_CC_CODES);
}

function isWRCCHolder() {
  let isWRCCHolderFlag = false;
  if(CookieHandler.cidAuthenticated()) {
    let crediCards = getWRCCCodes();
    $.each(crediCards, (i, card) => {
      let wrCPCCodes = new RegExp('\\b(' + wrccCodes + ')\\b', 'ig');
      if(card.match(wrCPCCodes)) {
        isWRCCHolderFlag = true;
      }
    });
	setLocalStorage(WR_CC_HOLDER, isWRCCHolderFlag, true);
  }
  return isWRCCHolderFlag;
}

function removeCorporateCodeFromCriteria() {
  try {
    const criteria = getCriteria();
    const localCorpCode = localStorage.getItem('userCorporateCode');
    localStorage.removeItem('userCorporateCode');
    if(criteria.corpCode && criteria.corpCode === localCorpCode) {
      setCriteria({
        corpCode: null
      });
    }
  } catch(ex) {
    console.log('removeCorporateCodeFromCriteria failed[ Exception Caught ] :: ', ex);
  }
}

let UserHandler = new User();

export {
  getDefaultCriteria,
  getCriteria,
  setCriteria,
  getSessionCriteria,
  setSessionStorage,
  getSessionStorage,
  removeItemFromSession,
  setLocalStorage,
  getLocalStorage,
  setIntlLocationInfo,
  setLocationDetails,
  getLocationDetails,
  initCriteria,
  normalizeSearchCriteria,
  prepareBookingBarValues,
  goToBook,
  goToPackages,
  setPropertyAvailability,
  getPropertyAvailability,
  removePropertyAvailability,
  setSearchOverview,
  getSearchOverview,
  removeSearchOverview,
  setRoomsRates,
  getRoomsRates,
  removeRoomsRates,
  deletePropAvailSessionData,
  deleteSearchOverviewSessionData,
  deleteRoomsRatesSessionData,
  getBrand,
  setBrand,
  getBrandTier,
  getBrandName,
  setBrandName,
  ReservationHandler,
  goToRoomsRates,
  buildSearchQuery,
  handleNonTextSearch,
  getUrlPathFromLocationDetails,
  navigateToSearchResults,
  UserHandler,
  User,
  setSiteWideAlert,
  getSiteWideAlert,
  setWRCCCodes,
  isWRCCHolder,
  setPiplConsent,
  getPiplConsent,
  removePiplConsent,
  refreshAfterSSOLogin,
  bookDataBeforeSSOLogin,
  refreshAfterSSOLogout,
  // bindFullProfileCall,
  // checkUserWRLoginSession
};
