import { templateUrls, HREF } from '../vars';
import ConfigsUtils from '../../base/aem-configs/config-utils';

/**
 * Class representing Tally Analytics.
 *
 * This class is responsible for tracking user interactions related to the Tally and Okta systems,
 * and it sends the tracked data to an analytics service.
 */
class TallyAnalytics {

  /**
   * Create an instance of TallyAnalytics.
   */
  constructor() {
    const uriWRJoin = templateUrls.joinPage;
    const uriWRFtsi = templateUrls.firstTimeSignInPage;
    const uriWRVerifyIdentity = templateUrls.verifyIdentityPage;
    const uriProfileSetup = templateUrls.profileSetup;
    const hrefCurrent = '' + $(location).attr(HREF);
    this.isJoinPage = hrefCurrent.includes(uriWRJoin);
    this.isFirstTimeSignInPage = hrefCurrent.includes(uriWRFtsi);
    this.isVerifyIdentityPage = hrefCurrent.includes(uriWRVerifyIdentity);
    this.isProfileSetupPage = hrefCurrent.includes(uriProfileSetup);
    this.timeOutDelay = ConfigsUtils.getJoinNowTimeout() || 2000;
    this.setupMutationObserver();
    // reset Tally-Okta digital data
    window.digitalData.tallyOkta = window.digitalData.tallyOkta || {};
  }   

  // Detect if Mobile
  mobilePlatform() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const hasMobileAppParams = urlParams.has('platform');
      if (hasMobileAppParams){
        const mobilePlatform = urlParams.get('platform');
        window.digitalData.platform = mobilePlatform;
        return;
      }
    } catch (error) {
      console.error('Could not read the URL param', error);
    }
  }
  
  /**
   * Sets up a mutation observer to detect changes in the DOM.
   *
   * This method allows the object to react to changes in the page's structure,
   * including the addition of elements that need to be tracked.
   */
  setupMutationObserver() {
    const observer = new MutationObserver((mutations) => { 
      if(mutations.length > 0){
        this.attachRewardsAccountVerifiedByToggleEvent();
      }
    });

    const targetNode = document.body;
    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);
  }

  /**
   * Attaches click event to the 'sign-in' buttons,
   * enabling user interactions with this element to be tracked.
   */
  attachSignInClickEvent() {
    try {
      const WRSignInDropDown = document.querySelector('.top-nav-item.sign-in'); // WR sign-in dropdown for desktop
      const WRSignInButtons = document.querySelectorAll('.main-login-form .btn-primary'); // WR sign-in buttons
      const signInButton = document.querySelector('.sign-in .btn-primary'); // non-WR sign-in buttons for desktop
      const signInButtonNew = document.querySelector('.sign-in-new .btn-primary'); // non-WR sign-in buttons for desktop
      const signInButtonNewMobile = document.querySelector('.sign-in-new-mob .btn-primary'); // non-WR sign-in buttons for Mobile
      const signInMobileTablet = document.querySelector('.wr-login-links.signin-button'); // non-WR sign-in link for mobile/tablet

      const allSignInLinks = [WRSignInDropDown, signInButton, signInButtonNew, signInButtonNewMobile, signInMobileTablet].concat(...WRSignInButtons);

      allSignInLinks.forEach((bttn) => {
        if (bttn) {
          bttn.addEventListener('click', this.handleSignInClickEvent.bind(this));
        }
      });
    } catch (error) {
      console.error('Could not find the \'Sign-In\' button -', error);
    }
  }

  /**
   * Attaches click event to the 'sign-in' links on the enrollment page and FTSI page,
   * enabling user interactions with this element to be tracked.
   */
  attachSignInLinkClickEvent() {
    try {
      setTimeout(() => {
        const signInLink = document.querySelector('#sign-in-link'); // this id must be authored
        if (signInLink) {
          signInLink.addEventListener('click', this.handleSignInClickEvent.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'Sign-In\' link -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'sign-in' buttons are clicked
   */
  handleSignInClickEvent(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('SignInButtonClick');
      console.log('SignInButtonClick');
    } catch (error) {
      console.error('Could not track the \'Sign-In\' button click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Join' buttons, 
   * enabling user interactions with this element to be tracked.
   */
  attachJoinClickEvent() {
    try {
      const wrJoinButton = document.querySelector('.main-nav-login .join-now a'); // WR Join buttons
      const wrJoinNowLinks = document.querySelectorAll('.login-join-links .join-link a'); // WR Join links
      const dropdownJoinButton = document.querySelector('.sign-in .btn-secondary'); // non-WR Join buttons for desktop
      const dropdownJoinButtonNew = document.querySelector('.sign-in-new .btn-secondary'); // non-WR Join buttons for desktop
      const dropdownJoinButtonNewMob = document.querySelector('.sign-in-new-mob .btn-secondary'); // non-WR Join buttons for Mobile
      const joinMobileTablet = document.querySelectorAll('a.wr-login-links')[1]; // non-WR Join link for mobile/tablet

      const allJoinButtons = [wrJoinButton, dropdownJoinButton, dropdownJoinButtonNew, dropdownJoinButtonNewMob, joinMobileTablet].concat(...wrJoinNowLinks);

      allJoinButtons.forEach((bttn) => {
        if (bttn) {
          bttn.addEventListener('click', this.handleJoinClickEvent.bind(this));
        }
      });
    } catch (error) {
      console.error('Could not find the \'Join\' button -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'Join' buttons are clicked
   */
  handleJoinClickEvent(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('JoinButtonClick');
    } catch (error) {
      console.error('Could not track the \'Join\' button click event -', error);
    }
  }

  /**
   * Attaches click event to the 'forgot password' link, 
   * enabling user interactions with this element to be tracked.
   */
  attachForgotPasswordClickEvent() {
    try {
      const forgotPasswordLink = document.querySelector('.main-login-form .login-forgot-password');
      if (forgotPasswordLink) {
        forgotPasswordLink.addEventListener('click', this.handleForgotPasswordClickEvent.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'forgot password\' link -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'forgot password' link is clicked
   */
  handleForgotPasswordClickEvent(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('ForgotPasswordClick');
    } catch (error) {
      console.error('Could not track the \'forgot password\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Look it Up' link, 
   * enabling user interactions with this element to be tracked.
   */
  attachLookitUpHereLinkClickEvent() {
    try {
      setTimeout(() => {
        const lookItUpLink = document.querySelector('#look-it-up-link');

        if (lookItUpLink) {
          lookItUpLink.addEventListener('click', this.handleLookitUpHereLinkClick.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'Look It Up\' link -', error);
    }
  }

  /**
   * This method handles how the 'Look it Up link click event is tracked
   */
  handleLookitUpHereLinkClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('LookitUpHereLinkClick');
    } catch (error) {
      console.error('Could not track the \'Look It Up\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Set Up Your Account' link,
   * enabling user interactions with this element to be tracked.
   */
  attachSetUpYourAccountClickEvent() {
    try {
      const setUpYourAccountBrandLinks = document.querySelectorAll('.myacc-links');
      const setUpYourAccountWRLinks = document.querySelectorAll('.login-join-links .new-account a');
      const setUpYourAccountLinks = [].concat(...setUpYourAccountWRLinks).concat(...setUpYourAccountBrandLinks);

      if (setUpYourAccountLinks) {
        setUpYourAccountLinks.forEach((bttn) => {
          if (bttn) {
            bttn.addEventListener('click', this.handleSetUpYourAccountClick.bind(this));
          }
        });
      }

    } catch (error) {
      console.error('Could not find the \'Set Up Your Account\' link -', error);
    }
  }

  /**
   * This method handles how the 'Set Up Your Account' link click event is tracked
   */
  handleSetUpYourAccountClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('SetupYourAccount');
    } catch (error) {
      console.error('Could not track the \'Set Up Your Account\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'My Account' link,
   * enabling user interactions with this element to be tracked.
   */
  attachMyAccountClickEvent() {
    try {
      const myAccountLink = document.querySelector('.wyndham-rewards-logged-in-container.sign-in .login-redirect-link');

      if (myAccountLink) {
        myAccountLink.addEventListener('click', this.handleMyAccountClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'My Account\' link -', error);
    }
  }

  /**
   * This method handles how the 'My Account' link click event is tracked
   */
  handleMyAccountClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('MyAccountLinkClick');
    } catch (error) {
      console.error('Could not track the \'My Account\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'sign-out' buttons,
   * enabling user interactions with this element to be tracked.
   */
  attachSignOutClickEvent() {
    try {
      const signOutLinks = document.querySelectorAll('[href="#logout"]'); // sign-out links on my profile page
      const signOutButtons = document.querySelectorAll('.sign-out-button'); // sign-out buttons and links in navbars and hamburger menus

      const allSignOutButtons =  [].concat(...signOutButtons).concat(...signOutLinks);

      allSignOutButtons.forEach((bttn) => {
        if (bttn) {
          bttn.addEventListener('click', this.handleSignOutClick.bind(this));
        }
      });
    } catch (error) {
      console.error('Could not find the \'Sign-Out\' button -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'sign-out' buttons are clicked
   */
  handleSignOutClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('SignOutButtonClick');
    } catch (error) {
      console.error('Could not track the \'Sign-Out\' button click event -', error);
    }
  }

  /**
   * Attaches change event to the account verification radio buttons,
   * enabling user interactions with this element to be tracked.
   */
  attachRewardsAccountVerifiedByToggleEvent() {
    try {
      setTimeout(() => {
        const accountVerificationRadioButtons = document.getElementsByName('verificationChoice');
        accountVerificationRadioButtons.forEach((accountVerificationRadioButton) => {
          if (accountVerificationRadioButton.checked) {
            window.digitalData.tallyOkta.rewardsAccountVerifiedByToggle = accountVerificationRadioButton.value;
          }
          accountVerificationRadioButton.addEventListener('change', this.handleRewardsAccountVerifiedByToggle.bind(this));
        });
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the account verification radio buttons -', error);
    }
  }

  /**
   * This method handles how data is tracked when the account verification radio buttons is clicked
   */
  handleRewardsAccountVerifiedByToggle(event) {
    try {
      window.digitalData.tallyOkta.rewardsAccountVerifiedByToggle = event.srcElement.value;
    } catch (error) {
      console.error('Could not fire the account verification radio buttons\' event -', error);
    }
  }

  /**
   * Attaches change event to the "Travel for Business?" checkbox,
   * enabling the customer type select dropdown to be reset to an empty value
   */
  attachTravelForBusinessCheckEvent() {
    try {
      setTimeout(() => {
        const businessEnrollmentCheckbox = document.querySelector('#businessEnrollmentCheckbox');
        if (businessEnrollmentCheckbox) {
          businessEnrollmentCheckbox.addEventListener('change', this.handleTravelForBusinessCheckbox.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'Travel for Business\' checkbox -', error);
    }
  }

  /**
   * This method handles accounts for the "Travel for Business?" checkbox to be unchecked
   * enabling the customer type select dropdown to be reset to an empty value
   */
  handleTravelForBusinessCheckbox(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      let eventElement = event.srcElement;
      if (!eventElement.checked) {
        window.digitalData.tallyOkta.travelForBusinessCustomerType = '';
      }
    } catch (error) {
      console.error('Could not fire the \'Travel for Business\' checkbox\'s change event -', error);
    }
  }

  /**
   * Attaches change event to the "Customer Type" select dropdown,
   * enabling user interactions with this element to be tracked.
   */
  attachCustomerTypeSelectEvent() {
    try {
      window.digitalData.tallyOkta.travelForBusinessCustomerType = '';
      setTimeout(() => {
        const customerTypeSelectDropdown = document.querySelector('.business-enrollment-select select');
        if (customerTypeSelectDropdown) {
          customerTypeSelectDropdown.addEventListener('change', this.handleCustomerTypeSelect.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find \'Customer Type\' select -', error);
    }
  }

  /**
   * This method handles how data is tracked when the "Customer Type" select dropdown is changed
   */
  handleCustomerTypeSelect(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

      let eventElement = event.srcElement;
      window.digitalData.tallyOkta.travelForBusinessCustomerType = eventElement.options[eventElement.selectedIndex].text;
    } catch (error) {
      console.error('Could not fire the \'Customer Type\' select\'s change event -', error);
    }
  }

  /**
   * Attaches change event to the "Join Now" button,
   * enabling user interactions with this element to be tracked.
   */
  attachJoinNowEvent() {
    try {
      setTimeout(() => {
        const joinNowButton = document.querySelector('#liteEnroll .btn-primary.btn-block');

        if (joinNowButton) {
          joinNowButton.addEventListener('click', this.handleJoinNowClick.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find \'Join Now\' button -', error);
    }
  }

  /**
   * This method handles how data is tracked when the "Join Now" button is clicked
   */
  handleJoinNowClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

      if (window.digitalData.tallyOkta.travelForBusinessCustomerType && window.digitalData.tallyOkta.travelForBusinessCustomerType != '') {
        this.satelliteTracker('wyndhamRewardsJoinClick');
      }
      if (window.digitalData.SMS.smsOptIn && window.digitalData.SMS.smsOptIn == 'Yes') {
        this.satelliteTracker('smsOptIn');
      }
      this.satelliteTracker('rewardsAccountVerifiedByToggle');
    } catch (error) {
      console.error('Could not fire the \'Join Now\' button\'s click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Resend Code' link,
   * enabling user interactions with this element to be tracked.
   */
  attachResendVerificationCodeLinkEvent() {
    try {
      const resendCodeLink = document.querySelector('#resend-code');

      if (resendCodeLink) {
        resendCodeLink.addEventListener('click', this.handleResendVerificationCodeLinkClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'Resend Code\' link -', error);
    }
  }

  /**
   * This method handles how the 'Resend Code' link click event is tracked
   */
  handleResendVerificationCodeLinkClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('ResendCodeLinkClick');
    } catch (error) {
      console.error('Could not track the \'Resend Code\' link click event -', error);
    }
  }

  /**
   * Initiates a mutation observer used to capture error messages as they appear,
   * enabling the understanding of a user's mistakes or failures in the website
   */
  initiateGeneratedErrorCapture() {
    try {
      window.digitalData.error.errorInfo.errorFormMessage = '';
      var eventTriggered = false;

      const errorObserver = new MutationObserver((mutations) => { 
        if(mutations.length > 0){
          const groupedMutations = [];
          for (let i = 0; i < mutations.length; i++) {
            if (
              mutations[i].attributeName === 'class' &&
              groupedMutations.indexOf(mutations[i].target.className) == -1 &&
              mutations[i].target.classList.contains('has-error') &&
              !eventTriggered) {
                groupedMutations.push(mutations[i].target.className);
                //console.log("help-block", mutations[i].target.className, i); 
                window.digitalData.error.errorInfo.errorFormMessage = mutations[i].target.querySelector('.help-block').innerText;
                window.digitalData.error.errorInfo.errorMessage = window.digitalData.error.errorInfo.errorFormMessage;
                this.satelliteTracker('ErrorGenerated');
                eventTriggered = true;
              } else if (
              mutations[i].attributeName === 'class' &&
              groupedMutations.indexOf(mutations[i].target.className) == -1 &&
              mutations[i].target.classList.contains('form-input-error') &&
              !eventTriggered) { 
                groupedMutations.push(mutations[i].target.className); 
                //console.log("error-msg-two", mutations[i].target.className, i) 
                window.digitalData.error.errorInfo.errorFormMessage = mutations[i].target.querySelector('.error-msg-two').innerText;
                window.digitalData.error.errorInfo.errorMessage = window.digitalData.error.errorInfo.errorFormMessage;
                this.satelliteTracker('ErrorGenerated');  
                eventTriggered = true;
              } else if (
              mutations[i].attributeName === 'class' &&
              groupedMutations.indexOf(mutations[i].target.className) == -1 &&
              (mutations[i].target.classList.contains('has-success') || !mutations[i].target.classList.contains('has-error')) &&
              eventTriggered) {   
                var closestParent = mutations[i].target.parentElement.closest('.form-group');
                if (closestParent == null || !closestParent.classList.contains('has-error')){ 
                  groupedMutations.push(mutations[i].target.className);
                  eventTriggered = false;
                } 
            }   
          }   
        }
      }); 

      const targetNode = document.body;
      const config = { attributes: true, childList: true, subtree: true };

      errorObserver.observe(targetNode, config);
    } catch (error) {
      console.error('Could not track the error messages -', error);
    }
  }



  /**
   * Attaches click event to the 'Submit' button on the 'Verify your Identity' page,
   * enabling user interactions with this element to be tracked.
   */
  attachSubmitButtonEvent() {
    try {
      const submitButton = document.querySelector('#verifyIdentity .btn-primary');

      if (submitButton) {
        submitButton.addEventListener('click', this.handleSubmitButtonClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'Submit\' Button -', error);
    }
  }

  /**
   * This method handles how the 'Submit' button click event is tracked on the 'Verify your Identity' page
   */
  handleSubmitButtonClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('SubmitButtonClick');
      console.log('SubmitButtonClick');
    } catch (error) {
      console.error('Could not track the \'Submit\' Button click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Get a Call/Text' link,
   * enabling user interactions with this element to be tracked.
   */
  attachAlternativeCodeLinkEvent() {
    try {
      const alternativeCodeLinklLink = document.querySelector('#alternative-code');

      if (alternativeCodeLinklLink) {
        alternativeCodeLinklLink.addEventListener('click', this.handleAlternativeCodeLinkClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'Get a Call/Text\' link -', error);
    }
  }

  /**
   * This method handles how the 'Get a Call/Text' link click event is tracked
   */
  handleAlternativeCodeLinkClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      const getACallLink = document.querySelector('#alternative-call');
      const getATextLink = document.querySelector('#alternative-text');
      if (!(getACallLink.style.display === 'none')) {
        this.satelliteTracker('GetaCallLinkClick');
      } else if (!(getATextLink.style.display === 'none')) {
        this.satelliteTracker('GetaTextLinkClick');
      }
    } catch (error) {
      console.error('Could not track the \'Get a Call/Text\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Customer Service' link,
   * enabling user interactions with this element to be tracked.
   */
  attachCallMemberServicesLinkEvent() {
    try {
      const customerServiceLink = document.querySelector('#member-services');

      if (customerServiceLink) {
        customerServiceLink.addEventListener('click', this.handleCallMemberServicesClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'Customer Service\' link -', error);
    }
  }

  /**
   * This method handles how the 'Customer Service' link click event is tracked
   */
  handleCallMemberServicesClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('CallMemberServicesClick');
      console.log('CallMemberServicesClick');
    } catch (error) {
      console.error('Could not track the \'Customer Service\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'set up account next time' link,
   * enabling user interactions with this element to be tracked.
   */
  attachSetupAccountNextTimeLinkEvent() {
    try {
      setTimeout(() => {
        const skipSetupLink = document.querySelector('.skip-setup');

        if (skipSetupLink) {
          skipSetupLink.addEventListener('click', this.handleSetupAccountNextTimeLinkClick.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'set up account next time\' link -', error);
    }
  }

  /**
   * This method handles how the 'set up account next time' link click event is tracked
   */
  handleSetupAccountNextTimeLinkClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('SetupAccountNextTimeClick');
    } catch (error) {
      console.error('Could not track the \'set up account next time\' link click event -', error);
    }
  }

  /**
   * Attaches click event to the 'Skip and Complete Account' button,
   * enabling user interactions with this element to be tracked.
   */
  attachSkipandCompleteAccountButtonEvent() {
    try {
      setTimeout(() => {
        const skipSetupLink = document.querySelector('.skipCompleteButton');

        if (skipSetupLink) {
          skipSetupLink.addEventListener('click', this.handleSkipandCompleteAccountButtonClick.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'Skip and Complete Account\' button -', error);
    }
  }

  /**
   * This method handles how the 'Skip and Complete Account' button click event is tracked
   */
  handleSkipandCompleteAccountButtonClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('SkipandCompleteAccountClick');
    } catch (error) {
      console.error('Could not track the \'Skip and Complete Account\' button click event -', error);
    }
  }


  /**
   * Checks if the event has already been handled or if the target has the 'data-add-button-label' attribute.
   *
   * @param {Event} event - The click event.
   * @returns {boolean} - Returns true if the event has already been handled, false otherwise.
   */
  isEventHandled(event) {
    if (event.alreadyHandled || event.target.hasAttribute('data-add-button-label')) {
      return true;
    }
    event.alreadyHandled = true;  // Mark the event as handled
    return false;
  }

  /**
   * Sends the analytics data using satellite tracker.
   *
   * This method sends the tracked data to the analytics service, which processes the data and provides insights based on it.
   *
   * @param {string} directCall - The name of the direct call.
   */
  satelliteTracker(directCall) {
    if (window._satellite && directCall) {
      window._satellite.track(directCall);
    }
  }

  /**
   * Initializes the class instance by setting up required events and configurations.
   *
   * This method checks if the current page is one of the Tally pages. If so, it sets up the mutation observer
   * and attaches click events to relevant elements.
   */
  init() {
    this.attachSignInClickEvent();
    this.attachForgotPasswordClickEvent();
    this.attachSetUpYourAccountClickEvent();
    this.initiateGeneratedErrorCapture();
    this.attachMyAccountClickEvent();
    this.attachSignOutClickEvent();
    this.attachJoinClickEvent();
    this.mobilePlatform();

    if (this.isJoinPage) {
      this.attachRewardsAccountVerifiedByToggleEvent();
      this.attachTravelForBusinessCheckEvent();
      this.attachCustomerTypeSelectEvent();
      this.attachJoinNowEvent();
    }

    if (this.isJoinPage || this.isFirstTimeSignInPage) {
      this.attachLookitUpHereLinkClickEvent();
      this.attachSignInLinkClickEvent();
    }

    if (this.isVerifyIdentityPage) {
      this.attachSubmitButtonEvent();
      this.attachResendVerificationCodeLinkEvent();
      this.attachAlternativeCodeLinkEvent();
      this.attachCallMemberServicesLinkEvent();
    }

    if (this.isProfileSetupPage) {
      this.attachSetupAccountNextTimeLinkEvent();
      this.attachSkipandCompleteAccountButtonEvent();
    }
  }
}

// Instantiate the TallyAnalytics class and set up event listeners
let tallyAnalytics = new TallyAnalytics();
tallyAnalytics.init();
