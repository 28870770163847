import { HREF, templateUrls, $_PAGE_ } from '../vars.js';
import ConfigsUtils from '../../base/aem-configs/config-utils';

/**
 * Class representing SMS Analytics.
 *
 * This class is responsible for tracking user interactions related to the SMS and Okta systems,
 * and it sends the tracked data to an analytics service.
 */
class SMSAnalytics {

  /**
   * Create an instance of SMSAnalytics.
   */
  constructor() {
    const uriWRJoin = templateUrls.joinPage;
    const hrefCurrent = '' + $(location).attr(HREF);
    this.isJoinPage = hrefCurrent.includes(uriWRJoin);
    const uriWRProfile = templateUrls.profilePage;
    const uriWRVerifyIdentity = templateUrls.verifyIdentityPage;
    this.isProfilePage = hrefCurrent.includes(uriWRProfile);
    this.isVerifyIdentityPage = hrefCurrent.includes(uriWRVerifyIdentity);
    this.isBookingPage = $_PAGE_.hasClass('booking-page');
    this.isPackagesPage = $_PAGE_.hasClass('packages-page');
    this.isConfirmationPage = $_PAGE_.hasClass('confirmation-page');
    this.timeOutDelay = ConfigsUtils.getJoinNowTimeout() || 2000;
    this.setupMutationObserver();
    if(!this.isConfirmationPage){
      // reset SMS digital data
      window.digitalData.SMS = window.digitalData.SMS || {};
    }
  }

  /**
   * Sets up a mutation observer to detect changes in the DOM.
   *
   * This method allows the object to react to changes in the page's structure,
   * including the addition of elements that need to be tracked.
   */
  setupMutationObserver() {
    const observer = new MutationObserver((mutations) => { 
      if(mutations.length > 0){
        this.attachRewardsAccountVerifiedByToggleEvent();
      }
    });

    const targetNode = document.body;
    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);
  }

  /**
   * Attaches change event to the phone type radio buttons on the booking page,
   * enabling user interactions with this element to be tracked.
   */
  attachPhoneNumberOptionTypeToggleEvent() {
    try {
      const phoneTypeRadioButtons = document.getElementsByName('phonetype-radio');
      phoneTypeRadioButtons.forEach((phoneTypeRadioButton) => {
        if (phoneTypeRadioButton.checked) {
          
          if (phoneTypeRadioButton.value === "phone-mobile") {
            window.digitalData.SMS.phoneNumberOptionType = "Mobile";
          } else {
            window.digitalData.SMS.phoneNumberOptionType = phoneTypeRadioButton.value;
          }  
        }

        phoneTypeRadioButton.addEventListener('change', this.handlePhoneNumberOptionTypeToggle.bind(this));
      });
    } catch (error) {
      console.error('Could not capture the phone type radio buttons -', error);
    }
  }

  /**
   * This method handles how data is tracked when the phone type radio buttons on the enroll is clicked
   */
  handlePhoneNumberOptionTypeToggle(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

      var formattedPhoneNumberOptionType = event.srcElement.value.replace(/^[^-]*-/, '').replace(/^(.)/, function(match, firstLetter) {
        return firstLetter.toUpperCase();
      });
     
      window.digitalData.SMS.phoneNumberOptionType = formattedPhoneNumberOptionType;

    } catch (error) {
      console.error('Could not capture the phone type radio buttons\' choice -', error);
    }
  }

  /**
   * Attaches change event to the account verification radio buttons on the enroll,
   * enabling user interactions with this element to be tracked.
   */
   attachRewardsAccountVerifiedByToggleEvent() {
    try {
      const accountVerificationRadioButtons = document.getElementsByName('verificationChoice');
      accountVerificationRadioButtons.forEach((accountVerificationRadioButton) => {
        if (accountVerificationRadioButton.checked) {
          if (accountVerificationRadioButton.value === "Text") {
            window.digitalData.SMS.phoneNumberOptionType = "Mobile";
          } else {
            window.digitalData.SMS.phoneNumberOptionType = accountVerificationRadioButton.value;
          } 
        }
        accountVerificationRadioButton.addEventListener('change', this.handleRewardsAccountVerifiedByToggle.bind(this));
      });
    } catch (error) {
      console.error('Could not find the account verification radio buttons -', error);
    }
  }

  /**
   * This method handles how data is tracked when the account verification radio buttons is clicked
   */
  handleRewardsAccountVerifiedByToggle(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      if (event.srcElement.value === "Text") {
        window.digitalData.SMS.phoneNumberOptionType = "Mobile";
      } else if (event.srcElement.value === "Voice") {
        window.digitalData.SMS.phoneNumberOptionType = "Other";
      } else {
        window.digitalData.SMS.phoneNumberOptionType = event.srcElement.value;
      }

    } catch (error) {
      console.error('Could not fire the account verification radio buttons\' event -', error);
    }
  }

  /**
   * Attaches change event to the state selector on booking,
   * enabling user interactions with this element to be tracked.
   */  
   attachBookingStateDropdown() {
    try { 
      const bookingCountryDropdown = document.getElementById('customerStateCode');
      bookingCountryDropdown.addEventListener('change', this.handleBookingStateDropdown.bind(this));
    } catch (error) {
      console.error('Could not find the Booking Country Dropdown -', error);
    }
  }

  /**
   * This method handles how data is tracked when the account verification radio buttons is clicked
   */
  handleBookingStateDropdown(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      var selectedValue = event.srcElement.value;
      if (selectedValue != "") {
        window.digitalData.SMS.phoneNumberOptionType = "Mobile"; 
      }
    } catch (error) {
      console.error('Could not fire the Booking State Dropdown\' event -', error);
    }
  } 

  /**
   * Attaches change event to the country selector on booking,
   * enabling user interactions with this element to be tracked.
   */  
  attachBookingCountryDropdown() {
    try { 
      const bookingCountryDropdown = document.getElementById('customerCountryCode');
      bookingCountryDropdown.addEventListener('change', this.handleBookingCountryDropdown.bind(this));
    } catch (error) {
      console.error('Could not find the Booking Country Dropdown -', error);
    }
  }

  /**
   * This method handles how data is tracked when the account verification radio buttons is clicked
   */
  handleBookingCountryDropdown(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      var selectedValue = event.srcElement.value;
      if (selectedValue != "") {
        window.digitalData.SMS.phoneNumberOptionType = "Mobile"; 
      }
    } catch (error) {
      console.error('Could not fire the Booking Country Dropdown\' event -', error);
    }
  }  

  /**
   * Attaches change event to the "Complete Reservation" button,
   * enabling user interactions with this element to be tracked.
   */
  attachBookingButtonClickEvent() {
    try {
      const bookingButton = document.querySelector('.confirm-booking');

      if (bookingButton) {
        bookingButton.addEventListener('click', this.handleBookingButtonClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find \'Complete Reservation\' button -', error);
    }
  }

  /**
   * This method handles how data is deleted when the "Complete Reservation" button is clicked
   */
  handleBookingButtonClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
       
      //window.digitalData.SMS = {};
    } catch (error) {
      console.error('Could not fire the \'Complete Reservation\' button\'s click event -', error);
    }
  }

  /**
   * Attaches change event to the 'about my stay' checkbox on the booking page,
   * enabling user interactions with this element to be tracked.
   */
  attachAboutMyStayCheckEvent() {
    try {
      setTimeout(() => {
        const aboutMyStayCheckbox = document.querySelector('#mobile-sms-updates');
        if (aboutMyStayCheckbox) {
          window.digitalData.SMS.textMessagesOptIn = aboutMyStayCheckbox.checked ? 'Yes' : 'No';
          aboutMyStayCheckbox.addEventListener('change', this.handleAboutMyStayCheck.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'about my stay\' checkbox -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'about my stay' checkbox is checked
   */
  handleAboutMyStayCheck(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

      window.digitalData.SMS.textMessagesOptIn = event.srcElement.checked ? 'Yes' : 'No';
    } catch (error) {
      console.error('Could not capture the \'about my stay\' checkbox\'s check -', error);
    }
  }

  /**
   * Attaches change event to the 'marketing text messages' checkbox on the booking page,
   * enabling user interactions with this element to be tracked.
   */
  attachBookingSMSOptInCheckEvent() {
    try {
      setTimeout(() => {
        const aboutMyStayCheckbox = document.querySelector('#marketing-sms-updates');
        if (aboutMyStayCheckbox) {
          window.digitalData.SMS.smsOptIn = aboutMyStayCheckbox.checked ? 'Yes' : 'No';
          aboutMyStayCheckbox.addEventListener('change', this.handleBookinSMSOptInCheck.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the \'marketing text messages\' checkbox -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'marketing text messages' checkbox is checked
   */
  handleBookinSMSOptInCheck(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

      window.digitalData.SMS.smsOptIn = event.srcElement.checked ? 'Yes' : 'No';
    } catch (error) {
      console.error('Could not capture the \'marketing text messages\' checkbox\'s check -', error);
    }
  }

  /**
   * Attaches click event to the 'Privacy Notice' link in the booking page,
   * enabling user interactions with this element to be tracked.
   */
  attachPrivacyPolicyClickTextMessagingEvent() {
    try {
      setTimeout(() => {
        const bookingPrivacyNoticeLinks = document.querySelectorAll('.privacy-notice-link');
        bookingPrivacyNoticeLinks.forEach((link) => {
          link.addEventListener('click', this.handlePrivacyPolicyClickTextMessagingClickEvent.bind(this));
        });
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find \'Privacy Notice\' link -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'Privacy Notice' links are clicked
   */
  handlePrivacyPolicyClickTextMessagingClickEvent(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('privacyPolicyClickTextMessaging');
    } catch (error) {
      console.error('Could not track the \'Privacy Notice\' link event -', error);
    }
  }

  /**
   * Attaches click event to the 'Privacy Notice' link in the enrollment page,
   * enabling user interactions with this element to be tracked.
   */
  attachPrivacyNoticeLinkClickEvent() {
    try {
      setTimeout(() => {
        const wrPrivacyNoticeLink = document.querySelector('#wr-privacy-notice');
        if (wrPrivacyNoticeLink) {
          wrPrivacyNoticeLink.addEventListener('click', this.handlePrivacyNoticeLinkClickEvent.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find \'Privacy Notice\' link -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'Privacy Notice' links are clicked
   */
  handlePrivacyNoticeLinkClickEvent(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('privacyPolicyClickSMSOptIn');
      console.log('privacyPolicyClickSMSOptIn');
    } catch (error) {
      console.error('Could not track the \'Privacy Notice\' link event -', error);
    }
  }

  /**
   * Attaches click event to the 'Additional Disclosures' links in the enrollment page,
   * enabling user interactions with this element to be tracked.
   */
  attachAdditionalDisclosuresLinkClickEvent() {
    try {
      setTimeout(() => {
        const wrAdditionalDisclosuresLink = document.querySelector('#wr-additional-disclosure');
        if (wrAdditionalDisclosuresLink){
          wrAdditionalDisclosuresLink.addEventListener('click', this.handleAdditionalDisclosuresLinkClickEvent.bind(this));
        }
      }, parseInt(this.timeOutDelay));
      // a second const is need for the 'Additional Disclosures' link in the booking page
    } catch (error) {
      console.error('Could not find \'Additional Disclosures\' link -', error);
    }
  }

  /**
   * This method handles how data is tracked when the 'Additional Disclosures' links are clicked
   */
  handleAdditionalDisclosuresLinkClickEvent(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('privacyPolicyClickAdditionalDisclosures');
    } catch (error) {
      console.error('Could not track the \'Additional Disclosures\' link event -', error);
    }
  }

  /**
   * Attaches change event to the "Subscribe to Wyndham Text Messages?" checkbox, 
   * enabling the customer type select dropdown to be reset to an empty value
   */
  
  attachSMSOptInCheckEvent() {
    try { 
      setTimeout(() => {
        const smsOptInCheckbox = document.querySelector('#smsLabel');
        window.digitalData.SMS.smsOptIn = 'No';

        if (smsOptInCheckbox) {
          smsOptInCheckbox.addEventListener('change', this.handleSMSOptInCheckbox.bind(this));
        }
      }, parseInt(this.timeOutDelay));
      
    } catch (error) {
      console.error('Could not find the \'Subscribe to Wyndham Text Messages\' checkbox -', error);
    }
  }

  /**
   * This method handles accounts for the "Subscribe to Wyndham Text Messages?" checkbox to be checked or unchecked
   * enabling the customer type select dropdown to be reset to an empty value
   */
  handleSMSOptInCheckbox(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

      let eventElement = event.srcElement;
      if (eventElement.checked) {
        window.digitalData.SMS.smsOptIn = 'Yes';
      } else if (!eventElement.checked) {
        window.digitalData.SMS.smsOptIn = 'No';
      }
    } catch (error) {
      console.error('Could not fire the \'Subscribe to Wyndham Text Messages\' checkbox\'s change event -', error);
    }
  }

  /**
   * Attaches change event to the "Subscribe to Wyndham Text Messages" checkbox,
   * enabling the customer type select dropdown to be reset to an empty value
   */
  attachProfileSMSOptInCheckEvent() {
    try {
      const smsOptInCheckbox = document.querySelector('#preferredSMSMarketing');

      if (smsOptInCheckbox) {
        window.digitalData.SMS.smsOptIn = smsOptInCheckbox.checked ? 'Yes' : 'No';
        smsOptInCheckbox.addEventListener('change', this.handleProfileSMSOptInCheckbox.bind(this));
      }
    } catch (error) {
      console.error('Could not find the \'Subscribe to Wyndham Text Messages\' checkbox -', error);
    }
  }

  /**
   * This method handles accounts for the "Subscribe to Wyndham Text Messages" checkbox to be checked or unchecked
   * enabling the customer type select dropdown to be reset to an empty value
   */
  handleProfileSMSOptInCheckbox(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      window.digitalData.SMS.smsOptIn = event.srcElement.checked ? 'Yes' : 'No'; 
    } catch (error) {
      console.error('Could not fire the \'Subscribe to Wyndham Text Messages\' checkbox\'s change event -', error);
    }
  }

  /**
   * Attaches change event to the "Save Changes" button in the my profile,
   * enabling user interactions with this element to be tracked.
   */
  attachProfileSaveChangesButtonEvent() {
    try {
      const saveChangesButtons = document.querySelectorAll('.form-content-width .submit.btn-primary');

      if (saveChangesButtons) {
        saveChangesButtons[saveChangesButtons.length - 1].addEventListener('click', this.handleProfileSaveChangesButtonClick.bind(this));
      }
    } catch (error) {
      console.error('Could not find \'Save Changes\' button -', error);
    }
  }

  /**
   * This method handles how data is deleted when the my profile page's "Save Changes" button is clicked
   */
  handleProfileSaveChangesButtonClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      //this.satelliteTracker('smsOptIn'); // Firing from profile.js
    } catch (error) {
      console.error('Could not fire the \'Save Changes\' button\'s click event -', error);
    }
  }

  /**
   * Attaches click event to the Wyndham Rewards 'Terms and Conditions' link,
   * enabling user interactions with this element to be tracked.
   */
  attachSmsPrivacyPolicyClickTermsAndConditionsLinkClickEvent() {
    try {
      setTimeout(() => {
        const smsPrivacyPolicyClickTermsAndConditions = document.querySelector('#wr-terms-and-conditions');

        if (smsPrivacyPolicyClickTermsAndConditions) {
          smsPrivacyPolicyClickTermsAndConditions.addEventListener('click', this.handleSmsPrivacyPolicyClickTermsAndConditionsLinkClick.bind(this));
        }
      }, parseInt(this.timeOutDelay));
    } catch (error) {
      console.error('Could not find the Wyndham Rewards \'Terms and Conditions\' link -', error);
    }
  }

  /**
   * This method handles how the Wyndham Rewards 'Terms and Conditions' link click event is tracked
   */
  handleSmsPrivacyPolicyClickTermsAndConditionsLinkClick(event) {
    try {
      if (this.isEventHandled(event)) return;  // Early return if the event has already been handled
      this.satelliteTracker('smsPrivacyPolicyClickTermsAndConditions');
      console.log('smsPrivacyPolicyClickTermsAndConditions');
    } catch (error) {
      console.error('Could not track the Wyndham Rewards \'Terms and Conditions\' link click event -', error);
    }
  }

  /**
   * Checks if the event has already been handled or if the target has the 'data-add-button-label' attribute.
   *
   * @param {Event} event - The click event.
   * @returns {boolean} - Returns true if the event has already been handled, false otherwise.
   */
  isEventHandled(event) {
    if (event.alreadyHandled || event.target.hasAttribute('data-add-button-label')) {
      return true;
    }
    event.alreadyHandled = true;  // Mark the event as handled
    return false;
  }

  /**
   * Sends the analytics data using satellite tracker.
   *
   * This method sends the tracked data to the analytics service, which processes the data and provides insights based on it.
   *
   * @param {string} directCall - The name of the direct call.
   */
  satelliteTracker(directCall) {
    if (window._satellite && directCall) {
      window._satellite.track(directCall);
    }
  }

  /**
   * Initializes the class instance by setting up required events and configurations.
   *
   * This method checks if the current page is one of the SMS pages. If so, it sets up the mutation observer
   * and attaches click events to relevant elements.
   */
  init() {
    if (this.isJoinPage || this.isProfilePage) {
      this.attachSmsPrivacyPolicyClickTermsAndConditionsLinkClickEvent();
      this.attachSMSOptInCheckEvent();
      this.attachPrivacyNoticeLinkClickEvent();
      this.attachAdditionalDisclosuresLinkClickEvent();
    }

    if (this.isJoinPage) {
      this.attachRewardsAccountVerifiedByToggleEvent();
    }

    if (this.isProfilePage) {
      this.attachProfileSMSOptInCheckEvent();
      this.attachProfileSaveChangesButtonEvent();
    }

    if (this.isBookingPage && !this.isPackagesPage) {
      this.attachBookingButtonClickEvent();
      this.attachPhoneNumberOptionTypeToggleEvent();
      this.attachAboutMyStayCheckEvent();
      this.attachBookingSMSOptInCheckEvent();
      this.attachPrivacyPolicyClickTextMessagingEvent();
      this.attachAdditionalDisclosuresLinkClickEvent();
      this.attachSmsPrivacyPolicyClickTermsAndConditionsLinkClickEvent();
      this.attachPrivacyNoticeLinkClickEvent();
      this.attachBookingCountryDropdown();
      this.attachBookingStateDropdown();
    }
  }
}

// Instantiate the SMSAnalytics class and set up event listeners
let smsAnalytics = new SMSAnalytics();
smsAnalytics.init();
