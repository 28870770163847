import {
  exists,
  ResizeHandler,
  isMobileWidth
} from '../../base/dom-utils.js';

class UUHorizontalLinkList {
  constructor() {
    if(exists('.uu-horizontal-list ul li')) {
      this.bindFindLastRowItems();
    }
  }

  bindFindLastRowItems() {
    if(!isMobileWidth()) {
      this.findLastRowItems();
    }

    ResizeHandler.addResizeEndFn(() => {
      if(!isMobileWidth()) {
        $('.uu-horizontal-list li').removeClass();
        this.findLastRowItems();
      }
    }, 'width');
  }

  findLastRowItems() {
    $('.uu-horizontal-list ul li').each((i,el) => {
      if(exists($(el).prev())) {
        if($(el).position().top != $(el).prev()
          .position().top) {
          $(el).prev()
            .addClass('last-in-row');
        }
      }
    });

    $('.uu-horizontal-list ul').find('.last-in-row:first')
      .addClass('end-first-row');
  }

}

export default new UUHorizontalLinkList();
