import ConfigsUtils from './config-utils';

const KEY = 'amenities-filter';

/**
 * Returns an object containing the amenities to be shown on the
 * Locations page depending on the amenities-filter AEM Config.
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 * @returns {Array} of amenities codes, empty array otherwise
 */
 const getAmenities = (channels, locales, brands) => {
  const config = ConfigsUtils.findConfig(KEY, channels, locales, brands);
  return config ? config.attributes[KEY] : [];
};

export default {
  getAmenities,
};
