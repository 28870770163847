import {
  exists,
  ResizeHandler,
  isMobileWidth,
  isTabletWidth
} from '../base/dom-utils.js';
import Analytics from '../base/wyn-analytics-module.js';
import { observeSrcMutations } from '../base/utils.js';

class HotelDealsCarousel {
  constructor() {
    this.handleMobileHiddenView();

    if (exists('.hotel-deals-carousel')) {
      this.handleCarouselConfig();
    }

    if (exists('.hotel-deals-stack')) {
      this.handleStackConfig();
    }
  }

  handleMobileHiddenView() {
    // Mobile hidden config
    $('.deals-carousel-component').each((i, el) => {
      // If Carousel is hidden on mobile, images are not loaded
      if (($(el).hasClass('hidden-xs')) && isMobileWidth()) {
        let $el = $(el);

        // Only Stack Version has mobile hidden option
        $el.find('img').each((j, img) => {
          $(img).data('src', img.src);
          img.src = '';
        });
      }
    });
  }

  handleCarouselConfig() {
    $(() => {
      $('.hotel-deals-slider .deal-button').click((e) => {
        Analytics.getHotelDealsCarouselInfo(e.currentTarget);
      });
      $('.hotel-deals-slider').on('init', (event, slick) => {
        this.showHideCarouselDots(slick);
      });
      $('.hotel-deals-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 0,
        responsive: [{
          breakpoint: 1199,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        },
        {
          breakpoint: 719,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true
          }
        }
        ]
      });
      $('.hotel-deals-slider').on('breakpoint', (event, slick, breakpoint) => {
        this.showHideCarouselDots(slick);
      });

      this.handleImageResize();
      $('.hotel-deals-slider').on('setPosition', (e) => {
        this.resizeDealsCards($(e.currentTarget));
      });

    });
  }

  resizeDealsCards($el) {
    $el.find('.slick-slide').height('auto');
    let tmpHeight = 0;
    $el.find('.deal-title').height('auto');
    $el.find('.deal-title').each((i, el) => {
      tmpHeight = $(el).height() > tmpHeight ? $(el).height() : tmpHeight;
    });
    $el.find('.deal-title').height(tmpHeight);

    let slickTrack = $el.find('.slick-track');
    let slickTrackHeight = $(slickTrack).height();
    $el.find('.slick-slide').css('height', slickTrackHeight + 'px');
    $el.find('.slick-list').css('height', slickTrackHeight + 'px');
  }

  showHideCarouselDots(slick) {
    let breakpoint = slick.activeBreakpoint || Math.max.apply(null, slick.breakpoints);
    if (slick.$dots && breakpoint) {
      let slidesToShow = slick.breakpointSettings[breakpoint].slidesToShow || 1;
      if (slick.$slides.length > slidesToShow) {
        slick.$dots.show();
      } else {
        slick.$dots.hide();
      }
    }
  }

  handleStackConfig() {
    // Run on window load for tablet and desktop
    $(window).on("load",() => {
      if (!isMobileWidth()) {
        this.handleDealCardHeights();
        this.handleDealCardRows();
      }
      this.handleImageResize();
      // Recalc on window resize
      ResizeHandler.addResizeEndFn(() => {
        if (!isMobileWidth()) {
          $('.hotel-deals-stack .deal-title').height('auto');
          this.handleDealCardHeights();
        }
      }, 'width');

      // needed for collapsible container to recalculate height
      $('.cc-show').click(() => {
        this.handleDealCardHeights();
      });
    });
  }

  // Only calculate against items in row
  handleDealCardRows() {
    $('.hotel-deals-stack .row').removeClass('deals-row');

    let columns = isTabletWidth() ? 2 : 3,
      row;

    $('.hotel-deals-stack .row').each((index, el) => {
      let cards = $(el).children('.hotel-deal-slide'),
        i = 0;
      while ((row = cards.slice(i, i += columns)).length) {
        row.wrapAll('<div class="deals-row"></div>');
      }
    });
  }
  // All titles will match the tallest item in row
  handleDealCardHeights() {
    $('.hotel-deals-stack .deals-row').each((i, el) => {
      let tmpHeight = 0;
      $(el).find('.deal-title')
        .height('auto');
      $(el).find('.deal-title')
        .each((i, title) => {
          tmpHeight = $(title).height() > tmpHeight ? $(title).height() : tmpHeight;
        });
      $(el).find('.deal-title')
        .height(tmpHeight);
    });
  }
  // Handle resize when image changes.
  handleImageResize() {
    observeSrcMutations('.hotel-deals-carousel .pll-image-full-size', (mutation) => {
      this.resizeDealsCards($(mutation.target).parents('.hotel-deals-slider'));
    });
  }
}

export default new HotelDealsCarousel();
