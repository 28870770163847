import {$_PAGE_} from '../base/vars.js';
import PropertyQuickView from '../components/property-quick-view.js';
import Search from "../components/search/Search";

let searchInst;

function initializePropertyQuickView() {
  const bbmd = $('#bb-md');
  if(bbmd.length) {
    $('.search-body #list-view').on('click', 'a.hotel-image-link, a.hotel-url--name', (e) => {
      e.preventDefault();

      const hotelDetailsWrapperElement = $(e.currentTarget).closest('.hotel-details-wrapper');
      const propertyId = hotelDetailsWrapperElement.attr('id');
      const propertyUrl = e.currentTarget.getAttribute('href');
      const priceButtonAvailableElement = hotelDetailsWrapperElement.find('.hotel-rate.is-active .available .price-button');
      const pointsButtonAvailableElement = hotelDetailsWrapperElement.find('.hotel-rewards.is-active .available .price-button');
      const pointsButtonUnavailableElement = hotelDetailsWrapperElement.find('.hotel-rewards.is-active .unavailable .hotel-url');

      const notificationAttr = hotelDetailsWrapperElement.attr('data-notification');
      let bookNowUrl;
      let notificationData;

      if (!pointsButtonUnavailableElement.length && pointsButtonAvailableElement.length) {
        bookNowUrl = pointsButtonAvailableElement.attr('href');
      } else if (priceButtonAvailableElement.length) {
        bookNowUrl = priceButtonAvailableElement.attr('href');
      }

      if (notificationAttr) {
        notificationData = JSON.parse(decodeURIComponent(notificationAttr));
      }

      PropertyQuickView.init(propertyId, propertyUrl, bookNowUrl, notificationData);
    });
  }
}
if ($_PAGE_.is('.search-results-page')) {
  if (!$_PAGE_.is('.search-results-page-v2')) {
    searchInst = new Search();
    initializePropertyQuickView();
  }
}
export default searchInst;
