import {
  exists,
  getCSSPropertyAsInt,
  getElTrueHeight,
  getElTrueWidth,
  getWindowWidth,
  isMobileWidth,
  isHomepageExtendedHeroException
} from '../base/dom-utils.js';
import {
  getDefaultCriteria,
  getCriteria,
  setCriteria,
  getBrand
} from '../base/session-handler.js';
import {
  $_BOOKING_BAR_MAIN_
} from '../base/vars.js';

class BookNowModal {
  constructor(bbForm, bbMini, bbDatePicker) {
    this.bbForm = bbForm;
    this.bbMini = bbMini;
    this.bbDatePicker = bbDatePicker;

    this.form = false;
    this.modalEle = false;
    this.modalData = false;
    this.linkAttr = false;
    this.formHid = false;
    this.currentBrand = false;
    this.search_button = false;
    this.dest_cont = false;
    this.miniTitle = false;
    this.isMinVis = false;
    this.hiddenBookBar = $('[name="booking-bar-hidden"]');
    this.globalNav = $('.globalnavigation');
    this.formEle = $('.booking-bar-main');
    if (isHomepageExtendedHeroException()) {
      if ($('.extended-hero-container').length) {
        this.$extendedHero = $('.extended-hero-container');
      } else {
        this.$extendedHero = $('.extended-hero-component');
      }
    }else{
      this.$extendedHero = $('.extended-hero-component');
    }
    this._radio = 'input[name=specialty-rates]';
    this._radioContainer = '.specialty-rates-radio';
    this._datepicker = '.datepicker-container';
    this.posInit = 0;

    this.bindLinkClicks();
  }
  bindLinkClicks() {
    $(document).on('click', 'a[href*=\'modal_\']', (e) => {
      e.preventDefault();
      let linkAtt = $(e.currentTarget).attr('href');
      let linklength, linkAttr;
      if (linkAtt.indexOf('#modal_') > -1) {
        linklength = linkAtt.indexOf('?');
        if (linklength > -1) {
          linkAttr = linkAtt.substring(0, linklength);
          this.init(linkAttr);
        } else {
          this.init(linkAtt);
        }
      } else {
        this.init(linkAtt);
      }
    });
  }
  init(linkAttr) {
    this.currentBrand = getBrand();
    this.modalEle = $(linkAttr);
    this.detachForm(); // Detaching the main booking bar
    if (this.formEle.hasClass('display-none')) {
      this.formHid = true;
      this.formEle.removeClass('display-none');
    }
    if (this.formEle.hasClass('hide-on-load')) {
      this.formHid = true;
      this.formEle.removeClass('hide-on-load');
    }
    this.formEle.addClass('booknow-bar');
    this.form.find('.intl-search').addClass('booknow-bar');
    this.form.find('.search-btn').addClass('btn-block');
    $('#bookingBarCollapsed').addClass('display-none');
    //Attach the form
    //form.find('.hide-desktop').removeClass('hide-desktop').attr('hideDesktop', true);
    this.modalEle.find('.booknow-form-container').html(this.form);
    this.populateData(this.modalEle); // Populate the data from the modal object into the bookingBar
    //Check property id and write the appropriate rooms logic
    this.room_button = this.modalEle.find('.roomsrates-button-container');
    if (this.modalData.roomsrateurl) {
      this.makeRoomCompatible(this.modalEle, this.modalData);
    }
    this.handleEvents(this.modalEle);
    this.handleOpeningUI(this.modalEle);
    //Handle dropdown events
    //When Modal closses
    $(linkAttr).one('hidden.bs.modal', () => {
      this.respawn();
    })
      .modal('show');
  }
  positionDropDown($container, $button) {
    let buttonWidth = $button.width(),
      buttonLeft = $button.parent().offset().left,
      dropdownWidth = getElTrueWidth($container),
      em = getCSSPropertyAsInt($button.parents('.container'), 'padding-left'),
      left = buttonLeft + buttonWidth / 2 - dropdownWidth / 2,
      containerOpts = {
        left: left - buttonLeft,
        width: dropdownWidth + 1 // Windows width calculation issue
      };
    if (left + dropdownWidth > (getWindowWidth() - em)) {
      containerOpts = {
        left: 'auto',
        right: em
      };
    } else if (left < em) {
      containerOpts.left = em;
    }
    $container.css(containerOpts);
  }
  handleOpeningUI(modalEle) {
    $_BOOKING_BAR_MAIN_.addClass('zerotop');
    //Update label when the code is applied
    let ratCodVa = modalEle.data('ratecode');
    if (ratCodVa != '' && ratCodVa != undefined) {
      let label = modalEle.find('.specialty-rates-radio').data('label');
      modalEle.find('.more-options-button').text(label);
    }
    this.miniTitle = modalEle.find('.mini-booking_property-title');
    if (this.miniTitle.is(':visible')) {
      this.isMinVis = true;
      this.miniTitle.css('display', 'none');
    }
  }
  handleEvents(modalEle) {
    let modelDialog = modalEle.find('.booknow-dialog');
    //Check if mobile and increase the height
    modelDialog.addClass('upabove');
    if (isMobileWidth()) {
      modelDialog.removeClass('upabove');
      modalEle.on('dropdown:opening', () => {
        modelDialog.addClass('full-height');
      });
      modalEle.on('dropdown:closing', () => {
        modelDialog.removeClass('full-height');
      });
    } else {
      //Solving positioning issue with the calendar drop down on first load
      let $button = modalEle.find('.booking-dates'),
        $container = modalEle.find('.booking-dates-dropdown');

      modalEle.on('dropdown:opened', () => {
        if ($container.is(':visible') && this.posInit == 0) {
          this.positionDropDown($container, $button);
          this.posInit++;
        }
      });
    }
    //When the form is submitted update the query parameter with the brand_filter value
    if (this.modalData && this.modalData.brandfilter != 'undefined') {
      setCriteria({brandFilter: this.modalData.brandfilter});
    }
  }
  respawn() {
    if (this.formHid) {
      this.formEle.addClass('display-none');
      this.formEle.addClass('hide-on-load');
    }
    if (this.search_button && this.search_button.hasClass('display-none')) {
      this.search_button.removeClass('display-none');
    }
    if (this.dest_cont && this.dest_cont.hasClass('display-none')) {
      this.dest_cont.removeClass('display-none');
    }
    if (this.miniTitle != null && this.isMinVis) {
      this.miniTitle.css('display', 'block');
    }
    this.formEle.removeClass('booknow-bar');
    this.form.find('.intl-search').removeClass('booknow-bar');
    this.form.find('.search-btn').removeClass('btn-block');

    $('#bookingBarCollapsed').removeClass('display-none');
    $_BOOKING_BAR_MAIN_.removeClass('zerotop');
    this.room_button.addClass('display-none');
    this.attachForm();
  }
  detachForm() {
    this.form = $('.booking-bar-main').length ? $('.booking-bar-main').detach() : this.bbForm.$0; //Don't manipulate the structure of this, Manipulate only the values
    return this.form;
  }
  attachForm() {
    this.form = this.modalEle.find('.booknow-form-container').find('.booking-bar-main');
    if (!isMobileWidth() && this.$extendedHero.length && this.$extendedHero.is('.has-booking-bar')) {
      this.$extendedHero.append(this.form);
    } else if (exists('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]')) {
      // Triggering this event places booking bar into the header on WR
      $('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]').trigger('wr:dropdown:open');
    } else if (this.globalNav.length > 0) {
      this.globalNav.append(this.form);
    } else {
      //Resolving the issue on upper up scale
      this.globalNav = this.hiddenBookBar.parent();
      this.globalNav.append(this.form);
    }
  }
  populateData(modalEle) {
    this.modalData = this.modelModalData(modalEle);
    //Populate the booking form with the details from modal code
    if (this.modalData.los && this.bbDatePicker.setMinLos) {
      // Once the los setter method is available use bookingBar.set.los(modalData.los);
      this.bbDatePicker.setMinLos(this.modalData.los, true);
    }
    if (this.modalData.location) {
      //Set Data
      this.bbForm.setLocation(this.modalData.location);
      //Update UI
      this.form.find('.destination').val(this.modalData.location);
    }
    if (this.modalData.ratetype && this.modalData.ratecode) {
      //Set Data
      this.bbForm.setSpecialRate(this.modalData.ratecode, this.modalData.ratetype);
      //Update UI
      let radio = $('input[value="' + this.modalData.ratetype + '"]'),
        radio_input = radio.parent().find('.code');
      this.collapseCodeInputs();
      radio.prop('checked', 'checked');
      radio_input.val(this.modalData.ratecode);
      this.openCodeInput(radio);
    }
  }
  makeRoomCompatible(modalEle, modalData) {
    this.search_button = modalEle.find('.search-button-container');
    this.dest_cont = modalEle.find('.destination-container');
    //Hide the Search button, show the rooms button and hide destination
    this.room_button.removeClass('display-none');
    this.search_button.addClass('display-none');
    this.dest_cont.addClass('display-none');
    this.room_button.on('click', (e) => {
      let form_data = this.getFormData(),
        rateType = '',
        rateCode = '',
        dataToUpdate = {checkInDate: form_data.checkInDate, checkOutDate: form_data.checkOutDate};
      //Get the parameter values and append to href and redirect
      if (form_data.rate && form_data.rate != undefined) {
        rateType = form_data.rate.type;
        rateCode = form_data.rate.code;
      }
      let href = `${this.modalData.roomsrateurl}?brand_id=${this.currentBrand}&radius=25&rooms=${form_data.rooms}&adults=${form_data.adults}&children=${form_data.children}&checkInDate=${form_data.checkInDate}&checkOutDate=${form_data.checkOutDate}&useWRPoints=${form_data.rewards}&brandCode=${getDefaultCriteria().brandCodeString}&referringBrand=${this.currentBrand}&sessionId=${getCriteria().sessionId}`; //adding query parameters to the URL
      if (rateType == 'groupCode') {
        href += '&groupCode=' + rateCode;
        dataToUpdate.groupCode = rateCode;
        dataToUpdate.ratePlan = '';
        dataToUpdate.rateCode = '';
        dataToUpdate.corpCode = '';
      } else if (rateType == 'rateCode') {
        href += '&rateCode=' + rateCode;
        dataToUpdate.rateCode = rateCode;
        dataToUpdate.ratePlan = '';
        dataToUpdate.groupCode = '';
        dataToUpdate.corpCode = '';
      } else if (rateType == 'promotional') {
        href += '&ratePlan=' + rateCode;
        dataToUpdate.ratePlan = rateCode;
        dataToUpdate.rateCode = '';
        dataToUpdate.groupCode = '';
        dataToUpdate.corpCode = '';
      } else if (rateType == 'corpCode') {
        href += '&corpCode=' + rateCode;
        dataToUpdate.corpCode = rateCode;
        dataToUpdate.ratePlan = '';
        dataToUpdate.rateCode = '';
        dataToUpdate.groupCode = '';
      } else {
        href += '&ratePlan=' + rateType + '&corpCode=' + rateCode;
        dataToUpdate.ratePlan = rateType;
        dataToUpdate.corpCode = rateCode;
        dataToUpdate.rateCode = '';
        dataToUpdate.groupCode = '';
      }
      if (this.modalData.brandfilter && this.modalData.brandfilter != undefined) {
        href += '&brand_filter=' + this.modalData.brandfilter;
      }
      setCriteria(dataToUpdate);
      window.location.assign(href);
    });
  }
  getFormData() {
    let data = {
      'checkInDate': this.bbForm.getFrom(),
      'checkOutDate': this.bbForm.getTo(),
      'adults': this.bbForm.getAdults(),
      'childAges': this.bbForm.getChildAges(),
      'children': this.bbForm.getChildren(),
      'rooms': this.bbForm.getRooms(),
      'rate': this.bbForm.getSpecialRate(),
      'rewards': this.bbForm.getRedeemWRPoint()
    };
    return data;
  }
  collapseCodeInputs() {
    $(this._radioContainer + ' .code-container').css({
      paddingTop: 0
    })
      .height(0)
      .find('input.code')
      .attr('tabindex', -1);
  }
  openCodeInput($radioCurrent) {
    let $codeDiv = $radioCurrent.parent().find('.code-container'),
      $codeInput = $codeDiv.find('input.code');

    $codeDiv.css({
      paddingTop: '1em',
      paddingLeft: '35px'
    }).height(getElTrueHeight($codeInput));
    $codeInput.attr('tabindex', 0);
  }
  checkNone() {
    $(this._radio + '.default').prop('checked', true);
  }
  modelModalData(modalEle) {
    let data = {
      'los': modalEle.data('los'),
      'location': modalEle.data('locationvalue'),
      'roomsrateurl': modalEle.data('roomsrateurl'),
      'ratetype': modalEle.data('ratetype'),
      'brandfilter': modalEle.data('brandfilter'),
      'ratecode': modalEle.data('ratecode')
    };
    return data;
  }
}

export default BookNowModal;
