/**
 * @class CurrencyConverterAnalytics
 * @description Handles the click events on the currency converter dropdown and modal,
 *              as well as tracking analytics for these events.
 */
import {
  isDesktopWidth
} from '../../base/dom-utils.js';

class CurrencyConverterAnalytics {

  constructor() {
    this.eventListeners = new WeakMap();
  }

  /**
   * Attaches a click event listener to the 'View More Currencies' link.
   * Sends the 'viewMoreCurrencies' event to Adobe Launch for tracking.
   */
  attachViewMoreCurrenciesClickEvent() {
    const viewMoreCurrenciesLink = document.querySelector('.currency-viewMore');
    if (viewMoreCurrenciesLink) {
      viewMoreCurrenciesLink.addEventListener('click', () => {
        if(isDesktopWidth()){
          this.satelliteTracker('viewMoreCurrencies');
        }
      });
    }
    
  }

  /**
   * Initializes click event listeners for the currency options in the modal.
   * Ensures event listeners are added only once using a WeakMap.
   * Sends the clicked currency code to Adobe Launch for tracking.
   */
  initializeClickOnCurrencyModalOptions() {
    const currencyOptionsDropDown = document.querySelectorAll('.top-currency-options .currency-option');
    const currencyOptionsModal = document.querySelectorAll('.full-currency-options .currency-option');

    const allCurrencyOptions = [...currencyOptionsDropDown, ...currencyOptionsModal];

    for (const option of allCurrencyOptions) {
      if (!this.eventListeners.has(option)) {
        const boundListener = this.onCurrencyOptionClick.bind(this);
        this.eventListeners.set(option, boundListener);
        option.addEventListener('click', boundListener);
      }
    }
  }

  /**
   * Handles the click event on a currency option.
   * Updates the previous and current currency in the digitalData object.
   * Sends the 'currencyConverterChange' event to Adobe Launch for tracking.
   * @param {Event} event
   */
  onCurrencyOptionClick(event) {
    const selectedCurrencyElement = document.querySelector('.currency-options .previous-option__selected');

    if (selectedCurrencyElement) {
      const selectedCurrencyText = selectedCurrencyElement.textContent.trim();
      if (selectedCurrencyText) {
        const selectedCurrencyCode = selectedCurrencyText.split(' ')[0];
        window.digitalData.currencyConverter.previousCurrency = selectedCurrencyCode;
      }
    }

    const clickedCurrency = event.target.closest('.currency-option');

    if (clickedCurrency) {
      const clickedCurrencyText = clickedCurrency.textContent.trim();
      if (clickedCurrencyText) {
        const currencyCode = clickedCurrencyText.split(' ')[0];
        window.digitalData.currencyConverter.currency = currencyCode;
        this.satelliteTracker('currencyConverterChange');
      }
    }
  }

  /**
   * Attaches a click event listener to the 'currencyDropDown' element.
   * Sends the 'currencyConverterClick' event to Adobe Launch for tracking when the dropdown is expanded.
   */
  attachCurrencyDropDownClickEvent() {
    const currencyDropDown = document.getElementById('currencyDropDown');

    const onCurrencyDropDownClick = () => {
      if (currencyDropDown.getAttribute('aria-expanded') !== 'true') {
        this.satelliteTracker('currencyConverterClick');
      }
    };

    if (currencyDropDown) {
      currencyDropDown.addEventListener('click', onCurrencyDropDownClick);
    }

    const currencySideOpen = document.querySelector('#currencySideOpen');
    if (currencySideOpen) {
      currencySideOpen.addEventListener('click', () => {
        this.satelliteTracker('currencyConverterClick');
      });
    }
    const currencySideOpenOverview = document.querySelector('#currencySideOpenOverview');
    if (currencySideOpenOverview) {
      currencySideOpenOverview.addEventListener('click', () => {
        this.satelliteTracker('currencyConverterClick');
      });
    }

    const currencyDropDownReferenceRR = document.querySelector('#currencyDropDownReferenceRR');
    if (currencyDropDownReferenceRR) {
      currencyDropDownReferenceRR.addEventListener('click', () => {
        this.satelliteTracker('currencyConverterClick');
      });
    }

    const currencyDropDownReference = document.querySelector('#currencyDropDownReference');
    if (currencyDropDownReference) {
      currencyDropDownReference.addEventListener('click', () => {
        this.satelliteTracker('currencyConverterClick');
      });
    }

    const currencyDropDownReferenceUpscale = document.querySelector('#currencyDropDownReferenceUpscale');
    if (currencyDropDownReferenceUpscale) {
      currencyDropDownReference.addEventListener('click', () => {
        this.satelliteTracker('currencyConverterClick');
      });
    }

  }

  /**
   * Sets up a MutationObserver to watch for changes in the '.full-currency-options' element.
   * Re-initializes click event listeners when a new child is added.
   */
  setupCurrencyObserver() {
    const targetNode = document.querySelector('.full-currency-options');

    const config = {
      childList: true,
      subtree: true
    };

    const callback = (mutationsList, observer) => {
      // If a new child is added, re-initialize the click event listeners
      if(mutationsList.length > 0){
        this.initializeClickOnCurrencyModalOptions();
      }
    };

    const observer = new MutationObserver(callback);
    if(targetNode){
      observer.observe(targetNode, config);
    }
  }

  /**
   * Triggers a direct call event in Adobe Launch if the _satellite object is available and the directCall parameter is defined.
   *
   * @param {string} directCall - The event name to be tracked in Adobe Launch.
   */
  satelliteTracker(directCall) {
    if (typeof window._satellite !== 'undefined' && typeof directCall !== 'undefined') {
      window._satellite.track(directCall);
    }
  }
}
let currencyConverterAnalyticsInst = new CurrencyConverterAnalytics();

currencyConverterAnalyticsInst.setupCurrencyObserver();
export default currencyConverterAnalyticsInst;
