const DESKTOP = 'desktop';
const TABLET = 'tablet';
const MOBILE_WEB = 'mobileweb';
const MOBILE_APP = 'mobileapp';

export default {
    Desktop: DESKTOP,
    Tablet: TABLET,
    MobileWeb: MOBILE_WEB,
    MobileApp: MOBILE_APP,
    Web: [
        DESKTOP,
        TABLET,
        MOBILE_WEB,
    ]
};
