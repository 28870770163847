import {
  _isDateType,
  _isNull,
  formatDateForPrinting,
  formatNumber,
  formatTimeShow,
  isMemberRate,
  printDollarAmount,
  printDollarAmountonPayment,
  getAddress,
  getAddressWithoutProvinceCountry,
  CookieHandler,
  isGoFreeRate,
  getUNAP,
  convertToCurrency,
  getSelectedCurencyCode,
} from '../base/utils.js';
import  HideProvinceCountryConfig from '../base/aem-configs/hide-Province-Country';
import { UserHandler,isWRCCHolder,getSessionCriteria} from '../base/session-handler.js';
import { _LANGUAGE_, $_PAGE_ } from '../base/vars.js';
import { currencyConverter } from '../base/currency-converter.js';

class BookingSummary {
  constructor() {
    this.$0 = $('.page-content,footer');
    this.$propertyName = this.$0.find('.property-name');
    this.$propertyAddress = this.$0.find('.property-address');
    this.$propertyPhone = this.$0.find('.property-number');
    this.$roomType = this.$0.find('.room-type-description');
    this.$roomDescription = this.$0.find('.room-description');
    this.$checkInDate = this.$0.find('.check-in span.date');
    this.$checkInTime = this.$0.find('.check-in span.time');
    this.$checkOutDate = this.$0.find('.check-out span.date');
    this.$checkOutTime = this.$0.find('.check-out span.time');
    this.$subtotal = this.$0.find('.price .subtotal .val');
    this.$pacSubtotal = this.$0.find('.points .pac-cash.subtotal');
    this.$points = this.$0.find('.points .pts.subtotal .val');
    this.$total = this.$0.find('.cash-total .val');
    this.$paymentTotal = this.$0.find('.cash-total .payment-value');
    this.$totalPoints = this.$0.find('.points-total .val');
    this.$cctotal = $('.credit-card-promo .book-room-rate-total-after-tax');
    this.$cccredit = $('.credit-card-promo .credit-val');
    this.$ccmath = $('.credit-card-promo .total-after-credit-val');
    this.$totalTax = this.$0.find('.total-roomrate-tax');
    this.$rdTotalTax = this.$0.find('.rd-total-roomrate-tax');
    this.$confirmationNumber = this.$0.find('.book-confirmation-number');
    this.$firstName = this.$0.find('.customer-first-name');
    this.$lastName = this.$0.find('.customer-last-name');
    this.$email = this.$0.find('.customer-email');
    this.$comments = this.$0.find('.customer-comments');
    this.$cancelPolicy = this.$0.find('.cancel-policy');
    this.$ratePlanDescription = this.$0.find('.rate-plan-short-description');
    this.$ratePlanRibbon = this.$0.find('.discount-ribbon');

    this.adults = new NumberSpan();
    this.children = new NumberSpan();
    this.nights = new NumberSpan();
    this.rooms = new NumberSpan();

    this.adults.setElements(this.$0.find('span.adults'));
    this.children.setElements(this.$0.find('span.children'));
    this.nights.setElements(this.$0.find('span.nights'));
    this.rooms.setElements(this.$0.find('span.rooms'));

    if(window.reservationDataFromAEM && window.reservationDataFromAEM.confirmationNumber){
      this.totalForStayConversion(parseFloat(window.reservationDataFromAEM.totalCash.replace('$','')),window.reservationDataFromAEM.currencyCode);
      currencyConverter.updateDisclaimerTime();
    }
    this.bwsScroll();
  }

  bwsScroll() {
    const header = document.querySelector('.retail-banner-mt');
    const bookingSummary = document.querySelector('.booking-summary-widget .res-rate-summary');

    if($(".booking-summary-widget").find(".rs-book-with-conf")){
      $(".booking-summary-widget").find(".rs-book-with-conf").hide();
    }

    if($(".booking-summary-widget").next(".container.component").find(".bb-count-on-us")){
      $(".booking-summary-widget").next(".container.component").find(".bb-count-on-us").hide();
    }

    // WYNDHAM GARDEN REMOVE EXTRA WHITE SPACE
    if($(".booking-summary-widget").prev('.booking-top-padding').length > 0 && window.brand_id === 'gn') {
      $(".booking-summary-widget").prev('.booking-top-padding').hide();
    }
    
    if (!header || !bookingSummary) {
      return;
    }

    const sticky = header.offsetHeight;

    const stickBookingSummary = () => {
      if (window.pageYOffset > sticky && window.innerWidth >= 720) {
        bookingSummary.classList.add('sticky');
      } else {
        bookingSummary.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', stickBookingSummary);
    stickBookingSummary();
  }

  handleRoomType(str) {
    this.$roomType.html(str);
  }
  handleRoomDescription(str) {
    this.$roomDescription.html(str);
  }

  handleCheckIn(d, t) {
    if (d) {
      if (!(_isDateType(d))) {
        d = new Date(d.replace('-', '/'));
      }

      let formatted = formatDateForPrinting(d, 'weekdayCompact');

      this.$checkInDate.html(formatted);
    }

    if (t) {
      this.$checkInTime.html(formatTimeShow(t));
    }
  }
  handleCheckOut(d, t) {
    if (d) {
      d = new Date(d);
      let formatted = formatDateForPrinting(d, 'weekdayCompact');

      this.$checkOutDate.html(formatted);
    }

    if (t) {
      this.$checkOutTime.html(formatTimeShow(t));
    }
  }
  handleSubtotal(price, currency) {
    this.$subtotal.html(printDollarAmount(price, currency));
  }
  handleTotal(price, currency) {
    this.$total.html(printDollarAmount(price, currency));
    this.$paymentTotal.html(printDollarAmountonPayment(price, currency));
    this.$cctotal.html(printDollarAmount(price, currency));
    let authoredCredit = parseFloat(this.$cccredit.data('val'));
    this.$cccredit.html(printDollarAmount(authoredCredit, currency));

    if(this.$points.text() !== '' && !this.$total.text().includes('+')){
      this.$total.prepend('+');
    }
    if (price - authoredCredit < 0) {
      this.$0.find('.math-container').remove();
    } else {
      this.$cccredit.prepend('- ');
      this.$ccmath.html(printDollarAmount((price - authoredCredit), currency));
    }

    this.totalForStayConversion(price,currency);
  }
  totalForStayConversion(price,currency){
    if (currency !== getSelectedCurencyCode(currency)) {
      if ($_PAGE_.is(".packages-page")){
        $(".res-rate-currency-conversion_header").hide();
      }
      $(".res-rate-currency-conversion_header_converted-rate").text((getSelectedCurencyCode(currency) == 'USD' ? '$' : '') + convertToCurrency(parseFloat(price),currency) + ' ' + getSelectedCurencyCode(currency));
      $(".res-rate-currency-conversion").show();
    }
  }
  handleTotalTax(price, currency) {
    this.$totalTax.html(printDollarAmount(price, currency));
    this.$rdTotalTax.html(printDollarAmount(price, currency, true));
  }
  handleConfirmationNumber(num) {
    this.$confirmationNumber.html(num);
  }
  handleCancelPolicy(policy) {
    this.$cancelPolicy.html(policy);
  }
  handleRatePlanDescription(description) {
    this.$ratePlanDescription.html(description);
  }
  handleRatePlanRibbon(rateplan) {
    // WRCCBarclay changes
    if (!UserHandler.isWRLoggedIn()) {
      // member rates only
      if (isMemberRate(rateplan)) {
        if (this.$ratePlanRibbon.length) {
          this.$ratePlanRibbon.removeClass('hidden');
        }
      }
    }else if(CookieHandler.cidAuthenticated() && isWRCCHolder()){
	if (this.$ratePlanRibbon.length && isGoFreeRate(rateplan)) {
	this.$ratePlanRibbon.removeClass('hidden');
	}
    }
  }
  handleAdults(n) {
    this.adults.setVal(n);
  }
  handleChildren(n) {
    this.children.setVal(n);
  }
  handleNights(n) {
    this.nights.setVal(n);
  }
  handleRooms(n) {
    this.rooms.setVal(n);
  }
  handleCustomer(customer) {
    this.$firstName.html(customer.FirstName);
    this.$lastName.html(customer.LastName);
    this.$email.html(customer.Email);
    this.$comments.html(customer.Comments);
  }
  handlePoints(totPoints) {
    this.$points.html(formatNumber(totPoints));
    //WRCCBarclay changes
	if(CookieHandler.cidAuthenticated() && isWRCCHolder()){
	this.$totalPoints.html('<strike>' + formatNumber(totPoints) + '</strike>');
	}else{
	this.$totalPoints.html(formatNumber(totPoints));
	}

  }
  handleGoFast(rate, nights) {
    this.handlePoints(rate.pacPoints * nights);
    this.$pacSubtotal.html(printDollarAmount(rate.totalBeforeTax, rate.currencyCode));
    this.$pacSubtotal.show();
    this.$0.find('.price').remove();
    this.handleTotal(rate.totalAfterTax, rate.currencyCode);
    if(!this.$total.text().includes('+')){
      this.$total.prepend('+');
    }
    this.handleTotalTax(rate.totalAfterTax - rate.totalBeforeTax, rate.currencyCode);
  }
  handleGoFree(totPoints) {
    let rateDetailsLink = this.$0.find('.rate-summary-content').find('.rate-details-link').detach()
      .wrap('<div class="name-secondary"></div>')
      .parent();
    this.handlePoints(totPoints);
    this.$0.find('.price').hide();
    this.$0.find('.tax').hide();
    this.$0.find('.cash-total').hide();
    this.$pacSubtotal.remove();
    this.$ratePlanDescription.after(rateDetailsLink);
  }
  handleCashRP() {
    this.$0.find('.points').remove();
    this.$0.find('.points-total').remove();
  }
  handleUNAP() {
    let propInfo = getUNAP();
    
    let shouldHideProvinceCountry = HideProvinceCountryConfig.getHideProvinceCountryConfig(getSessionCriteria().propertyId);
    
    let address = shouldHideProvinceCountry ? getAddressWithoutProvinceCountry() : getAddress();
    
    this.$propertyName.html(propInfo.name);
    this.$propertyAddress.html(address);
    if (_LANGUAGE_ !== 'en') {
      this.$propertyPhone.html(propInfo.phone);
    }
  }
  handleGiftCard(val) {
    if (_isNull(val)) {
      this.$0.find('.rs-gift-card-confirmation,.rs-gift-card').hide();
    }
  }
  instantiate($parent) {
    this.$0 = $parent;
    this.$roomType = $parent.find('.room-type-description');
    this.$roomDescription = $parent.find('.room-description');
    this.$checkInDate = $parent.find('.check-in span.date');
    this.$checkInTime = $parent.find('.check-in span.time');
    this.$checkOutDate = $parent.find('.check-out span.date');
    this.$checkOutTime = $parent.find('.check-out span.time');
    this.$subtotal = $parent.find('.price .subtotal .val');
    this.$pacSubtotal = $parent.find('.points .pac-cash.subtotal');
    this.$points = $parent.find('.points .pts.subtotal .val');
    this.$total = $parent.find('.cash-total .val');
    this.$paymentTotal = $parent.find('.cash-total .payment-value');
    this.$totalPoints = $parent.find('.points-total .val');
    this.$cctotal = $parent.find('.credit-card-promo .book-room-rate-total-after-tax');
    this.$cccredit = $parent.find('.credit-card-promo .credit-val');
    this.$ccmath = $parent.find('.credit-card-promo .total-after-credit-val');
    this.$totalTax = $parent.find('.total-roomrate-tax');
    this.$rdtotalTax = $parent.find('.rd-total-roomrate-tax');
    this.$confirmationNumber = $parent.find('.book-confirmation-number');
    this.$firstName = $parent.find('.customer-first-name');
    this.$lastName = $parent.find('.customer-last-name');
    this.$email = $parent.find('.customer-email');
    this.$comments = $parent.find('.customer-comments');
    this.$cancelPolicy = $parent.find('.cancel-policy');
    this.$ratePlanDescription = $parent.find('.rate-plan-short-description');

    this.adults = new NumberSpan();
    this.children = new NumberSpan();
    this.nights = new NumberSpan();
    this.rooms = new NumberSpan();

    this.adults.setElements(this.$0.find('span.adults'));
    this.children.setElements(this.$0.find('span.children'));
    this.nights.setElements(this.$0.find('span.nights'));
    this.rooms.setElements(this.$0.find('span.rooms'));
  }
}

class NumberSpan {
  constructor($container, val){
    this.$container = $container || null;
    this.val = val || null;
    this.$num = null;
    this.$singular = null;
    this.$plural = null;
    this.val = 0;
    this.init();
  }
  init(){
    if (this.$container && this.$container.length) {
      this.setElements(this.$container);
    }
    if (this.val !== 'undefined') {
      this.setVal(this.val);
    }
  }
  setElements($container) {
    this.$num = $container.find('> span.num');
    this.$singular = $container.find('> span.singular');
    this.$plural = $container.find('> span.plural');
  }
  setVal(v) {
    v = v * 1;
    if (!(isNaN(v))) {
      this.val = v;

      if (this.$num && this.$num.length) {
        this.print();
      }
    }
  }
  print() {
    if(this.$num){
      this.$num.html(this.val);
    }

    if (this.val == 1) {
      this.$singular.show();
      this.$plural.hide();
    } else {
      this.$singular.hide();
      this.$plural.show();
    }
  }
}

export default new BookingSummary();
