import { exists } from '../base/dom-utils.js';
import { ReservationHandler } from '../base/session-handler.js';
import { callService } from '../base/utils.js';
import { _LANGUAGE_, _REGION_, isPublish } from '../base/vars.js';

class Nor1 {
  constructor() {
    $(() => {
      if (exists('.nor1-component') && isPublish === true) {
        this.triggerNor1Call();
      }
    });
  }

  triggerNor1Call() {
    let nor1Component = $('.nor1-component'),
      nor1RestUrl = nor1Component.data('service-url'),
      apiKey = nor1Component.data('api'),
      providerId = nor1Component.data('provider'),
      reservation = ReservationHandler.getReviewReservation();

    let data = {
      PCD: reservation.propertyId, // Hotel/Property Code
      EBC: reservation.confirmationNumbers, // Booking code/reservation number
      RCC: reservation.roomCode, // Room category code
      GAD: reservation.checkInDate.replace(/-/g, ''), // Arrival date (20181109)
      NMN: reservation.nights, //Number of nights
      TBP: reservation.totalCash, // Total booking price
      GEM: reservation.email, // Guest email
      GLN: reservation.lastName, // Guest last name optional
      GFN: reservation.firstName, // Guest first name
      PID: providerId, // Provider ID (Nor1 provided)
      PET: 'API', // Provider Entry Type
      PRC: reservation.rateCode, // Rate code
      NOA: reservation.adults, // Number of adults
      NOC: reservation.children, // Number of children
      LNG: _LANGUAGE_, // ISO 639-1 compliant language code
      GCC: _REGION_, // ISO 3166-1-alpha-2 country code
      GDD: reservation.checkOutDate.replace(/-/g, ''), // Departure date (20181110)
      GNR: reservation.rooms, // Number of rooms
      api_key: apiKey // API key (Nor1 provided)
    };

    callService(nor1RestUrl, data, 'POST').then((res) => {
      if (res.success == true || res.success == 'true') {
        this.handleNor1Response(res);
      }
    });
  }

  handleNor1Response(res) {
    let resContent = res.content,
      resFirstName = resContent.first_name,
      resTitle = resContent.title,
      resDesc = resContent.description,
      resImg = res.img_src,
      resCTAText = resContent.button,
      resCTALink = res.offer_link;

    // dom els
    let nor1FirstName = $('.nor1-name'),
      nor1Title = $('.nor1-subtitle'),
      nor1Desc = $('.nor1-body'),
      nor1BtnText = $('.nor1-cta');

    $(nor1FirstName).html(resFirstName);
    $(nor1Title).html(resTitle);
    $(nor1Desc).html(resDesc);
    $(nor1BtnText).html(resCTAText);
    $('img.nor1-analytics').attr('src', resImg);
    $('.nor1-cta').attr('href', resCTALink);
    $('.nor1-component').show();
  }
}

export default new Nor1();
