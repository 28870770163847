import Analytics from '../base/wyn-analytics-module.js';

class TextOffer {
	constructor() {
		document.querySelectorAll('.card-button').forEach((el) => {
			el.addEventListener('click', function(ev) {
				var modalIndex = ev.target.href.indexOf("#modal");
				if (modalIndex > -1) {
					document.querySelector('#bookingBar__main').classList.remove('stick');
				}
			});
		});
    this.handleClickAnalytics();
	}


  handleClickAnalytics() {
    const packageLinks = document.querySelectorAll('.generic-page .text-offers-component .card-button');

    if(packageLinks.length > 0) {
      packageLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const cardContent = e.target.closest('.card-content');
          const titleText = cardContent.querySelector('.title-link p').textContent.trim();
          window.digitalData.package.packageInfo.packageSelected = titleText;
          Analytics.satelliteTracker('packageSelection');

        });
      });
    }
    
}

}

export default new TextOffer();
