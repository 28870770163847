import { exists, getWindowHeight } from '../../base/dom-utils.js';
import { removeProtocol } from '../../base/utils.js';

class UUSubnav {
  constructor() {
    if (exists('#uu-navbar-collapse-1')) {
      this.setActiveNavItems();
      this.onSubnavOpen();

      this.handlePageInteraction();
    }
  }

  setActiveNavItems() {
    $('#uu-navbar-collapse-1 a').each((i, el) => {
      let href = removeProtocol(el.href).split('?')[0].trim(),
        location = removeProtocol(window.location.href).split('?')[0];

      if (href === location && el.attributes.getNamedItem('href').value !== '#' && el.dataset.target !== '#genericLightbox') {
        $(el).addClass('uu-active');
        $(el).parents('.dropdown')
          .children('a')
          .addClass('uu-active');
      }
    });
  }

  onSubnavOpen() {
    $('#uu-navbar-collapse-1').on('show.bs.collapse', () => {
      let calcHeight = getWindowHeight() - ($('.uu-subnav-bg').offset().top - $(window).scrollTop());
      $('#uu-navbar-collapse-1').css({
        'max-height': calcHeight
      });
    });
  }

  handlePageInteraction() {
    if (exists('.booking-bar-form.mini-booking, .fix-property-info, #overview')) {
      let touchmoved;
      $('.booking-bar-form.mini-booking, .fix-property-info, #overview').on('touchend', () => {
        if(touchmoved != true){
          $('#uu-navbar-collapse-1').collapse('hide');
        }
      }).on('touchmove', () => {
          touchmoved = true;
      }).on('touchstart', () => {
          touchmoved = false;
      });
    }
  }
}

export default new UUSubnav();
