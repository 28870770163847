import ConfigsUtils from './config-utils';
const KEY = 'hide-province-country';

/**
 * Retrieves the configuration for hiding Province and city properties.
 * @returns {boolean|boolean} The value of the 'hide-country-city-property' attribute from the configuration, or an empty string if not found.
 * @see {config-utils.js}
 * @returns {Array} of amenities codes, empty array otherwise
 * @param propertyId Properties selected
 */
const getHideProvinceCountryConfig = (propertyId) => {
  const config = ConfigsUtils.findConfig(KEY);
  
  if (typeof config != 'undefined' && propertyId != null) {
    var hideProvinceAndCountry = config.attributes[KEY];
    
    if (typeof hideProvinceAndCountry === 'string') {
      hideProvinceAndCountry = hideProvinceAndCountry.split(',').map(id => id.trim());
    }

    const shouldHideProvinceCountry = Array.isArray(hideProvinceAndCountry)
      ? hideProvinceAndCountry.includes(propertyId)
      : hideProvinceAndCountry === propertyId;
    return shouldHideProvinceCountry;
  }
  return false;
};

export default {
  getHideProvinceCountryConfig
};
