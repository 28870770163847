import {exists} from '../base/dom-utils.js';

class Cvent {
    constructor() {
        if (exists('#cventDiv')) {
            this.$component = $('#cventDiv', '.cvent');
            this.$data = this.$component.data();
            this.initCvent();
        }
    }

    initCvent() {
        const heroImageSlides = this.heroImageSlides();
        const settings = {};
        settings.targetDiv = 'cventDiv';
        settings.formAccessKey = this.$data.formaccesskey;

        if (this.ifExist(this.$data.overrides) && this.$data.overrides) {
          settings.overrides = {
            assetRoot: this.$data.overrides,
          }; // USE FOR DEVELOPMENT
        }
        settings.theme = {
            font: {
                fontFamily: this.$data.fontfamily,
                src: this.buildfontSrc(),
                primaryColor: this.$data.primarycolor,
                secondaryColor: this.$data.secondarycolor
            },
            accents: {
                primaryColor: this.$data.accentprimarycolor,
                secondaryColor: this.$data.accentsecondarycolor,
                primaryHoverColor: this.$data.hoverprimarycolor,
                secondaryHoverColor: this.$data.hoversecondarycolor
            }
        };
        settings.heroImage = {
            large: window.origin + this.$data.heroimagelarge,
            medium: window.origin + this.$data.heroimagemedium,
            small: window.origin + this.$data.heroimagesmall,
            extraSmall: window.origin + this.$data.heroimageextrasmall,
            original: window.origin + this.$data.heroimageoriginal
        };
        settings.heroImageSlides = heroImageSlides;
        settings.enabledFilters = this.$data.enabledfilters.split(',');
        settings.analytics = {};
        settings.themeName = this.$data.themename;
        settings.integrationSupport = {
            fixedHeaderHeight: this.$data.fixedheaderheight,
        };
        $(window).load(()=> {
            // eslint-disable-next-line
            showSearch(settings);
        });
    }
    ifExist(setting) {
        return typeof setting !== 'undefined' ? setting : false;
    }


    heroImageSlides() {
        let slides = [];
        let slidesParse;
        slidesParse = this.$data.heroimageslides;
        if (this.ifExist(this.$data.heroimageslides)) {
            $.each(slidesParse, (i, item) => {
                slides.push({
                    images: {
                        large: item.heroImageLarge ? window.origin + item.heroImageLarge : false,
                        medium: item.heroImageMedium ? window.origin + item.heroImageMedium : false,
                        small: item.heroImageSmall ? window.origin + item.heroImageSmall : false,
                        extraSmall: item.heroImageExtraSmall ? window.origin + item.heroImageExtraSmall : false,
                        original: item.heroImageOriginal ? window.origin + item.heroImageOriginal : false,
                    },
                    title: item.title ? item.title : "",
                    description: item.description ? item.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&amp;/g, "&") : "",
                    link: item.link ? item.link : ""
                });
            });
        }
        return slides;
    }

    buildfontSrc() {
        let src = "";
        if (this.$data.fontfamily && this.$data.fontformat) {
            src = "url(" + this.$data.fontfamily + ") format(" + this.$data.fontformat + ")";
        }
        return src;
    }
}

export default new Cvent();
