import {
    convertMonthsToDays,
    getXDays,
} from '../utils.js';

const DEFAULT_MAXIMUM_LEAD_DEPARTURE = 365;

const DataSet = {
    MAXIMUM_LEAD_DEPARTURE: 'maxdate',
    MAXIMUM_LOS: 'max-los',
};
const Selectors = {
    BOOKING_DATES_DROPDOWN: '.booking-dates-dropdown',
    BOOKING_DATES_CONTAINER: '.booking-dates-container'
};


function getMaximumLeadDeparture() {
    const bookingDatesDropDownElement = $(Selectors.BOOKING_DATES_DROPDOWN);
    const maximumLeadDeparture = $(bookingDatesDropDownElement).data(DataSet.MAXIMUM_LEAD_DEPARTURE);

    let maxDate = DEFAULT_MAXIMUM_LEAD_DEPARTURE;

    if(maximumLeadDeparture){
      const maxMonths = parseInt(maximumLeadDeparture);
      maxDate = Math.floor(convertMonthsToDays(maxMonths));
    }

    return maxDate;
}

function getMaximumLengthOfStay() {
    const bookingDatesDropDownElement = $(Selectors.BOOKING_DATES_CONTAINER);
    return bookingDatesDropDownElement.data(DataSet.MAXIMUM_LOS);
}

function getMaximumCheckOutDate(checkInDate) {
    const maximumLeadDeparture = getMaximumLeadDeparture();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const currentDatePlusMaxLeadDeparture = getXDays(maximumLeadDeparture, today);
    let maxDate = currentDatePlusMaxLeadDeparture;
    const maxLOS = getMaximumLengthOfStay();
    
    if (maxLOS) {
        const checkInDatePlusMaxLOS = getXDays(maxLOS, checkInDate);
        maxDate = checkInDatePlusMaxLOS < currentDatePlusMaxLeadDeparture ? checkInDatePlusMaxLOS : currentDatePlusMaxLeadDeparture;
    }
    return maxDate;
}

function getMaximumCheckInDate() {
    return getMaximumLeadDeparture() - 1;
}

export default {
    getMaximumLeadDeparture,
    getMaximumLengthOfStay,
    getMaximumCheckOutDate,
    getMaximumCheckInDate,
};
