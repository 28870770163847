import { getBrand } from '../base/session-handler.js';
import { exists, isDesktopWidth } from '../base/dom-utils.js';

// homepage hero - drawer component
class HomepageHero {
  constructor() {
    if (exists('.homepage-hero-component')) {
      this.init();
    }
  }

  init() {
    this.$drawer = $('.hero-drawer-container');
    this.$bannerEl = $('.homepagehero .promo-banner');
    this.$trigger = $('.exit-open');

    setTimeout(() => {
      this.fadeInOutCheck();
      this.debouncedEvt(this.$drawer.add(this.$bannerEl), 'mouseenter', setTimeout(() => {
        this.fadeInOutCheck();
      }, 5000));
    }, 2000);
    //fix overlapping issues if content is more
    if (isDesktopWidth() && exists('#heroPromoRibbon')) {
      if ($('.promo-banner .hero-button-banner .banner-text').text().length > 80) {
        $('.promo-banner .hero-button-banner .banner-text').css('padding-right', '10%');
      }
    }

    this.$trigger.click(() => {
      this.expandCollapseDropdown();
    }).keydown((evt) => {
      // enter or space clicked
      if ((evt.which == 13) || (evt.which == 32)) {
        this.expandCollapseDropdown();
      }
    });

    $('.hero-drawer-container').on('click', () => {
      if (exists($('.hero-promo-ribbon .subline a'))) {
        $('.hero-promo-ribbon .subline a')[0].click();
      }
    });
  }

  debouncedEvt($targ, evtName, timeoutRef) {
    if (!$targ || $targ.length === 0) return;
    if (!evtName) return;

    $targ.on(evtName, function() {
      clearTimeout(timeoutRef);
    });
  }

  fadeInOutCheck() {
    this.expandCollapseDropdown();
  }

  expandCollapseDropdown() {
    this.$drawer.toggle();
    if (this.$drawer.css('display') === 'block') {
      this.$bannerEl.css({
        'background-color': 'rgba(255,255,255,1)'
      });
      this.$drawer.attr('aria-expanded', 'true');
      this.$trigger.attr('aria-expanded', 'true');
    } else {
      let currBrand = getBrand().toUpperCase();
      if (currBrand === 'AA' || currBrand === 'RE') {
        this.$bannerEl.css({
          'background-color': 'rgba(255,255,255,.85)'
        });
      } else {
        this.$bannerEl.css({
          'background-color': 'rgba(255,255,255,.85)'
        });
      }
      this.$drawer.attr('aria-expanded', 'false');
      this.$trigger.attr('aria-expanded', 'false');
    }
  }
}

export default new HomepageHero();
