import {
  children_stay_free_Age,
  children_stay_free_No_Age,
  smoking_policy_no,
  smoking_policy_yes,
} from '../base/vars.js';
import {
  formatTimeShow,
  replaceToken
} from "../base/utils";

class PropertyPolicies {

  constructor(propertyOverview) {
    let policies = propertyOverview.hotelPolicies;

      if (policies.checkInTime != null) {
        let check_In_Time = formatTimeShow(policies.checkInTime);
        this.checkInTime = check_In_Time.replace(/m/i, '.m.');
      }
      if (policies.checkOutTime != null) {
        let check_out_Time = formatTimeShow(policies.checkOutTime);
        this.checkOutTime = check_out_Time.replace(/m/i, '.m.');
      }
      if (policies.childStayFree && policies.childStayFree !== null) {
        this.children = children_stay_free_No_Age;
        if(policies.childMaxAge && policies.childMaxAge !== null) {
            this.children = children_stay_free_Age ? replaceToken(children_stay_free_Age, '${childAge}', policies.childMaxAge) : '';
        }
      }
      if (policies.petpolicy != null) {
        this.pets = policies.petpolicy;
      }
      if (policies.smokeFreePolicy && policies.smokeFreePolicy !== null) {
        this.smoking = smoking_policy_no;
        if (policies.smokeFreePolicy === 'Y') {
          this.smoking = smoking_policy_yes;
        }
      }
      if (propertyOverview.alertPriority && propertyOverview.alertMessage && propertyOverview.alertPriority === '3B') {
        this.alertMessage = propertyOverview.alertMessage;
      }
    }
}

export default PropertyPolicies;
