
import ConfigsUtils from './aem-configs/config-utils.js';
import {  
          _LOCALE_,
          _defaultChannelId,
          brand_id,
          $_PAGE_
      } from './vars.js';
import { _isValidBrand, currencyConverterCookieName, currencyRate,
   callService,updateCurrencySelectorCode } from './utils.js';
import {isTabletWidth, isMobileWidth} from './dom-utils.js';
import Analytics from '../base/analytics/currency-converter-analytics.js';
import { ReservationHandler, goToPackages, goToBook } from './session-handler.js';

class CurrencyConverter {
  constructor() {
    this.desktopCurrencyOn = false;
    this.tabletCurrencyOn = false;
    this.mobileCurrencyOn = false;
    this.$currencies = [];
    this.$fullCurrencyElement = $('.full-currency-options');
    this.$topCurrencyElement = $('.top-currency-options');
    this.$fullCurrency = ConfigsUtils.getConfigs('full-currencies',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any);
    this.$topCurrency  = ConfigsUtils.getConfigs('top-currencies',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any);
    this.$fullCurrencyList = [];
    this.$topCurrencyList  = [];
    if(!$(".page").is(".search-results-page") || currencyRate){
      updateCurrencySelectorCode('',currencyRate);
    }
    this.$disableCurrencyConversion = ConfigsUtils.getConfigs('disable-currency-conversion',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any);
    if(this.$disableCurrencyConversion && !this.$disableCurrencyConversion.reduce((result , item) => {
      return (_isValidBrand(window.brand_id) && item && item.brands && item.brands.includes(window.brand_id) && item.locales && item.locales.includes(window._LOCALE_)) ? item : result;
    },null)){
      this.getCurrency();
    }
    if (window.brand_id === 'wr') {
      this.initializeModalElements();
      this.initializeSidebarElements();
      this.addEventListeners();
    }
    this.handleCurrencySelectorDisabled();
    this.hideConverterOnModifyPage();
  }

  getCurrency() {
    this.addDefaultOption();
    let currencies =  localStorage['currencies'] && JSON.parse(localStorage['currencies']);

    if((!currencies || currencies.expireAt < new Date()) && ($_PAGE_.is('.search-results-page') || $_PAGE_.is('.booking-page') || $_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')  || $_PAGE_.is('.rooms-rates-page') || $_PAGE_.is('.property-page'))){
      callService('currencyRetrieve', {}).then((res) => {
        if(res && res.currencies && res.currencies.length > 0){
          this.$currencies =  [res.currencies.filter(currency=>(currency.exchange_rate !== null))[0],
            ...res.currencies.filter(currency=>(currency.exchange_rate !== null)).slice(1).sort((a,b)=> {
              return a.currency_code.toString().localeCompare(b.currency_code);
            })];
          this.orderCurrencyMapping();
          let date = new Date();
          localStorage['currencies'] = JSON.stringify({ expireAt:date.setTime(date.getTime() + (60 * 60 * 2 * 1000)),value: this.$currencies});
          this.updateDisclaimerTime();
        }
      });
    }else{
      try {
        this.$currencies = currencies && currencies.value;
        this.orderCurrencyMapping();
      } catch(error){
        return;
      }
    }

    this.updateDisclaimerTime();
    // Initiate analytics tracking
    Analytics.attachViewMoreCurrenciesClickEvent();
    Analytics.initializeClickOnCurrencyModalOptions();
    Analytics.attachCurrencyDropDownClickEvent();
  }

  /**
   * Initializes modal elements.
   * This method fetches the modal elements and assigns them to instance variables.
   * @method
   */
  initializeModalElements() {
    this.currencyModal = document.getElementById('currencyModal');
    this.currencyModalCloseButton = document.querySelector('.currency-selector__modal-close');
    this.currencyModalViewMoreButton =  document.querySelector('.currency-viewMore');

    if(this.currencyModal) {
      document.body.appendChild(this.currencyModal);
      this.currencyModal.classList.add('currency-converter__modal');
      document.querySelector('.currency-selector__modal').remove();
      $('.currency-selector__modal-close').on('click', () => {
        $('.modal-backdrop').remove();
        $('.fade.in').hide();
        $('.fade.in').removeClass('in');
        $('body').removeClass('modal-open');
      });
    }
  }

  /**
   * Initializes sidebar elements.
   * This method fetches the sidebar elements and assigns them to instance variables.
   * @method
   */
  initializeSidebarElements() {
    this.currencySideBar = document.getElementById('currencySideBar');
    if(this.currencySideBar) {
      document.body.appendChild(this.currencySideBar);
    }
  }

  /**
   * Toggles the visibility of the currency modal.
   * @method
   * @param {boolean} show - Whether to show or hide the modal.
   */
  toggleModalVisibility(show) {
    if (this.currencyModal) {
      this.currencyModal.style.display = show ? 'block' : 'none';
    }
  }

  /**
   * Adds event listeners for the modal.
   * @method
   */
  addEventListeners() {
    if (this.currencyModalCloseButton) {
      this.currencyModalCloseButton.addEventListener('click', () => {
        this.toggleModalVisibility(false);
      });
    }

    if (this.currencyModalViewMoreButton) {
      document.body.addEventListener('click', (event) => {
        if (event.target.matches('.currency-viewMore')) {
          this.toggleModalVisibility(true);
        }
      });
    }
  }

  orderCurrencyMapping(){
    try{
      this.$currencies.forEach((item) => {
        if(Object.keys(this.$fullCurrency[0]).length > 0 && this.$fullCurrency[0][item.currency_code]  && item.exchange_rate){
          this.$fullCurrencyList.push({
            currencyLable:this.$fullCurrency[0][item.currency_code],
            currencyCode:item.currency_code,
            curencyobject: item,
            currencyLableLanguage: item.in_language_value[_LOCALE_] || this.$fullCurrency[0][item.currency_code]
          });
        }
        if(Object.keys(this.$topCurrency[0]).length > 0 && this.$topCurrency[0][item.currency_code] && item.exchange_rate){
          this.$topCurrencyList.push({
            currencyLable:this.$topCurrency[0][item.currency_code],
            currencyCode:item.currency_code,
            curencyobject: item,
            currencyLableLanguage: item.in_language_value[_LOCALE_] || this.$topCurrency[0][item.currency_code]
          });
        }
      });

      let fullUSDindex = this.getIndexOfCurrency(this.$fullCurrencyList);
      if(fullUSDindex >= 0){
        [...this.$fullCurrencyList.splice(fullUSDindex,1),
          ...this.$fullCurrencyList
            .sort((a,b)=> {
              return a.currencyLableLanguage.toString().localeCompare(b.currencyLableLanguage);
            })].forEach(item=>this.populateCurency(this.$fullCurrencyElement,item.currencyLable,item.curencyobject,item.currencyLableLanguage));
      }else{
        this.$fullCurrencyList.sort((a,b)=> {
          return a.currencyLableLanguage.toString().localeCompare(b.currencyLableLanguage);
        }).forEach(item=>this.populateCurency(this.$fullCurrencyElement,item.currencyLable,item.curencyobject,item.currencyLableLanguage));
      }
      let topUSDindex = this.getIndexOfCurrency(this.$topCurrencyList);

      if(topUSDindex >= 0){
        [...this.$topCurrencyList.splice(topUSDindex,1),
          ...this.$topCurrencyList
            .sort((a,b)=> {
              return a.currencyLable.toString().localeCompare(b.currencyLable);
            })].forEach(item=>this.populateCurency(this.$topCurrencyElement,item.currencyLable,item.curencyobject,item.currencyLableLanguage));
      }else{
        this.$topCurrencyList
          .sort((a,b)=> {
            return a.currencyLable.toString().localeCompare(b.currencyLable);
          }).forEach(item=>this.populateCurency(this.$topCurrencyElement,item.currencyLable,item.curencyobject,item.currencyLableLanguage));
      }
    } catch (error){
      this.disableCurrencySelectorDesktop();
      this.disableCurrencySelectorTablet();
      this.disableCurrencySelectorMobile();
    }
  }

  getIndexOfCurrency(arr, curency = 'USD'){
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].currencyCode == curency) {
        return i;
      }
    }
    return -1;
  }

  populateCurency(element,lable,curencyobject,lableLanguage){
    let currencyOption = document.createElement('li');
    currencyOption.className = 'currency-option ' + curencyobject.currency_code;
    if(currencyRate && currencyRate.currency_code == curencyobject.currency_code){
      currencyOption.className = 'currency-option currency-option__selected';
    }
    currencyOption.appendChild(document.createTextNode(curencyobject.currency_code + ' - ' + (lableLanguage || lable)));
    $(currencyOption).on('click', () => {
      selectCurrency(curencyobject);
      $('.currency-option').removeClass('previous-option__selected');
      $('.currency-option.currency-option__selected').addClass('previous-option__selected');
      $('.currency-option').removeClass('currency-option__selected');
      $('.currency-option.' + curencyobject.currency_code).addClass('currency-option__selected');
    });
    element.append(currencyOption);
  }

  addDefaultOption(){
    let currencyOption = $('.currency-default-option').clone();
    this.$fullCurrencyElement.empty();
    this.$topCurrencyElement.empty();
    if(currencyRate && !currencyRate.currency_code){
      currencyOption.addClass('currency-option currency-option__selected');
    }
    currencyOption.on('click',() =>{
      removeCurrency();
      $('.currency-option').removeClass('previous-option__selected');
      $('.currency-option.currency-option__selected').addClass('previous-option__selected');
      $('.currency-option').removeClass('currency-option__selected');
      $('.currency-default-option').addClass('currency-option__selected');
    });
    this.$fullCurrencyElement.html(currencyOption.clone(true));
    this.$topCurrencyElement.html(currencyOption.clone(true));
  }

  updateDisclaimerTime(){
    try{
      let dateObject = new Date();
      let bloombergDate;
      let disclaimerDate = '-';
      let disclaimerDay = dateObject.toLocaleString('default', { day: 'numeric' });
      let disclaimerYear = dateObject.toLocaleString('default', { year: 'numeric' });
      let disclaimerMonth = dateObject.toLocaleString('default', { month: 'short' });
      if (localStorage.getItem(currencyConverterCookieName)){
        bloombergDate = JSON.parse(localStorage.getItem(currencyConverterCookieName))['last_modified_bloomberg_date'] || '';
      } else if(JSON.parse(localStorage['currencies']) && JSON.parse(localStorage['currencies']).value.filter(currency=>(currency.last_modified_bloomberg_date !== null)).length > 0){
        bloombergDate = JSON.parse(localStorage['currencies']).value.filter(currency=>(currency.last_modified_bloomberg_date !== null))[0]['last_modified_bloomberg_date'] || '';
      }
      if(bloombergDate){
        bloombergDate = bloombergDate.split('-');
        disclaimerYear = bloombergDate[0];
        disclaimerMonth = bloombergDate[1];
        dateObject.setMonth(Number(disclaimerMonth) - 1);
        disclaimerMonth = dateObject.toLocaleString('default', { month: 'short' });
        disclaimerDay = bloombergDate[2];
      }
      disclaimerDate = disclaimerMonth + " " + disclaimerDay + ", " + disclaimerYear;
      $('.currency-selector_disclaimer').text(function() {
        return $(this).text().replace('{updatedDate}', disclaimerDate);
      });
    } catch(error) {
      return;
      //console.log("Time could not be updated in the disclaimer text", error);
    }
  }

  currencySelectorChevronFlipper(){
    //Logic for flipping "currency" button chevron
    const bookingBarCollapsed = document.querySelector('.booking-bar-collapsed');
    $(".currency-toggle.dropdown-toggle.currency-converter").parent().each(function() {
      let target = this;
      new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const isDropdownExpanded = target.getElementsByTagName('a')[0].getAttribute('aria-expanded') == "true";
            const dropdownToggleSpan = target.getElementsByTagName('a')[0].querySelector('span');
            if (isDropdownExpanded) {
              dropdownToggleSpan.classList.add('currency-chevron__open');
              bookingBarCollapsed.classList += " currency-selector__drop-down__zPriority";
            } else {
              dropdownToggleSpan.classList.remove('currency-chevron__open');
              bookingBarCollapsed.classList.remove("currency-selector__drop-down__zPriority");
            }
          }
        });
      }).observe(target, { attributes: true });
    });
  }
  handleCurrencySelectorDisabled(){
    const currencyDropDownElement = document.getElementById("currencyDropDown");
    if ($(".currency-toggle_invert").length){
      $(".currency-toggle_invert").hide();
    }
    if (currencyDropDownElement){
      if(document.querySelector(".currency-converter__upscale")) {
        document.querySelector(".currency-selector__drop-down").classList.add('currency-selector__drop-down__upscale');
        document.querySelector(".currency-converter__upscale").after(document.querySelector(".currency-selector__drop-down"));
      }
      this.currencySelectorChevronFlipper();
      this.desktopCurrencyOn = true;
    }
    if ((document.getElementById("currencyDropDownReference") || document.getElementById("currencyDropDownReferenceRR") || document.getElementById("currencyDropDownReferenceUpscale")) && !this.tabletCurrencyOn){
      this.tabletOpensCurrencyModal(currencyDropDownElement);
      this.tabletCurrencyOn = true;
    }
    if (document.getElementById("currencySideOpen") || document.getElementById("currencySideOpenOverview")){
      if ($("#mobileCurrencyBreak").length && window.location.href.includes("overview")){
        $("#mobileCurrencyBreak").show();
      } else if ($("#mobileCurrencyBreak").length){
        $("#mobileCurrencyBreak").hide();
      }
      $(".currency-toggle__mobile").parent().removeClass("currency-off");
      if($("#searchCriteriaSummary").length && isMobileWidth()){
        $("#searchCriteriaSummary").removeClass("col-xs-24");
        $("#searchCriteriaSummary").parent().parent()[0].classList += " searchCriteriaSummary-grandparent";
        $("#searchCriteriaSummary")[0].classList += " searchCriteriaSummary-withCurrency";
      }
      if ($("#currencySideOpenOverview").length && window.location.href.includes("rooms-rates")){
        $("#currencySideOpenOverview").parent().parent().hide();
        $("#currencySideOpen").parent().siblings(':first-child').addClass("col-xs-19 noPadding");
      }
      if ($("h1 hotel-counter").length){
        $("h1 hotel-counter").css({"justify-content": "flex-start"});
        $("h1 hotel-counter").parent().parent().css({"display": "flex"});
      }
      this.mobileCurrencySidebar(currencyDropDownElement);

      this.mobileCurrencyOn = true;
    }

    if ((window.wyndham && window.wyndham.configs && window.wyndham.configs['disable-currency-conversion'] && window.wyndham.configs['disable-currency-conversion'].configs) && window.wyndham.configs['top-currencies'] && window.wyndham.configs['full-currencies']){
      for (let counter = 0; counter < window.wyndham.configs['disable-currency-conversion'].configs.length; counter++){
        if (window.wyndham.configs['disable-currency-conversion'].configs[counter].locales && (window.wyndham.configs['disable-currency-conversion'].configs[counter].locales.indexOf(_LOCALE_) != -1 || window.wyndham.configs['disable-currency-conversion'].configs[counter].locales.indexOf("all") != -1)){
          if (window.wyndham.configs['disable-currency-conversion'].configs[counter].brands && (window.wyndham.configs['disable-currency-conversion'].configs[counter].brands.indexOf(brand_id) != -1 || window.wyndham.configs['disable-currency-conversion'].configs[counter].brands.indexOf("all") != -1)){
            if (window.wyndham.configs['disable-currency-conversion'].configs[counter].channels) {
              if ($(".currency-toggle_invert").length){
                $(".currency-toggle_invert").show();
              }
              if ((window.wyndham.configs['disable-currency-conversion'].configs[counter].channels.indexOf("desktop") != -1 || window.wyndham.configs['disable-currency-conversion'].configs[counter].channels.indexOf("all") != -1) && this.desktopCurrencyOn){
                this.disableCurrencySelectorDesktop();
                this.desktopCurrencyOn = false;
              }
              if ((window.wyndham.configs['disable-currency-conversion'].configs[counter].channels.indexOf("tablet") != -1 || window.wyndham.configs['disable-currency-conversion'].configs[counter].channels.indexOf("all") != -1) && this.tabletCurrencyOn){
                this.disableCurrencySelectorTablet();
                this.tabletCurrencyOn = false;
              }
              if ((window.wyndham.configs['disable-currency-conversion'].configs[counter].channels.indexOf("mobileweb") != -1 || window.wyndham.configs['disable-currency-conversion'].configs[counter].channels.indexOf("all") != -1) && this.mobileCurrencyOn){
                this.disableCurrencySelectorMobile();
                this.mobileCurrencyOn = false;
              }
            }
          }
        }
      }
    }  else if(window.wyndham){
      if (window.wyndham && jQuery.isEmptyObject(window.wyndham.configs['top-currencies']) || jQuery.isEmptyObject(window.wyndham.configs['full-currencies'])){
        this.disableCurrencySelectorDesktop();
        this.desktopCurrencyOn = false;
        this.disableCurrencySelectorTablet();
        this.tabletCurrencyOn = false;
        this.disableCurrencySelectorMobile();
        this.mobileCurrencyOn = false;
      }
    }
  }
  disableCurrencySelectorDesktop(){
    $(".currency-toggle__separator").parent().addClass("currency-off");
    $("#currencyDropDown.currency-toggle").parent().addClass("currency-off");
  }
  disableCurrencySelectorTablet(){
    if (document.getElementById("currencyDropDownReferenceRR") && window.location.href.includes("rooms-rates")) {
      $("#currencyDropDownReferenceRR").addClass("currency-off");
    } else if (document.getElementById("currencyDropDownReference")) {
      $("#currencyDropDownReference").addClass("currency-off");
    }
    if (document.getElementById("currencyDropDownReferenceUpscale")) {
      $("#currencyDropDownReferenceUpscale").addClass("currency-off");
      $(".currency-toggle__separator-upscale").addClass("currency-off");
    }
    if($('.room-pricing-container').children('.pricing') && isTabletWidth()){
      $('.room-pricing-container').children('.pricing').css({"float": ""});
    }
  }
  disableCurrencySelectorMobile(){
    if (window.location.href.includes("rooms-rates")){
      $(".currency-toggle__mobile").addClass("currency-off");
    } else {
      $(".currency-toggle__mobile").parent().addClass("currency-off");
    }
    if($("#searchCriteriaSummary").length){
      $("#searchCriteriaSummary span .currency-toggle__mobile").parent().siblings(':first-child').removeClass("col-xs-17 text-left");
      $("#searchCriteriaSummary")[0].classList.remove("searchCriteriaSummary-withCurrency");
      $("#searchCriteriaSummary")[0].classList.remove("searchCriteriaSummary-grandparent");
    }
    if ($("#mobileCurrencyBreak").length){
      $("#mobileCurrencyBreak").hide();
    }
    if ($("h1 hotel-counter").length){
      $("h1 hotel-counter").css({"justify-content": ""});
      $("h1 hotel-counter").parent().parent().css({"display": ""});
    }
  }
  tabletOpensCurrencyModal(currencyDropDownElement){
    if (document.getElementById("currencyDropDownReferenceRR") && window.location.href.includes("rooms-rates")) {
      document.getElementById("currencyDropDownReferenceRR").getElementsByTagName("a")[0].addEventListener("click", function(){
        document.getElementById("currencyModalOpen").click();
      });
    } else if (document.getElementById("currencyDropDownReference")) {
      if(currencyRate && !currencyRate.currency_code && currencyDropDownElement){
        let chevron = document.getElementById("currencyDropDownReference").getElementsByTagName("a")[0].getElementsByTagName("span")[0];
        document.getElementById("currencyDropDownReference").getElementsByTagName("a")[0].innerText = currencyDropDownElement.innerText.trim() + " ";
        document.getElementById("currencyDropDownReference").getElementsByTagName("a")[0].appendChild(chevron);
      }
      document.getElementById("currencyDropDownReference").getElementsByTagName("a")[0].addEventListener("click", function(){
        document.getElementById("currencyModalOpen").click();
      });
    }
    if (document.getElementById("currencyDropDownReferenceUpscale")) {
      document.getElementById("currencyDropDownReferenceUpscale").getElementsByTagName("a")[0].addEventListener("click", function(){
        document.getElementById("currencyModalOpen").click();
      });
      if($("#currencyDropDownReferenceUpscale").is(":visible")){
        $("#currencyDropDownReference").hide();
      }
    }
    if($('.room-pricing-container').children('.pricing') && isTabletWidth()){
      $('.room-pricing-container').children('.pricing').css({"float": "left"});
    }
  }
  openMobileCurrencySidebar(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.documentElement.classList += 'currency-sidebar__overflow';
    document.body.scroll = "no";
    document.getElementById("currencySideBar").classList.add('currency-sidebar__open');
    if(document.getElementById("currencySideBar").classList.contains('currency-sidebar__close-slide')){
      document.getElementById("currencySideBar").classList.remove('currency-sidebar__close-slide');
    }
    document.getElementById("currencySideBar").classList.remove('currency-sidebar__close');
  }
  mobileCurrencySidebar(currencyDropDownElement){
    const currencySideBarElement = document.getElementById("currencySideBar");
    if(document.getElementById("currencySideOpen")){
      if(currencyRate && !currencyRate.currency_code && currencyDropDownElement){
        let chevron = document.getElementById("currencySideOpen").getElementsByTagName("span")[0];
        document.getElementById("currencySideOpen").innerText = currencyDropDownElement.innerText.trim() + " ";
        document.getElementById("currencySideOpen").appendChild(chevron);
      }
      document.getElementById("currencySideOpen").addEventListener("click", this.openMobileCurrencySidebar);
    }
    if(document.getElementById("currencySideOpenOverview")){
      document.getElementById("currencySideOpenOverview").addEventListener("click", this.openMobileCurrencySidebar);
    }
    document.getElementById("currencySideClose").addEventListener('click', function(){
      document.documentElement.classList.remove('currency-sidebar__overflow');
      document.body.scroll = "yes";
      currencySideBarElement.classList.add('currency-sidebar__close-slide');
      setTimeout(() => {
        currencySideBarElement.scrollTop = document.documentElement.scrollTop = 0;
        currencySideBarElement.classList.add('currency-sidebar__close');
      }, 1000);
      currencySideBarElement.classList.remove('currency-sidebar__open');
    });

    document.getElementById("currencySideBar").addEventListener("scroll", function(event){
      if (document.getElementById("currencySideBar").getBoundingClientRect().top != 0 ||
          document.getElementsByClassName("currency-sidebar__cancel")[0].getBoundingClientRect().top != 0 ||
          document.getElementsByClassName("currency-selector_vertical-spacer")[1].getBoundingClientRect().top != document.getElementsByClassName("currency-sidebar__cancel")[0].getBoundingClientRect().height){
            document.getElementById("currencySideBar").style.top = 0;
            document.getElementsByClassName("currency-sidebar__cancel")[0].style.top = 0;
            document.getElementsByClassName("currency-selector_vertical-spacer")[1].style.top = document.getElementsByClassName("currency-sidebar__cancel")[0].getBoundingClientRect().height + "px";
      }
    });
  }

  hideConverterOnModifyPage(){
    if ($_PAGE_.is('.modify-page')){
      if (this.desktopCurrencyOn){
        this.disableCurrencySelectorDesktop();
        this.desktopCurrencyOn = false;
      }
      if (this.tabletCurrencyOn){
        this.disableCurrencySelectorTablet();
        this.tabletCurrencyOn = false;
      }
      if (this.mobileCurrencyOn){
        if ($(".currency-toggle_invert").length){
          $(".currency-toggle_invert").show();
        }
        this.disableCurrencySelectorMobile();
        this.mobileCurrencyOn = false;
      }
    }
  }
}

function selectCurrency(curencyobject){
  localStorage.setItem(currencyConverterCookieName,JSON.stringify(curencyobject));
  if ($_PAGE_.is('.packages-page')) {
    let bookdata = ReservationHandler.getBookData();
    goToPackages(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.totatax, '', false, bookdata.directBillSupported);
  } else if ($_PAGE_.is('.booking-page')) {
    let bookdata = ReservationHandler.getBookData();
    goToBook(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.totatax, '', false, bookdata.directBillSupported);
  } else if($_PAGE_.is('.search-results-page-v2')){
    updateCurrencySelectorCode($('price-slider').attr('currency-code'),curencyobject);
    $('price-slider').each((i,ps)=>$(ps).attr('currency-code',$(ps).attr('currency-code')));
    $('availability-calendar').each((i,ac)=>$(ac).attr('currency-code',$(ac).attr('currency-code')));
    $('property-card-button').each((i,pcb)=>$(pcb).attr('currency-code',$(pcb).attr('currency-code')));
    $('map-listing').each((i,ml)=>$(ml).attr('unit-per-night-label',$(ml).attr('unit-per-night-label')));
    document.querySelector('.currency-selector__modal-close').click();
    closeMobileSidebar();
  } else {
    window.scroll(0, 0);
    location.reload();
  }
}

function removeCurrency(curencyobject){
  localStorage.removeItem(currencyConverterCookieName);
  if ($_PAGE_.is('.packages-page')) {
    let bookdata = ReservationHandler.getBookData();
    goToPackages(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.totatax, '', false, bookdata.directBillSupported);
  } else if ($_PAGE_.is('.booking-page')) {
    let bookdata = ReservationHandler.getBookData();
    goToBook(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.totatax, '', false, bookdata.directBillSupported);
  } else if($_PAGE_.is('.search-results-page-v2')){
    updateCurrencySelectorCode('',{});
    $('price-slider').each((i,ps)=>$(ps).attr('currency-code',$(ps).attr('currency-code')));
    $('availability-calendar').each((i,ac)=>$(ac).attr('currency-code',$(ac).attr('currency-code')));
    $('property-card-button').each((i,pcb)=>$(pcb).attr('currency-code',$(pcb).attr('currency-code')));
    $('map-listing').each((i,ml)=>$(ml).attr('unit-per-night-label',$(ml).attr('unit-per-night-label')));
    document.querySelector('.currency-selector__modal-close').click();
    closeMobileSidebar();
  } else {
    window.scroll(0, 0);
    location.reload();
  }
}

function closeMobileSidebar(){
  const currencySideBarElement = document.getElementById("currencySideBar");
  document.documentElement.classList.remove('currency-sidebar__overflow');
  document.body.scroll = "yes";
  currencySideBarElement.classList.add('currency-sidebar__close-slide');
  setTimeout(() => {
    currencySideBarElement.scrollTop = document.documentElement.scrollTop = 0;
    currencySideBarElement.classList.add('currency-sidebar__close');
  }, 1000);
  currencySideBarElement.classList.remove('currency-sidebar__open');
}

let currencyConverter = new CurrencyConverter();

export {
  currencyConverter,
};
