import {
  exists,
  getWindowHeight,
  isMobileWidth
} from '../base/dom-utils.js';

class Badging {
  constructor() {
    if (exists('.badge-component')) {
      this.showBadges();
      this.bindBadgeClick();
    }
  }

  showBadges() {
    let badges = $('.badge-component');
    if (isMobileWidth()) {
      badges.slice(3).hide();
      if (badges.length <= 1) {
        badges.addClass('center-col');
      }
    } else {
      badges.show();
      badges.removeClass('center-col');
    }
  }

  bindBadgeClick() {
    $('.badge-with-modal').on('click', (e) => {
      let badgeMdl = $('#badge-modal'),
        modalDtls = $(e.currentTarget).find('.modal-details')
          .html(),
        badgeMdlSec = badgeMdl.find('.modal-section');
      badgeMdlSec.html(modalDtls);
      this.displayBadgeModal();
    });
  }

  displayBadgeModal() {
    $('#badge-modal').modal('show');
    if (isMobileWidth()) {
      $('#badge-modal').height(getWindowHeight())
        .css({
          'top': 0,
          'position': 'fixed',
          'margin-top': 0
        });
    }
  }
}

export default new Badging();
