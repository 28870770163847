import {ReservationApi} from "../base/reservationApi";
import {getBrand, getBrandTier} from "../base/session-handler";
import { DateTime } from 'luxon';
import Analytics from '../base/wyn-analytics-module.js';

const aboutMyStayAnalytics = Analytics.getAboutMyStayAnalytics();

$('.no-reservation-found','.find-reservation-component').css('display', 'none');

$('.js-btn-lookup-reservation','.find-reservation-component').on('click', function() {

  aboutMyStayAnalytics.trackLookUp();

  const parent = $(this).parent();
  const firstName =  $('input[name="customerFirstName"]',parent).val();
  const lastName =  $('input[name="customerLastName"]',parent).val();
  const confirmation = $('input[name="confirmationNumber"]',parent).val();
  const brand = getBrand();
  const brandTier = getBrandTier();

  ReservationApi.retrieveReservation(firstName, lastName, confirmation, brand, brandTier)
    .then( (reservation) => {
        if(!$.isEmptyObject(reservation)) {
          $('.no-reservation-found','.find-reservation-component').css('display', 'none');
          //Populate Name Form
          $('input[name="customerFirstName"]').val(reservation.firstName);
          $('input[name="customerLastName"]').val(reservation.lastName);

          //Populate Billing Address Form

          // Populate Reservation Detail

          let checkInDate = DateTime.fromISO(reservation.checkInDate);
          let checkOutDate = DateTime.fromISO(reservation.checkOutDate);
          $('input[name="firstName"]').val(reservation.firstName);
          $('input[name="lastName"]').val(reservation.lastName);
          $('input[name="checkin"]').val(checkInDate.toLocaleString("MM/DD/YYYY"));
          $('input[name="checkout"]').val(checkOutDate.toLocaleString("MM/DD/YYYY"));
          $('input[name="hotelName"]').val(reservation.propertyName);
          $('input[name="hotelBrand"]').val(reservation.brandId);
          $('input[name="hotelcity"]').val(reservation.propertyCity);
          $('input[name="siteId"]').val(reservation.propertyId);

          aboutMyStayAnalytics.saveConfirmationNumber(confirmation);
          const category = $('#contactCategory option:selected:not([disabled])').text().trim();
          const subCategory = $('#subCategory option:selected:not([disabled])').text().trim();
          if (category) {
            aboutMyStayAnalytics.saveCategoryAndSubcategory(category, subCategory);
          }
          aboutMyStayAnalytics.trackAboutMyStaySubmit();
          aboutMyStayAnalytics.removeCategoryAndSubcategory();
        } else {
          $('.no-reservation-found','.find-reservation-component').css('display', 'block');
        }
      }
    )
    .catch( (error) => {
      $('.no-reservation-found','.find-reservation-component').css('display', 'block');
    });
});
