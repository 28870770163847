import {EventHandler} from './utils.js';
import {
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth
} from './dom-utils.js';

export function eagerLoadImage(el) {
  let src = getImageSourceFromData(el);
  if (src) {
    loadImageFromSrc(el, src);  
  }
}

function loadImageFromSrc(el, src, skipLoadNewImage = true) {
  if (src && el.is('img')) {
    el.attr('src', src).on('load', () => {
      EventHandler.send('img.load', el);
    });
  } else if (src) {
    if (skipLoadNewImage) {
      loadBackgroundImage(el, src);
    } else {
      let cachedImage = new Image();
      $(cachedImage).load(function() {
          loadBackgroundImage(el, src);
      });
      cachedImage.src = encodeURI(src);
    }
  }
}

function loadBackgroundImage(el, src) {
  el.css('background-image', 'url("' + encodeURI(src) + '")');
  EventHandler.send('bgImg.load', el);
}

/**
 * Check element data attribute.
 */
function getImageSourceFromData(el) {
  let src = '';
  if (isMobileWidth() && el.data('mobile-src')) {
    src = el.data('mobile-src');
  } else if (isTabletWidth() && el.data('tablet-src')) {
    src = el.data('tablet-src');
  } else if (isDesktopWidth() && el.data('desktop-src')) {
    src = el.data('desktop-src');
  } else if (el.data('info')) {
    src = getImageFromDataInfo(el);
  } else if (el.data('src') && (el.attr('src') != el.data('src'))) {
    src = el.data('src');
  }
  return src;
}

/**
 * Extracts data from element.
 */
function getData(element) {
  let data = {};
  try {
    if (typeof(element.dataset) != 'undefined' && typeof(element.dataset.info) != 'undefined') {
      Object.assign(data, defaults, JSON.parse(element.dataset.info));
    } else {
      data = element.data('info');
    }
  } catch (error) {
    console.debug(error);
    return null;
  }
  return data;
}

/**
 * Get src from an element.
 */
function getSource(element, dataInfo = null) {
  let src = null;
  let data = dataInfo != null ? dataInfo : getData(element);

  if (data) {
    if (isMobileWidth() && data.sm) {
      src = data.sm;
    } else if (isTabletWidth() && data.st) {
      src = data.st;
    } else {
      src = data.sd;
    }
  }
  return src;
}

/**
 * Obtains the image source from the data-info element.
 */
function getImageFromDataInfo(element, isPreview = false) {
  let query = '';
  let data = element.data('info');
  let src = getSource(element, data);

  if (src) {
      query = isMobileWidth() ? data.wm : isTabletWidth() ? data.wt : data.wd;
      query = buildQueryParameter(query, src.c ? src.c : null);
    return src.s + query;
  } else {
    return null;
  }
}

/**
 * Creates the query parameter using the downsize.
 */
function buildQueryParameter(width, crop) {
  if (crop) {
    return `?crop=${crop};*,*&downsize=${width}:*`;
  } else {
    return `?downsize=${width}:*`;
  }
}

/**
 * Default values. Letters explanation:
 * c = crop
 * s = source
 * w = width
 * p = preview
 * m = mobile
 * t = table
 * d = desktop
 */
const defaults = {
  wp: 20,
  wm: 720,
  wt: 1200,
  wd: 1800,
  st: null,
  sm: null,
  sd: null
};
