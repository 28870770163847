import { exists } from '../base/dom-utils.js';

class SearchReplace {
  constructor() {
    if (exists('.searchandreplace')) {
      this.handleSearch();
      this.handleReplace();
    }
  }

  renderSearchResults(resData) {
    $('#results-table').empty();
    if (resData.length === 0) {
      const noResultsTemplate = '<h4 class="no-results">No Search Records Found</h4>';
      $('#results-table').append(noResultsTemplate);
    } else {
      const searchResultsTemplate = `
      <h4 class="results-table-title">Search Results</h4>
      <div class="search-results-table">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Page Path</th>
            <th>Locale</th>
            <th>Brand</th>
            <th>Page Name</th>
          </tr>
        </thead>
        <tbody id="searchTableBody">
        </tbody>
      </table>
    </div>`;
      $('#results-table').append(searchResultsTemplate);
      resData.forEach(function(item) {
        const row =
          '<tr>' +
          '<td><input type="checkbox" class="pageCheckbox" data-pagepath="' +
          item.pagePath +
          '"></td>' +
          '<td>' +
          item.pagePath +
          '</td>' +
          '<td>' +
          item.locale +
          '</td>' +
          '<td>' +
          item.brand +
          '</td>' +
          '<td>' +
          item.pageName +
          '</td>' +
          '</tr>';
        $('#searchTableBody').append(row);
      });
      $('.results-table-title').innerHTML = 'Search Results';
    }
  }

  renderReplaceResults(resData) {
    $('#results-table').empty();
    if (resData.length === 0) {
      const noResultsTemplate = '<h4 class="no-results">No Records Found</h4>';
      $('#results-table').append(noResultsTemplate);
    } else {
      const replacedResultsTemplate = `
      <h4 class="results-table-title">Replaced Results</h4>
      <div class="replace-results-table">
      <table class="table">
        <thead>
          <tr>
            <th>Page Path</th>
            <th>Locale</th>
            <th>Brand</th>
            <th>Page Name</th>
          </tr>
        </thead>
        <tbody id="replaceTableBody">
        </tbody>
      </table>
    </div>`;
      $('#results-table').append(replacedResultsTemplate);
      resData.forEach(function(item) {
        var row =
          '<tr>' +
          '<td>' +
          item.pagePath +
          '</td>' +
          '<td>' +
          item.locale +
          '</td>' +
          '<td>' +
          item.brand +
          '</td>' +
          '<td>' +
          item.pageName +
          '</td>' +
          '</tr>';
        $('#replaceTableBody').append(row);
      });
      $('.results-table-title').innerHTML = 'Replaced Results';
    }
    $('#replaceButton').addClass('disabled');
    $('#replaceButton').prop('disabled', true);
  }

  validateSearch() {
    const rootPathValue = $('#rootPath').val();
    const searchPathValue = $('#searchPath').val();
    const rootRegex = /^\/content\/whg-ecomm-responsive\/.+/;
    const isValidRootPath = rootRegex.test(rootPathValue);
    const isValid = isValidRootPath && searchPathValue;
    $('#searchButton').toggleClass('disabled', !isValid);
    $('#searchButton').prop('disabled', !isValid);
  }

  validateReplace() {
    const searchPathValue = $('#searchPath').val();
    const replacePathValue = $('#replacePath').val();
    const atLeastOneChecked = $('.pageCheckbox:checked').length > 0;
    const isValid = searchPathValue && replacePathValue && atLeastOneChecked;
    $('#replaceButton').toggleClass('disabled', !isValid);
    $('#replaceButton').prop('disabled', !isValid);
  }

  handleSearch() {
    $('#searchButton').addClass('disabled')
      .prop('disabled', true);
    $('#rootPath, #searchPath').on('input', this.validateSearch);
    const self = this;
    $(document).on('click', '#searchButton', function() {
      $('#replacePath').val('');
      const rootPath = $('#rootPath').val();
      const searchPath = $('#searchPath').val();
      const searchPayload = JSON.stringify({
        searchVar: searchPath,
        operationType: 'search',
        searchRoot: rootPath,
      });
      const url = '/bin/search-and-replace';
      $.ajax({
        url: url,
        type: 'POST',
        data: searchPayload,
        contentType: 'application/json',
        success: function(data) {
          self.renderSearchResults(data.responseData);
          return data;
        },
      });
    });
  }

  handleReplace() {
    const self = this;
    $('#replaceButton').addClass('disabled')
      .prop('disabled', true);
    $('#searchPath, #replacePath').on('input', self.validateReplace);
    $(document).on('click', 'input.pageCheckbox', function() {
      self.validateReplace();
    });
    $(document).on('click', '#replaceButton', function() {
      const replacePath = $('#replacePath').val();
      const searchPath = $('#searchPath').val();
      const checkedPagePaths = [];
      $('.pageCheckbox:checked').each(function() {
        var pagePath = $(this).data('pagepath');
        checkedPagePaths.push(pagePath);
      });
      const replacePayload = JSON.stringify({
        searchVar: searchPath,
        operationType: 'replace',
        replaceVar: replacePath,
        pageList: checkedPagePaths,
      });

      const url = '/bin/search-and-replace';
      $.ajax({
        url: url,
        type: 'POST',
        data: replacePayload,
        contentType: 'application/json',
        success: function(data) {
          self.renderReplaceResults(data.responseData);
          return data;
        },
      });
    });
  }
}

export default new SearchReplace();
