import { $_PAGE_, _LANGUAGE_, _LOCALE_, brandMap, countryCodeList, requestUrls, HREF } from "../../base/vars";
import {
    _isNotNull, _isWeakFalse, _isWeakTrue,
    CookieHandler, enableNewRenovatedPriceTag,
    EventHandler, formatNumber, getCity, getCurrencyMapping,
    getDefaultSearchRadius, getQueryParameter,
    getQueryString, isAllInPriceRegion, replaceToken,
    RetailBannerUtils, setUNAP, uppercaseFirstLetterEachWord,
    convertToCurrency,getSelectedCurencyCode
} from "../../base/utils";
import {
    getBrand,
    getBrandTier,
    getCriteria,
    getLocationDetails,
    removePropertyAvailability, setCriteria, UserHandler
} from "../../base/session-handler";
import RateFetch from "./RateFetch";
import { handleMetaTag, handleTitleTag } from "../../base/seo";
import Analytics from "../../base/wyn-analytics-module";
import { exists, isMobileWidth, ResizeHandler, scrollToAnimated } from "../../base/dom-utils";
import SearchMap from "./SearchMap";
import SearchFilter from "./SearchFilter";
import SearchUtils from './SearchUtils';
import { BBForm } from "../booking-bar";
import AvailabilityCalendar from "../availability-calendar";
import { lazyLoadImageInViewport } from "../../base/lazy-load";
import Thinking from "../../base/thinking";
import SRPIncludeBrandsConfig from "../../base/aem-configs/srp-include-brands-config";
import SearchBrandBannerHandler from "./SearchBrandBanner";
import WRNonParticipatingBrandsConfig from "../../base/aem-configs/wr-non-participating-brands-config";

export default class Search {
    constructor() {
        this._locationDetails = null;
        this.amenityIconsMapData = {};
        this._searchCriteria = null;
        this._currentSearchRadius = null;
        this._paginationSize = 10;
        this._recordsPerPage = 100;
        this._pageNumber = 1;
        this._AuthoredCount = $('#list-view').data('fetchprops') ? $('#list-view').data('fetchprops') : ($('#list-view').data('fetchprops') === 0) ? 1 : 2;
        this._noResultsRendered = false;
        this._brandNameMapping = $.extend({}, brandMap);
        this._renderedProperties = {};
        this._dividerDivRendered = false;
        this._brandHotelsShown = false;
        this._defaultFilterApplied = false;
        this._remainingProps = '';
        this._remainingPropsData = {};
        this._allProps = '';
        this._tenProps = '';
        this._preScroll = false;
        this._stopPriceInit = false;
        this._propertyListForMap = [];
        this._globalPropertyMap = {};
        this._propertyIdListForRate = [];
        this._sortingEventBindRequired = true;
        this._propsForRateFetch = [];
        this._currentBrand = 'ALL';
        this._currentDisplayIndex = 0;
        this._maxDisplay = 50;
        this._ratesFetched = false;
        this._ratesRewards = false;
        this._allRatesFetched = false;
        this._refineRatesFetched = false;
        this._rateTypeFilterLoyalty = false;
        this._rateTypeFilterPrice = false;
        this._scrollEnabled = false;
        this._isLoadingDone = false;
        this._isDataAvailable = false;
        this._hostBrandIndex = 0;
        this._nonHostBrandIndex = 0;
        this._hostBrandSet = false;
        this._propertyRateMap = {};
        this._renderedPropertyItemCount = 0;
        this._fetchedPropertyItemCount = 0;
        this._isWYPropRateFetchingRequired = false;
        this._actualSearchResults = null;
        this._displaySearchResults = null;
        this._totalCurrDisplayedItem = 0;
        this._emeaRegion = false;
        this._isMilesLocation = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'US') ? true : false;
        this._maxRadius = getDefaultSearchRadius();
        this.searchUtilsInst = new SearchUtils();
        this._totalProperties = 0;
        this._isFirstListRender = true;
        this._searchBrandBannerHandler = new SearchBrandBannerHandler();

        EventHandler.one(EventHandler.getLocation.success, () => {
            this.init();
        });
        if ($_PAGE_.is('.search-results-page')) {
            removePropertyAvailability();
        }
        RetailBannerUtils.slideDownRetailBanner();

    }

    miscEventBindings() {
        $('#list-view').on('prop-avail', (event, response) => {
            if (!($.isEmptyObject(response))) { //To make sure this functionality executes after the first and second call complete
                this._propsForRateFetch = [];
                let searchResultsRate = new RateFetch(this, this._propsForRateFetch.slice(0), this._currentBrand, false, null);
                this.usePropertyRateForNextAction(response, searchResultsRate._propertyIds, searchResultsRate._rateFetchOnly, searchResultsRate._rateUsageFor, null);
                //If the first scroll is already registered then execute the
                if (this._preScroll) {
                    this.displayNextSetOfProperty();
                    this._isLoadingDone = true; //Setting loading done true to enable scroll
                }
            }
        });

        handleTitleTag();
        handleMetaTag();

        $(() => {
            EventHandler.on(EventHandler.criteria.updated, () => {
                $('a.hotel-url, a.hotel-image-link').each((i, el) => {
                    let href = decodeURIComponent($(el).attr('href')),
                        m;

                    if (href.match(/(.+)\?(.+)/)) { // Pre-existing link had query string, replace it
                        m = href.match(/(.+)\?(.+)/);
                        let path = m[1],
                            query = m[2],
                            newQuery = getQueryString(),
                            brandMatch = query.match(/brand_id=(\w+)?/),
                            brandId = _isNotNull(brandMatch) ? brandMatch[1] : '',
                            idMatch = query.match(/hotel_id=(\w+)?/),
                            hotel_id = _isNotNull(idMatch) ? idMatch[1] : '',
                            crossSellMatch = query.match(/orsid=(\w+)?/),
                            orsid = _isNotNull(crossSellMatch) ? crossSellMatch[1] : '';

                        if (_isNotNull(brandId)) {
                            if (newQuery === (newQuery = newQuery.replace(/(brand_id=)\w+/, '$1' + brandId))) { // Replace didn't happen
                                newQuery = 'brand_id=' + brandId + '&' + newQuery;
                            }
                        }
                        if (_isNotNull(hotel_id)) {
                            newQuery += '&hotel_id=' + hotel_id;
                        }
                        if (_isNotNull(orsid)) {
                            newQuery += '&orsid=' + orsid;
                        }

                        href = path + '?' + newQuery;

                        $(el).attr('href', href);
                    }
                });
            }).one(EventHandler.criteria.init, () => {
                if ($('body').hasClass('ie') || $('body').hasClass('ie11')) {
                    $(document).scrollTop(0);
                }
            });

            if ($('#mobileToggleView').length > 0) {
                $('#mobileToggleView .view-toggle-button').click((e) => {
                    let targetContainer = $(e.currentTarget).attr('data-id');
                    if (targetContainer === 'map-view-wrapper') {
                        $(e.currentTarget).attr('data-id', 'list-view-wrapper');
                        //swap out text values
                        let newText = $('.map-button .hidden-text').html(),
                            oldText = $('.map-button .active-text').html();
                        $('.map-button .active-text').html(newText);
                        $('.map-button .hidden-text').html(oldText);
                        let mapEle = $('#map-view');
                        $('#list-view').hide();
                        $('.spinnerview').hide();
                        $(e.currentTarget).find('.list-map-icon')
                            .removeClass('whg-core-icon-map');
                        $(e.currentTarget).find('.list-map-icon')
                            .addClass('whg-core-icon-list');
                        $('.show-brand-only').show();
                        $('#map-view').css('min-height', 450 + 'px');
                        if ($('#map-view').data('rendered')) {
                            this.SearchMap.onShowBrandOnly();
                            $('#map-view').show();
                        } else {
                            mapEle.empty();
                            this.SearchMap.loadMapViewComponent(mapEle.get(0), this.SearchMap.preparePropListForMap(), $('#rewardspricefilter').length > 0 && $('#wyndham-rewards-filter').is(':checked'));
                            $('#map-view').show();
                        }
                        Analytics.updateAnalyticsSearchToggle('Map');
                        Analytics.satelliteTracker('search_results_view');
                    } else if (targetContainer === 'list-view-wrapper') {
                        $(e.currentTarget).attr('data-id', 'map-view-wrapper');
                        if ($('#mapRateView').is(':visible')) {
                            this.SearchMap.hideRateView();
                        }
                        //swap out text values
                        let newText = $('.map-button .hidden-text').html(),
                            oldText = $('.map-button .active-text').html();
                        $('.map-button .active-text').html(newText);
                        $('.map-button .hidden-text').html(oldText);
                        $(e.currentTarget).find('.list-map-icon')
                            .addClass('whg-core-icon-map');
                        $(e.currentTarget).find('.list-map-icon')
                            .removeClass('whg-core-icon-list');
                        $('#map-view').hide();
                        $('.show-brand-only').hide();
                        $('.map-rate-wrapper').hide();
                        $('#list-view').show();
                        $('.spinnerview').show();
                        $('#map-view').css('min-height', 0 + 'px');
                        if ($('#searchNumPropReadOnly').data('total')) {
                            $('#searchNumPropReadOnly').text($('#searchNumPropReadOnly').data('total'));
                        }
                        Analytics.updateAnalyticsSearchToggle('List');
                        Analytics.satelliteTracker('search_results_view');
                    } else if (targetContainer === 'refine-result-wrapper') {
                        $('.' + targetContainer).slideToggle();
                        if ($('#mapRateView').is(':visible')) {
                            $('body').removeClass('takeover');
                        }
                        // Recalculate farthest left value for price slider's sliding value.
                        // Fix price slider position locking on mobile
                        $('.sliding-value').data('far-left', $('.sliding-value').position().left);
                    }
                    Analytics.satelliteTracker('search_results_view');
                });

                $('.filter-close-button').click((e) => {
                    $('#mobileToggleView .fitler-button').trigger('click');
                    if ($('#mapRateView').is(':visible')) {
                        $('body').addClass('takeover');
                    }
                    scrollToAnimated(0);
                });

            }
            if ($('.hotels-in-container .nav-tabs').length > 0) {
                $('.hotels-in-container .nav-tabs a').click((e) => {
                    //Need this complicated logic to toggle data attributes for mobile view buttons since they are separate elements
                    if ($('.map-button').attr('data-id') === 'list-view-wrapper' && $(e.target).attr('href') === '#map-vew') {
                        $('.map-button').attr('data-id', 'map-view-wrapper');
                        //swap out mobile toggle text when desktop tabs are clicked on
                        let newText = $('.map-button .hidden-text').html(),
                            oldText = $('.map-button .active-text').html();
                        $('.map-button .active-text').html(newText);
                        $('.map-button .hidden-text').html(oldText);
                    } else if ($('.map-button').attr('data-id') === 'map-view-wrapper' && $(e.target).attr('href') === '#list-vew') {
                        $('.map-button').attr('data-id', 'list-view-wrapper');
                        //swap out mobile toggle text when desktop tabs are clicked on
                        let newText = $('.map-button .hidden-text').html(),
                            oldText = $('.map-button .active-text').html();
                        $('.map-button .active-text').html(newText);
                        $('.map-button .hidden-text').html(oldText);
                    }
                });
            }
            // Place brand name into map view if needed
            if ($('.show-brand-only .brand-name').length > 0) {
                let currentBrandCode = getBrand(true),
                    brandTier = getBrandTier(),
                    currentBrandName = '';

                if (currentBrandCode == 'ALL' && !brandTier) {
                    $('.show-brand-only').addClass('hidden');
                } else {
                    this._brandNameMapping = $.extend({}, brandMap); // refresh the list

                    if (brandTier) {
                        currentBrandCode = brandTier.toUpperCase();
                    }

                    if (this._brandNameMapping && this._brandNameMapping[currentBrandCode]) {
                        currentBrandName = this._brandNameMapping[currentBrandCode];
                    }
                    currentBrandName = currentBrandName.replace(/-/g, ' ');
                    $('.show-brand-only .brand-name').html(currentBrandName);
                }
            }
        });

        $('.booking-bar-collapsed .dates-col').show();

    }

    mobileSortStyling() {
        $(document).on('change', '#mobileToggleView .view-toggle-button[data-id="sort-by-wrapper"]', (e) => {
            let $el = $(e.currentTarget),
                $sort = $el.siblings('.sort-icon');

            if ($el.prop('selectedIndex') === 0) {
                $sort.removeClass('sort-selected');
            } else {
                $sort.addClass('sort-selected');
            }
        });
    }

    init() {
        this.SearchMap = new SearchMap(this);
        this.SearchFilter = new SearchFilter(this, this.SearchMap, this._searchBrandBannerHandler);

        this.miscEventBindings();
        this.mobileSortStyling();

        this._currentBrand = getBrand(true);
        this._currentTier = getBrandTier();
        this._locationDetails = getLocationDetails();
        this._searchCriteria = getCriteria();

        if (exists('.show-brand-only')) {
            $('.show-brand-only input').val((this._currentTier || this._currentBrand).toUpperCase());
            $('.show-brand-only .toggle-checkbox').iosCheckbox();
        }
        if (countryCodeList && isAllInPriceRegion()) {
            this._emeaRegion = true;
        }
        let isRRAlt = getQueryParameter('rrAlt');
        if (isRRAlt && _isWeakTrue(isRRAlt)) {
            $('.redirect-message').show();
        }
        let useWRPoints = getQueryParameter('useWRPoints') || this._searchCriteria.useWRPoints;

        if (useWRPoints && (useWRPoints === true || useWRPoints === 'true')) {
            BBForm.setRedeemWRPoint(true);
            setCriteria({
                useWRPoints: true,
                rateTypeFilter: 'loyalty'
            });
            this._searchCriteria.useWRPoints = true;
            this._searchCriteria.rateTypeFilter = 'loyalty';
            $('#wyndham-rewards-filter, #sort-points-filter').prop('checked', true);
            $('.tier-filter-container').show();
            $('#chk-direct-bill').prop("checked", false);
            $('#chk-direct-bill').prop("disabled", true);
            $('#chk-direct-bill').parent().css('opacity', '0.6');
            $('.tier-filter-container input:not(.always-disable)')
                .prop('disabled', false)
                .parents('label')
                .removeClass('disabled');
        } else {
            BBForm.setRedeemWRPoint(false);
            setCriteria({
                useWRPoints: false,
                rateTypeFilter: 'price'
            });
            this._searchCriteria.useWRPoints = false;
            this._searchCriteria.rateTypeFilter = 'price';
            $('#price-filter').prop('checked', true);
            $('#chk-direct-bill').prop("disabled", false);
            $('#chk-direct-bill').parent().css('opacity', '1');
        }
        this.alignMobileSort();
        if (exists('.search-text-container h1')) {
            this.updateCityText(getLocationDetails('city'));
            EventHandler.one(EventHandler.UNAP.updated, () => {
                this.updateCityText(getCity());
            });
        }
        let brandFilter = getQueryParameter('brandFilter');
        var brandArray = [];
        if (typeof brandFilter === 'string') {
            if (brandFilter.indexOf(',') > 1) {
                brandArray = brandFilter.split(',');
                for (let brandIndex = 0; brandIndex < brandArray.length; brandIndex++) {
                    if (exists('#ourbrandsfilter input#' + brandArray[brandIndex].toLowerCase())) {
                        if ($('#ourbrandsfilter input#' + brandArray[brandIndex].toLowerCase()).is(':disabled') == false) {
                            $('#ourbrandsfilter input#' + brandArray[brandIndex].toLowerCase()).prop('checked', true);
                        }
                    }
                }
            } else {
                if (exists('#ourbrandsfilter input#' + brandFilter.toLowerCase())) {
                    if ($('#ourbrandsfilter input#' + brandFilter.toLowerCase()).is(':disabled') == false) {
                        $('#ourbrandsfilter input#' + brandFilter.toLowerCase()).prop('checked', true);
                    }
                }
            }
        }

        this.SearchFilter._defaultFilter = this.SearchFilter.getFilterValues();
        this.updateSearchValues();
        this.getSearchResults();

        EventHandler.one(EventHandler.serviceCall.end, () => {
            // This can be updated here since it's used for functionality and not analytics tracking.
            window.digitalData.search.updateSearchData = false;
            Analytics.handleSearchAnalytics();
            Analytics.updateSearchAnalyticsValues(this._locationDetails);
            Analytics.fireAnalyticsPageEvent();
        });

        //move focus off of radio buttons
        $('#price-filter').blur();
        $('#wyndham-rewards-filter').blur();
    }

    alignMobileSort() {
        var el = document.createElement('span');
        el.innerHTML = $('.mobile-toggle-view select option:first').val();
        document.body.appendChild(el);
        $('.sort-icon').css('text-indent', -el.offsetWidth - 2);
        let textIndent = ($('select.mobile-search-tabs').outerWidth() / 2) - (el.offsetWidth / 2);
        $('.iphone select.mobile-search-tabs').css('text-indent', textIndent);
        document.body.removeChild(el);
    }

    updateCityText(cityText) {
        let $h1 = $('.search-text-container h1'),
            dataText = $h1.data('text'),
            dataGeoText = $h1.data('geotext'),
            ld = getLocationDetails(),
            cityValue = (_isNotNull(ld.city) && ld.city !== '') ? ld.city : (_isNotNull(ld.state) && ld.state !== '') ? ld.state : (_isNotNull(ld.country) && ld.country !== '') ? ld.country : '',
            text = replaceToken(dataText, '${city}', cityValue) || dataText;
        text = replaceToken(text, '${location}', getCriteria().searched || getLocationDetails('location') || cityValue) || text;

        if (text !== false) {
            $h1.text(text);
        } else if (_isNotNull(dataText)) {
            $h1.text(dataText);
        }
        //update the Search Results Page header when user is searching by current location
        if ((CookieHandler.readCookie('UserGeoData') && CookieHandler.readCookie('UserGeoData').toString().length > 0)) {
            BBForm.setSearchByGeoLocation(true);
            let geoData = JSON.parse(CookieHandler.readCookie('UserGeoData'));
            if (typeof geoData === 'object' && !($.isEmptyObject(geoData)) && (dataGeoText && dataGeoText.toString().length > 0)) {
                $h1.text(dataGeoText);
            }
        }
    }

    updateSearchValues() {
        let locationDetails = this._locationDetails;
        Analytics.updateSearchAnalyticsValues(locationDetails);
    }

    /**
     * This method is used to perform following task
     * 1. set default filter
     * 2. bind filter event
     * 3. invoke multi property search service &
     * 4. SortByOption based on the "Redeem Wyndham Rewards" checkbox check
     */
    getSearchResults() {
        this.SearchFilter.enableFilter();
        this.getMultiPropertySearchByRadius(this.getMultiPropertySearchCriteria(), false);
        this.SearchFilter.changeSortByOption(this._searchCriteria);

        EventHandler.triggerEvent('srp.propertyData.getPropertyData', this.getMultiPropertySearchCriteria());
    }

    /**
     * Generate the search criteria to be sent to getMultiPropertySearch.
     */
    getMultiPropertySearchCriteria() {
        let locationDetails = this._locationDetails ? this._locationDetails : getLocationDetails();
        let searchCriteria = this._searchCriteria ? this._searchCriteria : getCriteria();
        let multiPropSC = {},
            brandTier = getBrandTier(),
            radius = searchCriteria.radius || getDefaultSearchRadius();
        if (radius < 0 || radius > getDefaultSearchRadius()) {
            this._currentSearchRadius = getDefaultSearchRadius();
        } else {
            this._currentSearchRadius = radius;
        }
        if (this.SearchFilter._defaultFilter) {
            this.SearchFilter._defaultFilter.distanceMin = radius;
        }
        if (typeof locationDetails !== 'undefined' && locationDetails !== null && !$.isEmptyObject(locationDetails) && locationDetails.latitude && locationDetails.longitude) {
            multiPropSC.latitude = locationDetails.latitude;
            multiPropSC.longitude = locationDetails.longitude;
        }

        multiPropSC.radius = radius;

        //if Point Of Interest elements have been authored, override the initial criteria with these values
        if ($('.poiattributes').length > 0) {
            let poiattributes = $('.poiattributes');
            let poiLat = poiattributes.attr('data-attribute-latitude'),
                poiLon = poiattributes.attr('data-attribute-longitude'),
                poiRadius = poiattributes.attr('data-attribute-radius'),
                poiPageInterest = poiattributes.attr('data-attribute-interest');
            if (poiLat != '') {
                multiPropSC.latitude = poiLat;
            }
            if (poiLon != '') {
                multiPropSC.longitude = poiLon;
            }
            if (poiRadius != '') {
                multiPropSC.radius = poiRadius;
            }
            if (poiPageInterest != '') {
                multiPropSC.searchByTag = poiPageInterest;
            }
        } else if ((CookieHandler.readCookie('UserGeoData') && CookieHandler.readCookie('UserGeoData').toString().length > 0)) {
            //Use geolocation lat and long when user click on the geo location icon
            BBForm.setSearchByGeoLocation(true);
            try {
                let geoData = JSON.parse(CookieHandler.readCookie('UserGeoData'));
                if (typeof geoData === 'object' && !($.isEmptyObject(geoData))) {
                    if (geoData.latitude !== '') {
                        multiPropSC.latitude = geoData.latitude;
                    }
                    if (geoData.longitude !== '') {
                        multiPropSC.longitude = geoData.longitude;
                    }
                }
            } catch (e) {
                console.log('User Geo Data not available');
            }
        }

        if (brandTier) {
            multiPropSC.brandTier = brandTier;
        }

        const srpBrandsAndTiers = SRPIncludeBrandsConfig.getSRPIncludeBrandsAndTiers();

        multiPropSC.includeBrands = srpBrandsAndTiers.brands;
        if (srpBrandsAndTiers.tiers) {
            multiPropSC.includeTiers = srpBrandsAndTiers.tiers;
        }

        multiPropSC.brandId = (!this._currentBrand.match(/^(WHG|WR)$/i)) ? this._currentBrand : 'ALL';
        multiPropSC.language = _LOCALE_;
        multiPropSC.useLimitedProperties = BBForm.isIntlSearch();
        multiPropSC.recordsPerPage = this._recordsPerPage * this._AuthoredCount;
        multiPropSC.pageNumber = this._pageNumber;
        multiPropSC.distanceUnit = (this._isMilesLocation) ? 'mile' : 'kilometer';
        multiPropSC.maxRadius = this._maxRadius;

        return multiPropSC;
    }

    /**
     * This method is used to invoke AJAX call for 'multiPropertySearchByRadius' service.
     *
     * multiPropSC - search criteria object for this service
     * distanceChanged - 'true' if distance changed due to filtering, otherwise 'false'
     */
    getMultiPropertySearchByRadius(multiPropSC, distanceChanged) {
        $.ajax({
            url: '/BWSServices/services/search/getMultiPropertySearch',
            type: 'GET',
            data: multiPropSC,
            dataType: 'json',
            success: (result, status, xhr) => {
                //Create a this._propertyRateMap
                if (result && result.status == 'OK') {
                  Analytics.updateAnalyticsSearchCount(parseFloat(result.availability.hostBrand.totalPropertyCount));

                    if (_LANGUAGE_ !== 'en' || result.inLanguageCity) {
                        setUNAP({
                            city: getCriteria().inLanguageCity || result.inLanguageCity,
                            state: getCriteria().inLanguageState || result.inLanguageState,
                            country: getCriteria().inLanguageCountry || result.inLanguageCountry
                        });

                        if (!getCriteria().inLanguageCity) {
                            setCriteria({
                                inLanguageCity: result.inLanguageCity,
                                inLanguageState: result.inLanguageState,
                                inLanguageCountry: result.inLanguageCountry
                            });
                        }
                    }
                    if (parseInt(result.radius) === this._maxRadius && this._currentSearchRadius != this._maxRadius) {
                        if ($('#distanceMin').length) {
                            $('#distanceMin').val(this._maxRadius);
                        } else {
                            $('#kmDistanceMin').val(this._maxRadius);
                        }
                    }

                    for (const property in result.availability) {
                        this._totalProperties += result.availability[property].availabilityCount;
                    }

                    this.updateResultsCountContainer();
                    result = this._searchBrandBannerHandler.removeShowBannerBrandsFromSearchResults(result);
                    this.prepareSearchResults(result, distanceChanged);
                } else {
                    //Display 'No Search Results found' section
                    this.getMultiPropertySearchError();
                    EventHandler.triggerEvent('brands-error', result);
                }
            },
            error: (result, status, error) => {
                EventHandler.triggerEvent('brands-error', result);
                this.getMultiPropertySearchError();
            }
        });
    }

    /**
     * Error handler for getMultiPropertySearch. Enable filters, set default values, show
     * the no results found view.
     */
    getMultiPropertySearchError() {
        let distanceMin = (this._isMilesLocation) ? $('#distanceMin') : $('#kmDistanceMin');
        this.SearchFilter._currentFilter = this.SearchFilter.getFilterValues();
        distanceMin.val(_isNotNull(this.SearchFilter._currentFilter) ? this.SearchFilter._currentFilter.distanceMin : getDefaultSearchRadius());
        distanceMin.parent().removeClass('hidden');
        this.SearchFilter.enableFilters();
        this._noResultsRendered = false;
        this.noResultsFound(false);
        this.SearchFilter.enableFiltersOnInit();
      Analytics.updateAnalyticsSearchCount(0);
    }

    /**
     * No results found handler. Triggered when a service throws an error or when no results
     * are found for the host brand. Displays a template indicating no results were found and updates
     * relevant analytics values.
     *
     * @param onlyNoHostBrandFound {boolean} - Boolean value indicating whether alt sell properties are available (true) or no results exist at all (false)
     */
    noResultsFound(onlyNoHostBrandFound) {
        if (!this._noResultsRendered) {
            let noResultsItems = {};

            noResultsItems.noHostBrandFound = onlyNoHostBrandFound;

            if (!onlyNoHostBrandFound || getBrand() !== 'WR') {
                var noResultsTemplate = _.template($('#noResultsFoundTemplate').html()),
                    compiledNoResultsTemplate = noResultsTemplate({
                        items: noResultsItems
                    });

                $('#list-view').append(compiledNoResultsTemplate);
                $('#list-view .brand-divider').show();
            }

            if (onlyNoHostBrandFound) {
                let currentBrandCode = (_isNotNull(getBrand(true))) ? getBrand(true).toLowerCase() : null,
                    currentBrandName = '',
                    brandTier = getBrandTier(),
                    tokenString = $('.no-results.more-results-nearby').text();

                if (brandTier) {
                    currentBrandName = brandTier.toUpperCase();
                    currentBrandCode = brandTier;
                }
                if (this._brandNameMapping && this._brandNameMapping[currentBrandCode]) {
                    currentBrandName = this._brandNameMapping[currentBrandCode];
                }

                if (currentBrandCode == "vo" && currentBrandName == "wyndham-vacations") {
                    currentBrandName = currentBrandName.slice(0, -1);
                }

                currentBrandName = currentBrandName.replace(/-/g, ' ');
                currentBrandName = uppercaseFirstLetterEachWord(currentBrandName);

                if (currentBrandName && currentBrandCode == "hj" && currentBrandName.toLowerCase() == "hojo") {
                    currentBrandName = "HoJo";
                }

                if ((tokenString.indexOf('${Brand}') > 0) || (tokenString.indexOf('${brand}') > 0)) {
                    tokenString = replaceToken(tokenString, '${brand}', currentBrandName);
                    $('.no-results.more-results-nearby').html(tokenString);
                }
            }

            // Adding Banner
            this._searchBrandBannerHandler.filterShowBannerBrands(this.SearchFilter._currentFilter);
            const bannerTemplate = this._searchBrandBannerHandler.renderBannerIfNeeded(0, 1);
            bannerTemplate && $('#list-view').append(bannerTemplate);

            this._noResultsRendered = true;
        }
    }

    /**
     * This method is used to prepare search result object for display.
     *
     * propJSONResp - JSON object returned by multiPropertySearchByRadius service
     * distanceChanged - 'true' if distance is changed via filtering, otherwise 'false'
     */
    prepareSearchResults(propJSONResp, distanceChanged) {
        this._actualSearchResults = propJSONResp;
        this._displaySearchResults = $.extend(true, {}, propJSONResp);

        if (this._sortingEventBindRequired) {
            this._sortingEventBindRequired = false;
            this.SearchFilter.bindSortingField();
        }
        this._propertyListForMap = [];
        this._propertyIdListForRate = [];
        this._globalPropertyMap = {};

        let tiersToDisable = $('.tier-filter-container input');

        //Create a global propertyMap
        let brands = propJSONResp.availability.hostBrand;
        for (let index = 0; index < brands.availabilityCount; index++) {
            let hotelObj = brands.availability[index];
            this._globalPropertyMap[hotelObj.hotelId] = hotelObj;
            this._propertyListForMap.push(hotelObj);
            this._propertyIdListForRate.push(hotelObj.hotelId);
            tiersToDisable = tiersToDisable.filter((i, el) => {
                return el.value.match(/(?=\S)[^,]+?(?=\s*(,|$))/g).indexOf(hotelObj.tierNum) < 0;
            });
            //marker goes here
        }
        brands = propJSONResp.availability.altSell;
        for (let index = 0; index < brands.availabilityCount; index++) {
            let hotelObj = brands.availability[index];
            this._globalPropertyMap[hotelObj.hotelId] = hotelObj;
            this._propertyListForMap.push(hotelObj);
            this._propertyIdListForRate.push(hotelObj.hotelId);
            tiersToDisable = tiersToDisable.filter((i, el) => {
                return el.value.match(/(?=\S)[^,]+?(?=\s*(,|$))/g).indexOf(hotelObj.tierNum) < 0;
            });
        }

        tiersToDisable.addClass('always-disable')
            .attr('disabled', true)
            .prop('checked', false)
            .parents('label')
            .addClass('disabled always-disable');

        if ($('.tier-filter-container input:checked').length === 1) {
            $('.tier-filter-container input:checked').addClass('always-disable')
                .attr('disabled', true)
                .parents('label')
                .addClass('disabled always-disable');
        }

        /************MAP creation is done*******************/
        this.SearchMap.enableMapViewList();

        this.SearchFilter.applyDefaultFilters(distanceChanged);
    }

    initializeForDisplayProperties(rateRequired) {
        //Removing all children property
        $('#emptyDiv').empty();
        $('#nonhost-brand').empty();
        $('div.alt-sell-bar').hide();
        //Removing flag to refresh the map again
        $('#map-view').data('rendered', false);
        this._scrollEnabled = false;
        this._totalPrevDisplayedItem = Number(this._totalCurrDisplayedItem);

        /*** Correct the count in _displaySearchResults object  */
        this._displaySearchResults.availability.hostBrand.availabilityCount = this._displaySearchResults.availability.hostBrand.availability.length;
        this._displaySearchResults.availability.altSell.availabilityCount = this._displaySearchResults.availability.altSell.availability.length;
        this._displaySearchResults.availability.hostBrand.totalPropertyCount = this._displaySearchResults.availability.hostBrand.availabilityCount + this._displaySearchResults.availability.altSell.availabilityCount;
        this._displaySearchResults.availability.altSell.totalPropertyCount = this._displaySearchResults.availability.hostBrand.totalPropertyCount;
        // Updating the count
        this._totalProperties = this._displaySearchResults.availability.hostBrand.totalPropertyCount;
        this.updateResultsCountContainer();
        // Resetting all
        this._totalCurrDisplayedItem = 0;
        this._isDataAvailable = true;
        this._currentDisplayIndex = 0;
        this._isNonHostBrand = false;
        this._isHostBrand = true;
        this._maxDisplay = 50;

        if (!this._isFirstListRender) {
            this.displayNextSetOfProperty();
        }
        this._isFirstListRender = false;
    }

    /**
     * This method is used to populate results count
     *
     * count - total property count from the search result.
     */
    updateResultsCountContainer() {
        //Populating results count
        $('#searchNumPropReadOnly').text(this._totalProperties);
        $('#searchCriteriaSummary').css('visibility', 'visible');
    }

    displayNextSetOfProperty() {
        if ((this._totalCurrDisplayedItem < this._displaySearchResults.availability.hostBrand.totalPropertyCount) || (this._totalCurrDisplayedItem < this._displaySearchResults.availability.altSell.totalPropertyCount)) {
            this._propsForRateFetch = [];
            this._propRateAvlbl = [];
            this._maxDisplay = this._maxDisplay + this._totalCurrDisplayedItem;

            this.iteratePropertiesToDisplay(this._totalCurrDisplayedItem, this._totalCurrDisplayedItem + this._paginationSize, this._maxDisplay);
            $('#emptyDiv').show();
        } else if (this._totalCurrDisplayedItem === 0) {
            this.SearchFilter.enableFilters();
            this.noResultsFound(false);
        }
    }

    fetchRatesForRemainingProperty(rateUsageFor, currPropList) {
        let propIdsForRate = this._propertyIdListForRate;
        let rateRequired = [],
            hotelId, propId, brand;
        for (let index = 0; index < propIdsForRate.length; index++) {
            hotelId = propIdsForRate[index];
            brand = this._globalPropertyMap[hotelId].brand.toUpperCase();
            if (!this._propertyRateMap.hasOwnProperty(hotelId)) {
                propId = brand + hotelId;
                if (brand == 'WY') {
                    propId += '|' + brand + this._globalPropertyMap[hotelId].hotelCode;
                }
                rateRequired.push(propId);
            }
        }

        if (rateRequired.length > 0) {
            // Call rate service to fetch the data
            let searchResultsRate = new RateFetch(this, rateRequired, this._currentBrand, true, rateUsageFor);
            // Fetching rate for property availability
            searchResultsRate.fetchPropAvailability(currPropList);
        } else if (this._propertyRateMap && !$.isEmptyObject(this._propertyRateMap)) {
            if (!currPropList) {
                currPropList = this._actualSearchResults;
            }
            this.SearchFilter.aggregateAllRateAndSort(rateUsageFor, this.populateRateInVault($.extend(true, {}, currPropList)));
        }
    }

    isWYPropRateFetchingRequired() {
        return this._isWYPropRateFetchingRequired;
    }

    usePropertyRateForNextAction(result, propertyIds, rateFetchOnly, rateUsageFor, currPropList) {
        //Create a propertyRateMap and update as price and loyalty rates will come in separate property-availability call
        if (result && result.status == 'OK') {
            this.updatePropertyRateMap(result.availability.hostBrand.availability);
            this.updatePropertyRateMap(result.availability.altSell.availability);
        }
        this._actualSearchResults = this.populateRateInVault(this._actualSearchResults);
        let mapEle = $('#map-view');
        if (mapEle.is(':visible')) {
            $('#list-view').hide();
            mapEle.empty();
            this.SearchMap.loadMapViewComponent(mapEle.get(0), this.SearchMap.preparePropListForMap(), $('#rewardspricefilter').length > 0 && $('#wyndham-rewards-filter').is(':checked'));
        }

        //Show switch when the prices are ready
        let isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
        if (!isProxy) {
            $('#mobileToggleView .map-button').addClass('visible-xs');
            $('.list-map-tab-wrapper').addClass('visible-sm visible-md visible-lg');
        }
        // Init price slider with min/max values
        if (exists('#price')) {
            let emeaRegion = this._emeaRegion;
            let prices = $.map(this._propertyRateMap, (item) => {
                if (item.rate.displayRate <= 0) return;
                if (emeaRegion && ($('#map-view').data('emea') && $('#map-view').data('emea').trim().length > 0) && item.rate.totalAfterTax > 0) {
                    return convertToCurrency(item.rate.totalAfterTax,item.rate.currencyCode);
                } else {
                    return convertToCurrency(item.rate.displayRate,item.rate.currencyCode);
                }
            });

            if (prices.length > 0 && !(this.SearchFilter._currentFilter.wrPoints)) {
                let minPrice = Math.min.apply(Math, prices),
                    maxPrice = Math.max.apply(Math, prices);
                this.sliderInit('#price', minPrice, maxPrice);
            }

            this.SearchFilter.enableFilters();
            if (this.SearchFilter._currentFilter.price) {
                this.SearchFilter._defaultFilter = this.SearchFilter.getFilterValues();
            }

        }

        this.SearchFilter.setAvailabilityRefineCountsAfterFilter($.extend(true, {}, this._actualSearchResults));
        this.SearchFilter.enableFiltersOnInit();
        /************MAP creation is done*******************/

        const populatedList = this.populateRateInVault((currPropList && currPropList.availability) ? currPropList : $.extend(true, {}, this._actualSearchResults));
        this.SearchFilter.aggregateAllRateAndSort('SORTING', populatedList);
        if (this.SearchFilter._currentFilter.showAvailableOnly && this._refineRatesFetched) {
            this._ratesFetched = true;  //to skip second prop-avail call
            this.SearchFilter.aggregateAllRateAndSort("REFINING", this.populateRateInVault((currPropList && currPropList.availability) ? currPropList : $.extend(true, {}, this._actualSearchResults)));
        }

        // Display results
        if (!$('#map-view').is(':visible')) {
            $('#list-view').show();
        }
        this.iteratePropertiesToDisplay(this._currentDisplayIndex, this._paginationSize, this._maxDisplay);
    }

    /**
     * Iterate properties to build request to AEM's propertyDataList.json
     */
    iteratePropertiesToDisplay(startIndex, endIndex, pageSize) {
        let brands,
            oldEndIndex;

        if (this._displaySearchResults.availability.hostBrand.availability.length > 0 && this._hostBrandIndex != this._displaySearchResults.availability.hostBrand.length) {
            brands = this._displaySearchResults.availability.hostBrand;
        } else {
            brands = this._displaySearchResults.availability.altSell;
        }

        let propertyId = '',
            brandCode;
        let hotelRequestData = {
            locale: _LOCALE_,
            hotels: []
        };
        if (this._totalCurrDisplayedItem < this._displaySearchResults.availability.hostBrand.availability.length) {
            //endIndex = startIndex + pageSize;
            if (endIndex >= brands.availabilityCount && this._displaySearchResults.availability.hostBrand.availability.length != this._hostBrandIndex) {
                oldEndIndex = endIndex;
                endIndex = brands.availabilityCount;
            }

            if (endIndex > brands.availability.length) {
                endIndex = brands.availability.length;
            }

            this._hostBrandSet = true;
            for (let index = this._hostBrandIndex; index < endIndex; index++) {
                var hotelObject = {
                    brandId: brands.availability[index].brand,
                    hotelId: brands.availability[index].hotelId,
                    location: brands.availability[index].state,
                    hotelCode: brands.availability[index].hotelCode
                };
                //Hotel code needs to be present for propertydatalist
                if (hotelObject.hotelCode === '') {
                    hotelObject.hotelCode = hotelObject.hotelId;
                }

                //Need brandTier for the divider on upscale brands
                if (brands.availability[index].brandTier) {
                    hotelObject.brandTier = brands.availability[index].brandTier;
                } else {
                    hotelObject.brandTier = '';
                }

                this._fetchedPropertyItemCount++;
                brandCode = hotelObject.brandId.toUpperCase();
                propertyId = hotelObject.brandId + hotelObject.hotelId;
                if (brandCode == 'WY' && this.isWYPropRateFetchingRequired()) {
                    propertyId += '|' + hotelObject.brandId.toUpperCase() + hotelObject.hotelCode;
                }
                hotelRequestData.hotels.push(hotelObject);

                if (this._currentTier) {
                    if (this._currentBrand.toUpperCase() == hotelObject.brandId.toUpperCase()) { // Host up scale brand
                        this._hostBrandIndex++;
                    } else { // Non-host brand or WyndhamHotelGroup
                        this._nonHostBrandIndex++;
                    }
                } else if (this._currentBrand == hotelObject.brandId.toUpperCase()) { // Host brand
                    this._hostBrandIndex++;
                } else { // Non-host brand or WyndhamHotelGroup
                    this._nonHostBrandIndex++;
                }

                this._propsForRateFetch.push(propertyId);
            }
        }
        if (this._displaySearchResults.availability.hostBrand.availability.length === 0 && getBrand() !== 'ALL') {
            this.noResultsFound(true);

            if (!this._dividerDivRendered) {
                this._brandHotelsShown = true;
            }
        }
        //get other brands
        brands = this._displaySearchResults.availability.altSell;
        //endIndex = startIndex + pageSize;
        // we need to reset the endIndex to the previous value if it was set to the host brand availability length.
        if (oldEndIndex) {
            endIndex = oldEndIndex;
        }
        //figure out the correct amount of alt sell brands to display.
        if (this._hostBrandSet) {
            endIndex = endIndex - this._hostBrandIndex;
        }

        //if endindex comes in as 0 for some reason, just set it to the total brand amount.
        if ((endIndex >= brands.availabilityCount) && (this._hostBrandSet || this._displaySearchResults.availability.hostBrand.availability < 1)) {
            endIndex = brands.availabilityCount;
        }

        for (let index = this._nonHostBrandIndex; index < endIndex; index++) {
            let hotelObject = {
                brandId: brands.availability[index].brand,
                hotelId: brands.availability[index].hotelId,
                location: brands.availability[index].state,
                hotelCode: brands.availability[index].hotelCode
            };

            //Need brandTier for the divider on upscale brands
            if (brands.availability[index].brandTier) {
                hotelObject.brandTier = brands.availability[index].brandTier;
            } else {
                hotelObject.brandTier = '';
            }
            //Hotel code needs to be present for propertydatalist
            if (hotelObject.hotelCode === '') {
                hotelObject.hotelCode = hotelObject.hotelId;
            }
            this._fetchedPropertyItemCount++;
            brandCode = hotelObject.brandId.toUpperCase();
            propertyId = hotelObject.brandId + hotelObject.hotelId;
            if (brandCode == 'WY' && this.isWYPropRateFetchingRequired()) {
                propertyId += '|' + hotelObject.brandId.toUpperCase() + hotelObject.hotelCode;
            }
            hotelRequestData.hotels.push(hotelObject);
            if (this._currentTier) {
                if (this._currentTier.toUpperCase() == hotelObject.brandTier.toUpperCase()) { // Host up scale brand
                    this._hostBrandIndex++;
                } else { // Non-host brand or WyndhamHotelGroup
                    this._nonHostBrandIndex++;
                }
            } else if (this._currentBrand == hotelObject.brandId.toUpperCase()) { // Host brand
                this._hostBrandIndex++;
            } else { // Non-host brand or WyndhamHotelGroup
                this._nonHostBrandIndex++;
            }

            this._propsForRateFetch.push(propertyId);
        }

        if (hotelRequestData.hotels.length > 0) {
            $.ajax({
                url: window.location.protocol + '//' + window.location.host + '/bin/propertyDataList.json',
                type: 'POST',
                data: JSON.stringify(hotelRequestData),
                dataType: 'json',
                contentType: 'application/json',
                success: (result, status, xhr) => {
                    if (result && result.error === false) {
                        if (hotelRequestData.hotels.length === 0) return;

                        this.buildHotelTemplates(result, hotelRequestData);
                        $_PAGE_.find('.list-view-wrapper').removeClass('loadingBar');
                    }
                    this.SearchFilter.enableFilters();
                    $_PAGE_.find('.spinnerview').removeClass('loadingBarExt');
                },
                error: (result, status, error) => {
                    EventHandler.triggerEvent('brands-error', result);
                    if (this._totalCurrDisplayedItem === 0) {
                        this.noResultsFound(false);
                    }
                    this.SearchFilter.enableFilters();
                    $_PAGE_.find('.spinnerview').removeClass('loadingBarExt');
                },
                complete: () => {
                    this.SearchFilter._busy = false;
                }
            });
        }
    } // End of iteratePropertiesToDisplay

    populateRateInVault(currentPropList) {
        currentPropList.availability.hostBrand.availability = this.assignRateInPropertyList(currentPropList.availability.hostBrand.availability);
        currentPropList.availability.altSell.availability = this.assignRateInPropertyList(currentPropList.availability.altSell.availability);
        return currentPropList;
    }

    assignRateInPropertyList(brandProps) {
        let index, hotelObj;
        for (index = 0; index < brandProps.length; index++) {
            hotelObj = this._propertyRateMap[brandProps[index].hotelId];
            if (hotelObj) {
                if (this.SearchFilter._currentFilter.wrPoints) {
                    brandProps[index].fnsAvailable = hotelObj.fnsAvailable;
                    brandProps[index].fnsPoints = hotelObj.fnsPoints;
                    brandProps[index].totalFnsPoints = hotelObj.totalFnsPoints;
                    if (brandProps[index].hasOwnProperty('rate')) {
                        brandProps[index].rate.pacRate = hotelObj.rate.pacRate;
                    } else {
                        brandProps[index].rate = hotelObj.rate;
                    }
                } else if (this.SearchFilter._currentFilter.price) {
                    if (brandProps[index].hasOwnProperty('rate')) {
                        brandProps[index].rate.alternate = hotelObj.rate.alternate;
                        brandProps[index].rate.cugRate = hotelObj.rate.cugRate;
                        brandProps[index].rate.currencyCode = hotelObj.rate.currencyCode;
                        brandProps[index].rate.displayRate = hotelObj.rate.displayRate;
                        brandProps[index].rate.errorCode = hotelObj.rate.errorCode;
                        brandProps[index].rate.message = hotelObj.rate.message;
                        brandProps[index].rate.ratePlanId = hotelObj.rate.ratePlanId;
                        brandProps[index].rate.strikethroughRate = hotelObj.rate.strikethroughRate;
                        brandProps[index].rate.strikethroughRatePlanId = hotelObj.rate.strikethroughRatePlanId;
                        brandProps[index].rate.totalAfterTax = hotelObj.rate.totalAfterTax;
                    } else {
                        brandProps[index].rate = hotelObj.rate;
                    }
                } else {
                    brandProps[index].rate = hotelObj.rate;
                    brandProps[index].fnsAvailable = hotelObj.fnsAvailable;
                    brandProps[index].fnsPoints = hotelObj.fnsPoints;
                    brandProps[index].totalFnsPoints = hotelObj.totalFnsPoints;
                }
            }
        }
        return brandProps;
    }

    updatePropertyRateMap(propList) {
        let index, hotelObj;
        for (index = 0; index < propList.length; index++) {
            hotelObj = propList[index];
            if (this._propertyRateMap[hotelObj.hotelId]) {
                if (this.SearchFilter._currentFilter.wrPoints) {
                    this._propertyRateMap[hotelObj.hotelId].fnsAvailable = hotelObj.fnsAvailable;
                    this._propertyRateMap[hotelObj.hotelId].fnsPoints = hotelObj.fnsPoints;
                    this._propertyRateMap[hotelObj.hotelId].totalFnsPoints = hotelObj.totalFnsPoints;
                    if (this._propertyRateMap[hotelObj.hotelId].hasOwnProperty('rate')) {
                        this._propertyRateMap[hotelObj.hotelId].rate.pacRate = hotelObj.rate.pacRate;
                    } else {
                        this._propertyRateMap[hotelObj.hotelId].rate = hotelObj.rate;
                    }
                } else {
                    if (this._propertyRateMap[hotelObj.hotelId].hasOwnProperty('rate')) {
                        this._propertyRateMap[hotelObj.hotelId].rate.alternate = hotelObj.rate.alternate;
                        this._propertyRateMap[hotelObj.hotelId].rate.cugRate = hotelObj.rate.cugRate;
                        this._propertyRateMap[hotelObj.hotelId].rate.currencyCode = hotelObj.rate.currencyCode;
                        this._propertyRateMap[hotelObj.hotelId].rate.displayRate = hotelObj.rate.displayRate;
                        this._propertyRateMap[hotelObj.hotelId].rate.errorCode = hotelObj.rate.errorCode;
                        this._propertyRateMap[hotelObj.hotelId].rate.message = hotelObj.rate.message;
                        this._propertyRateMap[hotelObj.hotelId].rate.ratePlanId = hotelObj.rate.ratePlanId;
                        this._propertyRateMap[hotelObj.hotelId].rate.strikethroughRate = hotelObj.rate.strikethroughRate;
                        this._propertyRateMap[hotelObj.hotelId].rate.strikethroughRatePlanId = hotelObj.rate.strikethroughRatePlanId;
                        this._propertyRateMap[hotelObj.hotelId].rate.totalAfterTax = hotelObj.rate.totalAfterTax;
                        if (!(this._propertyRateMap[hotelObj.hotelId].rate.hasOwnProperty('pacRate'))) {
                            this._propertyRateMap[hotelObj.hotelId].rate.pacRate = hotelObj.rate.pacRate;
                        }
                    } else {
                        this._propertyRateMap[hotelObj.hotelId].rate = hotelObj.rate;
                    }
                }
            } else {
                this._propertyRateMap[hotelObj.hotelId] = hotelObj;
            }
        }
    }

    sliderInit(ele, min, max) {
        let $el = $(ele),
            maxLabel = $el.data('maxlabel') || '',
            minDefaultVal = Math.floor(min) || $el.data('defaultmin'),
            maxDefaultVal = Math.ceil(max) || $el.data('defaultmax'),
            minVal = Math.floor(min) || $el.data('min'),
            maxVal = Math.ceil(max) || $el.data('max'),
            wasDisabled = $el.data('disabled') || $el.hasClass('ui-slider-disabled');

        $el.slider({
            range: 'min',
            min: minVal,
            max: maxVal,
            value: maxDefaultVal,
            create: (event, ui) => {
                $el.prepend(`
          <span class="min-slider-val">
            <span class="min-value">
              ${minDefaultVal}
              <span class="left-locked-text">${maxLabel}</span>
            </span>
            <span class="hyphen invisible">&ndash;</span>
            <span class="locked-value invisible">
              <span class="max-slider-val">${maxDefaultVal}</span> ${maxLabel}
            </span>
          </span>
        `);
                $el.append(`
          <span class="sliding-value">
            <span>
              <span class="max-slider-val">${maxDefaultVal}</span> ${maxLabel}
            </span>
          </span>`);
                $('.sliding-value', $el).data('far-left', $('.sliding-value', $el).position().left);
                $(ele + 'Max').val(maxDefaultVal);
                $(ele + 'Min').val(minDefaultVal);
                $el.find('.ui-slider-handle').attr({
                    'aria-valuemin': minVal,
                    'aria-valuemax': maxVal,
                    'aria-valuenow': maxDefaultVal,
                    'role': 'slider',
                    'aria-labelledby': 'priceRangeLabel'
                });

                if (!isMobileWidth()) {
                    this.sliderTextPosition($el, $el.find('.ui-slider-handle'), maxDefaultVal);
                }

                ResizeHandler.addResizeFn(() => {
                    // Recalculate far-left data value on resize.
                    let $slidingValue = $('.sliding-value', $el);
                    // Remove any existing styles so we use the max value that the slide can have.
                    $slidingValue.attr('style', '');
                    // Setting the new far-left value.
                    $slidingValue.data('far-left', $slidingValue.position().left);
                    // Finally recalculate the position of the text based on the new far-left value.
                    this.sliderTextPosition($el, $el.find('.ui-slider-handle'), $el.data('val'));
                });
            },
            slide: (event, ui) => {
                $('.max-slider-val', $el).html(ui.value);
                $el.data('val', ui.value);
                this._stopPriceInit = false;

                // Event fires before slider repositions; use timeout to reposition after slider moves
                setTimeout(() => {
                    this.sliderTextPosition($el, ui.handle, ui.value);
                }, 10);
            },
            change: (event, ui) => {
                let $priceMin = $('#priceMin'),
                    $priceMax = $('#priceMax');

                $('.max-slider-val', $el).html(ui.value);
                if (this._stopPriceInit) {
                    this.SearchFilter.setCurrentFilter();
                } else {
                    $(ele + 'Max').val(ui.value)
                        .trigger('change');
                }
                $(ui.handle).attr('aria-valuenow', ui.value);

                Analytics.updateSearchRefinementAnalytics('Price', $priceMin.val() + '-' + $priceMax.val());
                if (!event.hasOwnProperty('originalEvent')) {
                    return; // dont call analytics on filter search request end events
                }
                Analytics.satelliteTracker('price_slider');
            }
        });

        if ($el.data('disabled') || wasDisabled) {
            $el.slider('option', 'disabled', true);
        } else {
            $el.slider('option', 'disabled', false);
        }
    }

    sliderTextPosition($el, slider, value) {
        let sliderHandle = $(slider),
            handlePos = sliderHandle.position().left,
            $slidingValue = $('.sliding-value', $el);

        $slidingValue.css({
            right: 'auto',
            left: Math.min(handlePos - ($slidingValue.width() / 2), $slidingValue.data('far-left'))
        });

        if (value === parseInt(sliderHandle.attr('aria-valuemin'))) {
            $('.min-value').removeClass('invisible');
            $('.left-locked-text').show();
            $('.sliding-value, .locked-value, .hyphen').addClass('invisible');
        } else if ($slidingValue.position().left <= $('.locked-value').position().left) {
            $('.locked-value, .hyphen, .min-value').removeClass('invisible');
            $slidingValue.addClass('invisible');
            $('.left-locked-text').hide();
        } else {
            $('.locked-value, .hyphen').addClass('invisible');
            $('.sliding-value, .min-value').removeClass('invisible');
            $('.left-locked-text').hide();
        }
    }

    buildDividerTemplate() {
        const dividerTemplate = $('#moreResultsNearbyTemplate').html();

        this._dividerDivRendered = true;
        // divideritems.brandName = this._brandNameMapping[brandCode];
        // divideritems.currentBrand = this._currentBrand;
        // var compiledDividerTemplate = dividerTemplate({
        //   items: divideritems
        // });
        $('#list-view').append(dividerTemplate);

        if (!$('#list-view .accordion-link').hasClass('collapsed')) {
            $('#list-view .hotel-details-sec .collapse').addClass('in');
        }
        // Add id only to section that is visible for the accordion to work.
        $('#list-view .hotel-details-sec .collapse').attr('id', 'crossSellAccordion');

        // Setting up accordion events
        $('.more-results-nearby').click((e) => {
            // This has to be a base DOM event. jQuery propagation causes an infinite loop.
            if (!$(e.target).is('[data-target]') && $(e.currentTarget).find('.accordion-link').length > 0) {
                $(e.currentTarget).find('.accordion-link')[0].click();
            }
        });
        if ($('div.more-results-nearby').find('.accordion-link').hasClass('show-expand')) {
            $('div.more-results-nearby').find('.accordion-link')[0].click();
        }
        $('.more-results-nearby a:not(.accordion-link):not([data-target])').click((e) => {
            e.stopPropagation();
        });
        $('.accordion-link').keydown(function(e) {
            if (e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault();
                $(e.currentTarget).click();
            }
        });
    }

    /**
     * This used to render the Underscore template containing hotel listings
     * under search-result page.
     */
    buildHotelTemplates(hotelData, pairedRequestData) {
        let template = _.template($('#hotelTemplate').html() ? $('#hotelTemplate').html() : `<div></div>` ),
            propertyIdList = [];

            hotelData.hotels.forEach((value, index) => {
                let items = value;
                let hotelObj = pairedRequestData.hotels[index];
                let brandCode;
                let propertyId;
            //have to pull hotel ID from initial request
            brandCode = hotelObj.brandId.toUpperCase();

            //if notification exist, encode it and return in the dom as data-notification attribute
            if (items.notification) {
                items.notification = encodeURIComponent(JSON.stringify(items.notification));
            }

            if (brandCode != this._currentBrand) {
                this._brandHotelsShown = true;
            }

            //If host brand is shown, non host brands are also shown and divider is not yet rendered, render it before the rest.
            //Removed below code to support grouping of all brands
            const shouldRenderDivider = (!WRNonParticipatingBrandsConfig.isCurrentBrandParticipatingToWR() ||
                this._brandHotelsShown) && !this._dividerDivRendered;

            if (shouldRenderDivider) {
                this.buildDividerTemplate();
            }

            propertyId = hotelObj.brandId + hotelObj.hotelId;
            // Translated property names sourced from property-availability. Fall back to getMultiPropertySearch if property
            // name is not translated. Final fallback to AEM data source in case services don't return names.
            if (this._propertyRateMap && this._propertyRateMap[hotelObj.hotelId] && this._propertyRateMap[hotelObj.hotelId].hotelName) {
                items.name = this._propertyRateMap[hotelObj.hotelId].hotelName;
            } else if (this._globalPropertyMap[hotelObj.hotelId] && this._globalPropertyMap[hotelObj.hotelId].hotelName) {
                items.name = this._globalPropertyMap[hotelObj.hotelId].hotelName;
            }
            // UNAP - property address and phone should come from services.
            let mpsProp = this._globalPropertyMap[hotelObj.hotelId];
            items.address1 = mpsProp.address1;
            items.address2 = mpsProp.address2;
            if (mpsProp.address2) {
                items.fullAddress = mpsProp.address1 + ' ' + mpsProp.address2;
            } else {
                items.fullAddress = mpsProp.address1;
            }
            items.city = mpsProp.city;
            items.state = mpsProp.state;
            items.country = mpsProp.country;
            items.postalCode = mpsProp.postalCode;
            if (_LANGUAGE_ !== 'en') {
                if (mpsProp.countryCode && (mpsProp.countryCode.match(/^(cn)$/i) || mpsProp.countryCode.match(/^(tw)$/i) || mpsProp.countryCode.match(/^(hk)$/i) || mpsProp.countryCode.match(/^(mo)$/i))) {
                    if (mpsProp.phone1.indexOf('+') !== 0) {
                        items.phone = '+' + mpsProp.phone1;
                    } else {
                        items.phone = mpsProp.phone1;
                    }
                } else {
                    items.phone = mpsProp.phone1;
                }
            }
            items.hotelId = pairedRequestData.hotels[index].hotelId;

            // Enable new property attribute
            if (items.propertyAttributes && items.propertyAttributes.propertyNew) {
                let newRenovateStartDate = items.propertyAttributes.newRenovateStartDate,
                    newRenovateEndDate = items.propertyAttributes.newRenovateEndDate;
                if (newRenovateStartDate && newRenovateEndDate &&
                    enableNewRenovatedPriceTag(newRenovateStartDate, newRenovateEndDate)) {
                    items.newHotel = true;
                    items.newRenovateYear = items.propertyAttributes.newRenovateYear;
                }
            }
            if (items.propertyAttributes && items.propertyAttributes.propertyRenovated) {
                let newRenovateStartDate = items.propertyAttributes.newRenovateStartDate,
                    newRenovateEndDate = items.propertyAttributes.newRenovateEndDate;
                if (newRenovateStartDate && newRenovateEndDate &&
                    enableNewRenovatedPriceTag(newRenovateStartDate, newRenovateEndDate)) {
                    items.renovatedHotel = true;
                    items.newRenovateYear = items.propertyAttributes.newRenovateYear;
                }
            }
            if (items.propertyAttributes && items.propertyAttributes.isPriceTag) {
                let priceTagStartDate = items.propertyAttributes.priceTagStartDate,
                    priceTagEnddate = items.propertyAttributes.priceTagEnddate;
                if (priceTagStartDate && priceTagEnddate &&
                    enableNewRenovatedPriceTag(priceTagStartDate, priceTagEnddate)) {
                    items.priceTag = true;
                }
            }

            //FE brands fix for dazzler and esplendor for sprite image
            items.brand = (brandCode === 'FE') ? pairedRequestData.hotels[index].brandTier : brandCode;
            items.unavailableAuthoredText = _.contains(window.NOT_AVAILABLE_BRANDS, items.brand.toLowerCase());
            items.index = index;
            items.propertyId = propertyId;
            items.logoUrl = '/content/dam/assets/clientlibs/' + this._brandNameMapping[brandCode] + '/graphics/' + brandCode + '_logo_BMP.png';
            if ($('#rewardspricefilter').length > 0 && $('#wyndham-rewards-filter').is(':checked')) {
                items.showRewards = true;
            } else {
                items.showRewards = false;
            }

            items.overviewPath = items.overviewPath.replace('http://', 'https://');

            if (items.brand === 'LQ' && this.searchUtilsInst.isRedirectRequiredForLQ()) {
                items.overviewPath = this.searchUtilsInst.getLQURIString(items.overviewPath);
            } else if (!items.overviewPath.match(/\?/)) {
                items.overviewPath = items.overviewPath + '?' + getQueryString();
            }

            //If we're outside of the US, display the country instead of the state value.
            if ((items.country && !items.country.match(/^(us|ca)$/i)) &&
                (mpsProp.countryCode && !mpsProp.countryCode.match(/^(us|ca)$/i))) {
                items.state = items.country;
            }

            this._renderedProperties[propertyId] = items;
            let compiledTemplate = template({
                items: items
            });
            $('#list-view').append(compiledTemplate);
            $('.price-badge').click(function() {
                if (!isMobileWidth()) {
                    if (!$(this).closest('.hotel-details-wrapper').find('.price-button').hasClass('disabled')) {
                        $(this).closest('.hotel-details-wrapper').find('.price-button')[0].click();
                    }
                }
            });
            //Get amenities data and handles accordion
            let thisAnchor = $('.' + items.hotelId + '-hotel .hotel-amenities'),
                thisDatesFlexible = $('.' + items.hotelId + '-hotel .search-results_availability-calendar'),
                cbFired = false,
                availabilityCalendarInit = false,
                availabilityCalendar;

            if (!items.showRewards) {
                thisDatesFlexible.closest('.hotel-details-wrapper').addClass('view-by-rate');
            }

            //make sure click event is only bound once
            $(thisAnchor).on('click', (e) => {
                let $el = $(e.currentTarget);
                let amenContainer = $el.next('div.amenities-container');
                if (cbFired === false) {
                    this.getPropertyDetails(propertyId);
                    cbFired = true;
                }
                var isExpanded = !_isWeakTrue($el.attr('aria-expanded'));
                $(amenContainer).slideToggle()
                    .attr('aria-expanded', isExpanded);
                $el.attr('aria-expanded', isExpanded);
            });

            if (items && items.availabilityCalendar && items.availabilityCalendar.enabled && thisDatesFlexible.attr('aria-label')) {
                thisDatesFlexible.removeClass('hidden');
                $(thisDatesFlexible).on('click', (e) => {
                    let opts = {
                        minStay: items.availabilityCalendar.minStay,
                        minStayMessage: items.availabilityCalendar.minStayMessage ? items.availabilityCalendar.minStayMessage : '',
                        isSearchResults: true,
                        propertyId: hotelObj.hotelId
                    };

                    if (isMobileWidth()) {
                        availabilityCalendar = new AvailabilityCalendar();
                        availabilityCalendar.openMobile(opts);
                    } else {
                        let $el = $(e.currentTarget),
                            hotelWrapper = $el.closest('.hotel-details-wrapper'),
                            calendarContainer;
                        let isExpanded = !_isWeakTrue($el.attr('aria-expanded'));
                        $el.attr('aria-expanded', isExpanded);
                        if (availabilityCalendarInit === false) {
                            calendarContainer = $('<div class="calendar-container hidden-xs"/>');
                            calendarContainer.css('display', 'none');
                            hotelWrapper.append(calendarContainer);
                            availabilityCalendar = new AvailabilityCalendar();
                            availabilityCalendar.openInline(calendarContainer, opts);
                            availabilityCalendarInit = true;
                        } else {
                            calendarContainer = hotelWrapper.find('.calendar-container');
                        }
                        hotelWrapper.toggleClass('calendar-open');
                        calendarContainer.slideToggle().attr('aria-expanded', isExpanded);
                    }
                });
            }

            //repurposing existing brand code
            this._totalCurrDisplayedItem++;
            //Get data for rates/points and render page
            this.updateRenderedProperty(items.hotelId, items.isCrossSell, items.params);
            propertyIdList.push(propertyId);

            const bannerTemplate = DOMPurify.sanitize(this._searchBrandBannerHandler.renderBannerIfNeeded(index, hotelData.hotels.length));
            bannerTemplate && $('#list-view').append(bannerTemplate);
        });
        //load images
        lazyLoadImageInViewport('.search-body .lazy-load');
        // lazyLoadImage($('.lazy-load'));
        // Fetching rate for property availability
        this.populateMultiPropertiesRate(propertyIdList);
        // Hide pricing divs for properties with no rate returned
        $('.propSummary .pricing').filter((i, el) => {
            return $(el).find('.rate')
                .text()
                .trim() === '';
        })
            .remove();

        if (this._totalCurrDisplayedItem === 0) {
            this.noResultsFound(false);
        }
    }

    /**
     * This method is called after property-summary page is rendered to update rating/review count, distance & rates if exist.
     */
    updateRenderedProperty(propId, _isCrossSell, _hasParams) {
        let hotelObj, brandCode = '',
            uriParamString, href;
        if (!($('#property-section-updated-' + propId).text() == 'true') && this._globalPropertyMap && this._globalPropertyMap != undefined) {
            hotelObj = this._globalPropertyMap[propId],
                uriParamString = getQueryString(),
                href = $('#' + propId + '-name').find('a')
                    .attr('href'),
                brandCode = hotelObj.brand.toUpperCase();
            if (brandCode === 'WT' && this.searchUtilsInst.isRedirectRequiredForTryp()) {
                href = this.searchUtilsInst.getTrypURIString(hotelObj.hotelCode, this._globalPropertyMap);
            } else if (brandCode === 'WP' && this.searchUtilsInst.isRedirectRequiredForPH()) {
                href = this.searchUtilsInst.getPHURIString('O');
            } else if (brandCode === 'LQ' && this.searchUtilsInst.isRedirectRequiredForLQ()) {
                href = this.searchUtilsInst.getLQURIString(href);
            } else {
                if (uriParamString && uriParamString.length) {
                    uriParamString = uriParamString.replace('brand_id=' + this._currentBrand, 'brand_id=' + brandCode + '&hotel_id=' + propId);
                }

                href = ((href && href.length > 0) ? href : '');
                if ((_isWeakFalse(_isCrossSell) || _isWeakTrue(_hasParams)) && !href.match(/\?/)) {
                    href += '?' + uriParamString;
                }
            }
            $('#' + propId + '-name').find('a').attr(HREF, href);
            $('thumbnail-' + propId + ' .hotel-image-link').attr(HREF, href);
            $('.' + propId + '-distance').text(hotelObj.distance);
            if (this._actualSearchResults.distanceUnit === 'kilometer') {
                $('.' + propId + '-distance-text').text($('#' + propId + '-name .km-distance-text').text());
                $('#' + propId + '-name .distance-text').hide();
            } else {
                $('.' + propId + '-distance-text').text($('#' + propId + '-name .distance-text').text());
                $('#' + propId + '-name .km-distance-text').hide();
            }
            //Set flag for property section is already updated.
            $('#summary-' + propId).append('<span id="property-section-updated-' + propId + '" class="display-none">true</span>');
        }
        this._renderedPropertyItemCount++;
        this.checkRenderingAndResetScroll();
        // Check if rate is available for this property, then update the rate section
        if (this._propertyRateMap && this._propertyRateMap[propId]) {
            this.populateSinglePropertyRate(propId);
        } else if (brandCode === 'WY' && ('ALL|WY'.indexOf(this._currentBrand) < 0 || !this.isWYPropRateFetchingRequired())) {
            this.displayUnavailableProp(propId, brandCode);
        } else if (brandCode === 'WT' && this.searchUtilsInst.isRedirectRequiredForTryp()) {
            this.displayUnavailableProp(propId, brandCode);
        } else if (brandCode === 'WP' && this.searchUtilsInst.isRedirectRequiredForPH()) {
            this.displayUnavailableProp(propId, brandCode);
        } else if (brandCode === 'LQ' && this.searchUtilsInst.isRedirectRequiredForLQ()) {
            this.displayUnavailableProp(propId, brandCode);
        }
    }

    checkRenderingAndResetScroll() {
        if (this._fetchedPropertyItemCount == this._renderedPropertyItemCount) {
            this._fetchedPropertyItemCount = 0;
            this._renderedPropertyItemCount = 0;
        }
        if (!this._scrollEnabled) {
            this.enableScroll();
            this._scrollEnabled = true;
        }
        this._isLoadingDone = true;
    }

    /**
     * This method is used to enable the scrolling event and execute respective method to load next results
     */
    enableScroll() {
        $(window).scroll(() => {
            //Commenting the previous if condition and making it when scroll reaches last property in the list
            //if( this._isDataAvailable && this._isLoadingDone && ( ( $( window ).scrollTop() + $( window ).height() ) >= $( 'footer' ).position().top ) ) {
            //Making the datalist call on reaching the last property in the list not on footer top position
            if (this._isDataAvailable && this._isLoadingDone && (($(window).scrollTop() + $(window).height()) >= ($('.hotel-details-sec').last()
                .offset().top + (2 * $('.hotel-details-sec').last()
                    .height()))) && !$('#map-view').is(':visible')) {
                this._isLoadingDone = false;
                if (this._totalCurrDisplayedItem && ((this._totalCurrDisplayedItem < this._displaySearchResults.availability.hostBrand.totalPropertyCount) || (this._totalCurrDisplayedItem < this._displaySearchResults.availability.altSell.totalPropertyCount))) {
                    if (!$_PAGE_.find('.list-view-wrapper').hasClass('loadingBar')) {
                        this._preScroll = true;
                        $_PAGE_.find('.spinnerview').addClass('loadingBarExt');
                    }

                    this._preScroll = false;
                    this.displayNextSetOfProperty();
                }
            }
        });
    }

    populateMultiPropertiesRate(propertyIds) {
        let propId, hotelObj;
        for (let index = 0; index < propertyIds.length; index++) {
            propId = propertyIds[index];

            if (propId && propId.length == 7) {
                propId = '' + propId.substring(2);
                hotelObj = this._propertyRateMap[propId];

                this.populateRateDivSection(propId, hotelObj);
            } else if (propId && propId.indexOf('WY') >= 0) {
                propId = '' + propId.substring(2, 7);
                hotelObj = this._propertyRateMap[propId];

                this.populateRateDivSection(propId, hotelObj);
            }
        }
    }

    populateSinglePropertyRate(singlePropId) {
        if (singlePropId && singlePropId.length == 5) {
            let hotelObj = this._propertyRateMap[singlePropId];
            this.populateRateDivSection(singlePropId, hotelObj);
        }
    }

    populateRateDivSection(propId, hotObj) {
        let dollarPercentDataObj = $('#dollar-percent-data'),
            overRideText = dollarPercentDataObj.data('off-authoring'),
            dollarRatePlan = dollarPercentDataObj.data('dollar-rates');
        let displayRate;

        if (propId && $('#' + propId + '-loading').length > 0) {
            if (hotObj) {
                let hotelRateObj = hotObj.rate;
                if (hotObj.brand.toUpperCase() === 'WT' && this.searchUtilsInst.isRedirectRequiredForTryp()) {
                    $('.' + propId + '-unavailable:eq( 1 ) .otherBrand').remove();
                    let callMessage = $('#dollar-percent-data').data('tryp-call-message');
                    $('.hotel-rewards .unavailable-msg .' + propId + '-call-message').html(callMessage);
                } else {
                    $('.' + propId + '-unavailable:eq( 1 ) .wtBrand').remove();
                }
                if (this._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotelRateObj.totalAfterTax > 0)) {
                    displayRate = parseFloat(hotelRateObj.totalAfterTax);
                } else {
                    displayRate = parseFloat(hotelRateObj.displayRate);
                }
                if (hotelRateObj && displayRate >= 0) {
                    let currencySymbol = (getSelectedCurencyCode(hotelRateObj.currencyCode) == 'USD' ? '$' : '');
                    getCurrencyMapping(hotelRateObj.currencyCode);

                    if (!hotelRateObj.cugRate || UserHandler.isWRLoggedIn()) {
                        if (parseFloat(hotelRateObj.strikethroughRate) >= 0 && parseFloat(hotelRateObj.strikethroughRate) > parseFloat(displayRate)) {
                            let unroundedPercent = (1 - (parseFloat(displayRate) / parseFloat(hotelRateObj.strikethroughRate))) * 100;
                            let hotelRatePlanId = hotelRateObj.ratePlanId,
                                dollarSign;
                            let isDollar = _isNotNull(dollarRatePlan) && hotelRatePlanId.match(new RegExp(dollarRatePlan, 'i'));
                            let percentageOff = '';

                            if (isDollar) {
                                let percentOffSpan = $('.' + propId + '-hide-if-dollar');
                                $(percentOffSpan).hide();
                                dollarSign = getCurrencyMapping(hotelRateObj.currencyCode);
                                percentageOff = hotelRateObj.strikethroughRate - displayRate;
                                if (overRideText !== undefined && overRideText !== ' ') {
                                    percentageOff = dollarSign + percentageOff.toFixed(2) + ' ' + overRideText;
                                } else {
                                    percentageOff = dollarSign + percentageOff.toFixed(2);
                                }
                            } else {
                                // Handle for floating point errors; round down in all cases except if the number should be a round integer.
                                percentageOff = (unroundedPercent % 1 > 0.999) ? Math.round(unroundedPercent) : Math.floor(unroundedPercent);
                            }

                            $('.hotel-rate .' + propId + '-percentOff').text(percentageOff);
                            if ($('.' + propId + '-rooms-and-rates-button-link').length > 0) {
                                $('.' + propId + '-rooms-and-rates-button-link').click(() => {
                                  Analytics.updateSearchAlertAnalytics('Yes', 'Price', (isDollar ? percentageOff + ' off' : percentageOff + '% off'));
                                  Analytics.satelliteTracker('search_alert_type');
                                });
                            }
                            $('.hotel-rate .' + propId + '-strikedPrice').text(currencySymbol + parseFloat(hotelRateObj.strikethroughRate).toFixed(2));
                        } else {
                            $('.hotel-rate .' + propId + '-line-through').removeClass('display-inline-block')
                                .hide();
                            if ($('.' + propId + '-rooms-and-rates-button-link').length > 0) {
                                $('.' + propId + '-rooms-and-rates-button-link').click(() => {
                                  Analytics.updateSearchAlertAnalytics('No', '', '');
                                  Analytics.satelliteTracker('search_alert_type');
                                });
                            }
                        }
                    } else {
                        $('.hotel-rate .' + propId + '-line-through').removeClass('display-inline-block')
                            .hide();
                    }
                    $('.hotel-rate .' + propId + '-priceUnit').text(' ' + getSelectedCurencyCode((hotelRateObj.currencyCode ? hotelRateObj.currencyCode : '')));
                    let superScriptValue = parseFloat(convertToCurrency((hotelRateObj.cugRate && !UserHandler.isWRLoggedIn() && (hotelRateObj.strikethroughRate > 0)) ? hotelRateObj.strikethroughRate : displayRate,hotelRateObj.currencyCode)).toFixed(2),
                        decimalPosition = superScriptValue.indexOf('.'),
                        superScriptDiv = $('.' + propId + '-price').find('.' + propId + '-superscript');
                    $('.hotel-rate .' + propId + '-price').html(currencySymbol + Math.floor(superScriptValue));
                    //if the superscript container gets removed, append it back to the price container
                    if ($('.hotel-rate .' + propId + '-superscript').length === 0) {
                        $('.hotel-rate .' + propId + '-price').append(superScriptDiv[0]);
                    }
                    $('.hotel-rate .' + propId + '-superscript').html(superScriptValue.substring(decimalPosition + 1));
                    $('.hotel-rate #' + propId + '-loading').hide();
                    $('.hotel-rate .' + propId + '-available').show();
                    //Displaying WR points
                    $('p.' + propId + '-wrsection').show();
                    //Check if the rate is Alternate
                    if (hotelRateObj.alternate) {
                        $('.' + propId + '-available div.available-room-check span').text('Alternate Rate');
                    }
                    // $('#'+propId+'-unavailable').hide();
                    $('.hotel-rate .' + propId + '-unavailable').remove();
                    if (!isMobileWidth()) {
                        $('.' + propId + '-unavailable').closest('.propSummary').find('.price-badge').css('cursor', 'pointer');
                    }
                    this.assignURIInViewRoomsButton(propId, hotObj);

                    if (this.SearchFilter._isRearrangeRequired) {
                        this.SearchFilter.rearrangePropertyOrder(propId, hotObj.brand);
                    }
                    if (this._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotelRateObj.totalAfterTax > 0)) {
                        $('.' + propId + '-taxes-fees').removeClass('hidden');
                        if (isMobileWidth()) {
                            $('.' + propId + '-hotel .hotel-rate a.search-results_availability-calendar').css('display', 'inline-flex');
                        }
                    }
                } else {
                    //property is not available
                    this.displayUnavailableProp(propId, hotObj.brand);
                }

                if ((hotObj.fnsAvailable && hotelRateObj && (this._ratesRewards || displayRate >= 0)) || _.contains(window.NOT_AVAILABLE_BRANDS, hotObj.brand.toLowerCase())) {
                    let goFastCash;
                    let currencySymbol = getCurrencyMapping(hotelRateObj.currencyCode);
                    //Property is available
                    $('.' + propId + '-go-free').show();
                    $('.' + propId + '-go-free-points').text(formatNumber(hotObj.fnsPoints));
                    $('.hotel-rewards .' + propId + '-unavailable').remove();
                    if (!isMobileWidth()) {
                        $('.' + propId + '-unavailable').closest('.propSummary').find('.price-badge').css('cursor', 'pointer');
                    }
                    this.assignURIInViewRoomsButton(propId, hotObj);
                    if (hotObj.rate.pacRate && hotObj.rate.pacRate.pacRatePlan) {
                        if (this._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotObj.rate.pacRate.pacRateAfterTax && hotObj.rate.pacRate.pacRateAfterTax > 0)) {
                            goFastCash = parseFloat(hotObj.rate.pacRate.pacRateAfterTax);
                        } else {
                            goFastCash = parseFloat(hotObj.rate.pacRate.pacRate);
                        }
                        $('.' + propId + '-go-fast, .' + propId + '-or').show();
                        // $( '.' + propId + '-go-fast .or-text').show();
                        let goFastPoints = hotObj.rate.pacRate.pacPoints;
                        $('.hotel-rewards .' + propId + '-priceUnit').text(' ' + (hotelRateObj.currencyCode ? hotelRateObj.currencyCode : ''));
                        let superScriptValue = parseFloat(goFastCash).toFixed(2),
                            decimalPosition = superScriptValue.indexOf('.'),
                            superScriptDiv = $('.hotel-rewards .' + propId + '-price').find('.' + propId + '-superscript');
                        $('.hotel-rewards .' + propId + '-price').html(currencySymbol + Math.floor(goFastCash));
                        //if the superscript container gets removed, append it back to the price container
                        if ($('.hotel-rewards .' + propId + '-superscript').length === 0) {
                            $('.hotel-rewards .' + propId + '-price').append(superScriptDiv[0]);
                        }
                        $('.hotel-rewards .' + propId + '-superscript').html(superScriptValue.substring(decimalPosition + 1));
                        $('.hotel-rewards .' + propId + '-points').html(formatNumber(goFastPoints) + ' ');
                        if (this._emeaRegion && ($('.' + propId + '-taxes-fees').text().trim().length > 0) && (hotObj.rate.pacRate.pacRateAfterTax && hotObj.rate.pacRate.pacRateAfterTax > 0)) {
                            $('.' + propId + '-taxes-fees').removeClass('hidden');
                            if (isMobileWidth()) {
                                $('.' + propId + '-hotel .hotel-rate a.search-results_availability-calendar').css('display', 'inline-flex');
                            }
                        }
                    }
                    if (this.SearchFilter._isRearrangeRequired) {
                        this.SearchFilter.rearrangePropertyOrder(propId, hotObj.brand);
                    }
                }

                //Set flag for rate section is populated.
                $('.hotel-rate #summary-' + propId + ' .average-rate').append('<span id="rate-section-updated-' + propId + '" class="display-none">true</span>');
            } else {
                this.displayUnavailableProp(propId, this.searchUtilsInst.getPropertyFromGlobalMap(this._globalPropertyMap, propId).brand);
            }
        } else {
            this._rateUpdateComplete = false;
        }
    }

    /**
     * This method is used assign the Rooms-&-Rates link to the "VIEW ROMS" & "BOOK IT" button link.
     *
     * propId - property id
     */
    assignURIInViewRoomsButton(propId, hotelObj) {
        let href = $('#' + propId + '-name a').attr('href');

        let brand;
        if (typeof hotelObj === 'string') {
            brand = hotelObj;
        } else {
            brand = hotelObj.brand;
        }

        if (brand.toUpperCase() === 'WT' && this.searchUtilsInst.isRedirectRequiredForTryp()) {
            // href = this.searchUtilsInst.getTrypURIString( propId );
        } else if (brand.toUpperCase() === 'WP' && this.searchUtilsInst.isRedirectRequiredForPH()) {
            // href = this.searchUtilsInst.getPHURIString( "R" );
        } else if (brand.toUpperCase() === 'LQ' && this.searchUtilsInst.isRedirectRequiredForLQ()) {
            href = this.searchUtilsInst.getLQURIString(href);
        } else {
            if (href && href.length > 0) {
                let str = (typeof hotelObj === 'object' && !(_isWeakTrue(hotelObj.isCrossSell))) ? 'overview' : 'hotel-overview';

                href = href.replace(str, 'rooms-rates');
            }
        }
        //hotel obj returns the wrong object
        if (typeof hotelObj === 'object' && _isWeakTrue(hotelObj.isCrossSell) && _isNotNull(hotelObj.orsId)) {
            href += '&orsid=' + hotelObj.orsId;
            if (href.match(/\?.*hotel_id=/)) {
                href = decodeURIComponent(href).replace(/(hotel_id=)\w+?(\&{0,1})/, '$1' + hotelObj.orsId + '$2');
            } else {
                href += '&hotel_id=' + hotelObj.orsId;
            }
        }
        if (!href.match(/\?/)) {
            href += '?' + getQueryString();
        }
        $('.' + propId + '-rooms-and-rates-button-link').attr(HREF, href);
    }

    displayUnavailableProp(propId, brand) {
        $('#' + propId + '-loading').hide();
        $('#' + propId + '-loading').closest('.hotel-details-wrapper')
            .addClass('hotel-unavailable');

        if ((brand.toUpperCase() === 'WT' && this.searchUtilsInst.isRedirectRequiredForTryp()) ||
            (brand.toUpperCase() === 'WP' && this.searchUtilsInst.isRedirectRequiredForPH()) ||
            (brand.toUpperCase() === 'LQ' && this.searchUtilsInst.isRedirectRequiredForLQ()) ||
            (_.contains(window.NOT_AVAILABLE_BRANDS, brand.toLowerCase()))) {
            $('.' + propId + '-available').show();
            $('.' + propId + '-available').children(':not(div), div.available-room-check')
                .removeClass('display-inline-block')
                .hide();
            this.assignURIInViewRoomsButton(propId, brand.toUpperCase());
        } else {
            $('.' + propId + '-available').remove();
            $('.' + propId + '-unavailable').show();
            $('.' + propId + '-compare-bookit').hide(); // Hiding "BOOK IT" button from compare modal, and adjusting accordingly.
            $('.' + propId + '-compare-bookit').parent()
                .hide();
            $('.' + propId + '-compare-bookit').parent()
                .siblings('span')
                .removeClass('pull-right')
                .addClass('text-align-center');
            $(document).on('click', `#${propId}-unavailable a`, (e) => {
                e.preventDefault();
                Thinking.addIgnore('ServiceRequest');
            });
        }
    }

    // ------- AMENITY FETCHING -------
    getPropertyDetails(propId) {
        $.ajax({
            url: '/BWSServices/services/search/property/search',
            type: 'GET',
            data: {
                id: propId,
                channelId: 'tab',
                isAmenitiesNeeded: true,
                language: _LOCALE_
            },
            dataType: 'json',
            success: (result) => {
                if (result && result.status == 'OK') {
                    this.prepareAmenitySection(propId, result);
                }
            },
            error: (result, status, error) => {
                EventHandler.triggerEvent('brands-error', result);
            }
        });
    }

    fetchAmenityIconsMapData() {
        let amenityIconsMap = {},
            deferred = $.Deferred();

        if (requestUrls['amenityIconsMap']) {
            $.ajax({
                url: requestUrls['amenityIconsMap']
            }).then((response) => {
                if (response.list && response.list.hasOwnProperty('item')) {
                    amenityIconsMap = this.parseAmenityIconsMapList(response.list);
                    if (Object.keys(amenityIconsMap).length) {
                        deferred.resolve(amenityIconsMap);
                    } else {
                        deferred.reject();
                    }
                } else {
                    deferred.reject();
                }
            }, () => {
                deferred.reject();
            });
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    appendAmenitiesList(propId, amenityDetails) {
        let singleProp = amenityDetails.properties[0],
            amenities,
            singlePropFullId = singleProp.brand + singleProp.propertyId,
            template = _.template($('#amenitiesTemplate').html()),
            items = {};
        items.amenities = [];
        //PropertyId on the variable is only returning the hotel ID. Had to manually add on the brand ID to get it to match the Prop ID
        if (singleProp && singlePropFullId == propId) {
            amenities = singleProp.amenitiesDetails;
            if (amenities && amenities.length > 0) {
                let amenityName,
                    amenityServices,
                    amenityIcon,
                    amntN;
                for (let index = 0; index < amenities.length; index++) {
                    amenityName = amenities[index].groupDetails[0].amenityName;
                    if (amenityName && amenityName.length > 0) {
                        let itemData = {};
                        amenityServices = amenityName.split('#');
                        amntN = amenityServices[0];
                        amenityIcon = $.trim(amenities[index].groupDetails[0].amenityNameEN).toLowerCase().replace(/\s+/g, '_');
                        amenityIcon = this.amenityIconsMapData[amenityIcon];
                        amenityName = amntN.replace(/Information/gi, 'Info');
                        itemData.name = amenityName;
                        itemData.icon = amenityIcon;
                        items.amenities.push(itemData);
                    }
                }
                if (items.amenities.length > 6) {
                    //force array to six, since they only want six amenities for now
                    items.amenities.length = 6;
                }
                //Compile and render amenities template
                let compiledTemplate = DOMPurify.sanitize(template({
                    items: items
                }));
                $('#' + propId + '-amenities').html(DOMPurify.sanitize(compiledTemplate));
            } else {
                $('#' + propId + '-amenities').hide();
            }
        } else {
            $('#' + propId + '-amenities').hide();
        }
    }

    parseAmenityIconsMapList(amenityIconsMapJcr) {
        let amenityIconsMap = {};

        for (const property in amenityIconsMapJcr) {
            if (property.indexOf('item') >= 0) {
                let amenityIconObj = amenityIconsMapJcr[property];
                if (_isNotNull(amenityIconObj['jcr:title']) &&
                    _isNotNull(amenityIconObj['value'])) {
                    let amenityNameProcessed = this.getAmenityNameProcessed(amenityIconObj['jcr:title']);
                    amenityIconsMap[amenityNameProcessed] = amenityIconObj['value'];
                }
            }
        }

        return Object.keys(amenityIconsMap).length > 0 ? amenityIconsMap : false;
    }

    getAmenityNameProcessed(amenityNameEn) {
        return $.trim(amenityNameEn).toLowerCase().replace(/\s+/g, '_');
    }

    prepareAmenitySection(propId, propertySearch) {
        if (Object.keys(this.amenityIconsMapData).length === 0) {
            let instance = this;
            this.fetchAmenityIconsMapData().then((amenitiesMap) => {
                instance.amenityIconsMapData = amenitiesMap;
                instance.appendAmenitiesList(propId, propertySearch);
            }, () => {
                instance.appendAmenitiesList(propId, propertySearch);
            });
        } else {
            this.appendAmenitiesList(propId, propertySearch);
        }
    }

    // Passthrough function for map to work with filter
    isRearrangeRequired() {
        return this.SearchFilter._isRearrangeRequired;
    }

    rearrangePropertyOrder(propId, brand) {
        return this.SearchFilter.rearrangePropertyOrder(propId, brand);
    }
}
