import ConfigsUtils from './config-utils';
const BRANDS_KEY = 'srp-include-brands';
const TIERS_KEY = 'srp-include-tiers';

/**
 * Returns an object containing the tiers and brands to be included in the
 * getMultiPropertySearch call depending on the SRP-Include-Brands AEM Config.
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 * @returns {Object}
 */
const getSRPIncludeBrandsAndTiers = (channels, locales, brands) => {
    const config = ConfigsUtils.findConfig(BRANDS_KEY, channels, locales, brands);

    let includeBrandsAndTiers = {
        tiers: '',
        brands: '',
    };

    if(config) {
        let brandsAndTiers = ConfigsUtils.getBrandsAndTiersFromConfig(config, BRANDS_KEY, TIERS_KEY);
        includeBrandsAndTiers.tiers = brandsAndTiers.tiers.join(',');
        includeBrandsAndTiers.brands = brandsAndTiers.bwsBrands.join(',');
    }

    return includeBrandsAndTiers;
};

export default {
    getSRPIncludeBrandsAndTiers
};
