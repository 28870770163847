import { exists, scrollToAnimated, ResizeHandler, isMobileWidth } from '../base/dom-utils.js';
import { callService, formatNumber, EventHandler, getLocaleUrlToken } from '../base/utils.js';
import { getBrand, getBrandTier, setBrand, deletePropAvailSessionData, deleteSearchOverviewSessionData, deleteRoomsRatesSessionData } from '../base/session-handler.js';
import Analytics from '../base/wyn-analytics-module.js';
import BrandsFilterConfig from '../base/aem-configs/brands-filter-config';
import AmenitiesFilterConfig from '../base/aem-configs/amenities-filter-config';

class DynamicLocation {
  constructor() {
    if (exists('.locations-list-view-component')) {
      this.scrollHandlerFunction = this.scrollHandler.bind(this);
      this.windowScrollFunction = this.windowScroll.bind(this);
      // Set brand early on locations page
      setBrand();
      // Check for server-side rendering
      this.isSSR = window.isSSR;
      this.noAmensBrandsClicked = true;
      this.currentResults = {
        totalPropCount: 0,
        currentPropCount: 0,
        statesRendered: {},
        citiesRendered: {}
      };
      this.preserveSelections = false;
      // Pending Params = any changes you've made in the modal but haven't applied yet
      this.pendingParams = {
        brandIdList: [],
        brandTierList: [],
        amenityIdList: [],
        propertyTierIdList: [],
        filterItemList: {
          brandList: [],
          region: null,
          country: null,
          state: null,
          city: null,
          amenityList: [],
          propertyTierList: []
        },
        currentRegion: 0,
        isRegionSelected: false,
        countryCode: [],
        stateCode: null,
        city: null
      };
      // Current Params = Currently applied filters
      this.currentParams = {
        brandIdList: [],
        brandTierList: [],
        amenityIdList: [],
        propertyTierIdList: [],
        filterItemList: {
          brandList: [],
          region: null,
          country: null,
          state: null,
          city: null,
          amenityList: [],
          propertyTierList: []
        },
        currentRegion: 0,
        isRegionSelected: false,
        countryCode: [],
        stateCode: null,
        city: null
      };
      // search Params = Currently applied filter, converted to query string format to be sent to the service
      this.searchParams = {
        recordsPerPage: 50,
        pageNumber: 1,
        brandId: getBrand(true),
        tierId: this.supplyTier()
      };
      this.destinations = window.AEMdestinations || [];
      this.isNewRegion = true;

      // toggle brands filter options based on brands-filter config
      this.configuredBrandsAndTiers = BrandsFilterConfig.getBrandsAndTiers();
      this.hideNotApplicableBrands();

      // toggle amenities filter options based on amenities-filter config
      this.configuredAmenitiesForCurrentBrand = AmenitiesFilterConfig.getAmenities();
      this.hideNotApplicableAmenities();

      //object of elements utilized on page
      this.el = {
        locationList: $('#renderedList'),
        listComponent: $('.locations-list-view-component'),
        listMarker: $('#offsetListMarker'),
        hotelCount: $('#hotelCount'),
        modalHotelCount: $('#modal-hotel-count'),
        countryHeader: $('#countryHeader'),
        regionHeader: $('#regionHeader'),
        stateHeader: $('#stateHeader'),
        stateContainer: $('#stateContainerTemplate'),
        propertyList: $('#propertyList'),
        ddRegion: $('#dest-region'),
        ddCountry: $('#dest-country'),
        ddState: $('#dest-state'),
        ddCity: $('#dest-city'),
        filterModal: $('#locationsFilterModal'),
        brandsAmenitiesTiersBtns: $('#brands-cb :checkbox, #amenities-cb :checkbox, #tiers-cb :checkbox'),
        applyFilter: $('#apply-filter'),
        locationBanner: $('.locations-banner-component'),
        ssrContent: $('.aem-rendered-content'),
        selectedFilterList: $('#selectedFilterList'),
        selectedFilterItems: $('#selectedFilterItems'),
        selectedFilterItemsInject: $('#selectedFilterItemsInject'),
        landingMsg: $('#landingMsg'),
        noResultsMsg: $('#noResultsMsg'),
        updateFilters: $('.locations-banner-component [data-target="#locationsFilterModal"]'),
        oldRegionVal: $('#oldRegionVal'),
        oldCountryVal: $('#oldCountryVal'),
        oldStateVal: $('#oldStateVal'),
        oldCityVal: $('#oldCityVal'),
        filterToggle: $('.filter-toggle-button-group button'),
        filterPanel: $('.locations-filter-panel .panel-collapse'),
        topBadges: $('.filter-toggle-button-group .badge'),
        destPanel: $('.destinations-panel:first'),
        brandsPanel: $('.brands-panel'),
        mobileFilter: $('.mobile-selected-filter-list-toggle')
      };

      this.resetMobileSelectedFilterToggle();
      ResizeHandler.addResizeEndFn(() => this.resetMobileSelectedFilterToggle());

      //Display landing message of visiting from homepage
      this.landingMsgCheck();

      // Scroll to top button
      this.watchPageScroll();
      this.handleScrollTopClick();

      // scroll to top to reset position avoiding position on footer
      this.scrollToTopListener();

      //Check to see if cities need to be loaded based on search parameters passed
      this.renderGETSearchCheck();

      // Bind DOM events and filtering logic
      this.setFilterBindings();

      if (!this.isSSR) {
        this.isFirstLoad = true;
        this.currentParams.currentRegion = 0;
        this.getLocations();
      } else {
        this.el.mobileFilter.parent().removeClass('hidden');
      }
      deletePropAvailSessionData();
      deleteSearchOverviewSessionData();
      deleteRoomsRatesSessionData();
    }
  }
  supplyTier() {
    return (getBrandTier()) ? getBrandTier().toUpperCase() : undefined;
  }
  getFilterItemListLength() {
    let length = 0;
    if(this.currentParams.filterItemList.region !== null) length++;
    if(this.currentParams.filterItemList.country !== null) length++;
    if(this.currentParams.filterItemList.state !== null) length++;
    if(this.currentParams.filterItemList.city !== null) length++;
    length += this.currentParams.filterItemList.brandList.length;
    length += this.currentParams.filterItemList.amenityList.length;
    length += this.currentParams.filterItemList.propertyTierList.length;
    return length;
  }
  resetMobileSelectedFilterToggle() {
    if (!isMobileWidth()) {
      $('#selectedFilterList').collapse('show');
    } else {
      $('#selectedFilterList').collapse('hide');
    }
  }

  windowScroll() {
    let scrollTop = $(window).scrollTop(),
     elementOffset = this.el.listMarker.offset().top,
     distanceActual = (elementOffset - scrollTop),
     distancePercentage = distanceActual / window.innerHeight,
     maxScroll = scrollTop + window.innerHeight + 60,
     listTop = this.el.listComponent.offset().top;
    //if the distance of the 'marker' element is less than 90% from the top of the window, load in more results
    if (distancePercentage < .9 && scrollTop > this.lastScrollTop) {
      this.getLocations();
    }

    // Show/hide scroll to top buton
    if (scrollTop >= listTop && elementOffset >= maxScroll) {
      $('.scroll-top-container').fadeIn();
    } else {
      $('.scroll-top-container').fadeOut();
    }
    this.lastScrollTop = scrollTop;
  }

  //bind the window scroll event
  bindScroll() {
    //load all subsequent sets on scroll
    $(window).scroll(this.windowScrollFunction);
  }
  //re-engage the scroll functionality after a slight delay so we don't lose our place
  delayBindScroll() {
    setTimeout(() => {
      this.bindScroll();
    }, 500);
  }
  bindRegionOptions(bypassForGETSearch, localeIdx) {
    if (bypassForGETSearch) {
      this.isRegionSelected = true;
      this.getCountriesForDropdown(bypassForGETSearch, localeIdx);
    }
    this.el.ddRegion.on('change', () => {
      this.preserveSelections = false;
      // Empty out country, state, city dropdowns
      this.resetDropdown(this.el.ddCountry, false, 'countryCode');
      this.resetDropdown(this.el.ddState, true, 'stateCode');
      this.resetDropdown(this.el.ddCity, true, 'city');

      // Get values from selected element
      let currSelected = this.el.ddRegion.find('option:selected').val();
      let currSelectedRegion = this.el.ddRegion.find('option:selected').text();

      //Update active filters
      if (currSelected != '') {
        this.removeAndUpdateActiveFilters(currSelectedRegion, 'oldRegionVal', 'region');

        this.pendingParams.isRegionSelected = true;
        this.pendingParams.currentRegion = currSelected;

        // Get list of countries for region
        let currSelectedCountries = window.AEMdestinations[currSelected].countries;
        this.getCountriesForDropdown(currSelectedCountries);
      } else {
        this.pendingParams.isRegionSelected = false;
        this.pendingParams.currentRegion = 0;
      }
    });
  }
  getCountriesForRegion(region) {
    let newCntryArr = [];
    region.forEach((cntry) => {
      newCntryArr.push(cntry.countryCode);
    });
    return newCntryArr.join(',');
  }
  getCountriesForDropdown(countries, localeIdx) {
    this.el.ddRegion.unbind('change');
    this.el.ddCountry.unbind('change');

    this.el.ddCountry.removeAttr('disabled');
    countries.forEach((itm, idx) => {
      if(this.el.ddCountry.find(`option[value=${idx}]`).length === 0) {
        this.el.ddCountry.append(`<option value="${idx}">${itm.countryName}</option>`);
      }
    });
    if (!localeIdx) {
      // Bind country dropdown selection logic
      this.bindCountryOptions(countries);
    } else {
      this.bindCountryOptions(countries, localeIdx);
    }
  }
  bindCountryOptions(countries, localeIdx) {
    if (countries && localeIdx) {
      this.getStatesForDropdown(countries, localeIdx.country, countries[localeIdx.country].countryCode, true, false);
    }
    if (this.countryFromParams >= 0) {
      this.el.ddCountry.find('option[value="' + this.countryFromParams + '"]').prop('selected', true);
      this.countryFromParams = undefined;
    }
    this.el.ddCountry.on('change', () => {
      this.preserveSelections = false;
      // Empty state and city dropdowns
      this.resetDropdown(this.el.ddState, true, 'stateCode');
      this.resetDropdown(this.el.ddCity, true, 'city');

      // Get values from selected element
      let currSelected = this.el.ddCountry.find('option:selected').val();
      let currSelectedCountry = this.el.ddCountry.find('option:selected').text();
      let currSelectedCountryCode = countries[parseInt(currSelected)].countryCode;

      //Update active filters
      this.removeAndUpdateActiveFilters(currSelectedCountry, 'oldCountryVal', 'country');
      this.getStatesForDropdown(countries, currSelected, currSelectedCountryCode, false, true);
    });
    this.bindRegionOptions();
  }
  getStatesForDropdown(countries, currSelected, currSelectedCountryCode, forceCitiesRender, bindUpwards) {
    this.el.ddRegion.unbind('change');
    this.el.ddCountry.unbind('change');
    this.el.ddState.unbind('change');

    // Update Pending Params
    this.pendingParams.countryCode = currSelectedCountryCode;
    // If country has states, enable state dropdown
    if (countries[currSelected] && countries[currSelected].states) {
      this.el.ddState.removeAttr('disabled');
      countries[currSelected].states.forEach((itm) => {
        this.el.ddState.append(`<option value="${itm.stateCode}">${itm.stateName}</option>`);
      });
      this.bindStateOptions(false, countries, forceCitiesRender && this.stateFromParams, bindUpwards);
    } else {
      // Country has no states. Append cities instead.
      this.pendingParams.stateCode = undefined;
      this.callServiceConfig((res) => {
        if (res.countries) {
          this.bindStateOptions(res.countries[0].states[0], countries, false, true);
        } else {
          this.bindCountryOptions(countries);
        }
      }, {pageNumber: 1, countryCode: currSelectedCountryCode, recordsPerPage: 1000});
      this.removeAndUpdateActiveFilters(false, 'oldStateVal', 'state');
    }
  }
  bindStateOptions(override, preserveCntry, forceCitiesRender, bindUpwards) {
    if (this.stateFromParams) {
      let currGETState = this.el.ddState.find('option[value="' + this.stateFromParams + '"]');
      currGETState.prop('selected', true);
      this.stateFromParams = undefined;
    }

    // Override is available if no state values exist. Render all cities for the country.
    if (override && override.cities) {
      this.el.ddCity.removeAttr('disabled');
      override.cities.forEach((itm) => {
        this.el.ddCity.append(`<option value="${itm.cityName}">${itm.cityName}</option>`);
      });
    } else {
      if (forceCitiesRender) {
        this.getCitiesForDropdown(this.searchParams.stateCode);
      }
      // States exist. Wait for selection before populating cities.
      this.el.ddState.on('change', () => {
        this.preserveSelections = false;
        // Get values from selected item.
        let currSelected = this.el.ddState.find('option:selected').val();
        this.getCitiesForDropdown(currSelected, preserveCntry);
      });
    }

    if(bindUpwards) {
      this.bindCountryOptions(preserveCntry);
    }
  }
  getCitiesForDropdown(currSelected, preserveCntry) {
    this.el.ddRegion.unbind('change');
    this.el.ddCountry.unbind('change');
    this.el.ddState.unbind('change');
    // Empty city dropdown.
    this.resetDropdown(this.el.ddCity, true, 'city');

    this.pendingParams.stateCode = currSelected;

    this.searchParams.pageNumber = 1;

    //Update active filters
    this.removeAndUpdateActiveFilters(this.el.ddState.find('option:selected').text(), 'oldStateVal', 'state');

    this.callServiceConfig((res) => {
      if (parseInt(res.countriesCount) > 0) {
        res.countries[0].states[0].cities.forEach((itm) => {
          this.el.ddCity.append(`<option value="${itm.cityName}">${itm.cityName}</option>`);
        });
        this.el.ddCity.removeAttr('disabled');
        if (this.cityFromParams) {
          this.el.ddCity.find('option[value="' + this.cityFromParams + '"]').prop('selected', true);
          this.pendingParams.city = this.cityFromParams;
          this.cityFromParams = undefined;
        }
      }

      EventHandler.send('city-options-loaded');
    }, {
      brandId: '',
      tierId: '',
      amenityId: '',
      propertyTier: '',
      countryCode: this.pendingParams.countryCode,
      stateCode: this.pendingParams.stateCode,
      recordsPerPage: 1000
    });
    this.bindStateOptions(false, preserveCntry, false, true);
  }
  bindCityOptions() {
    this.el.ddCity.on('change', (el) => {
      this.preserveSelections = false;
      let currSelected = this.el.ddCity.find('option:selected').val();
      this.pendingParams.city = $(el.currentTarget).val();
      //Update active filters
      this.removeAndUpdateActiveFilters(currSelected, 'oldCityVal', 'city');
    });
  }
  resetDropdown(dropdown, disable, param, useCurrentParams) {
    if (disable) {
      dropdown.attr('disabled', true);
    }
    if (param) {
      this.searchParams[param] = undefined;
      if(useCurrentParams) {
        this.currentParams[param] = undefined;
      } else {
        this.pendingParams[param] = undefined;
      }
    }
    if (dropdown.attr('id') != 'dest-region') {
      dropdown.find('option:not(:disabled)').remove();
    }

    dropdown.prop('selectedIndex',0);
  }
  emptyFilterItems() {
    this.currentParams.filterItemList.region = null;
    this.currentParams.filterItemList.country = null;
    this.currentParams.filterItemList.state = null;
    this.currentParams.filterItemList.city = null;
    this.currentParams.filterItemList.brandList = [];
    this.currentParams.filterItemList.amenityList = [];
    this.currentParams.filterItemList.propertyTierList = [];
  }
  clearAllFilters() {
    $('.modal .collapse').collapse('hide');
    this.el.mobileFilter.parent().addClass('hidden');
    this.emptyFilterItems();
    this.el.selectedFilterItemsInject.html('');
    this.clearSelectedBrandsAmenitiesTiers();
    this.clearSelectedDestinations();
    this.bindRegionOptions();
    this.currentParams.isRegionSelected = false;
    this.currentParams.currentRegion = 0;
    this.searchParams = {
      brandId: 'ALL',
      recordsPerPage: 50
    };
    this.el.topBadges.hide();

    this.isFirstLoad = true;
    this.getLocations();
  }
  bindFilterReset(filterReset) {
    filterReset.on('click', (evt) => {
      evt.preventDefault();
      this.clearAllFilters();
    });
  }
  bindBrandsAmenitiesTiers() {
    this.el.brandsAmenitiesTiersBtns.on('change', (el) => {
      this.preserveSelections = false;

      let filterItemValue = this.getNameFromCheckbox(el.currentTarget);

      // Add checked filters to Pending Params
      if ($(el.currentTarget).prop('checked')) {
        if ($(el.currentTarget).hasClass('amen')) {
          this.pendingParams.amenityIdList.push(el.currentTarget.value);
          this.pendingParams.filterItemList.amenityList.push(filterItemValue);
        } else if($(el.currentTarget).hasClass('br')) {
          this.addBrandToList(el.currentTarget.value, this.pendingParams);
          this.pendingParams.filterItemList.brandList.push(filterItemValue);
        } else {
          this.pendingParams.propertyTierIdList.push(el.currentTarget.value);
          this.pendingParams.filterItemList.propertyTierList.push({
            name: filterItemValue,
            value: $(el.currentTarget).val()
          });
        }

      // Remove UNchecked filters from Pending Params
      } else {
        let filterElToRemove = $(el.currentTarget).next('.custom-checkbox').text().trim();
        if ($(el.currentTarget).hasClass('amen')) {
          this.pendingParams.amenityIdList = this.removeFromActiveFilterList(this.pendingParams.amenityIdList, el.currentTarget.value);
        } else if($(el.currentTarget).hasClass('br')) {
          if(window.brandTierMap[el.currentTarget.value]) {
            this.pendingParams.brandTierList = this.removeFromActiveFilterList(this.pendingParams.brandTierList, el.currentTarget.value.toUpperCase());
            this.pendingParams.brandIdList = this.removeFromActiveFilterList(this.pendingParams.brandIdList, window.brandTierMap[el.currentTarget.value].toUpperCase());
          } else {
            this.pendingParams.brandIdList = this.removeFromActiveFilterList(this.pendingParams.brandIdList, el.currentTarget.value.toUpperCase());
          }
        } else {
          this.pendingParams.propertyTierIdList = this.removeFromActiveFilterList(this.pendingParams.propertyTierIdList, el.currentTarget.value);
          filterElToRemove = el.currentTarget.value;
        }
        this.pendingParams.filterItemList = this.removeFromActiveFilterList(this.pendingParams.filterItemList, filterElToRemove);
      }
    });
  }

  // Add brand to current Params or pending Params list
  addBrandToList(brandId, paramsObj) {
    if(window.brandTierMap[brandId]) {
      paramsObj.brandTierList.push(brandId.toUpperCase());
      brandId = window.brandTierMap[brandId];
    }
    paramsObj.brandIdList.push(brandId.toUpperCase());
  }
  clearSelectedBrandsAmenitiesTiers() {
    this.el.brandsAmenitiesTiersBtns.prop('checked', false);
    this.currentParams.brandIdList = [];
    this.currentParams.brandTierList = [];
    this.currentParams.amenityIdList = [];
    this.currentParams.propertyTierIdList = [];
    this.searchParams.brandId = undefined;
    this.searchParams.tierId = undefined;
    this.searchParams.amenityId = undefined;
    this.searchParams.propertyTier = undefined;
    this.setFilterCounts();
  }
  clearSelectedDestinations() {
    this.resetDropdown(this.el.ddRegion);
    this.resetDropdown(this.el.ddCountry, true, 'countryCode', true);
    this.resetDropdown(this.el.ddState, true, 'stateCode', true);
    this.resetDropdown(this.el.ddCity, true, 'city', true);
    this.currentParams.currentRegion = 0;
    this.currentParams.isRegionSelected = false;
    this.setFilterCounts();
  }
  // If you close the modal without pressing apply
  clearAbandonedFilterChanges() {
    if (!this.preserveSelections) {
      // Drop all filter changes in pendingParams if apply not pressed
      this.resetPendingParamstoCurrentParams();

      // Amenities, Brands and Tiers
      this.el.filterModal.find('.checkbox input').each((i, el) => {
        if(this.currentParams.amenityIdList.indexOf(el.value) === -1 &&
          this.currentParams.brandIdList.indexOf(el.value.toUpperCase()) === -1 &&
          this.currentParams.brandTierList.indexOf(el.value.toUpperCase()) === -1 &&
          this.currentParams.propertyTierIdList.indexOf(el.value) === -1) {
          $(el).prop('checked', false);
        } else {
          $(el).prop('checked', true);
        }
      });
      // Destinations - Region
      this.el.oldRegionVal.val(this.currentParams.filterItemList.region);
      let regionSelected = this.el.ddRegion.find('option').filter((i,opt) => {
        return $(opt).text() === this.currentParams.filterItemList.region;
      })[0];
      if(regionSelected) {
        this.el.ddRegion.val(regionSelected.value);
        this.resetDropdown(this.el.ddCountry);
        this.getCountriesForDropdown(window.AEMdestinations[regionSelected.value].countries);
      } else {
        this.el.ddRegion.val('');
        this.resetDropdown(this.el.ddCountry, true);
        this.resetDropdown(this.el.ddState, true);
        this.resetDropdown(this.el.ddCity, true);
      }

      // Destinations - Country
      this.el.oldCountryVal.val(this.currentParams.filterItemList.country);
      let countrySelected = this.el.ddCountry.find('option').filter((i,opt) => {
        return $(opt).text() === this.currentParams.filterItemList.country;
      })[0];
      if(countrySelected) {
        this.el.ddCountry.val(countrySelected.value);
        this.resetDropdown(this.el.ddState);
        let countryList = window.AEMdestinations[this.el.ddRegion.val()].countries;
        this.getStatesForDropdown(countryList, countrySelected.value, countryList[countrySelected.value].countryCode, false, true);
      } else {
        this.el.ddCountry.val('');
        this.resetDropdown(this.el.ddState, true);
        this.resetDropdown(this.el.ddCity, true);
      }

      // Destinations - State
      this.el.oldStateVal.val(this.currentParams.filterItemList.state);
      let stateSelected = this.el.ddState.find('option').filter((i,opt) => {
        return $(opt).text() === this.currentParams.filterItemList.state;
      })[0];
      if(stateSelected) {
        this.el.ddState.val(stateSelected.value);
        this.resetDropdown(this.el.ddCity);
        this.getCitiesForDropdown(stateSelected.value, window.AEMdestinations[this.el.ddRegion.val()].countries);
      } else {
        this.el.ddState.val('');
        this.resetDropdown(this.el.ddCity, true);
      }

      // Destinations - City
      this.el.oldCityVal.val(this.currentParams.filterItemList.city);
      if(this.el.ddCity.find('option').length > 1) {
        // Cities are loaded already
        this.resetCityOption();
      } else {
        EventHandler.one('city-options-loaded', () => {
          this.resetCityOption();
        });
      }

      this.setFilterCounts();
    }
  }
  resetCityOption() {
    let citySelected = this.el.ddCity.find('option').filter((i,opt) => {
      return $(opt).text() === this.currentParams.filterItemList.city;
    })[0];
    if(citySelected) {
      this.el.ddCity.val(citySelected.value);
    } else {
      this.el.ddCity.val('');
    }
    this.setFilterCounts();
  }
  // Bind Individual Filter Remove (remove using close icon in Selected filter list)
  bindIndFilterRemove() {
    $('.close-icon').on('click', (el) => {
      let currFilterVal = $(el.currentTarget).prev('.option-name').text().trim(),
        cbValue;
      if($(el.currentTarget).prev('.option-name').attr('data-value')) {
        // New tier remove
        currFilterVal = $(el.currentTarget).prev('.option-name').attr('data-value');
        let tierToReset = $(`input[value="${currFilterVal}"]`);
        tierToReset.prop('checked', false);
        cbValue = currFilterVal;
      } else {
        let brandAmenityToReset = $('.custom-checkbox:contains("' + currFilterVal + '")');
        brandAmenityToReset.prev('input').prop('checked', false);
        cbValue = brandAmenityToReset.prev('input').val();
      }
      this.currentParams.filterItemList = this.removeFromActiveFilterList(this.currentParams.filterItemList, currFilterVal);
      this.removeFromSearchParams(cbValue, currFilterVal);
      let firstddToReset = this.el.destPanel.find('select:contains(' + currFilterVal + ')');
      firstddToReset.val('');
      let otherddReset = firstddToReset.parents('.select-dropdown').nextAll().find('select');
      otherddReset.each((i,el) => this.resetDropdown($(el), true));
      if (this.getFilterItemListLength() == 0) {
        $('#filterReset').remove();
        this.searchParams.brandId = 'ALL';
      }
      if(firstddToReset.is('#dest-region')) {
        this.bindRegionOptions();
      }
      this.isFirstLoad = true;
      if(!this.currentParams.isRegionSelected) {
        this.currentParams.currentRegion = 0;
      }
      this.getLocations();
      this.updateActiveFilters();
    });
  }
  stripDoubleCommas(list) {
    return list.replace(',,', ',');
  }
  removeFromSearchParams(brAmTrVal, destVal) {
    if(destVal == this.el.ddRegion.find('option:selected').text()) {
      this.currentParams.currentRegion = 0;
      this.currentParams.isRegionSelected = false;
      this.currentParams.stateCode = undefined;
      this.currentParams.city = undefined;
    }
    if(destVal == this.el.ddCountry.find('option:selected').text()) {
      this.currentParams.stateCode = undefined;
      this.currentParams.city = undefined;
    }
    if(destVal == this.el.ddState.find('option:selected').text()) {
      this.currentParams.stateCode = undefined;
      this.currentParams.city = undefined;
    }
    if (destVal == this.el.ddCity.find('option:selected').text()) {
      this.currentParams.city = undefined;
    }
    if (this.searchParams.amenityId && brAmTrVal) {
      this.currentParams.amenityIdList = this.removeFromActiveFilterList(this.currentParams.amenityIdList, brAmTrVal);
    }
    if (this.searchParams.propertyTier && brAmTrVal) {
      this.currentParams.propertyTierIdList = this.removeFromActiveFilterList(this.currentParams.propertyTierIdList, brAmTrVal);
    }
    if (this.searchParams.brandId && brAmTrVal) {
      if (window.brandTierMap[brAmTrVal]) {
        this.currentParams.brandIdList = this.removeFromActiveFilterList(this.currentParams.brandIdList, window.brandTierMap[brAmTrVal].toUpperCase());
        this.currentParams.brandTierList = this.removeFromActiveFilterList(this.currentParams.brandTierList, brAmTrVal.toUpperCase());
      } else {
        this.currentParams.brandIdList = this.removeFromActiveFilterList(this.currentParams.brandIdList, brAmTrVal.toUpperCase());
      }
    }

    this.applyCurrentParamsToSearchParams();
  }
  removeFromActiveFilterList(arr, toRemove) {
    if(Array.isArray(arr)) {
      // Removing from brand or amenity list
      let valRemoved = false,
        result = arr.filter((value) => {
          if(!valRemoved && (value.value == toRemove || value == toRemove)) {
            valRemoved = true;
            return false;
          }
          return true;
        });
      return result;
    } else {
      return this.removeFilterFromFilterList(arr, toRemove);
    }
  }
  removeFilterFromFilterList(arr, toRemove) {
    if(arr.region === toRemove) {
      arr.region = arr.country = arr.state = arr.city = null;
    } else if(arr.country === toRemove) {
      arr.country = arr.state = arr.city = null;
    } else if(arr.state === toRemove) {
      arr.state = arr.city = null;
    } else if(arr.city === toRemove) {
      arr.city = null;
    } else if(arr.brandList.indexOf(toRemove) >= 0) {
      arr.brandList = this.removeFromActiveFilterList(arr.brandList, toRemove);
    } else if(arr.amenityList.indexOf(toRemove) >= 0) {
      arr.amenityList = this.removeFromActiveFilterList(arr.amenityList, toRemove);
    } else if(arr.propertyTierList.filter((val) => val.value === toRemove).length > 0) {
      arr.propertyTierList = this.removeFromActiveFilterList(arr.propertyTierList, toRemove);
    }

    return arr;
  }
  removeAndUpdateActiveFilters(currItm, oldval, filterType) {
    this.pendingParams.filterItemList = this.removeFromActiveFilterList(this.pendingParams.filterItemList, this.el[oldval].val());
    if (currItm && !currItm.match(/Select/)) {
      this.pendingParams.filterItemList[filterType] = currItm;
      this.el[oldval].val(currItm);
    }
  }
  sortFilterList(obj) {
    let sortedFilterList = [];
    if(obj.region) sortedFilterList.push(obj.region);
    if(obj.country) sortedFilterList.push(obj.country);
    if(obj.state) sortedFilterList.push(obj.state);
    if(obj.city) sortedFilterList.push(obj.city);
    sortedFilterList = sortedFilterList.concat(obj.brandList.sort());
    sortedFilterList = sortedFilterList.concat(obj.amenityList.sort());
    sortedFilterList = sortedFilterList.concat(obj.propertyTierList.sort());

    return sortedFilterList;
  }
  updateActiveFilters(callback) {
    this.el.selectedFilterItemsInject.html('');
    let filtersToShow = this.sortFilterList(this.currentParams.filterItemList);
    _.each(filtersToShow, (filterItem) => {
      let itemList = _.template(this.el.selectedFilterItems.html());
      let renderItemList = itemList({filterItem: filterItem});
      this.el.selectedFilterItemsInject.append(renderItemList);
    });
    if (filtersToShow.length > 0) {
      this.el.selectedFilterItemsInject.append('<li><button class="btn-link" id="filterReset">Reset filters</button></li>');
      this.bindFilterReset($('#filterReset'));
    }
    this.bindIndFilterRemove();
    this.setFilterCounts();
    if (callback) {
      callback();
    }
  }
  bindFilterButtons() {
    this.el.filterToggle.click((e) => {
      let panelName = $(e.currentTarget).data('panel'),
        targetPanel = $(`.${panelName} .panel-collapse`);

      // Collapse hide animation has some delay and causes that the targetPanel hides before showing it.
      // Reset classes manually to hide other collapsible panels.
      this.el.filterPanel.removeClass('in collapsing').addClass('collapse');
      this.el.filterPanel.parents('.locations-filter-panel').find('.panel-title a').attr('aria-expanded', 'false');

      targetPanel.collapse('show');
    });

    this.el.updateFilters.click((e) => {
      this.el.filterPanel.collapse('hide');
    });
  }
  setFilterCounts() {
    let numDests = this.el.destPanel.find('select').filter((i, el) => {
      return el.value && el.value != '';
    }).length;
    if(numDests > 0) {
      $('.badge.dest .badge-indicator').text(numDests);
      $('.badge.dest').show();
    } else {
      $('.badge.dest').hide();
    }

    let numBrands = $('.brands-panel input[type=checkbox]:checked').length;
    if(numBrands > 0) {
      $('.badge.brand .badge-indicator').text(numBrands);
      $('.badge.brand').show();
    } else {
      $('.badge.brand').hide();
    }

    let numAmenities = $('.amenities-panel input[type=checkbox]:checked').length;
    if(numAmenities > 0) {
      $('.badge.amenity .badge-indicator').text(numAmenities);
      $('.badge.amenity').show();
    } else {
      $('.badge.amenity').hide();
    }

    let numTiers = $('.tiers-panel input[type=checkbox]:checked').length;
    if(numTiers > 0) {
      $('.badge.tier .badge-indicator').text(numTiers);
      $('.badge.tier').show();
    } else {
      $('.badge.tier').hide();
    }
  }
  bindFilterCountsAndEnableFilterBtn() {
    this.el.brandsAmenitiesTiersBtns.on('change', () => {
      this.setFilterCounts();
      this.toggleFilterBtnEnable(true);
    });
    $(document).on('change', '.locations-filter-modal select', (e) => {
      this.setFilterCounts();
      this.toggleFilterBtnEnable(true);
    });
  }
  toggleFilterBtnEnable(override) {
    if (override) {
      this.el.applyFilter.attr('disabled', false);
    } else {
      this.el.applyFilter.attr('disabled', true);
    }
  }

  // When you open the modal and when you close the modal
  resetPendingParamstoCurrentParams() {
    this.pendingParams = JSON.parse(JSON.stringify(this.currentParams));
  }

  // When Apply Filters button pressed and Current Params are set to new Pending Params & Search
  applyPendingParamstoCurrentParams() {
    this.currentParams = JSON.parse(JSON.stringify(this.pendingParams));
  }

  applyCurrentParamsToSearchParams() {
    this.searchParams.amenityId = this.currentParams.amenityIdList.join(',');
    this.searchParams.brandId = this.currentParams.brandIdList.join(',').toUpperCase() || 'ALL';
    this.searchParams.tierId = this.currentParams.brandTierList.join(',').toUpperCase();
    this.searchParams.propertyTier = this.currentParams.propertyTierIdList.join(',');
    this.searchParams.countryCode = this.currentParams.countryCode;
    this.searchParams.stateCode = this.currentParams.stateCode;
    this.searchParams.city = this.currentParams.city;
  }

  bindFilterApplyBtn() {
    this.el.applyFilter.on('click', (evt) => {
      evt.preventDefault();
      this.applyPendingParamstoCurrentParams();
      this.applyCurrentParamsToSearchParams();
      this.el.mobileFilter.parent().removeClass('hidden');
      this.hideMsg(this.el.landingMsg);
      this.el.filterModal.modal('hide');
      this.updateActiveFilters();
      this.isFirstLoad = true;
      if(!this.currentParams.isRegionSelected) {
        this.currentParams.currentRegion = 0;
      }
      this.landingMsgShown = false;
      this.getLocations();
      this.preserveSelections = true;
      this.trackAnalytics();
    });
  }
  checkModalClose() {
    this.el.filterModal.on('shown.bs.modal', () => {
      // Reset on opening the modal
      this.resetPendingParamstoCurrentParams();
      this.toggleFilterBtnEnable(false);

      $('[data-target="#locationsFilterModal"]').one('focus', (e) => $(e.currentTarget).blur());
    });
    // When modal is closed (event is fired when modal is closed w/ Apply button as well)
    this.el.filterModal.on('hidden.bs.modal', () => {
      this.clearAbandonedFilterChanges();
    });
  }
  bindModalCollapses() {
    if(!isMobileWidth()) {
      $('.locations-filter-panel').on('hide.bs.collapse', (e) => {
        $(e.currentTarget).parents('.modal-overflow')
          .removeClass('modal-overflow');
      });
    }
  }
  showMsg(elMsg) {
    elMsg.closest(this.el.locationBanner).removeClass('hidden');
    elMsg.siblings().addClass('hidden');
    elMsg.removeClass('hidden');
  }
  hideMsg(elMsg) {
    elMsg.closest(this.el.locationBanner).addClass('hidden');
    elMsg.addClass('hidden');
  }
  landingMsgCheck() {
    if (location.search.match('noResultFound=true')) {
      this.showMsg(this.el.landingMsg);
      this.isLandingMsgShown = true;
    }
  }
  //Match any incoming GET params, for use in a new search
  matchSearchableParams() {
    return location.search.match(/stateCode|countryCode|city|amenityId|amenities|brandId|propertyTier/);
  }
  getGETSearchRegion(cc) {
    let result = {};
    window.AEMdestinations.forEach((rgn, rIdx) => {
      rgn.countries.forEach((cntry, cIdx) => {
        if (cntry.countryCode == cc) {
          result.region = parseInt(rIdx);
          result.country = parseInt(cIdx);
        }
      });
    });
    return result;
  }
  getCountryCode(currRegion, currCountry) {
    return window.AEMdestinations[parseInt(currRegion)].countries[parseInt(currCountry)].countryCode;
  }
  getStateCode(currRegion, currCountry, currStateName, currStateCode) {
    let stateToReturn;
    window.AEMdestinations[parseInt(currRegion)].countries[parseInt(currCountry)].states.forEach((itm) => {

      if (currStateName && itm.stateName == currStateName) {
        stateToReturn =  itm.stateCode;
      }
      if (currStateCode && itm.stateCode == currStateCode) {
        stateToReturn =  itm.stateName;
      }

    });
    return stateToReturn;
  }
  convertQueryStringToCurrentParams(suppParams) {
    let brandIds;
    let propertyTierIds;
    for (var val in suppParams) {
      if (!val.match(/bypassCache/)) {

        switch(val) {
        case 'amenityId':
          this.currentParams.amenityIdList = suppParams[val].split(',');
          break;
        case 'brandId':
          brandIds = suppParams[val].split(',');
          for(let i = 0; i < brandIds.length; i++) {
            let idOrTier = brandIds[i],
              mappedId = window.brandTierMap[idOrTier.toLowerCase()];
            if(mappedId) {
              this.currentParams.brandIdList.push(mappedId.toUpperCase());
              this.currentParams.brandTierList.push(idOrTier);
            } else {
              this.currentParams.brandIdList.push(idOrTier);
            }
          }
          break;
          case 'propertyTier':
            propertyTierIds = suppParams[val].split(',');
            for(let i = 0; i < propertyTierIds.length; i++) {
              let propertyTierId = propertyTierIds[i].replace(/:/g,", ");
              this.currentParams.propertyTierIdList.push(propertyTierId);
            }
          break;
        default:
          this.currentParams[val] = suppParams[val];
          break;
        }
      }
    }
    this.applyCurrentParamsToSearchParams();
  }
  // Get query params
  renderGETSearchCheck() {
    $('#SSActiveBrand').remove();
    let suppParams = this.translateGETParamsToObject();

    if (!$.isEmptyObject(suppParams)) {
      this.resetDropdown(this.el.ddState);
      this.el.mobileFilter.parent().removeClass('hidden');
      this.enableAllDrops = true;

      this.convertQueryStringToCurrentParams(suppParams);

      let getlocaleIdx = this.getGETSearchRegion(suppParams.countryCode);
      this.countryFromParams = getlocaleIdx.country;
      this.stateFromParams = suppParams.stateCode;
      this.cityFromParams = suppParams.city;
      if(suppParams.countryCode) {
        this.bindRegionOptions(window.AEMdestinations[getlocaleIdx.region].countries, getlocaleIdx);
      }
      this.loadGETSearchInFilterModal(getlocaleIdx.region, getlocaleIdx.country, suppParams.stateCode, suppParams.city);

      this.setFilterCounts();
    } else {
      this.populateSelectsAndRadios();
    }
  }
  translateGETParamsToObject() {
    if(this.matchSearchableParams()) {
      return location.search.split('?')[1].split('&').reduce((params, param) => {
        var paramSplit = param.split('=').map((value) => {
          return decodeURIComponent(value.replace(/\+/g, ' '));
        });
        if(!params[paramSplit[0]]) {
          params[paramSplit[0]] = paramSplit[1];
        }
        return params;
      }, {});
    }
  }
  loadGETSearchInFilterModal(region, country, state, city) {
    let getSearchOptions = this.translateGETParamsToObject();
    this.populateSelectsAndRadios(getSearchOptions, region, country, state, city);
  }
  populateSelectsAndRadios(passedVal, region, country, state, city) {
    let brandValue = this.el.brandsPanel.find('input:checked');
    let localVals = window.AEMdestinations[region];
    if (region >= 0) {
      this.el.ddRegion.find('option[value="' + region  + '"]').prop('selected', true);
      this.currentParams.filterItemList.region = this.el.ddRegion.find('option[value="' + region  + '"]').text();
      this.currentParams.isRegionSelected = true;
      this.currentParams.currentRegion = region;
    }
    if (country >= 0) {
      this.el.ddCountry.removeAttr('disabled');
      this.currentParams.filterItemList.country = localVals.countries[country].countryName;
      this.currentParams.countryCode = localVals.countries[country].countryCode;
    }
    if (state) {
      this.el.ddState.removeAttr('disabled');
      this.currentParams.filterItemList.state = this.getStateCode(region, country, false, state);
      this.currentParams.stateCode = state;
    }
    if (city) {
      this.el.ddCity.removeAttr('disabled');
      this.currentParams.filterItemList.city = city;
      this.currentParams.city = city;
    }
    if (passedVal) {
      for (var key in passedVal) {
        if(key !== 'countryCode' && key !== 'stateCode' && key !== 'city') {
          this.el.brandsAmenitiesTiersBtns.each((i, el) => {
            let newVal = passedVal[key].split(',');
            newVal.forEach((itm) => {
              if ($(el).val().toUpperCase() == itm || (key === 'amenityId' &&  $(el).val() == itm) || (key === 'propertyTier' && $(el).val() == itm.replace(/:/g, ", "))) {
                $(el).prop('checked', true);
                if($(el).hasClass('amen')) {
                  this.currentParams.filterItemList.amenityList.push(this.getNameFromCheckbox(el));
                } else if($(el).hasClass('br')) {
                  this.currentParams.filterItemList.brandList.push(this.getNameFromCheckbox(el));
                } else if($(el).hasClass('tier')) {
                  let filterItemValue = this.getNameFromCheckbox(el);
                  this.currentParams.filterItemList.propertyTierList.push({
                    name: filterItemValue,
                    value: $(el).val()
                  });
                }
             }
            });
          });
        }
      }
    }
    if (getBrand() !== 'ALL' && getBrand() !== 'WR' && brandValue.val()) {
      this.currentParams.filterItemList.brandList.push(this.getNameFromCheckbox(brandValue));
      this.addBrandToList(brandValue.val(), this.currentParams);
      this.searchParams.brandId = this.currentParams.brandIdList.join(',').toUpperCase();
      this.searchParams.tierId = this.currentParams.brandTierList.join(',').toUpperCase();
    } else {
      if(!passedVal || !passedVal.brandId) {
        this.searchParams.brandId = 'ALL';
      }
    }
    this.updateActiveFilters();
  }
  getNameFromCheckbox(el) {
    let filterItemValue = $(el).next('.custom-checkbox').text().trim();

    // Format Tier multi label text for Selected List
    if ($(el).next('.custom-checkbox').hasClass('multi-label')) {
      let checkboxWrapper = $(el).parent(),
        line1 = checkboxWrapper.find('.first-field').html().trim(),
        line2 = checkboxWrapper.find('.second-field').html().trim(),
        divider = '';
      if(line1 && line2) {
        divider = ' / ';
      }
      filterItemValue = `${line1}${divider}${line2}`;
    }

    return filterItemValue;
  }
  setFilterBindings() {
    this.bindFilterButtons();
    this.bindRegionOptions();
    this.bindCityOptions();
    this.bindBrandsAmenitiesTiers();
    this.setFilterCounts();
    this.bindFilterCountsAndEnableFilterBtn();
    this.bindFilterApplyBtn();
    this.checkModalClose();
    this.bindModalCollapses();
  }
  //init function for loading results
  getLocations() {
    if (this.isFirstLoad) {
      this.isNewRegion = true;
      this.currentResults.currentPropCount = 0;
      this.currentResults.totalPropCount = 0;
      this.searchParams.pageNumber = 1;
      this.currentResults.statesRendered = {};
      this.currentResults.citiesRendered = {};
      this.el.locationList.empty();
      this.el.ssrContent.remove();
    }

    //unbind scroll functions so we don't make a deluge of calls to API – this will be reapplied when finished
    $(window).off('scroll', this.scrollHandlerFunction);
    $(window).off('scroll', this.windowScrollFunction);

    //only make calls for new results if we haven't loaded all the results yet
    if (this.currentResults.currentPropCount < this.currentResults.totalPropCount || this.isNewRegion) {
      // Append loading spinner if request is being made.
      this.el.locationList.append('<div class="loadingBar"></div>');
      if (this.el.ddCountry.find('option:selected').val() == '') {
        // Get country list for current region if showing multiple regions (no filter applied)
        this.searchParams.countryCode = this.getCountriesForRegion(this.destinations[this.currentParams.currentRegion].countries);
      }
      this.callServiceConfig((res) => {
        if (parseInt(res.propertiesCount) > 0) {
          // Results returned as expected
          if(!this.isLandingMsgShown) {
            this.hideMsg(this.el.noResultsMsg);
          }
          $('.content-module-component').show();
          this.searchParams.pageNumber++;
          $('.loadingBar').remove();
          this.renderLocationList(res);
          window.digitalData.search.filter.dynamicLocResults = false;
          this.isFirstLoad = false;
          this.isNewRegion = false;
        } else if(!this.currentParams.isRegionSelected && this.currentParams.currentRegion < this.destinations.length - 1) {
          $('.loadingBar').remove();
          // Current region has no properties. Determine if the next region should be started.
          this.currentParams.currentRegion++;
          this.isNewRegion = true;
          this.searchParams.pageNumber = 1;
          this.searchParams.tierId = this.searchParams.tierId || this.supplyTier();
          this.getLocations();
        } else if(this.isFirstLoad) {
          // No results found on first load. Show no results message
          this.showMsg(this.el.noResultsMsg);
          this.isLandingMsgShown = false;
          $('.content-module-component').hide();
          this.el.hotelCount.text(0);
          this.el.modalHotelCount.text(0);
          this.el.locationList.html('');
          window.digitalData.search.filter.dynamicLocResults = true;
          this.isNewRegion = false;
        } else {
          $('.loadingBar').remove();
        }
      });
    } else {
      this.watchPageScroll();
      // Current region has displayed all properties. Determine if the next region should be started.
      if (!this.currentParams.isRegionSelected && this.currentParams.currentRegion < this.destinations.length - 1) {
        this.currentParams.currentRegion++;
        this.isNewRegion = true;
        this.searchParams.pageNumber = 1;
        this.searchParams.tierId = this.searchParams.tierId || this.supplyTier();
        this.getLocations();
      }
    }

  }
  callServiceConfig(callback, paramOverride) {
    let params = this.searchParams;

    if(paramOverride) {
      params = $.extend(true, {}, this.searchParams, paramOverride);
    }

    // in case no brand filters are applied on the UI and all brands are not configured,
    // use the configured brands (instead of ALL) from the brands-filter AEM config.
    if(params.brandId === 'ALL' && !this.configuredBrandsAndTiers.brands.includes('all')) {
      params.brandId = this.configuredBrandsAndTiers.bwsBrands.join(',').toUpperCase();
      params.tierId = this.configuredBrandsAndTiers.tiers.join(',').toUpperCase();
    }

    callService('allProperties', params, 'GET').then((res) => {
      callback(res);
    });
  }
  getTotalPropCount() {
    let oldPageNumber = this.searchParams.pageNumber;
    this.callServiceConfig((res) => {
      this.searchParams.pageNumber = oldPageNumber;
      this.currentResults.totalPropCount = res.propertiesCount;
      this.el.hotelCount.text(formatNumber(res.propertiesCount));
      this.el.modalHotelCount.text(formatNumber(res.propertiesCount));
    }, {
      pageNumber: 1,
      countryCode: '',
      noPropertyData: true
    });
  }
  //render the UI for the results
  renderLocationList(data) {
    let currLocale = getLocaleUrlToken();
    if(this.isFirstLoad) {
      if(this.currentParams.isRegionSelected) {
        this.currentResults.totalPropCount = data.propertiesCount;
        this.el.hotelCount.text(formatNumber(this.currentResults.totalPropCount));
        this.el.modalHotelCount.text(formatNumber(this.currentResults.totalPropCount));
      } else {
        this.getTotalPropCount();
      }
    }
    if (this.isNewRegion) {
      let regionHeader = _.template(this.el.regionHeader.html());
      let renderedRegionHeader = regionHeader({
        region: this.destinations[this.currentParams.currentRegion]
      });
      this.el.locationList.append(renderedRegionHeader);
    }
    _.each(data.countries, (country) => {
      if (!exists(`[data-country=${country.countryName.replace(/\s/g,'-')}]`)) {
        let countryHeader = _.template(this.el.countryHeader.html());

        let renderedcountryHeader = countryHeader({ country: country });
        this.el.locationList.append(renderedcountryHeader);
        this.el.locationList.append(this.el.stateContainer.html());
      }
      let underscoreArray = [];
      _.each(country.states, (state) => {
        // only render State name once
        if (!this.currentResults.statesRendered[`${country.countryName}-${state.stateName}`]) {
          this.currentResults.statesRendered[`${country.countryName}-${state.stateName}`] = true;
          let stateHeader = _.template(this.el.stateHeader.html());
          let renderStateHeader = stateHeader({ state: state });

          let validStateCode = /^[A-Za-z]+$/;
          // only display valid States
          if (state.stateCode.match(validStateCode)) {
            underscoreArray.push(renderStateHeader);
          }
        }
        _.each(state.cities, (city) => {
          let propertyArray = [];
          if (!this.currentResults.citiesRendered[`${country.countryName}-${state.stateName}-${city.cityName}`]) {
            underscoreArray.push(`<h6 class="city">${city.cityName}</h6><ul class="property-list pl-${city.cityName.replace(/\s/g,'-')}">`);
          }
          _.each(city.propertyList, (property) => {
            // Set brandId to tierId if the tierId is in brandMap
            if(window.brandMap[property.tierId]) {
              property.brandId = property.tierId;
            }
            property.locale = currLocale;
            let propertyList = _.template(this.el.propertyList.html());
            let renderpropertyList = propertyList({ property: property, city: city, state: state });
            propertyArray.push(renderpropertyList);
            this.currentResults.currentPropCount++;
          });

          if (!this.currentResults.citiesRendered[`${country.countryName}-${state.stateName}-${city.cityName}`]) {
            underscoreArray.push(propertyArray.join('') + '</ul>');
            this.currentResults.citiesRendered[`${country.countryName}-${state.stateName}-${city.cityName}`] = true;
          } else {
            $(`.pl-${city.cityName.replace(/\s/g,'-')}`).append(propertyArray.join(''));
          }
        });
      });
      $(`[data-statelist-for=${country.countryName.replace(/\s/g,'-')}]`).append(underscoreArray.join(''));
    });

    this.delayBindScroll();
  }

  scrollHandler() {
    let scrollTop = $(window).scrollTop(),
      maxScroll = scrollTop + window.innerHeight + 60,
      endOffset = this.el.listMarker.offset().top,
      listTop = this.el.listComponent.offset().top;

    if (scrollTop >= listTop && endOffset >= maxScroll) {
      $('.scroll-top-container').fadeIn();
    } else {
      $('.scroll-top-container').fadeOut();
    }

    if (!history.state || !history.state.scrolled) {
      history.pushState({'scrolled': true}, 'statusScroll');
    }
  }

  // Watch scroll on brand pages
  watchPageScroll() {
    $(window).scroll(this.scrollHandlerFunction);
  }

  handleScrollTopClick() {
    $('#scrollTopBtn').on('click', (e) => {
      scrollToAnimated(0);
    });
  }

  scrollToTopListener() {
    window.onbeforeunload = () => {
      if (window.scrollY > 0) {
        history.pushState({'scrolled': true}, 'statusScroll');
      }
    };

    window.onload = () => {
      if (history.state && history.state.scrolled) {
        this.scrollToTop();
        $(document).one('scroll', (e) => {
          this.scrollToTop();
        });
      }
    };
  }

  scrollToTop() {
    history.replaceState({'scrolled': false}, 'statusScroll');
    window.scrollTo(0, 0);
  }

  /**
   * Hide the brands from brand filter based on the brands-filter AEM Config.
   *
   * Note - If only 1 brand is configured for the current brand (except 'all'), the brands filter will be hidden as well.
   */
  hideNotApplicableBrands() {
    let configuredBrandsForCurrentBrand = this.configuredBrandsAndTiers.brands;
    if(!configuredBrandsForCurrentBrand.includes('all')) {
      if(configuredBrandsForCurrentBrand.length == 1){
        // hide the brands filter button and panel if only 1 brand is configured in the brands-filter AEM config (and doesn't include 'all')
        $('button[data-panel="brands-panel"]').remove();
        $('.brands-panel').remove();
      } else {
        // hide the brands if 'all' is not configured
        $('#brands-cb > .checkbutton').each(function() {
          let brandCode = $(this).find('input').val();
          if(!configuredBrandsForCurrentBrand.includes(brandCode)) {
            $(this).remove();
          }
        });
      }
    }
  }

  /**
   * Hide the amenities from amenities filter based on the amenities-filter AEM Config.
   */
  hideNotApplicableAmenities() {
    let configuredAmenitiesForCurrentBrand = this.configuredAmenitiesForCurrentBrand;
    if(!this.configuredAmenitiesForCurrentBrand.includes('all')) {
      // hide the amenities if 'all' is not configured
      $('#amenities-cb > .checkbutton').each(function() {
        let amenityCode = $(this).find('input').val();
        if(!configuredAmenitiesForCurrentBrand.includes(amenityCode)) {
          $(this).remove();
        }
      });
    }
  }

  trackAnalytics() {
    let types = [],
      selection = [],
      brand = [],
      amenities = [],
      redemption = [];

    const destSelected = this.el.destPanel.find(':selected');

    let pushElements = function(elements, type) {
      if(elements.length > 0) {
        types.push(type);
        selection.push(elements.join(','));
      }
    };

    this.el.brandsAmenitiesTiersBtns.each((i, el) => {
      if ($(el).prop('checked')) {
        if ($(el).hasClass('amen')) {
          amenities.push($(el).next().text().trim());
        } else if($(el).hasClass('br')) {
          brand.push($(el).next().text().trim());
        } else {
          redemption.push($(el).val().replace(/\s/g, ''));
        }
      }
    });

    if (destSelected.val().length > 0) {
      let dest = [];
      destSelected.each(function() {
        if ($(this).val().length > 0){
          dest.push($(this).text());
        } else {
          dest.push('unused');
        }
      });
      pushElements(dest, 'destinations');
    }

    pushElements(brand, 'brand');
    pushElements(amenities, 'amenities');
    pushElements(redemption, 'redemption');

    Analytics.updateSearchRefinementAnalytics(types.join('|'), selection.join('|'));
    EventHandler.triggerEvent('dyna-loc-filter');
  }
}

export default new DynamicLocation();
