/* eslint-disable camelcase */
import {formatDateForG360, getLogLocale ,CookieHandler} from './utils.js';
import ConfigsUtils from './aem-configs/config-utils.js';
import { UserHandler, getCriteria } from './session-handler.js';

import {
  _LOCALE_,
  _defaultChannelId,
  REFRESH_TOKEN,
  $_PAGE_,
  brand_id,
  ACCESS_TOKEN,
  reservationDataFromAEM,
  LOGLOCALE_COOKIE
} from './vars.js';

class SnowStorm {
  constructor() {
    
    $(document).on('click', '.snow-storm', (e) => {
      e.preventDefault();
      this.redirectToSnowStorm(e);
    });
    $(document).on('click', '.snow-storm-redirect', (e) => {
      e.preventDefault();
      this.redirectToSnowStorm(e);
    });
  }

  redirectToSnowStorm(e) {
    this._params = $.extend(true, {}, getCriteria());
    let url = this.handleSnowStormURL();
    let arrival_date = formatDateForG360($('#from').val() || this._params.checkInDate);
    let departure_date = formatDateForG360($('#to').val() || this._params.checkOutDate);
    let numOfRooms = $('#numOfRooms').val() || this._params.rooms;
    let numOfAdults = parseInt($('#numOfAdults').val()) + parseInt($('#numOfChildren').val()) || parseInt(this._params.adults) + parseInt(this._params.children);
    let brand_id_ss = (brand_id == 'whg' || brand_id == 'wr' ) ? 'ALL' : brand_id;

    if (url != '') {
      if ($_PAGE_.is(".search-results-page") && brand_id && brand_id !== 'ALL') {
        this.handleOutbound(url ,
          { language_id: _LOCALE_,
            brand_id: brand_id_ss,
            redirect_to: 'Brand',
            checkin_date: arrival_date,
            checkout_date: departure_date,
          });
      } else if ($_PAGE_.is(".rooms-rates-page") || $_PAGE_.is(".property-page") || $_PAGE_.is(".packages-page") || $_PAGE_.is(".booking-page")) {
        if (this._params.propertyId) {
          this.handleOutbound(url ,
            { brand_id: brand_id_ss,
              redirect_to: 'PropertyHotel',
              property_id: this._params.propertyId,
              hotel_id: this._params.propertyId,
              checkin_date: arrival_date,
              checkout_date: departure_date,
              room_count: numOfRooms,
              passengers: numOfAdults,
              language_id: _LOCALE_
            });
        }

      }else if($_PAGE_.is(".booking-page")){
        this.handleOutbound(url ,
          { language_id: _LOCALE_,
            brand_id: brand_id_ss,
            redirect_to: 'Brand',
          });
      }else if($_PAGE_.is(".confirmation-page")){
        if(reservationDataFromAEM.channel == 'partner' && !$(e.currentTarget).hasClass('snow-storm-redirect')){
          this.handleOutbound(url ,
            { language_id: _LOCALE_,
              brand_id: brand_id_ss,
              hotel_id: reservationDataFromAEM.propertyId,
              confirmation_id:reservationDataFromAEM.confirmationNumber,
              property_id: reservationDataFromAEM.propertyId,
              first_name:reservationDataFromAEM.firstName,
              last_name:reservationDataFromAEM.lastName,
              redirect_to: 'FindRes',
            });
        }else{
          this.handleOutbound(url ,
            { language_id: _LOCALE_,
              brand_id: brand_id_ss,
              redirect_to: 'Brand',
            });
        }      
      } else if(brand_id == 'wr' && (($(".full-reservations-component").length && $(".fullreservations").length)  || $(".my-upcoming-reservations").length) ){
        let confirmationNumber = $(e.target).closest('.snowstorm').data('confirmationNumber'),
          firstName = $(e.target).closest('.snowstorm').data('firstName'),
          lastName = $(e.target).closest('.snowstorm').data('lastName'),
          propertyCode = $(e.target).closest('.snowstorm').data('propertyCode');
        if(confirmationNumber){
          this.handleOutbound(url ,
            { language_id: _LOCALE_,
              hotel_id: propertyCode,
              confirmation_id:confirmationNumber,
              property_id: propertyCode,
              first_name:firstName,
              last_name:lastName,
              redirect_to: 'FindRes',
            });
        }else{
          this.handleOutbound(url ,
            {
              language_id: _LOCALE_,
              brand_id: brand_id_ss,
              redirect_to: 'MyRes', // 'FH','MyRes','PropertyHotel','FindRes','Brand'
            });
        }
      }else{
        this.handleOutbound(url ,
          {
            language_id: _LOCALE_,
            brand_id: brand_id_ss,
            redirect_to: 'FH', // 'FH','MyRes','PropertyHotel','FindRes','Brand'
            checkin_date: arrival_date,
            checkout_date: departure_date,
          });
      }
    }
    console.log(url, this._params);
  }

  handleOutbound(url , param, openInNewTab = true) {
    // opened the new tab right away to avoid pop-up blocker by the browser
    let newWindow, actualDocument;
    if (openInNewTab) {
      newWindow = window.open();
      actualDocument = newWindow.document;
    } else {
      actualDocument = window.document;
    }

    let formContainer = actualDocument.createElement('div');
    var returnURL = url;

    let form = actualDocument.createElement('form');
    form.action = returnURL;
    form.method = 'post';
    form.classList.add('not-ajax');
    for (let key in param) {
      if (key !== '') {
        this.addInput(form, key, param[key],actualDocument);
      }
    }

    if (UserHandler.isWRLoggedIn()) {
      const idToken = UserHandler.getIdToken();
      const accessToken = CookieHandler.readCookie(ACCESS_TOKEN);
      const refreshToken = CookieHandler.readCookie(REFRESH_TOKEN);
      const logLocale = getLogLocale();

      // Add hidden inputs to form
      this.addInput(form, 'id_token', idToken,actualDocument);
      this.addInput(form, REFRESH_TOKEN, refreshToken,actualDocument);
      this.addInput(form, ACCESS_TOKEN, accessToken,actualDocument);
      this.addInput(form, LOGLOCALE_COOKIE, JSON.stringify(logLocale),actualDocument);
    }
    let sanitizedFormHtml = DOMPurify.sanitize(form.outerHTML);

    // Set sanitized form HTML
    formContainer.innerHTML = sanitizedFormHtml;

    actualDocument.body.appendChild(formContainer);

    // Submit
    formContainer.firstChild.submit();

    // Remove the appended Form as the form might be submitted in a new tab.
    actualDocument.body.removeChild(formContainer);

  }

  addInput(form, key, value, actualDocument) {
    let inputContainer = actualDocument.createElement('div');

    let input = actualDocument.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;

    let sanitizedInputHtml = DOMPurify.sanitize(input.outerHTML);
    inputContainer.innerHTML = sanitizedInputHtml;

    form.appendChild(inputContainer);
  }


  handleSnowStormURL() {
    return ConfigsUtils.getConfigs('snowstorm-configuration', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce((result, item) => {
      return (item.name === 'url' && item.value) ? item.value : result;
    }, null);
  }

}

export default SnowStorm;


let g360 = new SnowStorm();

export {
  g360,
};
