import {
  exists,
  matchElementsHeight,
  suppressNoSrcImages,
  ResizeHandler,
  isMobileWidth
} from '../base/dom-utils.js';
import {
  $_BOOKING_BAR_MINI_FORM
} from '../base/vars.js';

class OurRooms {
  constructor() {
    if (exists($('.our-rooms-component')) && exists($('.our-rooms-component .see-all-rooms'))) {
      this.bindSeeAllRoomsButton();
      this.matchHeightsBindings();
    }
  }

  bindSeeAllRoomsButton() {
    $('.our-rooms-component .see-all-rooms').click((e) => {
      e.preventDefault();

      $_BOOKING_BAR_MINI_FORM.submit();
    });
  }

  matchHeightsBindings() {
    this.matchRoomHeights();
    $('.our-rooms-component').each((i, el) => {
      let img = $(el).find('img');
      img.on('load', () => this.matchRoomHeights());
      img.each((i, elImg) => {
        suppressNoSrcImages(elImg);
      });
    });
    ResizeHandler.addResizeEndFn(() => this.matchRoomHeights());
  }

  matchRoomHeights() {
    $('.our-rooms-component .room-content, .our-rooms-component .item, .our-rooms-component .rooms-container .col-xs-24').removeAttr('style');

    if (isMobileWidth()) {
      $('.our-rooms-component .item').show();
    }
    $('.our-rooms-component .rooms-container, .our-rooms-component .carousel').each((i, el) => {
      matchElementsHeight($(el).find('.room-content'));
    });
    $('.our-rooms-component .carousel').each((i, el) => {
      matchElementsHeight($(el).find('.item'));

      if (isMobileWidth()) {
        let tmpHeight = $(el).find('.item')
          .height();
        $(el).find('.item')
          .removeAttr('style')
          .height(tmpHeight);
      }
    });

    if ($('body').is('.safari')) {
      $('.our-rooms-component .rooms-container').each((i, el) => {
        matchElementsHeight($(el).find('.col-xs-24'));
      });
    }
  }
}

export default new OurRooms();
