import LazyLoad from '../base/lazy-load.js';

/**
 * Uses the lazy load class to load iframes.
 */
class LazyLoadIframe {
  /**
   * Default constructor to pll-iframe class name.
   */
  constructor(className = '.ll-iframe') {
    let elements = [].slice.call(document.querySelectorAll(className));
    LazyLoad.addElements(elements, this.handleIframe);
  }

  /**
   * Callback to load iframe on elements.
   */
  handleIframe(element, observer) {
    element.src = element.dataset.src;
    element.addEventListener('load', () => {
      element.classList.remove('loading-iframe');
    });
    observer.unobserve(element);
  }
}

export default new LazyLoadIframe();
