import { exists } from '../base/dom-utils.js';
import Analytics from '../base/wyn-analytics-module.js';

class ContentModule {
  constructor() {
    if (exists('.content-module-component')) {
      this.bindLinkClick();
    }
  }

  bindLinkClick() {
    $('.content-module-component a').click((e) => {
      Analytics.getContentModuleInfo(e.currentTarget);
    });
  }
}

export default new ContentModule();
