

export function scrollToElement(element, options) {
  options = options || {};
  options.offset = options.offset || 0;

  let $scrollElement;
  let $element = $(element);
  let $modal = $element.closest('.modal');

  if ($modal.length) {
    $scrollElement = $modal;
    options.scrollTop = $element.position().top;
  } else {
    $scrollElement = $('html,body');
    options.scrollTop = $element.offset().top - $('header:visible .top-nav').height();
  }

  options.scrollTop -= options.offset;
  delete options.offset;

  return $scrollElement.animate(options, 1000).promise();
}
