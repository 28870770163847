import ConfigsUtils from './config-utils';

const KEY = 'wr-non-participating-brands';

/**
 * Returns a comma-separated string containing the brands that we must include in the 
 * getMultiPropertySearch call depending on the SRP-Include-Brands AEM Config.
 * If no SRP-Include-Brands AEM Configs satisfy the provided parameters, undefined is returned.
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 * @returns {string|undefined}
 */
const getWRNonParticipatingBrands = () => {
    const config = ConfigsUtils.findConfig(KEY);
    return config ? config.attributes[KEY] : [];
};

const isCurrentBrandParticipatingToWR = () => {
    const wrNonParticipatingBrand = getWRNonParticipatingBrands();
    return !wrNonParticipatingBrand.includes(window.brand_id);
};

export default {
    getWRNonParticipatingBrands,
    isCurrentBrandParticipatingToWR,
};
