import { exists, scrollToAnimated } from '../base/dom-utils.js';
import { ReservationHandler } from '../base/session-handler.js';
import {
  formatDateForBWS,
  formatDateForPrinting,
  formatTimeShow,
  getHeaderHeight,
  callService,
  isSMORate,
  CookieHandler
} from '../base/utils.js';
import { $_PAGE_, _LOCALE_, reservationDataFromAEM, $_PROMO_BANNER_ } from '../base/vars.js';
import Analytics from '../base/wyn-analytics-module.js';

class CancelReservation {
  constructor() {
    if ($_PAGE_.is('.confirmation-page')) {
      this.$confirmationContainer = $('.confirmation-component'), // confirmationComponent
      this.$title = this.$confirmationContainer.find('.confirmation-title'),
      this.$titleReserved = this.$confirmationContainer.find('.title-reserved'),
      this.$titleFound = this.$confirmationContainer.find('.title-found'),
      this.$titleCancelled = this.$confirmationContainer.find('.title-cancelled');

      this.$reservationContainer = $('.res-rate-summary'), // reservationSummary
      this.$modifyCancelContainer = this.$reservationContainer.find('.res-sum-modify-cancel'),
      this.$modifyButton = this.$reservationContainer.find('.modify-button'),
      this.$cancelButton = this.$reservationContainer.find('.cancel-button');

      this.$cancellationContainer = $('.cancellationdetails-component'), // cancellationDetails
      this.$cancelTime = this.$cancellationContainer.find('.cancel-guest-current-date'),
      this.$cancelConfirmation = this.$cancellationContainer.find('.cancel-guest-cancel-no'),
      this.$cancellationDetailsBackButton = this.$cancellationContainer.find('.back-button-cancelflow'),
      this.$cancellationDetailsCancelButton = this.$cancellationContainer.find('.cancel-reservation-confirmation-btn');

      var reservation = ReservationHandler.getReviewReservation();
      if (reservation.rateCode && isSMORate(reservation.rateCode)){
        $('.confirm-cancellation-data .guest-info .email-container:not(.hold-rate)').hide();
      } else {
        $('.confirm-cancellation-data .guest-info .hold-rate').hide();
      }
    }
  }

  // confirmationComponent
  showTitle($which) {
    this.$title.find('> div').hide();

    if ($which && $which.length) {
      $which.show();
    }
  }

  // reservationSummary
  hideModifyCancel() {
    this.$modifyCancelContainer.hide();
  }
  showModifyCancel() {
    this.$modifyCancelContainer.show();
  }

  cancelEnabled() {
    $('.promo-component').hide();
    this.hideModifyCancel();

    this.showCancellationDetails();

    let pos = this.$cancellationContainer.offset().top - getHeaderHeight();
    pos -= exists($('#bookingBarHeight')) ? $('#bookingBarHeight').height() : 0;
    scrollToAnimated(pos);
  }
  enableCancel() {
    this.$cancelButton.find('a').click((e) => {
      e.preventDefault();
      Analytics.updateCancellationData(ReservationHandler.getReviewReservation());
      var reservation = ReservationHandler.getReviewReservation();
      if (reservation.rateCode && isSMORate(reservation.rateCode)){
        $('.confirm-cancellation-data .guest-info .email-container:not(.hold-rate)').hide();
      } else {
        $('.confirm-cancellation-data .guest-info .hold-rate').hide();
      }
      Analytics.satelliteTracker('cancel_booking_info');
      this.cancelEnabled();
    });
  }

  // cancellationDetails
  handleCancelTime(time) {
    this.$cancelTime.html(time);
  }
  handleCancelConfirmation(num) {
    this.$cancelConfirmation.html(num);
  }
  hideCancellationDetails() {
    this.$cancellationDetailsCancelButton.off('click');
    this.$cancellationDetailsBackButton.off('click');

    this.$cancellationContainer.hide();

    $('.promo-component').show();

    this.showModifyCancel();
  }
  showCancellationDetails() {
    let $comments = this.$cancellationContainer.find('.customer-comments');
    if ($comments.length && !($comments.html().length)) {
      $comments.parent().remove();
    }

    this.$cancellationContainer.show();

    $_PROMO_BANNER_.hide();

    this.$cancellationDetailsBackButton.click(() => {
      this.hideCancellationDetails();
    });
    this.$cancellationDetailsCancelButton.click(() => {
      this.triggerCancellation();
    });
  }

  getCancelTime() {
    let dateNow = formatDateForPrinting(new Date()),
      currentTime = new Date(),
      hours = currentTime.getHours(),
      minutes = currentTime.getMinutes(),
      timeNow = '';
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }

    timeNow = formatTimeShow('' + hours + minutes);
    return (dateNow + ' ' + timeNow);
  }
  cancellationConfirmationCallback(res) {
    if (res && res.status === 'OK') {
      this.updateCancellationAnalytics();
      this.handleCancelTime(this.getCancelTime());
      this.handleCancelConfirmation(res.cancellation);
      Analytics.updateCancellationConfirmationNumber(res.cancellation);
      Analytics.updateCurrencyCode(reservationDataFromAEM.currencyCode);
      Analytics.setConfirmationAnalyticsData(ReservationHandler.getReviewReservation());
      Analytics.satelliteTracker('cancel_confirm_info');

      $('.page-content').removeClass('reservation-found')
        .removeClass('reservation-canceled')
        .removeClass('reservation-confirmed')
        .removeClass('reservation-modified')
        .addClass('canceled');
      $('.confirmation-title .headline-e.title-hold-rate, .confirmation-title .hold-rate .headline-e.title-cancelled, .page-content.canceled .hold-rate-msg').hide();
      $('.page-content.canceled .hold-rate .title-hold-rate-cancelled').show();
      scrollToAnimated(0);
    } else {
      alert(this.$confirmationContainer.data('unable-to-cancel-reservation-text') || 'Unable to cancel your reservation!');
      $(".cancel-reservation-confirmation-btn").attr("disabled", false);
    }
  }
  updateCancellationAnalytics() {
    let split = window.pageProperties.breadCrumbs.split('|');
    split[3] = 'find-reservation';
    split[4] = 'cancel-confirmation';
    window.digitalData.page.category.breadCrumbs = split[0] + '|' + split[1] + '|' + split[2] + '|' + split[3] + '|' + split[4];
    window.digitalData.page.pageInfo.pageName = 'cancel-confirmation';
    window.digitalData.page.category.primaryCategory = 'find-reservation';
    if ($_PAGE_.is('.confirmation-page')) {
      let res = ReservationHandler.getReviewReservation(),
        formattedBeforeTaxNumber = Number(res.subtotal.replace(/[^0-9\.]+/g, '')),
        formattedAfterTaxNumber = Number(res.totalCash.replace(/[^0-9\.]+/g, ''));

      window.digitalData.confirmation.cancelConfirmInfo.confirmationNumber = res.confirmationNumber;
      window.digitalData.confirmation.cancelConfirmInfo.cancelledTotalExclusive = parseFloat(formattedBeforeTaxNumber.toFixed(2));
      window.digitalData.confirmation.cancelConfirmInfo.cancelledTotalInclusive = parseFloat(formattedAfterTaxNumber.toFixed(2));
      let cancellationPolicy = res.cancellationPolicy.match(/PENALTY AMOUNT (.*) PER ROOM/);
      if (cancellationPolicy != null) {
        window.digitalData.confirmation.cancelConfirmInfo.penaltyFee = cancellationPolicy[1];
      }
    }
  }
  triggerCancellation() {
    let retData = ReservationHandler.getReviewReservation();

    if (typeof retData === 'object') {
      $(".cancel-reservation-confirmation-btn").attr("disabled", true);
      let rooms = retData.rooms;
      let confirmation = '';
      if (rooms == 1) {
        confirmation = retData.confirmationNumbers;
      } else {
        if (retData.itineraryNumber == '') {
          confirmation = retData.confirmationNumber;
        } else {
          confirmation = retData.itineraryNumber;
        }
      }
      let cancelData = {
        firstName: retData.firstName,
        lastName: retData.lastName,
        confirmation: confirmation,
        email: retData.email || ' ',
        checkinDate: formatDateForBWS(retData.checkInDate),
        checkoutDate: formatDateForBWS(retData.checkOutDate),
        hotelCode: retData.propertyId,
        hotelBrand: retData.brandId,
        brandTier: retData.brandTier,
        cancelTime: this.getCancelTime(),
        reservationChannel: retData.reservationChannel,
        language: _LOCALE_,
        deviceType: CookieHandler.getDeviceTypeFromCookie()
      };

      // cancellationConfirmationCallback();
      callService('cancelReservation', cancelData, 'POST').then((res) => this.cancellationConfirmationCallback(res));
    }
  }
}

export default new CancelReservation();
