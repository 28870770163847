import { $_PAGE_ } from '../vars.js';
import PackageAddon from '../../pages/package-addons.js';
import { getSessionStorage } from '../session-handler.js';
/**
 * Class representing the Packages Add-ons Analytics.
 *
 * This class is responsible for tracking user interactions related to package add-ons,
 * and it sends the tracked data to an analytics service.
 */
class PackagesAddonsAnalytics {

  /**
   * Create an instance of PackagesAddonsAnalytics.
   */
  constructor() {
    this.isPackagesPage = $_PAGE_.hasClass('packages-page');
    this.isBookingPage = $_PAGE_.hasClass('booking-page');
    this.isConfirmationPage = $_PAGE_.hasClass('confirmation-page');
    this.isRoomAndRatePage = $_PAGE_.hasClass('rooms-rates-page');
    this.setupMutationObserver();

    // reset packages digital data
    window.digitalData.packages = (this.isPackagesPage || this.isBookingPage || this.isConfirmationPage || this.isRoomAndRatePage) ? window.digitalData.packages || {} : {};

    if(this.isConfirmationPage){
      this.clearePackageForAnotherBooking();
    }
  }

  /**
   * Sets up a mutation observer to detect changes in the DOM.
   *
   * This method allows the object to react to changes in the page's structure,
   * including the addition of elements that need to be tracked.
   */
  setupMutationObserver() {
    const observer = new MutationObserver((mutations) => { 
      if(mutations.length > 0){
        this.attachShowMoreOrLessOptionsClick();
        this.attachAddPackageToBookingEvent();
        this.attachCartSummaryDetailsClickEvent();
        this.attachCartSummaryRemovePackageClickEvent();
      }
    });

    const targetNode = document.body;
    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);
  }

  /**
   * Attaches click event to the show more / show less button in a package category.
   *
   * This method sets up event listeners for all package category show more / show less buttons, 
   * enabling user interactions with these elements to be tracked.
   */
  attachShowMoreOrLessOptionsClick(){
    const packageShowMoreElements = document.querySelectorAll('li.see-more');
    packageShowMoreElements.forEach((buttonElement) => {
      buttonElement.addEventListener('click', this.handleShowMoreOrLessButton.bind(this));
    });
  }

  /**
   * This method handles how data is tracked when a show more / show less button is clicked
   */
  handleShowMoreOrLessButton(event) {
    if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

    let eventElement = event.srcElement;
    // Adjusts the the event element if a show-more or show-less button's child tag was clicked directly
    while (eventElement.tagName != 'LI'){
      eventElement = eventElement.parentNode;
    }

    if (eventElement.getAttribute("class") == 'see-more see-less') {
      //if the button reads "SHOW MORE OPTIONS"
      window.digitalData.packages.nameOfLinkClicked = eventElement.querySelector('a').getAttribute("data-more");
      this.satelliteTracker('showMoreShowLess');
    } else if (eventElement.getAttribute("class") == 'see-more') {
      // else if the button reads "SHOW LESS OPTIONS"
      window.digitalData.packages.nameOfLinkClicked = eventElement.querySelector('a').getAttribute("data-less");
      this.satelliteTracker('showMoreShowLess');
    }
  }

  /**
   * Attaches click event to the "Continue" button.
   *
   * This method tracks user interactions with the "Continue" button, providing insights
   * about user flow in the page.
   */
  attachButtonClickEvent() {
    const btnPrimary = document.querySelector('.packages-overview .booking-input-container .btn-primary');
    if (btnPrimary && !btnPrimary.dataset.eventAttached) {
      btnPrimary.addEventListener('click', () => {
        this.satelliteTracker('continueToBook');
      });
      btnPrimary.setAttribute('data-event-attached', 'true');
    }
  }

  /**
   * Handles the click event for adding/removing a package.
   *
   * This method is triggered when a user adds or removes a package, allowing the analytics service to track
   * which packages are popular and how often they are added or removed.
   *
   * @param {Event} event - The click event.
   */
  handleAddRemovePackageClick(event) {
    if (this.isEventHandled(event)) return;  // Early return if the event has already been handled

    const packageData = this.extractPackageData(event);

    this.updatePackages(packageData);

    this.satelliteTracker(packageData.action);
  }
  
  /**
   * Checks if the event has already been handled or if the target has the 'data-add-button-label' attribute.
   *
   * @param {Event} event - The click event.
   * @returns {boolean} - Returns true if the event has already been handled, false otherwise.
   */
  isEventHandled(event) {
    if (event.alreadyHandled || event.target.hasAttribute('data-add-button-label')) {
      return true;
    }
    event.alreadyHandled = true;  // Mark the event as handled
    return false;
  }

  /**
   * Extracts the package details from the mousedown event.
   *
   * This method is called when a user presses the mouse button on the add/remove package button.
   * It captures the package information before the action label is updated.
   *
   * @param {Event} event - The mousedown event.
   * @returns {Object} An object containing package details.
   */
  extractPackageData(event) {
    // Extract details from the event
    const action = this.getDirectCallName(event.target.closest('.package-container').querySelector('.book-text'));
    const packageName = event.target.closest('.package-container').querySelector('.package-title.title .name-toggle').innerText;
    const packageCategory = event.target.closest('.packages-add-on__item').querySelector('.intro-title').innerText;
    const packageQuantity = parseInt(event.target.closest('.package-container').querySelector('.package-quantity').value);

    return { action, packageName, packageCategory, packageQuantity };
  }

  /**
   * Returns the name of the direct call based on action.
   *
   * @param {object} actionElement - The button whose labeled action that was performed (either 'ADD' or 'REMOVE').
   * @returns {string} - Returns 'packageAdded' if the action was 'ADD', 'packageRemoved' if the action was 'REMOVE'.
   */
  getDirectCallName(actionElement) {
    //N/B: Add changes to remove and remove changes to add
    const action = actionElement.getAttribute('data-add-button-label') === actionElement.innerHTML ? actionElement.getAttribute('data-add-button-label') : actionElement.getAttribute('data-remove-button-label');
    return (action === actionElement.getAttribute('data-remove-button-label')) ? 'packageAdded' : 'packageRemoved';
  }

  /**
   * Updates package details and quantity based on the direct call name.
   *
   * @param {Object} packageData - An object containing packageCategory, packageName, and packageQuantity.
   */
  updatePackages(packageData) {
    const { action, packageCategory, packageName } = packageData;
    // Add mapping between package name and category
    this.addPackageMapping(packageCategory, packageName);

    // Add or remove package details and quantity based on "action"
    if (action === 'packageAdded') {
      this.addPackageNameDetails(packageName);
      this.addPackageCategoryDetails(packageCategory);
    } else {
      this.removePackageNameDetails(packageName);
      this.removePackageCategoryDetails(packageCategory,packageName);
    }
  }

  /**
   * Adds a mapping between a package name and category in digitalData.packages.packageMapping object.
   *
   * @param {string} packageCategory - The category of the package.
   * @param {string} packageName - The name of the package.
   */
  addPackageMapping(packageCategory, packageName) {
    if (!window.digitalData.packages.packageMapping) {
      window.digitalData.packages.packageMapping = {};
    }

    // If the category is not already in the mapping, add it with the package name as an array
    if (!window.digitalData.packages.packageMapping[packageCategory]) {
      window.digitalData.packages.packageMapping[packageCategory] = [packageName];
    } else {
      // If the category is already in the mapping, append the package name to its array
      if (!window.digitalData.packages.packageMapping[packageCategory].includes(packageName)) {
        window.digitalData.packages.packageMapping[packageCategory].push(packageName);
      }
    }
  }

  /**
   * Adds a click event listener to the add/remove package element.
   *
   * This method sets up an event listener for a given add/remove package element,
   * enabling user interactions with the element to be tracked.
   *
   * @param {HTMLElement} packageItem - The add/remove package element.
   */
  addRemovePackageClickListener(packageItem) {
    packageItem.addEventListener('click', this.handleAddRemovePackageClick.bind(this));
  }

  clearePackageForAnotherBooking(){
    const bookanother = document.querySelector('.book-another-link');
    if (bookanother) {
      bookanother.addEventListener('click', ()=>{
        window.digitalData.packages = {};
      });
    }
  }

  /**
   * Removes the click event listener from the add/remove package element.
   *
   * This method allows for the event listener attached to an add/remove package element to be removed,
   * if the element or its event listener is no longer needed.
   *
   * @param {HTMLElement} packageItem - The add/remove package element.
   */
  removeAddRemovePackageClickListener(packageItem) {
    packageItem.removeEventListener('click', this.handleAddRemovePackageClick.bind(this));
  }

  /**
   * Attaches click event to add/remove package elements.
   *
   * This method sets up event listeners for all add/remove package elements,
   * enabling user interactions with these elements to be tracked.
   */
  attachAddPackageToBookingEvent() {
    const addPackageToBookingElements = document.querySelectorAll('.add-package-tobooking');
    addPackageToBookingElements.forEach((packageItem) => {
      this.removeAddRemovePackageClickListener(packageItem);
      this.addRemovePackageClickListener(packageItem);
    });
  }

  /**
   * Attaches click event to the Details link elements.
   */
  attachCartSummaryDetailsClickEvent() {
    const detailsLinkElements = document.querySelectorAll('.rate-details-link.details');
    detailsLinkElements.forEach((element) => {
      element.removeEventListener('click', this.handleCartSummaryDetailsClick.bind(this)); // Optional: Remove existing event listener, if any
      element.addEventListener('click', this.handleCartSummaryDetailsClick.bind(this));
    });
  }

  /**
   * Attaches click event to the Remove package link elements.
   */
  attachCartSummaryRemovePackageClickEvent() {
    const removePackageLinkElements = document.querySelectorAll('.remove-package-ratesummary');
    removePackageLinkElements.forEach((element) => {
      element.removeEventListener('click', this.handleCartSummaryRemovePackageClick.bind(this)); // Optional: Remove existing event listener, if any
      element.addEventListener('click', this.handleCartSummaryRemovePackageClick.bind(this));
    });
  }

  /**
   * Handles the click event for the Details link.
   *
   * This method is triggered when a user clicks the Details link on a package in the cart.
   * It updates the analytics data and sends a tracking event.
   *
   * @param {Event} event - The click event.
   */
  handleCartSummaryDetailsClick(event) {
    // If the event has already been handled, no further actions are required
    if (this.isEventHandled(event)) return;

    this.satelliteTracker('packageDetailsLinkClicked');
  }

  /**
   * Handles the click event for the Remove package link.
   *
   * This method is triggered when a user removes a package from the cart.
   * It updates the analytics data and sends a tracking event.
   *
   * @param {Event} event - The click event.
   */
  handleCartSummaryRemovePackageClick(event) {
    // If the event has already been handled, no further actions are required
    if (this.isEventHandled(event)) return;

    // Extract package data from the event
    const packageData = this.extractPackageDataFromCart(event);

    const packageName = packageData.packageName;
    const packageCategory = packageData.packageCategory;

    this.removePackageNameDetails(packageName);
    this.removePackageCategoryDetails(packageCategory,packageName);

    this.satelliteTracker('packageRemoved');
  }

  /**
   * Extracts package data from the cart remove event.
   *
   * @param {Event} event - The click event.
   * @return {Object} - An object containing packageName, packageCategory, and packageQuantity.
   */
  extractPackageDataFromCart(event) {
    // Extract package details from the event
    const action = event.target.innerText;
    let packageName = event.target.closest('.price-name').querySelector('.name-main.room-description').innerText;
    const packageQuantityMatch = packageName.match(/\(([^)]+)\)/);
    const packageQuantity = packageQuantityMatch ? parseInt(packageQuantityMatch[1]) : 1;
    const packageCategory = event.target.getAttribute('data-category'); // Grab the data-category value

    // Remove the quantity part "(1x)" from the package name
    packageName = packageName.replace(/\(\d+x\)/, '').trim();

    return { action, packageName, packageCategory, packageQuantity };
  }

  /**
   * Formats the package details for tracking.
   *
   * This helper method formats the package category, package name, and quantity into a string
   * that is suitable for tracking. It uses '|' to separate the category and name, and follows the name
   * with the quantity after a ':'.
   *
   * @param {string} packageCategory - The category of the package.
   * @param {string} packageName - The name of the package.
   * @param {string} packageQuantity - The quantity of the package.
   * @returns {string} The formatted package details.
   */
  formatPackageDetails(packageCategory,packageName,packageQuantity) {
    return `${packageCategory} | ${packageName} | Quantity: ${packageQuantity}`;
  }

  /**
   * Method to add package name details to packageName in digitalData.packages object.
   *
   * @param {string} packageName - The name of the package.
   */
  addPackageNameDetails(packageName) {
    const packageDetailString = `${packageName}`;

    // Split existing package names into an array
    const existingPackages = window.digitalData.packages.packageName
      ? window.digitalData.packages.packageName.split(' | ').map(name => name.trim())
      : [];

    // Add the new package name if it doesn't already exist
    if (!existingPackages.includes(packageDetailString)) {
      existingPackages.push(packageDetailString);
    }

    // Update digitalData.packages.packageName
    window.digitalData.packages.packageName = existingPackages.join(' | ');
  }

  /**
   * Method to add package category details to allPackagesCategoryDetails in digitalData.packages object.
   *
   * @param {string} packageCategory - The category of the package.
   */
  addPackageCategoryDetails(packageCategory) {
    // Ensure packages object exists
    window.digitalData.packages = window.digitalData.packages || {};

    if (!window.digitalData.packages.packageCategory) {
      window.digitalData.packages.packageCategory = '';
    }

    const packageCategoryList = window.digitalData.packages.packageCategory.split('|').filter(item => item).map(item => item.trim());

    if (!packageCategoryList.includes(packageCategory)) {
      // If packageCategory is not in the list, add it
      packageCategoryList.push(packageCategory);

      // Transform the array back into a string separated by pipes
      window.digitalData.packages.packageCategory = packageCategoryList.join(' | ');
    }
  }


  /**
   * Method to remove package name details from packageName in digitalData.packages object.
   *
   * @param {string} packageName - The name of the package to be removed.
   */
  removePackageNameDetails(packageName) {

    const packageNameList = window.digitalData.packages.packageName.split('|').map(item => item.trim());

    // Remove packageName from the list, and transform it back into a string separated by pipes
    window.digitalData.packages.packageName = packageNameList.filter(
      (existingName) => existingName.toLowerCase() !== packageName.toLowerCase()
    ).join(' | ');
  }


  /**
   * Method to remove package category details from allPackagesCategoryDetails in digitalData.packages object.
   *
   * @param {string} packageCategory - The category of the package.
   * @param {string} packageName - The name of the package.
   */
  removePackageCategoryDetails(packageCategory, packageName) {
    // Check if the packageCategory contains the packageName
    if (window.digitalData.packages.packageMapping[packageCategory] && (
      window.digitalData.packages.packageMapping[packageCategory].includes(packageName) || window.digitalData.packages.packageMapping[packageCategory].toString().toLowerCase().includes(packageName) )) {

      // Remove packageName from the packageMapping of packageCategory
      window.digitalData.packages.packageMapping[packageCategory] =
        window.digitalData.packages.packageMapping[packageCategory].filter(
          pkg => pkg.toLowerCase() !== packageName.toLowerCase()
        );

      // If packageCategory is now empty, remove it from window.digitalData.packages.packageCategory
      if (window.digitalData.packages.packageMapping[packageCategory].length === 0) {
        const packageCategoryList = window.digitalData.packages.packageCategory.split('|').map(item => item.trim());

        // Remove packageCategory from the list, and transform it back into a string separated by pipes
        window.digitalData.packages.packageCategory = packageCategoryList.filter(
          existingCategory => existingCategory.toLowerCase() !== packageCategory.toLowerCase()
        ).join(' | ');
      }
    }
  }

  /**
   * Creates variables in the digitalData object to store the amount and cost of chosen packages.
   *
   * When the user moves onto the next page from the packages page or booking page,
   * the variables are set to 0 and the packages, if found, are iterated through to collect their data.
   */
  getCartPackagesData(){
    let buttonTarget;
    
    if (this.isPackagesPage && document.querySelector('a.search-btn.btn-primary')) {
      buttonTarget = document.querySelector('a.search-btn.btn-primary');
    } else if (this.isBookingPage && document.querySelector('button.submit.btn-primary.confirm-booking').getBoundingClientRect().x) {
      buttonTarget = document.querySelector('button.submit.btn-primary.confirm-booking');
    } else if (this.isBookingPage && document.querySelector('button.submit.btn-primary.hold-rate-msg').getBoundingClientRect().x) {
      buttonTarget = document.querySelector('button.submit.btn-primary.hold-rate-msg');
    }

    if (buttonTarget) {
      let digitalDataPackages = window.digitalData.packages;
      let oldPackagesVal = digitalDataPackages.packageCartVal ? digitalDataPackages.packageCartVal : 0;
      buttonTarget.addEventListener('click', () => {
        digitalDataPackages.packageCartVal = 0;
        digitalDataPackages.packageCartQuantity = 0;
        let addedPackages = getSessionStorage('added-package') || {};
        $.each(addedPackages, (counter, addedpackage)=>{
          digitalDataPackages.packageCartVal += (PackageAddon.getAllNightTotalPrice(addedpackage.packageAddons,addedpackage.price.amountAfterTax,addedpackage.qty));
          digitalDataPackages.packageCartQuantity += parseInt(addedpackage.qty);
        });
        if (this.isBookingPage && !this.isPackagesPage && oldPackagesVal > digitalDataPackages.packageCartVal) {
          //if the user removes any packages on the booking page, the original packages cost is removed from the totalInclusive value
          window.digitalData.booking.bookingInfo.totalInclusive = (parseFloat(window.digitalData.booking.bookingInfo.totalInclusive) - parseFloat(oldPackagesVal)).toFixed(2);
          //then, the new packages cost is added to the totalInclusive value
          window.digitalData.booking.bookingInfo.totalInclusive = (parseFloat(window.digitalData.booking.bookingInfo.totalInclusive) + parseFloat(digitalDataPackages.packageCartVal)).toFixed(2);
        }
        digitalDataPackages.packageCartVal = parseFloat(digitalDataPackages.packageCartVal).toFixed(2);
      });
    }
  }

  /**
   * Sends the analytics data using satellite tracker.
   *
   * This method sends the tracked data to the analytics service, which processes the data and provides insights based on it.
   *
   * @param {string} directCall - The name of the direct call.
   */
  satelliteTracker(directCall) {
    if (window._satellite && directCall) {
      window._satellite.track(directCall);
    }
  }

  /**
   * Initializes the class instance by setting up required events and configurations.
   *
   * This method checks if the current page is a packages page. If so, it sets up the mutation observer
   * and attaches click events to relevant elements.
   */
  init() {
    if (this.isPackagesPage) {
      this.attachButtonClickEvent();
      this.attachAddPackageToBookingEvent();
    }
    if (this.isBookingPage || this.isPackagesPage) {
      this.attachCartSummaryDetailsClickEvent();
      this.attachCartSummaryRemovePackageClickEvent();
      this.getCartPackagesData();
    }
  }
}

// Instantiate the PackagesAddonsAnalytics class and set up event listeners
let packagesAddonsAnalytics = new PackagesAddonsAnalytics();
packagesAddonsAnalytics.init();
