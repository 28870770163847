
import { isPublish } from '../../base/vars.js';
class Spinner {
  generateTemplate(overlay, fixed) {
    let classes = 'spinner-container';

    if (overlay) {
      classes += ' spinner-overlay';
    }

    if (fixed) {
      classes += ' spinner-fixed';
    }

    return (
      '<div class="' + classes + '">' +
      '<div class="spinner"></div>' +
      '</div>'
    );
  }
  prependTo(el, overlay, fixed) {
    if (this.isAllowed()) {
      $(el).prepend(this.generateTemplate(overlay, fixed));
    }
  }
  appendTo(el, overlay, fixed) {
    if (this.isAllowed() && $(el).children('.spinner-container').length == 0) {
      $(el).append(this.generateTemplate(overlay, fixed));
    }
  }
  remove(el) {
    if (el) {
      $(el).find('.spinner-container')
        .remove();
    } else {
      $('.spinner-container').remove();
    }
  }
  isAllowed() {
    return isPublish || !$('html').hasClass('edit');
  }
}

export default new Spinner();
