import {
  exists,
  getCSSPropertyAsInt,
  getDistanceScrolled,
  scrollToAnimated,
  stickify,
  ResizeHandler,
  isMobileWidth
} from '../base/dom-utils.js';
import { getHeaderHeight, EventHandler } from '../base/utils.js';
import {
  $_BOOKING_BAR_MAIN_,
  $_BOOKING_BAR_MINI_NAV,
  $_FOOTER_,
  $_PAGE_
} from '../base/vars.js';

class LeftNav {
  constructor() {
    this.$leftNav = $('.left-nav');

    if (exists('.left-nav-container')) {
      EventHandler.one(EventHandler.serviceCall.end, () => {
        this.disableStick();
        this.enableStick();
      });

      ResizeHandler.addResizeEndFn(() => {
        this.disableStick();
        this.enableStick();
      });
    }
  }
  disableStick() {
    this.resetLeftNavPosition();
    $(window).off('scroll', () => this.handleStickyLeftNav());
  }
  enableStick() {
    this.activateScrollspy();
    this.setStickyOptions();

    if (isMobileWidth()) {
      this.$leftNav.width('100%');
    } else {
      this.$leftNav.width($('.left-nav-container').width());
    }

    $(window).scroll(() => this.handleStickyLeftNav());
  }
  activateScrollspy() {
    let offset = getHeaderHeight();
    if (exists($('#bookingBarHeight'))) {
      offset += $('#bookingBarHeight').outerHeight();
    }

    $('body').scrollspy({
      target: '.bs-docs-sidebar',
      offset: offset + 20
    });

    $('.bs-docs-sidebar a, .all-ame-link a, .all-ame-link-mobile').click((e) => {
      e.preventDefault();

      let id = $(e.currentTarget).attr('href'),
        $target = exists($(id)) ? $(id) : null,
        mobileOffset = 0;

      if (exists('.property-page')) {
        let alertHeight = 0;
        if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
          alertHeight = $('.alert-component').height();
        }
        mobileOffset += alertHeight;
        if (isMobileWidth()) {
          mobileOffset += $('#bookingBar__mini').outerHeight();
        }
      }

      if (exists($target)) {
        scrollToAnimated($target.offset().top - (offset + mobileOffset));
      }

      if($(e.currentTarget).closest("ul").hasClass('nav-stacked')) {
        var ele = e.currentTarget;
        setTimeout(function(){
          $('#sidebar li').removeClass('active');
          $(ele).closest('li').addClass('active');    
        }, 500);
      }

      if($(e.currentTarget).closest('p').hasClass('all-ame-link')) {
        let id = $(e.currentTarget).attr('href');
        setTimeout(function(){
          $('#sidebar li').removeClass('active');
          $('#sidebar li a[href="' + id + '"]').parent('li').addClass('active');
        }, 500);
      }
    });

    if (isMobileWidth()) {
      $('.bs-docs-sidebar').on('activate.bs.scrollspy', (e) => {
        $('.left-nav-mobile__header p').html($(e.target).find('a')
          .html());
      });
    }
    $('body').on('touchmove', () => {
      if ($('.left-nav-mobile .navbar-header__trigger').attr('aria-expanded') == 'true') {
        $('.navbar-header__trigger').trigger('click');
      }
    });
  }

  setStickyOptions() {
    let $elOffset = getHeaderHeight(),

      opts = {
        end: $_FOOTER_.position().top,
        stick: {},
        unstick: {}
      };

    if (isMobileWidth()) {
      let autoclose = null;

      this.$leftNav = $('nav.left-nav-mobile');
      this.resetLeftNavPosition();
      opts.start = this.$leftNav.offset().top;
      opts.endOnEl = null;

      opts.onStick = () => {
        this.$leftNav.css({
          top: 0
        });
      };
      opts.onStuck = () => {
        let dir = $(window).scrollTop() - getDistanceScrolled(), // < 0 is up; > 0 is down
          $trigger = this.$leftNav.find('a'),
          $leftNav = this.$leftNav;

        function reveal() {
          if (isMobileWidth() && !exists($('.mini-booking-nav .no-bookings-message-component')))
            $leftNav.css({
              top: $_BOOKING_BAR_MINI_NAV.outerHeight()
            });

          if ($trigger.is('[aria-expanded=true]')) {
            $trigger.click();
          }
        }

        function hide() {
          clearTimeout(autoclose);
          autoclose = null;

          $leftNav.css({
            top: 0
          });

          if ($trigger.is('[aria-expanded=true]')) {
            $trigger.click();
          }
        }

        if (dir < 0) {
          reveal();

          clearTimeout(autoclose);
          autoclose = null;
          autoclose = setTimeout(() => {
            if ($trigger.is('[aria-expanded=false]')) {
              hide();
            }
          }, 3000);
        } else if (dir > 0) {
          hide();
        }
      };
      opts.onUnstick = () => {
        this.resetLeftNavPosition();
      };
    } else {
      this.$leftNav = $('.left-nav');
      let alertHeight = 0;

      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height();
      }

      $elOffset += alertHeight;
      $elOffset += getCSSPropertyAsInt($_BOOKING_BAR_MAIN_, 'font-size');
      $elOffset += !$_PAGE_.is('.property-page') ? $_BOOKING_BAR_MAIN_.outerHeight() : 0;
      opts.start = this.$leftNav.parent().offset().top - $elOffset;
      opts.endOnEl = $_FOOTER_;

      opts.stick.css = {
        top: $elOffset,
        width: this.$leftNav.width(),
        height: this.$leftNav.height()
      };

      opts.atTop = () => {
        this.$leftNav.css({
          top: 'auto'
        });
      };
      opts.atBottom = () => {
        this.$leftNav.css({
          top: opts.end - opts.start,
          position: 'absolute'
        });
      };
      opts.onUnstick = () => {
        this.resetLeftNavPosition();
      };
    }

    this.stickyOpts = opts;
    this.stickyOpts.$el = this.$leftNav;

    this.handleStickyLeftNav();
  }
  resetLeftNavPosition() {
    this.$leftNav.removeClass('stick sticky stuck').removeAttr('style');
  }
  handleStickyLeftNav() {
    stickify(this.stickyOpts.$el, this.stickyOpts);
  }
}

export default new LeftNav();
