import { exists } from '../base/dom-utils.js';
import { UserHandler } from '../base/session-handler.js';
import { EventHandler, CookieHandler, getJsonObject } from '../base/utils.js';
import { requestUrls } from './../base/vars.js';
class RewardsPromotion {
  constructor() {
    if (exists('.rewdregpromo-container')) {
      this._isWRLogIn = false;
      EventHandler.one(EventHandler.criteria.init, () => {
        this.init();
      });
    }
  }

  init() {
    //Initializing all the variables
    this.regSignIn = $('.stepone');
    this.bookNowModule = $('.booknowmodule');
    this.registerwrmodule = $('.registerwrmodule');
    this.postsignin = $('.postsigninmodule');
    this.successModule = $('.successmodule');
    this.alrRegModule = $('.existingmodule');
    //Parsley Guest form
    let parsleyConfig = {
      errorsContainer: () => {
        let $err = $('.promoreg-postsin-error');
        return $err;
      }
    };

    $('.promoreg-guestform').parsley(parsleyConfig);
    //User's login status
    this._isWRLogIn = UserHandler.isWRLoggedIn() || CookieHandler.cidAuthenticated() === 'true';
    if (this._isWRLogIn) {
      //execute the login function
      EventHandler.one(EventHandler.fullProfile.fetched, () => this.regLoggedUser());
    } else {
      //execute the non logged in function
      this.regGuestUser();
    }
  }

  regLoggedUser(promocode, parent) {
    let modShow = [this.postsignin];
    let modHide = [this.regSignIn, this.bookNowModule, this.registerwrmodule, this.alrRegModule];
    this.showModules(modShow);
    this.hideModules(modHide);
    $('.promoreg-postsign').on('click', (e) => {
      e.preventDefault();
      //Get promo code from the anchor data attr and get the user info from user object
      let promocode = $(e.currentTarget).data('promocode');
      let parent = $(e.currentTarget).parents()
        .find('.postsigninmodule');
      let userinfo = UserHandler.getWRUserInfo();
      if (userinfo != null && promocode != undefined) {
        let reqData = `accountNumber=${userinfo.wrNo}&authenticated=Y&email=${userinfo.email}&phoneNumber=${userinfo.phone}&promotionCode=${promocode}`;
        this.executeReg(reqData, parent);
      }
    });
  }

  regGuestUser() {
    //Just use the promotion id and member number thats entered
    let modShow = [this.regSignIn, this.bookNowModule, this.registerwrmodule];
    let modHide = [this.postsignin, this.successModule, this.alrRegModule];
    this.showModules(modShow);
    this.hideModules(modHide);
    //Serialize the form data and append authorized false
    $('form.promoreg-guestform').submit((e) => {
      e.preventDefault();
      let reqData = $(e.currentTarget).serialize() + '&authenticated=Y';
      this.executeReg(reqData, $(e.currentTarget));
    });
  }

  getUrlVars(url) {
    var vars = [], hash;
    var hashes = url.split('&');
    for(var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
  }

  executeReg(reqData, parent) {
    let errorCnt = parent.find('.promoreg-postsin-error');
    errorCnt.html('');
    if (!errorCnt.hasClass('hidden')) {
      errorCnt.addClass('hidden');
    }

    let accountNumber = '';
    if(window.User && window.User.accountNumber) {
      accountNumber = window.User.accountNumber;
    } else if(window.User && window.User.membershipId) {
      accountNumber = window.User.membershipId;
    } else {
      console.error('AccountNumber not found in user or user sessionInfo while calling registerPromotion api');
    }
    let promoCodeStr = this.getUrlVars(reqData);
    let promoCode = reqData.promotionCode ? reqData.promotionCode : promoCodeStr['promotionCode'];
    // DAI-37035 : Register for Promotion on deals-page
    let requestData = JSON.stringify({
      promotionCode: promoCode,
      memberIdentifier: accountNumber
    });
    let url = requestUrls['registerPromotion'] ? requestUrls['registerPromotion'] : '/WHGServices/loyalty/V4/member/promotionRegister';
    if(promoCode) {
      $.ajax({
        type: 'POST',
        url: url,
        data: requestData,
        contentType: 'application/json',
        dataType: 'json',
        success: (data) => {
          if (data && data.success) {
            //Registration successful.
            EventHandler.triggerEvent('promo_register_complete');
            this.showSuccess();
          } else if (data && data.Status === 'Error') {
            //Wrong member number.
            let errorCnt = parent.find('.promoreg-postsin-error');
            //Hardcoded message for now because of late notice from business
            let errmsg = errorCnt.data('invalidmember');
            errorCnt.html(errmsg);
            if (errorCnt.hasClass('hidden')) {
              errorCnt.removeClass('hidden');
            }
            this.scrollToError();
          }
        },
        error: (errData) => {
          EventHandler.triggerEvent('brands-error', errData);
          const err = getJsonObject(errData);
          let data = err.responseJSON;
          if (data.ErrorMessage !== undefined) {
            let errmsg = data.ErrorMessage;
            if (data !== null && data.ErrorCode === '1007' && errmsg === "Good news: You're already registered!") {
              //Member already registered
              this.showAlreadyReg();
              EventHandler.triggerEvent('promo_already_registered');
            } else {
              var errorCnt = parent.find('.promoreg-postsin-error');
              errorCnt.html(DOMPurify.sanitize(errmsg));
              if (errorCnt.hasClass('hidden')) {
                errorCnt.removeClass('hidden');
              }
              this.scrollToError();
            }
          }
        }
      });
    }
  }

  showSuccess() {
    let modShow = [this.successModule];
    let modHide = [this.regSignIn, this.bookNowModule, this.registerwrmodule, this.postsignin, this.alrRegModule];
    this.showModules(modShow);
    this.hideModules(modHide);
  }
  showAlreadyReg() {
    let modShow = [this.alrRegModule];
    let modHide = [this.regSignIn, this.bookNowModule, this.registerwrmodule, this.postsignin, this.successModule];
    this.showModules(modShow);
    this.hideModules(modHide);
  }
  showModules(modules) {
    for (let i = 0; i < modules.length; i++) {
      let mod = modules[i];
      if (mod.hasClass('hidden')) {
        mod.removeClass('hidden');
      }
    }
  }
  scrollToError() {
    $('html, body').animate({
      scrollTop: $('.promoreg-error:not(.hidden)').offset().top - 300
    }, 500);
  }
  hideModules(modules) {
    for (let i = 0; i < modules.length; i++) {
      let mod = modules[i];
      if (!mod.hasClass('hidden')) {
        mod.addClass('hidden');
      }
    }
  }
}

export default new RewardsPromotion();
