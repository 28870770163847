/* eslint-disable camelcase */
import { formatDateForG360,getLogLocale ,CookieHandler} from './utils.js';
import ConfigsUtils from './aem-configs/config-utils.js';
import { UserHandler, getCriteria } from './session-handler.js';

import {
  _LOCALE_,
  _defaultChannelId,
  $_PAGE_,
  REFRESH_TOKEN,
  ACCESS_TOKEN,
  LOGLOCALE_COOKIE
} from './vars.js';

class G360 {
  constructor() {
    this.$0 = $('.need-more-rooms');
    this.$1 = $('.link-srfp');

    this.$0.on('click', (e) => {
      e.preventDefault();
      this.redirectToG360();
    });
    this.$1.on('click', (e) => {
      e.preventDefault();
      this.redirectToG360();
    });
  }

  redirectToG360() {
    this._params = $.extend(true, {}, getCriteria());
    let url = this.handleG360URL() || '';
    let tenantID = this.handleG360TenantID() || '';
    let arrival_date = formatDateForG360($('#from').val() || this._params.checkInDate);
    let departure_date = formatDateForG360($('#to').val() || this._params.checkOutDate);
    let placeId = this._params.placeId || this._params.loc_temp || this._params.loc; // Place id stored
    let numOfRooms = $('#numOfRooms').val() || this._params.rooms;

    if (url != '') {
      if ($_PAGE_.is(".homepage") || $_PAGE_.is(".homepage-template")) {
        if (placeId && $('.booking-bar-form .destination').val()) {
          this.handleOutbound(url ,
            { tenant_config_id: tenantID,
              destination:placeId,
              arrival_date: arrival_date,
              departure_date: departure_date,
              room_count: numOfRooms,
            });
        } else {
          this.handleOutbound(url,{tenant_config_id: tenantID});
        } 
      } else if ($_PAGE_.is(".search-results-page")) {
        if (placeId) {
          this.handleOutbound(url ,
            { tenant_config_id: tenantID,
              destination:placeId,
              arrival_date: arrival_date,
              departure_date: departure_date,
              room_count: numOfRooms,
            });
        } else {
          this.handleOutbound(url ,{ tenant_config_id: tenantID});
        }
      } else if ($_PAGE_.is(".rooms-rates-page") || $_PAGE_.is(".property-page") || $_PAGE_.is(".packages-page")) {
        if (this._params.propertyId) {
          this.handleOutbound(url ,
            { tenant_config_id: tenantID,
              hotel_code:this._params.propertyId,
              arrival_date: arrival_date,
              departure_date: departure_date,
              room_count: numOfRooms,
            });
        }

      } else {
        this.handleOutbound(url ,{ tenant_config_id: tenantID});
      }
    }
    console.log(url, tenantID, this._params, formatDateForG360(this._params.checkInDate));
  }

  handleOutbound(url , param, openInNewTab = true) {
    // opened the new tab right away to avoid pop-up blocker by the browser
    let newWindow, actualDocument;
    if (openInNewTab) {
      newWindow = window.open();
      actualDocument = newWindow.document;
    } else {
      actualDocument = window.document;
    }

    let formContainer = actualDocument.createElement('div');
    var returnURL = url;

    let form = actualDocument.createElement('form');
    form.action = returnURL;
    form.method = 'post';
    form.classList.add('not-ajax');
    for (let key in param) {
      if (key !== '') {
        this.addInput(form, key, param[key],actualDocument);
      }
    }

    if (UserHandler.isWRLoggedIn()) {
      const idToken = UserHandler.getIdToken();
      const accessToken = CookieHandler.readCookie(ACCESS_TOKEN);
      const refreshToken = CookieHandler.readCookie(REFRESH_TOKEN);
      const logLocale = getLogLocale();

      // Add hidden inputs to form
      this.addInput(form, 'id_token', idToken,actualDocument);
      this.addInput(form, REFRESH_TOKEN, refreshToken,actualDocument);
      this.addInput(form, ACCESS_TOKEN, accessToken,actualDocument);
      this.addInput(form, LOGLOCALE_COOKIE, JSON.stringify(logLocale),actualDocument);
    }
    let sanitizedFormHtml = DOMPurify.sanitize(form.outerHTML);

    // Set sanitized form HTML
    formContainer.innerHTML = sanitizedFormHtml;

    actualDocument.body.appendChild(formContainer);

    // Submit
    formContainer.firstChild.submit();

    // Remove the appended Form as the form might be submitted in a new tab.
    actualDocument.body.removeChild(formContainer);

  }

  addInput(form, key, value, actualDocument) {
    let inputContainer = actualDocument.createElement('div');

    let input = actualDocument.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;

    let sanitizedInputHtml = DOMPurify.sanitize(input.outerHTML);
    inputContainer.innerHTML = sanitizedInputHtml;

    form.appendChild(inputContainer);
  }


  handleG360URL() {
    return ConfigsUtils.getConfigs('g360-configuration', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce((result, item) => {
      return (item.name === 'url' && item.value) ? item.value : result;
    }, null);
  }

  handleG360TenantID() {
    return ConfigsUtils.getConfigs('g360-configuration', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce((result, item) => {
      return (item.name === 'tenant_config_id' && item.value) ? item.value : result;
    }, null);
  }

}

export default G360;


let g360 = new G360();

export {
  g360,
};
