import {
  exists,
  isMobileWidth
} from '../base/dom-utils.js';
import { handleOverviewMetaTag } from '../base/seo.js';
import {
  getCriteria,
  setSearchOverview,
  removeSearchOverview,
  getPropertyAvailability,
  setCriteria,
  setPropertyAvailability,
  getBrand,
  UserHandler,
  isWRCCHolder
} from '../base/session-handler.js';
import Thinking from '../base/thinking.js';
import {
  _isNotNull,
  formatDateForBWSAvailability,
  formatTimeShow,
  replaceToken,
  getServiceUrl,
  callService,
  setUNAP,
  EventHandler,
  RetailBannerUtils,
  CookieHandler,
  enableNewRenovatedPriceTag,
  isNotificationEnable
} from '../base/utils.js';
import {
  $_PAGE_,
  $_BOOKING_BAR_MINI_NAV,
  _LOCALE_,
  domainUrl,
  isPublish,
  overview_lat,
  overview_long,
  overview_orsId,
  overview_propertyId,
  hotel_check_in,
  hotel_check_out,
  key_amenities,
  children_stay_free_Age,
  children_stay_free_No_Age,
  smoking_policy_no,
  smoking_policy_yes,
  additionalCorpCodes,
  memberRateCorpCodes,
  property_country_code,
  property_new,
  property_renovated,
  newRenovate_startDate,
  newRenovate_enddate,
  newRenovate_year,
  wrccCorpCodes,
  everGreenRateCodes
} from '../base/vars.js';
import Analytics from '../base/wyn-analytics-module.js';
import { lazyLoadBackgroundCarousel, lazyLoadEconomyCarousel } from '../base/lazy-load.js';
import FeaturedRoomsRates from '../components/featured-rooms-rates.js';
import FeaturedAmenityIcons from "../components/featured-amenity-icons.js";
import RenderFavourite from '../components/render-favourite.js';
import BadgeExternalized from "../components/badge-externalized.js";

class PropertyOverview {
  constructor(propPage) {
    this.PropertyPage = propPage;
    this.PropertyPageRates = this.PropertyPage.PropertyPageRates;
    this.PropertyPagePolicies = this.PropertyPage.PropertyPagePolicies;
    this.data = {};
    this.amenityIconsMapData = {};
    if ($_PAGE_.is('.property-page')) {
      const params = location.search.slice(1).split('&').reduce((acc, s) => {
        const [k, v] = s.split('=');
        return Object.assign(acc, {[k]: v});
      }, {});

      if(params.childAge){
          window.digitalData.search.searchInfo.age = params.childAge;
      } else {
          window.digitalData.search.searchInfo.age = "";
      }
      if (exists('#uuCarousel')) {
        let totalItemLength = $('#uuCarousel .item').length;

        if (totalItemLength > 1) {
          $('.carousel-number').removeClass('hidden');
          let currentItem = $('#uuCarousel .item.active').data("index") + 1;
          $('.total-carousel-items').html(totalItemLength);
          $('.current-carousel-item').html(currentItem);
          let lastEle = totalItemLength - 1;
          if(exists('.uu-property')) {
            lazyLoadBackgroundCarousel($('#uuCarousel .item[data-index=0]').find('canvas.pll-background'));
            lazyLoadBackgroundCarousel($('#uuCarousel .item[data-index=' + currentItem + ']').find('canvas.pll-background'));
            lazyLoadBackgroundCarousel($('#uuCarousel .item[data-index=' + lastEle + ']').find('canvas.pll-background'));
            $('#uuCarousel .item[data-index=0]').find('img').attr("itemprop","image");
          } else {
            lazyLoadEconomyCarousel($('#uuCarousel .item[data-index=0]').find('canvas.pll-image'));
            lazyLoadEconomyCarousel($('#uuCarousel .item[data-index=' + currentItem + ']').find('canvas.pll-image'));
            lazyLoadEconomyCarousel($('#uuCarousel .item[data-index=' + lastEle + ']').find('canvas.pll-image'));
            $('#uuCarousel .item[data-index=0]').find('img').attr("itemprop","image");
          }

          $('#uuCarousel').bind('slide.bs.carousel', function(e) {
            if(e.direction == "left"){
              $('.carousel-dots-container').addClass("bb-next-dots");
            }
            if(e.direction == "right"){
              $('.carousel-dots-container').addClass("bb-prev-dots");
            }
          });
          $('#uuCarousel').bind('slid.bs.carousel', function(e){
            currentItem = $('#uuCarousel .item.active').data("index") + 1;
            if(e.direction == "left"){
              $('.carousel-dots-container').removeClass("bb-next-dots");
              if(exists('.uu-property')) {
                lazyLoadBackgroundCarousel($('#uuCarousel .item[data-index=' + currentItem + ']').find('canvas.pll-background'));
              } else {
                lazyLoadEconomyCarousel($('#uuCarousel .item[data-index=' + currentItem + ']').find('canvas.pll-image'));
              }

            }
            if(e.direction == "right"){
              $('.carousel-dots-container').removeClass("bb-prev-dots");
              let prevItem = currentItem - 2;
              if(exists('.uu-property')) {
                lazyLoadBackgroundCarousel($('#uuCarousel .item[data-index=' + prevItem + ']').find('canvas.pll-background'));
              } else {
                lazyLoadEconomyCarousel($('#uuCarousel .item[data-index=' + prevItem + ']').find('canvas.pll-image'));
              }

            }

            $('.total-carousel-items').html(totalItemLength);
            $('.current-carousel-item').html(currentItem);

          });
        }

        // This needs to be set when at least one image is there on the page
        if(totalItemLength) {
          this.setPropertyImgToDatalayer();
        }

        $('.uu-hero-carousel .item').on("click", function(e) {
          e.preventDefault();
          var el = e.currentTarget; // Get target image ID

          var imageId = $(el).find('canvas.pll-background').data('gallery-id'),
            galId = $(el).find('canvas.pll-background').data('target'),
            carouselId = $('' + galId).find('.carousel').attr('id'),
            imageIndex = 0; // Iterate carousel to check for target image.

          $("#".concat(carouselId, " .item")).each(function(index, item) {
            // If present, rotate carousel to target image. If not, open at default location
            if ($(item).attr('id') === imageId) {
              imageIndex = index;
            }

            $("#".concat(carouselId)).carousel(imageIndex);
            $("#".concat(carouselId)).data('start-pos', imageIndex);
          });
        });
      }

      $('.carousel').carousel({
        swipe: true
      });
      if(!isMobileWidth()) {
        $('.item img.pll-image-full-size, .item canvas.pll-background, .item div.pll-background-full-size').removeAttr("data-target");
      }
      removeSearchOverview();
      let isbadge = $('.property-badge-inclusion-component');
      if(exists('.uu-property')) {
        if(isbadge && isbadge.length > 0) {
          $('.new-property-label').css({'position':'relative','bottom':'-55px','margin-bottom':'10px','height':'22px'});
          } else {
          $('.new-property-label').css({'position':'relative','margin-top':'40px','bottom':'-15px'});
        }
      }

      if (exists('.notification')) {
        if(isNotificationEnable($('.notification'))) {
          $('.notification').removeClass('hidden');
        }
      }

      if(property_new && property_new === 'true') {
        let startDate = newRenovate_startDate ? decodeURIComponent(newRenovate_startDate) : '',
          endDate = newRenovate_enddate ? decodeURIComponent(newRenovate_enddate) : '';
        if(startDate && endDate && enableNewRenovatedPriceTag(startDate, endDate)) {
          $('.new-property-label').removeClass('hidden');
          $('.property-page .newly-opened').removeClass('hidden');
          if (newRenovate_year) {
            $('.property-page .newly-opened .new-renovate-year').html(' ' + newRenovate_year);
          }
        }
      }
      if(property_renovated && property_renovated === 'true') {
        let startDate = newRenovate_startDate ? decodeURIComponent(newRenovate_startDate) : '',
          endDate = newRenovate_enddate ? decodeURIComponent(newRenovate_enddate) : '';
        if(startDate && endDate && enableNewRenovatedPriceTag(startDate, endDate)) {
          $('.new-property-label').removeClass('hidden');
          $('.property-page .renovated').removeClass('hidden');
          if (newRenovate_year) {
            $('.property-page .renovated .new-renovate-year').html(' ' + newRenovate_year);
          }
        }
      }
      if(exists('.uu-property')) {
        if($('.new-property-label') &&  $('.new-property-label').length > 0){
          $('.uu-property .property-page-intro-component').css('padding-top','0');
          } else {
          $('.uu-property .property-page-intro-component').css('padding-top','15px');
        }
      }
      // if(isPriceTag && isPriceTag === 'true') {
      //   let startDate = priceTag_startDate ? decodeURIComponent(priceTag_startDate) : '',
      //     endDate = priceTag_enddate ? decodeURIComponent(priceTag_enddate) : '';
      //   if(startDate && endDate && enableNewRenovatedPriceTag(startDate, endDate)) {
      //     $('.property-page .price-badge').removeClass('hidden');
      //     $('.property-page .mini-booking-price-badge').removeClass('hidden');
      //   }
      // }
    }

  }

  init() {
    let params = {};
    params.propertyId = this.PropertyPage._params.propertyId || overview_propertyId; //to be fetched from ui
    params.isOverviewNeeded = 'true';
    params.isAmenitiesNeeded = 'true';
    params.channelId = 'tab';
    params.language = _LOCALE_;
    params.isRoomAmenitiesNeeded = FeaturedRoomsRates.componentExist();
    this.fetchOverview('overview', params);
    Analytics.updateSearchBrandValue();
    const propertyHeroEl = document.querySelector('.property-hero');
    const uuHeroEl = document.querySelector('.uu-hero-carousel #uuCarousel');
    if(propertyHeroEl) {
      RenderFavourite.fetchFavourite(propertyHeroEl, overview_propertyId, 'true');
    } else if (uuHeroEl && uuHeroEl.children.length) {
      RenderFavourite.fetchFavourite(uuHeroEl, overview_propertyId, 'true');
    }
  }

  setFieldsToDatalayer(value, flag) {
    window.digitalData.property.propertyInfo.propertyImg = value;
    window.digitalData.property.propertyInfo.propertyImgDefault = flag;
  }

  setSrcToDatalayer(src) {
    if(src){
      // Check if the image is the default image then add nothing to the data layer
      if(src.includes('generic')) {
        this.setFieldsToDatalayer('', true);
      } else {
        this.setFieldsToDatalayer(src, false);
      }
    } else {
      this.setFieldsToDatalayer('', false);
    }
  }

  setPropertyImgToDatalayer() {
    const propertyImgElem = document.querySelector(".property-page .property-hero .carousel .item .pll-image-container > canvas");
    const rePropertyImgElem = document.querySelector(".property-page .property-page-hero-carousel-component .carousel .item .pll-background-wrapper > .pll-background");
    if(propertyImgElem){
      const propertyImgSrc = JSON.parse(propertyImgElem.getAttribute("data-info"));
      const propertyImgScrPathVal = propertyImgSrc.sd.s;
      this.setSrcToDatalayer(propertyImgScrPathVal);
    } else if(rePropertyImgElem){
      const rePropertyImgSrc = JSON.parse(rePropertyImgElem.getAttribute("data-info"));
      const rePropertyImgSrcPathVal = rePropertyImgSrc.sd.s;
      this.setSrcToDatalayer(rePropertyImgSrcPathVal);
    } else {
      this.setFieldsToDatalayer('', false);
    }
  }

  fetchOverview(url, params) {
    EventHandler.send(EventHandler.property.search.start);
    callService(url, params).then((res) => {
      if (res && res.status === 'OK') {
        setSearchOverview(res.properties[0], true);
        this.data = res.properties[0];
        Analytics.updatePropTierNum(this.data.TierNum);
        this.populateOverviewView();
        if (this.PropertyPage._params.useWRPoints === true) {
          this.PropertyPageRates.init();
        }
        this.PropertyPagePolicies.init(this.data.hotelPolicies, this.data);
        let policyListItem = $('#hotel-policies ul li');
        let checkInTime = $('.form-label.Check-In.Time');
        let checkOutTime = $('.form-label.Check-Out.Time');
        for (let i = 0; i < 2; ++i) {
          $(policyListItem[i]).addClass('policy-number-' + i + ' hotel-policy-li');
        }
        if (typeof hotel_check_in !== 'undefined') {
          $(checkInTime).text(hotel_check_in);
        }
        if (typeof hotel_check_out !== 'undefined') {
          $(checkOutTime).text(hotel_check_out);
        }
        $('li.hotel-policy-li:nth-child(2)').after('<div class="hotel-policies__divider-line hidden-xs"></div>');
        if (typeof key_amenities !== 'undefined') {
          $('div.hotel-policies__divider-line').after('<p class="form-label hotel-policies-amenities__title">' + key_amenities + '</p>');
        }
        //AmenityList
        if (exists('.amenity-list') || exists('.hotel-policies-amenities__list')) {
          this.appendAmenities(res);
        }

        if (typeof res.properties !== 'undefined' && res.properties.length) {
          EventHandler.send(EventHandler.property.search.success, null, res.properties[0]);
        }

        if (exists('.badge-externalized')) {
          const siteLanguage = document.getElementsByTagName('html')[0].getAttribute('lang');
          let amenitiesShortCodeArr = [];
          const amenitiesShortCode = this.data && this.data.otherAmenities && this.data.otherAmenities.length && this.data.otherAmenities[0].groupDetails ? this.data.otherAmenities[0].groupDetails : [];
          if(amenitiesShortCode.length > 0) {
            amenitiesShortCode.forEach((item)=> {
              amenitiesShortCodeArr.push(item.amenityName);
            });

            BadgeExternalized.getAmenitiesRanking(amenitiesShortCodeArr, siteLanguage).then((data) => {
              const badgeExternalizedElem = document.querySelector('.badge-externalized');
              if(badgeExternalizedElem){
                let badgeContent = '';
                const badgeExternalizedContent = badgeExternalizedElem.querySelector('.badge-externalized-content');
                data && data.amenities && data.amenities.length > 0 && Array.isArray(data.amenities) && data.amenities.forEach((amimg) => {
                  badgeContent += `<li><a data-toggle="modal" href="#badge-modal" class="badge-externalize-modal"><img src="${amimg.imagePath}" alt="${amimg.amenityName}" class="badge-externalized-img lazy-load-pageload" /></a><span class="badge-externalized-description">${amimg.description}</div></li>`;
                });
                badgeExternalizedContent.querySelector('ul').innerHTML = badgeContent;
                BadgeExternalized.badgeExternalizedClick();
              }
            });
          }
        }

      } else {
        console.log('Property not found!');
        EventHandler.triggerEvent('brands-error', res);
        EventHandler.send(EventHandler.property.search.error);
      }
    });
  }
  populateOverviewView() {
    let phoneNumber = this.data.phone1;
    if(property_country_code && (property_country_code === 'CN' || property_country_code === 'TW' || property_country_code === 'HK' || property_country_code === 'MO')) {
      if(phoneNumber.indexOf('+') !== 0) {
        phoneNumber = '+' + phoneNumber;
      }
    }
    setUNAP({
      name: this.data.hotelName,
      address: this.data.address1 + (this.data.address2 ? ' ' + this.data.address2 : '') + (this.data.address3 ? ' ' + this.data.address3 : ''),
      city: this.data.city,
      state: this.data.state,
      stateCode: this.data.stateCode,
      country: this.data.country,
      postalCode: this.data.postalCode,
      phone: phoneNumber
    });

    if (isMobileWidth() && exists('#uuCarousel') && exists('.mob-prop-details')) {
      let alertHeight = 0;
      if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        alertHeight = $('.alert-component').height();
      }
      let mobileaddrtop = alertHeight + $('#uuCarousel').height() + $('.uu-umbrella-promo-banner').outerHeight() + $('.mob-prop-details').outerHeight(true) + $('#pageHeader').height();
      $_BOOKING_BAR_MINI_NAV.parent().parent()
        .css({
          top: mobileaddrtop
        });
    }

    $('.reviews-link-policies').on('click', () => {
      $('html, body').animate({
        scrollTop: $('.trip-advisor-iframe').offset().top - 80
      }, 400);
    });
  }

  appendAmenitiesList(amenityDetails) {
    let $amenitiesUL = $('div.hotel-policies-amenities__list');

    if (_isNotNull(amenityDetails) && $amenitiesUL.length) {
      for (let i = 0; i < amenityDetails.length; ++i) {
        let grpDeets = amenityDetails[i].groupDetails;
        let amenityNames = grpDeets[0].amenityName;
        let amenityNamesEn = grpDeets[0].amenityNameEN;
        let amenContainer = '<div class="amenities-section-container ' + amenityNamesEn + '">';

        if (Object.keys(this.amenityIconsMapData).length) {
          let amenityNameProcessed = FeaturedAmenityIcons.getIconKeyFromName(amenityNamesEn),
              amenityIconCSSClass = this.amenityIconsMapData[amenityNameProcessed];

          if(_isNotNull(amenityIconCSSClass)) {
            amenContainer += '<div class="amenity-icon-holder">';
            amenContainer += '<span class="amenities-icon ' + amenityIconCSSClass + ' pull-left">&nbsp;</span>';
            amenContainer += '</div>';
          }
        }

        amenContainer += '<div class="ams-titles-holder" itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification">';
        amenContainer += '<span class="ams-titles" itemprop="name">' + amenityNames + '</span><meta itemprop="value" content="True">';
        amenContainer += '</div>';
        amenContainer += '</div>';

        if (i < 6) {
          $amenitiesUL.append(amenContainer);
        }
      }
    }
  }

  appendAmenities(response) {
    if (response.status.match(/ok/i) &&
      typeof response.properties !== 'undefined' &&
      response.properties.length &&
      response.properties[0].amenitiesDetails &&
      response.properties[0].amenitiesDetails.length
    ) {
      if (exists('.hotel-policies-amenities__list')) {
        // Check for amenities icons mapping
        if (Object.keys(this.amenityIconsMapData).length === 0) {
          let instance = this;
          FeaturedAmenityIcons.getAmenityIconsMap().then((amenitiesMap) => {
            instance.amenityIconsMapData = amenitiesMap;
            instance.appendAmenitiesList(response.properties[0].amenitiesDetails);
          }, () => {
            instance.appendAmenitiesList(response.properties[0].amenitiesDetails);
          });
        } else {
          this.appendAmenitiesList(response.properties[0].amenitiesDetails);
        }

        // Waiting for response to be available
        // Need to review this later, using timeouts for this is not recommended
        // If takes request takes longer to resolve, the code will break.
        if (exists('.property-page')) {
          setTimeout(function() {
            if(exists('.hotel-amenities-ada-component')) {
              let sectionId = $('.hotel-amenities-ada-component .section-title-component.standard-title').attr('id');
              $('.all-ame-link a, .all-ame-link-mobile').attr('href', '#' + sectionId);
            } else if(exists('.hotel-amenities-component')) {
              let sectionId = $('.hotel-amenities-component .section-title-component.standard-title').attr('id');
              $('.all-ame-link a, .all-ame-link-mobile').attr('href', '#' + sectionId);
            }
          }, 500);
        }
      }

      if (exists('.amenity-list')) {
        let amenitiesName = response.properties[0].amenitiesDetails;
        let amenities = [];
        for (let i = 0; i < amenitiesName.length; ++i) {
          amenities.push(amenitiesName[i].groupDetails[0].amenityName);
        }
        amenities.sort();

        let amenitiesLen = amenities.length / 2;

        let amenitiesArray = _.groupBy(amenities, function(element, index) {
          return Math.floor(index / amenitiesLen);
        });

        amenitiesArray = _.toArray(amenitiesArray);
        let leftAmenities = amenitiesArray[0];

        let rightAmenities = amenitiesArray[1];
        $.each(leftAmenities, (index, amenity) => {
          $('.left-amenities').append(
            '<div class="amenity-list-div" itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification"><span itemprop="name">' +
            amenity + '</span><meta itemprop="value" content="True"></div>');
        });

        $.each(rightAmenities, (index, amenity) => {
          $('.right-amenities').append(
            '<div class="amenity-list-div" itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification"><span itemprop="name">' +
            amenity + '</span><meta itemprop="value" content="True"></div>');
        });
      }
    }
  }
}

class PropertyPolicies {
  constructor(propPage) {
    this.data = {};
  }
  init(policyData, overviewData) {
    this.data = policyData;
    this.populatePoliciesView(overviewData);
  }
  populatePoliciesView(overviewData) {
    let ptxt = '',
      policies = this.data,
      pdata = overviewData;

    if (policies && exists('.hotel-policies-list-box')) {
      $('.hotel-policy-wrapper').show();
      let polbox = $('.hotel-policies-list-box').find('ul');

      if (policies.checkInTime != null) {
        $('.check-in-time').show();
        let check_In_Time = formatTimeShow(policies.checkInTime);
        let check_In_Time_periods = check_In_Time.replace(/m/i, '.m.');
        $('.check-in-time-desc').text(check_In_Time_periods);
        ptxt += '<li><span class="form-label ' + 'Check-In Time' + '"></span> <span class="hide-clocks policies-icon icon-' + 'check-in-time' + '">&nbsp;</span><span class="policy-desc headline-h ' + 'Check-In Time' + '">' + (formatTimeShow(policies.checkInTime)) + '</span></li>';
      }
      if (policies.checkOutTime != null) {
        $('.check-out-time').show();
        let check_out_Time = formatTimeShow(policies.checkOutTime);
        let check_out_Time_periods = check_out_Time.replace(/m/i, '.m.');
        $('.check-out-time-desc').text(check_out_Time_periods);
        ptxt += '<li><span class="form-label ' + 'Check-Out Time' + '"></span> <span class="hide-clocks policies-icon icon-' + 'check-out-time' + '">&nbsp;</span><span class="policy-desc headline-h ' + 'Check-Out Time' + '">' + (formatTimeShow(policies.checkOutTime)) + '</span></li>';
      }
      if (policies.childStayFree && policies.childStayFree !== null) {
        $('.children-stay').show();
        (policies.childMaxAge && policies.childMaxAge !== null) ?
          $('.children-stay-desc').text(children_stay_free_Age ? replaceToken(children_stay_free_Age, '${childAge}', policies.childMaxAge) : '') :
          $('.children-stay-desc').text(children_stay_free_No_Age);
      }
      if (policies.petpolicy != null) {
        $('.pet-policy').show();
        $('.pet-policy-desc').text(policies.petpolicy);
      }
      if (policies.smokeFreePolicy && policies.smokeFreePolicy !== null) {
        $('.smoke-free').show();
        if (policies.smokeFreePolicy === 'Y') {
          $('.smoke-free-desc').text(smoking_policy_yes);
        } else {
          $('.smoke-free-desc').text(smoking_policy_no);
        }
      }
      //populate blue alert with policies in the policy section
      if (pdata.alertPriority && pdata.alertMessage && pdata.alertPriority === '3B') {
        $('.hotel-policy-wrapper .alert-msg').show();
        $('.hotel-policy-wrapper .alert-msg-desc').text(pdata.alertMessage);
      }
      $(polbox).append(ptxt);
      let polUlLi = $('#hotel-policies ul li');
      for (let i = 0; i < polUlLi.length; i++) {
        if (i > 1) {
          $(polUlLi[i]).addClass('never-show-me');
        }
      }
    }
  }
}

class PropertyRates {
  constructor(propPage) {
    this.PropertyPage = propPage;
    this.data = {};
    if (overview_propertyId) {
      setCriteria({
        propertyId: overview_propertyId
      });
    }
  }
  init(isReInit) {
    let p = this.PropertyPage._params,
      criteria = getCriteria(),
      params = {};

    params = $.extend(true, {
      "useWRPoints": criteria.useWRPoints,
      "rate_code": criteria.rateCode,
      "corporate_id": criteria.corpCode,
      "ratePlan": criteria.ratePlan,
      "group_code": criteria.groupCode,
      "childAge": criteria.childAge,
      "children": criteria.children,
      "adults": criteria.adults,
      "rooms": criteria.rooms
    }, p);

    //handle SRB
    if (params.useWRPoints === true) {
      //DAI-537: Change to implement the future go free rate plan
      params.rateCode = 'SRB';
    }

    //Handle specially for Wyndham
    if (params.brand_id === 'WY') {
      params.properties = p.brand_id + (p.hotel_id || p.propertyId) + '|' + p.brand_id + p.orsId;
    } else {
      params.properties = p.brand_id + p.propertyId; //to be fetched from ui
    }

    //Add additionalCorpCodes param for mobile view only
    if (isMobileWidth() && _isNotNull(additionalCorpCodes)) {
      params.additionalCorpCodes = additionalCorpCodes;
      if (UserHandler.isWRLoggedIn() && _isNotNull(memberRateCorpCodes)) {
        params.additionalCorpCodes = additionalCorpCodes + '|' + memberRateCorpCodes;
      }
    } else if (UserHandler.isWRLoggedIn() && _isNotNull(memberRateCorpCodes)) {
      params.additionalCorpCodes = memberRateCorpCodes;
    }

    //Add param to include everGreenRate as part of the bestRate Calculation
    if (isMobileWidth() && _isNotNull(everGreenRateCodes)) {
      params.ratePlan = (params.ratePlan) ? params.ratePlan + ',' + everGreenRateCodes : everGreenRateCodes;
    }

    if(isWRCCHolder() && _isNotNull(wrccCorpCodes)) {
      if(params.additionalCorpCodes) {
        params.additionalCorpCodes = params.additionalCorpCodes + '|' + wrccCorpCodes;
      } else {
        params.additionalCorpCodes = wrccCorpCodes;
      }
    }

    this.fetchRates(params, isReInit);
  }
  fetchRates(params, forceReload) {
    let propertyAvailability = getPropertyAvailability(),
      data = this.getSelectedProperty(propertyAvailability, 'hostBrand', params.propertyId, true) || this.getSelectedProperty(propertyAvailability, 'altSell', params.propertyId, true);

    Thinking.addIgnore('property-availability');
    EventHandler.send(EventHandler.rates.propertyAvailability.start);

    if ($.isEmptyObject(data) || forceReload) {
      callService('rates', params).then((res) => {
        if (res && res.status === 'OK') {
          data = this.getSelectedProperty(res, 'hostBrand', params.propertyId, false) || this.getSelectedProperty(res, 'altSell', params.propertyId, false);
          this.handleFetchRatesSuccess(data);
        } else {
          EventHandler.triggerEvent('brands-error', res);
          EventHandler.send(EventHandler.rates.propertyAvailability.error);
        }
      });
    } else {
      EventHandler.send(EventHandler.criteria.updated); // Needed in order to set events in the mini booking bar that will update rates.
      this.handleFetchRatesSuccess(data);
    }
  }
  handleFetchRatesSuccess(obj) {
    this.data = obj;
    setPropertyAvailability(this.data, true);
    EventHandler.send(EventHandler.rates.propertyAvailability.success, null, this.data);
    Thinking.addIgnore('property-availability', true);
  }
  getSelectedProperty(results, key, id, sessionFlag) {
    let property = {};

    if (results) {
      if (results.availability && results.availability[key]) {
        property = _.findWhere(results.availability[key].availability, { "hotelCode": id }) || _.findWhere(results.availability[key].availability, { "hotelId": id });
        property = (property && sessionFlag && property.rate && property.rate.displayRate <= 0) ? {} : property;
      } else if (results[key]) {
        property = _.findWhere(results[key].availability, { "hotelCode": id }) || _.findWhere(results[key].availability, { "hotelId": id });
        property = (property && sessionFlag && property.rate && property.rate.displayRate <= 0) ? {} : property;
      } else {
        if ( ((results.hotelCode && results.hotelCode == id) || (results.hotelId && results.hotelId == id)) && ((sessionFlag === false) || (sessionFlag === true && results.rate && results.rate.displayRate && results.rate.displayRate > -1)) ) {
          property = results;
        }
      }
    }
    if ($.isEmptyObject(property)) {
      console.log('Unable to find property in availability response');
    } else {
      return property;
    }
  }
}

class HotelAdaAmenities {
  constructor() {
    this.component = '';
    this.amenityListComponent = '';
    this.categorizedListComponent = '';
    this.data = {};
    EventHandler.one(EventHandler.property.search.success, (event, data) => {
      this.component = $('.hotel-amenities-ada-component');
      this.amenityListComponent = $('.hotel-amenities-component');
      this.categorizedListComponent = $('.categorized-list-component');
      this.leftNavComponent = $('.left-nav-component');
      this.data = data || {};
      this.beforeInit();
    });
  }

  beforeInit() {
    // If there is no HotelAmenities component, show the Amenities List or
    // the Categorized List component depending on the type of template
    if (exists(this.component)) {
      // DAI-15354: If there aren't accessible amenities in service response hide ADA tab
      if (this.data.accessibleAmenities && this.data.accessibleAmenities.length === 0) {
        this.component.find('div.expandable-item:nth-child(2)').addClass('hidden');
      }
      this.init();

      if (exists(this.categorizedListComponent)) {
        this.removeFromLeftNav(this.hasSectionTitleId(this.categorizedListComponent));
        this.categorizedListComponent.remove();
      }
      if (exists(this.amenityListComponent)) {
        this.removeFromLeftNav(this.hasSectionTitleId(this.amenityListComponent));
        this.amenityListComponent.remove();
      }
    }
  }

  hasSectionTitleId(element) {
    let id = false;
    if (exists(element)) {
      let sectionTitle = element.find('.section-title-component');
      if (exists(sectionTitle)) {
        id = sectionTitle.attr('id');
      }
    }
    return id;
  }

  removeFromLeftNav(id) {
    if (id && exists(this.leftNavComponent)) {
      return this.leftNavComponent.find(`[href='#${id}']`).closest('li').remove();
    }
  }

  init() {
    // Hotel Amenities
    if (_isNotNull(this.data.amenitiesDetails) && this.data.amenitiesDetails.length) {
      let hotelAmenitiesRaw = this.data.amenitiesDetails,
        hotelAmenitiesList = this.component.find('.amenities .hotel-amenities'),
        hotelAmenities = [];

      for (let i = 0; i < hotelAmenitiesRaw.length; ++i) {
        hotelAmenities.push(`<li itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification"><span itemprop="name">${hotelAmenitiesRaw[i].groupDetails[0].amenityName}</span><meta itemprop="value" content="True"></li>`);
      }

      // Sort & display
      hotelAmenities.sort();
      hotelAmenitiesList.append(hotelAmenities.join(''));
    }

    // ADA Amenities
    if (_isNotNull(this.data.accessibleAmenities) && this.data.accessibleAmenities.length) {
      let adaAmenitiesRaw = this.data.accessibleAmenities,
        adaAmenitiesList = this.component.find('.amenities .ada-amenities'),
        // unvAdaAmenitiesList = this.component.find('.amenities .unv-ada-amenities'),
        adaAmenities = [];
        // unAvailAdaAmenities = [];

      for (let i = 0; i < adaAmenitiesRaw.length; ++i) {
        if (adaAmenitiesRaw[i].available) {
          adaAmenities.push(`<li itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification"><span itemprop="name">${adaAmenitiesRaw[i].amenityName}</span><meta itemprop="value" content="True"></li>`);
        }
        //  else {
        //   unAvailAdaAmenities.push(`<li>${adaAmenitiesRaw[i].amenityName}</li>`);
        // }
      }

      // Sort & display
      adaAmenities.sort();
      adaAmenitiesList.append(adaAmenities.join(''));
      // unAvailAdaAmenities.sort();
      // unvAdaAmenitiesList.append(unAvailAdaAmenities.join(''));

      // Remove fluid text
      // if (!(isMobileWidth()) && (adaAmenities.length < unAvailAdaAmenities.length + 1)) {
      //   $('#expandable-ada-amenities').find('.amenities').removeClass('fluid-text-sm');
      // }
    }
  }
}


class PropertyPage {
  constructor() {
    this._params = {};

    if ($_PAGE_.is('.property-page')) {
      this.HotelAdaAmenities = new HotelAdaAmenities();
      this.PropertyPagePolicies = new PropertyPolicies(this);
      this.PropertyPageRates = new PropertyRates(this);
      this.PropertyPageOverview = new PropertyOverview(this);

      this.init();
      let isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
      if (isProxy) {
        let sectionId = $('.uu-wide-map-component .section-title-component.standard-title').attr('id'),
          isLeftNavComponent = $('.left-nav-component');
          if(sectionId && exists(isLeftNavComponent)){
            isLeftNavComponent.find(`[href='#${sectionId}']`).closest('li').remove();
          }
        $('.uu-wide-map-component').remove();
      }
      RetailBannerUtils.slideDownRetailBanner();
      this.amenitiesAnchor();
    }
  }
  init() {
    Thinking.addIgnore('getSearchUrl');
    EventHandler.one(EventHandler.criteria.init, () => {
      this.propertyInit();
    }).one(EventHandler.serviceCall.end, () => {
      Thinking.addIgnore('getSearchUrl', true);
      window.digitalData.search.updateSearchData = false;
      Analytics.handleSearchAnalytics();
      EventHandler.triggerEvent('overviewView');
    })
      .on(EventHandler.dates.updated, () => {
        this.initParams();
        this.PropertyPageRates.init(true);
        FeaturedRoomsRates.init(true);
      });

    if (!isPublish) {
      // Make UNAP call on author
      let params = {};
      params.propertyId = overview_propertyId;
      params.isOverviewNeeded = 'true';
      params.isAmenitiesNeeded = 'true';
      params.channelId = 'tab';
      params.language = _LOCALE_;
      this.PropertyPageOverview.fetchOverview(domainUrl + getServiceUrl('overview'), params);
    }

    if (exists('meta[name=description]')) {
      handleOverviewMetaTag();
    }

    // No Booking Message Link Transformation (Click to Call)
    if (exists($('.mini-booking-nav .no-bookings-message-component'))) {
      $('.mini-booking-nav .no-bookings-message-component').find('a[href^=\'tel:\']')
        .each((i, el) => {
          let oldHref = $(el).attr('href'),
            newHref = '';
          if (oldHref.indexOf('+') === -1) {
            newHref = oldHref.replace('tel:', 'tel:+');
            $(el).attr('href', newHref);
          }
        });
    }
  }
  propertyInit() {
    this.correctDuplicateFooterIssue();
    this.initParams();
    this.PropertyPageOverview.init();
    FeaturedRoomsRates.init();
    if (this._params.useWRPoints !== true) {
      this.PropertyPageRates.init();
    }
  }
  initParams() {
    let sc = getCriteria();

    this._params.brand_id = getBrand(true) || sc.brandId;
    this._params.checkin_date = formatDateForBWSAvailability(sc.checkInDate);
    this._params.checkout_date = formatDateForBWSAvailability(sc.checkOutDate);
    this._params.useWRPoints = sc.useWRPoints;
    this._params.rate_code = sc.rateCode;
    this._params.corporate_id = sc.corpCode;
    this._params.ratePlan = sc.ratePlan;
    this._params.group_code = sc.groupCode;
    this._params.childAge = sc.childAge;
    this._params.children = sc.children;
    this._params.adults = sc.adults;
    this._params.rooms = sc.rooms;
    this._params.iata = sc.iata;
    this._params.language = sc.language;
    this._params.properties = sc.properties;
    this._params.propertyId = overview_propertyId || sc.propertyId;
    this._params.orsId = overview_orsId;
    this._params.lat = overview_lat;
    this._params.longi = overview_long;
  }
  correctDuplicateFooterIssue() {
    if (getBrand() == 'ALL') {
      if ($('div.footer-text').length > 1) {
        $('div.footer-text').each((i, el) => {
          if ($(el).find('.parsys').length == 0) {
            $(el).remove();
          }
        });
      }
      if ($('div.footer-wrapper').length > 1) {
        $('div.footer-wrapper').each((i, el) => {
          if ($(el).find('.footerparsys').length == 0) {
            $(el).remove();
          }
        });
      }
    }
  }

  amenitiesAnchor() {
    if (window.location.hash == '#amenities-section-title') {
      const hotelAmenities = $('.hotel-amenities-ada-component'),
        amenities = $('.hotel-amenities-component'),
        isUU = exists($('.uu-servicesamenities-page')),
        bookingBarMini = $('#bookingBar__mini');
      let finalHeight = 0,
        finalOffset = 0,
        elementToScroll;

      if (isUU) {
        finalHeight = bookingBarMini.outerHeight() + $('.navbar.navbar-fixed-top.uu-subnav-bg').outerHeight();
      } else {
        finalHeight = $('#pageHeader').outerHeight();
      }

      window.addEventListener('load', function() {
        if (hotelAmenities.length > 0) {
          elementToScroll = $('.hotel-amenities-ada-component');
          finalOffset = hotelAmenities.offset().top;
        } else if (amenities.length > 0) {
          elementToScroll = $('.hotel-amenities-component');
          finalOffset = amenities.offset().top;
        }

        this.setTimeout(function() {
          window.scroll(0, finalOffset - finalHeight);
        }, 200);

        this.setTimeout(function() {
          if(window.matchMedia("(max-width: 719px)").matches) {
            finalHeight = isUU ? finalHeight : bookingBarMini.outerHeight();
            finalHeight -= 19;

            const uuDropdown = $('.navbar.navbar-fixed-top.uu-subnav-bg');
            if (uuDropdown.length > 0) {
              uuDropdown.css('top', $('#bookingBar__mini').outerHeight() + 'px');
            }
          }

          finalOffset = elementToScroll.offset().top;

          window.scroll(0, finalOffset - finalHeight);
        }, 2000);
      });
    }
  }
}

export default new PropertyPage();
