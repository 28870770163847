import {
  exists,
  getElTrueHeight,
  objectFitImage,
  ResizeHandler,
  isMobileWidth
} from '../base/dom-utils.js';
import  HideProvinceCountryConfig from '../base/aem-configs/hide-Province-Country';
import { getAddress, getUNAP, EventHandler, getAddressWithoutProvinceCountry } from '../base/utils.js';
import { _LANGUAGE_, $_BOOKING_BAR_MINI_FORM } from '../base/vars.js';
import { getSessionCriteria} from '../base/session-handler.js';

class PropertyHero {
  constructor() {
    if (exists('.property-hero')) {
      this.bindPropertyHeroEvents();
      this.positionPropertyInfo();
    }
    this.bindUNAP();
  }

  bindPropertyHeroEvents() {
    $('.property-hero img.pll-image-full-size').click((e) => {
      let el = e.currentTarget;
      // Get target image ID
      let imageId = $(el).data('gallery-id'),
        galId = $(el).data('target'),
        carouselId = $('' + galId).find('.carousel')
          .attr('id'),
        imageIndex = 0;
      // Iterate carousel to check for target image.
      $(`#${carouselId} .item`).each((index, item) => {
        // If present, rotate carousel to target image. If not, open at default location
        if ($(item).attr('id') === imageId) {
          imageIndex = index;
        }
        $(`#${carouselId}`).carousel(imageIndex);
        $(`#${carouselId}`).data('start-pos', imageIndex);
      });
    });

    // Don't show photo gallery when clicking on links or booking bar.
    $('.property-hero .booking-bar-container').click((event) => {
      event.stopPropagation();
    });
  }

  positionPropertyInfo() {
    this.propertyInfoMarginTop();
    ResizeHandler.addResizeEndFn(() => this.propertyInfoMarginTop());
    EventHandler.on('load', () => this.propertyInfoMarginTop(), $('.property-hero .banner-img'));
  }

  propertyInfoMarginTop() {
    if (!isMobileWidth()) {
      $('.hotel-content .property-info').css({
        marginTop: getElTrueHeight($_BOOKING_BAR_MINI_FORM) + 15
      });
      if (exists($('.property-hero .low-res'))) {
        $('.property-hero').addClass('low-res-hero');
        $('.property-hero .pll-background-wrapper').addClass('low-res-pll-container');
        $('.property-hero .pll-background-wrapper').css({
          top: getElTrueHeight($_BOOKING_BAR_MINI_FORM) + 35
        });
        $('.property-hero .hotel-content').css('background-image', 'none');
      }
    } else {
      $('.hotel-content .property-info').css({
        marginTop: '0'
      });
      $('.property-hero, .hotel-content').removeAttr('style');
      $('.property-hero .low-res').css('top', '50%');
      objectFitImage($('.property-hero .low-res'), $('.property-hero'));

      if (exists($('.property-hero .hi-res'))) {
        $('.property-hero .hi-res').removeAttr('style');
        objectFitImage($('.property-hero .hi-res'), $('.property-hero'));
      }
    }
  }

  bindUNAP() {
    EventHandler.one(EventHandler.UNAP.updated, () => {
      let unap = getUNAP();

      let shouldHideProvinceCountry = HideProvinceCountryConfig.getHideProvinceCountryConfig(getSessionCriteria().propertyId);
      
      let address = shouldHideProvinceCountry ? getAddressWithoutProvinceCountry() : getAddress();

      $('.property-hero .property-name span, .uu-hero-carousel .property-name, .mob-prop-details .property-name .highlight-wrapper').text(unap.name);
      $('.uu-hero-carousel .prop-addr, .mob-prop-details .prop-addr').text(address);
      $('.property-hero .property-address').html('<span class="highlight-wrapper">' + address + '</span>');
      $('.property-hero .property-address').css('display', 'inline');

      let phoneNumber = unap.phone;
      if (phoneNumber && phoneNumber.indexOf('+') !== 0) {
        phoneNumber = '+' + phoneNumber;
      }

      if (_LANGUAGE_ !== 'en') {
        $('.property-hero .property-phone .property-content a, .mob-prop-details .property-phone .property-content a, .uu-hero-carousel .property-phone .property-content a').text(phoneNumber);
        $('.property-hero .property-phone .property-content a, .mob-prop-details .property-phone .property-content a, .uu-hero-carousel .property-phone .property-content a, .property-phone-mobile').attr('href', 'tel:' + phoneNumber);
      }
      $('.property-hero .property-phone').css('display', 'inline');
    });
  }
}

export default new PropertyHero();
