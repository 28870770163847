import Analytics from '../../base/wyn-analytics-module.js';
class CardsCarousel {
  constructor() {
    this.instancesColumn3 = $('.cards-carousel.column-3');
    this.instancesColumn2 = $('.cards-carousel.column-2');

    if (this.instancesColumn3.length > 0) {
      this.initCarousel3();
      window.at.wait(this.instancesColumn3, (root) => this.initCarousel3(root));
    }
    if (this.instancesColumn2.length > 0) {
      this.initCarousel2();
      window.at.wait(this.instancesColumn2, (root) => this.initCarousel2(root));
    }
    this.getPackagesInfo();
    this.handleClickAnalytics();
  }
  initCarousel3(root) {
    $.each($('.cards-carousel.column-3 .cards-container', root), (i, e) => {
      $(e).on('init', (event, slick) => {
        this.equalizeCardHeights(event.currentTarget);
        this.showHideDots(slick);
      });
      $(e).slick({
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 0,
        responsive: [{
          breakpoint: 1199,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        },
        {
          breakpoint: 719,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true
          }
        }
        ]
      });
      $(e).on('setPosition', (event, slick) => {
        this.equalizeCardHeights(event.currentTarget);
      });
    });
  }
  initCarousel2(root) {
    $.each($('.cards-carousel.column-2 .cards-container', root), (i, e) => {
      $(e).on('init', (event, slick) => {
        this.showHideDots(slick);
        this.equalizeCardHeights(event.currentTarget);
      });
      $(e).slick({
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 0,
        responsive: [{
          breakpoint: 1199,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false
          }
        },
        {
          breakpoint: 719,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true
          }
        }
        ]
      });
      $(e).on('breakpoint', (event, slick, breakpoint) => {
        this.showHideDots(slick);
      });
      $(e).on('setPosition', (event, slick) => {
        this.equalizeCardHeights(event.currentTarget);
      });
    });
  }
  showHideDots(slick) {
    let breakpoint = slick.activeBreakpoint || Math.max.apply(null, slick.breakpoints);
    if (slick.$dots && breakpoint) {
      let slidesToShow = slick.breakpointSettings[breakpoint].slidesToShow || 1;
      if (slick.$slides.length > slidesToShow) {
        slick.$dots.show();
      } else {
        slick.$dots.hide();
      }
    }
  }
  equalizeCardHeights(carousel) {
    $(carousel).find('.slick-slide')
      .height('auto');

    let slickTrack = $(carousel).find('.slick-track'),
      slickTrackHeight = $(slickTrack).height();

    $(carousel).find('.slick-slide')
      .css('height', slickTrackHeight + 'px');
  }
  getPackagesInfo() {
    let packages = [];
    if($('.text-offers-component .cards-carousel .text-offer-card.slick-slide:not(.slick-cloned)').length > 0){
      let packagesObj = {};
      $('.text-offers-component .cards-carousel .text-offer-card.slick-slide').each(function(index) {
        let packageName = $(this).find('.card-content > .top-content > .title-link')
          .text()
          .trim();

        if (!(packageName in packagesObj)) {
          packagesObj[packageName] = true;
        }
        packages = Object.keys(packagesObj);
      });
      if(window.digitalData.hasOwnProperty("package")){
        window.digitalData.package.packageInfo.packageNames = packages.join('|');
      }
    }
  }

  handleClickAnalytics() {
    const carouselArrows = document.querySelectorAll('.generic-page .text-offers-component .slick-arrow');
    if(carouselArrows.length > 0) {
      carouselArrows.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          Analytics.satelliteTracker('carausalArrowClick');
        });
      });
    }
  }

}

export default new CardsCarousel();
