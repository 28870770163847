import ConfigsUtils from './config-utils';

const KEY = 'brands-filter';

/**
 * Returns an object containing the tiers and brands to be shown on the
 * Locations page depending on the brands-filter AEM Config.
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 * @returns {Object}
 */
const getBrandsAndTiers = (channels, locales, brands) => {
  const config = ConfigsUtils.findConfig(KEY, channels, locales, brands);

  return ConfigsUtils.getBrandsAndTiersFromConfig(config, KEY);
};


export default {
  getBrandsAndTiers,
};
