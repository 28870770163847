import ConfigsUtils from './config-utils';

const KEY = 'maxAllowedRoomCount';

/**
 * Returns an object containing the max allowed rooms to be shown on the
 * Locations page depending on the max-allowed-room-config AEM Config.
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 * @returns {Array} of amenities codes, empty array otherwise
 */
 const getMaximumAllowedRoomCount = () => {
  const config = ConfigsUtils.findConfig(KEY);
    return config ? config.attributes[KEY] : "";
};

export default {
  getMaximumAllowedRoomCount,
};
