import { exists } from '../../base/dom-utils.js';

class UUPhotoGrid {
  constructor() {
    if (exists('.uu-photo-grid')) {
      this.bindGridClicks();
    }
  }

  bindGridClicks() {
    $('.uu-photo-grid').on({
      click: (e) => {
        this.setPhotoGallery(e.currentTarget);
      },
      keydown: (e) => {
        if (e.keyCode == 13) {
          this.setPhotoGallery(e.currentTarget);
          $(e.currentTarget).find('img').click();
        }
      }
    });
  }

  setPhotoGallery(element) {
    // Get target image ID
    let imageId = $('img.img-responsive', element).data('gallery-id'),
      galId = $('img.img-responsive', element).data('target'), //'#propLightBox'
      carouselId = $(`${galId}`).find('.carousel')
        .attr('id'),
      imageIndex = 0;

    // Iterate carousel to check for target image.
    $(`#${carouselId} .item`).each((i,el) => {
      if ($(el).attr('id') === imageId) {
        imageIndex = i;
      }
    });

    $(`#${carouselId}`).carousel(imageIndex)
      .data('start-pos', imageIndex);
  }
}

export default new UUPhotoGrid();
