import { exists } from '../../base/dom-utils.js';
import { $_GENERIC_LIGHTBOX_ } from '../../base/vars.js';

class UUVideo {
  constructor() {
    if(exists('.uu-video-content')) {
      this.setVideoSrcOnModalOpen();
    }
  }

  setVideoSrcOnModalOpen() {
    let $uuVideoContent = $('.uu-video-content'),
      url = $uuVideoContent.find('iframe').attr('src');

    $uuVideoContent.find('iframe').css('width', '100% !important');

    $_GENERIC_LIGHTBOX_.on('hide.bs.modal', () => {
      $uuVideoContent.find('iframe').attr('src', '');
    }).on('show.bs.modal', () => {
      $uuVideoContent.attr('src', url);
    });
  }
}

export default new UUVideo();
